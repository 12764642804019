import { RepeatIcon } from "@chakra-ui/icons";
import { Flex, IconButton, Text, useToast } from "@chakra-ui/react";
import { toastDefaultStyle } from "chakra/theme";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { Checkbox } from "components/checkbox";
import DynamicTable from "components/dynamic-table";
import { Column } from "components/dynamic-table/types";
import { useAttendanceContext } from "contexts/AttendanceContext";
import { formatData } from "utils/string-formats";
import { bancosContratos, tiposEmprestimos } from "./consts";

export function ContractsTable() {
  const { formControl, dispatch } = useAttendanceContext();
  const toast = useToast();
  const attendance = formControl.values as Attendance;
  const { contratosEmprestimosInss, convenio } = attendance;
  const showTable = convenio === "INSS" && !!contratosEmprestimosInss?.length;
  // const [saldoQuitacao] = sumFields(contratosEmprestimos, ["saldoQuitacao"]);

  const columns: Column[] = [
    {
      name: "Simular",
      render: (row) => {
        return (
          <Checkbox
            isChecked={row.simular}
            onChange={(value) => {
              dispatch({
                type: "changeField",
                payload: {
                  data: contratosEmprestimosInss?.map((contract) => {
                    if (contract.contrato === row.contrato) {
                      contract.simular = value;
                    }
                    return contract;
                  }),
                  fieldName: "contratosEmprestimosInss",
                },
              });
            }}
          />
        );
      },
    },
    {
      name: "Ações",
      render: (row) => {
        return (
          <IconButton
            aria-label=""
            onClick={() =>
              toast({
                title: "Serviço em Desenvolvimento!",
                ...toastDefaultStyle,
              })
            }
            size="sm"
            icon={<RepeatIcon />}
          />
        );
      },
    },
    {
      name: "Banco",
      render: ({ codBanco }) => {
        return bancosContratos[codBanco];
      },
    },
    {
      name: "Valor Parcelas",
      render: ({ valorParcela }) => {
        if (valorParcela)
          return <Text color="#0c0">{`R$ ${valorParcela}`}</Text>;
      },
    },
    {
      name: "Taxa",
      render: ({ taxa }) => {
        if (taxa) return `${taxa}%`;
      },
    },
    {
      name: "Quantidade Parcelas",
      render: ({ quantidadeParcelas }) => {
        return quantidadeParcelas;
      },
    },
    {
      name: "Parcelas em Aberto",
      render: ({ quantidadeParcelasEmAberto }) => {
        return quantidadeParcelasEmAberto;
      },
    },
    {
      name: "Saldo Dev. Aprox.",
      render: ({ saldoQuitacao }) => {
        if (saldoQuitacao != null)
          return <Text color="#00bfff">{`R$ ${saldoQuitacao}`}</Text>;
      },
    },
    {
      name: "Contrato",
      key: "contrato",
    },
    {
      name: "Tipo de Empréstimo",
      render: ({ codTipoEmprestimo }) => {
        return tiposEmprestimos[codTipoEmprestimo];
      },
      cellStyle: () => ({ whiteSpace: "nowrap" }),
    },
    {
      name: "Valor Empréstimo",
      render: ({ valorEmprestado }) => {
        if (valorEmprestado) return `R$ ${valorEmprestado}`;
      },
    },
    {
      name: "Início Desconto",
      key: "competenciaInicioDesconto",
    },
    {
      name: "Fim Desconto",
      key: "competenciaFimDesconto",
    },
    {
      name: "Data Averbação",
      render: ({ dataInicioContrato }) => {
        if (dataInicioContrato) return formatData(dataInicioContrato);
      },
    },
    {
      name: "Situação",
      render: ({ situacao }) => {
        return <Text color="#0c0">{situacao}</Text>;
      },
    },
  ];

  // const lastRow: ContratoInss = {
  //   contrato: contratosEmprestimosInss?.length,
  //   saldoQuitacao: `TOTAL: R$ ${saldoQuitacao}`,
  // };

  return showTable ? (
    <Flex flexDir="column">
      <Text fontSize="20" mt="25px" ml="0px" fontWeight="500">
        Emprestimos Bancários
      </Text>
      <hr style={{ margin: "5px 0 20px 0px" }} />
      <DynamicTable
        rows={contratosEmprestimosInss || []}
        columns={columns}
        containerStyle={{ boxShadow: "0 0 1em rgba(0,0,0, 0.05)", pb: "0" }}
        sortBy="valorParcelaSelecionado"
        sortOrder="DESC"
        emptyMessage="Usuário não fez empréstimos."
        // lastRow={lastRow}
        headerStyle={() => ({
          whiteSpace: "normal",
          textAlign: "center",
          py: "10px",
        })}
        isLoading={false}
      />
    </Flex>
  ) : null;
}
