import { CloseIcon } from "@chakra-ui/icons";
import { Button, useDisclosure, useToast } from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { CustomModal } from "components/custom-modal";
import DynamicTable from "components/dynamic-table";
import { Column } from "components/dynamic-table/types";
import { ChangeIcon } from "components/vectors/change-icon";
import { Dispatch, SetStateAction, useLayoutEffect, useState } from "react";

export interface UsersProps {
  id: number;
  username: string;
  name: string;
}

export function ModalChangeUser({
  changeUserModalData,
  setChangeUserModalData,
  onUpdate,
}: {
  changeUserModalData: { id: number };
  setChangeUserModalData: Dispatch<SetStateAction<{ id: number } | null>>;

  onUpdate: () => void;
}) {
  const [users, setUsers] = useState<UsersProps[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(true);
  const toast = useToast();

  function handleChangeUser(userId: number, attendanceId: number) {
    setIsLoading(true);
    api
      .get(`atendimentos/${attendanceId}/transfer/${userId}`)
      .then(() => {
        toast({
          title: "Atendimento transferido com sucesso!",
          ...toastDefaultStyle,
        });
        onClose();
        onUpdate();
      })
      .catch(() =>
        toast({
          title: "Erro ao transferir atendimento!",
          status: "error",
          ...toastDefaultStyle,
        })
      )
      .finally(() => setIsLoading(false));
  }

  useLayoutEffect(() => {
    if (isOpen) {
      api
        .get("/usuarios/possible-transfer-attendance")
        .then((response) => {
          setUsers(response.data);
        })
        .catch(() =>
          toast({
            title: "Erro ao carregar usuários!",
            status: "error",
            ...toastDefaultStyle,
          })
        )
        .finally(() => setIsLoading(false));
    }
  }, [isOpen]);

  useLayoutEffect(() => {
    if (changeUserModalData?.id) onOpen();
  }, [changeUserModalData]);

  useLayoutEffect(() => {
    if (!isOpen) {
      setChangeUserModalData(null);
      setUsers([]);
      setIsLoading(true);
    }
  }, [isOpen]);

  const columns: Column[] = [
    {
      name: "Nome do Usuário",
      key: "name",
    },
    {
      name: "Ações",
      render: (row) => (
        <Button
          onClick={() => handleChangeUser(row.id, changeUserModalData.id)}
          leftIcon={<ChangeIcon />}
          size="sm"
          variant="outline"
        >
          Transferir
        </Button>
      ),
    },
  ];

  const footer = (
    <>
      <Button
        onClick={onClose}
        leftIcon={<CloseIcon w="12px" h="12px" />}
        variant="outline"
      >
        Cancelar
      </Button>
    </>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Transferir Atendimento"
      modalFooter={footer}
      size="2xl"
    >
      <DynamicTable
        columns={columns}
        rows={users}
        isLoading={isLoading}
        size="md"
        containerStyle={{ boxShadow: "0 0 1em rgba(0,0,0, 0.05)" }}
      />
    </CustomModal>
  );
}
