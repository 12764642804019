import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { hasSession } from "Session";
import api from "api/api";
import { FieldError } from "components/field-error";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CustomInput } from "../../components/login-page/custom-input";
import { LockIcon } from "../../components/vectors/lock-icon";
import { Logo } from "../../components/vectors/logo";
import { UserIcon } from "../../components/vectors/user-icon";
import { getSubdomain } from "utils/verify-domain";
import { userTypeRedirect } from "components/login-page/functions";
import { isMaisAgilDomain } from "utils/is-mais-agil-domain";
import { MaisAgilLogo } from "components/vectors/logo/mais-agil-logo";
import { getLogo } from "components/white-label";

const loginErrorList: any = {
  401: "Usuário ou Senha incorretos.",
};

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setUserAction, user } = useApplicationContext();
  const router = useHistory();
  const { state }: { state: any } = useLocation();

  useEffect(() => {
    setLoginError("");
  }, [username, password]);

  useEffect(() => {
    if (hasSession()) router.push(userTypeRedirect(user));
  }, [user]);

  async function handleSubmit(authorization: string) {
    setIsLoading(true);
    try {
      const response = await api.get("/usuarios/login", {
        headers: { Authorization: "Basic " + authorization },
      });
      setIsLoading(false);
      setUserAction({
        type: "login",
        payload: { userData: response.data, token: authorization },
      });
      console.log(response.data);
      // router.push(state?.prevPath ? state?.prevPath : "/");
    } catch (error: any) {
      setLoginError(
        loginErrorList[error.response?.status] ?? "Algo deu errado."
      );
      setIsLoading(false);
    }
  }

  return (
    <Flex px="25px" w="100%" h="100vh" alignItems="center" fontSize="16">
      <Flex
        alignItems="center"
        flexDir="column"
        maxW="550px"
        w="100%"
        mx="auto"
      >
        <Box mb="73px" w={isMaisAgilDomain() ? "40%" : "80%"} mx="auto">
          {getLogo()}
        </Box>
        <CustomInput
          icon={<UserIcon />}
          placeholder="Usuário"
          onChange={(e) => setUsername(e.target.value)}
          value={username}
          mb="18px"
          onKeyDown={(e) =>
            e.key === "Enter"
              ? handleSubmit(window.btoa(username + ":" + password))
              : undefined
          }
        />
        <CustomInput
          icon={<LockIcon />}
          placeholder="Senha"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type="password"
          onKeyDown={(e) =>
            e.key === "Enter"
              ? handleSubmit(window.btoa(username + ":" + password))
              : undefined
          }
        />
        <FieldError isError={loginError !== ""}>{loginError}</FieldError>
        <Button
          isLoading={isLoading}
          mt="17px"
          h="55px"
          color="#fff"
          fontWeight="800"
          bg="primary.500"
          w="100%"
          borderRadius="10"
          _hover={{ bg: "primary.400" }}
          onClick={() => handleSubmit(window.btoa(username + ":" + password))}
          loadingText="Entrando"
        >
          Entrar
        </Button>
        {getSubdomain() === "www" ||
        getSubdomain() === "localhost" ||
        getSubdomain() === "novapowerhub" ? (
          <Text mt="74px" fontWeight="500">
            POWERED BY <b>NOVA PROMOTORA</b>
          </Text>
        ) : null}
      </Flex>
    </Flex>
  );
};
