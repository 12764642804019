import { CreditCardIcon } from "components/vectors/credit-card-icon";
import { DatabaseIcon } from "components/vectors/database-icon";
import { DocumentIcon } from "components/vectors/document-icon";
import { MonitorIcon } from "components/vectors/monitor-icon";

import { ReactNode } from "react";
import { CampaignIcon } from "components/vectors/campaign-icon";
import { WhatsAppIcon } from "components/vectors/whatsapp-icon";
import { CashIcon } from "components/vectors/cash-icon";
import { HomeIcon } from "components/vectors/menu-icons/home-icon";
import { MegaphoneIcon } from "components/vectors/menu-icons/mega-phone-icon";
import { PersonIcon } from "components/vectors/menu-icons/person-icon";
import { WalletIcon } from "components/vectors/menu-icons/wallet-icon";
import { PhoneIcon } from "components/vectors/menu-icons/phone-icon";
import { SearchIcon } from "components/vectors/search-icon";

export interface SubItemProps {
  name: string;
  href: string;
  subItems?: undefined;
}

export interface MenuItemProps {
  name: string;
  subItems?: SubItemProps[];
  href?: string;
  icon: JSX.Element | ReactNode;
}

export const menuArray: MenuItemProps[] = [
  {
    name: "Acessos",
    subItems: [
      {
        name: "Empresas",
        href: "/empresas",
      },
      {
        name: "Usuários",
        href: "/usuarios",
      },
    ],
    icon: <PersonIcon />,
  },
  {
    name: "Carteira",
    href: "/carteira",
    icon: <CashIcon />,
  },
  {
    name: "Usuários Bancos",
    href: "/usuarios-bancos",
    icon: <MonitorIcon />,
  },
  {
    name: "Mailing",
    href: "/mailing",
    icon: <DatabaseIcon />,
  },
  {
    name: "Consulta",
    href: "/consulta",
    icon: <SearchIcon stroke="currentColor" />,
  },
  // {
  //   name: "Simulação",
  //   subItems: [
  //     {
  //       name: "Margem Aumento",
  //       href: "/margem-aumento",
  //     },
  //   ],
  //   icon: <MonitorIcon />,
  // },
  {
    name: "BMG Cartão Saque",
    href: "/bmg-cartao-saque-lista",
    icon: <CreditCardIcon />,
  },
  {
    name: "Sessões WhatsApp",
    href: "/sessoes-whatsapp",
    icon: <WhatsAppIcon color="currentColor" />,
  },
  {
    name: "Campanha",
    subItems: [
      {
        name: "Campanha WhatsApp",
        href: "/campanha-whatsapp",
      },
      {
        name: "Campanha SMS",
        href: "/campanha-sms",
      },
      // {
      //   name: "Campanha URA WhatsApp",
      //   href: "/campanha-ura-whatsapp",
      // },
    ],
    icon: <CampaignIcon />,
  },
  {
    name: "Atendimento",
    icon: <PhoneIcon />,
    subItems: [
      {
        name: "Atendimentos",
        href: "/atendimentos",
      },
      {
        name: "Tabelas Digitação",
        href: "/tabelas-digitacao",
      },
    ],
  },
  {
    name: "Extrato",
    href: "/extrato-online-avulso",
    icon: <DocumentIcon />,
  },
];

export const newMenuItens: MenuItemProps[] = [
  {
    name: "Home",
    href: "/",
    icon: <HomeIcon />,
  },
  {
    name: "Acessos",
    icon: <PersonIcon />,
    subItems: [
      { name: "Empresas", href: "/empresas" },
      { name: "Usuários Corban", href: "/usuarios" },
      { name: "Usuários Bancos", href: "/usuarios-bancos" },
      { name: "Sessões WhatsApp", href: "/sessoes-whatsapp" },
    ],
  },
  {
    name: "Mailing",
    icon: <MegaphoneIcon />,
    subItems: [
      { name: "Mailing Lista", href: "/mailing" },
      { name: "Campanha WhatsApp", href: "/campanha-whatsapp" },
      { name: "Usuários Bancos", href: "/usuarios-bancos" },
      { name: "Campanha SMS", href: "/campanha-sms" },
    ],
  },
  {
    name: "Carteira",
    icon: <WalletIcon />,
    href: "/carteira",
  },
  {
    name: "Atendimento",
    icon: <PhoneIcon />,
    subItems: [
      { name: "Atendimento", href: "/atendimentos" },
      { name: "Tabela Digitação", href: "/tabelas-digitacao" },
    ],
  },
];
