import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FlexProps,
  IconButton,
  Progress,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { toastDefaultStyle } from "chakra/theme";
import { FieldError } from "components/field-error";
import { DocumentIcon2 } from "components/vectors/document-icon";
import { UploadIcon } from "components/vectors/upload-icon";
import { DragEvent, ReactNode, useRef, useState } from "react";
import { formatFileSize } from "utils/string-formats";

// const validTypes = [
//   "image/jpeg",
//   "image/jpg",
//   "image/png",
//   "image/svg",
//   "image/webp",
//   "image/svg+xml",
// ];

const getExtension = new Map([["audio/mpeg", "mp3"]]);

export function DraggableFileField({
  file,
  handleFile,
  handleDelete,
  error,
  isSending = false,
  fieldTitle,
  fileExtensions = ["text/csv"],
  disabled,
  tooltipMessage,
  dragFieldStyle,
  description = (
    <Text userSelect="none">
      Arraste um arquivo para cá ou <b>clique para inserir</b>
    </Text>
  ),
}: {
  file: File | undefined | null;
  handleFile: (file: File) => void;
  handleDelete?: () => void;
  error: string;
  isSending?: boolean;
  fieldTitle?: string;
  fileExtensions?: string[];
  disabled?: boolean;
  tooltipMessage?: string;
  dragFieldStyle?: FlexProps;
  description?: ReactNode;
}) {
  const [dragHover, setDragHover] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const toast = useToast();

  function validateFileExtension(file: File) {
    if (fileExtensions.indexOf(file.type) === -1) {
      toast({
        title: "Extensão inválida!",
        description: `Extensões aceitas: ${fileExtensions
          .map(
            (ext) =>
              `.${getExtension.get(ext) ?? ext.split("/")[1].split("+")[0]}`
          )
          .join(" ")}`,
        ...toastDefaultStyle,
      });
      return false;
    }
    return true;
  }

  const dragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (isDisable) return;
    setDragHover(true);
  };

  const dragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (isDisable) return;
    setDragHover(true);
  };

  const dragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (isDisable) return;
    setDragHover(false);
  };
  const isDisable = isSending || disabled;
  return (
    <>
      {fieldTitle ? (
        <Text mb="10px" mt="10px">
          {fieldTitle}
        </Text>
      ) : null}
      <Tooltip label={tooltipMessage}>
        <Flex
          onClick={isDisable ? undefined : () => fileInputRef.current?.click()}
          onDrop={(e) => {
            e.preventDefault();
            if (isDisable) return;
            const file = e.dataTransfer.files;
            if (file.length && validateFileExtension(file[0]))
              handleFile(file[0]);
          }}
          borderRadius="0.25rem"
          onDragOver={dragOver}
          onDragLeave={dragLeave}
          onDragEnter={dragEnter}
          onMouseLeave={isDisable ? undefined : () => setDragHover(false)}
          opacity={isDisable ? "0.6" : "1"}
          cursor="pointer"
          p={file ? "0" : "20px"}
          border={file ? undefined : "default-border"}
          flexDir="column"
          _hover={{
            bg: "bg-gray",
          }}
          transition="0.3s"
          w="100%"
          pos="relative"
          {...dragFieldStyle}
        >
          {file ? (
            <Flex
              bg="#fff"
              p="10px 15px"
              gap="10px"
              borderRadius="0.25rem"
              alignItems="center"
              justifyContent="space-between"
              border="default-border"
              transition="0.3s"
              _hover={{
                bg: "bg-gray",
              }}
            >
              <Flex gap="15px" alignItems="center">
                <DocumentIcon2 width="33px" height="33px" />
                <Box>
                  <Text fontWeight="bold" fontSize="17">
                    {file.name}
                  </Text>
                  <Text>{formatFileSize(file.size)}</Text>
                </Box>
              </Flex>
              {handleDelete ? (
                <IconButton
                  aria-label="Deletar Arquivo"
                  onClick={(e) => {
                    if (isDisable) return;
                    e.preventDefault();
                    e.stopPropagation();
                    handleDelete();
                  }}
                  icon={<DeleteIcon />}
                  size="xs"
                  variant="danger"
                  animation="presence 0.3s ease"
                />
              ) : null}
            </Flex>
          ) : (
            <Flex
              flexDir="column"
              alignItems="center"
              transition="0.3s"
              textAlign="center"
            >
              {description}
              <Box
                transition="0.3s"
                transform={dragHover ? "skew(-20deg, 25deg)" : "none"}
                mt="15px!important"
              >
                <UploadIcon width="30px" height="30px" />
              </Box>
            </Flex>
          )}
        </Flex>
      </Tooltip>
      <input
        key={Math.random()}
        ref={fileInputRef}
        type="file"
        style={{ display: "none" }}
        onChange={(e) => {
          if (isDisable) return;
          const file = e.target.files;
          if (file?.length && validateFileExtension(file[0]))
            handleFile(file[0]);
        }}
        accept={fileExtensions.join(",")}
      />
      {isSending ? (
        <Progress
          colorScheme="third"
          size="xs"
          mt="10px"
          w="100%"
          borderRadius="4"
          opacity={isSending ? "1" : "0"}
          isIndeterminate
        />
      ) : null}
      <FieldError isError={error !== ""}>{error}</FieldError>
    </>
  );
}
