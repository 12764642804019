import { Box, Button, Grid, Text, useToast } from "@chakra-ui/react";
import AttendanceDropdown from "components/performance/attendance-dropdown";
import OptimizedInput from "components/performance/attendance-input";
import { useAttendanceContext } from "contexts/AttendanceContext";
import { numberMask } from "utils/string-formats";
import {
  calcularLimiteCartao,
  calcularMargemCartao,
  calcularMargemLivreNovo,
  calcularSaqueCartao,
  dropdownSimNao,
} from "../../../fields-data";
import type { Attendance } from "../../../types";
import { TabDataProps } from "../../consts";
import { ContractsRefinancingTable } from "./contracts-refinancing-table";
import { TabelaAntecipacoesFGTS } from "./tabela-antecipacoes-fgts";
import { useApplicationContext } from "contexts/ApplicationContext";
import { toastDefaultStyle } from "chakra/theme";
import api from "api/api";
import { consultarFGTS, reCalcularFGTS } from "./consts";
import { DropdownField } from "components/dropdown-field";
import { useEffect, useState } from "react";
import { DropdownOption } from "components/dropdown";
import { objectSchema } from "utils/object-methods";
import { fieldValidation } from "utils/field-validation";

interface TabelaOptionsProps {
  id: number;
  convenio: string;
  tipoOperacao: null;
  banco: string;
  prazo: null;
  codigo: string;
  descricaoCompleta: string;
  needToken: boolean;
  token: null;
}

export function SimulacoesFields({
  onChangeField,
  resetFieldsTabelaDigitacao,
}: TabDataProps) {
  const [tabelaOptions, setTabelaOptions] = useState<DropdownOption[]>([]);
  const [tabelaOptionsLoading, setTabelaOptionsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<{ [k: string]: string }>({});
  const {
    formControl,
    dispatch,
    setConsultandoFGTS,
    consultandoFGTS,
    reConsultandoFGTS,
    setReConsultandoFGTS,
  } = useAttendanceContext();
  const { user } = useApplicationContext();
  const toast = useToast({ ...toastDefaultStyle });

  const formValues = formControl.values as Attendance;
  const codTable = formValues.atendimentoFactaFGTS?.codTable;
  const convenio = formValues.convenio;
  const observacaoSaldo =
    formValues.atendimentoFactaFGTS?.observacaoSaldo ?? "";
  const simulacaoAntecipacaoFactaFGTS =
    formValues.simulacaoAntecipacaoFactaFGTS || [];
  const checkedList = simulacaoAntecipacaoFactaFGTS.filter(
    (crr) => crr.checked
  );

  const { isValid, errors } = objectSchema(
    {
      codTable: (value) => {
        const error = "Selecione a tabela";
        const { isValid, errorMessage } = fieldValidation({ value }).required({
          message: error,
        });
        return { valid: isValid, message: errorMessage };
      },
    },
    { codTable }
  );

  const getTabelaOptions = async () => {
    setTabelaOptionsLoading(true);
    try {
      const { data }: { data: TabelaOptionsProps[] } = await api.get(
        "/tabelas-digitacao/facta/fgts"
      );
      setTabelaOptions(
        data.map(({ codigo, descricaoCompleta }) => {
          return { name: `${codigo} - ${descricaoCompleta}`, value: codigo };
        })
      );
    } catch (e) {
      toast({ title: "Erro ao obter tabelas" });
    } finally {
      setTabelaOptionsLoading(false);
    }
  };

  useEffect(() => {
    getTabelaOptions();
  }, []);

  let tabelaLabel =
    tabelaOptions.find((option) => option.value === codTable)?.name ??
    "Selecione";

  if (tabelaLabel.length > 20) tabelaLabel = tabelaLabel.slice(0, 20) + "...";

  return (
    <>
      {convenio === "ANTECIPACAO_FGTS" ? (
        <Box>
          <Text fontSize="20" ml="0px" mt="0px" fontWeight="500">
            Consulta FGTS
          </Text>
          <hr style={{ margin: "5px 0 20px 0px" }} />
          <Grid
            templateColumns="31fr 23fr 23fr 23fr"
            w="100%"
            gap="16px"
            mb="16px"
          >
            <Box>
              <DropdownField
                dropdownProps={{
                  w: "100%",
                  isLoading: tabelaOptionsLoading,
                  children: tabelaLabel,
                }}
                titleProps={{ mb: "4px" }}
                title="Tabela"
                options={tabelaOptions}
                value={formValues?.atendimentoFactaFGTS?.codTable}
                onChange={(value) => {
                  setFieldErrors((errors) => {
                    delete errors["codTable"];
                    return errors;
                  });

                  let data = {
                    ...formValues.atendimentoFactaFGTS!,
                    codTable: value,
                  };

                  dispatch({
                    type: "changeFieldWithInitial",
                    payload: { data, fieldName: "atendimentoFactaFGTS" },
                  });
                }}
                errorMessage={fieldErrors["codTable"]}
              />
            </Box>

            <Box>
              <Text mb="4px">&nbsp;</Text>
              <Button
                w="100%"
                // leftIcon={<SearchIcon />}
                isLoading={consultandoFGTS}
                loadingText="Consultando"
                onClick={() => {
                  if (!isValid) {
                    setFieldErrors({ ...errors });
                    return;
                  }
                  consultarFGTS({
                    dispatch,
                    formControl,
                    loading: setConsultandoFGTS,
                    user,
                    codTable: codTable!,
                  });
                }}
              >
                Consultar
              </Button>
            </Box>
            <Box>
              <Text mb="4px">&nbsp;</Text>
              <Button
                w="100%"
                // leftIcon={<RepeatIcon />}
                isLoading={reConsultandoFGTS}
                loadingText="Recalculando"
                onClick={() => {
                  if (!isValid) {
                    setFieldErrors({ ...errors });
                    return;
                  }
                  reCalcularFGTS({
                    dispatch,
                    formControl,
                    loading: setReConsultandoFGTS,
                    user,
                    codTable: codTable!,
                  });
                }}
                isDisabled={!checkedList.length}
              >
                Recalcular
              </Button>
            </Box>
            <Box>
              <Text mb="4px">Valor Liberado</Text>
              <OptimizedInput
                value={
                  formValues.atendimentoFactaFGTS?.valorLiberadoAntecipacao ??
                  ""
                }
                isReadOnly
              />
            </Box>

            <Box gridArea={"auto / span 4"}>
              <Text mb="4px">Observação</Text>
              <OptimizedInput color="red" value={observacaoSaldo} isReadOnly />
            </Box>
          </Grid>
          <Text fontSize="20" ml="0px" mt="0px" fontWeight="500">
            Desconto Anual FGTS
          </Text>
          <hr style={{ margin: "5px 0 20px 0px" }} />
          <TabelaAntecipacoesFGTS />
        </Box>
      ) : (
        <Box>
          <Text fontSize="20" ml="0px" mt="0px" fontWeight="500">
            Margens
          </Text>
          <hr style={{ margin: "5px 0 20px 0px" }} />
          <Grid templateColumns="1fr 1fr 1fr 1fr" w="100%" gap="16px" mb="16px">
            <Box>
              <Text mb="4px">Margem Livre (Novo)</Text>
              <OptimizedInput
                value={formValues.valorMargemLivreNovo || ""}
                onChangeField={onChangeField}
                fieldName="valorMargemLivreNovo"
                mask={numberMask}
              />
            </Box>
            <Box>
              <Text mb="4px">Coeficiente (Novo)</Text>
              <OptimizedInput
                value={formValues.coeficienteNovo || ""}
                onChangeField={onChangeField}
                fieldName="coeficienteNovo"
                mask={numberMask}
              />
            </Box>
            <Box>
              <Text mb="4px">Valor Cliente Marg. Livre (Novo)</Text>
              <OptimizedInput
                value={calcularMargemLivreNovo(
                  formValues.valorMargemLivreNovo,
                  formValues.coeficienteNovo
                )}
                mask={numberMask}
              />
            </Box>
            <Box>
              <Text mb="4px">Qtd. Emprest.</Text>
              <OptimizedInput
                value={formValues.qtdEmprestimos || ""}
                onChangeField={onChangeField}
                fieldName="qtdEmprestimos"
              />
            </Box>
          </Grid>
          {!formValues.convenio ||
          formValues.convenio === "INSS" ||
          formValues.convenio === "SIAPE" ? (
            <>
              <Grid
                templateColumns={{
                  base: "1fr 1fr 1fr",
                  "2xl": "repeat(4,1fr)",
                }}
                gap="16px"
              >
                <Box>
                  <Text mb="4px">Possui Cartão RMC</Text>
                  <AttendanceDropdown
                    value={
                      formValues.possuiCartaoRmc == null
                        ? ""
                        : formValues.possuiCartaoRmc
                    }
                    onChangeField={onChangeField}
                    fieldName="possuiCartaoRmc"
                    options={dropdownSimNao}
                    w="full"
                  />
                </Box>
                <Box>
                  <Text mb="4px">Valor Margem Cartão RMC</Text>
                  <OptimizedInput
                    value={
                      formValues.possuiCartaoRmc === true
                        ? "#####"
                        : calcularMargemCartao(formValues.valorSalario)
                    }
                    readOnly
                    fontWeight="600"
                    cursor="not-allowed"
                  />
                </Box>
                <Box>
                  <Text mb="4px">Valor Limite Cartão RMC</Text>
                  <OptimizedInput
                    value={
                      formValues.possuiCartaoRmc === true
                        ? "#####"
                        : calcularLimiteCartao(formValues.valorSalario)
                    }
                    readOnly
                    fontWeight="600"
                    cursor="not-allowed"
                  />
                </Box>
                <Box>
                  <Text mb="4px">Valor Saque Cartão RMC</Text>
                  <OptimizedInput
                    value={
                      formValues.possuiCartaoRmc === true
                        ? "#####"
                        : calcularSaqueCartao(formValues.valorSalario)
                    }
                    readOnly
                    fontWeight="600"
                    cursor="not-allowed"
                  />
                </Box>
                <Box>
                  <Text mb="4px">Possui Cartão Benefício</Text>
                  <AttendanceDropdown
                    value={formValues.possuiCartaoBeneficio}
                    onChangeField={onChangeField}
                    fieldName="possuiCartaoBeneficio"
                    options={dropdownSimNao}
                    w="full"
                    openTop={true}
                  />
                </Box>
                <Box>
                  <Text mb="4px">Valor Margem Cartão Benefício</Text>
                  <OptimizedInput
                    value={
                      formValues.possuiCartaoBeneficio === true
                        ? "#####"
                        : calcularMargemCartao(formValues.valorSalario)
                    }
                    readOnly
                    fontWeight="600"
                    cursor="not-allowed"
                    mask={numberMask}
                  />
                </Box>
                <Box>
                  <Text mb="4px">Valor Limite Cartão Benefício</Text>
                  <OptimizedInput
                    value={
                      formValues.possuiCartaoBeneficio === true
                        ? "#####"
                        : calcularLimiteCartao(formValues.valorSalario)
                    }
                    readOnly
                    fontWeight="600"
                    cursor="not-allowed"
                    mask={numberMask}
                  />
                </Box>
                <Box>
                  <Text mb="4px">Valor Saque Cartão Benefício</Text>
                  <OptimizedInput
                    value={
                      formValues.possuiCartaoBeneficio === true
                        ? "#####"
                        : calcularSaqueCartao(formValues.valorSalario)
                    }
                    readOnly
                    fontWeight="600"
                    cursor="not-allowed"
                    mask={numberMask}
                  />
                </Box>
              </Grid>
            </>
          ) : null}
          <Box maxW="calc(66vw - 88px)" mt="15px">
            <Text fontSize="20" ml="0px" fontWeight="500">
              Refinanciamento / Portabilidade
            </Text>
            <hr style={{ margin: "5px 0 20px 0px" }} />

            <ContractsRefinancingTable onChangeField={onChangeField} />
          </Box>
        </Box>
      )}
    </>
  );
}
