import {
  Box,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Spinner,
  Text,
  TextProps,
} from "@chakra-ui/react";
import { Popover, PopoverProps } from "components/popover";
import { ChangeEvent, ReactNode, forwardRef, useEffect, useState } from "react";

export interface InputFieldProps {
  title?: string;
  value?: any;
  onChange?: (a: ChangeEvent<HTMLInputElement>) => void;
  isInvalid?: boolean;
  errorMessage?: string;
  inputProps?: InputProps;
  titleProps?: TextProps;
  popoverProps?: Omit<PopoverProps, "children" | "button">;
  messageErrorProps?: TextProps;
  isLoading?: boolean;
  rightIcon?: JSX.Element | ReactNode;
  leftIcon?: JSX.Element | ReactNode;
  closeErrorOnBlur?: boolean;
  isDisabled?: boolean;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      title,
      value,
      isInvalid,
      errorMessage,
      onChange,
      titleProps,
      inputProps,
      popoverProps,
      messageErrorProps,
      isLoading,
      leftIcon,
      rightIcon,
      closeErrorOnBlur = true,
      isDisabled,
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const { onBlur, onFocus, ...inputPropsRest } = inputProps || {};

    useEffect(() => {
      if (!errorMessage) setIsOpen(false);
      if (!closeErrorOnBlur && errorMessage) setIsOpen(true);
    }, [!!errorMessage]);

    return (
      <>
        {title ? (
          <Text mb="8px" {...titleProps}>
            {title}
          </Text>
        ) : null}
        <Popover
          button={
            <InputGroup>
              <Input
                ref={ref}
                value={value}
                onChange={onChange}
                isInvalid={isInvalid || !!errorMessage}
                onBlur={(e) => {
                  if (closeErrorOnBlur) setIsOpen(false);
                  onBlur?.(e);
                }}
                isDisabled={isDisabled}
                onFocus={(e) => {
                  if (!!errorMessage) setIsOpen(true);
                  onFocus?.(e);
                }}
                {...inputPropsRest}
              />
              {rightIcon || (isLoading && !leftIcon) ? (
                <InputRightElement>
                  {isLoading ? <Spinner w="20px" h="20px" /> : rightIcon}
                </InputRightElement>
              ) : null}
              {leftIcon ? (
                <InputRightElement>
                  {isLoading ? <Spinner w="20px" h="20px" /> : leftIcon}
                </InputRightElement>
              ) : null}
            </InputGroup>
          }
          isOpen={isOpen}
          customArrowPos={{
            right: "80%",
            transform: "translate(50%, calc(-100% + 1px))",
          }}
          popupStyles={{ width: "100%" }}
          variant="danger"
          {...popoverProps}
        >
          <Text
            color="#fff"
            fontWeight="bold"
            fontSize="13"
            {...messageErrorProps}
          >
            {errorMessage}&nbsp;
          </Text>
        </Popover>
      </>
    );
  }
);
