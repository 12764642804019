import { Flex, Grid, Text, useToast } from "@chakra-ui/react";
import { AudioMessagePlayer } from "components/chat-window/message-template/audio/audio-message-player";
import { useEffect, useState } from "react";
//@ts-ignore
import { DraggableFileField } from "components/input-file";
import MessageTemplate from "components/chat-window/message-template";
import { MessageContainer } from "components/chat-window/message-template/message-container";
import { fileToBase64 } from "utils/file-to-base64";
import { ModalCampanhaWhatsAppData } from "./modal-campanha-whatsapp";
import { toastDefaultStyle } from "chakra/theme";
import { base64toFile } from "utils/base64-to-file";

interface AudioInfo {
  duration: number;
  url: string;
}

export function CampanhaAudio({
  onChangeCampanha,
  error,
  campanha,
  isDisabled,
}: {
  onChangeCampanha: (
    field:
      | keyof ModalCampanhaWhatsAppData
      | (keyof ModalCampanhaWhatsAppData)[],
    value: any
  ) => Promise<void>;
  error: string;
  campanha: Partial<ModalCampanhaWhatsAppData>;
  isDisabled: boolean;
}) {
  const [audioInfo, setAudioInfo] = useState<null | AudioInfo>(null);
  const [audio, setAudio] = useState<File | null>(null);
  const isVisiblePlayer = audio && audioInfo != null;
  const toast = useToast();
  const key: keyof ModalCampanhaWhatsAppData = "mensagem";

  async function getAudioData(file: File): Promise<AudioInfo> {
    const url = URL.createObjectURL(file);
    return new Promise((resolve) => {
      const audio = document.createElement("audio");
      audio.muted = true;
      const source = document.createElement("source");
      source.src = url; //--> blob URL
      audio.preload = "metadata";
      audio.appendChild(source);
      audio.onloadedmetadata = function () {
        resolve({ duration: audio.duration, url });
        audio.remove();
      };
    });
  }

  const convertBase64toAudioFile = async () => {
    const file = base64toFile(campanha.mensagem!, "Áudio MP3 da Campanha");
    const audioInfo = await getAudioData(file);
    setAudioInfo(audioInfo);
    setAudio(file);
  };

  useEffect(() => {
    const regexCheckBase64 =
      /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
    if (campanha.mensagem && regexCheckBase64.test(campanha.mensagem))
      convertBase64toAudioFile();
  }, []);

  return (
    <Flex w="100%" flexDir="column" mt="10px">
      <Text mb="10px">Faça um upload de um arquivo</Text>
      <Grid templateColumns={isVisiblePlayer ? "1fr 1fr" : "1fr"} gap="16px">
        <DraggableFileField
          file={audio}
          handleFile={async (audio) => {
            const audioInfo = await getAudioData(audio);
            if (audioInfo.duration >= 61)
              return toast({
                title: "Escolha um audio com no máximo 1 minuto",
                ...toastDefaultStyle,
              });
            setAudio(audio);
            setAudioInfo(audioInfo);
            const base64Audio = await fileToBase64(audio);
            onChangeCampanha(key, base64Audio);
          }}
          handleDelete={() => {
            setAudio(null);
            onChangeCampanha(key, "");
          }}
          disabled={isDisabled}
          error={error}
          fileExtensions={["audio/mpeg"]}
          description={
            <Text userSelect="none">
              Arraste um arquivo MP3 para cá ou <b>clique para inserir</b>
            </Text>
          }
        />
        {isVisiblePlayer ? (
          <MessageContainer
            isMyMessage={true}
            containerProps={{
              maxW: "",
              w: "100%",
              overflow: "hidden",
              borderRadius: "6px",
              p: "8px",
            }}
          >
            <AudioMessagePlayer
              src={audioInfo.url}
              duration={`${audioInfo.duration}`}
              myMessage={true}
              id="campanha-audio"
              isDownloaded
              w="100%"
              mx="auto"
            />
          </MessageContainer>
        ) : null}
      </Grid>
    </Flex>
  );
}
