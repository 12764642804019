import { AddIcon, CloseIcon, Search2Icon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { CustomModal } from "components/custom-modal";
import { useState } from "react";
import { Attendance } from "../atendimento-form/types";
import { createEventListener, makeEvent } from "services/events";
import { SearchForCreateAttendance } from "./search-for-create-attendance";
import { PageDivider } from "components/page-divider";
import { CreateCustomAttendance } from "./create-custom-attendance";

export function NewAttendanceModal({
  makeAttendance,
}: {
  makeAttendance: (data: Attendance) => void;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  createEventListener("openNewAttendanceModal", onOpen);

  return (
    <CustomModal
      isOpen={isOpen}
      modalTitle="Atendimento"
      onClose={onClose}
      modalFooter={
        <Button
          variant="outline"
          leftIcon={<CloseIcon w="12px" h="12px" />}
          onClick={onClose}
        >
          Cancelar
        </Button>
      }
      minW="1200px"
    >
      <PageDivider
        pages={[
          {
            title: "Pesquisar",
            icon: <Search2Icon />,
            content: (
              <SearchForCreateAttendance
                isOpen={isOpen}
                makeAttendance={makeAttendance}
                onClose={onClose}
                onOpen={onOpen}
              />
            ),
          },
          {
            title: "Novo",
            icon: <AddIcon />,
            content: <CreateCustomAttendance onClose={onClose} />,
          },
        ]}
      />
    </CustomModal>
  );
}

export const openNewAttendanceModal = () => makeEvent("openNewAttendanceModal");
