type AnimationTimingFunctions = "ease" | "easeInCubic" | "linear";

function ease(currTime: number) {
  currTime *= 2;
  if (currTime < 1) return 0.5 * currTime * currTime;
  return -0.5 * (--currTime * (currTime - 2) - 1);
}

function easeInCubic(currTime: number) {
  return currTime ** 3;
}

// Animação um pouco mais otimizada porém limitada a apenas 1 passo de valor
export function AnimateElement(
  duration: number,
  values: [number, number],
  onChangeProgress: (value: number) => void,
  onFinish?: () => void,
  animationTimingFunction: AnimationTimingFunctions = "ease"
) {
  let stop = false;
  let startValue = values[0];
  let destinationValue = values[1];
  let start: number | null = null;
  let end: number | null = null;
  const timingFuncion = {
    ease,
    easeInCubic,
    linear: (t: number) => t,
  }[animationTimingFunction];
  function startAnim(timeStamp: number) {
    start = timeStamp;
    end = start + duration;
    animate(timeStamp);
  }
  function animate(now: number) {
    if (typeof start === "number") {
      if (stop) {
        onChangeProgress(destinationValue);
        return onFinish?.();
      }
      if (now >= end!) stop = true;
      let currTime = (now - start) / duration;
      let easedValue = timingFuncion(currTime);
      let currValue = startValue + (destinationValue - startValue) * easedValue;
      onChangeProgress(currValue);

      requestAnimationFrame(animate);
    }
  }
  requestAnimationFrame(startAnim);
}

async function asyncAnimation(
  duration: number,
  values: [number, number],
  onChangeProgress: (value: number) => void,
  onFinish?: () => void,
  animationTimingFunction: AnimationTimingFunctions = "ease"
) {
  return new Promise((res) => {
    AnimateElement(
      duration,
      values,
      onChangeProgress,
      () => {
        res(null);
        onFinish?.();
      },
      animationTimingFunction
    );
  });
}

// Animação avançada com multiplos passos
export async function startAnimation(
  duration: number,
  values: number[],
  onChangeProgress: (value: number) => void,
  onFinish?: () => void,
  animationTimingFunction: AnimationTimingFunctions = "ease"
) {
  if (values.length < 2) throw new Error("values length insufficient");
  const stepsLength = values.length - 1;
  const stepDuration = duration / stepsLength;

  for (let k = 0; k < stepsLength; k++) {
    const isLast = k + 1 === stepsLength;
    await asyncAnimation(
      stepDuration,
      [values[k], values[k + 1]],
      onChangeProgress,
      undefined,
      animationTimingFunction
    );
    if (isLast) onFinish?.();
  }
}
