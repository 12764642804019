import { CloseIcon, WarningIcon } from "@chakra-ui/icons";
import { Box, Button, ButtonProps, Flex, Text } from "@chakra-ui/react";
import { CustomModal } from "components/custom-modal";
import { useState } from "react";
import { createEventListener, makeEvent } from "services/events";

interface ModalErrorOptions {
  title?: string;
  message?: string;
  onClose?: () => any;
  closeButtonStyle?: ButtonProps;
}

export function ModalError() {
  const [modalErrorData, setModalErrorData] =
    useState<null | ModalErrorOptions>(null);

  createEventListener("open-modal-error", (options: ModalErrorOptions) => {
    setModalErrorData(options);
  });

  function onClose() {
    setModalErrorData(null);
    modalErrorData?.onClose?.();
  }

  return (
    <CustomModal
      isOpen={modalErrorData !== null}
      onClose={onClose}
      modalTitle={
        <Flex alignItems="center">
          <Box mr="8px" transform="translateY(-2px)">
            <WarningIcon color="secondary.700" w="20px" h="20px" />
          </Box>
          {modalErrorData?.title ?? "Erro"}
        </Flex>
      }
      modalFooter={
        <Button
          variant="outline"
          onClick={onClose}
          leftIcon={<CloseIcon w="12px" h="12px" />}
          {...modalErrorData?.closeButtonStyle}
        >
          Fechar
        </Button>
      }
    >
      <Flex alignItems="center">
        <Text>{modalErrorData?.message ?? "Erro"}</Text>
      </Flex>
    </CustomModal>
  );
}

export function openModalError(options: ModalErrorOptions) {
  makeEvent("open-modal-error", options);
}
