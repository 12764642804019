export const dropdownBancos = [
  { name: "BANRISUL", value: "BANRISUL" },
  { name: "BMG", value: "BMG" },
  { name: "BRADESCO", value: "BRADESCO" },
  { name: "CETELEM", value: "CETELEM" },
  { name: "C6", value: "C6" },
  { name: "DAYCOVAL", value: "DAYCOVAL" },
  { name: "FACTA", value: "FACTA" },
  { name: "ITAÚ", value: "ITAU" },
  { name: "MASTER", value: "MASTER" },
  { name: "OLÉ", value: "OLE" },
  { name: "PAN", value: "PAN" },
  { name: "PAGBANK", value: "PAGBANK" },
  { name: "SAFRA", value: "SAFRA" },
  { name: "CREFISA", value: "CREFISA" },
];

export const dropdownBancosTabelas = [
  { name: "FACTA", value: "FACTA" },
  { name: "PAN", value: "PAN" },
  { name: "BMG", value: "BMG" },
  { name: "MASTER", value: "MASTER" },
];
