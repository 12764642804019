import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Text,
  Textarea,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import api from "api/api";
import { CustomModal } from "components/custom-modal";
import { Dropdown } from "components/dropdown";
import { CampanhaSMSRow } from "pages/campanha-sms";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { createEventListener, makeEvent } from "services/events";
import { fieldValidation } from "utils/field-validation";
import { objectSchema } from "utils/object-methods";
import { csvFields, firstCsvRow } from "./csv-cols";
import { toastDefaultStyle } from "chakra/theme";
import { InputField } from "components/input-field";

interface ModalDataProps extends CampanhaSMSRow {}

export function ModalCampanhaSMS({
  setCampanhas,
}: {
  setCampanhas: Dispatch<SetStateAction<CampanhaSMSRow[]>>;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState<Partial<ModalDataProps>>({});
  const toast = useToast();
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const { mensagemLength } = getMensagemLength();

  const { isValid, fieldsErrors, errors } = objectSchema(
    {
      nome: (value) => {
        const message = "Campo Obrigatório";
        const validation = fieldValidation({ value }).required({ message });
        return { valid: validation.isValid, message: validation.errorMessage };
      },
      mensagem: (value) => {
        const message = "Campo Obrigatório";
        const validation = fieldValidation({ value }).required({ message });
        return { valid: validation.isValid, message: validation.errorMessage };
      },
      quantidadeMaximaTelLead: (value) => {
        const message = "Campo Obrigatório";
        value = Number(value);
        const validation = fieldValidation({ value }).required({ message });
        return { valid: validation.isValid, message: validation.errorMessage };
      },
    },
    modalData
  );

  function getMensagemLength(mensagem: string = modalData.mensagem || "") {
    const textLength = mensagem.length;
    const uppercaseLength = textLength - mensagem.replace(/[A-Z]/g, "").length;
    const mensagemLength = textLength + uppercaseLength;
    return { mensagemLength, uppercaseLength };
  }

  const getPreVisualizacao = () => {
    let preVisualizacao = "";
    if (modalData.mensagem) preVisualizacao = modalData.mensagem;
    Object.keys(firstCsvRow).forEach((key) => {
      preVisualizacao = preVisualizacao?.replaceAll(
        `$${key}`,
        firstCsvRow[key]
      );
    });
    return preVisualizacao;
  };

  const onChangeModalData = (key: keyof ModalDataProps, data: any) => {
    setModalData({ ...modalData, [key]: data });
    setInvalidFields(invalidFields.filter((curr) => curr !== key));
  };

  const onOpen = (modalData: ModalDataProps) => {
    setIsOpen(true);
    setModalData(modalData);
  };
  const onClose = () => {
    setIsOpen(false);
    setModalData({});
    setInvalidFields([]);
  };

  createEventListener("openModalCampanhaSMS", onOpen);

  const handleSubmit = async (modalData: ModalDataProps) => {
    if (!isValid) return setInvalidFields(fieldsErrors);
    modalData.quantidadeMaximaTelLead = Number(
      modalData.quantidadeMaximaTelLead
    );
    modalData.mensagem = modalData.mensagem!.trim();
    modalData.nome = modalData.nome!.trim();
    const { id, nome, mensagem, quantidadeMaximaTelLead } = modalData;
    const mappedFields = csvFields
      .filter((field) => modalData.mensagem?.indexOf(`$${field}`) !== -1)
      .map((field) => `$${field}`);
    setIsLoading(true);
    try {
      const { data } = await api.put(`/campanha-sms/${modalData.id}`, {
        id,
        nome,
        mensagem,
        quantidadeMaximaTelLead,
        mappedFields,
      });

      setCampanhas((prev) =>
        prev.map((row) => {
          if (row.id === modalData.id) {
            row.statusSms = "AGUARDANDO INÍCIO";
            row.paused = true;
            row.mensagem = mensagem;
            row.nome = nome;
            row.quantidadeMaximaTelLead = quantidadeMaximaTelLead;
            row.waitingLength = data;
          }
          return row;
        })
      );
      onClose();
    } catch (e) {
      toast({
        title: "Erro ao salvar campanha",
        status: "error",
        ...toastDefaultStyle,
      });
    } finally {
      setCampanhas((prev) =>
        prev.map((campanha) => {
          if (campanha.id === modalData.id) campanha = modalData;
          return campanha;
        })
      );
      setIsLoading(false);
    }
  };

  const csvCols = csvFields.map((csvCol) => ({ name: csvCol, value: csvCol }));

  const modalFooter = (
    <>
      <Button
        isLoading={isLoading}
        leftIcon={<CheckIcon />}
        onClick={() => handleSubmit(modalData as ModalDataProps)}
        loadingText="Salvando"
      >
        Salvar
      </Button>
      <Button
        leftIcon={<CloseIcon w="11px" h="11px" />}
        onClick={onClose}
        variant="outline"
      >
        Cancelar
      </Button>
    </>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      size={"3xl"}
      modalTitle="Editar Campanha"
      modalFooter={modalFooter}
      onClose={onClose}
    >
      <Box>
        <Box>
          <InputField
            title="Nome da Campanha"
            value={modalData.nome}
            errorMessage={
              invalidFields.includes("nome") ? errors["nome"] : undefined
            }
            onChange={(e) => {
              onChangeModalData("nome", e.target.value);
            }}
          />
        </Box>

        <Grid templateColumns="1fr 1fr" mt="10px" w="100%" gap="20px">
          <Box>
            <Text mb="8px">Inserir coluna do arquivo na mensagem</Text>
            <Dropdown
              options={csvCols}
              w="100%"
              onChange={(value) =>
                modalData.mensagem
                  ? onChangeModalData(
                      "mensagem",
                      modalData.mensagem + `$${value}`
                    )
                  : onChangeModalData("mensagem", `$${value}`)
              }
            />
          </Box>
          <Box>
            <InputField
              title="Qtd. máxima de telefones por CPF"
              value={modalData.quantidadeMaximaTelLead}
              errorMessage={
                invalidFields.includes("quantidadeMaximaTelLead")
                  ? errors["quantidadeMaximaTelLead"]
                  : undefined
              }
              inputProps={{ type: "number" }}
              onChange={(e) => {
                onChangeModalData("quantidadeMaximaTelLead", e.target.value);
              }}
            />
          </Box>
          <Box>
            <Flex mb="8px" justifyContent="space-between">
              <Text>Informe a mensagem</Text>
              <Text
                color={
                  mensagemLength > 160
                    ? "var(--chakra-colors-secondary-400)"
                    : ""
                }
              >
                {mensagemLength}/160
              </Text>
            </Flex>
            <InputField
              value={modalData.mensagem}
              errorMessage={
                invalidFields.includes("mensagem")
                  ? errors["mensagem"]
                  : undefined
              }
              onChange={(e) => {
                const text = e.target.value;
                const { uppercaseLength } = getMensagemLength(text);
                const limit = 160 - uppercaseLength;
                onChangeModalData("mensagem", text.slice(0, limit));
              }}
              inputProps={{
                as: "textarea",
                h: "154px",
                p: "10px 16px",
                _focus: {
                  outline: "none",
                  boxShadow: "none",
                  borderStyle: "solid",
                  borderWidth: "1px",
                  borderColor: "var(--chakra-colors-custom-gray)",
                },
              }}
            />
          </Box>
          <Box>
            <Text mb="8px">
              Pré-visualização (utlizando um exemplo de contato do arquivo)
            </Text>
            <Textarea
              value={getPreVisualizacao()}
              disabled
              height="130px"
              autoComplete="new-password"
              borderStyle="solid"
              borderWidth="1px"
              borderColor="var(--chakra-colors-custom-gray)"
              _focus={{
                outline: "none",
                boxShadow: "none",
                borderStyle: "solid",
                borderWidth: "1px",
                borderColor: "var(--chakra-colors-custom-gray)",
              }}
              _hover={{ bg: "var(--chakra-colors-bg-gray)" }}
            />
          </Box>
        </Grid>
      </Box>
    </CustomModal>
  );
}

export const openModalCampanhaSMS = (row: CampanhaSMSRow) =>
  makeEvent("openModalCampanhaSMS", row);
