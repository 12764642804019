import { Icon, IconProps } from "@chakra-ui/react";

export function UploadIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="2"
      {...rest}
    >
      <path
        d="M16.0107 16L12.0107 12L8.01071 16"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21L12 12"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.88 18.09C21.7494 17.4786 22.4014 16.6061 22.7413 15.5991C23.0812 14.5921 23.0914 13.503 22.7704 12.4898C22.4494 11.4766 21.8139 10.5921 20.9561 9.96456C20.0983 9.33703 19.0628 8.99916 18 8.99999H16.74C16.4392 7.82787 15.8765 6.73924 15.0941 5.81607C14.3117 4.89291 13.3301 4.15925 12.2232 3.67035C11.1163 3.18144 9.91284 2.95002 8.70352 2.99351C7.4942 3.037 6.31051 3.35426 5.24155 3.92142C4.17259 4.48858 3.24622 5.29084 2.53218 6.26782C1.81814 7.2448 1.33505 8.37104 1.11925 9.56174C0.903465 10.7524 0.960605 11.9766 1.28637 13.142C1.61214 14.3074 2.19805 15.3838 2.99999 16.29"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
