import { Box, Center, Flex } from "@chakra-ui/react";
import { ReactNode, useEffect, useId, useRef } from "react";
import { getRelativeRect } from "utils/relative-rect";

interface PageDividerProps {
  pages: {
    title: string;
    icon?: JSX.Element | ReactNode;
    content: ReactNode;
  }[];
}

export function PageDivider({ pages }: PageDividerProps) {
  const id = useId();
  const lineRef = useRef<HTMLDivElement>(null);
  const containerTitleRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerGeralRef = useRef<HTMLDivElement>(null);

  const onChange = (i: number) => {
    const container = containerRef.current!;
    const containerGeral = containerGeralRef.current!;
    const containerTitle = containerTitleRef.current!;
    const line = lineRef.current!;
    container.style.transform = `translateX(calc(-${
      (i * 100) / pages.length
    }% - ${i * 16}px))`;
    const maxWLimitPerPage = containerGeral.clientWidth - 30;
    console.log(maxWLimitPerPage);
    for (let k = 0; k < container.children.length; k++) {
      const childPageContainer = container.children.item(k) as HTMLDivElement;
      const currTitle = containerTitle.children.item(k) as HTMLDivElement;
      const titleBlockRect = getRelativeRect(currTitle, containerTitle);
      childPageContainer.style.maxWidth = `${maxWLimitPerPage}px`;
      childPageContainer.style.minWidth = `${maxWLimitPerPage}px`;
      if (k === i) {
        childPageContainer.style.opacity = "1";
        currTitle.style.color = `var(--chakra-colors-primary-300)`;
        line.style.width = `${titleBlockRect.width}px`;
        line.style.transform = `translateX(${titleBlockRect.left}px)`;
      } else {
        childPageContainer.style.opacity = "0";
        currTitle.style.color = `black`;
      }
    }
  };

  useEffect(() => {
    if (containerTitleRef.current) onChange(0);
  }, []);

  return (
    <Box>
      <Flex ref={containerTitleRef} w="100%" pos="relative">
        {pages?.map(({ title, icon }, i) => (
          <Center
            key={`page-divider-header-${i}`}
            p="10px 12px 0px"
            height="60px"
            onClick={(e) => onChange(i)}
            cursor="pointer"
            justifyContent="flex-start"
            userSelect="none"
            bg="#fff"
            // borderTop="1px solid var(--chakra-colors-gray-200)"
            // borderLeft="1px solid var(--chakra-colors-gray-200)"
            // borderRight="1px solid var(--chakra-colors-gray-200)"
            // transform="translateY(1px)"
            borderRadius="6px 6px 0 0"
            mr="10px"
          >
            {icon ? <Center mr="7px">{icon}</Center> : null} {title}
          </Center>
        ))}
        <Box
          ref={lineRef}
          // px="4px"
          transition="0.4s"
          left="0"
          pos="absolute"
          bottom="0"
        >
          <Box w="100%" h="4px" borderRadius="6px" bg="primary.300" />
        </Box>
        <Box
          bg="var(--chakra-colors-gray-200)"
          pos="absolute"
          left="0"
          insetX="0"
          bottom="0"
          as="hr"
        />
      </Flex>
      <Flex
        ref={containerGeralRef}
        mt="10px"
        w="100%"
        overflow="hidden"
        border="1px solid var(--chakra-colors-gray-200)"
        borderRadius="6px"
        p="10px 15px"
      >
        <Flex w={`${pages.length * 100}%`} ref={containerRef} transition="0.4s">
          {pages.map(({ content }, i) => {
            const isLast = i + 1 === pages.length;
            return (
              <Box
                mr={isLast ? "0" : "16px"}
                transition="0.3s"
                key={`page-divider-${id}-page-${i}`}
                minW="100%"
                maxW="100%"
              >
                {content}
              </Box>
            );
          })}
        </Flex>
      </Flex>
    </Box>
  );
}
