import { useToast } from "@chakra-ui/react";
import { api3cPlus } from "api/wss-c3plus";
import { toastDefaultStyle } from "chakra/theme";
import { openModal3cPlusCampanhas } from "components/atendimentos-components/modal-3cplus-campanhas";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useReducer,
} from "react";
import {
  disconnect3cPlusStomp,
  get3CplusAuthenticatedUser,
  logoutCampaign,
  use3cPlusWSConnection,
} from "services/api3cplus";
import { useApplicationContext } from "./ApplicationContext";
import {
  Controls3cPlusActions,
  Context3cPlusProps,
  Controls3cPlusProps,
} from "./types/context-3cplus-types";
import { getExportedVariable } from "services/get-external-function";

export const initialControls3cPlus: Controls3cPlusProps = {
  stompClientInstance: null,
  lastEventName: null,
  isManualMode: false,
  idCall: null,
  inCall: false,
  isMuted: false,
  campaign: { isLogged: false },
  hangingUpCall: false,
};
const initialValues: Context3cPlusProps = {
  deslogarCampanha: async () => undefined,
  controls3cPlus: initialControls3cPlus,
  dispatch3cPlus: () => undefined,
};

export let current3cplusControls = initialControls3cPlus;

function reducer3cPlus(
  state: Controls3cPlusProps,
  action: Controls3cPlusActions
): Controls3cPlusProps {
  const { type, payload } = action;
  const session = getExportedVariable("session-3cplus", { throwsError: false });
  let result: Controls3cPlusProps;
  switch (type) {
    case "inManualMode":
      result = { ...state, isManualMode: true };
      break;
    case "notInManualMode":
      result = { ...state, isManualMode: false };
      break;
    case "setIdCall":
      result = { ...state, idCall: payload };
      break;
    case "setStatus":
      result = { ...state, lastEventName: payload };
      break;
    case "inCall":
      result = { ...state, inCall: true, idCall: payload.idCall };
      break;
    case "notInCall":
      const idCall = payload?.clearIdCall ? null : state.idCall;
      result = { ...state, inCall: false, idCall, hangingUpCall: false };
      break;
    case "mute":
      session?.mute?.();
      const mute = session ? true : state.isMuted;
      result = { ...state, isMuted: mute };
      break;
    case "unMute":
      session?.unmute?.();
      const unmute = session ? false : state.isMuted;
      result = { ...state, isMuted: unmute };
      break;
    case "campaignLogin":
      result = {
        ...state,
        campaign: { isLogged: true, campaignName: payload },
      };
      break;
    case "campaignLogout":
      result = { ...initialControls3cPlus };
      break;
    case "hangingUpCall":
      result = { ...state, hangingUpCall: true };
      break;
    case "notHangingUpCall":
      result = { ...state, hangingUpCall: false };
      break;
    default:
      result = state;
  }
  current3cplusControls = result;
  return result;
}

const context3cPlus = createContext<Context3cPlusProps>(initialValues);

export const Context3cPlusProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [controls3cPlus, dispatch3cPlus] = useReducer(
    reducer3cPlus,
    initialControls3cPlus
  );
  const toast = useToast(toastDefaultStyle);
  const ws3cPlus = use3cPlusWSConnection({ dispatch3cPlus });
  const { user } = useApplicationContext();
  const userData = user.userData;

  const deslogarCampanha = async () => {
    try {
      if (controls3cPlus.campaign.isLogged) {
        await api3cPlus.post(`/3cplus/agent/logout`);
        dispatch3cPlus({ type: "campaignLogout" });
      } else openModal3cPlusCampanhas();
    } catch (e: any) {
      const errorMessage = e?.response?.data?.detail;
      if (errorMessage === "O agente já está desconectado") {
        dispatch3cPlus({ type: "campaignLogout" });
      } else toast({ title: errorMessage ?? "Erro ao deslogar campanha" });
    } finally {
    }
  };

  useEffect(() => {
    if (userData.enabled3cplus) {
      get3CplusAuthenticatedUser().then((response) => console.log(response));
      return () => {
        disconnect3cPlusStomp();
        logoutCampaign();
      };
    }
  }, []);

  useEffect(() => {
    if (userData.enabled3cplus) {
      window.onbeforeunload = (e) => {
        e.preventDefault();
        deslogarCampanha();
        (e || window.event).returnValue =
          "Você será deslogado da campanha se sair da página.";
        return "Você será deslogado da campanha se sair da página.";
      };
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [controls3cPlus.campaign]);

  return (
    <context3cPlus.Provider
      value={{ deslogarCampanha, controls3cPlus, dispatch3cPlus }}
    >
      {children}
    </context3cPlus.Provider>
  );
};

export const use3cPlusContext = () => {
  const context = useContext(context3cPlus);
  if (context === undefined)
    throw new Error(
      "useApplicationContext must be used within a ApplicationContextProvider"
    );
  return context;
};
