import { Flex, useToast } from "@chakra-ui/react";
import DynamicTable from "components/dynamic-table";
import { Column } from "components/dynamic-table/types";
import { CreditIcon } from "components/vectors/credit-icon";
import { DebitIcon } from "components/vectors/debit-icon";
import { useApplicationContext } from "contexts/ApplicationContext";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { createEventListener, makeEvent } from "services/events";
import { PartnersSearch } from "../partners-search";
import apiCarteira from "api/api-carteira";
import { toastDefaultStyle } from "chakra/theme";
import { SelectedCustomer } from "pages/carteira";

interface Transation {
  descricao: string;
  valor: number;
  data: string;
  tipoValor: "CREDITO" | "DEBITO";
}

interface ChildUser {
  customer: {
    cnpj: string | null;
    codigoNova: null;
    comercialUser: null;
    creditoApiBmgCartaoSaque: null;
    creditoApiInssHistOff: null;
    email: string | null;
    enabled: boolean;
    enabled3cplus: null;
    id: number;
    name: string;
    regionalCustomer: null;
    telefone: null;
    token3cplus: null;
  };
  enabled: boolean;
  enabledWabox: boolean;
  foneWabox: string;
  id: number;
  name: string;
  password: null;
  type: string;
  userId3cplus: number;
  username: string;
}

export function WalletTable({
  setSaldo,
  setSelectedCustomer,
  selectedCustomer,
}: {
  setSelectedCustomer: Dispatch<SetStateAction<SelectedCustomer>>;
  selectedCustomer: SelectedCustomer;
  setSaldo: Dispatch<SetStateAction<string>>;
}) {
  const [isLoading, setLoading] = useState(false);
  const [transations, setTransations] = useState<Transation[]>([]);
  const toast = useToast();

  const getData = async ({
    id,
    name,
    codigoNova,
  }: {
    id: number;
    name: string;
    codigoNova: string;
  }) => {
    setLoading(true);
    try {
      const { data } = await apiCarteira.get(`/carteiras/extrato/${id}`);
      setSelectedCustomer({ id, name, codigoNova });
      setTransations(data.transacoes);
      setSaldo(data.saldo.toFixed(2));
    } catch (e) {
      toast({
        title: "Não foi possível obter dados",
        status: "error",
        ...toastDefaultStyle,
      });
      setSaldo(Number(0).toFixed(2));
      setTransations([]);
    } finally {
      setLoading(false);
    }
  };

  createEventListener("update-wallet-table", getData);

  const columns: Column[] = [
    {
      name: "Data",
      render: (row: Transation) => row.data,
    },
    {
      name: "Descrição",
      render: (row: Transation) => row.descricao,
    },
    {
      name: <Flex ml="auto">Valor (R$)</Flex>,
      render: (row: Transation, index) => {
        const isCredit = row.tipoValor === "CREDITO";
        return (
          <Flex justifyContent="flex-end">
            {isCredit ? (
              <CreditIcon display="block" mr="4px" />
            ) : (
              <DebitIcon display="block" mr="4px" />
            )}{" "}
            {row.valor.toFixed(2).replace("-", "")}
          </Flex>
        );
      },
      key: "valor",
      cellStyle: () => ({ textAlign: "end" }),
    },
  ];

  return (
    <Flex flexDir="column">
      <PartnersSearch selectedCustomer={selectedCustomer} />
      <DynamicTable
        columns={columns}
        rows={transations}
        isLoading={isLoading}
        headerStyle={(header) => ({
          textAlign: header.key === "valor" ? "end" : "center",
        })}
        containerStyle={{ boxShadow: "0 0 1em rgba(0,0,0,0.08)" }}
      />
    </Flex>
  );
}

export const updateWalletTable = (data: SelectedCustomer) =>
  makeEvent("update-wallet-table", data);
