import { CloseIcon } from "@chakra-ui/icons";
import { Button, useToast } from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { CustomModal } from "components/custom-modal";
import DynamicTable from "components/dynamic-table";
import { Column } from "components/dynamic-table/types";
import { DocumentIcon } from "components/vectors/document-icon";
import { useState } from "react";

export function ButtonAcompanharProposta({
  attendance,
}: {
  attendance: Attendance;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const toast = useToast({ ...toastDefaultStyle });

  const onOpen = async () => setIsOpen(true);

  const onClose = () => setIsOpen(false);

  const handleConsultar = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(
        `facta/fgts/consultar-andamento/${attendance.id}`
      );
      onOpen();
      setStatusList(data);
    } catch (e) {
      toast({
        title: "Erro ao consultar o acompanhamento da proposta",
        description: "Tente novamente",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const columns: Column[] = [
    {
      name: "Data",
      render: (row) => {
        return row.datetime;
      },
    },
    {
      name: "Status",
      render: (row) => {
        return row.status;
      },
    },
  ];

  const modalFooter = (
    <>
      <Button
        variant="outline"
        leftIcon={<CloseIcon w="12px" h="12px" />}
        onClick={onClose}
      >
        Fechar
      </Button>
    </>
  );

  return (
    <>
      <Button
        w="100%"
        onClick={handleConsultar}
        isDisabled={!attendance?.atendimentoFactaFGTS?.ade}
        rightIcon={<DocumentIcon width="22px" height="22px" />}
        isLoading={isLoading}
        loadingText="Consultando"
      >
        Acompanhar Proposta
      </Button>
      <CustomModal
        modalTitle="Acompanhar Status Digitação"
        isOpen={isOpen}
        onClose={onClose}
        modalFooter={modalFooter}
        size="3xl"
      >
        <DynamicTable
          columns={columns}
          rows={statusList}
          isLoading={isLoading}
        />
      </CustomModal>
    </>
  );
}
