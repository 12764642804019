import { Dispatch, SetStateAction } from "react";
import {
  CheckFieldProps,
  MailingFilterArray,
  MailingFilterProps,
} from "./types";
import { InputFilter } from "./mailing-filter/filters-components/input-filter";
import { currencyMaskFormat, integerInputFormat } from "utils/string-formats";
import { CheckboxesFilter } from "./mailing-filter/filters-components/checkboxes";
import { KeyWordsFilter } from "./mailing-filter/filters-components/key-words";
import { BooleanRadioFilter } from "./mailing-filter/filters-components/boolean-radio";
import { BankListSearch } from "./mailing-filter/filters-components/bank-list-search";
import { banks } from "./mailing-filter/consts";

const ufList = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MS",
  "MT",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

export const especieCodeList = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 15, 18, 19, 20, 21, 22, 23, 24, 25,
  26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44,
  45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63,
  64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82,
  83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
];

export const especieCodeListConsignaveis = [
  1, 2, 3, 4, 5, 6, 7, 8, 18, 19, 20, 21, 22, 23, 24, 26, 27, 28, 29, 32, 33,
  34, 37, 38, 41, 42, 43, 44, 45, 46, 49, 51, 52, 54, 55, 56, 57, 58, 59, 60,
  72, 78, 81, 82, 83, 84, 87, 88, 89, 92, 93, 96,
];

const especieCodeListNaoConsignaveis = especieCodeList.filter(
  (curr) => !especieCodeListConsignaveis.includes(curr)
);

const especieCodeListTradicionais = [21, 41, 42, 46, 57, 93];

const especieCodeListNaoTradicionais = [
  1, 2, 3, 7, 8, 9, 20, 22, 23, 24, 26, 27, 28, 37, 38, 43, 45, 49, 52, 54, 55,
  56, 58, 59, 60, 78, 81, 84, 89, 96,
];

const especieCodeListInvalidez = [4, 5, 6, 32, 33, 34, 51, 83, 92];

const tiposEmprestimos = [98, 76, 44];

export const conveniosOptions = [
  { name: "INSS", value: "INSS" },
  { name: "Prefeitura", value: "pref" },
  { name: "Governo", value: "gov" },
];

const especieHeaderOptions = [
  {
    name: "Todos",
    options: especieCodeList,
  },
  {
    name: "Todos Consignáveis",
    options: especieCodeListConsignaveis,
  },
  {
    name: "Não Consignáveis",
    options: especieCodeListNaoConsignaveis,
  },
  {
    name: "Tradicionais",
    options: especieCodeListTradicionais,
  },
  {
    name: "Não Tradicionais",
    options: especieCodeListNaoTradicionais,
  },
  {
    name: "Invalidez",
    options: especieCodeListInvalidez,
  },
];

function checkBetween(values: any[] | undefined): CheckFieldProps {
  const includeEmptyFields =
    values?.includes(undefined) || values?.includes("");
  if (values?.length === 2 && !includeEmptyFields) {
    if (values?.[0] <= values?.[1]) return { isValid: true };
    else
      return {
        isValid: false,
        errorMessage: "O primeiro valor deve ser menor ou igual ao segundo",
      };
  } else {
    return { isValid: false, errorMessage: "Prencha os campos corretamente" };
  }
}

function checkBetweenDates(values: any[] | undefined): CheckFieldProps {
  const includeEmptyFields =
    values?.includes(undefined) || values?.includes("");
  if (values?.length === 2 && !includeEmptyFields) {
    if (new Date(values[0]).getTime() <= new Date(values[1]).getTime())
      return { isValid: true };
    else
      return {
        isValid: false,
        errorMessage: "Período inválido",
      };
  } else {
    return { isValid: false, errorMessage: "Prencha os campos corretamente" };
  }
}

function checkUniqueField(values: any[] | undefined): CheckFieldProps {
  if (values?.[0] != null) return { isValid: true };
  else return { isValid: false, errorMessage: "Campo Obrigatório" };
}

export function mailingFilters({
  onChangeFilter,
  setInvalidFields,
  filtersValues,
  invalidFields,
}: {
  onChangeFilter: MailingFilterProps["onChangeFilter"];
  filtersValues: MailingFilterProps["filtersValues"];
  invalidFields: { [k: string]: string };
  setInvalidFields: Dispatch<SetStateAction<{ [k: string]: string }>>;
}): MailingFilterArray[] {
  return [
    {
      key: "salario",
      isVisible: (convenio) => ["INSS", "pref", "gov"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      render: (
        <InputFilter
          title="Salário"
          description="Valor do salário do lead ou servidor"
          mapKey="salario"
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          formatValue={currencyMaskFormat}
        />
      ),
    },
    {
      key: "idade",
      isVisible: (convenio) => ["gov", "pref", "INSS"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      render: (
        <InputFilter
          title="Idade"
          description="Idade do servidor ou lead"
          mapKey="idade"
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          inputProps={{ type: "number" }}
        />
      ),
    },
    {
      key: "ddb",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      checkField: (operation, values) => {
        const operations: { [k: string]: CheckFieldProps } = {
          between: checkBetweenDates(values),
          equal: checkUniqueField(values),
        };
        return operations[operation];
      },
      render: (
        <InputFilter
          title="DDB - Início do Benefício (despacho do benefício)"
          description="Período do despacho do benefício"
          mapKey="ddb"
          operators={{ Range: "between", Igual: "equal" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          inputProps={{ type: "month" }}
          formatValue={(value) => ({ value, raw: value })}
        />
      ),
    },
    {
      key: "representante_legal",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      render: (
        <BooleanRadioFilter
          title="Possui Representante Legal"
          description="Se o beneficiario tem cartão ou não"
          mapKey="representante_legal"
          operators={{ Sim: 1, Não: 0 }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          parseValue={{ 0: "in", 1: "in" }}
        />
      ),
    },
    {
      key: "bancoPag",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      checkField: (operation, values) => {
        const isValid = values?.length > 0;
        const errorMessage = "Adicione pelo menos um código de banco";
        return { isValid, errorMessage: !isValid ? errorMessage : undefined };
      },
      render: (
        <BankListSearch
          title="Banco Pagador"
          description="Código do banco que o lead recebe o benefício (Banco pagador)"
          mapKey="bancoPag"
          operators={{ Igual: "in", Diferente: "not_in" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          inputProps={{ placeholder: "Pesquise por bancos" }}
          options={banks}
        />
      ),
    },
    {
      key: "possui_cartao",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      render: (
        <BooleanRadioFilter
          title="Possui Cartão RMC"
          description="Se o beneficiario tem cartão ou não"
          mapKey="possui_cartao"
          operators={{ Sim: 1, Não: 0 }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          parseValue={{ 0: "in", 1: "in" }}
        />
      ),
    },
    {
      key: "margem",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      render: (
        <InputFilter
          title="Margem Disponível"
          description="Valor da margem do lead disponível para consignação"
          mapKey="margem"
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          formatValue={currencyMaskFormat}
        />
      ),
    },
    {
      key: "margem_rmc",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      render: (
        <InputFilter
          title="Margem RMC"
          description="Valor da margem  para cartão do lead disponível para consignação"
          mapKey="margem_rmc"
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          formatValue={currencyMaskFormat}
        />
      ),
    },
    {
      key: "especie",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      render: (
        <CheckboxesFilter
          title="Espécie"
          description="Código da espécie do benefício recebido pelo lead"
          mapKey="especie"
          operators={{ Igual: "in" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          options={especieCodeList}
          headerOptions={especieHeaderOptions}
        />
      ),
    },
    {
      key: "uf",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      render: (
        <CheckboxesFilter
          title="UF"
          description="Estado do endereço dos leads"
          mapKey="uf"
          operators={{ Igual: "in" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          options={ufList}
          headerOptions={[{ name: "Todos", options: ufList }]}
        />
      ),
    },
    {
      key: "cidade",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      checkField: (operation, values) => checkUniqueField(values),
      render: (
        <KeyWordsFilter
          title="Cidade"
          description="Município do endereço dos leads"
          mapKey="cidade"
          operators={{ Igual: "in", Diferente: "not_in" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          inputProps={{ placeholder: "Adicionar cidades" }}
        />
      ),
    },
    {
      key: "bancoOp",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      checkField: (operation, values) => {
        const isValid = values?.length > 0;
        const errorMessage = "Adicione pelo menos um código de banco";
        return { isValid, errorMessage: !isValid ? errorMessage : undefined };
      },
      render: (
        <BankListSearch
          title="Banco Operação"
          description="Código do banco da parcela contratada pelo cliente (Banco da operação)"
          mapKey="bancoOp"
          operators={{ Igual: "in", Diferente: "not_in" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          inputProps={{ placeholder: "Pesquise por bancos" }}
          options={banks}
        />
      ),
    },
    {
      key: "taxa",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      render: (
        <InputFilter
          title="Taxa Contrato"
          description="Filtro taxa contrato ativos"
          mapKey="taxa"
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          formatValue={currencyMaskFormat}
        />
      ),
    },
    {
      key: "vl_parcelas",
      isVisible: (convenio) => ["gov", "pref", "INSS"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      render: (
        <InputFilter
          title="Valor das Parcelas"
          description="Valor das parcelas dos contratos"
          mapKey="vl_parcelas"
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          formatValue={integerInputFormat}
        />
      ),
    },
    {
      key: "quantidade_parcela_paga",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      render: (
        <InputFilter
          title="Quantidade de Parcelas Pagas"
          description="Filtro de quantidade de parcelas pagas"
          mapKey="quantidade_parcela_paga"
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          formatValue={integerInputFormat}
        />
      ),
    },
    {
      key: "qt_emprestimos",
      isVisible: (convenio) => ["INSS"].includes(convenio),
      checkField: (operation, values) => checkBetween(values),
      render: (
        <InputFilter
          title="Quantidade de Empréstimos"
          description="Filtro quantidade de empréstimos"
          mapKey="qt_emprestimos"
          operators={{ Range: "between" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          formatValue={integerInputFormat}
        />
      ),
    },
    {
      key: "uf_servidor",
      isVisible: (convenio) => ["gov", "pref"].includes(convenio),
      checkField: (operation, values) => checkUniqueField(values),
      render: (
        <KeyWordsFilter
          title="UF Servidor"
          description="Estado do Endereço dos Servidores"
          mapKey="uf_servidor"
          operators={{ Igual: "in", Diferente: "not_in" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
          inputProps={{ placeholder: "Adicione UFs ex.: SP" }}
        />
      ),
    },
    {
      key: "municipio_servidor",
      isVisible: (convenio) => ["gov", "pref"].includes(convenio),
      checkField: (operation, values) => checkUniqueField(values),
      render: (
        <KeyWordsFilter
          title="Município Servidor"
          description="Município Servidor"
          mapKey="municipio_servidor"
          operators={{ Igual: "in", Diferente: "not_in" }}
          onChangeFilter={onChangeFilter}
          filtersValues={filtersValues}
          invalidFields={invalidFields}
        />
      ),
    },
  ];
}
