import { Icon } from "@chakra-ui/react";

export function CalendarIcon({ ...rest }) {
  return (
    <Icon
      width="29px"
      height="27px"
      viewBox="0 0 29 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21.7516 4.5H7.49226C4.86718 4.5 2.73914 6.51472 2.73914 9V20.25C2.73914 22.7353 4.86718 24.75 7.49226 24.75H21.7516C24.3767 24.75 26.5048 22.7353 26.5048 20.25V9C26.5048 6.51472 24.3767 4.5 21.7516 4.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.73914 11.25H26.5048M9.86882 2.25V6.75V2.25ZM19.3751 2.25V6.75V2.25Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
