import { Flex, Text } from "@chakra-ui/react";
import DynamicTable from "components/dynamic-table";
import { Column } from "components/dynamic-table/types";
import { useAttendanceContext } from "contexts/AttendanceContext";
import { formatData } from "utils/string-formats";
import { bancosContratos, tiposEmprestimos } from "../contracts-table/consts";

export function ContractsRMCTable() {
  const { formControl } = useAttendanceContext();
  const { contratosRMCInss: contratosCartaoRMCInss } = formControl.values;
  const showTable = !!contratosCartaoRMCInss?.length;

  const columns: Column[] = [
    {
      name: "Banco",
      render: ({ codBanco }) => {
        if (codBanco) return `${codBanco} - ${bancosContratos[codBanco]}`;
      },
    },
    {
      name: "Contrato",
      key: "contrato",
    },
    {
      name: "Tipo de Empréstimo",
      render: ({ codTipoEmprestimo }) => {
        return tiposEmprestimos[codTipoEmprestimo];
      },
    },
    {
      name: "Limite Cartão",
      render: ({ limiteCartao }) => {
        if (limiteCartao) return `R$ ${limiteCartao}`;
      },
    },
    {
      name: "Início Contrato",
      render: ({ dataInicioContrato }) => {
        if (dataInicioContrato) return formatData(dataInicioContrato);
      },
    },
    {
      name: "Valor Reservado",
      render: ({ valorReservado }) => {
        if (valorReservado)
          return <Text color="#0c0">{`R$ ${valorReservado}`}</Text>;
      },
    },
    {
      name: "Situação",
      render: ({ situacao }) => {
        return <Text color="#0c0">{situacao}</Text>;
      },
    },
  ];

  return showTable ? (
    <Flex flexDirection="column">
      <Text fontSize="20" ml="0px" mt="0px" fontWeight="500">
        Contratos Cartão RMC
      </Text>
      <hr style={{ margin: "5px 0 20px 0px" }} />
      <DynamicTable
        rows={contratosCartaoRMCInss || []}
        columns={columns}
        containerStyle={{ boxShadow: "0 0 1em rgba(0,0,0, 0.05)", pb: "0" }}
        sortBy="valorParcelaSelecionado"
        sortOrder="DESC"
        emptyMessage="Usuário não fez contratos."
        // lastRow={lastRow}
        headerStyle={() => ({
          whiteSpace: "normal",
          textAlign: "center",
          py: "10px",
        })}
        isLoading={false}
      />
    </Flex>
  ) : null;
}
