import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Flex,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import {
  Attendance,
  Product,
} from "components/atendimentos-components/atendimento-form/types";
import DynamicTable from "components/dynamic-table";
import { Column } from "components/dynamic-table/types";
import { ConfirmDialog, ConfirmDialogData } from "components/modal-confirm";
import { useAttendanceContext } from "contexts/AttendanceContext";
import { useEffect, useState } from "react";
import { sumFields } from "./functions";
import { ProductModal } from "./product-modal";

export function ProductTable() {
  const { formControl, dispatch } = useAttendanceContext();
  const [confirmDialogData, setConfirmDialogData] =
    useState<ConfirmDialogData | null>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [updateValues, setUpdateValues] = useState<{
    updatedItem: Product;
    index: number;
  } | null>(null);

  const attendance = formControl.values as Attendance;
  const { produtosFgts, convenio } = attendance;
  const showTable = convenio === "ANTECIPACAO_FGTS" && !!produtosFgts?.length;

  function openModalConfirm(index: number) {
    setConfirmDialogData({
      message: `Deseja excluir este produto "Ano ${index + 1}" ?`,
      accept: () => dispatch({ type: "deleteProduct", payload: index }),
      close: () => setConfirmDialogData(null),
      acceptButtonStyle: {
        variant: "danger",
        leftIcon: <DeleteIcon />,
      },
    });
  }

  useEffect(() => {
    if (!isOpen) setUpdateValues(null);
  }, [isOpen]);

  const [sumLiberado, sumParcela] = sumFields(produtosFgts, [
    "valorLiberadoSelecionado",
    "valorParcelaSelecionado",
  ]);

  const columns: Column[] = [
    {
      name: "Ano",
      key: "ano",
      render: (row, index) => `Ano ${index + 1}`,
    },
    {
      name: "Valor do Saque",
      key: "valorLiberadoSelecionado",
      render: (row) => `R$ ${row.valorLiberadoSelecionado}`,
    },
    {
      name: "Valor do Saque Antecipado",
      key: "valorParcelaSelecionado",
      render: (row, index) => `R$ ${row.valorParcelaSelecionado}`,
    },
    {
      name: "Ações",
      render: (row, index) =>
        produtosFgts?.length !== index ? (
          <>
            <IconButton
              aria-label="Deletar Produto"
              icon={<DeleteIcon w="13px" h="13px" />}
              variant="danger"
              minW="25px"
              h="25px"
              p="0"
              mr="5px"
              onClick={() => openModalConfirm(index)}
            />
            <IconButton
              aria-label="Atualizar Produto"
              icon={<EditIcon w="13px" h="13px" />}
              minW="25px"
              h="25px"
              p="0"
              onClick={() => {
                onOpen();
                setUpdateValues({ updatedItem: row, index });
              }}
            />
          </>
        ) : null,
    },
  ];

  const addButton = (
    <Tooltip label="Adicionar Produto" bg="gray.200" color="text">
      <IconButton
        aria-label="Adicionar Produto"
        icon={<AddIcon w="13px" h="13px" />}
        minW="25px"
        h="25px"
        p="0"
        bg="transparent"
        border="1px solid rgba(0, 2, 62, 0.5)"
        color="text"
        _hover={{ bg: "gray.200" }}
        onClick={onOpen}
      />
    </Tooltip>
  );

  const lastRow = {
    ano: "",
    valorLiberadoSelecionado: ``,
    valorParcelaSelecionado: `TOTAL: R$ ${sumParcela}`,
  };

  return showTable ? (
    <>
      <Flex flexDir="column">
        <Text fontSize="20" ml="0px" mt="25px" fontWeight="500">
          Produtos FGTS
        </Text>
        <hr style={{ margin: "5px 0 20px 0px" }} />
        <DynamicTable
          rows={produtosFgts || []}
          columns={columns}
          containerStyle={{ boxShadow: "0 0 1em rgba(0,0,0, 0.05)", pb: "0" }}
          sortBy="valorParcelaSelecionado"
          sortOrder="DESC"
          headerButton={addButton}
          emptyMessage="Usuário não adquiriu produtos."
          lastRow={lastRow}
        />
      </Flex>
      {isOpen ? (
        <ProductModal
          isOpen={isOpen}
          onClose={onClose}
          initialValues={updateValues}
        />
      ) : null}
      <ConfirmDialog dialogData={confirmDialogData} />
    </>
  ) : null;
}
