import { GetLayout } from "components/get-layout";
import { TebelasComissaoPage } from "components/tabelas-digitacao-components/tebelas-comissao-page";

export function TebelasComissao() {
  return (
    <GetLayout>
      <TebelasComissaoPage />
    </GetLayout>
  );
}
