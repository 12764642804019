import { userTypeRedirect } from "components/login-page/functions";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";

export function IndexPage() {
  const { user } = useApplicationContext();
  const router = useHistory();

  useEffect(() => {
    router.push(userTypeRedirect(user));
  }, []);

  return <></>;
}
