import { Icon, IconProps } from "@chakra-ui/react";

export function ChevronUpDownIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="12px"
      height="20px"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1.40998 7.41L5.99998 2.83L10.59 7.41L12 6L5.99998 3.8147e-06L-1.52588e-05 6L1.40998 7.41Z"
        fill="currentColor"
      />
      <path
        d="M10.59 12L5.99995 16.58L1.40995 12L-4.57764e-05 13.41L5.99995 19.41L12 13.41L10.59 12Z"
        fill="currentColor"
      />
    </Icon>
  );
}
