import { RepeatIcon } from "@chakra-ui/icons";
import { Button, Flex, Text } from "@chakra-ui/react";
import { GetLayout } from "components/get-layout";
import { GenerateMailingModal } from "components/mailing-components/mailing-modal-filter";
import { MailingOptionsModal } from "components/mailing-components/mailing-options-modal";
import { MailingsTable } from "components/mailing-components/mailing-table";
import { MailingUploadModal } from "components/mailing-components/mailing-upload-modal";
import { SendForCleaningModal } from "components/mailing-components/send-for-cleaning-modal";
import { ContactListIcon } from "components/vectors/contact-list-icon";
import { UploadIcon } from "components/vectors/upload-icon";
import { useState } from "react";
import { makeEvent } from "services/events";

export function MailingPage() {
  const [loading, setLoading] = useState(false);
  return (
    <GetLayout>
      <Flex mb="30px" alignItems="center" justifyContent="space-between">
        <Text fontSize="25" fontWeight="bold">
          Mailing
        </Text>
        <Flex>
          <Button
            mr="8px"
            leftIcon={<ContactListIcon />}
            onClick={() => makeEvent("open-mailing-filter")}
          >
            Gerar Mailing
          </Button>
          <Button
            mr="8px"
            onClick={() => makeEvent("open-mailing-upload")}
            leftIcon={<UploadIcon />}
          >
            Upload Mailing
          </Button>
          <Button
            onClick={() => makeEvent("update-mailings-table")}
            leftIcon={<RepeatIcon />}
            isLoading={loading}
            loadingText="Atualizando"
          >
            Atualizar
          </Button>
        </Flex>
      </Flex>
      <MailingsTable setLoading={setLoading} loading={loading} />
      <GenerateMailingModal />
      <MailingUploadModal />
      <MailingOptionsModal />
      <SendForCleaningModal />
    </GetLayout>
  );
}
