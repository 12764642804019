import {
  Box,
  Center,
  Grid,
  IconButton,
  Input,
  InputProps,
  Text,
} from "@chakra-ui/react";
import { Dropdown, DropdownProps } from "components/dropdown";
import { MailingOptionFilter } from ".";
import { SetStateAction, useEffect, useState } from "react";
import { CleanIcon } from "components/vectors/clean-icon";

type FilterProps =
  | {
      type: "between";
      addedFilters: MailingOptionFilter;
      setAddedFilters: (
        newValue: SetStateAction<MailingOptionFilter>,
        key: string
      ) => void;
      valueType?: "number";
      componentProps?: { gte?: InputProps; lte?: InputProps };
      filterKey: string;
      isDisabled?: boolean;
      mask?: (value: any) => { raw: any; value: any };
      isChecked?: (value: any) => boolean;
    }
  | {
      type: "dropdown";
      addedFilters: MailingOptionFilter;
      setAddedFilters: (
        newValue: SetStateAction<MailingOptionFilter>,
        key: string
      ) => void;
      valueType?: "text";
      componentProps?: DropdownProps & { asyncOptions?: () => Promise<any> };
      filterKey: string;
      isDisabled?: boolean;
      mask?: (value: any) => { raw: any; value: any };
      isChecked?: (value: any) => boolean;
    }
  | {
      type: "input";
      addedFilters: MailingOptionFilter;
      setAddedFilters: (
        newValue: SetStateAction<MailingOptionFilter>,
        key: string
      ) => void;
      valueType?: "text" | "number";
      componentProps?: InputProps;
      filterKey: string;
      isDisabled?: boolean;
      mask?: (value: any) => { raw: any; value: any };
      isChecked?: (value: any) => boolean;
    };

export const Filter = ({
  type,
  addedFilters,
  setAddedFilters,
  valueType = "text",
  componentProps,
  filterKey,
  isDisabled,
  mask,
  isChecked,
}: FilterProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<undefined | DropdownProps["options"]>(
    undefined
  );

  useEffect(() => {
    const props = componentProps as any;
    if (props?.asyncOptions) {
      const getData = async () =>
        setOptions(await props.asyncOptions(setIsLoading));
      getData();
    }
  }, []);

  if (type === "between") {
    const valueGte = addedFilters[filterKey].value.gte;
    const valueLte = addedFilters[filterKey].value.lte;
    const gte = mask?.(valueGte).value ?? valueGte ?? "";
    const lte = mask?.(valueLte).value ?? valueLte ?? "";

    return (
      <Grid templateColumns="1fr auto 1fr">
        <Input
          onChange={({ target: { value } }) => {
            const filterValue = addedFilters[filterKey].value;
            const parseValue =
              valueType === "number"
                ? value.length
                  ? Number(value)
                  : ""
                : mask?.(value).raw ?? value;
            setAddedFilters((addedFilters) => {
              const isChecked =
                !!`${parseValue}` && !!`${filterValue?.lte ?? ""}`;
              return {
                ...addedFilters,
                [filterKey]: {
                  ...addedFilters[filterKey],
                  value: { ...filterValue, gte: parseValue },
                  isChecked,
                },
              };
            }, filterKey);
          }}
          value={`${gte}` || ""}
          type={valueType}
          disabled={isDisabled}
          {...componentProps?.gte}
        />
        <Text mx="4px" my="auto">
          a
        </Text>
        <Input
          onChange={({ target: { value } }) => {
            const filterValue = addedFilters[filterKey].value;
            const parseValue =
              valueType === "number"
                ? value.length
                  ? Number(value)
                  : ""
                : mask?.(value).raw ?? value;
            setAddedFilters((addedFilters) => {
              const isChecked =
                !!`${parseValue}` && !!`${filterValue?.gte ?? ""}`;
              return {
                ...addedFilters,
                [filterKey]: {
                  ...addedFilters[filterKey],
                  value: { ...filterValue, lte: parseValue },
                  isChecked,
                },
              };
            }, filterKey);
          }}
          value={`${lte}` || ""}
          type={valueType}
          disabled={isDisabled}
          {...componentProps?.lte}
        />
      </Grid>
    );
  }
  if (type === "input") {
    const currentValue = addedFilters[filterKey].value;
    const value = mask?.(currentValue).value ?? currentValue;
    return (
      <Box>
        <Input
          onChange={({ target: { value } }) => {
            const maskParseValue = mask?.(value).raw ?? value;
            const parseValue =
              valueType === "number"
                ? value.length
                  ? Number(value)
                  : ""
                : maskParseValue;
            setAddedFilters(
              (addedFilters) => ({
                ...addedFilters,
                [filterKey]: {
                  ...addedFilters[filterKey],
                  value: parseValue,
                  isChecked: isChecked?.(maskParseValue) ?? !!maskParseValue,
                },
              }),
              filterKey
            );
          }}
          value={`${value ?? ""}`}
          type="number"
          disabled={isDisabled}
          {...componentProps}
        />
      </Box>
    );
  } else if (type === "dropdown") {
    return (
      <Grid templateColumns="auto 32px" gap="8px">
        <Dropdown
          w="100%"
          onChange={(value) => {
            const filterValue = addedFilters[filterKey].value as any[];
            const multiSelect = componentProps?.multiSelect ?? true;
            const parsedValue = mask?.(value).raw ?? value;
            if (multiSelect)
              if (filterValue?.length) {
                const isInclude = filterValue.includes(value);
                if (isInclude) {
                  const newValue = filterValue.filter(
                    (curr: any) => curr !== parsedValue
                  );

                  setAddedFilters(
                    (addedFilters) => ({
                      ...addedFilters,
                      [filterKey]: {
                        ...addedFilters[filterKey],
                        value: newValue,
                        isChecked: !!newValue?.length,
                      },
                    }),
                    filterKey
                  );
                } else {
                  const newValue = [...filterValue, parsedValue];
                  setAddedFilters(
                    (addedFilters) => ({
                      ...addedFilters,
                      [filterKey]: {
                        ...addedFilters[filterKey],
                        value: newValue,
                        isChecked: !!newValue?.length,
                      },
                    }),
                    filterKey
                  );
                }
              } else {
                const newValue = [parsedValue];
                setAddedFilters(
                  (addedFilters) => ({
                    ...addedFilters,
                    [filterKey]: {
                      ...addedFilters[filterKey],
                      value: newValue,
                      isChecked: !!newValue?.length,
                    },
                  }),
                  filterKey
                );
              }
            else
              setAddedFilters(
                (addedFilters) => ({
                  ...addedFilters,
                  [filterKey]: {
                    ...addedFilters[filterKey],
                    value: parsedValue,
                    isChecked: !!value?.length,
                  },
                }),
                filterKey
              );
          }}
          value={addedFilters[filterKey].value}
          multiSelect
          disabled={isDisabled}
          options={componentProps?.options || options}
          isLoading={isLoading}
          loadingText="Selecione"
          spinnerPlacement="end"
          {...componentProps}
        >
          {componentProps?.options
            ?.filter(({ name, value }) =>
              addedFilters[filterKey].value?.includes(value)
            )
            .map(({ name }) => name)
            .join(",")}
        </Dropdown>
        <Center>
          <IconButton
            aria-label=""
            variant="outline"
            size="sm"
            icon={<CleanIcon />}
            onClick={() => {
              setAddedFilters(
                (addedFilters) => ({
                  ...addedFilters,
                  [filterKey]: {
                    ...addedFilters[filterKey],
                    value: [],
                    isChecked: false,
                  },
                }),
                filterKey
              );
            }}
            disabled={isDisabled}
          />
        </Center>
      </Grid>
    );
  }
  return <></>;
};
