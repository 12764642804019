import { Icon, IconProps } from "@chakra-ui/react";

export function KeyboardIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 216 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.28 4.5H198.72C205.778 4.5 211.5 10.2218 211.5 17.28V103.68C211.5 110.738 205.778 116.46 198.72 116.46H17.28C10.2218 116.46 4.5 110.738 4.5 103.68V17.28C4.5 10.2218 10.2218 4.5 17.28 4.5Z"
        stroke="currentColor"
        strokeWidth="9"
      />
      <rect
        x="22"
        y="25.96"
        width="17.28"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="48.88"
        y="25.96"
        width="17.28"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="73.84"
        y="25.96"
        width="17.28"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="100.72"
        y="25.96"
        width="17.28"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="125.68"
        y="25.96"
        width="17.28"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="152.56"
        y="25.96"
        width="17.28"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="177.52"
        y="25.96"
        width="17.28"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="22"
        y="51.88"
        width="30.72"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="164.08"
        y="51.88"
        width="30.72"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="61.36"
        y="51.88"
        width="17.28"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="48.88"
        y="77.8"
        width="17.28"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="22"
        y="77.8"
        width="17.28"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="87.28"
        y="51.88"
        width="17.28"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="113.2"
        y="51.88"
        width="17.28"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="138.16"
        y="51.88"
        width="17.28"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="152.56"
        y="77.8"
        width="17.28"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="73.84"
        y="77.8"
        width="71.04"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="177.52"
        y="77.8"
        width="17.28"
        height="17.28"
        rx="2"
        fill="currentColor"
      />
    </Icon>
  );
}
