import { User } from "contexts/types/application-context-types";
import { allRouters } from "routes/routes-array";
import { MenuItemProps, SubItemProps } from "./menu-array";

export function checkIsVisible(
  menuItem: MenuItemProps | SubItemProps,
  user: User
): boolean {
  let isVisible = false;
  const { href, subItems } = menuItem;

  if (href) {
    const { permission } = allRouters.find(({ path }) => path === href)!;
    isVisible = permission(user);
  } else {
    subItems?.forEach(({ href }) => {
      const { permission } = allRouters.find(({ path }) => path === href)!;
      isVisible = isVisible || permission(user);
    });
  }

  return isVisible;
}
