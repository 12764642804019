import { useEffect, useState } from "react";
// import { Toast } from 'primereact/toast';
import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import { GetLayout } from "components/get-layout";
import { useApplicationContext } from "contexts/ApplicationContext";

import {
  Box,
  Button,
  Grid,
  IconButton,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { toastDefaultStyle } from "chakra/theme";
import { Checkbox } from "components/checkbox";
import { CustomModal } from "components/custom-modal";
import { Dropdown } from "components/dropdown";
import DynamicTable from "components/dynamic-table";
import { FieldError } from "components/field-error";
import { FaKey } from "react-icons/fa";
import api from "../api/api";
import { openModalConfirm } from "components/modal-confirm-new";
import { isColomboUser } from "utils/is-colombo-user";
import { allowedUserTypesLoginColombo } from "components/login-page/functions";

export const Usuarios = () => {
  const { user } = useApplicationContext();
  const { userData } = user;
  const [usuarioDialog, setUsuarioDialog] = useState({
    customer: { id: "" },
    nome: "",
  });
  const [usuarioPasswordDialog, setUsuarioPasswordDialog] = useState({});
  const [customers, setCustomers] = useState([]);
  const [filtros, setFiltros] = useState({ nomeOrCodigo: "" });
  const [openUsuarioDialog, setOpenUsuarioDialog] = useState(false);
  const [openUsuarioPasswordDialog, setOpenUsuarioPasswordDialog] =
    useState(false);
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const [errors, setErrors] = useState({
    customer: false,
    name: false,
    username: false,
    password: false,
    type: false,
  });
  const toast = useToast();

  const onOpenUsuarioModal = () => {
    setOpenUsuarioDialog(true);
  };

  const onCloseUsuarioModal = () => {
    setOpenUsuarioDialog(false);
    setSaving(false);
  };

  const getFilteredUsuarios = () => {
    let filteredUsuarios = [...usuarios];
    if (filtros.nomeOrCodigo.trim() !== "") {
      filteredUsuarios = filteredUsuarios.filter(
        (c) =>
          (c.name || "")
            .toUpperCase()
            .includes(filtros.nomeOrCodigo.trim().toUpperCase()) ||
          (c.username || "")
            .toUpperCase()
            .includes(filtros.nomeOrCodigo.trim().toUpperCase())
      );
    }
    return filteredUsuarios;
  };

  const onChangeFiltro = (field, e) => {
    setFiltros({ ...filtros, [field]: e.target.value });
  };

  const saveOrUpdateUsuarioPassword = async () => {
    let hasPasswordError =
      !usuarioPasswordDialog.password ||
      usuarioPasswordDialog.password.trim().length === 0;
    setErrors({ password: hasPasswordError });
    if (hasPasswordError) return;
    let tempUsuarioPassword = {
      ...usuarioPasswordDialog,
      password: usuarioPasswordDialog.password
        ? usuarioPasswordDialog.password.trim()
        : "",
    };
    setUpdatingPassword(true);
    try {
      await api.patch(
        "/usuarios/" + tempUsuarioPassword.id + "/password",
        tempUsuarioPassword
      );
    } catch (error) {
      toast({
        title: "Erro ao alterar senha do usuário!",
        status: "error",
        ...toastDefaultStyle,
      });
    }
    await loadUsuarios();
    setOpenUsuarioPasswordDialog(false);
    setUpdatingPassword(false);
  };

  const saveOrUpdateUsuario = async () => {
    let hasCustomerError =
      !usuarioDialog.customer || !usuarioDialog.customer.id;
    let hasNameError =
      !usuarioDialog.name || usuarioDialog.name.trim().length === 0;
    let hasUsernameError =
      !usuarioDialog.username || usuarioDialog.username.trim().length === 0;
    let hasPasswordError =
      !usuarioDialog.id &&
      (!usuarioDialog.password || usuarioDialog.password.trim().length === 0);

    let hasTypeError = !usuarioDialog.type;
    setErrors({
      customer: hasCustomerError,
      name: hasNameError,
      username: hasUsernameError,
      password: hasPasswordError,
      type: hasTypeError,
    });
    if (
      hasCustomerError ||
      hasNameError ||
      hasUsernameError ||
      hasPasswordError ||
      hasTypeError
    )
      return;
    let tempUsuario = {
      ...usuarioDialog,
      name: usuarioDialog.name.trim(),
      username: usuarioDialog.username.trim(),
      password: usuarioDialog.password ? usuarioDialog.password.trim() : "",
      enabledWabox: usuarioDialog.enabledWabox,
    };
    setSaving(true);
    try {
      if (!usuarioDialog.id) {
        await api.post("/usuarios", tempUsuario);
      } else {
        await api.put("/usuarios/" + tempUsuario.id, tempUsuario);
      }
      onCloseUsuarioModal();
      setSaving(false);
      await loadUsuarios();
    } catch (error) {
      const errorMessage = { username_exists: "Usuário já cadastrado" };
      toast({
        title:
          errorMessage[error?.response?.data?.message] ??
          "Erro ao salvar usuário!",
        status: "error",
        ...toastDefaultStyle,
      });
      return;
    } finally {
      setSaving(false);
    }
  };

  const atualizar = async () => {
    setLoading(true);
    await loadCustomers();
    await loadUsuarios();
    setLoading(false);
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await loadCustomers();
      await loadUsuarios();
      setLoading(false);
    };
    load();
  }, []);

  const loadUsuarios = async () => {
    try {
      let response = await api.get("/usuarios");
      setUsuarios(response.data);
    } catch (error) {
      toast({
        title: "Erro ao carregar usuários",
        status: "error",
        ...toastDefaultStyle,
      });
    }
  };

  const loadCustomers = async () => {
    try {
      let response = await api.get("/customers");
      setCustomers(response.data);
    } catch (error) {
      toast({
        title: "Não foi possível carregar as empresas",
        status: "error",
        ...toastDefaultStyle,
      });
    }
  };

  const acceptDeleteUsuario = async (rowData) => {
    try {
      await api.delete("/usuarios/" + rowData.id);
    } catch (error) {
      toast({
        title: "Erro ao excluir Usuário!",
        status: "error",
        ...toastDefaultStyle,
      });
    }
    await loadUsuarios();
  };

  const onChangeUsuarioDialogCheckbox = (field, value) => {
    setUsuarioDialog({ ...usuarioDialog, [field]: value });
  };

  const onChangeUsuarioDialog = (field, value) => {
    setErrors({ ...errors, [field]: false });
    if (["username", "password"].includes(field)) {
      setUsuarioDialog({ ...usuarioDialog, [field]: value.trim() });
    } else if ("customer" === field) {
      setUsuarioDialog({ ...usuarioDialog, customer: { id: value } });
    } else {
      setUsuarioDialog({ ...usuarioDialog, [field]: value });
    }
  };

  const onChangeUsuarioPasswordDialog = (field, value) => {
    setErrors({ ...errors, [field]: false });
    setUsuarioPasswordDialog({
      ...usuarioPasswordDialog,
      [field]: value.trim(),
    });
  };

  const openDialogNewUsuario = () => {
    setErrors({
      customer: false,
      name: false,
      username: false,
      password: false,
      type: false,
    });
    setUsuarioDialog({
      enabled: true,
      customer: { id: "" },
      name: "",
      type: "",
      username: "",
      password: "",
    });
    onOpenUsuarioModal();
  };

  const editUsuario = (usuario) => {
    setErrors({
      customer: false,
      name: false,
      username: false,
      password: false,
      type: false,
    });
    setUsuarioDialog({ ...usuario });
    onOpenUsuarioModal();
  };

  const editUsuarioPassword = (usuario) => {
    setErrors({ password: false });
    setUsuarioPasswordDialog({ ...usuario, password: "" });
    setOpenUsuarioPasswordDialog(true);
  };

  const getDescOfType = (type) => {
    if ("SUPER" === type) return "Super";
    else if ("FINANCEIRO" === type) return "Financeiro";
    else if ("ASCOM" === type) return "ASCOM";
    else if ("REGIONAL" === type) return "Regional";
    else if ("COMERCIAL" === type) return "Comercial";
    else if ("PARCEIRO_MASTER" === type) return "Parceiro (Master)";
    else if ("PARCEIRO_VENDEDOR" === type) return "Parceiro (Vendedor)";
    else if ("PARCEIRO_OPERACIONAL" === type) return "Parceiro (Operacional)";
    else return "";
  };

  const getDropdownUserType = (type) => {
    if (usuarioDialog.id && !canEdit()) {
      return [
        { name: "Super", value: "SUPER" },
        { name: "Regional", value: "REGIONAL" },
        { name: "Comercial", value: "COMERCIAL" },
        { name: "Parceiro (Master)", value: "PARCEIRO_MASTER" },
        { name: "Parceiro (Vendedor)", value: "PARCEIRO_VENDEDOR" },
        { name: "Parceiro (Operacional)", value: "PARCEIRO_OPERACIONAL" },
      ];
    } else {
      const userTypes = {
        SUPER: [
          { name: "Regional", value: "REGIONAL" },
          { name: "Comercial", value: "COMERCIAL" },
          { name: "Parceiro (Master)", value: "PARCEIRO_MASTER" },
          { name: "Parceiro (Vendedor)", value: "PARCEIRO_VENDEDOR" },
          { name: "Parceiro (Operacional)", value: "PARCEIRO_OPERACIONAL" },
        ],
        REGIONAL: [
          { name: "Comercial", value: "COMERCIAL" },
          { name: "Parceiro (Master)", value: "PARCEIRO_MASTER" },
          { name: "Parceiro (Vendedor)", value: "PARCEIRO_VENDEDOR" },
          { name: "Parceiro (Operacional)", value: "PARCEIRO_OPERACIONAL" },
        ],
        COMERCIAL: [
          { name: "Parceiro (Master)", value: "PARCEIRO_MASTER" },
          { name: "Parceiro (Vendedor)", value: "PARCEIRO_VENDEDOR" },
          { name: "Parceiro (Operacional)", value: "PARCEIRO_OPERACIONAL" },
        ],
        PARCEIRO_MASTER: [
          { name: "Parceiro (Vendedor)", value: "PARCEIRO_VENDEDOR" },
          { name: "Parceiro (Operacional)", value: "PARCEIRO_OPERACIONAL" },
        ],
      };
      return isColomboUser({ userData })
        ? userTypes[userData.type]?.filter((curr) =>
            allowedUserTypesLoginColombo.includes(curr.value)
          )
        : userTypes[userData.type];
    }
  };

  const hideUsuarioPasswordDialog = () => {
    setOpenUsuarioPasswordDialog(false);
  };

  const usuarioDialogFooter = (
    <>
      <Button
        leftIcon={<CheckIcon />}
        className="p-button-text"
        onClick={saveOrUpdateUsuario}
        isLoading={saving}
        loadingText="Salvando"
      >
        Salvar
      </Button>
      <Button
        leftIcon={<CloseIcon w="12px" h="12px" />}
        variant="outline"
        onClick={onCloseUsuarioModal}
      >
        Cancelar
      </Button>
    </>
  );

  const usuarioPasswordDialogFooter = (
    <>
      <Button
        leftIcon={<CheckIcon />}
        onClick={saveOrUpdateUsuarioPassword}
        isLoading={updatingPassword}
        loadingText="Salvando"
      >
        Salvar
      </Button>
      <Button
        leftIcon={<CloseIcon w="12px" h="12px" />}
        onClick={hideUsuarioPasswordDialog}
        variant="outline"
      >
        Cancelar
      </Button>
    </>
  );

  const canEdit = () => {
    if (usuarioDialog.id) {
      if (
        "SUPER" === userData.type &&
        [
          "REGIONAL",
          "FINANCEIRO",
          "COMERCIAL",
          "PARCEIRO_MASTER",
          "PARCEIRO_VENDEDOR",
          "PARCEIRO_OPERACIONAL",
        ].includes(usuarioDialog.type)
      ) {
        return true;
      } else if (
        "REGIONAL" === userData.type &&
        [
          "COMERCIAL",
          "PARCEIRO_MASTER",
          "PARCEIRO_VENDEDOR",
          "PARCEIRO_OPERACIONAL",
        ].includes(usuarioDialog.type)
      ) {
        return true;
      } else if (
        "COMERCIAL" === userData.type &&
        [
          "PARCEIRO_MASTER",
          "PARCEIRO_VENDEDOR",
          "PARCEIRO_OPERACIONAL",
        ].includes(usuarioDialog.type)
      ) {
        return true;
      } else if (
        "PARCEIRO_MASTER" === userData.type &&
        ["PARCEIRO_VENDEDOR", "PARCEIRO_OPERACIONAL"].includes(
          usuarioDialog.type
        )
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const columns = [
    {
      name: "Ativo",
      render: (row) => {
        if (row.enabled === true) return "SIM";
        if (row.enabled === false) return "NÃO";
      },
    },
    {
      name: "Nome",
      key: "name",
    },
    {
      name: "Tipo",
      render: (row) => getDescOfType(row.type),
      key: "type",
    },
    {
      name: "Usuário",
      key: "username",
    },
    {
      name: "Empresa",
      render: (row) => {
        return row.customer.name;
      },
    },
    {
      name: "Ações",
      render: (row) => {
        return (
          <>
            <IconButton
              icon={<EditIcon />}
              mr="8px"
              onClick={() => editUsuario(row)}
            />
            <IconButton
              icon={<FaKey />}
              mr="8px"
              onClick={() => editUsuarioPassword(row)}
            />
            <IconButton
              icon={<DeleteIcon />}
              variant="danger"
              onClick={() =>
                openModalConfirm({
                  message: `Deseja excluir ${row.username} ("${row.name})" ?`,
                  title: "Confirmação",
                  onConfirm: () => acceptDeleteUsuario(row),
                  confirmButtonStyle: {
                    variant: "danger",
                    leftIcon: <DeleteIcon />,
                    loadingText: "Deletando",
                  },
                })
              }
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <GetLayout>
        <Grid mb="30px" templateColumns="1fr 1fr 1fr" gap="16px">
          <Box>
            <Text mb="8px">Filtrar por Nome ou Usuário</Text>
            <Input
              value={filtros.nomeOrCodigo || ""}
              onChange={(e) => onChangeFiltro("nomeOrCodigo", e)}
            />
          </Box>
          <Box>
            <Text mb="8px">&nbsp;</Text>
            <Button
              w="100%"
              leftIcon={<AddIcon />}
              onClick={openDialogNewUsuario}
            >
              Adicionar Usuário
            </Button>
          </Box>
          <Box>
            <Text mb="8px">&nbsp;</Text>
            <Button
              w="100%"
              isLoading={loading}
              loadingText="Atualizando"
              leftIcon={<RepeatIcon />}
              onClick={atualizar}
            >
              Atualizar
            </Button>
          </Box>
        </Grid>
        <DynamicTable
          rows={getFilteredUsuarios()}
          columns={columns}
          isLoading={loading}
        />
        <CustomModal
          isOpen={openUsuarioPasswordDialog}
          size="md"
          modalTitle="Redefinir Senha"
          modalFooter={usuarioPasswordDialogFooter}
          onClose={hideUsuarioPasswordDialog}
        >
          <Box>
            <Text mb="8px">Nova senha:</Text>
            <Input
              value={usuarioPasswordDialog.password}
              isInvalid={errors.password}
              onChange={(e) =>
                onChangeUsuarioPasswordDialog("password", e.target.value)
              }
            />
            <FieldError isError={errors.password}>
              Informe a nova senha
            </FieldError>
          </Box>
        </CustomModal>

        <CustomModal
          isOpen={openUsuarioDialog}
          size="md"
          modalTitle="Usuários"
          modalFooter={usuarioDialogFooter}
          onClose={onCloseUsuarioModal}
        >
          <Checkbox
            mb="16px"
            isChecked={usuarioDialog.enabled}
            onChange={(value) =>
              onChangeUsuarioDialogCheckbox("enabled", value)
            }
            disabled={!canEdit()}
          >
            Ativo
          </Checkbox>

          <Box mb="16px">
            <Text mb="8px">Empresa</Text>
            <Dropdown
              w="100%"
              value={usuarioDialog.customer.id}
              options={customers}
              optionValue="id"
              onChange={(value) => onChangeUsuarioDialog("customer", value)}
              optionLabel="name"
              isInvalid={errors.customer}
              disabled={!!usuarioDialog.id}
            />
            <FieldError isError={errors.customer}>
              Selecione uma empresa
            </FieldError>
          </Box>
          <Box mb="16px">
            <Text mb="8px">Nome</Text>
            <Input
              value={usuarioDialog.name}
              isInvalid={errors.name}
              onChange={(e) => onChangeUsuarioDialog("name", e.target.value)}
              disabled={!canEdit()}
            />
            <FieldError isError={errors.name}>Informe um nome</FieldError>
          </Box>
          <Box mb="16px">
            <Text mb="8px">Usuário</Text>
            <Input
              value={usuarioDialog.username}
              isInvalid={errors.username}
              onChange={(e) =>
                onChangeUsuarioDialog("username", e.target.value)
              }
              disabled={!canEdit()}
            />
            <FieldError isError={errors.username}>
              Informe um usuário
            </FieldError>
          </Box>

          {!usuarioDialog.id && (
            <Box mb="16px">
              <Text mb="8px">Senha</Text>
              <Input
                value={usuarioDialog.password}
                isInvalid={errors.password}
                onChange={(e) =>
                  onChangeUsuarioDialog("password", e.target.value)
                }
              />
              <FieldError isError={errors.password}>
                Informe uma senha
              </FieldError>
            </Box>
          )}

          <Box mb="16px">
            <Text mb="8px">Tipo</Text>
            <Dropdown
              w="100%"
              value={usuarioDialog.type}
              disabled={!canEdit()}
              onChange={(value) => onChangeUsuarioDialog("type", value)}
              options={getDropdownUserType(usuarioDialog.type)}
              isInvalid={errors.type}
            />
            <FieldError isError={errors.type}>Selecione um tipo</FieldError>
          </Box>
        </CustomModal>
      </GetLayout>
    </>
  );
};
