import { Box, Button, Grid, Text } from "@chakra-ui/react";
import { useState } from "react";
import { maskCPF } from "utils/string-formats";
import { dropdownConvenioAtendimento } from "../atendimento-form/fields-data";
import { InputField } from "components/input-field";
import { DropdownField } from "components/dropdown-field";
import { objectSchema } from "utils/object-methods";
import { validateCPF } from "utils/cpf-validate";
import { fieldValidation } from "utils/field-validation";
import { AddIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { openModalConfirm } from "components/modal-confirm-new";
import api from "api/api";
import { openModalAttendance } from "../modal-atendimento";
import { makeEvent } from "services/events";

export function CreateCustomAttendance({ onClose }: { onClose: () => void }) {
  const [isLoading, setIsLoading] = useState(false);
  const [convenio, setConvenio] = useState("");
  const [cpf, setCPF] = useState("");
  const [errorMessages, setErrorMessages] = useState<{
    [k: string]: string | undefined;
  }>({});
  const { isValid, body, errors } = objectSchema(
    {
      cpf: (value) => {
        const onlyCpfNumbers = cpf.replace(/\D/g, "");
        const validate = validateCPF(onlyCpfNumbers);
        const field = fieldValidation({ value })
          .required({
            message: "Insira um cpf",
          })
          .custom({
            validate: () => onlyCpfNumbers.length === 11,
            message: "CPF incompleto",
          });
        const message = field.errorMessage
          ? field.errorMessage
          : !validate
          ? "CPF Inválido"
          : undefined;

        return {
          valid: validate && field.isValid,
          message,
          format: onlyCpfNumbers,
        };
      },
      convenio: (value) => {
        const { isValid, errorMessage } = fieldValidation({ value }).required({
          message: "Insira um convênio",
        });
        return { valid: isValid, message: errorMessage };
      },
    },
    { cpf, convenio }
  );

  const handleSubmit = async () => {
    if (!isValid) return setErrorMessages(errors);
    setIsLoading(true);
    try {
      const { data } = await api.post("/atendimentos", body);
      openModalConfirm({
        message: "Deseja iniciar o atendimento?",
        onConfirm: () => openModalAttendance({ id: data.id, init: true }),
        confirmButtonStyle: { variant: undefined, leftIcon: <CheckIcon /> },
        rejectButtonStyle: { leftIcon: <CloseIcon w="12px" h="12px" /> },
      });
      onClose();
      makeEvent("updateAttendances", null, { throwsError: false });
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box w="100%" py="20px">
      <Grid templateColumns="1fr 1fr 1fr" w="100%" gap="16px">
        <Box>
          <DropdownField
            title="Convênio:"
            dropdownProps={{
              w: "100%",
              options: dropdownConvenioAtendimento,
              value: convenio,
              onChange: (v) => {
                setConvenio(v);
                setErrorMessages({ ...errorMessages, convenio: undefined });
              },
              menuListProps: { maxH: "140px" },
            }}
            errorMessage={errorMessages["convenio"]}
          />
        </Box>
        <Box>
          <InputField
            title="CPF:"
            inputProps={{
              value: cpf,
              onChange: ({ target: { value } }) => {
                setCPF(maskCPF(value));
                setErrorMessages({ ...errorMessages, cpf: undefined });
              },
            }}
            errorMessage={errorMessages["cpf"]}
            closeErrorOnBlur={false}
          />
        </Box>
        <Box>
          <Text mb="8px">&nbsp;</Text>
          <Button
            w="100%"
            onClick={handleSubmit}
            isLoading={isLoading}
            leftIcon={<AddIcon w="14px" h="14px" />}
            loadingText="Criando Atendimento"
          >
            Criar atendimento
          </Button>
        </Box>
      </Grid>
    </Box>
  );
}
