import { Link, useToast } from "@chakra-ui/react";
import { toastDefaultStyle } from "chakra/theme";
import { GetLayout } from "components/get-layout";
import { BlockUI } from "primereact/blockui";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { useEffect, useState } from "react";
import api from "../api/api";

export const ExtratoOnlineAvulso = () => {
  const [saldo, setSaldo] = useState("");
  const [extratosOnline, setExtratosOnline] = useState([]);
  const [beneficio, setBeneficio] = useState("");
  const [loading, setLoading] = useState(false);
  const [showingExtrato, setShowingExtrato] = useState(false);
  const toast = useToast();

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await loadExtratosOnline();
      await loadSaldo();
      setLoading(false);
    };
    load();
  }, []);

  const loadExtratosOnline = async () => {
    let response = await api.get("/extratos");
    setExtratosOnline(response.data);
  };

  const loadSaldo = async () => {
    let response = await api.get("/extratos/saldo");
    setSaldo(response.data);
  };

  const atualizar = async () => {
    setLoading(true);
    await loadExtratosOnline();
    await loadSaldo();
    setLoading(false);
  };

  const gerarExtrato = async () => {
    if (beneficio.trim() === "") {
      toast({
        title: "Informe o benefício",
        status: "error",
        ...toastDefaultStyle,
      });

      return;
    }
    setLoading(true);
    let response;
    try {
      response = await api.get("/extratos/create/" + beneficio);
      setShowingExtrato(true);
    } catch (error) {
      let hasMessage = error?.response?.data?.message;
      if (hasMessage && "sem_saldo" === error.response.data.message) {
        toast({
          title: "Sem saldo!",
          status: "error",
          ...toastDefaultStyle,
        });
      } else if (
        hasMessage &&
        "matricula_invalida" === error.response.data.message
      ) {
        toast({
          title: "Benefício inválido!",
          status: "error",
          ...toastDefaultStyle,
        });
      } else if (
        hasMessage &&
        "fora_horario_consulta" === error.response.data.message
      ) {
        toast({
          title: "Fora do horário de consulta!",
          status: "error",
          ...toastDefaultStyle,
        });
      } else if (
        hasMessage &&
        "fora_do_horario_permitido" === error.response.data.message
      ) {
        toast({
          title: "Fora do horário permitido!",
          status: "error",
          ...toastDefaultStyle,
        });
      } else if (
        hasMessage &&
        "informe_matricula" === error.response.data.message
      ) {
        toast({
          title: "Informe o benefício!",
          status: "error",
          ...toastDefaultStyle,
        });
      } else if (
        hasMessage &&
        "sem_credito_api" === error.response.data.message
      ) {
        toast({
          title: "Serviço indisponível. Contate o administrador!",
          status: "error",
          ...toastDefaultStyle,
        });
      } else if (
        hasMessage &&
        "service_unavailable" === error.response.data.message
      ) {
        toast({
          title: "Serviço indisponível!",
          status: "error",
          ...toastDefaultStyle,
        });
      } else {
        toast({
          title: "Não foi possível gerar o extrato. Contate o administrador!",
          status: "error",
          ...toastDefaultStyle,
        });
      }
      setShowingExtrato(false);
      setLoading(false);
      return;
    }

    await loadExtratosOnline();
    await loadSaldo();

    setLoading(false);
  };

  const bodyTemplate = (data, props) => (
    <>
      <span className="p-column-title">{props.header}</span>
      {data[props.field]}
    </>
  );
  const dateBodyTemplate = (data, props) => (
    <>
      <span className="p-column-title">{props.header}</span>
      {parseDateAndTime(data[props.field])}
    </>
  );

  const parseDateAndTime = (value) => {
    return (
      value.substring(8, 10) +
      "/" +
      value.substring(5, 7) +
      "/" +
      value.substring(0, 4) +
      " " +
      value.substring(11, 13) +
      ":" +
      value.substring(14, 16) +
      ":" +
      value.substring(17, 19)
    );
  };

  const actionTemplate = (rowData) => (
    <Link
      href={`${process.env.REACT_APP_PUBLIC_API_URL}/extratos/exibir/${rowData.hash}`}
      target="_blank"
      label="download"
      className="p-button-sm p-button-text"
    >
      {"Exibir"}
    </Link>
  );

  const getLoadingTemplate = () => {
    return (
      <ProgressSpinner
        style={{ width: "150px", height: "150px" }}
        strokeWidth="3"
        animationDuration=".5s"
      />
    );
  };

  return (
    <GetLayout>
      <div>
        <BlockUI
          blocked={loading}
          fullScreen
          template={getLoadingTemplate}
        ></BlockUI>
        <div className="grid table-demo no-printme">
          <div className="col-12">
            <div className="card">
              <div className="grid p-fluid">
                <div className="field col-3">
                  <label htmlFor="filtro">Saldo</label>
                  <InputText
                    id="filtro"
                    value={saldo === undefined || saldo === null ? 0 : saldo}
                    disabled
                    autoComplete="new-password"
                    onChange={(e) => setSaldo(e.target.value)}
                  />
                </div>
                <div className="field col-3">
                  <label htmlFor="beneficio">Informe o benefício</label>
                  <InputText
                    id="beneficio"
                    value={beneficio || ""}
                    autoComplete="new-password"
                    onChange={(e) => setBeneficio(e.target.value)}
                  />
                </div>
                <div className="field col-3">
                  <label htmlFor="gerar">&nbsp;</label>
                  <Button
                    id="gerar"
                    label="Gerar Extrato"
                    icon="pi pi-plus"
                    className="p-button-primary mr-2"
                    onClick={gerarExtrato}
                  />
                </div>
                <div className="field col-3">
                  <label htmlFor="atualizar">&nbsp;</label>
                  <Button
                    id="atualizar"
                    label="Atualizar"
                    icon="pi pi-refresh"
                    className="p-button-primary mr-2"
                    onClick={atualizar}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <BlockUI blocked={loading} fullScreen template={getLoadingTemplate}></BlockUI> */}
        </div>
        {/* {showingExtrato && <div className="card printme" id='showDiv'></div>} */}
        {extratosOnline.length > 0 && (
          <div className="col-12">
            <div className="card">
              <DataTable
                value={extratosOnline}
                paginator
                className="p-datatable-customers"
                rows={10}
                dataKey="id"
                rowHover
                emptyMessage="Não há extratos"
                size="small"
              >
                <Column
                  field="nb"
                  header="Benefício"
                  sortable
                  body={bodyTemplate}
                ></Column>
                <Column
                  field="dataCriacao"
                  header="Criação"
                  sortable
                  body={dateBodyTemplate}
                ></Column>
                <Column header="Ações" body={actionTemplate}></Column>
              </DataTable>
            </div>
          </div>
        )}
      </div>
    </GetLayout>
  );
};
