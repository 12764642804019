import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  ListItem,
  Progress,
  Spacer,
  Spinner,
  Td,
  Text,
  Tooltip,
  Tr,
  UnorderedList,
  useToast,
} from "@chakra-ui/react";
import api from "api/api";
import { defaultScroll, toastDefaultStyle } from "chakra/theme";
import { Popover } from "components/popover";
import { Fragment, useEffect, useRef, useState } from "react";
import { FaPlay, FaRedo } from "react-icons/fa";
import { createEventListener, makeEvent } from "services/events";
import { AnimateElement } from "utils/animations";
import { MailingTableRow } from "..";
import { DownloadIcon, EditIcon } from "@chakra-ui/icons";
import {
  MailingOptionFilter,
  openMailingOptions,
} from "components/mailing-components/mailing-options-modal";
import { createLinkAndDownload } from "utils/create-link-and-download";
import { HigienizacaoProgress } from "./higienizacao-progress";

export interface Pipeline {
  pipelineStage:
    | "HIGIENIZACAO"
    | "FILTRO"
    | "TELEFONIA"
    | "ENVIA_WHATS"
    | "ENVIA_DISCADORA"
    | "ENVIA_CAMPANHA_SMS"
    | "SAQUE_COMPLEMENTAR"
    | "REFIN";
  pipelineStatus:
    | "FINALIZADO"
    | "EM_ANDAMENTO"
    | "NAO_INICIADO"
    | "PAUSADO"
    | "ERROR"
    | null;
  pipelineTipoHigienizacao: "REFIN" | "IN_100" | null;
  pipelineItemStatus: "CANCELADO" | null;
  higienizacaoId: string | null;
  pipelineErrorDescription: string | null;
  configJson: string;
  mailingLeadCount: number;
  higienizacaoStatusResultList: Array<{
    total: number;
    totalCompleted: number;
    tipoHigienizacao: "REFIN" | "CONSULTA_TOP";
    finished?: boolean;
    lastError?: string;
    higienizacaoId: string;
    banco: string;
  }> | null;
}

type Step = { title: string; key: Pipeline["pipelineStage"] };

export interface MailingOptionsAutoComplete {
  higienizacao: MailingOptionFilter;
  filters: MailingOptionFilter;
  telefonia: MailingOptionFilter;
  enviaWhats: MailingOptionFilter;
  enviaDiscadora: MailingOptionFilter;
  enviaSMS: MailingOptionFilter;
}

export const MailingTableRowDetails = ({
  columnsLength,
  row,
  rowIndex,
}: {
  row: MailingTableRow;
  columnsLength: number;
  rowIndex: number;
}) => {
  const [modalData, setModalData] = useState<Pipeline[]>([]);
  const checkedFields = useRef<Partial<MailingOptionsAutoComplete>>({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const tableRow = useRef<HTMLTableRowElement>(null);
  const internalContainer = useRef<HTMLDivElement>(null);
  const [activeStep, setActiveStep] = useState(0);
  const toast = useToast();
  const steps: Step[] = [
    { title: "Higienização", key: "HIGIENIZACAO" },
    { title: "Filtro", key: "FILTRO" },
    { title: "Telefonia", key: "TELEFONIA" },
    { title: "Envio de campanha Whatsapp", key: "ENVIA_WHATS" },
    { title: "Envio de campanha SMS", key: "ENVIA_CAMPANHA_SMS" },
    { title: "Envio de campanha URA", key: "ENVIA_DISCADORA" },
  ];

  const filteredSteps = steps.filter(({ key }) =>
    modalData.find(({ pipelineStage }) => pipelineStage === key)
  );

  const filtroIndex = filteredSteps.findIndex((i) => i.key === "FILTRO");
  const isFilterDisabled =
    !modalData.find((i) => i.pipelineStage === "FILTRO") ||
    activeStep > filtroIndex;

  const onOpen = async () => {
    if (row.currentPipelineOrdem == null) return;
    setIsOpen(true);
    getData();
  };

  const onClose = () => {
    setIsOpen(false);
    setIsLoading(true);
    checkedFields.current = {};
  };

  const onToggle = () => (isOpen ? onCloseTableRow() : onOpen());

  const onOpenTableRow = () => {
    if (internalContainer.current) {
      const children = internalContainer.current.children[0];
      const childrenHeight = children.clientHeight;
      AnimateElement(
        200,
        [0, 1],
        (progress) => {
          if (tableRow.current && internalContainer.current) {
            const rowStyle = internalContainer.current.style;
            const currentHeight = progress * childrenHeight;
            rowStyle.opacity = `${progress}`;
            rowStyle.height = `${currentHeight}px`;
          }
        },
        () => {
          if (internalContainer.current)
            internalContainer.current.style.overflow = "visible";
        }
      );
    }
  };

  const onCloseTableRow = () => {
    if (internalContainer.current) {
      const children = internalContainer.current.children[0];
      const childrenHeight = children.clientHeight;
      AnimateElement(
        200,
        [1, 0],
        (progress) => {
          if (tableRow.current && internalContainer.current) {
            const rowStyle = internalContainer.current.style;
            const currentHeight = progress * childrenHeight;
            rowStyle.opacity = `${progress}`;
            rowStyle.height = `${currentHeight}px`;
          }
        },
        onClose
      );
    }
  };

  const verifyStageStatus = (
    stage: Pipeline["pipelineStage"],
    status: Pipeline["pipelineStatus"],
    data?: Pipeline[]
  ) => {
    if (data)
      return !!data
        .filter((crr) => crr.pipelineStage === stage)
        .filter((crr) => crr.pipelineStatus === status).length;
    return !!modalData
      .filter((crr) => crr.pipelineStage === stage)
      .filter((crr) => crr.pipelineStatus === status).length;
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      const { data }: { data: Pipeline[] } = await api.get(
        `/pipeline/status/mailing/${row.id}`
      );
      setModalData(data);
      const isFinished =
        data.filter((i) => i.pipelineStatus === "FINALIZADO").length ===
        data.length;
      setActiveStep(
        isFinished ? row.currentPipelineOrdem! : row.currentPipelineOrdem! - 1
      );
      let higienizacao: MailingOptionFilter = {};
      let filters: MailingOptionFilter = {};
      let telefonia: MailingOptionFilter = {};
      let enviaWhats: MailingOptionFilter = {};
      let enviaDiscadora: MailingOptionFilter = {};
      let enviaSMS: MailingOptionFilter = {};
      if (data.length) {
        data.forEach((pipeline) => {
          switch (pipeline.pipelineStage) {
            case "HIGIENIZACAO":
              if (pipeline.configJson) {
                const parser = JSON.parse(pipeline.configJson);
                const keys = Object.keys(parser);
                keys.forEach((key) => {
                  const currentFilter = parser[key];
                  higienizacao[key] = {
                    isChecked: true,
                    value: currentFilter,
                  };
                });
              }
              break;
            case "FILTRO":
              if (pipeline.configJson) {
                const parser = JSON.parse(pipeline.configJson);
                const keys = Object.keys(parser);
                keys.forEach((key) => {
                  const currentFilter = parser[key];
                  filters[key] = {
                    isChecked: true,
                    value: currentFilter,
                  };
                });
              }
              break;
            case "TELEFONIA":
              if (pipeline.configJson) {
                const parser = JSON.parse(pipeline.configJson);
                const keys = Object.keys(parser);
                keys.forEach((key) => {
                  const currentFilter = parser[key];
                  telefonia[key] = {
                    isChecked: true,
                    value: currentFilter,
                  };
                });
              }
              break;
            case "ENVIA_WHATS":
              if (pipeline.configJson) {
                const parser = JSON.parse(pipeline.configJson);
                const keys = Object.keys(parser);
                keys.forEach((key) => {
                  const currentFilter = parser[key];
                  enviaWhats[key] = {
                    isChecked: true,
                    value: currentFilter,
                  };
                });
              }
              break;
            case "ENVIA_CAMPANHA_SMS":
              if (pipeline.configJson) {
                const parser = JSON.parse(pipeline.configJson);
                const keys = Object.keys(parser);
                keys.forEach((key) => {
                  const currentFilter = parser[key];
                  enviaSMS[key] = {
                    isChecked: true,
                    value: currentFilter,
                  };
                });
              }
              break;
            case "ENVIA_DISCADORA":
              if (pipeline.configJson) {
                const parser = JSON.parse(pipeline.configJson);
                const keys = Object.keys(parser);
                keys.forEach((key) => {
                  const currentFilter = parser[key];
                  enviaDiscadora[key] = {
                    isChecked: true,
                    value: currentFilter,
                  };
                });
              }
          }
        });
        checkedFields.current = {
          higienizacao,
          filters,
          telefonia,
          enviaWhats,
          enviaDiscadora,
          enviaSMS,
        };
      } else onCloseTableRow();
    } catch (e: any) {
      toast({
        title: "Erro ao obter dados mailing",
        status: "error",
        ...toastDefaultStyle,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const groupBy = (items: any, key: any) =>
    items.reduce(
      (result: any, item: any) => ({
        ...result,
        [item[key]]: [...(result[item[key]] || []), item],
      }),
      {}
    );

  const getErrorMessage = (data: Pipeline | undefined) => {
    if (
      (data?.pipelineStage === "HIGIENIZACAO" ||
        data?.pipelineStage === "TELEFONIA") &&
      data?.higienizacaoStatusResultList
    ) {
      let errorMessages = groupBy(
        data.higienizacaoStatusResultList.filter(
          (status) => status.lastError !== null
        ),
        "lastError"
      );

      return Object.keys(errorMessages);
    } else {
      return [data?.pipelineErrorDescription];
    }
  };

  const getHigienizacaoIds = (
    data: Pipeline | undefined
  ): Array<string> | null => {
    if (data?.higienizacaoStatusResultList) {
      return data.higienizacaoStatusResultList
        .filter((result) => result.higienizacaoId)
        .map((result) => result.higienizacaoId);
    }
    return null;
  };

  const advancePipeline = async () => {
    setIsLoading(true);
    try {
      const response = await api.post(`/pipeline/advance/${row.id}`);
      setActiveStep(activeStep + 1);
    } catch (e) {
      toast({
        title: "Erro ao avançar pipeline",
        status: "error",
        ...toastDefaultStyle,
      });
    } finally {
      setIsLoading(false);
      makeEvent("update-mailings-table", true);
    }
  };

  const replayPipeline = async (higienizacaoIds: Array<string> | null) => {
    setIsLoading(true);

    try {
      await api.post(`/pipeline/replay/${row.id}`, higienizacaoIds);
      // setActiveStep(activeStep + 1);
    } catch (e) {
      toast({
        title: "Erro ao executar novamente o pipeline",
        status: "error",
        ...toastDefaultStyle,
      });
    } finally {
      setIsLoading(false);
      makeEvent("update-mailings-table", true);
    }
  };

  const renderPopover = (
    title: string,
    stepButton: any,
    isError: boolean,
    isCurrentStep: boolean,
    isFinished: boolean,
    key: string
  ) => {
    let stageData = modalData.find((p) => p.pipelineStage === key);
    const openPopover =
      isCurrentStep &&
      !isFinished &&
      (key !== "FILTRO" ||
        (key === "FILTRO" && stageData?.pipelineStatus !== "ERROR"));

    if (openPopover) {
      let icon = <FaPlay />;
      let onClickButton = advancePipeline;
      let buttonText = "Avançar etapa";
      let shouldShowError = isError && isCurrentStep;
      let errorMessage = null;
      if (shouldShowError) {
        icon = <FaRedo />;
        let higienizacaoIds = getHigienizacaoIds(stageData);
        if (higienizacaoIds !== null) {
          buttonText = "Executar novamente";
          onClickButton = () => replayPipeline(higienizacaoIds);
        }
        errorMessage = getErrorMessage(stageData);
      }

      return (
        <Popover position="right-bottom" title={title} button={stepButton}>
          <Flex
            flexDir="column"
            p="0 0 0px 0"
            overflow="overlay"
            maxH="350px"
            maxW="350px"
            sx={defaultScroll}
          >
            {shouldShowError && key !== "HIGIENIZACAO" ? (
              <Flex flexDir="column">
                <Heading as="h6" size="xs">
                  Erros:
                </Heading>
                {errorMessage?.map((message) => (
                  <Text wordBreak="break-word" color="tomato">
                    {" "}
                    - {message}
                  </Text>
                ))}
              </Flex>
            ) : null}
            {key === "HIGIENIZACAO" ? (
              <HigienizacaoProgress modalData={modalData} />
            ) : null}
            <Button
              pos="sticky"
              bottom="0"
              minH="40px"
              leftIcon={icon}
              w="100%"
              onClick={onClickButton}
            >
              {buttonText}
            </Button>
          </Flex>
        </Popover>
      );
    }
    return stepButton;
  };

  useEffect(() => {
    if (isOpen) onOpenTableRow();
  }, [isOpen]);

  createEventListener(`mailing-update-${row.id}`, getData);

  createEventListener(
    `table-row`,
    (index: number) => {
      if (index === rowIndex) onToggle();
      else onCloseTableRow();
    },
    `${rowIndex}`
  );

  return isOpen ? (
    <Tr ref={tableRow}>
      <Td colSpan={columnsLength} padding={0}>
        <Flex
          flexDir="column"
          w="100%"
          ref={internalContainer}
          overflow="hidden"
          pos="relative"
        >
          <Flex flexDir="column" p="16px">
            <Flex w="100%" justifyContent="flex-end">
              <Button
                mr="8px"
                leftIcon={<DownloadIcon />}
                isLoading={isDownloading}
                onClick={async () => {
                  setIsDownloading(true);
                  try {
                    const { data } = await api.get(
                      `pipeline/mailing/${row.id}/download`
                    );
                    createLinkAndDownload(new Blob([data]), `${row.name}.csv`);
                  } catch (e) {
                  } finally {
                    setIsDownloading(false);
                  }
                }}
                loadingText="Baixando"
              >
                Baixar CSV
              </Button>
              <Button
                leftIcon={<EditIcon />}
                isDisabled={isFilterDisabled}
                onClick={() =>
                  openMailingOptions({
                    ...row,
                    enabledFields:
                      checkedFields.current as MailingOptionsAutoComplete,
                  })
                }
              >
                Editar Filtros
              </Button>
            </Flex>
            <Flex flexDir="column" p="8px 0px 16px">
              <Center p="16px" h="calc(45px + 32px)">
                {filteredSteps.map(({ title, key }, index) => {
                  const currentStage = modalData.find(
                    (p) => p.pipelineStage === key
                  );
                  const isRunning = verifyStageStatus(key, "EM_ANDAMENTO");
                  const isError = verifyStageStatus(key, "ERROR");
                  const isFinished =
                    currentStage?.pipelineStatus === "FINALIZADO";

                  const isPassed = activeStep > index || isFinished;
                  const isLast = filteredSteps.length === index + 1;
                  const isCurrentStep = activeStep === index;
                  const leadCount = currentStage?.mailingLeadCount;
                  const showLeadCount = isPassed || isCurrentStep;
                  const button = (
                    <Center
                      borderRadius="90px"
                      bg={isPassed ? "primary.300" : undefined}
                      color={isPassed ? "#fff" : undefined}
                      w="45px"
                      h="45px"
                      border={
                        isCurrentStep
                          ? undefined
                          : "3px solid var(--chakra-colors-gray-200)"
                      }
                      pos="relative"
                    >
                      <Text>{index + 1}</Text>
                      {isCurrentStep ? (
                        <Box
                          inset="0"
                          border={
                            isError
                              ? "3px dashed var(--chakra-colors-secondary-400)"
                              : "3px dashed var(--chakra-colors-primary-200)"
                          }
                          animation={
                            isRunning ? "spin 5s linear infinite" : undefined
                          }
                          pos="absolute"
                          borderRadius="90px"
                        />
                      ) : null}
                    </Center>
                  );
                  return (
                    <Fragment key={key}>
                      {!(index === 0) ? (
                        <Box
                          width="30px"
                          height="2px"
                          marginRight="12px"
                          bg={
                            isPassed
                              ? "var(--chakra-colors-primary-200)"
                              : "var(--chakra-colors-gray-200)"
                          }
                        />
                      ) : null}
                      <Tooltip
                        label={
                          showLeadCount
                            ? `Quantidade de leads: ${leadCount ?? 0}`
                            : undefined
                        }
                      >
                        <Flex
                          alignItems="center"
                          mr={isLast ? undefined : "12px"}
                          fontSize={14}
                        >
                          {renderPopover(
                            title,
                            button,
                            isError,
                            isCurrentStep,
                            isFinished,
                            key
                          )}
                          <Text ml="8px">{title}</Text>
                        </Flex>
                      </Tooltip>
                    </Fragment>
                  );
                })}
              </Center>

              {isLoading ? (
                <Center inset="0" pos="absolute" bg="#fff">
                  <Spinner />
                </Center>
              ) : null}
            </Flex>
          </Flex>
        </Flex>
      </Td>
    </Tr>
  ) : null;
};
