export function sumFields(array: any[] | null, fields: string[]) {
  const resultArray = Array.from({ length: fields.length }).fill(0) as number[];
  if (array) {
    fields.map((key, index) => {
      array.forEach((item) => {
        const sum = (resultArray[index] + item[key]) as number;
        resultArray[index] = parseFloat(sum.toFixed(2));
      });
    });
  }
  return resultArray;
}
