import { Button, Flex, Grid, Input, Text } from "@chakra-ui/react";

export function SaldosConfig() {
  return (
    <Flex
      flexDir="column"
      alignItems="start"
      p="15px 20px"
      borderRadius="10"
      bg="#fff"
      border="default-border"
    >
      <Text fontSize="20" mb="20px">
        Saldos
      </Text>
      <Grid
        templateColumns="auto auto"
        alignItems="center"
        pb="15px"
        gap="15px"
      >
        <Text mr="15px">Saldo Extrato:</Text>
        <Input maxW="230px" defaultValue="10.000" readOnly />

        <Text mr="15px">Saldo Offline Inss:</Text>
        <Input maxW="230px" defaultValue="10.000" readOnly />
      </Grid>
      <Flex w="100%" justifyContent="flex-end">
        <Button>Renovar</Button>
      </Flex>
    </Flex>
  );
}
