import { Box, Center, Text } from "@chakra-ui/react";
import { MenuItemProps, SubItemProps } from "./menu-array";
import { useHistory } from "react-router-dom";
import { useApplicationContext } from "contexts/ApplicationContext";
import { Dispatch, SetStateAction, useRef } from "react";

export function NewMenuItem({
  isFixedOpen,
  setSubMenuOptions,
  setIsOpenSubmenu,
  ...menuItem
}: MenuItemProps & {
  isFixedOpen: boolean;
  setSubMenuOptions: Dispatch<SetStateAction<SubItemProps[] | undefined>>;
  setIsOpenSubmenu: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { icon, name, href, subItems: subitems } = menuItem;
  const router = useHistory();
  const { user } = useApplicationContext();
  const subitemRef = useRef<HTMLDivElement>(null);
  const thisRouter = window.location.pathname;
  const subitemsLinks = subitems?.map(({ href }) => href) || [];
  const isActive = subitemsLinks.includes(thisRouter) || thisRouter === href;

  const isHovering = (element: HTMLElement | null) =>
    element?.matches(":hover");

  const getSubmenuContainer = () =>
    document.getElementById("submenu-container");

  return (
    <Center
      ref={subitemRef}
      w="90px"
      h="67px"
      borderRadius="10px"
      color={isActive ? "#B21F17" : "#4E5762"}
      _hover={isActive ? undefined : { bg: "#F2F2F2" }}
      flexDir="column"
      bg={isActive ? "#FFF3F3" : "transparent"}
      cursor="pointer"
      onClick={href ? () => router.push(href) : undefined}
      onMouseEnter={(e) => {
        if (subitems) {
          setIsOpenSubmenu(true);
          setSubMenuOptions(subitems);
        }
      }}
      onMouseLeave={(e) => {
        if (!isHovering(getSubmenuContainer())) setIsOpenSubmenu(false);
      }}
    >
      <Box mb="8px">{icon}</Box>
      <Text fontWeight="medium" fontSize="12px">
        {name}
      </Text>
    </Center>
  );
}
