import { INITIAL_TABLES_VALUES } from "./modal-config-tables";

export interface TableConfigType {
  convenio: string;
  tipoOperacao: string;
  prazo: string;
  banco: string;
  descricaoCompleta: string;
  codigo: string;
  needToken?: boolean;
  token?: string;
}

export type TableConfigActionsTypes =
  | {
      type: "changeField";
      payload: { fieldName: keyof TableConfigType; data: string };
    }
  | { type: "resetFields"; payload?: undefined };

interface OperationsType {
  changeField: "changeField";
  resetFields: "resetFields";
}

const operations: OperationsType = {
  changeField: "changeField",
  resetFields: "resetFields",
};

export function reducerTables(
  state: TableConfigType,
  action: TableConfigActionsTypes
) {
  const { type, payload } = action;

  switch (type) {
    case operations.changeField:
      const { fieldName, data } = payload;
      const resetFildsOnChange = {
        convenio: {
          ...state,
          tipoOperacao: "",
          prazo: "",
          codigo: "",
          descricaoCompleta: "",
          needToken: undefined,
          token: "",
          [fieldName]: data,
        },
        tipoOperacao: {
          ...state,
          prazo: "",
          codigo: "",
          descricaoCompleta: "",
          needToken: undefined,
          token: "",
          [fieldName]: data,
        },
        prazo: {
          ...state,
          codigo: "",
          descricaoCompleta: "",
          needToken: undefined,
          token: "",
          [fieldName]: data,
        },
        banco: {
          ...state,
          codigo: "",
          prazo: "",
          needToken: undefined,
          token: "",
          [fieldName]: data,
        },
        needToken: {
          ...state,
          token: "",
          [fieldName]: data,
        },
      };
      const key = fieldName as keyof typeof resetFildsOnChange;

      return (
        resetFildsOnChange[key] ?? {
          ...state,
          [fieldName]: data,
        }
      );

    case operations.resetFields:
      return INITIAL_TABLES_VALUES;
    default:
      return state;
  }
}
