import { Icon, IconProps } from "@chakra-ui/react";

export function MegaphoneIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="27px"
      height="23px"
      viewBox="0 0 27 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M22.875 10.1119C22.875 8.6931 22.049 7.42383 20.7917 6.83112V3.66862C20.7917 2.75612 20.3448 1.91654 19.5953 1.42279C18.8734 0.947791 17.9719 0.868103 17.1766 1.20873L8.18229 5.16394H3.60417C2.16823 5.16394 1 6.33217 1 7.7681V12.4556C1 13.8915 2.16823 15.0598 3.60417 15.0598H4.64583V20.2681C4.64583 21.1296 5.34688 21.8306 6.20833 21.8306H7.25C8.11146 21.8306 8.8125 21.1296 8.8125 20.2681V15.3369L17.1771 19.015C17.1771 19.015 17.1797 19.0165 17.1813 19.0171C17.5047 19.1561 17.8469 19.2244 18.1885 19.2244C18.6802 19.2244 19.1698 19.0816 19.5958 18.8009C20.3448 18.3077 20.7922 17.4681 20.7922 16.5556V13.3931C22.0495 12.7999 22.875 11.5306 22.875 10.1119ZM3.60417 14.0181C2.74271 14.0181 2.04167 13.3171 2.04167 12.4556V7.7681C2.04167 6.90665 2.74271 6.2056 3.60417 6.2056H7.77083V14.0181H3.60417ZM7.77083 20.2681C7.77083 20.5556 7.5375 20.7889 7.25 20.7889H6.20833C5.92083 20.7889 5.6875 20.5556 5.6875 20.2681V15.0598H7.77083V20.2681ZM19.75 16.5556C19.75 17.1165 19.4781 17.6306 19.0224 17.9311C18.5896 18.2165 18.0693 18.2639 17.5943 18.0608L8.81302 14.1994V6.02487L17.5917 2.16394C18.0672 1.95925 18.588 2.00664 19.0219 2.29258C19.4776 2.5931 19.7495 3.10717 19.7495 3.6681L19.75 16.5556ZM20.7917 12.1936V8.03008C21.4339 8.51029 21.8333 9.27592 21.8333 10.1119C21.8333 10.9478 21.4339 11.7134 20.7917 12.1936Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.740741"
      />
      <path
        d="M25.4791 9.591H24.4375C24.1494 9.591 23.9166 9.82434 23.9166 10.1118C23.9166 10.3993 24.1494 10.6327 24.4375 10.6327H25.4791C25.7671 10.6327 26 10.3993 26 10.1118C26 9.82434 25.7671 9.591 25.4791 9.591Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.740741"
      />
      <path
        d="M23.0979 6.94987C23.2161 6.94987 23.3343 6.90977 23.4322 6.828L24.2302 6.15821C24.4505 5.97331 24.4796 5.64466 24.2942 5.42435C24.1098 5.20508 23.7822 5.17487 23.5609 5.35977L22.763 6.02956C22.5427 6.21446 22.5135 6.5431 22.6989 6.76341C22.8015 6.88633 22.9489 6.94935 23.0984 6.94935L23.0979 6.94987Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.740741"
      />
      <path
        d="M23.4318 13.3957C23.211 13.2108 22.8829 13.2394 22.6985 13.4598C22.5136 13.6801 22.5417 14.0087 22.7626 14.1931L23.5605 14.8629C23.6584 14.9447 23.7766 14.9848 23.8948 14.9848C24.0438 14.9848 24.1912 14.9217 24.2943 14.7988C24.4792 14.5785 24.4511 14.2499 24.2303 14.0655L23.4318 13.3957Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.740741"
      />
    </Icon>
  );
}
