import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import api from "../api/api";
import { GetLayout } from "components/get-layout";

export const UsuarioDatafast = () => {
  const [usuarioDatafast, setUsuarioDatafast] = useState({
    enabledDatafast: false,
    usernameDatafast: "",
    passwordDatafast: "",
  });
  const [usuarioDatafastTemp, setUsuarioDatafastTemp] = useState({
    enabledDatafast: false,
    usernameDatafast: "",
    passwordDatafast: "",
  });
  const [loading, setLoading] = useState(true);

  const loadUsuarios = async () => {
    let response = await api.get("/customers/usuario-datafast");
    setUsuarioDatafast(response.data);
    setUsuarioDatafastTemp({ ...response.data });
    setLoading(false);
  };

  useEffect(() => {
    loadUsuarios();
  }, []);

  const hasChanged = () => {
    return (
      usuarioDatafast.enabledDatafast !== usuarioDatafastTemp.enabledDatafast ||
      usuarioDatafast.usernameDatafast !==
        usuarioDatafastTemp.usernameDatafast ||
      usuarioDatafast.passwordDatafast !== usuarioDatafastTemp.passwordDatafast
    );
  };

  const onChangeUsuarioDatafastText = (e) => {
    setUsuarioDatafastTemp({
      ...usuarioDatafastTemp,
      [e.target.id]: e.target.value,
    });
  };

  const onChangeUsuarioDatafastCheckbox = (e) => {
    setUsuarioDatafastTemp({
      ...usuarioDatafastTemp,
      [e.target.id]: e.checked,
    });
  };

  const cancelar = () => {
    setUsuarioDatafastTemp({ ...usuarioDatafast });
  };

  const patchUsuarioDatafast = async (e) => {
    await api.patch("/customers/usuario-datafast", usuarioDatafastTemp);
    loadUsuarios();
  };

  if (loading) {
    return <label>Carregando...</label>;
  } else {
    return (
      <GetLayout>
        <div className="grid">
          <div className="col-12 md:col-6">
            <div className="card">
              <h5>Usuário API</h5>
              <div className="p-fluid formgrid grid">
                <div className="field-checkbox col-12">
                  <Checkbox
                    id="enabledDatafast"
                    name="enabledDatafast"
                    checked={usuarioDatafastTemp.enabledDatafast}
                    onChange={(e) => onChangeUsuarioDatafastCheckbox(e)}
                  />
                  <label htmlFor="enabledDatafast">Ativo</label>
                </div>
                <div className="field col-12">
                  <label htmlFor="usernameDatafast">Usuário</label>
                  <InputText
                    id="usernameDatafast"
                    type="text"
                    value={usuarioDatafastTemp.usernameDatafast}
                    onChange={(e) => onChangeUsuarioDatafastText(e)}
                  />
                </div>
                <div className="field col-12">
                  <label htmlFor="passwordDatafast">Senha</label>
                  <InputText
                    id="passwordDatafast"
                    type="text"
                    value={usuarioDatafastTemp.passwordDatafast}
                    onChange={(e) => onChangeUsuarioDatafastText(e)}
                  />
                </div>
                <div className="field col-6">
                  <Button
                    disabled={!hasChanged()}
                    onClick={() => patchUsuarioDatafast()}
                    label="Salvar"
                    className="mr-2 mb-2"
                  />
                </div>
                <div className="field col-6">
                  <Button
                    disabled={!hasChanged()}
                    onClick={() => cancelar()}
                    label="Cancelar"
                    className="p-button-danger mr-2 mb-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </GetLayout>
    );
  }
};
