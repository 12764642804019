import { DropdownOption } from "components/dropdown";

export const tipoOperacaoINSSArray: DropdownOption[] = [
  { name: "Novo INSS", value: "NOVO_INSS" },
  { name: "Refin. INSS", value: "REFIN_INSS" },
  { name: "Port. INSS", value: "PORT_INSS" },
  { name: "Cartão RMC INSS", value: "CARTAO_RMC_INSS" },
  { name: "Cartão Benefício INSS", value: "CARTAO_BENEFICIO_INSS" },
];

export const tipoOperacaoSIAPEArray: DropdownOption[] = [
  { name: "Novo SIAPE", value: "NOVO_SIAPE" },
  { name: "Refin. SIAPE", value: "REFIN_SIAPE" },
  { name: "Port. SIAPE", value: "PORT_SIAPE" },
  { name: "Cartão RMC SIAPE", value: "CARTAO_RMC_SIAPE" },
  { name: "Cartão Benefício SIAPE", value: "CARTAO_BENEFICIO_SIAPE" },
];

export const conveniosTabelaDigitacao = [
  { name: "INSS", value: "INSS" },
  { name: "SIAPE", value: "SIAPE" },
];
