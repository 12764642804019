import {
  Box,
  Center,
  Flex,
  Grid,
  Spinner,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import { getToken } from "Session";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { sortArrayBy } from "utils/filter-array-by";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useEffect, useState } from "react";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import { makeWebSocketEvent } from "utils/web-socket-events";
import WhatsAppPortal from "../whatsapp-portal";
import { AddWhatsAppServer } from "./add-whatsapp-server";
import { ModalEditSessionWhatsApp } from "../modals/modal-edit-session-name";

export interface WhatsAppSessionListProps {
  id: number;
  nome: string;
  whatsappSessionId: string;
  customerCodigoNova: string;
  customerName: string;
}

export function GridServers() {
  const [loading, setLoading] = useState(true);
  const [servers, setServers] = useState<WhatsAppSessionListProps[]>([]);
  const [seeAll, setSeeAll] = useState(false);
  const [isConnect, setIsConnect] = useState(false);
  const { user } = useApplicationContext();
  const url = process.env.REACT_APP_PUBLIC_WEB_SOCKET!;
  const permitedSeeAll = ["SUPER"].includes(user.userData.type!);
  const { sendJsonMessage, readyState } = useWebSocket<any>(
    url,
    {
      retryOnError: true,
      queryParams: {
        info: window.btoa(
          JSON.stringify({
            token: getToken(),
            client: "tela-whatsapp-sessions",
          })
        ),
      },
      reconnectInterval: 5000,
      reconnectAttempts: Number.MAX_VALUE,
      shouldReconnect: () => true,
      onMessage: ({ data }) => {
        const parsedData = JSON.parse(data);
        makeWebSocketEvent(
          parsedData.state,
          parsedData,
          parsedData.whatsappSessionId
        );
      },
    },
    servers.length !== 0 && isConnect
  );

  const toast = useToast();

  const getServers = async (options?: { all: boolean }) => {
    setLoading(true);
    setIsConnect(false);
    try {
      const { data } = await api.get(
        `/whatsapp-sessions${options?.all ? "?all=true" : ""}`
      );
      setServers(data);
    } catch (e) {
      toast({
        title: "Erro ao carregar sessões whatsapp!",
        status: "error",
        ...toastDefaultStyle,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getServers();
  }, []);

  useEffect(() => {
    if (servers.length) setIsConnect(true);
  }, [servers]);

  const servidores: string[] = [
    ...new Set(
      sortArrayBy(servers, "customerCodigoNova", "ASC").map(
        ({ customerName }) => customerName
      )
    ),
  ];

  return (
    <>
      <Flex justifyContent="space-between" mb="30px">
        <Text fontSize="25" fontWeight="bold">
          Sessões WhatsApp
        </Text>
        <AddWhatsAppServer
          loading={loading}
          servers={servers}
          setLoading={setLoading}
          setServers={setServers}
          sendJsonMessage={sendJsonMessage}
        />
      </Flex>

      <Flex
        justifyContent="flex-start"
        alignItems="center"
        pb={permitedSeeAll ? "1rem" : undefined}
      >
        {permitedSeeAll ? (
          <>
            <Text fontSize="sm" mr="10px">
              Sessões de Todos os Parceiros
            </Text>
            <Switch
              colorScheme="green"
              onChange={(e) => {
                if (e.target.checked) {
                  getServers({ all: true });
                } else getServers();
                setSeeAll(e.target.checked);
              }}
            />
          </>
        ) : null}
      </Flex>
      {loading ? (
        <Center w="100%" h="100%">
          <Spinner />
        </Center>
      ) : (
        <>
          {servidores.map((customer, index) => {
            const isLast = index + 1 === servidores.length;
            const filteredServers = servers.filter(
              ({ customerName }) => customerName === customer
            );

            const customerCode = filteredServers[0]?.customerCodigoNova;
            return (
              <Box w="100%" key={index} mb={isLast ? undefined : "15px"}>
                {seeAll ? (
                  <>
                    <Text fontSize="20px">
                      {customerCode
                        ? `${customerCode} - ${customer}`
                        : customer}
                    </Text>
                    <hr style={{ marginBottom: "10px", marginTop: "10px" }} />
                  </>
                ) : null}
                <Grid gap="25px" templateColumns="1fr 1fr 1fr" w="100%">
                  {filteredServers.map((item) => {
                    return (
                      <WhatsAppPortal
                        key={item.id}
                        setServers={setServers}
                        sendJsonMessage={sendJsonMessage}
                        {...item}
                      />
                    );
                  })}
                </Grid>
              </Box>
            );
          })}
          <ModalEditSessionWhatsApp setServers={setServers} />
        </>
      )}
    </>
  );
}
