import { DeleteIcon } from "@chakra-ui/icons";
import { Box, Grid, IconButton, Text, useToast } from "@chakra-ui/react";
import { bancosContratos } from "components/atendimentos-components/atendimento-form/contracts-table/consts";
import DynamicTable from "components/dynamic-table";
import { Column } from "components/dynamic-table/types";
import AttendanceDropdown from "components/performance/attendance-dropdown";
import { useAttendanceContext } from "contexts/AttendanceContext";
import { numberMask } from "utils/string-formats";
import {
  novoPrazoOptions,
  operacaoOptions,
  valorParcelaReduzida,
} from "./consts";
import OptimizedInput from "components/performance/attendance-input";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";

export function ContractsRefinancingTable({
  onChangeField,
}: {
  onChangeField: (
    fieldName: keyof Attendance,
    value: string | boolean | null
  ) => void;
}) {
  const { formControl, dispatch } = useAttendanceContext();
  const formValues = formControl.values;
  const filteredContracts =
    formValues.contratosEmprestimosInss?.filter(({ simular }) => simular) || [];
  const toast = useToast();
  const isNovoPrazo = ["PORT_REFIN", "REFIN"].includes(
    formValues.simulacaoOperacao!
  );

  const columns: Column[] = [
    {
      name: "Ações",
      render: (row) => {
        return (
          <IconButton
            aria-label=""
            onClick={() => {
              dispatch({
                type: "changeField",
                payload: {
                  data: formValues.contratosEmprestimosInss?.map((contract) => {
                    if (contract.contrato === row.contrato) {
                      contract.simular = false;
                    }
                    return contract;
                  }),
                  fieldName: "contratosEmprestimosInss",
                },
              });
            }}
            size="sm"
            icon={<DeleteIcon />}
          />
        );
      },
    },
    {
      name: "Banco",
      render: ({ codBanco }) => {
        return bancosContratos[codBanco];
      },
    },
    {
      name: "Valor Parcela Original",
      render: ({ valorParcela }) => {
        if (valorParcela)
          return <Text color="#0c0">{`R$ ${valorParcela}`}</Text>;
      },
      cellStyle: () => ({ textAlign: "right" }),
      key: "valorParcela",
    },
    {
      name: "Taxa Original",
      render: ({ taxa }) => {
        if (taxa) return `${taxa}%`;
      },
      cellStyle: () => ({ textAlign: "right" }),
    },
    {
      name: "Nova Taxa",
      render: () => {
        return formValues.simulacaoNovaTaxa + "%";
      },
      cellStyle: () => ({ textAlign: "right" }),
    },
    {
      name: "Valor Parcela Reduzida",
      render: ({ taxa, saldoQuitacao, quantidadeParcelasEmAberto }) => {
        if (
          (!["REFIN", "PORT_REFIN"].includes(formValues.simulacaoOperacao!) ||
            formValues.simulacaoNovoPrazo) &&
          formValues.simulacaoNovaTaxa &&
          saldoQuitacao &&
          formValues.simulacaoOperacao
        ) {
          const result = valorParcelaReduzida(
            formValues.simulacaoNovaTaxa,
            saldoQuitacao,
            formValues as Attendance,
            quantidadeParcelasEmAberto
          );
          return `R$ ${result}`;
        }
      },
      cellStyle: () => ({ textAlign: "right" }),
      key: "valorParcelaReduzida",
    },
    {
      name: "Valor Redução",
      render: ({
        valorParcela,
        taxa,
        saldoQuitacao,
        quantidadeParcelasEmAberto,
      }) => {
        const result = valorParcelaReduzida(
          formValues.simulacaoNovaTaxa,
          saldoQuitacao,
          formValues as Attendance,
          quantidadeParcelasEmAberto
        );
        const valorReducao = result ? valorParcela - result : null;
        if (valorReducao) return `R$ ${valorReducao.toFixed(2)}`;
      },
    },
    {
      name: "Contrato Novo (Oriundo)",
      render: ({
        valorParcela,
        taxa,
        saldoQuitacao,
        quantidadeParcelasEmAberto,
      }) => {
        if (
          formValues.simulacaoTroco?.length &&
          !isNaN(Number(formValues.simulacaoTroco))
        ) {
          const result = valorParcelaReduzida(
            formValues.simulacaoNovaTaxa,
            saldoQuitacao + Number(formValues.simulacaoTroco),
            formValues as Attendance,
            quantidadeParcelasEmAberto
          );
          const valorReducao = result
            ? parseFloat(valorParcela) - result
            : null;
          if (valorReducao && formValues.simulacaoCoeficiente) {
            const contratoNovo = valorReducao / formValues.simulacaoCoeficiente;
            return `R$ ${contratoNovo?.toFixed(2)}`;
          }
        }
      },
      cellStyle: () => ({ textAlign: "right" }),
    },
    {
      name: "Saldo Dev. Aprox.",
      render: ({ saldoQuitacao }) => {
        if (saldoQuitacao != null)
          return <Text color="#00bfff">{`R$ ${saldoQuitacao}`}</Text>;
      },
      cellStyle: () => ({ textAlign: "right" }),
    },
    {
      name: "Parcelas em Aberto",
      render: ({ quantidadeParcelasEmAberto }) => {
        return quantidadeParcelasEmAberto;
      },
      cellStyle: () => ({ textAlign: "right" }),
    },
    {
      name: "Quantidade Parcelas",
      render: ({ quantidadeParcelas }) => {
        return quantidadeParcelas;
      },
      cellStyle: () => ({ textAlign: "right" }),
    },
    {
      name: "Novo Prazo",
      render: () => {
        return formValues.simulacaoNovoPrazo;
      },
      cellStyle: () => ({ textAlign: "right" }),
      isVisible: isNovoPrazo,
    },
    {
      name: "Valor Empréstimo",
      render: ({ valorEmprestado }) => {
        if (valorEmprestado) return `R$ ${valorEmprestado}`;
      },
      cellStyle: () => ({ textAlign: "right" }),
    },
    {
      name: "Contrato",
      key: "contrato",
    },
  ];

  let somaValorParcela = 0;
  let somaValorParcelaReduzida = 0;
  filteredContracts.forEach(
    ({ saldoQuitacao, quantidadeParcelasEmAberto, valorParcela }) => {
      const r = valorParcelaReduzida(
        formValues.simulacaoNovaTaxa,
        saldoQuitacao,
        formValues as Attendance,
        quantidadeParcelasEmAberto
      );
      if (r != null) somaValorParcelaReduzida += r;
      if (valorParcela != null) somaValorParcela += valorParcela;
    }
  );

  const lastRow = {
    valorParcela: somaValorParcela ? `R$ ${somaValorParcela.toFixed(2)}` : null,
    valorParcelaReduzida: somaValorParcelaReduzida
      ? `R$ ${somaValorParcelaReduzida.toFixed(2)}`
      : null,
  };

  return (
    <>
      <Grid
        gridTemplateColumns={
          isNovoPrazo ? "1fr 1fr 1fr 0.5fr 1fr" : "repeat(4, 1fr)"
        }
        gap="16px"
        mb="15px"
      >
        <Box>
          <Text mb="4px">Operação</Text>
          <AttendanceDropdown
            value={formValues.simulacaoOperacao}
            onChangeField={onChangeField}
            fieldName="simulacaoOperacao"
            options={operacaoOptions}
            w="full"
          />
        </Box>
        <Box>
          <Text mb="4px">Nova Taxa</Text>
          <OptimizedInput
            value={formValues.simulacaoNovaTaxa || ""}
            onChangeField={onChangeField}
            fieldName="simulacaoNovaTaxa"
            mask={numberMask}
          />
        </Box>
        <Box>
          <Text mb="4px">Troco</Text>
          <OptimizedInput
            value={formValues.simulacaoTroco || ""}
            onChangeField={onChangeField}
            fieldName="simulacaoTroco"
            mask={numberMask}
          />
        </Box>
        {isNovoPrazo ? (
          <Box>
            <Text mb="4px">Novo Prazo</Text>
            <AttendanceDropdown
              value={formValues.simulacaoNovoPrazo}
              onChangeField={onChangeField}
              fieldName="simulacaoNovoPrazo"
              options={novoPrazoOptions}
              w="full"
            />
          </Box>
        ) : null}
        <Box>
          <Text mb="4px">Coeficiente Nova Operação</Text>
          <OptimizedInput
            value={formValues.simulacaoCoeficiente}
            onChangeField={onChangeField}
            fieldName="simulacaoCoeficiente"
            mask={numberMask}
          />
        </Box>
      </Grid>
      <DynamicTable
        columns={columns}
        rows={filteredContracts}
        containerStyle={{ boxShadow: "0 0 1em rgba(0,0,0, 0.05)", pb: "0" }}
        sortBy="valorParcelaSelecionado"
        sortOrder="DESC"
        emptyMessage="Não há contratos."
        headerStyle={() => ({
          whiteSpace: "normal",
          textAlign: "center",
          py: "10px",
        })}
        lastRow={lastRow}
        isLoading={false}
      />
    </>
  );
}
