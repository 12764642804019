import { useApplicationContext } from "contexts/ApplicationContext";
import { Route, Switch } from "react-router-dom";
import { allRouters } from "routes/routes-array";

export function Routes() {
  const { user } = useApplicationContext();

  return (
    <Switch>
      {allRouters.map(({ path, permission, Children, exact }, index) => {
        if (!permission(user)) return null;
        return (
          <Route key={`route-${index}`} path={path} exact={exact}>
            <Children />
          </Route>
        );
      })}
    </Switch>
  );
}
