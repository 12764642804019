export type Listener = (a: any) => any;
let listeners: Map<string, Listener> = new Map();

export function changeProgressListener(
  eventType: string,
  listener: (e: any) => any
) {
  listeners.set(eventType, listener);
}
export function changeProgress(eventType: string, data?: any) {
  const target = listeners.get(eventType);
  if (target) {
    return target(data);
  } else {
    // throw new Error(`Listerner Not Found ${eventType}`);
  }
}
