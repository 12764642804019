import { Icon, IconProps } from "@chakra-ui/react";

export function DownloadCircleIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="24" cy="24" r="22.5" stroke="currentColor" strokeWidth="3" />
      <rect
        x="18"
        y="12"
        width="12"
        height="16.875"
        rx="2"
        fill="currentColor"
      />
      <path
        d="M24.7221 38.2462C24.3284 38.6572 23.6716 38.6572 23.2779 38.2462L13.4963 28.0355C12.8871 27.3996 13.3378 26.3438 14.2184 26.3438L33.7816 26.3438C34.6622 26.3438 35.1129 27.3996 34.5037 28.0355L24.7221 38.2462Z"
        fill="currentColor"
      />
    </Icon>
  );
}
