import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import { useApplicationContext } from "contexts/ApplicationContext";
import { MenuItemProps, newMenuItens } from "./menu-array";
import { memo, useEffect, useRef, useState } from "react";
import { AnimateElement } from "utils/animations";
import { NewMenuItem } from "./new-menu-item";
import { checkIsVisible } from "./check-is-visible";
import { useHistory } from "react-router-dom";

export const menuWidth = "290px";

export let isMenuOpenState = false;

export let isSubMenuOpen = false;

function SideMenu() {
  const [isFixedOpen, setIsFixedOpen] = useState(true);
  const [isOpenSubmenu, setIsOpenSubmenu] = useState(false);
  const [subMenuOptions, setSubMenuOptions] =
    useState<MenuItemProps["subItems"]>();
  const { user } = useApplicationContext();
  const submenuContainerRef = useRef<HTMLDivElement>(null);
  const router = useHistory();

  const onOpenAnimation = () => {
    const target = submenuContainerRef.current;
    const currentOpacity = target
      ? Number(getComputedStyle(target).opacity)
      : 0;
    AnimateElement(200, [currentOpacity, 1], (progress) => {
      if (target) {
        target.style.opacity = `${progress}`;
        target.style.width = `${progress * 255}px`;
      }
    });
  };

  const onCloseAnimation = () => {
    const target = submenuContainerRef.current;
    const currentOpacity = target
      ? Number(getComputedStyle(target).opacity)
      : 1;
    AnimateElement(200, [currentOpacity, 0], (progress) => {
      if (target) {
        target.style.opacity = `${progress}`;
        target.style.width = `${progress * 255}px`;
      }
    });
  };

  const onOpenSideMenu = () => {
    setIsFixedOpen(true);
  };

  const onCloseSideMenu = () => {
    setIsFixedOpen(false);
  };

  useEffect(() => {
    if (isOpenSubmenu) onOpenAnimation();
    else onCloseAnimation();
  }, [isOpenSubmenu]);

  return (
    <Stack
      pos="relative"
      h="100%"
      borderRight="1px solid #E8EAED"
      pt="20px"
      bg="#fff"
      w={{ base: "100px" }}
      flexDir="column"
      alignItems="center"
      spacing="8px"
      zIndex={10}
    >
      {/* <IconButton
        aria-label=""
        _hover={{ bg: "#F0F0F0" }}
        bg={isFixedOpen ? "#F2F2F2" : "transparent"}
        w="50px"
        h="50px"
        icon={<HamburguerIcon />}
        borderRadius="full"
        onClick={onToggleFixedOpen}
      /> */}

      {newMenuItens.map((item, index) => {
        const isVisible = checkIsVisible(item, user);
        return isVisible ? (
          <NewMenuItem
            isFixedOpen={isFixedOpen}
            setSubMenuOptions={setSubMenuOptions}
            setIsOpenSubmenu={setIsOpenSubmenu}
            key={index}
            {...item}
          />
        ) : null;
      })}

      <Box
        ref={submenuContainerRef}
        id="submenu-container"
        opacity={0}
        pos="absolute"
        top="0"
        bottom="0"
        right="5px"
        transform="translateX(100%)"
        mt="0!important"
        zIndex={10}
        overflow="hidden"
        onMouseLeave={() => {
          setIsOpenSubmenu(false);
        }}
        pl="6px"
      >
        <Box
          px="10px"
          py="20px"
          borderRight="1px solid #E8EAED"
          bg="#fff"
          h="100%"
        >
          {subMenuOptions?.map((submenu, index) => {
            const { href, name } = submenu;
            const thisRouter = window.location.pathname;
            const isActive = thisRouter === href;
            const isVisible = checkIsVisible(submenu, user);

            return isVisible ? (
              <Flex
                key={href || index}
                bg={isActive ? "#FFF3F3" : undefined}
                px="12px"
                alignItems="center"
                h="36px"
                minW="229px"
                borderRadius="10px"
                _hover={isActive ? undefined : { bg: "#F2F2F2" }}
                mb="8px"
                whiteSpace="nowrap"
                onClick={() => router.push(href)}
                cursor="pointer"
              >
                <Box w="8px" h="1px" bg="currentColor" mr="6px" />
                <Text fontSize="14" fontWeight="medium">
                  {name}
                </Text>
              </Flex>
            ) : null;
          })}
        </Box>
      </Box>
    </Stack>
  );
}

export default memo(SideMenu);
