import { Icon, IconProps } from "@chakra-ui/react";

export function ImageIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="235"
      height="197"
      viewBox="0 0 235 197"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        x="32.5"
        y="38.5"
        width="196"
        height="152"
        rx="8.5"
        stroke="#AAAAAA"
        strokeWidth="13"
      />
      <circle cx="75.5" cy="79.5" r="21.5" fill="#D9D9D9" />
      <path
        d="M89 115.5L54 150V170H207.5V122L163 77L106.5 132L89 115.5Z"
        fill="#D9D9D9"
      />
      <path
        d="M89.0541 32.0001L191.205 13.4688C192.292 13.2716 193.333 13.9928 193.53 15.0797L196.599 32.0001H209.811L206.321 12.7592C204.842 4.60799 197.036 -0.801151 188.884 0.67757L12.7591 32.6286C4.60786 34.1073 -0.801273 41.914 0.677448 50.0652L24.7746 182.897C25.3227 185.919 26.7404 188.563 28.7227 190.62C27.0072 188.181 26 185.208 26 182V116.822L13.4687 47.7447C13.2715 46.6579 13.9927 45.617 15.0796 45.4198L26.4452 43.358C28.0725 36.8337 33.9718 32.0001 41 32.0001H89.0541Z"
        fill="#AAAAAA"
      />
    </Icon>
  );
}
