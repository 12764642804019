import { DeleteIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import api from "api/api";
import { In100Props } from "pages/in100";
import { useState, Dispatch, SetStateAction } from "react";

export function In100DeleteButton({
  row,
  setList,
}: {
  row: In100Props;
  setList: Dispatch<SetStateAction<In100Props[]>>;
}) {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      //   const { data, status } = await api.get(`/in100/${row.id}`);
      //   if (status === 200)
      //     setList((list) => list.filter((curr) => curr.id !== row.id));
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <IconButton
      aria-label=""
      variant="danger"
      icon={<DeleteIcon />}
      isLoading={loading}
      onClick={handleDelete}
    />
  );
}
