export const selectOperator: any = {
  ignore: "",
  range: "between",
  "0": "equal",
  "1": "equal",
  equal: "in",
  "not_in-codbanco": "not_in",
  "equal-codbanco": "in",
};

export const selectValue: any = {
  ignore: [],
  "0": [0],
  "1": [1],
  range: [0, 0],
  equal: [0],
  in: [0],
  "not_in-codbanco": [],
  "equal-codbanco": [],
};

export const banks: { name: string; value: string }[] = [
  { value: "001", name: "Banco do Brasil" },
  { value: "003", name: "Banco da Amazônia" },
  { value: "004", name: "Banco do Nordeste" },
  { value: "012", name: "Banco Inbursa" },
  { value: "021", name: "Banestes" },
  { value: "025", name: "Banco Alfa" },
  { value: "029", name: "Banco Itau Consignado" },
  { value: "033", name: "Banco Santander" },
  { value: "036", name: "Banco Bradesco - BBI" },
  { value: "037", name: "Banco do Estado do Pará" },
  { value: "040", name: "Banco Cargill" },
  { value: "041", name: "Banrisul" },
  { value: "047", name: "Banco do Estado do Sergipe" },
  { value: "062", name: "Hipercard" },
  { value: "063", name: "Banco Bradescard" },
  { value: "065", name: "Banco AndBank" },
  { value: "066", name: "Banco Morgan Stanley" },
  { value: "069", name: "Banco Crefisa" },
  { value: "070", name: "Banco de Brasília - BRB" },
  { value: "074", name: "Banco J.Safra" },
  { value: "075", name: "Banco ABN Amro" },
  { value: "076", name: "Banco KDB" },
  { value: "081", name: "PagBank" },
  { value: "096", name: "Banco B3" },
  { value: "104", name: "Caixa Econômica Federal" },
  { value: "107", name: "Banco BOCOM BBM" },
  { value: "121", name: "Banco Agibank" },
  { value: "184", name: "Banco Itaú BBA" },
  { value: "208", name: "Banco BTG Pactual" },
  { value: "212", name: "Banco Original" },
  { value: "213", name: "Banco Arbi" },
  { value: "217", name: "Banco Joh Deere" },
  { value: "218", name: "Banco BS2" },
  { value: "222", name: "Banco Credit Agricole Brasil" },
  { value: "224", name: "Banco Fibra" },
  { value: "233", name: "Banco Cifra" },
  { value: "237", name: "Banco Bradesco" },
  { value: "241", name: "Banco Clássico" },
  { value: "243", name: "Banco Máxima" },
  { value: "246", name: "Banco ABC Brasil" },
  { value: "249", name: "Banco Investcred Unibanco" },
  { value: "250", name: "Banco de Crédito e Varejo" },
  { value: "254", name: "Paraná Banco" },
  { value: "265", name: "Banco Fator" },
  { value: "266", name: "Banco Cédula" },
  { value: "318", name: "Banco BMG" },
  // { value: "320", name: "Banco China Construction Bank" },
  { value: "341", name: "Itau Unibanco" },
  { value: "366", name: "Banco Societe Generale Brasil" },
  { value: "370", name: "Banco Mizuho do Brasil" },
  { value: "376", name: "Banco JP Morgan" },
  { value: "389", name: "Banco Mercantil do Brasil" },
  { value: "394", name: "Banco Bradesco Financiamentos" },
  { value: "399", name: "Kirton Bank" },
  { value: "412", name: "Banco Capital" },
  { value: "422", name: "Banco Safra" },
  { value: "456", name: "Banco MUFG" },
  { value: "464", name: "Banco Sumitomo Mitsui Brasileiro" },
  { value: "477", name: "Citibank" },
  { value: "479", name: "Banco ItauBank" },
  { value: "487", name: "Deutsche Bank" },
  { value: "488", name: "JPMorgan Chase Bank" },
  { value: "492", name: "ING Bank" },
  { value: "495", name: "Banco La Provincia de Buenos Aires" },
  { value: "505", name: "Banco Credit Suisse" },
  { value: "600", name: "Banco Luso Brasileiro" },
  { value: "604", name: "Banco Industrial do Brasil" },
  { value: "610", name: "Banco VR" },
  { value: "611", name: "Banco Paulista" },
  { value: "612", name: "Banco Guanabara" },
  { value: "613", name: "Omni Banco" },
  { value: "623", name: "Banco Pan" },
  { value: "626", name: "Banco C6 - FICSA" },
  { value: "630", name: "Banco SmartBank" },
  { value: "633", name: "Banco Rendimento" },
  { value: "634", name: "Banco Triângulo" },
  { value: "637", name: "Banco Sofisa" },
  { value: "643", name: "Banco Pine" },
  { value: "652", name: "Itau Unibanco Holding" },
  { value: "653", name: "Banco Indusval" },
  { value: "654", name: "Banco A.J. Renner" },
  { value: "655", name: "Banco Votorantim" },
  { value: "707", name: "Banco Daycoval" },
  { value: "739", name: "Banco Cetelem" },
  { value: "741", name: "Banco Ribeirao Preto" },
  { value: "743", name: "Banco Semear" },
  { value: "745", name: "Banco Citibank" },
  { value: "746", name: "Banco Modal" },
  { value: "747", name: "Banco Rabobank International Brasil" },
  { value: "748", name: "Banco Cooperativo Sicredi" },
  { value: "751", name: "Scotiabank Brasil" },
  { value: "752", name: "Banco BNP Paribas Brasil" },
  { value: "753", name: "Novo Banco Continental" },
  { value: "756", name: "Banco Cooperativo do Brasil" },
  { value: "757", name: "Banco KEB HANA do Brasil" },
  { value: "935", name: "FACTA Financeira" },
];
