import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
// import { Toast } from 'primereact/toast';
import { DeleteIcon } from "@chakra-ui/icons";
import { GetLayout } from "components/get-layout";
import { ConfirmDialog } from "components/modal-confirm";
import { BlockUI } from "primereact/blockui";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import api from "../api/api";
import { useToast } from "@chakra-ui/react";
import { toastDefaultStyle } from "chakra/theme";

export const MargemAumentoLista = () => {
  const [confirmDialogData, setConfirmDialogData] = useState(null);
  const fileUploadRef = useRef(null);
  const [margemAumentoListaDialog, setMargemAumentoListaDialog] = useState({
    nome: "",
    banco: "",
    file: "",
    filename: "",
  });
  const [
    margemAumentoListaDownloadDialog,
    setMargemAumentoListaDownloadDialog,
  ] = useState({});
  const [loading, setLoading] = useState(false);
  const [openMargemAumentoListaDialog, setOpenMargemAumentoListaDialog] =
    useState(false);
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
  const [margemAumentoListas, setMargemAumentoListas] = useState([]);
  const [errors, setErrors] = useState({ nome: false });
  const [openResumoDialog, setOpenResumoDialog] = useState(false);
  const [resumo, setResumo] = useState({});
  const [coeficiente, setCoeficiente] = useState("");
  const toast = useToast();

  const saveOrUpdateMargemAumentoLista = async () => {
    let hasNomeError =
      !margemAumentoListaDialog.nome ||
      margemAumentoListaDialog.nome.trim().length === 0;
    let hasBancoError = !margemAumentoListaDialog.banco;
    let hasFileError = !margemAumentoListaDialog.file;
    setErrors({ nome: hasNomeError, banco: hasBancoError, file: hasFileError });
    if (hasNomeError || hasBancoError || hasFileError) return;
    setLoading(true);
    let formData = new FormData();
    formData.append("files", margemAumentoListaDialog.file);
    formData.append("filename", margemAumentoListaDialog.filename);
    formData.append("nome", margemAumentoListaDialog.nome);
    formData.append("banco", margemAumentoListaDialog.banco);
    try {
      let response = await api.post("/margem-aumento-listas", formData);
      setResumo(response.data);
      setOpenResumoDialog(true);
    } catch (error) {
      let hasMessage =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message;
      if (
        hasMessage &&
        "column_cpf_not_found" === error.response.data.message
      ) {
        toast({
          title: "Erro ao criar lista: Arquivo não possui coluna CPF!",
          status: "error",
          ...toastDefaultStyle,
        });
      } else if (
        hasMessage &&
        "duplicate_column_cpf_found" === error.response.data.message
      ) {
        toast({
          title: "Erro ao criar lista: Arquivo possui mais de uma coluna CPF!",
          status: "error",
          ...toastDefaultStyle,
        });
      } else {
        toast({
          title: "Erro ao criar lista!",
          status: "error",
          ...toastDefaultStyle,
        });
      }
    }
    setOpenMargemAumentoListaDialog(false);
    await loadMargemAumentoListas();
    setLoading(false);
  };

  const download = async (rowData) => {
    let coeficienteTratado = coeficiente.replace(",", ".");
    if (coeficienteTratado === "" || isNaN(coeficienteTratado)) {
      setErrors({ ...errors, coeficiente: true });
      return;
    }
    setLoading(true);
    let response;
    try {
      response = await api.get(
        "/margem-aumento-listas/download/" +
          rowData.id +
          "?coeficiente=" +
          coeficienteTratado
      );
      let filename = rowData.nome + ".csv";
      createLinkAndDownload(response, filename);
    } catch (error) {
      toast({
        title: "Erro ao realizar download!",
        status: "error",
        ...toastDefaultStyle,
      });
    }
    setLoading(false);
    setOpenDownloadDialog(false);
  };

  const createLinkAndDownload = (response, filename) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const onChangeCoeficiente = (coeficiente) => {
    setErrors({ ...errors, coeficiente: false });
    let novoCoeficiente = "";
    let casasAlemVirgula = 0;
    let passouVirgula = false;
    for (let i = 0; i < coeficiente.length; i++) {
      if (
        ["0", "9", "8", "7", "6", "5", "4", "3", "2", "1"].includes(
          coeficiente[i]
        )
      ) {
        if (passouVirgula) {
          if (casasAlemVirgula < 6) {
            novoCoeficiente += coeficiente[i];
            casasAlemVirgula++;
          }
        } else {
          novoCoeficiente += coeficiente[i];
        }
      } else if ([",", "."].includes(coeficiente[i])) {
        if (!passouVirgula) {
          novoCoeficiente += coeficiente[i];
          passouVirgula = true;
        }
      }
    }
    setCoeficiente(novoCoeficiente);
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await loadMargemAumentoListas();
      setLoading(false);
    };
    load();
  }, []);

  const loadMargemAumentoListas = async () => {
    let response = await api.get("/margem-aumento-listas");
    setMargemAumentoListas(response.data);
  };

  const atualizar = async () => {
    setLoading(true);
    await loadMargemAumentoListas();
    setLoading(false);
  };

  const onChangeMargemAumentoListaDialog = (field, e) => {
    setErrors({ ...errors, [field]: false });
    setMargemAumentoListaDialog({
      ...margemAumentoListaDialog,
      [field]: e.target.value,
    });
  };

  const onChangeFile = (e) => {
    setErrors({ ...errors, file: false });
    setMargemAumentoListaDialog({
      ...margemAumentoListaDialog,
      file: e.target.files[0],
      filename: e.target.files[0].name,
    });
  };

  const openNew = () => {
    setErrors({});
    setMargemAumentoListaDialog({
      nome: "",
      banco: "",
      file: "",
      filename: "",
    });
    setOpenMargemAumentoListaDialog(true);
  };

  const margemAumentoListaTableHeader = (
    <div className="table-header">
      Lista Margem Aumento
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <Button
          label="Atualizar"
          icon="pi pi-plus"
          className="p-button-primary mr-2"
          onClick={atualizar}
          style={{ width: "150px" }}
        />
        <Button
          label="Nova"
          icon="pi pi-plus"
          className="p-button-primary mr-2"
          onClick={openNew}
          style={{ width: "150px" }}
        />
      </span>
    </div>
  );

  const nameTemplate = (rowData) => {
    return rowData.nome;
  };

  const bodyTemplate = (data, props) => (
    <>
      <span className="p-column-title">{props.header}</span>
      {data[props.field]}
    </>
  );
  const dateBodyTemplate = (data, props) => (
    <>
      <span className="p-column-title">{props.header}</span>
      {parseDate(data[props.field])}
    </>
  );

  const parseDate = (value) => {
    return (
      value.substring(8, 10) +
      "/" +
      value.substring(5, 7) +
      "/" +
      value.substring(0, 4)
    );
  };

  const dropdownBancos = [
    // { name: 'PAN', value: 'PAN' },
    // { name: 'BMG', value: 'BMG' },
    // { name: 'BRADESCO', value: 'BRADESCO' },
    // { name: 'ITAÚ', value: 'ITAU' },
    // { name: 'SAFRA', value: 'SAFRA' },
    { name: "OLÉ", value: "OLE" },
    // { name: 'C6', value: 'C6' },
    // { name: 'DAYCOVAL', value: 'DAYCOVAL' },
    // { name: 'BANRISUL', value: 'BANRISUL' },
    // { name: 'CETELEM', value: 'CETELEM' }
  ];

  const actionTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-download"
          className="p-button-rounded p-button-primary mr-1"
          onClick={() => abrirDownloadDialog(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => confirmDeleteMargemAumentoLista(rowData)}
        />
      </div>
    );
  };

  const margemAumentoListaDialogFooter = (
    <>
      <Button
        label="Salvar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => saveOrUpdateMargemAumentoLista()}
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => hideMargemAumentoListaDialog()}
      />
    </>
  );

  const resumoDialogFooter = (
    <Button
      label="Fechar"
      icon="pi pi-times"
      className="p-button-text"
      onClick={() => hideResumoDialog()}
    />
  );

  const downloadDialogFooter = (
    <Button
      label="Fechar"
      icon="pi pi-times"
      className="p-button-text"
      onClick={() => hideDownloadDialog()}
    />
  );

  const hideDownloadDialog = () => {
    setOpenDownloadDialog(false);
  };

  const hideMargemAumentoListaDialog = () => {
    setOpenMargemAumentoListaDialog(false);
  };

  const hideResumoDialog = () => {
    setOpenResumoDialog(false);
  };

  const abrirDownloadDialog = (rowData) => {
    setCoeficiente("");
    setMargemAumentoListaDownloadDialog({ ...rowData });
    setOpenDownloadDialog(true);
  };

  const acceptDeleteMargemAumentoLista = async (rowData) => {
    setLoading(true);
    try {
      await api.delete("/margem-aumento-listas/" + rowData.id);
    } catch (error) {
      toast({
        title: "Erro ao excluir lista!",
        status: "error",
        ...toastDefaultStyle,
      });
    }
    await loadMargemAumentoListas();
    setLoading(false);
  };

  // const rejectDeleteMargemAumentoLista = () => {
  //     // toast.current.show({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
  // };

  const confirmDeleteMargemAumentoLista = (rowData) => {
    setConfirmDialogData({
      message: `Deseja excluir "${rowData.nome}"?`,
      title: "Confirmação",
      accept: () => acceptDeleteMargemAumentoLista(rowData),
      close: () => setConfirmDialogData(null),
      acceptButtonStyle: { variant: "danger", leftIcon: <DeleteIcon /> },
    });
  };

  const getLoadingTemplate = () => {
    return (
      <ProgressSpinner
        style={{ width: "150px", height: "150px" }}
        strokeWidth="3"
        animationDuration=".5s"
      />
    );
  };

  return (
    <>
      <ConfirmDialog dialogData={confirmDialogData} />
      <GetLayout>
        <div className="grid table-demo">
          <div className="col-12">
            <div className="card">
              <BlockUI
                blocked={loading}
                fullScreen
                template={getLoadingTemplate}
              ></BlockUI>
              <DataTable
                value={margemAumentoListas}
                paginator
                className="p-datatable-customers"
                rows={10}
                dataKey="id"
                rowHover
                emptyMessage="Não há listas cadastradas"
                header={margemAumentoListaTableHeader}
                size="small"
              >
                <Column
                  field="nome"
                  header="Nome"
                  sortable
                  body={nameTemplate}
                ></Column>
                <Column
                  field="banco"
                  header="Banco"
                  sortable
                  body={bodyTemplate}
                ></Column>
                <Column
                  field="criacao"
                  header="Criação"
                  sortable
                  body={dateBodyTemplate}
                ></Column>
                <Column header="Ações" body={actionTemplate}></Column>
              </DataTable>

              <Dialog
                visible={openMargemAumentoListaDialog}
                style={{ width: "450px" }}
                header="Nova Lista"
                modal
                className="p-fluid"
                footer={margemAumentoListaDialogFooter}
                onHide={hideMargemAumentoListaDialog}
              >
                <div className="field">
                  <label htmlFor="nomeDialog">Nome</label>
                  <InputText
                    id="nomeDialog"
                    value={margemAumentoListaDialog.nome}
                    className={errors.nome && "p-invalid"}
                    autoComplete="new-password"
                    onChange={(e) =>
                      onChangeMargemAumentoListaDialog("nome", e)
                    }
                  />
                  {errors.nome && (
                    <small className="p-invalid">Informe um nome</small>
                  )}
                </div>
                <div className="field">
                  <label htmlFor="bancoDialog">Banco</label>
                  <Dropdown
                    id="bancoDialog"
                    value={margemAumentoListaDialog.banco}
                    className={errors.banco && "p-invalid"}
                    onChange={(e) =>
                      onChangeMargemAumentoListaDialog("banco", e)
                    }
                    options={dropdownBancos}
                    optionLabel="name"
                    placeholder="Selecione"
                  />
                  {errors.banco && (
                    <small className="p-invalid">Selecione um banco</small>
                  )}
                </div>
                <input
                  ref={fileUploadRef}
                  hidden
                  type="file"
                  onChange={(e) => onChangeFile(e)}
                />
                <div className="grid">
                  <div className="field col-6">
                    <Button
                      label="Selecionar arquivo"
                      icon="pi pi-plus"
                      onClick={() => {
                        fileUploadRef.current.click();
                      }}
                    ></Button>
                    {errors.file && (
                      <small className="p-invalid">Selecione um arquivo</small>
                    )}
                  </div>
                </div>
                <div className="field">
                  <label>{margemAumentoListaDialog.filename}</label>
                </div>
              </Dialog>
              <Dialog
                visible={openDownloadDialog}
                style={{ width: "220px" }}
                header="Download"
                modal
                className="p-fluid"
                footer={downloadDialogFooter}
                onHide={hideDownloadDialog}
              >
                <div className="grid">
                  <div className="field col-12">
                    <label htmlFor="coeficiente">Coeficiente</label>
                    <InputText
                      id="coeficiente"
                      value={coeficiente}
                      className={errors.coeficiente && "p-invalid"}
                      onChange={(e) => onChangeCoeficiente(e.target.value)}
                      autoComplete="new-password"
                    />
                    {errors.coeficiente && (
                      <small className="p-invalid">Coeficiente inválido</small>
                    )}
                  </div>
                  <div className="field col-12">
                    <Button
                      label="Download"
                      onClick={() => {
                        download(margemAumentoListaDownloadDialog);
                      }}
                    ></Button>
                  </div>
                </div>
              </Dialog>
              <Dialog
                visible={openResumoDialog}
                style={{ width: "450px" }}
                header="Resumo"
                modal
                className="p-fluid"
                footer={resumoDialogFooter}
                onHide={hideResumoDialog}
              >
                <p>
                  <b>
                    Qtd. de CPFs válidos e adicionados: {resumo.qtdCpfsValidos}
                  </b>
                </p>
                {resumo.qtdCpfsRepetidos > 0 && (
                  <p>Qtd. de CPFs repetidos: {resumo.qtdCpfsRepetidos}</p>
                )}
                {resumo.qtdCpfsInvalidos > 0 && (
                  <p>Qtd. de CPFs inválidos: {resumo.qtdCpfsInvalidos}</p>
                )}
              </Dialog>
            </div>
          </div>
        </div>
      </GetLayout>
    </>
  );
};
