import "react-app-polyfill/ie11";
import ReactDOM from "react-dom/client";
import App from "./App";
import { ChakraProvider, createStandaloneToast } from "@chakra-ui/react";
import theme from "chakra/theme";
import { BrowserRouter } from "react-router-dom";
import { ModalConfirm } from "components/modal-confirm-new";
import { ModalError } from "components/modal-error";
import { ModalInfo } from "components/modal-info";

const { ToastContainer } = createStandaloneToast();

const container = document.getElementById("root");

const root = ReactDOM.createRoot(container);

root.render(
  <ChakraProvider theme={theme}>
    <BrowserRouter>
      <ModalConfirm />
      <ModalError />
      <ModalInfo />
      <App />
      <ToastContainer />
    </BrowserRouter>
  </ChakraProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
