import { Checkbox } from "components/checkbox";
import DynamicTable from "components/dynamic-table";
import { Column } from "components/dynamic-table/types";
import { useAttendanceContext } from "contexts/AttendanceContext";

export function TabelaAntecipacoesFGTS() {
  const { formControl, dispatch } = useAttendanceContext();
  const formValues = formControl.values;
  const simulacaoAntecipacaoFactaFGTS =
    formValues.simulacaoAntecipacaoFactaFGTS || [];

  const onChangeCheckBoxes = (isChecked: boolean, row: any) => {
    let isExecuted = false;
    const changeChecked = simulacaoAntecipacaoFactaFGTS.map((crrRow, index) => {
      if (row.sequence - 1 === index) {
        const nextCheckBox = simulacaoAntecipacaoFactaFGTS[index + 1];
        isExecuted = true;
        if (!isChecked && nextCheckBox?.checked)
          return { ...crrRow, checked: true };
        return { ...crrRow, checked: isChecked };
      }
      if (!isExecuted) {
        return { ...crrRow, checked: true };
      }
      return { ...crrRow, checked: false };
    });
    dispatch({
      type: "changeFieldWithInitial",
      payload: {
        data: changeChecked,
        fieldName: "simulacaoAntecipacaoFactaFGTS",
      },
    });
  };

  const columns: Column[] = [
    {
      name: "",
      render: (row) => (
        <Checkbox
          onChange={(isChecked) => onChangeCheckBoxes(isChecked, row)}
          isChecked={row.checked}
        />
      ),
    },
    {
      name: "Data",
      render: (row) =>
        row.data ? new Date(row.data).toLocaleDateString() : "",
    },
    {
      name: "Valor Descontado",
      render: (row) => `R$ ${Number(row.valorDescontado).toFixed(2)}`,
    },
  ];

  return (
    <DynamicTable
      containerStyle={{ boxShadow: "0 0 1em rgba(0,0,0,0.02)" }}
      columns={columns}
      rows={simulacaoAntecipacaoFactaFGTS}
      onClickRow={(row) => onChangeCheckBoxes(!row.checked, row)}
    />
  );
}
