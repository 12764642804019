import { Icon, IconProps } from "@chakra-ui/react";

export function CreditIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect width="18" height="18" rx="9" fill="#00D008" />
      <rect x="8.1001" y="4.5" width="1.8" height="9" rx="0.9" fill="white" />
      <rect
        x="4.5"
        y="9.90002"
        width="1.8"
        height="9"
        rx="0.9"
        transform="rotate(-90 4.5 9.90002)"
        fill="white"
      />
    </Icon>
  );
}
