import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Input,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { dropdownStatus } from "components/atendimentos-components/atendimento-form/fields-data";
import {
  ModalAtendimento,
  openModalAttendance,
} from "components/atendimentos-components/modal-atendimento";
import { ModalChangeUser } from "components/atendimentos-components/modal-change-user";
import {
  NewAttendanceModal,
  openNewAttendanceModal,
} from "components/atendimentos-components/new-attendance-modal";
import { Calendar } from "components/calendar";
import CustomTable from "components/custom-table-pagined";
import { Dropdown } from "components/dropdown";
import { RowStatus } from "components/dynamic-table/row-status";
import { openModalConfirm } from "components/modal-confirm-new";
import { Popover, closePopover } from "components/popover";
import { CalendarIcon } from "components/vectors/calendar-icon";
import { ChangeIcon } from "components/vectors/change-icon";
import { WhatsAppIcon } from "components/vectors/whatsapp-icon";
import { useApplicationContext } from "contexts/ApplicationContext";
import { AttendanceProvider } from "contexts/AttendanceContext";
import { useEffect, useRef, useState } from "react";
import { createEventListener } from "services/events";
import { exportFunction } from "services/get-external-function";
import { cpfFormat, phoneFormat } from "utils/string-formats";

export function Atendimento() {
  const { user } = useApplicationContext();
  const [changeUserModalData, setChangeUserModalData] = useState(null);
  const [atendimentos, setAtendimentos] = useState([]);
  const [atendimentosLength, setAtendimentosLength] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dropdownUsers, setDropdownUsers] = useState([]);
  const [dropdowns, setDropdowns] = useState({ status: [], users: [] });
  const [cpfNbPhone, setCPFNBPhone] = useState("");
  const [order, setOrder] = useState([]);
  const [filtros, setFiltros] = useState({
    page: 0,
    listStatus: [],
    cpfNbFone: "",
    userIds: [],
    dataCriacaoInicial: "",
    dataCriacaoFinal: "",
    orderType: "DESC",
    orderBy: null,
  });
  const timesRunCpfNbFone = useRef(0);
  const timesRunListStatus = useRef(0);
  const timesRunUserIds = useRef(0);
  const timesRunOrder = useRef(0);

  const toast = useToast(toastDefaultStyle);

  function getOrigem(origem) {
    const origin = {
      LANDING_PAGE: "Landing page",
      WABOX: "WhatsApp",
      TELEFONIA: "Telefonia",
      MANUAL: "Manual",
      WHATSAPP: "WhatsApp",
      URA_WHATSAPP: "URA WhatsApp",
      SMS: "SMS",
    };

    return origin[origem] ?? origem;
  }

  const getDescConvenio = (convenio) => {
    return (
      { ANTECIPACAO_FGTS: "Antecipação FGTS", SIAPE: "SIAPE", INSS: "INSS" }[
        convenio
      ] ?? convenio
    );
  };

  const makeAttendance = async (
    atendimentoTemplate,
    withOutConfirm,
    createWith3cPlus
  ) => {
    setLoading(true);
    try {
      const { data } = await api.post(
        createWith3cPlus ? "/atendimentos/create-from-3cplus" : "/atendimentos",
        atendimentoTemplate
      );
      await loadAtendimentos();
      if (!withOutConfirm) confirmIniciarAtendimento(data);
      else acceptIniciarAtendimento(data.id);
      return data;
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  exportFunction(
    "makeAttendance",
    async ({ mailingData, createWith3cPlus }) => {
      // pegar numero telefone mailingData.phone e setar no atendimento
      if (mailingData)
        await makeAttendance(mailingData, true, createWith3cPlus);
      else
        toast({
          title: "Sem dados para iniciar o atendimento",
          ...toastDefaultStyle,
        });
    }
  );

  useEffect(() => {
    timesRunCpfNbFone.current++;
    if (timesRunCpfNbFone.current > 1) {
      const delay = setTimeout(() => {
        onChangeFiltro("cpfNbFone", cpfNbPhone);
      }, 700);
      return () => clearTimeout(delay);
    }
  }, [cpfNbPhone]);

  useEffect(() => {
    timesRunOrder.current++;
    if (timesRunOrder.current > 1) {
      const delay = setTimeout(() => {
        onChangeFiltro(["orderBy", "orderType"], order);
      }, 700);
      return () => clearTimeout(delay);
    }
  }, [order]);

  useEffect(() => {
    timesRunListStatus.current++;
    if (timesRunListStatus.current > 1) {
      const delay = setTimeout(() => {
        onChangeFiltro("listStatus", dropdowns.status);
      }, 1000);
      return () => clearTimeout(delay);
    }
  }, [dropdowns.status]);

  useEffect(() => {
    timesRunUserIds.current++;
    if (timesRunUserIds.current > 1) {
      const delay = setTimeout(() => {
        onChangeFiltro("userIds", dropdowns.users);
      }, 1000);
      return () => clearTimeout(delay);
    }
  }, [dropdowns.users]);

  useEffect(() => {
    loadAtendimentos(true, { page: 0 });
    api
      .get("usuarios/possible-transfer-attendance")
      .then(({ data }) =>
        setDropdownUsers(
          data.map((item) => ({ name: item.name, value: item.id }))
        )
      );
  }, []);

  const loadAtendimentos = async (
    withLoading = false,
    {
      page = filtros.page,
      cpfNbFone = filtros.cpfNbFone,
      listStatus = filtros.listStatus,
      userIds = filtros.userIds,
      dataCriacaoInicial = filtros.dataCriacaoInicial,
      dataCriacaoFinal = filtros.dataCriacaoFinal,
      somenteNaoLidos = undefined,
      orderBy = filtros.orderBy,
      orderType = filtros.orderType,
    } = {}
  ) => {
    if (withLoading) setLoading(true);
    try {
      const { data } = await api.post("atendimentos/filter", {
        page,
        cpfNbFone,
        listStatus,
        userIds,
        dataCriacaoInicial,
        dataCriacaoFinal,
        somenteNaoLidos,
        orderBy,
        orderType,
      });
      setAtendimentos(data.atendimentos || []);
      setAtendimentosLength(data.size || 0);
      // setFiltros((filtros) => ({ ...filtros, page: 0 }));
    } catch (e) {
      toast({
        title: `Não foi possível carregar os atendimentos.`,
        description: `Tente recarregar a página`,
      });
    } finally {
      if (withLoading) setLoading(false);
    }
  };

  const atualizar = async () => {
    await loadAtendimentos(true);
  };
  createEventListener("updateAttendances", atualizar);

  const onChangeFiltro = (field, value) => {
    const updatedFilter = { ...filtros };
    if (Array.isArray(field)) {
      field.forEach((key, index) => {
        updatedFilter[key] = value[index];
      });
      setFiltros(updatedFilter);
    } else {
      updatedFilter[field] = value;
      setFiltros(updatedFilter);
    }
    loadAtendimentos(true, updatedFilter);
  };

  const onClickAtendimento = (rowData) => {
    if (rowData.status === "PENDENTE") confirmIniciarAtendimento(rowData);
    else if (rowData.status !== "PENDENTE") openAtendimento(rowData.id);
  };

  const openAtendimento = async (id) => {
    await openModalAttendance({ id });
    // await loadAtendimentos();
  };

  const confirmIniciarAtendimento = (rowData) => {
    openModalConfirm({
      message: "Deseja iniciar o atendimento?",
      onConfirm: () => acceptIniciarAtendimento(rowData.id),
      confirmButtonStyle: { variant: undefined, leftIcon: <CheckIcon /> },
      rejectButtonStyle: { leftIcon: <CloseIcon w="12px" h="12px" /> },
    });
  };

  const acceptIniciarAtendimento = async (id) => {
    await openModalAttendance({ id, init: true });
    loadAtendimentos();
  };

  const columns = [
    {
      name: "Criação",
      key: "dataCriacao",
      render: ({ dataCriacao }) => dataCriacao,
      sortClick: true,
    },
    {
      name: "Atualização",
      key: "dataAtualizacao",
      render: ({ dataAtualizacao }) => dataAtualizacao,
      sortClick: true,
    },
    {
      name: "CPF",
      key: "cpf",
      render: (row) => (row.cpf ? cpfFormat(row.cpf) : "Não informado"),
      sortClick: false,
    },
    {
      name: "Nome",
      key: "firstName",
      render: ({ firstName }) => firstName,
      sortClick: false,
    },
    {
      name: "Origem",
      key: "origem",
      sortClick: true,
      render: (row) => getOrigem(row.origem),
    },
    {
      name: <WhatsAppIcon />,
      key: "unreadMessage",
      render: (row) => (row.unreadMessage ? <WhatsAppIcon /> : null),
      sortClick: true,
    },
    {
      name: "Convênio",
      key: "convenio",
      render: (row) => getDescConvenio(row.convenio),
      sortClick: true,
    },
    {
      name: "Status Atend.",
      key: "status",
      sortClick: true,
      render: (row) =>
        row.status ? (
          <RowStatus status={row.status}>
            {{
              NAO_E_O_CLIENTE: "Não é o cliente",
              PERSISTIR_MUITO_TOP: "Persistir muito (Top)",
              NADA_A_OFERECER: "Nada a oferecer",
              EM_NEGOCIACAO: "Em negociação",
              SEM_INTERESSE_QUENTE: "Sem interesse (Quente)",
            }[row.status] ?? row.status.toLowerCase().replace("_", " ")}
          </RowStatus>
        ) : null,
    },
    {
      name: "Telefone",
      key: "telefoneContatoNormalizado",
      render: (row) => phoneFormat(row.telefoneContatoNormalizado),
      sortClick: false,
    },
    {
      name: "Usuário",
      key: "userName",
      render: ({ userName, id }) => {
        const changeUserPermission = [
          "PARCEIRO_MASTER",
          "COMERCIAL_REGIONAL",
          "SUPER",
        ].includes(user.userData?.type);

        if (changeUserPermission)
          return (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                setChangeUserModalData({ id });
              }}
              w="100%"
              variant="outline"
              leftIcon={<ChangeIcon />}
              size="sm"
              iconSpacing={userName ? "0.5rem" : "0"}
            >
              {userName}
            </Button>
          );

        return userName;
      },
      sortClick: true,
    },
    {
      isVisible: ["PARCEIRO_MASTER", "MASTER", "SUPER"].includes(
        user.userData.type
      ),
      name: "Ações",
      render: ({ id }) => {
        return (
          <IconButton
            icon={<DeleteIcon />}
            variant="danger"
            onClick={(e) => {
              e.stopPropagation();
              openModalConfirm({
                onConfirm: async () => {
                  try {
                    const resp = await api.delete(`atendimentos/${id}`);
                    toast({
                      title: "Atendimento Deletado",
                      ...toastDefaultStyle,
                    });
                    setAtendimentos((atendimentos) =>
                      atendimentos.filter(
                        (atendimento) => atendimento.id !== id
                      )
                    );
                  } catch (e) {
                    toast({
                      title: "Erro ao deletar atendimento",
                      status: "error",
                      ...toastDefaultStyle,
                    });
                  }
                },
                confirmButtonStyle: {
                  loadingText: "Deletando",
                  leftIcon: <DeleteIcon />,
                },
                message: `Deseja deletar o atendimento?`,
              });
            }}
          />
        );
      },
    },
  ];

  return (
    <>
      <ModalChangeUser
        changeUserModalData={changeUserModalData}
        setChangeUserModalData={setChangeUserModalData}
        onUpdate={atualizar}
      />
      <Box mb="40px" w="100%">
        <Grid
          templateColumns={{
            base: "0.8fr 1.5fr 1.5fr 1.5fr auto 40px 40px",
          }}
          w="100%"
          gap="8px"
        >
          <Flex flexDir="column" justifyContent="center">
            <Text mb="8px">Qtd. Atend.</Text>
            <Input
              value={atendimentosLength}
              textAlign="center"
              fontWeight="600"
              cursor="not-allowed"
              disabled
            />
          </Flex>
          <Flex flexDir="column" justifyContent="center">
            <Text mb="8px">Filtro: CPF, NB ou Fone</Text>
            <Input
              value={cpfNbPhone}
              onChange={(e) => setCPFNBPhone(e.target.value)}
            />
          </Flex>

          <Flex flexDir="column" justifyContent="center">
            <Text mb="8px">Filtro: Status</Text>
            <Dropdown
              value={dropdowns.status}
              onChange={(value) => {
                if (dropdowns.status.length) {
                  const isInclude = dropdowns.status.includes(value);
                  if (isInclude)
                    setDropdowns({
                      ...dropdowns,
                      status: dropdowns.status.filter((curr) => curr !== value),
                    });
                  else
                    setDropdowns({
                      ...dropdowns,
                      status: [...dropdowns.status, value],
                    });
                } else setDropdowns({ ...dropdowns, status: [value] });
              }}
              options={dropdownStatus}
              optionLabel="name"
              placeholder="TODOS"
              w="full"
              containerStyles={{}}
              multiSelect
            >
              {dropdowns.status.length > 1
                ? dropdowns.status.length === dropdownStatus.length
                  ? "TODOS"
                  : `${dropdowns.status.length} Selecionados`
                : dropdowns.status
                    .map(
                      (filter) =>
                        dropdownStatus.find((status) => status.value === filter)
                          .name
                    )
                    .join()}
            </Dropdown>
          </Flex>
          <Flex flexDir="column" justifyContent="center">
            <Text mb="8px">Filtro: Usuários</Text>
            <Dropdown
              value={dropdowns.users || []}
              onChange={(value) => {
                if (dropdowns.users.length) {
                  const isInclude = dropdowns.users.includes(value);
                  if (isInclude)
                    setDropdowns({
                      ...dropdowns,
                      users: dropdowns.users.filter((curr) => curr !== value),
                    });
                  else
                    setDropdowns({
                      ...dropdowns,
                      users: [...dropdowns.users, value],
                    });
                } else setDropdowns({ ...dropdowns, users: [value] });
              }}
              options={dropdownUsers}
              optionLabel="name"
              placeholder="TODOS"
              w="full"
              multiSelect
            >
              {dropdowns.users.length > 1
                ? dropdowns.users.length === dropdownUsers.length
                  ? "TODOS"
                  : `${dropdowns.users.length} Selecionados`
                : dropdowns.users
                    .map((id) => {
                      return dropdownUsers.find((item) => item.value === id)
                        .name;
                    })
                    .join()}
            </Dropdown>
          </Flex>
          <Grid templateColumns="1fr 1fr" gap="8px" alignItems="center">
            <Box>
              <Text mb="8px">Início Data Criação</Text>
              <Popover
                id="begin-date"
                button={
                  <Button
                    leftIcon={<CalendarIcon width="22px" height="22px" />}
                    variant="none"
                    _hover={{ bg: "gray.200" }}
                    border="1px solid var(--chakra-colors-custom-gray)"
                  >
                    {filtros.dataCriacaoInicial
                      ? filtros.dataCriacaoInicial.replace(/-/g, "/")
                      : "dd/mm/aaaa"}
                  </Button>
                }
                onClose={() => {}}
                closeOnBlur
                position="left-bottom"
                title="Filtro por data: Início"
                onClickClosePopoversList={["month-popover", "year-popover"]}
              >
                <Flex flexDir="column" minW="320px">
                  <Calendar
                    insidePopoverId="begin-date"
                    selectedDate={filtros.dataCriacaoInicial}
                    setSelectedDate={(date) => {
                      onChangeFiltro(
                        "dataCriacaoInicial",
                        date.toLocaleDateString().replace(/\//g, "-")
                      );
                    }}
                  />
                  <hr style={{ margin: "4px 0" }} />
                  <Button
                    leftIcon={<CloseIcon w="10px" h="10px" />}
                    ml="auto"
                    variant="outline"
                    size="sm"
                    onClick={() => {
                      onChangeFiltro("dataCriacaoInicial", null);
                      closePopover("begin-date");
                    }}
                  >
                    Limpar Filtro
                  </Button>
                </Flex>
              </Popover>
            </Box>
            <Box>
              <Text mb="8px">Fim Data Criação</Text>
              <Popover
                id="end-date"
                button={
                  <Button
                    leftIcon={<CalendarIcon width="22px" height="22px" />}
                    variant="none"
                    _hover={{ bg: "gray.200" }}
                    border="1px solid var(--chakra-colors-custom-gray)"
                  >
                    {filtros.dataCriacaoFinal
                      ? filtros.dataCriacaoFinal.replace(/-/g, "/")
                      : "dd/mm/aaaa"}
                  </Button>
                }
                closeOnBlur
                position="left-bottom"
                onClose={() => {}}
                title="Filtro por data: Final"
                onClickClosePopoversList={["month-popover", "year-popover"]}
              >
                <Flex flexDir="column" minW="320px">
                  <Calendar
                    insidePopoverId="end-date"
                    selectedDate={filtros.dataCriacaoFinal}
                    setSelectedDate={(date) =>
                      onChangeFiltro(
                        "dataCriacaoFinal",
                        date.toLocaleDateString().replace(/\//g, "-")
                      )
                    }
                  />
                  <hr style={{ margin: "4px 0" }} />
                  <Button
                    leftIcon={<CloseIcon w="10px" h="10px" />}
                    ml="auto"
                    size="sm"
                    variant="outline"
                    onClick={() => {
                      onChangeFiltro("dataCriacaoFinal", null);
                      closePopover("end-date");
                    }}
                  >
                    Limpar Filtro
                  </Button>
                </Flex>
              </Popover>
            </Box>
          </Grid>
          <Flex flexDir="column" alignItems="center" justifyContent="center">
            <Text mb="8px">&nbsp;</Text>
            <Tooltip label="Atualizar">
              <IconButton
                icon={<RepeatIcon />}
                onClick={atualizar}
                isLoading={loading}
              />
            </Tooltip>
          </Flex>
          <Flex flexDir="column" alignItems="center" justifyContent="center">
            <Text mb="8px">&nbsp;</Text>
            <Tooltip label="Novo atendimento">
              <IconButton
                aria-label=""
                icon={<AddIcon />}
                onClick={openNewAttendanceModal}
              />
            </Tooltip>
          </Flex>
        </Grid>
      </Box>

      <NewAttendanceModal makeAttendance={makeAttendance} />

      <CustomTable
        columns={columns}
        rows={atendimentos}
        isLoading={loading}
        rowsLength={atendimentosLength}
        onClickRow={onClickAtendimento}
        onChangeFilter={({ orderBy, sortOrder }) => {
          setOrder([orderBy, sortOrder]);
        }}
        onChangePage={(currentPage) => {
          onChangeFiltro("page", currentPage);
        }}
        page={filtros.page}
        rowStyle={(row) => ({
          bg: row.unreadMessage ? "#00a88420" : "#ffffff",
          _hover: {
            bg: row.unreadMessage ? "#00a88430" : "rgba(45, 61, 133, 0.03)",
          },
        })}
      />

      <AttendanceProvider>
        <ModalAtendimento />
      </AttendanceProvider>
    </>
  );
}
