import { AddIcon, RepeatIcon } from "@chakra-ui/icons";
import { Button, Flex, Text } from "@chakra-ui/react";
import DynamicTable from "components/dynamic-table";
import { RowStatus } from "components/dynamic-table/row-status";
import { Column } from "components/dynamic-table/types";
import { GetLayout } from "components/get-layout";
import { In100DeleteButton } from "components/in100-components/in100-delete-button";
import { useEffect, useState } from "react";

export interface In100Props {
  id: number;
  name: string;
}

export function In100Page() {
  const [list, setList] = useState<In100Props[]>([]);
  const [loading, setLoading] = useState(false);

  const getList = async () => {
    setLoading(true);
    try {
      // const {data }  = await api.get("in100")
      // setList(data)
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const columns: Column[] = [
    {
      name: "Nome",
      key: "name",
    },
    {
      name: "Status",
      render: ({ status }) => {
        return <RowStatus status={status}>{status}</RowStatus>;
      },
    },
    {
      name: "Ações",
      render: (row) => {
        return <In100DeleteButton row={row} setList={setList} />;
      },
    },
  ];

  return (
    <GetLayout>
      <Flex justifyContent="space-between" px="10px" mb="30px">
        <Text fontSize="25" fontWeight="bold">
          Lista de Higienizações
        </Text>

        <Flex gap="20px" alignItems="center">
          <Button
            leftIcon={<RepeatIcon />}
            onClick={getList}
            isLoading={loading}
            loadingText="Atualizando"
          >
            Atualizar
          </Button>
          <Button leftIcon={<AddIcon />}>Novo</Button>
        </Flex>
      </Flex>
      <DynamicTable columns={columns} rows={list} isLoading={loading} />
    </GetLayout>
  );
}
