import { Icon, IconProps } from "@chakra-ui/react";

export function ContactListIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="20px"
      height="20px"
      viewBox="0 0 169 191"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        x="18"
        y="6"
        width="145"
        height="179"
        rx="7"
        stroke="currentColor"
        strokeWidth="12"
      />
      <path
        d="M114 69C114 82.3503 103.384 93 90.5 93C77.616 93 67 82.3503 67 69C67 55.6497 77.616 45 90.5 45C103.384 45 114 55.6497 114 69Z"
        stroke="currentColor"
        strokeWidth="12"
      />
      <path
        d="M69.9654 91.28C59.1242 98.5333 50.0177 109.2 53.9205 133.093C54.4987 136.649 57.8234 144.016 66.4963 145.04C77.3375 146.32 102.489 146.32 114.198 145.04C123.564 144.016 127.352 135.511 128.074 131.387C130.243 122.427 129.982 101.605 111.596 90"
        stroke="currentColor"
        strokeWidth="13"
        strokeLinecap="round"
      />
      <rect y="23" width="36" height="13" rx="6.5" fill="currentColor" />
      <rect y="49" width="36" height="13" rx="6.5" fill="currentColor" />
      <rect y="76" width="36" height="13" rx="6.5" fill="currentColor" />
      <rect y="102" width="36" height="13" rx="6.5" fill="currentColor" />
      <rect y="128" width="36" height="13" rx="6.5" fill="currentColor" />
      <rect y="155" width="36" height="13" rx="6.5" fill="currentColor" />
    </Icon>
  );
}
