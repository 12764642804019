import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Grid,
  Input,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import api from "api/api";
import { invalidTextStyle, toastDefaultStyle } from "chakra/theme";
import { dropdownConvenioAtendimento } from "components/atendimentos-components/atendimento-form/fields-data";
import { CustomModal } from "components/custom-modal";
import { Dropdown } from "components/dropdown";
import { dropdownBancosTabelas } from "components/usuarios-bancos-components/consts";
import {
  RefObject,
  useEffect,
  useLayoutEffect,
  useReducer,
  useState,
} from "react";
import { tipoOperacaoINSSArray, tipoOperacaoSIAPEArray } from "./arrays";
import { reducerTables, TableConfigType } from "./functions";

export const INITIAL_TABLES_VALUES: TableConfigType = {
  convenio: "",
  banco: "",
  tipoOperacao: "",
  prazo: "",
  descricaoCompleta: "",
  codigo: "",
};

export function ModalConfigTables({
  openRef,
  onSaved,
}: {
  openRef: RefObject<HTMLButtonElement>;
  onSaved: () => void;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [formControl, dispatch] = useReducer(
    reducerTables,
    INITIAL_TABLES_VALUES
  );
  const [dataTables, setDataTables] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const toast = useToast();
  const { convenio, tipoOperacao, banco, prazo, codigo } = formControl;
  const showPrazo =
    convenio === "INSS" && tipoOperacao === "NOVO_INSS" && banco === "PAN";

  const tipoOperacaoOptions =
    convenio === "INSS"
      ? tipoOperacaoINSSArray
      : convenio === "SIAPE"
      ? tipoOperacaoSIAPEArray
      : [];

  async function handleShowTables() {
    setIsLoading(true);
    try {
      const { data } = await api.get("/tabelas-digitacao/prazos-tabelas", {
        params: { convenio, tipoOperacao, banco },
      });
      if (Object.keys(data).length === 0) {
        toast({ title: "Não há tabelas.", ...toastDefaultStyle });
      }
      setDataTables(data);
      setIsLoading(false);
    } catch (e: any) {
      if (e.response?.data?.message === "digitador_not_found") {
        toast({
          title: "Não há usuário digitador cadastrado",
          status: "error",
          ...toastDefaultStyle,
        });
      }
      toast({
        title: "Erro ao recuperar tabelas",
        status: "error",
        ...toastDefaultStyle,
      });
      setDataTables({});
      setIsLoading(false);
    }
  }

  async function handleSave() {
    setIsSaving(true);
    try {
      const response = await api.post("/tabelas-digitacao", formControl);
      setDataTables(response.data);
      setIsSaving(false);
      onSaved();
      onClose();
    } catch (e) {
      toast({
        title: "Ocorreu um erro ao salvar",
        status: "error",
        ...toastDefaultStyle,
      });
      setIsSaving(false);
    }
  }

  useEffect(() => {
    if (openRef.current) openRef.current.onclick = onOpen;
  });

  useLayoutEffect(() => {
    if (!isOpen) {
      setDataTables({});
      dispatch({ type: "resetFields" });
    }
  }, [isOpen]);

  useLayoutEffect(() => {
    const rules = {
      INSS: convenio && tipoOperacao && banco,
      SIAPE: convenio && tipoOperacao && banco,
      ANTECIPACAO_FGTS: convenio && banco,
    };
    const key = convenio as keyof typeof rules;
    if (rules[key]) {
      handleShowTables();
    }
  }, [convenio, tipoOperacao, banco]);

  useLayoutEffect(() => {
    setDataTables({});
  }, [convenio]);

  const prazos = Object.keys(dataTables).map((key) => ({
    name: `${key} meses`,
    value: key,
  }));

  const tables = dataTables[formControl.prazo]
    ? dataTables[formControl.prazo].map((table: any) => ({
        name: table.descricaoCompleta,
        value: table.codigo,
        data: { needToken: table.needToken },
      }))
    : [];

  const changeValue = (fieldName: keyof TableConfigType, data: string) =>
    dispatch({
      type: "changeField",
      payload: {
        fieldName,
        data,
      },
    });

  const modalFooter = (
    <>
      {isLoading ? <Spinner size="sm" /> : null}
      <Button
        onClick={handleSave}
        isLoading={isSaving}
        loadingText="Salvando"
        leftIcon={<CheckIcon />}
        disabled={!codigo}
      >
        Salvar
      </Button>

      <Button
        onClick={onClose}
        variant="outline"
        leftIcon={<CloseIcon w="12px" h="12px" />}
      >
        Cancelar
      </Button>
    </>
  );

  return (
    <CustomModal
      modalTitle="Configuração Tabelas"
      modalFooter={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
      size="5xl"
    >
      <Box>
        <Grid gridTemplateColumns="1fr" w="100%" gap="20px">
          <Box>
            <Text mb="10px">Convênio</Text>
            <Dropdown
              disabled={isLoading}
              value={convenio}
              onChange={(value) => changeValue("convenio", value)}
              options={dropdownConvenioAtendimento}
              w="full"
            />
          </Box>
          <Box>
            <Text mb="10px">Tipo Operação</Text>
            <Dropdown
              disabled={isLoading || !tipoOperacaoOptions.length}
              value={tipoOperacao}
              onChange={(value) => changeValue("tipoOperacao", value)}
              options={tipoOperacaoOptions}
              w="full"
            />
          </Box>
          <Box>
            <Text mb="10px">Bancos</Text>
            <Dropdown
              disabled={isLoading}
              value={formControl.banco}
              onChange={(value) => changeValue("banco", value)}
              options={dropdownBancosTabelas}
              w="full"
            />
          </Box>

          {/* {showPrazo ? ( */}
          <Box>
            <Text mb="10px">Prazo</Text>
            <Dropdown
              disabled={!Object.keys(dataTables).length || isLoading}
              value={prazo}
              onChange={(value) => changeValue("prazo", value)}
              options={prazos}
              w="full"
            />
          </Box>
          {/* ) : null} */}

          <Box>
            <Text mb="10px">Tabelas</Text>
            <Dropdown
              disabled={!tables.length || isLoading}
              value={codigo}
              onChange={(_, option) => {
                changeValue("descricaoCompleta", option.name);
                changeValue("codigo", option.value);
                changeValue("needToken", option.data?.needToken);
              }}
              options={tables}
              w="full"
            />
          </Box>
          {formControl.needToken ? (
            <Box>
              <Text mb="10px">Token</Text>
              <Input
                onChange={({ target: { value } }) =>
                  changeValue("token", value)
                }
              />
            </Box>
          ) : null}
        </Grid>
      </Box>
    </CustomModal>
  );
}
