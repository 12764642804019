import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import {
  Flex,
  IconButton,
  Spinner,
  Table,
  TableColumnHeaderProps,
  TableContainer,
  TableContainerProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { defaultScroll } from "chakra/theme";
import { Column } from "components/dynamic-table/types";
import { sortArrayBy } from "utils/filter-array-by";
import { ChevronDownIcon } from "components/vectors/chevron-down-icon";
import { ChevronUpDownIcon } from "components/vectors/chevron-up-down-icon";
import { Fragment, ReactNode, useEffect, useState } from "react";

interface CustomTableProps {
  columns: Column[];
  rows: any[];
  pageSize?: number | null;
  onClickRow?: (row: any) => void;
  sortBy?: string;
  isLoading?: boolean;
  emptyMessage?: string;
  sortOrder?: "ASC" | "DESC";
  containerStyle?: TableContainerProps;
  rowKey?: string;
  headerButton?: ReactNode;
  lastRow?: any;
  size?: "full" | "md";
  headerStyle?: (row: any) => TableColumnHeaderProps;
  rowsLength?: number;
  onChangePage?: (page: number) => void;
  onChangeFilter?: (filter: ArrayFilter) => void;
  rowStyle?: (row: any) => any;
  page?: number;
}

interface ArrayFilter {
  orderBy: string;
  sortOrder: CustomTableProps["sortOrder"];
}

function CustomTable({
  columns = [],
  rows = [],
  rowsLength,
  pageSize = 10,
  onClickRow,
  sortBy,
  isLoading,
  emptyMessage = "Sem dados",
  sortOrder = "ASC",
  containerStyle,
  rowKey = "id",
  page,
  headerButton,
  lastRow,
  size,
  onChangePage,
  headerStyle,
  onChangeFilter,
  rowStyle,
}: CustomTableProps) {
  const [currentIndexPage, setCurrentIndexPage] = useState(0);
  const [arrayFilter, setArrayFilter] = useState<ArrayFilter>({
    orderBy: "",
    sortOrder: "DESC",
  });
  const fontSize = { base: "12px", xl: "15px" }; // 15px
  const px = { base: "0.8rem", xl: "1.5rem" }; // 1.5rem

  const changeFilter = (arrayFilter: ArrayFilter) => {
    setArrayFilter(arrayFilter);
    onChangeFilter?.(arrayFilter);
  };

  const numberOfPages =
    pageSize && rowsLength ? Math.ceil(rowsLength / pageSize) : 1;

  if (currentIndexPage > numberOfPages - 1 && currentIndexPage !== 0)
    setCurrentIndexPage(numberOfPages - 1);

  // if (sortBy && !arrayFilter.orderBy)
  //   rows = sortArrayBy(rows, sortBy, sortOrder);

  // if (arrayFilter.orderBy)
  //   rows = sortArrayBy(rows, arrayFilter.orderBy, arrayFilter.sortOrder);

  const paginedData =
    pageSize && rows.length > pageSize
      ? rows.slice(
          currentIndexPage * pageSize,
          (currentIndexPage + 1) * pageSize
        )
      : rows;

  let pageArray: number[] = [];
  for (let k = 0; k < numberOfPages; k++) {
    pageArray.push(k);
  }

  if (currentIndexPage - 2 >= 0 && numberOfPages > 5) {
    const cut: number[] = pageArray.slice(
      currentIndexPage - 2,
      currentIndexPage + 3
    );
    if (cut.length < 5)
      pageArray = pageArray.slice(numberOfPages - 5, numberOfPages);
    else pageArray = cut;
  } else pageArray = pageArray.slice(0, 5);

  useEffect(() => {
    if (typeof page === "number" && page !== currentIndexPage) {
      setCurrentIndexPage(page);
    }
  }, [page]);

  return (
    <TableContainer
      pos="relative"
      bg="#fff"
      pb="30px"
      w="100%"
      overflow="auto"
      sx={defaultScroll}
      borderRadius="10"
      boxShadow="0 0 1em rgba(0,0,0,0.1)"
      {...containerStyle}
    >
      {headerButton ? (
        <Flex
          alignItems="center"
          pos="absolute"
          top="0"
          h="50.5px"
          right={{ base: "10px", "2xl": "25px" }}
        >
          {headerButton}
        </Flex>
      ) : null}
      <Table>
        <Thead>
          <Tr bg="#F5F5F5">
            {columns.map((column, index) => {
              const { name, key, sortClick, isVisible = true } = column;
              const isSelected = key === arrayFilter.orderBy;
              const isASC = arrayFilter.sortOrder === "ASC";

              return isVisible ? (
                <Th
                  key={`column-${name}-${index}`}
                  textTransform="none"
                  fontSize={fontSize}
                  fontWeight="bold"
                  px={px}
                  color="text"
                  fontFamily="Inter"
                  bg={isSelected ? "gray.200" : "none"}
                  _hover={{ bg: sortClick ? "gray.200" : "none" }}
                  cursor={sortClick ? "pointer" : undefined}
                  onClick={
                    sortClick
                      ? () =>
                          isSelected
                            ? isASC
                              ? changeFilter({
                                  orderBy: "",
                                  sortOrder: "DESC",
                                })
                              : changeFilter({
                                  orderBy: key,
                                  sortOrder: "ASC",
                                })
                            : changeFilter({
                                orderBy: key,
                                sortOrder: "DESC",
                              })
                      : undefined
                  }
                  {...headerStyle?.(column)}
                >
                  <Flex alignItems="center" gap="8px" minH="26px">
                    {name}{" "}
                    {sortClick ? (
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        w="25px"
                        h="26px"
                        transform={isASC ? undefined : "rotate(180deg)"}
                      >
                        {!isSelected ? (
                          <ChevronUpDownIcon width="8px" />
                        ) : (
                          <ChevronDownIcon width="8px" />
                        )}
                      </Flex>
                    ) : null}
                  </Flex>
                </Th>
              ) : null;
            })}
          </Tr>
        </Thead>

        <Tbody>
          {isLoading
            ? null
            : paginedData.map((row, rowIndex) => (
                <Tr
                  key={`row-${rowIndex}`}
                  onClick={onClickRow ? () => onClickRow(row) : undefined}
                  cursor={onClickRow ? "pointer" : undefined}
                  className="table-row"
                  {...rowStyle?.(row)}
                >
                  {columns.map(
                    ({ key, render, cellStyle, isVisible = true }, index) => {
                      return isVisible ? (
                        <Fragment key={`cell-${rowIndex}-${index}`}>
                          {render ? (
                            <Td
                              py="0.6em"
                              color="text"
                              fontSize={fontSize}
                              px={px}
                              {...cellStyle?.(row, rowIndex)}
                            >
                              {render(row, rowIndex)}
                            </Td>
                          ) : (
                            <Td
                              py="0.6em"
                              color="text"
                              px={px}
                              fontSize={fontSize}
                              {...cellStyle?.(row, rowIndex)}
                            >
                              {row[key ?? ""]}
                            </Td>
                          )}
                        </Fragment>
                      ) : null;
                    }
                  )}
                </Tr>
              ))}
          {lastRow ? (
            <Tr background="#F5F5F5">
              {columns.map(
                ({ key, cellStyle, render, isVisible = true }, index) => {
                  render?.(lastRow, paginedData.length);
                  return isVisible ? (
                    <Td
                      key={`cell-${paginedData.length}-${index}`}
                      py="0.6em"
                      fontSize={fontSize}
                      px={px}
                      color="text"
                      {...cellStyle?.(null, null)}
                    >
                      {lastRow[key ?? ""]}
                    </Td>
                  ) : null;
                }
              )}
            </Tr>
          ) : null}
        </Tbody>
      </Table>
      {isLoading ? (
        <Flex
          minH={size === "md" ? "200px" : "400px"}
          justifyContent="center"
          alignItems="center"
        >
          <Spinner />
        </Flex>
      ) : null}
      {isLoading === false && !rows.length ? (
        <Text display="flex" alignItems="center" p="0 0 0 20px" minH="39px">
          {emptyMessage}
        </Text>
      ) : null}

      {isLoading ? null : pageSize && numberOfPages > 1 ? (
        <Flex w="100%" justifyContent="flex-end" p="30px 40px 0">
          <Flex alignItems="center" gap="10px">
            <IconButton
              aria-label="pagina anterior"
              variant="outline"
              icon={<ArrowLeftIcon w="9px" h="9px" />}
              onClick={() => {
                if (typeof page !== "number") setCurrentIndexPage(0);
                onChangePage?.(0);
              }}
              disabled={currentIndexPage === 0}
            />
            <IconButton
              aria-label="pagina anterior"
              variant="outline"
              icon={<ChevronLeftIcon />}
              onClick={() => {
                if (typeof page !== "number")
                  setCurrentIndexPage((state) => state - 1);
                onChangePage?.(currentIndexPage - 1);
              }}
              disabled={currentIndexPage === 0}
            />
            {pageArray.map((number, index) => {
              const active = currentIndexPage === number;
              return (
                <Text
                  key={`pagestable-${index}`}
                  _hover={{ textDecor: "underline" }}
                  py="4px"
                  px="5px"
                  fontSize={fontSize}
                  fontWeight={active ? "bold" : "normal"}
                  onClick={() => {
                    if (typeof page !== "number") setCurrentIndexPage(number);
                    onChangePage?.(number);
                  }}
                  cursor="pointer"
                  textAlign="center"
                  borderRadius="5"
                  bg={active ? "#D0DAFF" : "none"}
                >
                  {number + 1}
                </Text>
              );
            })}
            <IconButton
              variant="outline"
              aria-label="proxima pagina"
              icon={<ChevronRightIcon />}
              onClick={() => {
                if (typeof page !== "number")
                  setCurrentIndexPage((state) => state + 1);
                onChangePage?.(currentIndexPage + 1);
              }}
              disabled={currentIndexPage === numberOfPages - 1}
            />
            <IconButton
              variant="outline"
              aria-label="proxima pagina"
              icon={<ArrowRightIcon w="9px" h="9px" />}
              onClick={() => {
                if (typeof page !== "number")
                  setCurrentIndexPage(numberOfPages - 1);
                onChangePage?.(numberOfPages - 1);
              }}
              disabled={currentIndexPage === numberOfPages - 1}
            />
          </Flex>
        </Flex>
      ) : null}
    </TableContainer>
  );
}

export default CustomTable;
