import { SettingsIcon } from "@chakra-ui/icons";
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { LogoutIcon } from "components/vectors/logout-icon";
import { UserIcon } from "components/vectors/user-icon";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useHistory } from "react-router-dom";

export function ProfileMenu() {
  const { user, setUserAction } = useApplicationContext();
  const { userData } = user;
  const router = useHistory();

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        bg={userData.image ? undefined : "bg-gray"}
        bgImage={userData.image}
        borderRadius="full"
        w="45px"
        h="45px"
      >
        {userData.image ? null : <UserIcon stroke="custom-gray" />}
      </MenuButton>

      <MenuList py="0" overflow="hidden">
        {user.userData.type === "SUPER" ? (
          <MenuItem
            icon={<SettingsIcon width="15px" height="15px" />}
            _focus={{}}
            onClick={() => {
              router.push("/config");
            }}
            _hover={{ bg: "gray.100" }}
          >
            Configurações
          </MenuItem>
        ) : null}
        <MenuItem
          icon={<LogoutIcon width="15px" height="15px" />}
          _focus={{}}
          onClick={() => {
            setUserAction({ type: "logout" });
            router.push("/login");
          }}
          _hover={{ bg: "gray.100" }}
        >
          Sair
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
