import { Box, BoxProps } from "@chakra-ui/react";

export function FieldError({
  children,
  isError,
  ...rest
}: { isError: boolean; children: any } & BoxProps) {
  return (
    <>
      {isError ? (
        <Box
          w="100%"
          p="10px"
          my="10px"
          color="#fff"
          borderRadius="10"
          fontSize="13"
          bg="#E30613"
          border="1px solid #ff6369"
          {...rest}
        >
          {children}
        </Box>
      ) : null}
    </>
  );
}
