import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { getErrorByMessage } from "components/atendimentos-components/modal-atendimento/functions";
import { CustomModal } from "components/custom-modal";
import { FilterIcon } from "components/vectors/filter-icon";
import { useState, useRef, Fragment } from "react";
import { createEventListener, makeEvent } from "services/events";
import { ConveniosMailing } from "./convenios";
import { MailingFilterMap, MailingFilterValueProps } from "./types";
import { AnimateElement } from "utils/animations";
import { mailingFilters } from "../mailing-modal-filter/consts";

export type AddedFilterProps = {
  key: string;
  op: string;
  value: number[] | string[];
};

export function GenerateMailingModal() {
  const [convenio, setConvenio] = useState("");
  const [filtersValues, setFiltersValues] = useState<
    Map<string, MailingFilterMap>
  >(new Map());
  const [invalidFields, setInvalidFields] = useState<{ [k: string]: string }>(
    {}
  );
  const [isOpen, setIsOpen] = useState(false);
  const [subConvenio, setSubConvenio] = useState("");
  const [loading, setLoading] = useState(false);
  const quantityRef = useRef<HTMLInputElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);

  const onChangeFilter = (field: string, newValue: MailingFilterValueProps) => {
    setFiltersValues(newValue);
    setInvalidFields((prev) => {
      delete prev[field];
      return { ...prev };
    });
  };

  const filtersArray = mailingFilters({
    onChangeFilter,
    setInvalidFields,
    filtersValues,
    invalidFields,
  });

  const validateFields = () => {
    const keys = Array.from(filtersValues.entries());
    let isValidResult = true;
    const modal = document.getElementById("mailing-modal")!;
    let scrollY = modal?.scrollTop;
    let destination: number | null = null;
    keys.forEach(([key, currentFilter]) => {
      const { checkField } = filtersArray.find((crr) => crr.key === key)! || {};
      const { isValid, errorMessage } =
        checkField?.(currentFilter?.op!, currentFilter?.value)! || {};
      if (checkField != null && !isValid) {
        setInvalidFields({ ...invalidFields, [key]: errorMessage! });
        isValidResult = false;
        const target = document.getElementById(`mailing-filter-${key}`)!;
        const currYPos = minLimit(0, target?.offsetTop - 30);
        if (destination == null || currYPos < destination)
          destination = currYPos;
      }
    });
    if (!isValidResult)
      AnimateElement(1000, [scrollY, destination!], (top) =>
        modal?.scrollTo({ top })
      );
    return isValidResult;
  };

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    setFiltersValues(new Map());
    setSubConvenio("");
    setConvenio("");
  };

  createEventListener("open-mailing-filter", onOpen);

  const toast = useToast();

  async function handleSubmit() {
    const filters = Array.from(filtersValues.entries()).map(([key, value]) => ({
      ...value,
      key,
    }));
    const isValid = validateFields();
    if (!isValid) return;
    const quantity = quantityRef.current?.value;
    const name = nameRef.current?.value.trim();
    const convenioData = ["gov", "pref"].includes(convenio)
      ? subConvenio
      : convenio;
    if (!convenioData) {
      const errorMessage: any = { gov: " governo", pref: "a prefeitura" };
      return toast({
        title: `Escolha um${errorMessage[convenio] ?? " convênio"}!`,
        ...toastDefaultStyle,
      });
    }
    if (!quantity)
      return toast({
        title: "Preecha a quantidade mailing!",
        ...toastDefaultStyle,
      });
    if (!name)
      return toast({
        title: "Preecha o campo Nome!",
        ...toastDefaultStyle,
      });

    setLoading(true);

    try {
      const { data } = await api.post(`/mailings`, {
        filters,
        qtdSolicitada: Number(quantity),
        name,
        convenio,
        subConvenio,
      });

      makeEvent("update-mailings-table");
      toast({
        title: "Solicitação enviada com sucesso",
        status: "success",
        ...toastDefaultStyle,
      });
      onClose();
    } catch (e: any) {
      toast({
        title: getErrorByMessage(
          e,
          "Não foi possível obter dados do filtro mailing"
        ),
        ...toastDefaultStyle,
      });
    } finally {
      setLoading(false);
    }
  }

  const modalFooter = (
    <>
      <Button
        leftIcon={<FilterIcon />}
        onClick={handleSubmit}
        isLoading={loading}
        loadingText="Filtrando"
        isDisabled={filtersValues.size === 0}
      >
        Filtrar
      </Button>
      <Button
        onClick={onClose}
        variant="outline"
        leftIcon={<CloseIcon w="12px" h="12px" />}
      >
        Fechar
      </Button>
    </>
  );

  const visibleFilters = filtersArray.filter(({ isVisible }) =>
    isVisible(convenio)
  );

  const isEmptyVisibleFilters = visibleFilters.length === 0;

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      modalFooter={modalFooter}
      modalTitle="Filtros Mailing"
      size="full"
      maxH="90vh"
      isLoading={loading}
      scroll="inside"
      id="mailing-modal"
    >
      <Box>
        <Flex alignItems="flex-start" mb="15px">
          <ConveniosMailing
            convenio={convenio}
            setConvenio={(convenio) => {
              setFiltersValues(new Map());
              setConvenio(convenio);
            }}
            selectedOption={subConvenio}
            setSelected={setSubConvenio}
          />
          <Box mr="8px">
            <Text mb="8px">Quantidade:</Text>
            <Input
              w="150px"
              ref={quantityRef}
              onChange={({ target, currentTarget }) => {
                const value = target.value.replace(/\D/g, "");
                currentTarget.value = value;
                if (Number(value) > 10000) currentTarget.value = "10000";
              }}
            />
          </Box>
          <Box>
            <Text mb="8px">Nome:</Text>
            <Input w="400px" ref={nameRef} />
          </Box>
        </Flex>

        <Text my="15px">Filtros:</Text>

        <Grid templateColumns="1fr 1fr" gap="16px">
          {visibleFilters.map(({ isVisible, key, render }) => {
            return <Fragment key={key}>{render}</Fragment>;
          })}
          {isEmptyVisibleFilters ? <Text>Escolha um Convênio!</Text> : null}
        </Grid>
      </Box>
    </CustomModal>
  );
}

function minLimit(limit: number, number: number) {
  if (number < limit) return limit;
  return number;
}
