import { EditIcon } from "@chakra-ui/icons";
import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import type { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { UserIcon2 } from "components/vectors/user-icon";
import { useAttendanceContext } from "contexts/AttendanceContext";
import { Dispatch, SetStateAction, useState } from "react";
import { makeEvent } from "services/events";
import { phoneFormat } from "utils/string-formats";
import { createWebSocketEventListener } from "utils/web-socket-events";
import { ChatStatus, ContactInfo } from "..";

export function ChatHeader() {
  const [contactInfo, setContactInfo] = useState<Partial<ContactInfo>>({});
  const { formControl } = useAttendanceContext();
  const { telefoneContatoNormalizado: phone, user } =
    formControl.values as Attendance;
  const toast = useToast();

  createWebSocketEventListener("contact-info", (data) => {
    setContactInfo(data.contactInfo);
  });

  return (
    <Flex
      overflow="hidden"
      h="50px"
      minH="50px"
      bg="#076058"
      alignItems="center"
      justifyContent="space-between"
      px="10px"
      borderRadius="10px 10px 0 0"
    >
      <Flex alignItems="center">
        <Box
          h="40px"
          w="40px"
          borderRadius="full"
          mr="10px"
          bgSize="cover"
          bgPos="center"
          bgImage={`url(${contactInfo.profilePicUrl})`}
          bgColor={contactInfo.profilePicUrl ? undefined : "gray.300"}
        >
          {contactInfo.profilePicUrl ? null : (
            <UserIcon2 width="40px" height="40px" />
          )}
        </Box>
        <Box color="white">
          <Text fontWeight="bold" fontSize="14">
            {`${contactInfo.name ?? ""}`}
          </Text>
          <Flex
            alignItems="center"
            fontSize={contactInfo.name ? "11" : "14"}
            cursor="pointer"
          >
            {`${phoneFormat(phone) ?? ""}`}{" "}
            {phone ? (
              <Flex
                as="span"
                ml="5px"
                h={contactInfo.name ? "20px" : "25px"}
                w={contactInfo.name ? "20px" : "25px"}
                alignItems="center"
                justifyContent="center"
                borderRadius="3"
                _hover={{ bg: "rgba(255,255,255,0.12)" }}
                onClick={() => makeEvent("open-modal-change-number")}
              >
                <EditIcon />
              </Flex>
            ) : (
              <Box
                cursor="pointer"
                onClick={() => makeEvent("open-modal-change-number")}
              >
                Adicionar telefone (WhatsApp)
              </Box>
            )}
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
}
