import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Image,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { openModalConfirm } from "components/modal-confirm-new";
import { SkeletonLoading } from "components/skeleton-loading";
import { LogoutIcon2 } from "components/vectors/logout-icon";
import { RepeatIcon } from "components/vectors/repeat-icon";
import { WhatsAppIcon } from "components/vectors/whatsapp-icon";
import QRCode from "qrcode";
import { Dispatch, SetStateAction, memo, useState } from "react";
import { FaPlay, FaStop } from "react-icons/fa";
import { SendJsonMessage } from "react-use-websocket/dist/lib/types";
import { phoneFormat } from "utils/string-formats";
import { createWebSocketEventListener } from "utils/web-socket-events";
import { WhatsAppSessionListProps } from "./grid-servers";
import { openModalEditSessionWhatsApp } from "./modals/modal-edit-session-name";

type PortalPages = "qrcode-generated" | "chat" | "qrcode-not-generated";
function WhatsAppPortal({
  id,
  nome,
  whatsappSessionId,
  setServers,
  sendJsonMessage,
}: {
  setServers: Dispatch<SetStateAction<WhatsAppSessionListProps[]>>;
  sendJsonMessage: SendJsonMessage;
} & WhatsAppSessionListProps) {
  const [number, setNumber] = useState("");
  const [portalPage, setPortalPage] = useState<PortalPages>("chat");
  const [loading, setLoading] = useState(true);
  const [qrCode, setQrCode] = useState("");
  const [isGeneratingQrCode, setIsGeneratingQrCode] = useState(false);
  const [maturando, setMaturando] = useState(false);
  const toast = useToast();

  const handleDelete = () => {
    openModalConfirm({
      message: `Deseja deletar o servidor: "${nome}"?`,
      onConfirm: async () => {
        try {
          const { status } = await api.delete(`/whatsapp-sessions/${id}`);
          toast({
            title: "Sessão Deletada!",
            status: "info",
            ...toastDefaultStyle,
          });
          sendJsonMessage({ action: "update-pm2" });
          setServers((servers) => servers.filter((server) => server.id !== id));
        } catch (e) {
          toast({
            title: "Erro ao deletar sessão!",
            status: "error",
            ...toastDefaultStyle,
          });
        }
      },
      confirmButtonStyle: { loadingText: "Deletando" },
    });
  };

  const handleDisconnect = () => {
    openModalConfirm({
      message: `Deseja desconectar o servidor: "${nome}"?`,
      onConfirm: async () => {
        sendJsonMessage({ action: "disconnect", whatsappSessionId });
      },
      confirmButtonStyle: { loadingText: "Desconectando" },
    });
  };

  createWebSocketEventListener(
    "qrcode-not-generated",
    (data) => {
      setPortalPage("qrcode-not-generated");
      setLoading(false);
    },
    whatsappSessionId
  );
  createWebSocketEventListener(
    "qrcode-generated",
    (data) => {
      QRCode.toDataURL(data.qrCode, function (err, url) {
        setPortalPage("qrcode-generated");
        setIsGeneratingQrCode(false);
        setQrCode(url);
        setLoading(false);
      });
    },
    whatsappSessionId
  );
  createWebSocketEventListener(
    "chat",
    (data) => {
      setPortalPage("chat");
      setLoading(false);
      if (typeof data.wid === "string") setNumber(data.wid);
    },
    whatsappSessionId
  );

  const pages = {
    "qrcode-not-generated": (
      <Flex
        onClick={() => {
          if (!isGeneratingQrCode) {
            sendJsonMessage({
              action: "generate-qrcode",
              whatsappSessionId,
            });
            setIsGeneratingQrCode(true);
          }
        }}
        w="200px"
        sx={{ aspectRatio: "1" }}
        bg="#60AFA1"
        _hover={{ bg: "#7EBEB2" }}
        transition="0.2s"
        borderRadius="full"
        px="15px"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        textAlign="center"
        color="white"
        cursor={isGeneratingQrCode ? undefined : "pointer"}
      >
        <RepeatIcon
          display="block"
          mb="25px"
          isAnimating={isGeneratingQrCode}
        />
        <Text maxW="200px" color="white" fontWeight="400" fontSize="12">
          {isGeneratingQrCode
            ? "RECARREGANDO O CÓDIGO QR"
            : "CLIQUE PARA RECARREGAR O CÓDIGO QR"}
        </Text>
      </Flex>
    ),
    "qrcode-generated": (
      <>
        <Image src={qrCode} h="calc(100%)" />
      </>
    ),
    chat: (
      <Box fontSize="18" textAlign="center">
        <Text textAlign="center">
          <Text as="span">
            {number.startsWith("55") &&
            (number.length === 13 || number.length === 12)
              ? phoneFormat(number, {
                  withCountryNumber: number.length >= 12,
                })
              : number}
          </Text>{" "}
        </Text>{" "}
        <Box
          w="100%"
          p="2px 10px"
          borderRadius="4"
          bg="whatsapp.500"
          fontWeight="semibold"
          color="#fff"
          onClick={() => {}}
          mb="8px"
        >
          Conectado
        </Box>
      </Box>
    ),
  };

  return (
    <Box>
      <Flex alignItems="center" mb="8px">
        <WhatsAppIcon display="block" mr="10px" /> <Text>{nome}</Text>
      </Flex>
      <SkeletonLoading isLoading={loading} borderRadius="10px">
        <Center
          p="8px"
          h="250px"
          flexDir="column"
          bgImg={`${
            portalPage === "chat"
              ? "url(/assets/backgrounds/whatsapp.svg),"
              : ""
          } linear-gradient(#fff, #fff)`}
          bgSize={portalPage === "chat" ? "40% 100%, 100% 100%" : "100% 100%"}
          bgRepeat="no-repeat"
          bgPos="center"
          borderRadius="10px"
          pos="relative"
          border="1px solid var(--chakra-colors-custom-gray)"
        >
          <Flex pos="absolute" right="8px" top="8px">
            {portalPage === "chat" ? (
              loading ? null : (
                <>
                  <Tooltip label="Maturação de Número">
                    <Button
                      aria-label=""
                      size="sm"
                      rightIcon={maturando ? <FaStop /> : <FaPlay />}
                      onClick={() => {
                        setMaturando((maturando) => {
                          if (!maturando)
                            toast({
                              title:
                                "Serviço em desenvolvimento, a maturação é fictícia.",
                              ...toastDefaultStyle,
                            });
                          return !maturando;
                        });
                      }}
                      mr="8px"
                    >
                      {maturando ? (
                        <Text animation="blink 1s linear infinite">
                          Maturando...
                        </Text>
                      ) : (
                        "Maturar"
                      )}
                    </Button>
                  </Tooltip>
                  <Tooltip label="Editar">
                    <IconButton
                      aria-label=""
                      size="sm"
                      icon={<EditIcon />}
                      onClick={() => openModalEditSessionWhatsApp(id)}
                      mr="8px"
                    />
                  </Tooltip>
                  <Tooltip label="Deslogar">
                    <IconButton
                      aria-label=""
                      size="sm"
                      icon={<LogoutIcon2 />}
                      onClick={handleDisconnect}
                      mr="8px"
                    />
                  </Tooltip>
                </>
              )
            ) : null}
            <Tooltip label="Excluir">
              <IconButton
                aria-label=""
                size="sm"
                icon={<DeleteIcon />}
                variant="danger"
                pos="relative"
                zIndex="100"
                onClick={handleDelete}
              />
            </Tooltip>
          </Flex>
          {loading ? null : pages[portalPage]}
        </Center>
      </SkeletonLoading>
    </Box>
  );
}

export default memo(WhatsAppPortal);
