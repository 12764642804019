import { GetLayout } from "components/get-layout";
import { GridServers } from "components/sessoes-whatsapp-components/grid-servers";

export function SessoesWhatsApp() {
  return (
    <GetLayout>
      <GridServers />
    </GetLayout>
  );
}
