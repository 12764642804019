import { Icon, IconProps } from "@chakra-ui/react";

export function PauseIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="10px"
      height="12px"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M6 12H10V0H6V12Z" fill="#EB4E3D" />
      <path d="M0 12H4V0H0V12Z" fill="#EB4E3D" />
    </Icon>
  );
}
