import { HamburgerIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Grid, Text, Textarea } from "@chakra-ui/react";
import { InputField } from "components/input-field";
import { Popover } from "components/popover";
import { ModalCampanhaWhatsAppData } from ".";

export function MensagemFields({
  modalData,
  CSVcols,
  objectKey = "mensagem",
  invalidFields,
  onChangeCampanha,
  errors,
  isDisabledFields,
}: {
  modalData: Partial<ModalCampanhaWhatsAppData>;
  objectKey?: keyof ModalCampanhaWhatsAppData;
  invalidFields: string[];
  errors: { [key: string]: string };
  onChangeCampanha: (
    field:
      | keyof ModalCampanhaWhatsAppData
      | (keyof ModalCampanhaWhatsAppData)[],
    value: any
  ) => Promise<void>;
  CSVcols: { name: string; value: string }[];
  isDisabledFields: boolean;
}) {
  const getPreVisualizacao = (mensagem: string | undefined) => {
    if (mensagem == null) return "";
    let preMensagem = mensagem;
    for (let attr in modalData.columnsValues) {
      preMensagem = preMensagem.replaceAll(
        `#${attr}#`,
        modalData.columnsValues?.[attr]
      );
    }
    return preMensagem;
  };
  return (
    <Grid templateColumns="1fr 1fr" mt="10px" w="100%" gap="20px">
      <Box>
        <Flex mb="8px" justifyContent="space-between" alignItems="center">
          <Text>Informe a mensagem</Text>
          <Popover
            title="Adicione campos do CSV"
            button={
              <Button
                aria-label=""
                size="sm"
                variant="outline"
                leftIcon={<HamburgerIcon />}
              >
                Colunas CSV
              </Button>
            }
            popupStyles={{ width: "auto" }}
            position="left-bottom"
          >
            <Grid
              w="100%"
              gap="8px"
              templateColumns="1fr"
              overflow={"auto"}
              maxH="200px"
            >
              {CSVcols.map(({ name, value }, index) => {
                return (
                  <Button
                    key={value}
                    onClick={() =>
                      onChangeCampanha(
                        objectKey,
                        (modalData[objectKey] || "") + `#${value}#`
                      )
                    }
                    size="sm"
                    variant="outline"
                    isDisabled={isDisabledFields}
                  >
                    {name}
                  </Button>
                );
              })}
            </Grid>
          </Popover>
        </Flex>
        <InputField
          value={modalData[objectKey] || ""}
          // auto-resize={true}
          errorMessage={
            invalidFields.includes(objectKey) ? errors[objectKey] : undefined
          }
          onChange={(e) => onChangeCampanha(objectKey, e.target.value)}
          inputProps={{
            as: "textarea",
            h: "154px",
            p: "10px 16px",
            _focus: {
              outline: "none",
              boxShadow: "none",
              borderStyle: "solid",
              borderWidth: "1px",
              borderColor: "var(--chakra-colors-custom-gray)",
            },
            isDisabled: isDisabledFields,
          }}
        />
      </Box>
      <Box>
        <Text mb="8px">
          Pré-visualização (utlizando primeiro contato do arquivo)
        </Text>
        <Textarea
          value={getPreVisualizacao(modalData[objectKey])}
          disabled
          // auto-resize={true}
          height={"137px"}
          autoComplete="new-password"
          borderStyle="solid"
          borderWidth="1px"
          borderColor="var(--chakra-colors-custom-gray)"
          _focus={{
            outline: "none",
            boxShadow: "none",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "var(--chakra-colors-custom-gray)",
          }}
          _hover={{ bg: "var(--chakra-colors-bg-gray)" }}
        />
      </Box>
    </Grid>
  );
}
