import { Box, Grid, Text } from "@chakra-ui/react";
import AttendanceDropdown from "components/performance/attendance-dropdown";
import OptimizedInput from "components/performance/attendance-input";
import { useAttendanceContext } from "contexts/AttendanceContext";
import {
  dropdownTipoContaPagamento,
  dropdownTipoPagamento,
} from "../../../fields-data";
import { Attendance } from "../../../types";
import { TabDataProps } from "../../consts";

export function DadosBancariosFields({
  onChangeField,
  resetFieldsTabelaDigitacao,
}: TabDataProps) {
  const { formControl } = useAttendanceContext();
  const formValues = formControl.values as Attendance;
  return (
    <>
      <Grid templateColumns="1fr 1fr 1fr" gap="16px">
        <Box>
          <Text mb="4px">Tipo Pagamento</Text>
          <AttendanceDropdown
            value={formValues.tipoPagamento || ""}
            fieldName="tipoPagamento"
            onChangeField={onChangeField}
            options={dropdownTipoPagamento}
            placeholder="Selecione"
            w="full"
          />
        </Box>
        <Box>
          <Text mb="4px">Cód. Banco Pagamento</Text>
          <OptimizedInput
            value={formValues.codBancoPagamento || ""}
            onChangeField={onChangeField}
            fieldName="codBancoPagamento"
          />
        </Box>
        <Box>
          <Text mb="4px">Tipo Conta Pagamento</Text>
          <AttendanceDropdown
            value={formValues.tipoContaPagamento || ""}
            fieldName="tipoContaPagamento"
            onChangeField={onChangeField}
            options={dropdownTipoContaPagamento}
            w="full"
          />
        </Box>
      </Grid>
      <Grid templateColumns="3fr 1fr 3fr 1fr" gap="16px">
        <Box>
          <Text mb="4px">Agência Pagamento</Text>
          <OptimizedInput
            value={formValues.agenciaPagamento || ""}
            onChangeField={onChangeField}
            fieldName="agenciaPagamento"
          />
        </Box>
        <Box>
          <Text mb="4px">DV (Ag)</Text>
          <OptimizedInput
            value={formValues.dvAgenciaPagamento || ""}
            onChangeField={onChangeField}
            fieldName="dvAgenciaPagamento"
          />
        </Box>
        <Box>
          <Text mb="4px">Conta Pagamento</Text>
          <OptimizedInput
            value={formValues.contaPagamento || ""}
            onChangeField={onChangeField}
            fieldName="contaPagamento"
          />
        </Box>
        <Box>
          <Text mb="4px">DV (Conta)</Text>
          <OptimizedInput
            value={formValues.dvContaPagamento || ""}
            onChangeField={onChangeField}
            fieldName="dvContaPagamento"
          />
        </Box>
      </Grid>
    </>
  );
}
