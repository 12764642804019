import { removeToken } from "Session";
import { User } from "contexts/types/application-context-types";

export const redirectColomboUserTo = new Map<User["userData"]["type"], string>([
  ["PARCEIRO_VENDEDOR", "/atendimentos"],
  ["COMERCIAL", "/usuarios"],
  ["PARCEIRO_MASTER", "/usuarios"],
]);

export const allowedUserTypesLoginColombo = Array.from(
  redirectColomboUserTo.keys()
);

export const redirectUserTo = new Map<User["userData"]["type"], string>([
  ["FINANCEIRO", "/carteira"],
  ["PARCEIRO_VENDEDOR", "/atendimentos"],
  ["PARCEIRO_OPERACIONAL", "/atendimentos"],
  ["SUPER", "/mailing"],
  ["REGIONAL", "/mailing"],
  ["COMERCIAL", "/mailing"],
  ["PARCEIRO_MASTER", "/mailing"],
]);

export const userTypeRedirect = ({
  userData,
}: {
  userData: User["userData"];
}) => {
  if (userData.customerCodigoNova?.startsWith("1309")) {
    if (redirectColomboUserTo.get(userData.type!))
      return redirectColomboUserTo.get(userData.type!)!;
    else {
      removeToken();
      return "/login";
    }
  } else {
    if (redirectUserTo.get(userData.type!))
      return redirectUserTo.get(userData.type!)!;
    else {
      removeToken();
      return "/login";
    }
  }
};
