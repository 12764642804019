import { Image } from "@chakra-ui/react";
import { Logo } from "components/vectors/logo";
import { LogoImediata } from "components/vectors/logo/imediata";
import { MaisAgilLogo } from "components/vectors/logo/mais-agil-logo";
import { getSubdomain } from "utils/verify-domain";

export const getLogo = () => {
  return (
    {
      maisagil: <MaisAgilLogo />,
      comprex: (
        <Image width="100%" src="/assets/layout/images/comprex-logo.png" />
      ),
      pl30: <Image width="100%" src="/assets/layout/images/pl30-logo.png" />,
      imediata: <LogoImediata />,
    }[getSubdomain()] ?? <Logo />
  );
};

export const getNavbarLogo = () => {
  return (
    {
      maisagil: <MaisAgilLogo width="80px" height="auto" />,
      comprex: (
        <Image width="180px" src="/assets/layout/images/comprex-logo.png" />
      ),
      pl30: <Image width="80px" src="/assets/layout/images/pl30-logo.png" />,
      imediata: <LogoImediata width="140px" />,
    }[getSubdomain()] ?? <Logo width="177px" height="34px" />
  );
};

export const getTitle = () => {
  return (
    {
      maisagil: "+Ágil Promotora",
      comprex: "Comprex Promotora",
      pl30: "PL30 Promotora",
      imediata: "Imediata Brasil Promotora",
    }[getSubdomain()] ?? "Nova Promotora"
  );
};
