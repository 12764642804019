import { Icon, IconProps } from "@chakra-ui/react";

export function DebitIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="20px"
      height="19px"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.99121 18.386C14.9746 18.386 19.0879 14.3372 19.0879 9.44045C19.0879 4.54374 14.9658 0.494873 9.98242 0.494873C5.00781 0.494873 0.90332 4.54374 0.90332 9.44045C0.90332 14.3372 5.0166 18.386 9.99121 18.386ZM6.53711 10.3056C5.9834 10.3056 5.58789 9.98549 5.58789 9.45775C5.58789 8.93001 5.96582 8.59261 6.53711 8.59261H13.4453C14.0166 8.59261 14.3857 8.93001 14.3857 9.45775C14.3857 9.98549 13.999 10.3056 13.4453 10.3056H6.53711Z"
        fill="#F50000"
      />
    </Icon>
  );
}
