import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Flex,
  IconButton,
  Spinner,
  Table,
  TableColumnHeaderProps,
  TableContainer,
  TableContainerProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { defaultScroll } from "chakra/theme";
import { sortArrayBy } from "utils/filter-array-by";
import { ChevronDownIcon } from "components/vectors/chevron-down-icon";
import { ChevronUpDownIcon } from "components/vectors/chevron-up-down-icon";
import { Fragment, ReactNode, useState } from "react";
import { Column } from "./types";
import { makeEvent } from "services/events";

interface DynamicTableProps {
  columns: Column[];
  rows: any[];
  pageSize?: number | null;
  onClickRow?: (row: any) => void;
  sortBy?: string;
  isLoading?: boolean;
  emptyMessage?: string;
  sortOrder?: "ASC" | "DESC";
  containerStyle?: TableContainerProps;
  rowKey?: string;
  headerButton?: ReactNode;
  lastRow?: any;
  size?: "full" | "md";
  headerStyle?: (row: any) => TableColumnHeaderProps;
  fontSize?: BoxProps["fontSize"];
  px?: any;
  RowInsideRender?: ({
    columnsLength,
    row,
  }: {
    row: any;
    columnsLength: number;
    rowIndex: number;
  }) => JSX.Element | null;
}

interface ArrayFilter {
  orderBy: string;
  sortOrder: DynamicTableProps["sortOrder"];
}

function DynamicTable({
  columns = [],
  rows = [],
  pageSize = 10,
  onClickRow,
  sortBy,
  isLoading,
  emptyMessage = "Sem dados",
  sortOrder = "ASC",
  containerStyle,
  rowKey = "id",
  headerButton,
  lastRow,
  fontSize = { base: "12px", xl: "15px" },
  px = { base: "0.8rem", xl: "1.5rem" },
  size,
  headerStyle,
  RowInsideRender,
}: DynamicTableProps) {
  const [currentIndexPage, setCurrentIndexPage] = useState(0);
  const [arrayFilter, setArrayFilter] = useState<ArrayFilter>({
    orderBy: "",
    sortOrder: "DESC",
  });
  // const fontSize = { base: "12px", xl: "15px" }; // 15px
  // const px = { base: "0.8rem", xl: "1.5rem" }; // 1.5rem

  const numberOfPages = pageSize ? Math.ceil(rows.length / pageSize) : 1;

  if (currentIndexPage > numberOfPages - 1 && currentIndexPage !== 0)
    setCurrentIndexPage(numberOfPages - 1);

  if (sortBy && !arrayFilter.orderBy)
    rows = sortArrayBy(rows, sortBy, sortOrder);

  if (arrayFilter.orderBy)
    rows = sortArrayBy(rows, arrayFilter.orderBy, arrayFilter.sortOrder);

  const paginedData =
    pageSize && rows.length > pageSize
      ? rows.slice(
          currentIndexPage * pageSize,
          (currentIndexPage + 1) * pageSize
        )
      : rows;

  let pageArray: number[] = [];
  for (let k = 0; k < numberOfPages; k++) {
    pageArray.push(k);
  }

  if (currentIndexPage - 2 >= 0 && numberOfPages > 5) {
    const cut: number[] = pageArray.slice(
      currentIndexPage - 2,
      currentIndexPage + 3
    );
    if (cut.length < 5)
      pageArray = pageArray.slice(numberOfPages - 5, numberOfPages);
    else pageArray = cut;
  } else pageArray = pageArray.slice(0, 5);

  return (
    <TableContainer
      pos="relative"
      bg="#fff"
      pb="30px"
      w="100%"
      overflowY={rows.length > 10 ? "auto" : "visible"}
      overflowX={"auto"}
      sx={defaultScroll}
      borderRadius="10"
      boxShadow="0 0 1em rgba(0,0,0,0.1)"
      {...containerStyle}
    >
      {headerButton ? (
        <Flex
          alignItems="center"
          pos="absolute"
          top="0"
          h="50.5px"
          right={{ base: "10px", "2xl": "25px" }}
        >
          {headerButton}
        </Flex>
      ) : null}
      <Table>
        <Thead>
          <Tr bg="#F5F5F5">
            {columns.map((column, index) => {
              const { name, key, sortClick, isVisible = true } = column;
              const isSelected = key === arrayFilter.orderBy;
              const isASC = arrayFilter.sortOrder === "ASC";

              return isVisible ? (
                <Th
                  key={`column-${name}-${index}`}
                  textTransform="none"
                  fontSize={fontSize}
                  px={px}
                  fontWeight="bold"
                  color="text"
                  fontFamily="Inter"
                  bg={isSelected ? "gray.200" : "none"}
                  _hover={{ bg: sortClick ? "gray.200" : "none" }}
                  cursor={sortClick ? "pointer" : undefined}
                  onClick={
                    sortClick
                      ? () =>
                          isSelected
                            ? isASC
                              ? setArrayFilter({
                                  orderBy: "",
                                  sortOrder: "DESC",
                                })
                              : setArrayFilter({
                                  orderBy: key,
                                  sortOrder: "ASC",
                                })
                            : setArrayFilter({
                                orderBy: key,
                                sortOrder: "DESC",
                              })
                      : undefined
                  }
                  {...headerStyle?.(column)}
                >
                  <Flex alignItems="center" gap="8px" minH="26px">
                    {name}{" "}
                    {sortClick ? (
                      <Flex
                        alignItems="center"
                        justifyContent="center"
                        w="25px"
                        h="26px"
                        transform={isASC ? undefined : "rotate(180deg)"}
                      >
                        {!isSelected ? (
                          <ChevronUpDownIcon width="8px" />
                        ) : (
                          <ChevronDownIcon width="8px" />
                        )}
                      </Flex>
                    ) : null}
                  </Flex>
                </Th>
              ) : null;
            })}
          </Tr>
        </Thead>

        <Tbody>
          {isLoading
            ? null
            : paginedData.map((row, rowIndex) => (
                <Fragment key={`row-${rowIndex}`}>
                  <Tr
                    onClick={() => {
                      if (onClickRow) onClickRow(row);
                      if (RowInsideRender) makeEvent(`table-row`, rowIndex);
                    }}
                    cursor={
                      onClickRow || RowInsideRender ? "pointer" : undefined
                    }
                    className="table-row"
                  >
                    {columns.map(
                      ({ key, render, cellStyle, isVisible = true }, index) => {
                        return isVisible ? (
                          <Fragment key={`cell-${rowIndex}-${index}`}>
                            {render ? (
                              <Td
                                py="0.6em"
                                color="text"
                                fontSize={fontSize}
                                px={px}
                                {...cellStyle?.(row, rowIndex)}
                              >
                                {render(row, rowIndex)}
                              </Td>
                            ) : (
                              <Td
                                py="0.6em"
                                color="text"
                                fontSize={fontSize}
                                px={px}
                                {...cellStyle?.(row, rowIndex)}
                              >
                                {row[key ?? ""]}
                              </Td>
                            )}
                          </Fragment>
                        ) : null;
                      }
                    )}
                  </Tr>
                  {RowInsideRender ? (
                    <RowInsideRender
                      columnsLength={columns.length}
                      row={row}
                      rowIndex={rowIndex}
                    />
                  ) : null}
                </Fragment>
              ))}
          {lastRow ? (
            <Tr background="#F5F5F5">
              {columns.map(
                ({ key, cellStyle, render, isVisible = true }, index) => {
                  render?.(lastRow, paginedData.length);
                  return isVisible ? (
                    <Td
                      key={`cell-${paginedData.length}-${index}`}
                      py="0.6em"
                      color="text"
                      fontSize={fontSize}
                      px={px}
                      {...cellStyle?.(null, null)}
                    >
                      {lastRow[key ?? ""]}
                    </Td>
                  ) : null;
                }
              )}
            </Tr>
          ) : null}
        </Tbody>
      </Table>
      {isLoading ? (
        <Flex
          minH={size === "md" ? "200px" : "400px"}
          justifyContent="center"
          alignItems="center"
        >
          <Spinner />
        </Flex>
      ) : null}
      {isLoading === false && !rows.length ? (
        <Text display="flex" alignItems="center" p="0 0 0 20px" minH="39px">
          {emptyMessage}
        </Text>
      ) : null}

      {isLoading ? null : pageSize && numberOfPages > 1 ? (
        <Flex w="100%" justifyContent="flex-end" p="30px 40px 0">
          <Flex alignItems="center" gap="10px">
            <IconButton
              aria-label="pagina anterior"
              variant="outline"
              icon={<ArrowLeftIcon w="9px" h="9px" />}
              onClick={() => setCurrentIndexPage(0)}
              disabled={currentIndexPage === 0}
            />
            <IconButton
              aria-label="pagina anterior"
              variant="outline"
              icon={<ChevronLeftIcon />}
              onClick={() => setCurrentIndexPage((state) => state - 1)}
              disabled={currentIndexPage === 0}
            />
            {pageArray.map((number, index) => {
              const active = currentIndexPage === number;
              return (
                <Text
                  key={`pagestable-${index}`}
                  _hover={{ textDecor: "underline" }}
                  py="4px"
                  px="5px"
                  fontWeight={active ? "bold" : "normal"}
                  onClick={() => setCurrentIndexPage(number)}
                  cursor="pointer"
                  textAlign="center"
                  borderRadius="5"
                  bg={active ? "#D0DAFF" : "none"}
                >
                  {number + 1}
                </Text>
              );
            })}
            <IconButton
              variant="outline"
              aria-label="proxima pagina"
              icon={<ChevronRightIcon />}
              onClick={() => setCurrentIndexPage((state) => state + 1)}
              disabled={currentIndexPage === numberOfPages - 1}
            />
            <IconButton
              variant="outline"
              aria-label="proxima pagina"
              icon={<ArrowRightIcon w="9px" h="9px" />}
              onClick={() => setCurrentIndexPage(numberOfPages - 1)}
              disabled={currentIndexPage === numberOfPages - 1}
            />
          </Flex>
        </Flex>
      ) : null}
    </TableContainer>
  );
}

export default DynamicTable;
