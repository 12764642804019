export const tiposEmprestimos: any = {
  "98": "98 - Empréstimo por Consignação",
  "76": "76 - Reserva de Margem para Cartão (RMC)",
  "44": "44 - Reserva de Cartão Consignado (RCC)",
};

export const bancosContratos: any = {
  "12": "Banco Inbursa",
  "29": "Banco Itaú Consignado",
  "33": "Banco Santander",
  "41": "Banrisul",
  "69": "Banco Crefisa",
  "104": "Caixa Econ. Federal",
  "121": "Banco Agibank",
  "169": "Banco Olé",
  "237": "Banco Bradesco",
  "254": "Paraná Banco",
  "290": "PagBank",
  "318": "Banco BMG",
  "394": "Banco Bradesco Financiamentos",
  "341": "Banco Itaú",
  "422": "Banco Safra",
  "623": "Banco PAN",
  "626": "Banco C6",
  "707": "Banco Daycoval",
  "739": "Banco Cetelem",
  "935": "Facta Financeira",
};
