import { HStack, Radio, RadioGroup, RadioGroupProps } from "@chakra-ui/react";
import { MailingFilterOption, MailingFilterProps } from "../../types";

export function RadioFilter({
  operators,
  mapKey,
  onChangeFilter,
  value,
  radioGroupProps,
}: {
  operators: MailingFilterProps["operators"];
  mapKey: MailingFilterProps["mapKey"];
  onChangeFilter: MailingFilterProps["onChangeFilter"];
  value?: MailingFilterOption | undefined;
  radioGroupProps?: RadioGroupProps;
}) {
  return (
    <RadioGroup
      onChange={(value: MailingFilterOption) => {
        if (value !== "")
          onChangeFilter(mapKey, (prev) => {
            prev.set(mapKey, { op: value });
            return new Map(prev.entries());
          });
        else
          onChangeFilter(mapKey, (prev) => {
            prev.delete(mapKey);
            return new Map(prev.entries());
          });
      }}
      value={value ?? ""}
      {...radioGroupProps}
    >
      <HStack spacing="20px" mb={value ? "10px" : ""}>
        <Radio value="">Ignorar Filtro</Radio>
        {Object.keys(operators).map((key) => {
          const value = operators[key];
          return (
            <Radio key={`${value}-${mapKey}`} value={value}>
              {key}
            </Radio>
          );
        })}
      </HStack>
    </RadioGroup>
  );
}
