import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { createStandaloneToast, useToast } from "@chakra-ui/react";
import { Client, StompConfig } from "@stomp/stompjs";
import { getToken } from "Session";
import { api3cPlus } from "api/wss-c3plus";
import { toastDefaultStyle } from "chakra/theme";
import { openModalConfirm } from "components/modal-confirm-new";
import { useApplicationContext } from "contexts/ApplicationContext";
import {
  Controls3cPlusActions,
  Controls3cPlusProps,
  Use3cPlusConnectionParams,
} from "contexts/types/context-3cplus-types";
import { stomp3cPlusEvents } from "./stomp-3cplus-events";
import { isModalAttendanceOpen } from "pages/atendimentos-page/get-layout-atendimento";
import { current3cplusControls } from "contexts/Context3cPlus";
import { Dispatch } from "react";

export interface QualificationProps {
  allow_schedule: null;
  allow_schedule_to_another_number: null;
  behavior: 2;
  color: string;
  conversion: false;
  days_limit: null;
  emoji: null;
  id: number;
  name: string;
  readable_behavior: "not-call-identifier";
  should_insert_blacklist: boolean;
}

const { toast } = createStandaloneToast();

export const get3CplusAuthenticatedUser = async () => {
  const { data } = await api3cPlus.get(`/3cplus/me`);
  return data;
};

export const enterCampaign = async (campaign: number, mode: string) => {
  const { data } = await api3cPlus.post(`/3cplus/agent/login`, {
    campaign,
    mode,
  });
  return data;
};

export const logoutCampaign = async () => {
  const { data } = await api3cPlus.post(`/3cplus/agent/logout`, {});
  return data;
};

export const getQualifications = async () => {
  const { data }: { data: QualificationProps[] } = await api3cPlus.get(
    `/3cplus/qualification-lists/agent/qualifications`
  );
  return data;
};

export const listCampaigns = async () => {
  const { data } = await api3cPlus.get(`/3cplus/campaigns/campaigns`);
  return data;
};

export const enterManualMode = async () => {
  const { lastEventName, isManualMode } = current3cplusControls;
  if (!isManualMode) {
    try {
      const { data } = await api3cPlus.post("/3cplus/agent/manual_call/enter");
      return data;
    } catch (e) {
      const { data } = await api3cPlus.post(
        `/3cplus/agent/manual_call_acw/enter`
      );
      return data;
    }
  }
};

export const exitManualCallState = async ({
  dispatch3cPlus,
}: {
  dispatch3cPlus: Dispatch<Controls3cPlusActions>;
}) => {
  const { data } = await api3cPlus.post(`/3cplus/agent/manual_call/exit`);
  dispatch3cPlus({ type: "notInManualMode" });
  return data;
};

export const manualCall = async (phone: string) => {
  const { data } = await api3cPlus.post(`/3cplus/agent/manual_call/dial`, {
    phone,
  });
  return data;
};

export const makeManualCall = async ({
  phone,
  dispatch3cPlus,
}: {
  phone: string;
  dispatch3cPlus: Dispatch<Controls3cPlusActions>;
}) => {
  if (phone.startsWith("55")) phone = phone.replace("55", "");
  try {
    await enterManualMode();
    dispatch3cPlus({ type: "inManualMode" });
  } catch (e) {}
  await manualCall(phone);
};

export const desligarChamada3cPlus = async ({
  dispatch3cPlus,
  idCall,
}: {
  idCall: number;
  dispatch3cPlus: Dispatch<Controls3cPlusActions>;
}) => {
  try {
    await api3cPlus.post(`3cplus/agent/call/${idCall}/hangup`);
    if (!isModalAttendanceOpen && current3cplusControls.isManualMode) {
      openModalReturnToCampaign({ dispatch3cPlus });
    }
  } catch (e: any) {
    const errorMessage = e?.response?.data?.detail;
    toast({
      title: errorMessage ?? "Erro ao desligar chamada",
      ...toastDefaultStyle,
    });
  } finally {
  }
};

let stompClientInstance: Client | null = null;

export const use3cPlusWSConnection = ({
  dispatch3cPlus,
}: Use3cPlusConnectionParams) => {
  const {
    user: { userData },
  } = useApplicationContext();
  const [user, pass] = window.atob(getToken()!).split(":");
  const toast = useToast(toastDefaultStyle);
  if (userData.enabled3cplus) {
    const stompConfig: StompConfig = {
      connectHeaders: { login: user, passcode: pass },
      brokerURL: process.env.REACT_APP_PUBLIC_ATTENDANCE_WEBSOCKET,
      debug: function (str: string) {
        // console.log("STOMP: " + str);
      },

      reconnectDelay: 500,
      onConnect: (frame: any) => {
        console.log("STOMP CONNECTED");

        stomp3cPlusEvents.forEach(({ eventType, callback }) => {
          stompClientInstance?.subscribe(eventType, (message) => {
            const eventName = eventType.split("/").splice(-1)[0];
            dispatch3cPlus({ type: "setStatus", payload: eventName });
            console.log(eventName);
            callback(message, { dispatch3cPlus });
          });
        });
      },
    };

    if (stompClientInstance == null) {
      stompClientInstance = new Client(stompConfig);
      stompClientInstance.activate();
    }
  }
  return stompClientInstance;
};

export function disconnect3cPlusStomp() {
  stompClientInstance?.deactivate();
  stompClientInstance = null;
}

export const openModalReturnToCampaign = ({
  dispatch3cPlus,
}: {
  dispatch3cPlus: Dispatch<Controls3cPlusActions>;
}) => {
  openModalConfirm({
    message: "Deseja voltar para campanha?",
    onConfirm: async () => await exitManualCallState({ dispatch3cPlus }),
    confirmButtonStyle: { variant: undefined, leftIcon: <CheckIcon /> },
    rejectButtonStyle: { leftIcon: <CloseIcon w="12px" h="12px" /> },
  });
};
