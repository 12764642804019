import { SVGProps } from "react";

export function MessageArrow({ ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 5 5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M5 5V0H0L5 5Z" fill="currentColor" />
    </svg>
  );
}
