import { Flex, Grid } from "@chakra-ui/react";
import Checkbox, { CheckboxProps } from "components/checkbox";
import { Template } from "../mailing-template";
import { MailingFilterProps } from "../../types";

export function CheckboxesFilter({
  title,
  description,
  mapKey,
  onChangeFilter,
  operators,
  checkboxProps,
  filtersValues,
  headerOptions,
  options,
}: {
  options: any[];
  headerOptions: { name: string; options: any[] }[];
  checkboxProps?: CheckboxProps;
} & MailingFilterProps) {
  const currentFilter = filtersValues.get(mapKey);
  const filterValue = currentFilter?.value ?? [];
  const op = operators[Object.keys(operators)[0]];

  const isCheckedHeader = (options: any[]) => {
    for (let option of options) if (!filterValue.includes(option)) return false;
    return true;
  };

  const onToggleCheckbox = (value: any) => {
    const isArray = Array.isArray(value);
    const options = value;

    const isInclude = isArray
      ? isCheckedHeader(value)
      : filterValue.includes(value);

    if (isInclude) {
      if (isArray) {
        const newValue = filterValue.filter((curr) => !options!.includes(curr));
        if (!newValue.length) {
          onChangeFilter(mapKey, (prev) => {
            prev.delete(mapKey);
            return new Map(prev.entries());
          });
        } else {
          onChangeFilter(mapKey, (prev) => {
            prev.set(mapKey, { ...currentFilter!, value: newValue, op });
            return new Map(prev.entries());
          });
        }
      } else {
        const newValue = filterValue.filter((curr) => curr !== value);
        if (!newValue.length) {
          onChangeFilter(mapKey, (prev) => {
            prev.delete(mapKey);
            return new Map(prev.entries());
          });
        } else {
          onChangeFilter(mapKey, (prev) => {
            prev.set(mapKey, { ...currentFilter!, value: newValue, op });
            return new Map(prev.entries());
          });
        }
      }
    } else {
      if (isArray) {
        onChangeFilter(mapKey, (prev) => {
          prev.set(mapKey, { ...currentFilter!, value: options, op });
          return new Map(prev.entries());
        });
      } else {
        const newValue = [...new Set([...filterValue, value])];
        onChangeFilter(mapKey, (prev) => {
          prev.set(mapKey, { ...currentFilter!, value: newValue, op });
          return new Map(prev.entries());
        });
      }
    }
  };

  return (
    <Template title={title} description={description} mapKey={mapKey}>
      <Flex>
        {headerOptions?.map(({ name, options }) => {
          return (
            <Checkbox
              key={name}
              p="10px"
              isChecked={isCheckedHeader(options)}
              onChange={() => onToggleCheckbox(options)}
              {...checkboxProps}
            >
              {name}
            </Checkbox>
          );
        })}
      </Flex>
      <Grid
        templateColumns={{
          base: "repeat(10, 1fr)",
          xl: "repeat(15, 1fr)",
          "2xl": "repeat(20, 1fr)",
        }}
        flexWrap="wrap"
        w="100%"
      >
        {options?.map((optionValue: any) => {
          return (
            <Checkbox
              key={optionValue}
              p="10px"
              isChecked={currentFilter?.value?.includes(optionValue)}
              onChange={() => onToggleCheckbox(optionValue)}
              {...checkboxProps}
            >
              {optionValue}
            </Checkbox>
          );
        })}
      </Grid>
    </Template>
  );
}
