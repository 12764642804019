import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  IconButton,
  Input,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import api from "api/api";
import { invalidTextStyle, toastDefaultStyle } from "chakra/theme";
import { CustomModal } from "components/custom-modal";
import { Dropdown } from "components/dropdown";
import DynamicTable from "components/dynamic-table";
import { openModalConfirm } from "components/modal-confirm-new";
import { dropdownBancos } from "components/usuarios-bancos-components/consts";
import { useEffect, useLayoutEffect, useState } from "react";

interface UsuarioBanco {
  banco: string;
  codigoDigitador?: string;
  cpfUsuarioCertificado?: string;
  customer?: string;
  ativoDigitador?: boolean;
  id?: number | string;
  lastErrorMsg?: string;
  nomeUsuarioCertificado: null | string;
  password: string;
  username: string;
  ativoHigienizador: boolean;
  codigoPromotora?: string;
}

export function PageContent() {
  const [usuariosBancos, setUsuariosBancos] = useState<UsuarioBanco[]>([]);
  const [usuarioBancoDialog, setUsuarioBancoDialog] = useState<UsuarioBanco>({
    id: "",
    ativoDigitador: false,
    banco: "",
    username: "",
    password: "",
    cpfUsuarioCertificado: "",
    nomeUsuarioCertificado: "",
    codigoDigitador: "",
    ativoHigienizador: false,
  });
  const digitadorDisabled = !["PAN", "FACTA", "BMG", "MASTER"].includes(
    usuarioBancoDialog.banco
  );

  const {
    isOpen: isOpenModal,
    onClose: onCloseModal,
    onOpen: onOpenModal,
  } = useDisclosure();

  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    banco: false,
    username: false,
    password: false,
    cpfUsuarioCertificado: false,
    nomeUsuarioCertificado: false,
    codigoDigitador: false,
    codigoPromotora: false,
  });

  const loadUsuariosBanco = async () => {
    try {
      let response = await api.get("/usuarios-bancos");
      setUsuariosBancos(response.data);
    } catch (error) {
      toast({
        title: "Erro ao buscar lista de Usuario Banco",
        status: "error",
        ...toastDefaultStyle,
      });
    }
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await loadUsuariosBanco();
      setLoading(false);
    };
    load();
  }, []);

  const openNew = () => {
    setErrors({
      banco: false,
      username: false,
      password: false,
      cpfUsuarioCertificado: false,
      nomeUsuarioCertificado: false,
      codigoDigitador: false,
      codigoPromotora: false,
    });
    setUsuarioBancoDialog({
      banco: "",
      username: "",
      password: "",
      cpfUsuarioCertificado: "",
      nomeUsuarioCertificado: "",
      codigoDigitador: "",
      ativoHigienizador: false,
    });
    onOpenModal();
  };

  const acceptDeleteUsuarioBanco = async (rowData: UsuarioBanco) => {
    setLoading(true);
    try {
      await api.delete("/usuarios-bancos/" + rowData.id);
    } catch (error) {
      toast({
        title: "Erro ao excluir Usuario Banco",
        status: "error",
        ...toastDefaultStyle,
      });
    }
    await loadUsuariosBanco();
    setLoading(false);
  };

  const confirmDeleteUsuarioBanco = (rowData: UsuarioBanco) => {
    openModalConfirm({
      message: `Deseja excluir ${rowData.username} (${rowData.banco}) ?`,
      onConfirm: () => acceptDeleteUsuarioBanco(rowData),
      confirmButtonStyle: { variant: "danger", leftIcon: <DeleteIcon /> },
    });
  };

  const onChangeUsuarioBancoDialog = (
    field: keyof typeof errors,
    value: string
  ) => {
    setErrors({ ...errors, [field]: false });
    if (["username", "password"].includes(field)) {
      setUsuarioBancoDialog({
        ...usuarioBancoDialog,
        [field]: value,
      });
    } else {
      setUsuarioBancoDialog({
        ...usuarioBancoDialog,
        [field]: value,
      });
    }
  };

  const onChangeUsuarioBancoDialogCheckbox = (
    field: keyof typeof usuarioBancoDialog,
    e: { target: { checked: boolean } }
  ) => {
    setUsuarioBancoDialog({
      ...usuarioBancoDialog,
      [field]: e.target.checked,
    });
  };

  useLayoutEffect(() => {
    if (digitadorDisabled)
      onChangeUsuarioBancoDialogCheckbox("ativoDigitador", {
        target: { checked: false },
      });
  }, [usuarioBancoDialog.banco]);

  const saveOrUpdateUsuarioBanco = async () => {
    let isError = false;
    let errorsCopy: any = { ...errors };
    const checkFields: any = {
      PAN: [
        "banco",
        "username",
        "password",
        "cpfUsuarioCertificado",
        "nomeUsuarioCertificado",
        "codigoDigitador",
        "codigoPromotora",
      ],
      BMG: ["banco", "username", "password"],
      MASTER: ["banco", "username", "password"],
    };
    const fields: (keyof UsuarioBanco)[] = usuarioBancoDialog.ativoDigitador
      ? checkFields[usuarioBancoDialog.banco] ?? [
          "banco",
          "username",
          "password",
          "cpfUsuarioCertificado",
        ]
      : usuarioBancoDialog.banco === "PAN"
      ? ["banco", "username", "password", "codigoPromotora"]
      : ["banco", "username", "password"];

    fields.forEach((field) => {
      if (!usuarioBancoDialog[field]) {
        isError = true;
        errorsCopy[field] = true;
      }
    });
    if (isError) {
      setErrors(errorsCopy);
      return;
    }

    let tempUsuarioBanco = {
      ...usuarioBancoDialog,
      username: usuarioBancoDialog.username.trim(),
      password: usuarioBancoDialog.password.trim(),
    };
    setLoading(true);
    try {
      if (!usuarioBancoDialog.id) {
        await api.post("/usuarios-bancos", tempUsuarioBanco);
      } else {
        await api.put(
          "/usuarios-bancos/" + tempUsuarioBanco.id,
          tempUsuarioBanco
        );
      }
    } catch (error: any) {
      toast({
        title: error?.response?.data ?? "Erro ao salvar o Usuario Banco",
        status: "error",
        ...toastDefaultStyle,
      });
    }
    onCloseModal();
    await loadUsuariosBanco();
    setLoading(false);
  };

  const editUsuarioBanco = (usuarioBanco: UsuarioBanco) => {
    setErrors({
      banco: false,
      username: false,
      password: false,
      cpfUsuarioCertificado: false,
      nomeUsuarioCertificado: false,
      codigoDigitador: false,
      codigoPromotora: false,
    });
    setUsuarioBancoDialog({ ...usuarioBanco });
    onOpenModal();
  };

  const usuarioBancoDialogFooter = (
    <>
      <Button
        leftIcon={<CheckIcon />}
        onClick={saveOrUpdateUsuarioBanco}
        isLoading={loading}
        loadingText="Salvando"
      >
        Salvar
      </Button>
      <Button
        leftIcon={<CloseIcon w="12px" h="12px" />}
        variant="outline"
        onClick={onCloseModal}
      >
        Cancelar
      </Button>
    </>
  );

  const columns = [
    {
      name: "Banco",
      key: "banco",
    },
    {
      name: "Usuário",
      key: "username",
      render: (row: UsuarioBanco) => (
        <>
          <Text>{row.username}</Text>
          {row.lastErrorMsg ? (
            <Text sx={invalidTextStyle}>({row.lastErrorMsg})</Text>
          ) : null}
        </>
      ),
    },
    {
      name: "Higienizador",
      render: (row: UsuarioBanco) =>
        row.ativoHigienizador ? <b>SIM</b> : "NÃO",
    },
    {
      name: "Digitador",
      render: (row: UsuarioBanco) => (row.ativoDigitador ? <b>SIM</b> : "NÃO"),
    },
    {
      name: "Senha",
      key: "password",
    },
    {
      name: "Ações",
      render: (row: UsuarioBanco) => (
        <Flex gap="10px">
          <IconButton
            aria-label=""
            icon={<EditIcon />}
            onClick={() => editUsuarioBanco(row)}
          />
          <IconButton
            aria-label=""
            icon={<DeleteIcon />}
            variant="danger"
            onClick={() => confirmDeleteUsuarioBanco(row)}
          />
        </Flex>
      ),
    },
  ];

  return (
    <>
      <Flex justifyContent="space-between" px="10px" mb="30px">
        <Text fontSize="25" fontWeight="bold">
          Usuários Bancos
        </Text>
        <Flex gap="20px" alignItems="center">
          <Button leftIcon={<AddIcon />} onClick={openNew}>
            Novo
          </Button>
        </Flex>
      </Flex>

      <DynamicTable
        columns={columns}
        rows={usuariosBancos}
        isLoading={loading}
        emptyMessage="Não há usuários cadastrados"
      />

      <CustomModal
        isOpen={isOpenModal}
        minW="450px"
        modalTitle="Usuário Banco"
        modalFooter={usuarioBancoDialogFooter}
        onClose={onCloseModal}
      >
        <Flex>
          <Checkbox
            isChecked={usuarioBancoDialog.ativoHigienizador}
            onChange={(e) =>
              onChangeUsuarioBancoDialogCheckbox("ativoHigienizador", e)
            }
            mb="15px"
            mr="10px"
          >
            Ativo (Higienizador)
          </Checkbox>
          <Checkbox
            isChecked={usuarioBancoDialog.ativoDigitador}
            onChange={(e) =>
              onChangeUsuarioBancoDialogCheckbox("ativoDigitador", e)
            }
            mb="15px"
            disabled={digitadorDisabled}
          >
            Ativo (Digitador)
          </Checkbox>
        </Flex>
        <Box mb="15px">
          <Text mb="8px">Banco</Text>
          <Dropdown
            value={usuarioBancoDialog.banco}
            onChange={(value) => onChangeUsuarioBancoDialog("banco", value)}
            options={dropdownBancos}
            placeholder="Selecione"
            w="full"
            isInvalid={errors.banco}
          />
          {errors.banco && (
            <Text sx={invalidTextStyle}>Selecione um banco</Text>
          )}
        </Box>
        <Box mb="15px">
          <Text mb="8px">Usuário</Text>
          <Input
            value={usuarioBancoDialog.username}
            onChange={(e) =>
              onChangeUsuarioBancoDialog("username", e.target.value)
            }
            isInvalid={errors.username}
          />
          {errors.username && (
            <Text sx={invalidTextStyle}>Informe um usuário</Text>
          )}
        </Box>
        <Box mb="15px">
          <Text mb="8px">Senha</Text>
          <Input
            value={usuarioBancoDialog.password}
            onChange={(e) =>
              onChangeUsuarioBancoDialog("password", e.target.value)
            }
            isInvalid={errors.password}
          />
          {errors.password && (
            <Text sx={invalidTextStyle}>Informe uma senha</Text>
          )}
        </Box>
        {usuarioBancoDialog.banco === "PAN" ? (
          <Box mb="15px">
            <Text mb="8px">Código Promotora</Text>
            <Input
              value={usuarioBancoDialog.codigoPromotora}
              onChange={(e) =>
                onChangeUsuarioBancoDialog(
                  "codigoPromotora",
                  e.target.value.trim()
                )
              }
              isInvalid={errors.codigoPromotora}
            />
            {errors.codigoPromotora && (
              <Text sx={invalidTextStyle}>Informe o código da promotora</Text>
            )}
          </Box>
        ) : null}
        {usuarioBancoDialog.ativoDigitador &&
        usuarioBancoDialog.banco !== "BMG" &&
        usuarioBancoDialog.banco !== "MASTER" ? (
          <>
            <Box mb="15px">
              <Text mb="8px">
                {usuarioBancoDialog.banco === "PAN"
                  ? "CPF Usuário Certificado"
                  : "Login Certificado"}
              </Text>
              <Input
                value={usuarioBancoDialog.cpfUsuarioCertificado}
                onChange={(e) =>
                  onChangeUsuarioBancoDialog(
                    "cpfUsuarioCertificado",
                    e.target.value
                  )
                }
                isInvalid={errors.cpfUsuarioCertificado}
              />
              {errors.cpfUsuarioCertificado && (
                <Text sx={invalidTextStyle}>
                  {`Informe um ${
                    usuarioBancoDialog.banco === "PAN"
                      ? "CPF Usuário Certificado"
                      : "Login Certificado"
                  }`}
                </Text>
              )}
            </Box>
            {usuarioBancoDialog.banco === "PAN" ? (
              <>
                <Box mb="15px">
                  <Text mb="8px">Nome Usuário Certificado</Text>
                  <Input
                    value={usuarioBancoDialog.nomeUsuarioCertificado || ""}
                    onChange={(e) =>
                      onChangeUsuarioBancoDialog(
                        "nomeUsuarioCertificado",
                        e.target.value
                      )
                    }
                    isInvalid={errors.nomeUsuarioCertificado}
                  />
                  {errors.nomeUsuarioCertificado && (
                    <Text sx={invalidTextStyle}>
                      Informe um Nome Usuário Certificado
                    </Text>
                  )}
                </Box>
                <Box mb="15px">
                  <Text mb="8px">Código Digitador</Text>
                  <Input
                    value={usuarioBancoDialog.codigoDigitador}
                    onChange={(e) =>
                      onChangeUsuarioBancoDialog(
                        "codigoDigitador",
                        e.target.value
                      )
                    }
                    isInvalid={errors.codigoDigitador}
                  />
                  {errors.codigoDigitador && (
                    <Text sx={invalidTextStyle}>
                      Informe um Código Digitador
                    </Text>
                  )}
                </Box>
              </>
            ) : null}
          </>
        ) : null}
      </CustomModal>
    </>
  );
}
