import { createStandaloneToast } from "@chakra-ui/react";
import { IMessage } from "@stomp/stompjs";
import {
  Controls3cPlusActions,
  Controls3cPlusProps,
} from "contexts/types/context-3cplus-types";
import { Dispatch } from "react";
import { getExportedFunction } from "./get-external-function";
import { isModalAttendanceOpen } from "pages/atendimentos-page/get-layout-atendimento";
import { toastDefaultStyle } from "chakra/theme";
import { current3cplusControls } from "contexts/Context3cPlus";

type Params = {
  dispatch3cPlus: Dispatch<Controls3cPlusActions>;
};

interface StompEventArray {
  eventType: string;
  callback: (m: IMessage, { dispatch3cPlus }: Params) => void;
}

const { toast } = createStandaloneToast();

export const stomp3cPlusEvents: StompEventArray[] = [
  {
    eventType: "/user/3cplus/agent-idle",
    callback: (message, { dispatch3cPlus }) => {
      const { isManualMode } = current3cplusControls;
      const clearIdCall = !isManualMode;
      dispatch3cPlus({ type: "notInCall", payload: { clearIdCall } });
      // dispatch3cPlus({ type: "notInManualMode" });
    },
  },
  {
    eventType: "/user/3cplus/agent-was-logged-out",
    callback: (message, { dispatch3cPlus }) => {
      dispatch3cPlus({ type: "campaignLogout" });
    },
  },
  {
    eventType: "/user/3cplus/agent-entered-manual",
    callback: (message, { dispatch3cPlus }) => {
      dispatch3cPlus({ type: "inManualMode" });
    },
  },
  {
    eventType: "/user/3cplus/manual-call-acw-connected",
    callback: (message, { dispatch3cPlus }) => {
      //
    },
  },
  {
    eventType: "/user/3cplus/manual-call-acw-disconnected",
    callback: (message, { dispatch3cPlus }) => {
      dispatch3cPlus({ type: "notInCall" });
    },
  },
  {
    eventType: "/user/3cplus/manual-call-disconnected",
    callback: (message, { dispatch3cPlus }) => {
      dispatch3cPlus({ type: "notInCall" });
    },
  },
  {
    eventType: "/user/3cplus/agent-failed-to-enter-manual",
    callback: (message, { dispatch3cPlus }) => {
      dispatch3cPlus({ type: "notInManualMode" });
      toast({
        title: "Falha para entrar em modo manual",
        status: "error",
        ...toastDefaultStyle,
      });
    },
  },
  {
    eventType: "/user/3cplus/agent-failed-to-leave-manual-mode",
    callback: (message, { dispatch3cPlus }) => {
      dispatch3cPlus({ type: "inManualMode" });
      toast({
        title: "Falha para sair de modo manual",
        status: "error",
        ...toastDefaultStyle,
      });
    },
  },
  {
    eventType: "/user/3cplus/agent-entered-manual-acw",
    callback: (message, { dispatch3cPlus }) => {
      //
    },
  },
  {
    eventType: "/user/3cplus/agent-left-manual-acw",
    callback: (message, { dispatch3cPlus }) => {
      //
    },
  },
  {
    eventType: "/user/3cplus/agent-left-manual-mode",
    callback: (message, { dispatch3cPlus }) => {
      dispatch3cPlus({ type: "notInManualMode" });
    },
  },
  {
    eventType: "/user/3cplus/call-was-connected",
    callback: (message, { dispatch3cPlus }) => {
      let data = JSON.parse(message.body);
      const idCall = data?.call?.id;
      dispatch3cPlus({ type: "inCall", payload: { idCall } });
      if (data?.call?.call_mode !== "manual") {
        const makeAttendance = getExportedFunction("makeAttendance");
        let mailingData = {
          phone: data.mailing?.phone,
          ...data.mailing?.data,
        };
        makeAttendance({ mailingData, createWith3cPlus: true });
      }
    },
  },
  {
    eventType: "/user/3cplus/agent-in-acw",
    callback: (message, { dispatch3cPlus }) => {
      const clearIdCall = !isModalAttendanceOpen;
      dispatch3cPlus({ type: "notInCall", payload: { clearIdCall } });
    },
  },
  {
    eventType: "/user/3cplus/call-was-ended",
    callback: (message, { dispatch3cPlus }) => {
      dispatch3cPlus({ type: "notInCall" });
    },
  },
  {
    eventType: "/user/3cplus/call-was-finished",
    callback: (message, { dispatch3cPlus }) => {
      dispatch3cPlus({ type: "notInCall" });
    },
  },
  {
    eventType: "/user/3cplus/agent-login-failed",
    callback: (message, { dispatch3cPlus }) => {
      dispatch3cPlus({ type: "setIdCall", payload: null });
      toast({
        title: "Falha ao fazer login",
        status: "error",
        ...toastDefaultStyle,
      });
    },
  },
  {
    eventType: "/user/3cplus/call-was-abandoned",
    callback: (message, { dispatch3cPlus }) => {
      const clearIdCall = true;
      dispatch3cPlus({ type: "notInCall", payload: { clearIdCall } });
      toast({ title: "O agente abandonou a chamada", ...toastDefaultStyle });
    },
  },
  {
    eventType: "/user/3cplus/call-was-abandoned-due-amd",
    callback: (message, { dispatch3cPlus }) => {
      const clearIdCall = true;
      dispatch3cPlus({ type: "notInCall", payload: { clearIdCall } });
      toast({ title: "O agente abandonou a chamada", ...toastDefaultStyle });
    },
  },
  {
    eventType: "/user/3cplus/call-was-answered",
    callback: (message, { dispatch3cPlus }) => {
      const data = JSON.parse(message.body);
      const idCall = data?.call?.id;
      dispatch3cPlus({ type: "inCall", payload: { idCall } });
    },
  },
];
