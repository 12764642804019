import { createElement } from "react";
import { dropdownOrgaosEmissoresDocDigitacaoFGTSFacta } from "./const";

export const factaCitiesArray = [
  { name: "MONTE SANTO", value: { cityName: "MONTE SANTO", uf: "RN" } },
  { name: "PELOTAS", value: { cityName: "PELOTAS", uf: "RS" } },
  { name: "SANTO ANGELO", value: { cityName: "SANTO ANGELO", uf: "RS" } },
  { name: "CRUZ ALTA", value: { cityName: "CRUZ ALTA", uf: "RS" } },
  {
    name: "SANTA CRUZ DO SUL",
    value: { cityName: "SANTA CRUZ DO SUL", uf: "RS" },
  },
  { name: "SOBRADINHO", value: { cityName: "SOBRADINHO", uf: "RS" } },
  { name: "CANOAS", value: { cityName: "CANOAS", uf: "RS" } },
  {
    name: "PALMEIRA DAS MISSOES",
    value: { cityName: "PALMEIRA DAS MISSOES", uf: "RS" },
  },
  { name: "MONTENEGRO", value: { cityName: "MONTENEGRO", uf: "RS" } },
  { name: "BARROS CASSAL", value: { cityName: "BARROS CASSAL", uf: "RS" } },
  { name: "TAQUARA", value: { cityName: "TAQUARA", uf: "RS" } },
  { name: "ALEGRETE", value: { cityName: "ALEGRETE", uf: "RS" } },
  { name: "URUGUAIANA", value: { cityName: "URUGUAIANA", uf: "RS" } },
  { name: "SAPUCAIA DO SUL", value: { cityName: "SAPUCAIA DO SUL", uf: "RS" } },
  { name: "RIO GRANDE", value: { cityName: "RIO GRANDE", uf: "RS" } },
  { name: "PASSO FUNDO", value: { cityName: "PASSO FUNDO", uf: "RS" } },
  { name: "TAPES", value: { cityName: "TAPES", uf: "RS" } },
  { name: "RIO PARDO", value: { cityName: "RIO PARDO", uf: "RS" } },
  { name: "QUARAI", value: { cityName: "QUARAI", uf: "RS" } },
  { name: "NOVO HAMBURGO", value: { cityName: "NOVO HAMBURGO", uf: "RS" } },
  { name: "CORONEL BARROS", value: { cityName: "CORONEL BARROS", uf: "RS" } },
  { name: "SANTA MARIA", value: { cityName: "SANTA MARIA", uf: "RS" } },
  { name: "GRAMADO", value: { cityName: "GRAMADO", uf: "RS" } },
  { name: "SANTIAGO", value: { cityName: "SANTIAGO", uf: "RS" } },
  {
    name: "SANTANA DO LIVRAMENTO",
    value: { cityName: "SANTANA DO LIVRAMENTO", uf: "RS" },
  },
  {
    name: "CACHOEIRA DO SUL",
    value: { cityName: "CACHOEIRA DO SUL", uf: "RS" },
  },
  { name: "CAXIAS DO SUL", value: { cityName: "CAXIAS DO SUL", uf: "RS" } },
  { name: "VIAMAO", value: { cityName: "VIAMAO", uf: "RS" } },
  { name: "ALVORADA", value: { cityName: "ALVORADA", uf: "RS" } },
  { name: "SAO LEOPOLDO", value: { cityName: "SAO LEOPOLDO", uf: "RS" } },
  { name: "ESTEIO", value: { cityName: "ESTEIO", uf: "RS" } },
  { name: "CACHOEIRINHA", value: { cityName: "CACHOEIRINHA", uf: "RS" } },
  { name: "GRAVATAI", value: { cityName: "GRAVATAI", uf: "RS" } },
  { name: "PORTO ALEGRE", value: { cityName: "PORTO ALEGRE", uf: "RS" } },
  { name: "LAJEADO", value: { cityName: "LAJEADO", uf: "RS" } },
  { name: "TRAMANDAI", value: { cityName: "TRAMANDAI", uf: "RS" } },
  { name: "CHARQUEADAS", value: { cityName: "CHARQUEADAS", uf: "RS" } },
  { name: "ROSARIO DO SUL", value: { cityName: "ROSARIO DO SUL", uf: "RS" } },
  { name: "CACAPAVA DO SUL", value: { cityName: "CACAPAVA DO SUL", uf: "RS" } },
  { name: "CARAZINHO", value: { cityName: "CARAZINHO", uf: "RS" } },
  { name: "IJUI", value: { cityName: "IJUI", uf: "RS" } },
  { name: "GUAPORE", value: { cityName: "GUAPORE", uf: "RS" } },
  { name: "OSORIO", value: { cityName: "OSORIO", uf: "RS" } },
  { name: "SAO BORJA", value: { cityName: "SAO BORJA", uf: "RS" } },
  { name: "CANGUCU", value: { cityName: "CANGUCU", uf: "RS" } },
  {
    name: "SAO LUIZ GONZAGA",
    value: { cityName: "SAO LUIZ GONZAGA", uf: "RS" },
  },
  { name: "ELDORADO DO SUL", value: { cityName: "ELDORADO DO SUL", uf: "RS" } },
  { name: "GETULIO VARGAS", value: { cityName: "GETULIO VARGAS", uf: "RS" } },
  { name: "PLANALTO", value: { cityName: "PLANALTO", uf: "RS" } },
  { name: "TRES PASSOS", value: { cityName: "TRES PASSOS", uf: "RS" } },
  { name: "CIDREIRA", value: { cityName: "CIDREIRA", uf: "RS" } },
  { name: "ITATIBA DO SUL", value: { cityName: "ITATIBA DO SUL", uf: "RS" } },
  { name: "ERECHIM", value: { cityName: "ERECHIM", uf: "RS" } },
  { name: "CANELA", value: { cityName: "CANELA", uf: "RS" } },
  {
    name: "SANTO ANTONIO DA PATRULHA",
    value: { cityName: "SANTO ANTONIO DA PATRULHA", uf: "RS" },
  },
  { name: "CAPAO DA CANOA", value: { cityName: "CAPAO DA CANOA", uf: "RS" } },
  { name: "ESTRELA", value: { cityName: "ESTRELA", uf: "RS" } },
  { name: "SAO JERONIMO", value: { cityName: "SAO JERONIMO", uf: "RS" } },
  { name: "CAPAO DO LEAO", value: { cityName: "CAPAO DO LEAO", uf: "RS" } },
  { name: "TUPANCIRETA", value: { cityName: "TUPANCIRETA", uf: "RS" } },
  { name: "CIANORTE", value: { cityName: "CIANORTE", uf: "PR" } },
  { name: "GIRUA", value: { cityName: "GIRUA", uf: "RS" } },
  { name: "SARANDI", value: { cityName: "SARANDI", uf: "RS" } },
  { name: "BOM JESUS", value: { cityName: "BOM JESUS", uf: "RS" } },
  { name: "SAPIRANGA", value: { cityName: "SAPIRANGA", uf: "RS" } },
  { name: "SAO SEPE", value: { cityName: "SAO SEPE", uf: "RS" } },
  {
    name: "VILA NOVA DO SUL",
    value: { cityName: "VILA NOVA DO SUL", uf: "RS" },
  },
  {
    name: "GUARANI DAS MISSOES",
    value: { cityName: "GUARANI DAS MISSOES", uf: "RS" },
  },
  { name: "LAVRAS DO SUL", value: { cityName: "LAVRAS DO SUL", uf: "RS" } },
  { name: "TORRES", value: { cityName: "TORRES", uf: "RS" } },
  { name: "TRIUNFO", value: { cityName: "TRIUNFO", uf: "RS" } },
  { name: "CAMPO BOM", value: { cityName: "CAMPO BOM", uf: "RS" } },
  { name: "GUAIBA", value: { cityName: "GUAIBA", uf: "RS" } },
  { name: "PIRATINI", value: { cityName: "PIRATINI", uf: "RS" } },
  { name: "BUTIA", value: { cityName: "BUTIA", uf: "RS" } },
  { name: "MAQUINE", value: { cityName: "MAQUINE", uf: "RS" } },
  {
    name: "SAO SEBASTIAO DO CAI",
    value: { cityName: "SAO SEBASTIAO DO CAI", uf: "RS" },
  },
  {
    name: "SAO JOSE DO NORTE",
    value: { cityName: "SAO JOSE DO NORTE", uf: "RS" },
  },
  { name: "BENTO GONCALVES", value: { cityName: "BENTO GONCALVES", uf: "RS" } },
  { name: "CACEQUI", value: { cityName: "CACEQUI", uf: "RS" } },
  { name: "VERANOPOLIS", value: { cityName: "VERANOPOLIS", uf: "RS" } },
  { name: "HORIZONTINA", value: { cityName: "HORIZONTINA", uf: "RS" } },
  { name: "VACARIA", value: { cityName: "VACARIA", uf: "RS" } },
  { name: "VERA CRUZ", value: { cityName: "VERA CRUZ", uf: "RS" } },
  { name: "GENERAL CAMARA", value: { cityName: "GENERAL CAMARA", uf: "RS" } },
  { name: "JAGUARI", value: { cityName: "JAGUARI", uf: "RS" } },
  {
    name: "SANTA BARBARA DO SUL",
    value: { cityName: "SANTA BARBARA DO SUL", uf: "RS" },
  },
  { name: "MANOEL VIANA", value: { cityName: "MANOEL VIANA", uf: "RS" } },
  { name: "GAURAMA", value: { cityName: "GAURAMA", uf: "RS" } },
  { name: "FORMIGUEIRO", value: { cityName: "FORMIGUEIRO", uf: "RS" } },
  {
    name: "SAO LOURENCO DO SUL",
    value: { cityName: "SAO LOURENCO DO SUL", uf: "RS" },
  },
  {
    name: "PINHEIRO MACHADO",
    value: { cityName: "PINHEIRO MACHADO", uf: "RS" },
  },
  { name: "ENCANTADO", value: { cityName: "ENCANTADO", uf: "RS" } },
  { name: "IRAI", value: { cityName: "IRAI", uf: "RS" } },
  { name: "CONDOR", value: { cityName: "CONDOR", uf: "RS" } },
  { name: "ARROIO GRANDE", value: { cityName: "ARROIO GRANDE", uf: "RS" } },
  { name: "TAQUARI", value: { cityName: "TAQUARI", uf: "RS" } },
  { name: "SAO GABRIEL", value: { cityName: "SAO GABRIEL", uf: "RS" } },
  { name: "IMBE", value: { cityName: "IMBE", uf: "RS" } },
  {
    name: "SAO MIGUEL DAS MISSOES",
    value: { cityName: "SAO MIGUEL DAS MISSOES", uf: "RS" },
  },
  { name: "REDENTORA", value: { cityName: "REDENTORA", uf: "RS" } },
  { name: "TRES COROAS", value: { cityName: "TRES COROAS", uf: "RS" } },
  { name: "ESTANCIA VELHA", value: { cityName: "ESTANCIA VELHA", uf: "RS" } },
  { name: "HERVAL", value: { cityName: "HERVAL", uf: "RS" } },
  { name: "FELIZ", value: { cityName: "FELIZ", uf: "RS" } },
  {
    name: "SAO FRANCISCO DE ASSIS",
    value: { cityName: "SAO FRANCISCO DE ASSIS", uf: "RS" },
  },
  { name: "MACHADINHO", value: { cityName: "MACHADINHO", uf: "RS" } },
  { name: "ITAQUI", value: { cityName: "ITAQUI", uf: "RS" } },
  { name: "JAGUARAO", value: { cityName: "JAGUARAO", uf: "RS" } },
  { name: "IGREJINHA", value: { cityName: "IGREJINHA", uf: "RS" } },
  {
    name: "FORTALEZA DOS VALOS",
    value: { cityName: "FORTALEZA DOS VALOS", uf: "RS" },
  },
  { name: "RONDA ALTA", value: { cityName: "RONDA ALTA", uf: "RS" } },
  { name: "CANDIOTA", value: { cityName: "CANDIOTA", uf: "RS" } },
  { name: "CAMAQUA", value: { cityName: "CAMAQUA", uf: "RS" } },
  { name: "CANDIDO GODOI", value: { cityName: "CANDIDO GODOI", uf: "RS" } },
  { name: "CERRO LARGO", value: { cityName: "CERRO LARGO", uf: "RS" } },
  { name: "CANDELARIA", value: { cityName: "CANDELARIA", uf: "RS" } },
  {
    name: "CAPELA DE SANTANA",
    value: { cityName: "CAPELA DE SANTANA", uf: "RS" },
  },
  { name: "ARROIO DO TIGRE", value: { cityName: "ARROIO DO TIGRE", uf: "RS" } },
  { name: "TRES DE MAIO", value: { cityName: "TRES DE MAIO", uf: "RS" } },
  { name: "MARAU", value: { cityName: "MARAU", uf: "RS" } },
  { name: "MOSTARDAS", value: { cityName: "MOSTARDAS", uf: "RS" } },
  { name: "LAGOA VERMELHA", value: { cityName: "LAGOA VERMELHA", uf: "RS" } },
  { name: "SOLEDADE", value: { cityName: "SOLEDADE", uf: "RS" } },
  { name: "PALMARES DO SUL", value: { cityName: "PALMARES DO SUL", uf: "RS" } },
  { name: "HULHA NEGRA", value: { cityName: "HULHA NEGRA", uf: "RS" } },
  {
    name: "BARRA DO RIBEIRO",
    value: { cityName: "BARRA DO RIBEIRO", uf: "RS" },
  },
  { name: "ITAARA", value: { cityName: "ITAARA", uf: "RS" } },
  {
    name: "JULIO DE CASTILHOS",
    value: { cityName: "JULIO DE CASTILHOS", uf: "RS" },
  },
  { name: "SALTO DO JACUI", value: { cityName: "SALTO DO JACUI", uf: "RS" } },
  { name: "DOM PEDRITO", value: { cityName: "DOM PEDRITO", uf: "RS" } },
  { name: "FARROUPILHA", value: { cityName: "FARROUPILHA", uf: "RS" } },
  { name: "ERNESTINA", value: { cityName: "ERNESTINA", uf: "RS" } },
  {
    name: "SAO JOSE DO OURO",
    value: { cityName: "SAO JOSE DO OURO", uf: "RS" },
  },
  {
    name: "SAO VICENTE DO SUL",
    value: { cityName: "SAO VICENTE DO SUL", uf: "RS" },
  },
  { name: "RODEIO BONITO", value: { cityName: "RODEIO BONITO", uf: "RS" } },
  { name: "ARROIO DO MEIO", value: { cityName: "ARROIO DO MEIO", uf: "RS" } },
  { name: "FLORES DA CUNHA", value: { cityName: "FLORES DA CUNHA", uf: "RS" } },
  {
    name: "ENCRUZILHADA DO SUL",
    value: { cityName: "ENCRUZILHADA DO SUL", uf: "RS" },
  },
  { name: "ESPUMOSO", value: { cityName: "ESPUMOSO", uf: "RS" } },
  { name: "ROLANTE", value: { cityName: "ROLANTE", uf: "RS" } },
  { name: "CASCA", value: { cityName: "CASCA", uf: "RS" } },
  { name: "IBIRAPUITA", value: { cityName: "IBIRAPUITA", uf: "RS" } },
  {
    name: "FREDERICO WESTPHALEN",
    value: { cityName: "FREDERICO WESTPHALEN", uf: "RS" },
  },
  { name: "TEUTONIA", value: { cityName: "TEUTONIA", uf: "RS" } },
  { name: "TENENTE PORTELA", value: { cityName: "TENENTE PORTELA", uf: "RS" } },
  { name: "FLORIANOPOLIS", value: { cityName: "FLORIANOPOLIS", uf: "SC" } },
  { name: "IBARAMA", value: { cityName: "IBARAMA", uf: "RS" } },
  { name: "AUGUSTO PESTANA", value: { cityName: "AUGUSTO PESTANA", uf: "RS" } },
  {
    name: "SANTANA DA BOA VISTA",
    value: { cityName: "SANTANA DA BOA VISTA", uf: "RS" },
  },
  { name: "ALECRIM", value: { cityName: "ALECRIM", uf: "RS" } },
  { name: "COXILHA", value: { cityName: "COXILHA", uf: "RS" } },
  { name: "NOVA PETROPOLIS", value: { cityName: "NOVA PETROPOLIS", uf: "RS" } },
  { name: "ITAPEMA", value: { cityName: "ITAPEMA", uf: "SC" } },
  { name: "NOVA SANTA RITA", value: { cityName: "NOVA SANTA RITA", uf: "RS" } },
  { name: "ENTRE IJUIS", value: { cityName: "ENTRE IJUIS", uf: "RS" } },
  { name: "TUPARENDI", value: { cityName: "TUPARENDI", uf: "RS" } },
  { name: "HARMONIA", value: { cityName: "HARMONIA", uf: "RS" } },
  { name: "IBIRUBA", value: { cityName: "IBIRUBA", uf: "RS" } },
  {
    name: "SANTA VITORIA DO PALMAR",
    value: { cityName: "SANTA VITORIA DO PALMAR", uf: "RS" },
  },
  {
    name: "SANTO ANTONIO DAS MISSOES",
    value: { cityName: "SANTO ANTONIO DAS MISSOES", uf: "RS" },
  },
  { name: "RESTINGA SECA", value: { cityName: "RESTINGA SECA", uf: "RS" } },
  { name: "PANTANO GRANDE", value: { cityName: "PANTANO GRANDE", uf: "RS" } },
  { name: "JOIA", value: { cityName: "JOIA", uf: "RS" } },
  { name: "NAO-ME-TOQUE", value: { cityName: "NAO-ME-TOQUE", uf: "RS" } },
  { name: "CRICIUMA", value: { cityName: "CRICIUMA", uf: "SC" } },
  { name: "CHUI", value: { cityName: "CHUI", uf: "RS" } },
  { name: "PAROBE", value: { cityName: "PAROBE", uf: "RS" } },
  { name: "CHIAPETTA", value: { cityName: "CHIAPETTA", uf: "RS" } },
  { name: "SAO MARTINHO", value: { cityName: "SAO MARTINHO", uf: "RS" } },
  { name: "BOM PRINCIPIO", value: { cityName: "BOM PRINCIPIO", uf: "RS" } },
  { name: "LAGOAO", value: { cityName: "LAGOAO", uf: "RS" } },
  { name: "ALTO ALEGRE", value: { cityName: "ALTO ALEGRE", uf: "RS" } },
  { name: "PINHAL", value: { cityName: "PINHAL", uf: "RS" } },
  { name: "DOIS IRMAOS", value: { cityName: "DOIS IRMAOS", uf: "RS" } },
  { name: "NOVO BARREIRO", value: { cityName: "NOVO BARREIRO", uf: "RS" } },
  {
    name: "SAO DOMINGOS DO SUL",
    value: { cityName: "SAO DOMINGOS DO SUL", uf: "RS" },
  },
  { name: "AMARAL FERRADOR", value: { cityName: "AMARAL FERRADOR", uf: "RS" } },
  { name: "MARCELINO RAMOS", value: { cityName: "MARCELINO RAMOS", uf: "RS" } },
  { name: "ABADIA DE GOIAS", value: { cityName: "ABADIA DE GOIAS", uf: "GO" } },
  { name: "CERRITO", value: { cityName: "CERRITO", uf: "RS" } },
  { name: "IBIACA", value: { cityName: "IBIACA", uf: "RS" } },
  { name: "SANTO AUGUSTO", value: { cityName: "SANTO AUGUSTO", uf: "RS" } },
  { name: "FAGUNDES VARELA", value: { cityName: "FAGUNDES VARELA", uf: "RS" } },
  { name: "ABATIA", value: { cityName: "ABATIA", uf: "PR" } },
  { name: "TUNAS", value: { cityName: "TUNAS", uf: "RS" } },
  { name: "ALTO FELIZ", value: { cityName: "ALTO FELIZ", uf: "RS" } },
  { name: "ABEL FIGUEIREDO", value: { cityName: "ABEL FIGUEIREDO", uf: "PA" } },
  {
    name: "NICOLAU VERGUEIRO",
    value: { cityName: "NICOLAU VERGUEIRO", uf: "RS" },
  },
  { name: "AJURICABA", value: { cityName: "AJURICABA", uf: "RS" } },
  { name: "IPIRANGA DO SUL", value: { cityName: "IPIRANGA DO SUL", uf: "RS" } },
  { name: "JABOTICABA", value: { cityName: "JABOTICABA", uf: "RS" } },
  { name: "TUCUNDUVA", value: { cityName: "TUCUNDUVA", uf: "RS" } },
  { name: "SEGREDO", value: { cityName: "SEGREDO", uf: "RS" } },
  { name: "NOVA PRATA", value: { cityName: "NOVA PRATA", uf: "RS" } },
  { name: "ROQUE GONZALES", value: { cityName: "ROQUE GONZALES", uf: "RS" } },
  { name: "ARROIO DO SAL", value: { cityName: "ARROIO DO SAL", uf: "RS" } },
  { name: "PARAI", value: { cityName: "PARAI", uf: "RS" } },
  { name: "PUTINGA", value: { cityName: "PUTINGA", uf: "RS" } },
  { name: "BOSSOROCA", value: { cityName: "BOSSOROCA", uf: "RS" } },
  { name: "PICADA CAFE", value: { cityName: "PICADA CAFE", uf: "RS" } },
  { name: "SINIMBU", value: { cityName: "SINIMBU", uf: "RS" } },
  { name: "BRAGA", value: { cityName: "BRAGA", uf: "RS" } },
  { name: "ABRE-CAMPO", value: { cityName: "ABRE-CAMPO", uf: "MG" } },
  { name: "SELBACH", value: { cityName: "SELBACH", uf: "RS" } },
  { name: "SALVADOR DO SUL", value: { cityName: "SALVADOR DO SUL", uf: "RS" } },
  { name: "MORRO REDONDO", value: { cityName: "MORRO REDONDO", uf: "RS" } },
  { name: "ABREULANDIA", value: { cityName: "ABREULANDIA", uf: "TO" } },
  { name: "PANAMBI", value: { cityName: "PANAMBI", uf: "RS" } },
  { name: "GLORINHA", value: { cityName: "GLORINHA", uf: "RS" } },
  { name: "GENTIL", value: { cityName: "GENTIL", uf: "RS" } },
  {
    name: "BOA VISTA DO BURICA",
    value: { cityName: "BOA VISTA DO BURICA", uf: "RS" },
  },
  {
    name: "ARROIO DOS RATOS",
    value: { cityName: "ARROIO DOS RATOS", uf: "RS" },
  },
  { name: "DOM FELICIANO", value: { cityName: "DOM FELICIANO", uf: "RS" } },
  { name: "VANINI", value: { cityName: "VANINI", uf: "RS" } },
  { name: "ACAILANDIA", value: { cityName: "ACAILANDIA", uf: "MA" } },
  { name: "INDEPENDENCIA", value: { cityName: "INDEPENDENCIA", uf: "RS" } },
  { name: "TAPERA", value: { cityName: "TAPERA", uf: "RS" } },
  { name: "IVOTI", value: { cityName: "IVOTI", uf: "RS" } },
  { name: "BROCHIER", value: { cityName: "BROCHIER", uf: "RS" } },
  { name: "AMETISTA DO SUL", value: { cityName: "AMETISTA DO SUL", uf: "RS" } },
  { name: "PAIM FILHO", value: { cityName: "PAIM FILHO", uf: "RS" } },
  { name: "ITACURUBI", value: { cityName: "ITACURUBI", uf: "RS" } },
  { name: "NOVO MACHADO", value: { cityName: "NOVO MACHADO", uf: "RS" } },
  { name: "CURITIBA", value: { cityName: "CURITIBA", uf: "PR" } },
  { name: "VICENTE DUTRA", value: { cityName: "VICENTE DUTRA", uf: "RS" } },
  {
    name: "LAGOA BONITA DO SUL",
    value: { cityName: "LAGOA BONITA DO SUL", uf: "RS" },
  },
  { name: "OURO VERDE", value: { cityName: "OURO VERDE", uf: "SC" } },
  { name: "TERRA DE AREIA", value: { cityName: "TERRA DE AREIA", uf: "RS" } },
  { name: "NOVA HARTZ", value: { cityName: "NOVA HARTZ", uf: "RS" } },
  { name: "ACAUA", value: { cityName: "ACAUA", uf: "PI" } },
  { name: "SAO VENDELINO", value: { cityName: "SAO VENDELINO", uf: "RS" } },
  { name: "BARRACAO", value: { cityName: "BARRACAO", uf: "RS" } },
  { name: "PORTO XAVIER", value: { cityName: "PORTO XAVIER", uf: "RS" } },
  {
    name: "MONTE BELO DO SUL",
    value: { cityName: "MONTE BELO DO SUL", uf: "RS" },
  },
  { name: "SAO NICOLAU", value: { cityName: "SAO NICOLAU", uf: "RS" } },
  { name: "VICTOR GRAEFF", value: { cityName: "VICTOR GRAEFF", uf: "RS" } },
  { name: "GARRUCHOS", value: { cityName: "GARRUCHOS", uf: "RS" } },
  {
    name: "EUGENIO DE CASTRO",
    value: { cityName: "EUGENIO DE CASTRO", uf: "RS" },
  },
  { name: "CAMPO NOVO", value: { cityName: "CAMPO NOVO", uf: "RS" } },
  { name: "ANTONIO PRADO", value: { cityName: "ANTONIO PRADO", uf: "RS" } },
  {
    name: "CAMPINA DAS MISSOES",
    value: { cityName: "CAMPINA DAS MISSOES", uf: "RS" },
  },
  { name: "PARAISO DO SUL", value: { cityName: "PARAISO DO SUL", uf: "RS" } },
  {
    name: "BOA VISTA DAS MISSOES",
    value: { cityName: "BOA VISTA DAS MISSOES", uf: "RS" },
  },
  { name: "ANTA GORDA", value: { cityName: "ANTA GORDA", uf: "RS" } },
  { name: "PINHAL GRANDE", value: { cityName: "PINHAL GRANDE", uf: "RS" } },
  { name: "ACORIZAL", value: { cityName: "ACORIZAL", uf: "MT" } },
  { name: "MUITOS CAPOES", value: { cityName: "MUITOS CAPOES", uf: "RS" } },
  { name: "PALMITINHO", value: { cityName: "PALMITINHO", uf: "RS" } },
  { name: "DAVID CANABARRO", value: { cityName: "DAVID CANABARRO", uf: "RS" } },
  { name: "MATO LEITAO", value: { cityName: "MATO LEITAO", uf: "RS" } },
  {
    name: "BOM RETIRO DO SUL",
    value: { cityName: "BOM RETIRO DO SUL", uf: "RS" },
  },
  { name: "ACRELANDIA", value: { cityName: "ACRELANDIA", uf: "AC" } },
  { name: "ACU", value: { cityName: "ACU", uf: "RN" } },
  { name: "AFONSO BEZERRA", value: { cityName: "AFONSO BEZERRA", uf: "RN" } },
  { name: "PORTO LUCENA", value: { cityName: "PORTO LUCENA", uf: "RS" } },
  { name: "AFONSO CUNHA", value: { cityName: "AFONSO CUNHA", uf: "MA" } },
  { name: "ERVAL GRANDE", value: { cityName: "ERVAL GRANDE", uf: "RS" } },
  { name: "TRES CACHOEIRAS", value: { cityName: "TRES CACHOEIRAS", uf: "RS" } },
  { name: "AGRESTINA", value: { cityName: "AGRESTINA", uf: "PE" } },
  { name: "CAMARGO", value: { cityName: "CAMARGO", uf: "RS" } },
  { name: "AGRICOLANDIA", value: { cityName: "AGRICOLANDIA", uf: "PI" } },
  { name: "JAQUIRANA", value: { cityName: "JAQUIRANA", uf: "RS" } },
  {
    name: "BALNEARIO PINHAL",
    value: { cityName: "BALNEARIO PINHAL", uf: "RS" },
  },
  { name: "AGUA CLARA", value: { cityName: "AGUA CLARA", uf: "MS" } },
  { name: "AGUA COMPRIDA", value: { cityName: "AGUA COMPRIDA", uf: "MG" } },
  { name: "BARRA DO QUARAI", value: { cityName: "BARRA DO QUARAI", uf: "RS" } },
  {
    name: "AGUA DOCE DO MARANHAO",
    value: { cityName: "AGUA DOCE DO MARANHAO", uf: "MA" },
  },
  { name: "AGUANIL", value: { cityName: "AGUANIL", uf: "MG" } },
  {
    name: "TIRADENTES DO SUL",
    value: { cityName: "TIRADENTES DO SUL", uf: "RS" },
  },
  {
    name: "AGUAS DE LINDOIA",
    value: { cityName: "AGUAS DE LINDOIA", uf: "SP" },
  },
  {
    name: "AGUAS DE SANTA BARBARA",
    value: { cityName: "AGUAS DE SANTA BARBARA", uf: "SP" },
  },
  { name: "ACEGUA", value: { cityName: "ACEGUA", uf: "RS" } },
  { name: "BOM PROGRESSO", value: { cityName: "BOM PROGRESSO", uf: "RS" } },
  { name: "LAGES", value: { cityName: "LAGES", uf: "SC" } },
  { name: "LINHA NOVA", value: { cityName: "LINHA NOVA", uf: "RS" } },
  { name: "CAIBATE", value: { cityName: "CAIBATE", uf: "RS" } },
  { name: "DONA FRANCISCA", value: { cityName: "DONA FRANCISCA", uf: "RS" } },
  { name: "CRUZEIRO DO SUL", value: { cityName: "CRUZEIRO DO SUL", uf: "RS" } },
  { name: "SAO MARCOS", value: { cityName: "SAO MARCOS", uf: "RS" } },
  { name: "ALEGRIA", value: { cityName: "ALEGRIA", uf: "RS" } },
  { name: "SAO CARLOS", value: { cityName: "SAO CARLOS", uf: "RS" } },
  { name: "VIADUTOS", value: { cityName: "VIADUTOS", uf: "RS" } },
  { name: "CAPITAO", value: { cityName: "CAPITAO", uf: "RS" } },
  { name: "MIRAGUAI", value: { cityName: "MIRAGUAI", uf: "RS" } },
  {
    name: "NOVA ROMA DO SUL",
    value: { cityName: "NOVA ROMA DO SUL", uf: "RS" },
  },
  { name: "PONTAO", value: { cityName: "PONTAO", uf: "RS" } },
  {
    name: "CERRO GRANDE DO SUL",
    value: { cityName: "CERRO GRANDE DO SUL", uf: "RS" },
  },
  { name: "CAMBARA DO SUL", value: { cityName: "CAMBARA DO SUL", uf: "RS" } },
  {
    name: "BARRA DO RIO AZUL",
    value: { cityName: "BARRA DO RIO AZUL", uf: "RS" },
  },
  {
    name: "BOA VISTA DO SUL",
    value: { cityName: "BOA VISTA DO SUL", uf: "RS" },
  },
  { name: "NOVA PALMA", value: { cityName: "NOVA PALMA", uf: "RS" } },
  { name: "NOVO CABRAIS", value: { cityName: "NOVO CABRAIS", uf: "RS" } },
  { name: "ESTACAO", value: { cityName: "ESTACAO", uf: "RS" } },
  {
    name: "SENTINELA DO SUL",
    value: { cityName: "SENTINELA DO SUL", uf: "RS" },
  },
  { name: "TAPEJARA", value: { cityName: "TAPEJARA", uf: "RS" } },
  { name: "SEBERI", value: { cityName: "SEBERI", uf: "RS" } },
  { name: "TRES PALMEIRAS", value: { cityName: "TRES PALMEIRAS", uf: "RS" } },
  { name: "CORTADO", value: { cityName: "CORTADO", uf: "RS" } },
  { name: "CARLOS BARBOSA", value: { cityName: "CARLOS BARBOSA", uf: "RS" } },
  { name: "IVORA", value: { cityName: "IVORA", uf: "RS" } },
  {
    name: "AGUAS DE SAO PEDRO",
    value: { cityName: "AGUAS DE SAO PEDRO", uf: "SP" },
  },
  { name: "MATA", value: { cityName: "MATA", uf: "RS" } },
  { name: "ARVOREZINHA", value: { cityName: "ARVOREZINHA", uf: "RS" } },
  {
    name: "CAPAO BONITO DO SUL",
    value: { cityName: "CAPAO BONITO DO SUL", uf: "RS" },
  },
  { name: "EREBANGO", value: { cityName: "EREBANGO", uf: "RS" } },
  { name: "BARRA FUNDA", value: { cityName: "BARRA FUNDA", uf: "RS" } },
  {
    name: "SAO JOSE DOS AUSENTES",
    value: { cityName: "SAO JOSE DOS AUSENTES", uf: "RS" },
  },
  { name: "BRASILIA", value: { cityName: "BRASILIA", uf: "DF" } },
  {
    name: "SANTO ANTONIO DO PLANALTO",
    value: { cityName: "SANTO ANTONIO DO PLANALTO", uf: "RS" },
  },
  { name: "IBIRAIARAS", value: { cityName: "IBIRAIARAS", uf: "RS" } },
  { name: "ARARICA", value: { cityName: "ARARICA", uf: "RS" } },
  { name: "MUCUM", value: { cityName: "MUCUM", uf: "RS" } },
  { name: "PORTO MAUA", value: { cityName: "PORTO MAUA", uf: "RS" } },
  { name: "BAGE", value: { cityName: "BAGE", uf: "RS" } },
  { name: "ESMERALDA", value: { cityName: "ESMERALDA", uf: "RS" } },
  {
    name: "AGUAS LINDAS DE GOIAS",
    value: { cityName: "AGUAS LINDAS DE GOIAS", uf: "GO" },
  },
  { name: "AGUDO", value: { cityName: "AGUDO", uf: "RS" } },
  { name: "JUIZ DE FORA", value: { cityName: "JUIZ DE FORA", uf: "MG" } },
  { name: "AGUDOS DO SUL", value: { cityName: "AGUDOS DO SUL", uf: "PR" } },
  { name: "AGUIARNOPOLIS", value: { cityName: "AGUIARNOPOLIS", uf: "TO" } },
  {
    name: "ALAGOINHA DO PIAUI",
    value: { cityName: "ALAGOINHA DO PIAUI", uf: "PI" },
  },
  { name: "HUMAITA", value: { cityName: "HUMAITA", uf: "RS" } },
  { name: "ARATIBA", value: { cityName: "ARATIBA", uf: "RS" } },
  { name: "ALAMBARI", value: { cityName: "ALAMBARI", uf: "SP" } },
  { name: "ALBERTINA", value: { cityName: "ALBERTINA", uf: "MG" } },
  { name: "ALCANTIL", value: { cityName: "ALCANTIL", uf: "PB" } },
  { name: "ALCINOPOLIS", value: { cityName: "ALCINOPOLIS", uf: "MS" } },
  { name: "ALDEIAS ALTAS", value: { cityName: "ALDEIAS ALTAS", uf: "MA" } },
  { name: "JACAREZINHO", value: { cityName: "JACAREZINHO", uf: "PR" } },
  { name: "SOMBRIO", value: { cityName: "SOMBRIO", uf: "SC" } },
  {
    name: "ALEGRETE DO PIAUI",
    value: { cityName: "ALEGRETE DO PIAUI", uf: "PI" },
  },
  {
    name: "ALGODAO DE JANDAIRA",
    value: { cityName: "ALGODAO DE JANDAIRA", uf: "PB" },
  },
  { name: "ALHANDRA", value: { cityName: "ALHANDRA", uf: "PB" } },
  { name: "RONDINHA", value: { cityName: "RONDINHA", uf: "RS" } },
  {
    name: "ALIANCA DO TOCANTINS",
    value: { cityName: "ALIANCA DO TOCANTINS", uf: "TO" },
  },
  { name: "ALMAS", value: { cityName: "ALMAS", uf: "TO" } },
  { name: "ALMEIRIM", value: { cityName: "ALMEIRIM", uf: "PA" } },
  { name: "ALMINO AFONSO", value: { cityName: "ALMINO AFONSO", uf: "RN" } },
  {
    name: "ALMIRANTE TAMANDARE DO SUL",
    value: { cityName: "ALMIRANTE TAMANDARE DO SUL", uf: "RS" },
  },
  { name: "ALTA FLORESTA", value: { cityName: "ALTA FLORESTA", uf: "MT" } },
  {
    name: "ALTA FLORESTA DOESTE",
    value: { cityName: "ALTA FLORESTA DOESTE", uf: "RO" },
  },
  {
    name: "ALTAMIRA DO MARANHAO",
    value: { cityName: "ALTAMIRA DO MARANHAO", uf: "MA" },
  },
  {
    name: "ALTAMIRA DO PARANA",
    value: { cityName: "ALTAMIRA DO PARANA", uf: "PR" },
  },
  {
    name: "ALTO ALEGRE DO MARANHAO",
    value: { cityName: "ALTO ALEGRE DO MARANHAO", uf: "MA" },
  },
  {
    name: "ALTO ALEGRE DO PINDARE",
    value: { cityName: "ALTO ALEGRE DO PINDARE", uf: "MA" },
  },
  {
    name: "LIBERATO SALZANO",
    value: { cityName: "LIBERATO SALZANO", uf: "RS" },
  },
  {
    name: "ALTO ALEGRE DOS PARECIS",
    value: { cityName: "ALTO ALEGRE DOS PARECIS", uf: "RO" },
  },
  { name: "ALTO ARAGUAIA", value: { cityName: "ALTO ARAGUAIA", uf: "MT" } },
  { name: "ALTO BELA VISTA", value: { cityName: "ALTO BELA VISTA", uf: "SC" } },
  { name: "RIO DE JANEIRO", value: { cityName: "RIO DE JANEIRO", uf: "RJ" } },
  {
    name: "ALTO DA BOA VISTA",
    value: { cityName: "ALTO DA BOA VISTA", uf: "MT" },
  },
  { name: "ALTO GARCAS", value: { cityName: "ALTO GARCAS", uf: "MT" } },
  { name: "CAMPOS BORGES", value: { cityName: "CAMPOS BORGES", uf: "RS" } },
  { name: "ALTO HORIZONTE", value: { cityName: "ALTO HORIZONTE", uf: "GO" } },
  { name: "ALTO LONGA", value: { cityName: "ALTO LONGA", uf: "PI" } },
  { name: "ALTO PARAGUAI", value: { cityName: "ALTO PARAGUAI", uf: "MT" } },
  { name: "CHAPECO", value: { cityName: "CHAPECO", uf: "SC" } },
  { name: "ALTO PARAISO", value: { cityName: "ALTO PARAISO", uf: "PR" } },
  { name: "ALTO TAQUARI", value: { cityName: "ALTO TAQUARI", uf: "MT" } },
  { name: "SAO VALENTIM", value: { cityName: "SAO VALENTIM", uf: "RS" } },
  {
    name: "FEIRA DE SANTANA",
    value: { cityName: "FEIRA DE SANTANA", uf: "BA" },
  },
  { name: "ALVARAES", value: { cityName: "ALVARAES", uf: "AM" } },
  { name: "ALVINLANDIA", value: { cityName: "ALVINLANDIA", uf: "SP" } },
  {
    name: "ALVORADA DO GURGUEIA",
    value: { cityName: "ALVORADA DO GURGUEIA", uf: "PI" },
  },
  { name: "CARLOS GOMES", value: { cityName: "CARLOS GOMES", uf: "RS" } },
  { name: "ALVORADA DO SUL", value: { cityName: "ALVORADA DO SUL", uf: "PR" } },
  { name: "CATUIPE", value: { cityName: "CATUIPE", uf: "RS" } },
  { name: "CASEIROS", value: { cityName: "CASEIROS", uf: "RS" } },
  { name: "SERTAO", value: { cityName: "SERTAO", uf: "RS" } },
  { name: "ALVORADA DOESTE", value: { cityName: "ALVORADA DOESTE", uf: "RO" } },
  { name: "AMAJARI", value: { cityName: "AMAJARI", uf: "RR" } },
  {
    name: "AMAPA DO MARANHAO",
    value: { cityName: "AMAPA DO MARANHAO", uf: "MA" },
  },
  { name: "CENTENARIO", value: { cityName: "CENTENARIO", uf: "RS" } },
  { name: "AMARAJI", value: { cityName: "AMARAJI", uf: "PE" } },
  { name: "AMARALINA", value: { cityName: "AMARALINA", uf: "GO" } },
  { name: "URUSSANGA", value: { cityName: "URUSSANGA", uf: "SC" } },
  { name: "SAO PAULO", value: { cityName: "SAO PAULO", uf: "SP" } },
  {
    name: "AMARANTE DO MARANHAO",
    value: { cityName: "AMARANTE DO MARANHAO", uf: "MA" },
  },
  { name: "AMATURA", value: { cityName: "AMATURA", uf: "AM" } },
  { name: "OURINHOS", value: { cityName: "OURINHOS", uf: "SP" } },
  { name: "CERRO BRANCO", value: { cityName: "CERRO BRANCO", uf: "RS" } },
  {
    name: "AMERICO BRASILIENSE",
    value: { cityName: "AMERICO BRASILIENSE", uf: "SP" },
  },
  {
    name: "AMPARO DE SAO FRANCISCO",
    value: { cityName: "AMPARO DE SAO FRANCISCO", uf: "SE" },
  },
  { name: "ANAHY", value: { cityName: "ANAHY", uf: "PR" } },
  { name: "CHAPADA", value: { cityName: "CHAPADA", uf: "RS" } },
  { name: "ANAJAS", value: { cityName: "ANAJAS", uf: "PA" } },
  { name: "ANAJATUBA", value: { cityName: "ANAJATUBA", uf: "MA" } },
  { name: "ANANAS", value: { cityName: "ANANAS", uf: "TO" } },
  { name: "ANAPU", value: { cityName: "ANAPU", uf: "PA" } },
  { name: "ANAPURUS", value: { cityName: "ANAPURUS", uf: "MA" } },
  { name: "ROCA SALES", value: { cityName: "ROCA SALES", uf: "RS" } },
  { name: "ANASTACIO", value: { cityName: "ANASTACIO", uf: "MS" } },
  { name: "NOVA BASSANO", value: { cityName: "NOVA BASSANO", uf: "RS" } },
  { name: "COLINAS", value: { cityName: "COLINAS", uf: "RS" } },
  { name: "ANAURILANDIA", value: { cityName: "ANAURILANDIA", uf: "MS" } },
  { name: "ANDIRA", value: { cityName: "ANDIRA", uf: "PR" } },
  { name: "ANDORINHA", value: { cityName: "ANDORINHA", uf: "BA" } },
  { name: "ANGELANDIA", value: { cityName: "ANGELANDIA", uf: "MG" } },
  { name: "FOZ DO IGUACU", value: { cityName: "FOZ DO IGUACU", uf: "PR" } },
  { name: "COLORADO", value: { cityName: "COLORADO", uf: "RS" } },
  { name: "ANGELICA", value: { cityName: "ANGELICA", uf: "MS" } },
  {
    name: "COQUEIROS DO SUL",
    value: { cityName: "COQUEIROS DO SUL", uf: "RS" },
  },
  { name: "ANGICO", value: { cityName: "ANGICO", uf: "TO" } },
  { name: "ANHANGUERA", value: { cityName: "ANHANGUERA", uf: "GO" } },
  { name: "LAPA", value: { cityName: "LAPA", uf: "PR" } },
  { name: "UNIFLOR", value: { cityName: "UNIFLOR", uf: "PR" } },
  { name: "ANTONIO JOAO", value: { cityName: "ANTONIO JOAO", uf: "MS" } },
  { name: "ANTONIO MARTINS", value: { cityName: "ANTONIO MARTINS", uf: "RN" } },
  { name: "ANTONIO OLINTO", value: { cityName: "ANTONIO OLINTO", uf: "PR" } },
  {
    name: "APARECIDA DO RIO DOCE",
    value: { cityName: "APARECIDA DO RIO DOCE", uf: "GO" },
  },
  { name: "CRISSIUMAL", value: { cityName: "CRISSIUMAL", uf: "RS" } },
  {
    name: "APARECIDA DO RIO NEGRO",
    value: { cityName: "APARECIDA DO RIO NEGRO", uf: "TO" },
  },
  {
    name: "APARECIDA DO TABOADO",
    value: { cityName: "APARECIDA DO TABOADO", uf: "MS" },
  },
  { name: "FORTALEZA", value: { cityName: "FORTALEZA", uf: "CE" } },
  {
    name: "APARECIDA DOESTE",
    value: { cityName: "APARECIDA DOESTE", uf: "SP" },
  },
  { name: "APERIBE", value: { cityName: "APERIBE", uf: "RJ" } },
  { name: "APIACA", value: { cityName: "APIACA", uf: "ES" } },
  { name: "IMIGRANTE", value: { cityName: "IMIGRANTE", uf: "RS" } },
  { name: "MAFRA", value: { cityName: "MAFRA", uf: "SC" } },
  { name: "APIACAS", value: { cityName: "APIACAS", uf: "MT" } },
  { name: "APICUM-ACU", value: { cityName: "APICUM-ACU", uf: "MA" } },
  { name: "APORE", value: { cityName: "APORE", uf: "GO" } },
  { name: "APUI", value: { cityName: "APUI", uf: "AM" } },
  { name: "DERRUBADAS", value: { cityName: "DERRUBADAS", uf: "RS" } },
  { name: "APUIARES", value: { cityName: "APUIARES", uf: "CE" } },
  {
    name: "DOIS IRMAOS DAS MISSOES",
    value: { cityName: "DOIS IRMAOS DAS MISSOES", uf: "RS" },
  },
  { name: "DOIS LAJEADOS", value: { cityName: "DOIS LAJEADOS", uf: "RS" } },
  { name: "AQUIDAUANA", value: { cityName: "AQUIDAUANA", uf: "MS" } },
  { name: "ARACAGI", value: { cityName: "ARACAGI", uf: "PB" } },
  { name: "ARACARIGUAMA", value: { cityName: "ARACARIGUAMA", uf: "SP" } },
  { name: "ARACATU", value: { cityName: "ARACATU", uf: "BA" } },
  { name: "ENGENHO VELHO", value: { cityName: "ENGENHO VELHO", uf: "RS" } },
  { name: "ARACU", value: { cityName: "ARACU", uf: "GO" } },
  { name: "FONTOURA XAVIER", value: { cityName: "FONTOURA XAVIER", uf: "RS" } },
  { name: "ARAGOIANIA", value: { cityName: "ARAGOIANIA", uf: "GO" } },
  { name: "ARAGOMINAS", value: { cityName: "ARAGOMINAS", uf: "TO" } },
  { name: "ARAGUACEMA", value: { cityName: "ARAGUACEMA", uf: "TO" } },
  {
    name: "FAXINAL DO SOTURNO",
    value: { cityName: "FAXINAL DO SOTURNO", uf: "RS" },
  },
  { name: "ARAGUACU", value: { cityName: "ARAGUACU", uf: "TO" } },
  { name: "ARAGUAIANA", value: { cityName: "ARAGUAIANA", uf: "MT" } },
  { name: "ARAGUAINA", value: { cityName: "ARAGUAINA", uf: "TO" } },
  { name: "ARAGUAINHA", value: { cityName: "ARAGUAINHA", uf: "MT" } },
  { name: "ARAGUANA", value: { cityName: "ARAGUANA", uf: "MA" } },
  { name: "ARAGUATINS", value: { cityName: "ARAGUATINS", uf: "TO" } },
  { name: "ARAL MOREIRA", value: { cityName: "ARAL MOREIRA", uf: "MS" } },
  { name: "ARAME", value: { cityName: "ARAME", uf: "MA" } },
  { name: "ARAMINA", value: { cityName: "ARAMINA", uf: "SP" } },
  { name: "ARANDU", value: { cityName: "ARANDU", uf: "SP" } },
  { name: "NITEROI", value: { cityName: "NITEROI", uf: "RJ" } },
  { name: "ARANTINA", value: { cityName: "ARANTINA", uf: "MG" } },
  { name: "ARAPEI", value: { cityName: "ARAPEI", uf: "SP" } },
  { name: "ALPESTRE", value: { cityName: "ALPESTRE", uf: "RS" } },
  { name: "NONOAI", value: { cityName: "NONOAI", uf: "RS" } },
  { name: "ARAPOEMA", value: { cityName: "ARAPOEMA", uf: "TO" } },
  { name: "ARAPORA", value: { cityName: "ARAPORA", uf: "MG" } },
  { name: "ARAPOTI", value: { cityName: "ARAPOTI", uf: "PR" } },
  { name: "ARAPUA", value: { cityName: "ARAPUA", uf: "MG" } },
  { name: "ARAPUTANGA", value: { cityName: "ARAPUTANGA", uf: "MT" } },
  { name: "ARATACA", value: { cityName: "ARATACA", uf: "BA" } },
  { name: "ARCO-IRIS", value: { cityName: "ARCO-IRIS", uf: "SP" } },
  { name: "ARCOVERDE", value: { cityName: "ARCOVERDE", uf: "PE" } },
  {
    name: "GRAMADO DOS LOUREIROS",
    value: { cityName: "GRAMADO DOS LOUREIROS", uf: "RS" },
  },
  {
    name: "AREIA DE BARAUNAS",
    value: { cityName: "AREIA DE BARAUNAS", uf: "PB" },
  },
  { name: "AREIAL", value: { cityName: "AREIAL", uf: "PB" } },
  { name: "ARENAPOLIS", value: { cityName: "ARENAPOLIS", uf: "MT" } },
  { name: "ARES", value: { cityName: "ARES", uf: "RN" } },
  { name: "ARINOS", value: { cityName: "ARINOS", uf: "MG" } },
  {
    name: "BOQUEIRAO DO LEAO",
    value: { cityName: "BOQUEIRAO DO LEAO", uf: "RS" },
  },
  { name: "ARIPUANA", value: { cityName: "ARIPUANA", uf: "MT" } },
  { name: "ARIQUEMES", value: { cityName: "ARIQUEMES", uf: "RO" } },
  { name: "ARIRANHA", value: { cityName: "ARIRANHA", uf: "SP" } },
  {
    name: "ARIRANHA DO IVAI",
    value: { cityName: "ARIRANHA DO IVAI", uf: "PR" },
  },
  { name: "ARNEIROZ", value: { cityName: "ARNEIROZ", uf: "CE" } },
  {
    name: "SAO PAULO DAS MISSOES",
    value: { cityName: "SAO PAULO DAS MISSOES", uf: "RS" },
  },
  { name: "ARRAIAS", value: { cityName: "ARRAIAS", uf: "TO" } },
  { name: "ITAPUCA", value: { cityName: "ITAPUCA", uf: "RS" } },
  { name: "ARROIO DO PADRE", value: { cityName: "ARROIO DO PADRE", uf: "RS" } },
  { name: "ASSUNCAO", value: { cityName: "ASSUNCAO", uf: "PB" } },
  { name: "BELEM", value: { cityName: "BELEM", uf: "PA" } },
  { name: "JACUTINGA", value: { cityName: "JACUTINGA", uf: "RS" } },
  {
    name: "ASSUNCAO DO PIAUI",
    value: { cityName: "ASSUNCAO DO PIAUI", uf: "PI" },
  },
  {
    name: "ATALAIA DO NORTE",
    value: { cityName: "ATALAIA DO NORTE", uf: "AM" },
  },
  {
    name: "JAZIDAS OU CAPELA SAO VICENTE",
    value: { cityName: "JAZIDAS OU CAPELA SAO VICENTE", uf: "RS" },
  },
  { name: "JOACABA", value: { cityName: "JOACABA", uf: "SC" } },
  { name: "ATILIO VIVACQUA", value: { cityName: "ATILIO VIVACQUA", uf: "ES" } },
  { name: "AUGUSTINOPOLIS", value: { cityName: "AUGUSTINOPOLIS", uf: "TO" } },
  { name: "AURORA DO PARA", value: { cityName: "AURORA DO PARA", uf: "PA" } },
  {
    name: "AURORA DO TOCANTINS",
    value: { cityName: "AURORA DO TOCANTINS", uf: "TO" },
  },
  { name: "ABELARDO LUZ", value: { cityName: "ABELARDO LUZ", uf: "SC" } },
  {
    name: "LAGOA DOS TRES CANTOS",
    value: { cityName: "LAGOA DOS TRES CANTOS", uf: "RS" },
  },
  { name: "AVELINOPOLIS", value: { cityName: "AVELINOPOLIS", uf: "GO" } },
  { name: "AXIXA", value: { cityName: "AXIXA", uf: "MA" } },
  {
    name: "AXIXA DO TOCANTINS",
    value: { cityName: "AXIXA DO TOCANTINS", uf: "TO" },
  },
  { name: "CASCAVEL", value: { cityName: "CASCAVEL", uf: "PR" } },
  { name: "BABACULANDIA", value: { cityName: "BABACULANDIA", uf: "TO" } },
  { name: "BACABAL", value: { cityName: "BACABAL", uf: "MA" } },
  { name: "BACABEIRA", value: { cityName: "BACABEIRA", uf: "MA" } },
  { name: "BACURITUBA", value: { cityName: "BACURITUBA", uf: "MA" } },
  { name: "CONSTANTINA", value: { cityName: "CONSTANTINA", uf: "RS" } },
  { name: "MARATA", value: { cityName: "MARATA", uf: "RS" } },
  { name: "BAGRE", value: { cityName: "BAGRE", uf: "PA" } },
  { name: "SAUDADES", value: { cityName: "SAUDADES", uf: "SC" } },
  { name: "RECIFE", value: { cityName: "RECIFE", uf: "PE" } },
  { name: "BAIA DA TRAICAO", value: { cityName: "BAIA DA TRAICAO", uf: "PB" } },
  { name: "BAIA FORMOSA", value: { cityName: "BAIA FORMOSA", uf: "RN" } },
  { name: "MATO CASTELHANO", value: { cityName: "MATO CASTELHANO", uf: "RS" } },
  { name: "BAIANOPOLIS", value: { cityName: "BAIANOPOLIS", uf: "BA" } },
  {
    name: "BAIXA GRANDE DO RIBEIRO",
    value: { cityName: "BAIXA GRANDE DO RIBEIRO", uf: "PI" },
  },
  { name: "BANANEIRAS", value: { cityName: "BANANEIRAS", uf: "PB" } },
  { name: "BANDEIRA DO SUL", value: { cityName: "BANDEIRA DO SUL", uf: "MG" } },
  {
    name: "BANDEIRANTES DO TOCANTINS",
    value: { cityName: "BANDEIRANTES DO TOCANTINS", uf: "TO" },
  },
  { name: "MONTAURI", value: { cityName: "MONTAURI", uf: "RS" } },
  { name: "BANNACH", value: { cityName: "BANNACH", uf: "PA" } },
  { name: "BANZAE", value: { cityName: "BANZAE", uf: "BA" } },
  {
    name: "BARAO DE ANTONINA",
    value: { cityName: "BARAO DE ANTONINA", uf: "SP" },
  },
  {
    name: "BARAO DE COTEGIPE",
    value: { cityName: "BARAO DE COTEGIPE", uf: "RS" },
  },
  {
    name: "BARAO DE MELGACO",
    value: { cityName: "BARAO DE MELGACO", uf: "MT" },
  },
  {
    name: "MORRINHOS DO SUL",
    value: { cityName: "MORRINHOS DO SUL", uf: "RS" },
  },
  {
    name: "BARAO DE MONTE ALTO",
    value: { cityName: "BARAO DE MONTE ALTO", uf: "MG" },
  },
  { name: "BARCARENA", value: { cityName: "BARCARENA", uf: "PA" } },
  { name: "ITAJUBA", value: { cityName: "ITAJUBA", uf: "MG" } },
  { name: "MULITERNO", value: { cityName: "MULITERNO", uf: "RS" } },
  { name: "BARCELOS", value: { cityName: "BARCELOS", uf: "AM" } },
  { name: "NOVA ALVORADA", value: { cityName: "NOVA ALVORADA", uf: "RS" } },
  { name: "NOVA BOA VISTA", value: { cityName: "NOVA BOA VISTA", uf: "RS" } },
  {
    name: "BARRA DALCANTARA",
    value: { cityName: "BARRA DALCANTARA", uf: "PI" },
  },
  { name: "NOVA PADUA", value: { cityName: "NOVA PADUA", uf: "RS" } },
  {
    name: "BARRA DE GUABIRABA",
    value: { cityName: "BARRA DE GUABIRABA", uf: "PE" },
  },
  {
    name: "BARRA DE SANTANA",
    value: { cityName: "BARRA DE SANTANA", uf: "PB" },
  },
  {
    name: "BARRA DE SANTO ANTONIO",
    value: { cityName: "BARRA DE SANTO ANTONIO", uf: "AL" },
  },
  { name: "BARRA DO BUGRES", value: { cityName: "BARRA DO BUGRES", uf: "MT" } },
  { name: "BARRA DO CHAPEU", value: { cityName: "BARRA DO CHAPEU", uf: "SP" } },
  { name: "BARRA DO CHOCA", value: { cityName: "BARRA DO CHOCA", uf: "BA" } },
  { name: "BARRA DO CORDA", value: { cityName: "BARRA DO CORDA", uf: "MA" } },
  { name: "BELO HORIZONTE", value: { cityName: "BELO HORIZONTE", uf: "MG" } },
  { name: "PARECI NOVO", value: { cityName: "PARECI NOVO", uf: "RS" } },
  { name: "BARRA DO GARCAS", value: { cityName: "BARRA DO GARCAS", uf: "MT" } },
  { name: "BARRA DO JACARE", value: { cityName: "BARRA DO JACARE", uf: "PR" } },
  { name: "BARRA DO ROCHA", value: { cityName: "BARRA DO ROCHA", uf: "BA" } },
  {
    name: "BARREIRAS DO PIAUI",
    value: { cityName: "BARREIRAS DO PIAUI", uf: "PI" },
  },
  { name: "CIRIACO", value: { cityName: "CIRIACO", uf: "RS" } },
  { name: "VARGINHA", value: { cityName: "VARGINHA", uf: "MG" } },
  { name: "BARREIRINHAS", value: { cityName: "BARREIRINHAS", uf: "MA" } },
  { name: "BARRINHA", value: { cityName: "BARRINHA", uf: "SP" } },
  { name: "BARRO DURO", value: { cityName: "BARRO DURO", uf: "PI" } },
  { name: "BARROCAS", value: { cityName: "BARROCAS", uf: "BA" } },
  { name: "BARROLANDIA", value: { cityName: "BARROLANDIA", uf: "TO" } },
  { name: "BATAGUACU", value: { cityName: "BATAGUACU", uf: "MS" } },
  { name: "ARARANGUA", value: { cityName: "ARARANGUA", uf: "SC" } },
  { name: "BATAYPORA", value: { cityName: "BATAYPORA", uf: "MS" } },
  { name: "BELA CRUZ", value: { cityName: "BELA CRUZ", uf: "MG" } },
  {
    name: "BELA VISTA DE GOIAS",
    value: { cityName: "BELA VISTA DE GOIAS", uf: "GO" },
  },
  {
    name: "BELA VISTA DO MARANHAO",
    value: { cityName: "BELA VISTA DO MARANHAO", uf: "MA" },
  },
  { name: "JUNDIAI", value: { cityName: "JUNDIAI", uf: "SP" } },
  { name: "APUCARANA", value: { cityName: "APUCARANA", uf: "PR" } },
  {
    name: "BELA VISTA DO PARAISO",
    value: { cityName: "BELA VISTA DO PARAISO", uf: "PR" },
  },
  {
    name: "BELA VISTA DO PIAUI",
    value: { cityName: "BELA VISTA DO PIAUI", uf: "PI" },
  },
  { name: "BELAGUA", value: { cityName: "BELAGUA", uf: "MA" } },
  { name: "BELEM DO PIAUI", value: { cityName: "BELEM DO PIAUI", uf: "PI" } },
  { name: "PIRAPO", value: { cityName: "PIRAPO", uf: "RS" } },
  { name: "SERAFINA CORREA", value: { cityName: "SERAFINA CORREA", uf: "RS" } },
  { name: "BELO MONTE", value: { cityName: "BELO MONTE", uf: "AL" } },
  { name: "BELTERRA", value: { cityName: "BELTERRA", uf: "PA" } },
  { name: "POCO DAS ANTAS", value: { cityName: "POCO DAS ANTAS", uf: "RS" } },
  { name: "SANTO CRISTO", value: { cityName: "SANTO CRISTO", uf: "RS" } },
  { name: "BENEDITINOS", value: { cityName: "BENEDITINOS", uf: "PI" } },
  { name: "BENEDITO LEITE", value: { cityName: "BENEDITO LEITE", uf: "MA" } },
  { name: "POUSO NOVO", value: { cityName: "POUSO NOVO", uf: "RS" } },
  { name: "BENTO FERNANDES", value: { cityName: "BENTO FERNANDES", uf: "RN" } },
  {
    name: "PRESIDENTE LUCENA",
    value: { cityName: "PRESIDENTE LUCENA", uf: "RS" },
  },
  { name: "CACIQUE DOBLE", value: { cityName: "CACIQUE DOBLE", uf: "RS" } },
  { name: "PROGRESSO", value: { cityName: "PROGRESSO", uf: "RS" } },
  { name: "BEQUIMAO", value: { cityName: "BEQUIMAO", uf: "MA" } },
  { name: "BERIZAL", value: { cityName: "BERIZAL", uf: "MG" } },
  {
    name: "BERNARDINO BATISTA",
    value: { cityName: "BERNARDINO BATISTA", uf: "PB" },
  },
  { name: "PROTASIO ALVES", value: { cityName: "PROTASIO ALVES", uf: "RS" } },
  {
    name: "BERNARDO DO MEARIM",
    value: { cityName: "BERNARDO DO MEARIM", uf: "MA" },
  },
  { name: "BERNARDO SAYAO", value: { cityName: "BERNARDO SAYAO", uf: "TO" } },
  { name: "BERTOLINIA", value: { cityName: "BERTOLINIA", uf: "PI" } },
  { name: "BERURI", value: { cityName: "BERURI", uf: "AM" } },
  {
    name: "BETANIA DO PIAUI",
    value: { cityName: "BETANIA DO PIAUI", uf: "PI" },
  },
  { name: "BIRITIBAMIRIM", value: { cityName: "BIRITIBAMIRIM", uf: "SP" } },
  {
    name: "BOA ESPERANCA DO IGUACU",
    value: { cityName: "BOA ESPERANCA DO IGUACU", uf: "PR" },
  },
  { name: "BOA HORA", value: { cityName: "BOA HORA", uf: "PI" } },
  { name: "RELVADO", value: { cityName: "RELVADO", uf: "RS" } },
  { name: "BOA SAUDE", value: { cityName: "BOA SAUDE", uf: "RN" } },
  { name: "BOA VENTURA", value: { cityName: "BOA VENTURA", uf: "PB" } },
  {
    name: "BOA VENTURA DE SAO ROQUE",
    value: { cityName: "BOA VENTURA DE SAO ROQUE", uf: "PR" },
  },
  {
    name: "BOA VISTA DO GURUPI",
    value: { cityName: "BOA VISTA DO GURUPI", uf: "MA" },
  },
  { name: "BOCA DA MATA", value: { cityName: "BOCA DA MATA", uf: "AL" } },
  { name: "BOCA DO ACRE", value: { cityName: "BOCA DO ACRE", uf: "AM" } },
  {
    name: "BOCAINA DE MINAS",
    value: { cityName: "BOCAINA DE MINAS", uf: "MG" },
  },
  { name: "BODO", value: { cityName: "BODO", uf: "RN" } },
  { name: "BODOQUENA", value: { cityName: "BODOQUENA", uf: "MS" } },
  { name: "BOFETE", value: { cityName: "BOFETE", uf: "SP" } },
  {
    name: "BOM JESUS DAS SELVAS",
    value: { cityName: "BOM JESUS DAS SELVAS", uf: "MA" },
  },
  {
    name: "BOM JESUS DO ARAGUAIA",
    value: { cityName: "BOM JESUS DO ARAGUAIA", uf: "MT" },
  },
  {
    name: "BOM JESUS DO SUL",
    value: { cityName: "BOM JESUS DO SUL", uf: "PR" },
  },
  {
    name: "BOM JESUS DO TOCANTINS",
    value: { cityName: "BOM JESUS DO TOCANTINS", uf: "PA" },
  },
  {
    name: "BOM JESUS DOS PERDOES",
    value: { cityName: "BOM JESUS DOS PERDOES", uf: "SP" },
  },
  { name: "BOM LUGAR", value: { cityName: "BOM LUGAR", uf: "MA" } },
  { name: "VISTA GAUCHA", value: { cityName: "VISTA GAUCHA", uf: "RS" } },
  {
    name: "BOM PRINCIPIO DO PIAUI",
    value: { cityName: "BOM PRINCIPIO DO PIAUI", uf: "PI" },
  },
  {
    name: "SALDANHA MARINHO",
    value: { cityName: "SALDANHA MARINHO", uf: "RS" },
  },
  { name: "BOM REPOUSO", value: { cityName: "BOM REPOUSO", uf: "MG" } },
  {
    name: "BOM SUCESSO DE ITARARE",
    value: { cityName: "BOM SUCESSO DE ITARARE", uf: "SP" },
  },
  {
    name: "PRESIDENTE BERNARDES",
    value: { cityName: "PRESIDENTE BERNARDES", uf: "SP" },
  },
  {
    name: "QUINZE DE NOVEMBRO",
    value: { cityName: "QUINZE DE NOVEMBRO", uf: "RS" },
  },
  { name: "CAICARA", value: { cityName: "CAICARA", uf: "RS" } },
  {
    name: "SANTA CLARA DO SUL",
    value: { cityName: "SANTA CLARA DO SUL", uf: "RS" },
  },
  {
    name: "BOM SUCESSO DO SUL",
    value: { cityName: "BOM SUCESSO DO SUL", uf: "PR" },
  },
  { name: "LINS", value: { cityName: "LINS", uf: "SP" } },
  {
    name: "SANTA MARIA DO HERVAL",
    value: { cityName: "SANTA MARIA DO HERVAL", uf: "RS" },
  },
  { name: "BONFIM DO PIAUI", value: { cityName: "BONFIM DO PIAUI", uf: "PI" } },
  {
    name: "BONFINOPOLIS DE MINAS",
    value: { cityName: "BONFINOPOLIS DE MINAS", uf: "MG" },
  },
  { name: "BONITO DE MINAS", value: { cityName: "BONITO DE MINAS", uf: "MG" } },
  { name: "SANTA TEREZA", value: { cityName: "SANTA TEREZA", uf: "RS" } },
  { name: "BONOPOLIS", value: { cityName: "BONOPOLIS", uf: "GO" } },
  {
    name: "BOQUEIRAO DO PIAUI",
    value: { cityName: "BOQUEIRAO DO PIAUI", uf: "PI" },
  },
  { name: "BORACEIA", value: { cityName: "BORACEIA", uf: "SP" } },
  { name: "BORBA", value: { cityName: "BORBA", uf: "AM" } },
  { name: "ASSAI", value: { cityName: "ASSAI", uf: "PR" } },
  { name: "BORRAZOPOLIS", value: { cityName: "BORRAZOPOLIS", uf: "PR" } },
  {
    name: "SANTO ANTONIO DO PALMA",
    value: { cityName: "SANTO ANTONIO DO PALMA", uf: "RS" },
  },
  { name: "BOTUMIRIM", value: { cityName: "BOTUMIRIM", uf: "MG" } },
  { name: "BRASILANDIA", value: { cityName: "BRASILANDIA", uf: "MS" } },
  { name: "RIO DO SUL", value: { cityName: "RIO DO SUL", uf: "SC" } },
  {
    name: "BRASILANDIA DE MINAS",
    value: { cityName: "BRASILANDIA DE MINAS", uf: "MG" },
  },
  {
    name: "MAXIMILIANO DE ALMEIDA",
    value: { cityName: "MAXIMILIANO DE ALMEIDA", uf: "RS" },
  },
  { name: "VALE REAL", value: { cityName: "VALE REAL", uf: "RS" } },
  {
    name: "SAO JOAO DA URTIGA",
    value: { cityName: "SAO JOAO DA URTIGA", uf: "RS" },
  },
  {
    name: "BRASILANDIA DO SUL",
    value: { cityName: "BRASILANDIA DO SUL", uf: "PR" },
  },
  { name: "GOIAS", value: { cityName: "GOIAS", uf: "GO" } },
  {
    name: "BRASILANDIA DO TOCANTINS",
    value: { cityName: "BRASILANDIA DO TOCANTINS", uf: "TO" },
  },
  {
    name: "SAO JOSE DAS MISSOES",
    value: { cityName: "SAO JOSE DAS MISSOES", uf: "RS" },
  },
  { name: "BRASILEIRA", value: { cityName: "BRASILEIRA", uf: "PI" } },
  {
    name: "SAO JOSE DO INHACORA",
    value: { cityName: "SAO JOSE DO INHACORA", uf: "RS" },
  },
  { name: "BRASNORTE", value: { cityName: "BRASNORTE", uf: "MT" } },
  { name: "BRAZABRANTES", value: { cityName: "BRAZABRANTES", uf: "GO" } },
  { name: "ERVAL SECO", value: { cityName: "ERVAL SECO", uf: "RS" } },
  { name: "BREJETUBA", value: { cityName: "BREJETUBA", uf: "ES" } },
  { name: "CORUMBA", value: { cityName: "CORUMBA", uf: "MS" } },
  { name: "RIO NEGRO", value: { cityName: "RIO NEGRO", uf: "PR" } },
  {
    name: "SAO PEDRO DA SERRA",
    value: { cityName: "SAO PEDRO DA SERRA", uf: "RS" },
  },
  { name: "BREJINHO", value: { cityName: "BREJINHO", uf: "PE" } },
  { name: "ITAPETININGA", value: { cityName: "ITAPETININGA", uf: "SP" } },
  {
    name: "BREJINHO DE NAZARE",
    value: { cityName: "BREJINHO DE NAZARE", uf: "TO" },
  },
  { name: "BREJO ALEGRE", value: { cityName: "BREJO ALEGRE", uf: "SP" } },
  { name: "BREJO DE AREIA", value: { cityName: "BREJO DE AREIA", uf: "MA" } },
  { name: "BREJO DO CRUZ", value: { cityName: "BREJO DO CRUZ", uf: "PB" } },
  {
    name: "SAO VALENTIM DO SUL",
    value: { cityName: "SAO VALENTIM DO SUL", uf: "RS" },
  },
  { name: "BREJO DO PIAUI", value: { cityName: "BREJO DO PIAUI", uf: "PI" } },
  {
    name: "BREJO DOS SANTOS",
    value: { cityName: "BREJO DOS SANTOS", uf: "PB" },
  },
  { name: "SERTAO SANTANA", value: { cityName: "SERTAO SANTANA", uf: "RS" } },
  { name: "SANANDUVA", value: { cityName: "SANANDUVA", uf: "RS" } },
  {
    name: "BREJO GRANDE DO ARAGUAIA",
    value: { cityName: "BREJO GRANDE DO ARAGUAIA", uf: "PA" },
  },
  {
    name: "SETE DE SETEMBRO",
    value: { cityName: "SETE DE SETEMBRO", uf: "RS" },
  },
  { name: "BREJOLANDIA", value: { cityName: "BREJOLANDIA", uf: "BA" } },
  { name: "NOVA FRIBURGO", value: { cityName: "NOVA FRIBURGO", uf: "RJ" } },
  { name: "BREU BRANCO", value: { cityName: "BREU BRANCO", uf: "PA" } },
  { name: "BREVES", value: { cityName: "BREVES", uf: "PA" } },
  { name: "BUGRE", value: { cityName: "BUGRE", uf: "MG" } },
  { name: "BURITAMA", value: { cityName: "BURITAMA", uf: "SP" } },
  { name: "BURITI ALEGRE", value: { cityName: "BURITI ALEGRE", uf: "GO" } },
  { name: "GUARAPUAVA", value: { cityName: "GUARAPUAVA", uf: "PR" } },
  { name: "BURITI BRAVO", value: { cityName: "BURITI BRAVO", uf: "MA" } },
  { name: "BURITI DE GOIAS", value: { cityName: "BURITI DE GOIAS", uf: "GO" } },
  {
    name: "BURITI DO TOCANTINS",
    value: { cityName: "BURITI DO TOCANTINS", uf: "TO" },
  },
  {
    name: "BURITI DOS LOPES",
    value: { cityName: "BURITI DOS LOPES", uf: "PI" },
  },
  {
    name: "BURITI DOS MONTES",
    value: { cityName: "BURITI DOS MONTES", uf: "PI" },
  },
  { name: "PALMITOS", value: { cityName: "PALMITOS", uf: "SC" } },
  { name: "LAGUNA", value: { cityName: "LAGUNA", uf: "SC" } },
  { name: "BURITICUPU", value: { cityName: "BURITICUPU", uf: "MA" } },
  { name: "PIRAPO", value: { cityName: "PIRAPO", uf: "PR" } },
  { name: "BURITIRANA", value: { cityName: "BURITIRANA", uf: "MA" } },
  { name: "CAARAPO", value: { cityName: "CAARAPO", uf: "MS" } },
  {
    name: "CABACEIRAS DO PARAGUACU",
    value: { cityName: "CABACEIRAS DO PARAGUACU", uf: "BA" },
  },
  { name: "TRAVESSEIRO", value: { cityName: "TRAVESSEIRO", uf: "RS" } },
  {
    name: "CABECEIRA GRANDE",
    value: { cityName: "CABECEIRA GRANDE", uf: "MG" },
  },
  {
    name: "CABECEIRAS DO PIAUI",
    value: { cityName: "CABECEIRAS DO PIAUI", uf: "PI" },
  },
  { name: "TUPANCI DO SUL", value: { cityName: "TUPANCI DO SUL", uf: "RS" } },
  { name: "TUPANDI", value: { cityName: "TUPANDI", uf: "RS" } },
  { name: "CABIXI", value: { cityName: "CABIXI", uf: "RO" } },
  {
    name: "CABO DE SANTO AGOSTINHO",
    value: { cityName: "CABO DE SANTO AGOSTINHO", uf: "PE" },
  },
  { name: "CACAULANDIA", value: { cityName: "CACAULANDIA", uf: "RO" } },
  { name: "CACERES", value: { cityName: "CACERES", uf: "MT" } },
  { name: "UNIAO DA SERRA", value: { cityName: "UNIAO DA SERRA", uf: "RS" } },
  {
    name: "CACHOEIRA DE PAJEU",
    value: { cityName: "CACHOEIRA DE PAJEU", uf: "MG" },
  },
  {
    name: "CACHOEIRA DO ARARI",
    value: { cityName: "CACHOEIRA DO ARARI", uf: "PA" },
  },
  {
    name: "CACHOEIRA DO PIRIA",
    value: { cityName: "CACHOEIRA DO PIRIA", uf: "PA" },
  },
  {
    name: "CACHOEIRA DOS INDIOS",
    value: { cityName: "CACHOEIRA DOS INDIOS", uf: "PB" },
  },
  {
    name: "CACHOEIRA GRANDE",
    value: { cityName: "CACHOEIRA GRANDE", uf: "MA" },
  },
  {
    name: "CACIMBA DE DENTRO",
    value: { cityName: "CACIMBA DE DENTRO", uf: "PB" },
  },
  { name: "CACIMBAS", value: { cityName: "CACIMBAS", uf: "PB" } },
  { name: "CACOAL", value: { cityName: "CACOAL", uf: "RO" } },
  { name: "CAETANOS", value: { cityName: "CAETANOS", uf: "BA" } },
  { name: "CAFEARA", value: { cityName: "CAFEARA", uf: "PR" } },
  { name: "CAFEZAL DO SUL", value: { cityName: "CAFEZAL DO SUL", uf: "PR" } },
  { name: "CAIANA", value: { cityName: "CAIANA", uf: "MG" } },
  {
    name: "VISTA ALEGRE DO PRATA",
    value: { cityName: "VISTA ALEGRE DO PRATA", uf: "RS" },
  },
  {
    name: "CAICARA DO NORTE",
    value: { cityName: "CAICARA DO NORTE", uf: "RN" },
  },
  {
    name: "CAICARA DO RIO DO VENTO",
    value: { cityName: "CAICARA DO RIO DO VENTO", uf: "RN" },
  },
  { name: "CAIRU", value: { cityName: "CAIRU", uf: "BA" } },
  { name: "XANGRI-LA", value: { cityName: "XANGRI-LA", uf: "RS" } },
  {
    name: "JUAZEIRO DO NORTE",
    value: { cityName: "JUAZEIRO DO NORTE", uf: "CE" },
  },
  { name: "SOBRAL", value: { cityName: "SOBRAL", uf: "CE" } },
  { name: "ABAIARA", value: { cityName: "ABAIARA", uf: "CE" } },
  { name: "CAIUA", value: { cityName: "CAIUA", uf: "SP" } },
  { name: "ACARAPE", value: { cityName: "ACARAPE", uf: "CE" } },
  { name: "ACARAU", value: { cityName: "ACARAU", uf: "CE" } },
  { name: "ACOPIARA", value: { cityName: "ACOPIARA", uf: "CE" } },
  { name: "CAJARI", value: { cityName: "CAJARI", uf: "MA" } },
  { name: "CAJATI", value: { cityName: "CAJATI", uf: "SP" } },
  {
    name: "CAJAZEIRAS DO PIAUI",
    value: { cityName: "CAJAZEIRAS DO PIAUI", uf: "PI" },
  },
  { name: "CAJAZEIRINHAS", value: { cityName: "CAJAZEIRINHAS", uf: "PB" } },
  { name: "AIUABA", value: { cityName: "AIUABA", uf: "CE" } },
  { name: "CAJUEIRO", value: { cityName: "CAJUEIRO", uf: "AL" } },
  {
    name: "CAJUEIRO DA PRAIA",
    value: { cityName: "CAJUEIRO DA PRAIA", uf: "PI" },
  },
  { name: "ALCANTARAS", value: { cityName: "ALCANTARAS", uf: "CE" } },
  { name: "CALCOENE", value: { cityName: "CALCOENE", uf: "AP" } },
  { name: "CALDAS BRANDAO", value: { cityName: "CALDAS BRANDAO", uf: "PB" } },
  { name: "ALTANEIRA", value: { cityName: "ALTANEIRA", uf: "CE" } },
  { name: "ALTO SANTO", value: { cityName: "ALTO SANTO", uf: "CE" } },
  { name: "CALDAZINHA", value: { cityName: "CALDAZINHA", uf: "GO" } },
  {
    name: "CALDEIRAO GRANDE DO PIAUI",
    value: { cityName: "CALDEIRAO GRANDE DO PIAUI", uf: "PI" },
  },
  { name: "CALIFORNIA", value: { cityName: "CALIFORNIA", uf: "PR" } },
  { name: "MANICORE", value: { cityName: "MANICORE", uf: "AM" } },
  { name: "CAMAPUA", value: { cityName: "CAMAPUA", uf: "MS" } },
  { name: "AMONTADA", value: { cityName: "AMONTADA", uf: "CE" } },
  {
    name: "CAMOCIM DE SAO FELIX",
    value: { cityName: "CAMOCIM DE SAO FELIX", uf: "PE" },
  },
  {
    name: "CAMPESTRE DE GOIAS",
    value: { cityName: "CAMPESTRE DE GOIAS", uf: "GO" },
  },
  {
    name: "CAMPESTRE DO MARANHAO",
    value: { cityName: "CAMPESTRE DO MARANHAO", uf: "MA" },
  },
  {
    name: "ANTONINA DO NORTE",
    value: { cityName: "ANTONINA DO NORTE", uf: "CE" },
  },
  {
    name: "CAMPINA DA LAGOA",
    value: { cityName: "CAMPINA DA LAGOA", uf: "PR" },
  },
  {
    name: "CAMPINA DO MONTE ALEGRE",
    value: { cityName: "CAMPINA DO MONTE ALEGRE", uf: "SP" },
  },
  { name: "CAMPINACU", value: { cityName: "CAMPINACU", uf: "GO" } },
  { name: "AQUIRAZ", value: { cityName: "AQUIRAZ", uf: "CE" } },
  { name: "CAMPINAPOLIS", value: { cityName: "CAMPINAPOLIS", uf: "MT" } },
  { name: "ARACATI", value: { cityName: "ARACATI", uf: "CE" } },
  {
    name: "CAMPO ALEGRE DO FIDALGO",
    value: { cityName: "CAMPO ALEGRE DO FIDALGO", uf: "PI" },
  },
  {
    name: "CAMPO GRANDE DO PIAUI",
    value: { cityName: "CAMPO GRANDE DO PIAUI", uf: "PI" },
  },
  { name: "ARACOIABA", value: { cityName: "ARACOIABA", uf: "CE" } },
  {
    name: "CAMPO LARGO DO PIAUI",
    value: { cityName: "CAMPO LARGO DO PIAUI", uf: "PI" },
  },
  {
    name: "CAMPO LIMPO DE GOIAS",
    value: { cityName: "CAMPO LIMPO DE GOIAS", uf: "GO" },
  },
  {
    name: "CAMPO NOVO DE RONDONIA",
    value: { cityName: "CAMPO NOVO DE RONDONIA", uf: "RO" },
  },
  {
    name: "CAMPO NOVO DO PARECIS",
    value: { cityName: "CAMPO NOVO DO PARECIS", uf: "MT" },
  },
  { name: "ARARENDA", value: { cityName: "ARARENDA", uf: "CE" } },
  { name: "POXOREU", value: { cityName: "POXOREU", uf: "MT" } },
  { name: "ARARIPE", value: { cityName: "ARARIPE", uf: "CE" } },
  { name: "CAMPO VERDE", value: { cityName: "CAMPO VERDE", uf: "MT" } },
  { name: "CAMPOS DE JULIO", value: { cityName: "CAMPOS DE JULIO", uf: "MT" } },
  {
    name: "CAMPOS DOS GOYTACAZES",
    value: { cityName: "CAMPOS DOS GOYTACAZES", uf: "RJ" },
  },
  { name: "ARATUBA", value: { cityName: "ARATUBA", uf: "CE" } },
  { name: "CAMPOS LINDOS", value: { cityName: "CAMPOS LINDOS", uf: "TO" } },
  { name: "CAMUTANGA", value: { cityName: "CAMUTANGA", uf: "PE" } },
  {
    name: "CANAA DOS CARAJAS",
    value: { cityName: "CANAA DOS CARAJAS", uf: "PA" },
  },
  {
    name: "CANABRAVA DO NORTE",
    value: { cityName: "CANABRAVA DO NORTE", uf: "MT" },
  },
  { name: "CANAPI", value: { cityName: "CANAPI", uf: "AL" } },
  { name: "CANAS", value: { cityName: "CANAS", uf: "SP" } },
  { name: "ASSARE", value: { cityName: "ASSARE", uf: "CE" } },
  {
    name: "CANDEIAS DO JAMARI",
    value: { cityName: "CANDEIAS DO JAMARI", uf: "RO" },
  },
  { name: "CANDIBA", value: { cityName: "CANDIBA", uf: "BA" } },
  {
    name: "CANDIDO DE ABREU",
    value: { cityName: "CANDIDO DE ABREU", uf: "PR" },
  },
  { name: "AURORA", value: { cityName: "AURORA", uf: "CE" } },
  { name: "CANDIDO MENDES", value: { cityName: "CANDIDO MENDES", uf: "MA" } },
  { name: "BAIXIO", value: { cityName: "BAIXIO", uf: "CE" } },
  { name: "CANITAR", value: { cityName: "CANITAR", uf: "SP" } },
  { name: "BANABUIU", value: { cityName: "BANABUIU", uf: "CE" } },
  { name: "CANTA", value: { cityName: "CANTA", uf: "RR" } },
  { name: "CANUTAMA", value: { cityName: "CANUTAMA", uf: "AM" } },
  { name: "BARBALHA", value: { cityName: "BARBALHA", uf: "CE" } },
  { name: "CAPARAO", value: { cityName: "CAPARAO", uf: "MG" } },
  {
    name: "CAPELA DO ALTO ALEGRE",
    value: { cityName: "CAPELA DO ALTO ALEGRE", uf: "BA" },
  },
  { name: "BARREIRA", value: { cityName: "BARREIRA", uf: "CE" } },
  { name: "MORRINHOS", value: { cityName: "MORRINHOS", uf: "GO" } },
  { name: "CAPIM", value: { cityName: "CAPIM", uf: "PB" } },
  { name: "CUIABA", value: { cityName: "CUIABA", uf: "MT" } },
  { name: "CAPIM GROSSO", value: { cityName: "CAPIM GROSSO", uf: "BA" } },
  {
    name: "SALVADOR DAS MISSOES",
    value: { cityName: "SALVADOR DAS MISSOES", uf: "RS" },
  },
  { name: "UMBU", value: { cityName: "UMBU", uf: "RS" } },
  { name: "MACAMBARA", value: { cityName: "MACAMBARA", uf: "RS" } },
  { name: "SALVADOR", value: { cityName: "SALVADOR", uf: "BA" } },
  { name: "LONDRINA", value: { cityName: "LONDRINA", uf: "PR" } },
  {
    name: "CAPINZAL DO NORTE",
    value: { cityName: "CAPINZAL DO NORTE", uf: "MA" },
  },
  { name: "CAPISTRANO", value: { cityName: "CAPISTRANO", uf: "CE" } },
  {
    name: "CAPITAO DE CAMPOS",
    value: { cityName: "CAPITAO DE CAMPOS", uf: "PI" },
  },
  { name: "BARRO", value: { cityName: "BARRO", uf: "CE" } },
  {
    name: "CAPITAO GERVASIO OLIVEIRA",
    value: { cityName: "CAPITAO GERVASIO OLIVEIRA", uf: "PI" },
  },
  { name: "BARROQUINHA", value: { cityName: "BARROQUINHA", uf: "CE" } },
  { name: "BATURITE", value: { cityName: "BATURITE", uf: "CE" } },
  {
    name: "CAPITAO LEONIDAS MARQUES",
    value: { cityName: "CAPITAO LEONIDAS MARQUES", uf: "PR" },
  },
  { name: "BEBERIBE", value: { cityName: "BEBERIBE", uf: "CE" } },
  { name: "CAPIXABA", value: { cityName: "CAPIXABA", uf: "AC" } },
  { name: "PETROPOLIS", value: { cityName: "PETROPOLIS", uf: "RJ" } },
  { name: "CARACARAI", value: { cityName: "CARACARAI", uf: "RR" } },
  { name: "CARAIBAS", value: { cityName: "CARAIBAS", uf: "BA" } },
  { name: "CARAMBEI", value: { cityName: "CARAMBEI", uf: "PR" } },
  { name: "CARAPEBUS", value: { cityName: "CARAPEBUS", uf: "RJ" } },
  {
    name: "CARAUBAS DO PIAUI",
    value: { cityName: "CARAUBAS DO PIAUI", uf: "PI" },
  },
  { name: "CARDOSO MOREIRA", value: { cityName: "CARDOSO MOREIRA", uf: "RJ" } },
  { name: "CAREIRO", value: { cityName: "CAREIRO", uf: "AM" } },
  { name: "BOA VIAGEM", value: { cityName: "BOA VIAGEM", uf: "CE" } },
  {
    name: "CAREIRO DA VARZEA",
    value: { cityName: "CAREIRO DA VARZEA", uf: "AM" },
  },
  {
    name: "CARIDADE DO PIAUI",
    value: { cityName: "CARIDADE DO PIAUI", uf: "PI" },
  },
  {
    name: "CARIRI DO TOCANTINS",
    value: { cityName: "CARIRI DO TOCANTINS", uf: "TO" },
  },
  { name: "CARLINDA", value: { cityName: "CARLINDA", uf: "MT" } },
  { name: "CARLOPOLIS", value: { cityName: "CARLOPOLIS", uf: "PR" } },
  {
    name: "CARMO DO RIO VERDE",
    value: { cityName: "CARMO DO RIO VERDE", uf: "GO" },
  },
  { name: "CARMOLANDIA", value: { cityName: "CARMOLANDIA", uf: "TO" } },
  { name: "CARNAUBAIS", value: { cityName: "CARNAUBAIS", uf: "RN" } },
  {
    name: "CARNAUBEIRA DA PENHA",
    value: { cityName: "CARNAUBEIRA DA PENHA", uf: "PE" },
  },
  { name: "BREJO SANTO", value: { cityName: "BREJO SANTO", uf: "CE" } },
  { name: "VOLTA REDONDA", value: { cityName: "VOLTA REDONDA", uf: "RJ" } },
  { name: "CARNEIROS", value: { cityName: "CARNEIROS", uf: "AL" } },
  { name: "CAROEBE", value: { cityName: "CAROEBE", uf: "RR" } },
  { name: "CAPANEMA", value: { cityName: "CAPANEMA", uf: "PR" } },
  { name: "CARRAPATEIRA", value: { cityName: "CARRAPATEIRA", uf: "PB" } },
  { name: "CARRASCO BONITO", value: { cityName: "CARRASCO BONITO", uf: "TO" } },
  { name: "CARVALHOS", value: { cityName: "CARVALHOS", uf: "MG" } },
  { name: "CASA GRANDE", value: { cityName: "CASA GRANDE", uf: "MG" } },
  { name: "CASCALHO RICO", value: { cityName: "CASCALHO RICO", uf: "MG" } },
  { name: "CASEARA", value: { cityName: "CASEARA", uf: "TO" } },
  { name: "CASINHAS", value: { cityName: "CASINHAS", uf: "PE" } },
  { name: "CASSERENGUE", value: { cityName: "CASSERENGUE", uf: "PB" } },
  { name: "CASSILANDIA", value: { cityName: "CASSILANDIA", uf: "MS" } },
  { name: "CASTANHEIRA", value: { cityName: "CASTANHEIRA", uf: "MT" } },
  { name: "CASTANHEIRAS", value: { cityName: "CASTANHEIRAS", uf: "RO" } },
  { name: "CAMOCIM", value: { cityName: "CAMOCIM", uf: "CE" } },
  { name: "GUACUI", value: { cityName: "GUACUI", uf: "ES" } },
  {
    name: "CASTELO DO PIAUI",
    value: { cityName: "CASTELO DO PIAUI", uf: "PI" },
  },
  { name: "CAMPOS SALES", value: { cityName: "CAMPOS SALES", uf: "CE" } },
  { name: "CATINGUEIRA", value: { cityName: "CATINGUEIRA", uf: "PB" } },
  { name: "CATOLANDIA", value: { cityName: "CATOLANDIA", uf: "BA" } },
  { name: "CATUNDA", value: { cityName: "CATUNDA", uf: "CE" } },
  { name: "PACOTI", value: { cityName: "PACOTI", uf: "CE" } },
  { name: "CATURAMA", value: { cityName: "CATURAMA", uf: "BA" } },
  { name: "MEDIANEIRA", value: { cityName: "MEDIANEIRA", uf: "PR" } },
  { name: "CANINDE", value: { cityName: "CANINDE", uf: "CE" } },
  { name: "CATURITE", value: { cityName: "CATURITE", uf: "PB" } },
  { name: "CATUTI", value: { cityName: "CATUTI", uf: "MG" } },
  { name: "MODELO", value: { cityName: "MODELO", uf: "SC" } },
  { name: "CAXINGO", value: { cityName: "CAXINGO", uf: "PI" } },
  { name: "PEJUCARA", value: { cityName: "PEJUCARA", uf: "RS" } },
  { name: "COLORADO", value: { cityName: "COLORADO", uf: "PR" } },
  {
    name: "CENTENARIO DO SUL",
    value: { cityName: "CENTENARIO DO SUL", uf: "PR" },
  },
  {
    name: "CENTRAL DO MARANHAO",
    value: { cityName: "CENTRAL DO MARANHAO", uf: "MA" },
  },
  { name: "CASCAVEL", value: { cityName: "CASCAVEL", uf: "CE" } },
  { name: "CARIDADE", value: { cityName: "CARIDADE", uf: "CE" } },
  { name: "CARIRE", value: { cityName: "CARIRE", uf: "CE" } },
  { name: "CARIRIACU", value: { cityName: "CARIRIACU", uf: "CE" } },
  { name: "CARIUS", value: { cityName: "CARIUS", uf: "CE" } },
  { name: "POCONE", value: { cityName: "POCONE", uf: "MT" } },
  {
    name: "CENTRO DO GUILHERME",
    value: { cityName: "CENTRO DO GUILHERME", uf: "MA" },
  },
  {
    name: "CENTRO NOVO DO MARANHAO",
    value: { cityName: "CENTRO NOVO DO MARANHAO", uf: "MA" },
  },
  { name: "XAXIM", value: { cityName: "XAXIM", uf: "SC" } },
  { name: "CEREJEIRAS", value: { cityName: "CEREJEIRAS", uf: "RO" } },
  { name: "CARNAUBAL", value: { cityName: "CARNAUBAL", uf: "CE" } },
  { name: "OROS", value: { cityName: "OROS", uf: "RS" } },
  { name: "CERRO CORA", value: { cityName: "CERRO CORA", uf: "RN" } },
  { name: "CESARIO LANGE", value: { cityName: "CESARIO LANGE", uf: "SP" } },
  { name: "CHA GRANDE", value: { cityName: "CHA GRANDE", uf: "PE" } },
  {
    name: "CHAPADA DA NATIVIDADE",
    value: { cityName: "CHAPADA DA NATIVIDADE", uf: "TO" },
  },
  {
    name: "CHAPADA DE AREIA",
    value: { cityName: "CHAPADA DE AREIA", uf: "TO" },
  },
  {
    name: "CHAPADA DOS GUIMARAES",
    value: { cityName: "CHAPADA DOS GUIMARAES", uf: "MT" },
  },
  { name: "CATARINA", value: { cityName: "CATARINA", uf: "CE" } },
  { name: "CHAPADA GAUCHA", value: { cityName: "CHAPADA GAUCHA", uf: "MG" } },
  { name: "CHAPADAO DO SUL", value: { cityName: "CHAPADAO DO SUL", uf: "MS" } },
  { name: "CHAPADINHA", value: { cityName: "CHAPADINHA", uf: "MA" } },
  { name: "CAUCAIA", value: { cityName: "CAUCAIA", uf: "CE" } },
  { name: "CHAVES", value: { cityName: "CHAVES", uf: "PA" } },
  { name: "ANDRE DA ROCHA", value: { cityName: "ANDRE DA ROCHA", uf: "RS" } },
  { name: "CHOPINZINHO", value: { cityName: "CHOPINZINHO", uf: "PR" } },
  { name: "CEDRO", value: { cityName: "CEDRO", uf: "CE" } },
  { name: "CHUPINGUAIA", value: { cityName: "CHUPINGUAIA", uf: "RO" } },
  { name: "CHAVAL", value: { cityName: "CHAVAL", uf: "CE" } },
  { name: "CHORO", value: { cityName: "CHORO", uf: "CE" } },
  { name: "CHUVISCA", value: { cityName: "CHUVISCA", uf: "RS" } },
  { name: "CHOROZINHO", value: { cityName: "CHOROZINHO", uf: "CE" } },
  { name: "SAO JOSE DO SUL", value: { cityName: "SAO JOSE DO SUL", uf: "RS" } },
  { name: "SANTOS", value: { cityName: "SANTOS", uf: "SP" } },
  { name: "CIDADE GAUCHA", value: { cityName: "CIDADE GAUCHA", uf: "PR" } },
  { name: "CIDELANDIA", value: { cityName: "CIDELANDIA", uf: "MA" } },
  { name: "CLAUDIA", value: { cityName: "CLAUDIA", uf: "MT" } },
  { name: "CLEMENTINA", value: { cityName: "CLEMENTINA", uf: "SP" } },
  { name: "COARI", value: { cityName: "COARI", uf: "AM" } },
  { name: "COCAL DE TELHA", value: { cityName: "COCAL DE TELHA", uf: "PI" } },
  { name: "COREAU", value: { cityName: "COREAU", uf: "CE" } },
  { name: "DIADEMA", value: { cityName: "DIADEMA", uf: "SP" } },
  { name: "COCAL DOS ALVES", value: { cityName: "COCAL DOS ALVES", uf: "PI" } },
  { name: "BRUSQUE", value: { cityName: "BRUSQUE", uf: "SC" } },
  { name: "CRATEUS", value: { cityName: "CRATEUS", uf: "CE" } },
  { name: "CRATO", value: { cityName: "CRATO", uf: "CE" } },
  { name: "COCALINHO", value: { cityName: "COCALINHO", uf: "MT" } },
  { name: "CROATA", value: { cityName: "CROATA", uf: "CE" } },
  { name: "CODAJAS", value: { cityName: "CODAJAS", uf: "AM" } },
  { name: "CODO", value: { cityName: "CODO", uf: "MA" } },
  { name: "COITE DO NOIA", value: { cityName: "COITE DO NOIA", uf: "AL" } },
  { name: "BARBACENA", value: { cityName: "BARBACENA", uf: "MG" } },
  { name: "CRUZ", value: { cityName: "CRUZ", uf: "CE" } },
  { name: "COIVARAS", value: { cityName: "COIVARAS", uf: "PI" } },
  { name: "COLARES", value: { cityName: "COLARES", uf: "PA" } },
  { name: "COLIDER", value: { cityName: "COLIDER", uf: "MT" } },
  { name: "COLINAS DO SUL", value: { cityName: "COLINAS DO SUL", uf: "GO" } },
  {
    name: "COLINAS DO TOCANTINS",
    value: { cityName: "COLINAS DO TOCANTINS", uf: "TO" },
  },
  { name: "COLMEIA", value: { cityName: "COLMEIA", uf: "TO" } },
  { name: "COLNIZA", value: { cityName: "COLNIZA", uf: "MT" } },
  {
    name: "COLONIA DO GURGUEIA",
    value: { cityName: "COLONIA DO GURGUEIA", uf: "PI" },
  },
  { name: "ILOPOLIS", value: { cityName: "ILOPOLIS", uf: "RS" } },
  {
    name: "COLONIA DO PIAUI",
    value: { cityName: "COLONIA DO PIAUI", uf: "PI" },
  },
  { name: "ICARA", value: { cityName: "ICARA", uf: "SC" } },
  {
    name: "COLONIA LEOPOLDINA",
    value: { cityName: "COLONIA LEOPOLDINA", uf: "AL" },
  },
  {
    name: "COLORADO DO OESTE",
    value: { cityName: "COLORADO DO OESTE", uf: "RO" },
  },
  { name: "COMBINADO", value: { cityName: "COMBINADO", uf: "TO" } },
  {
    name: "COMENDADOR LEVY GASPARIAN",
    value: { cityName: "COMENDADOR LEVY GASPARIAN", uf: "RJ" },
  },
  { name: "COMODORO", value: { cityName: "COMODORO", uf: "MT" } },
  {
    name: "CONCEICAO DA APARECIDA",
    value: { cityName: "CONCEICAO DA APARECIDA", uf: "MG" },
  },
  {
    name: "CONCEICAO DA BARRA DE MINAS",
    value: { cityName: "CONCEICAO DA BARRA DE MINAS", uf: "MG" },
  },
  {
    name: "CONCEICAO DAS PEDRAS",
    value: { cityName: "CONCEICAO DAS PEDRAS", uf: "MG" },
  },
  {
    name: "CONCEICAO DO ARAGUAIA",
    value: { cityName: "CONCEICAO DO ARAGUAIA", uf: "PA" },
  },
  {
    name: "CONCEICAO DO CASTELO",
    value: { cityName: "CONCEICAO DO CASTELO", uf: "ES" },
  },
  {
    name: "CONCEICAO DO LAGO ACU",
    value: { cityName: "CONCEICAO DO LAGO ACU", uf: "MA" },
  },
  { name: "CAMPO GRANDE", value: { cityName: "CAMPO GRANDE", uf: "MS" } },
  {
    name: "CONCEICAO DO TOCANTINS",
    value: { cityName: "CONCEICAO DO TOCANTINS", uf: "TO" },
  },
  { name: "PIRACICABA", value: { cityName: "PIRACICABA", uf: "SP" } },
  {
    name: "CONCEICAO DOS OUROS",
    value: { cityName: "CONCEICAO DOS OUROS", uf: "MG" },
  },
  { name: "NOVA ARACA", value: { cityName: "NOVA ARACA", uf: "RS" } },
  { name: "CONCHAL", value: { cityName: "CONCHAL", uf: "SP" } },
  { name: "FARIAS BRITO", value: { cityName: "FARIAS BRITO", uf: "CE" } },
  {
    name: "CONCORDIA DO PARA",
    value: { cityName: "CONCORDIA DO PARA", uf: "PA" },
  },
  { name: "CONEGO MARINHO", value: { cityName: "CONEGO MARINHO", uf: "MG" } },
  { name: "CONFRESA", value: { cityName: "CONFRESA", uf: "MT" } },
  { name: "CONGO", value: { cityName: "CONGO", uf: "PB" } },
  { name: "CONGONHINHAS", value: { cityName: "CONGONHINHAS", uf: "PR" } },
  {
    name: "CONQUISTA DOESTE",
    value: { cityName: "CONQUISTA DOESTE", uf: "MT" },
  },
  { name: "FORQUILHA", value: { cityName: "FORQUILHA", uf: "CE" } },
  {
    name: "CONSELHEIRO LAFAIETE",
    value: { cityName: "CONSELHEIRO LAFAIETE", uf: "MG" },
  },
  { name: "FORTIM", value: { cityName: "FORTIM", uf: "CE" } },
  {
    name: "CONSELHEIRO MAIRINCK",
    value: { cityName: "CONSELHEIRO MAIRINCK", uf: "PR" },
  },
  {
    name: "CONTENDAS DO SINCORA",
    value: { cityName: "CONTENDAS DO SINCORA", uf: "BA" },
  },
  { name: "COQUEIRO SECO", value: { cityName: "COQUEIRO SECO", uf: "AL" } },
  { name: "CORDEIROS", value: { cityName: "CORDEIROS", uf: "BA" } },
  { name: "CORGUINHO", value: { cityName: "CORGUINHO", uf: "MS" } },
  { name: "GENERAL SAMPAIO", value: { cityName: "GENERAL SAMPAIO", uf: "CE" } },
  { name: "COROATA", value: { cityName: "COROATA", uf: "MA" } },
  { name: "CORONEL BICACO", value: { cityName: "CORONEL BICACO", uf: "RS" } },
  {
    name: "CORONEL EZEQUIEL",
    value: { cityName: "CORONEL EZEQUIEL", uf: "RN" },
  },
  { name: "GRACA", value: { cityName: "GRACA", uf: "CE" } },
  { name: "GRANJA", value: { cityName: "GRANJA", uf: "CE" } },
  { name: "GRANJEIRO", value: { cityName: "GRANJEIRO", uf: "CE" } },
  { name: "GROAIRAS", value: { cityName: "GROAIRAS", uf: "CE" } },
  { name: "GUAIUBA", value: { cityName: "GUAIUBA", uf: "CE" } },
  {
    name: "GUARACIABA DO NORTE",
    value: { cityName: "GUARACIABA DO NORTE", uf: "CE" },
  },
  { name: "GUARAMIRANGA", value: { cityName: "GUARAMIRANGA", uf: "CE" } },
  {
    name: "CORONEL JOAO PESSOA",
    value: { cityName: "CORONEL JOAO PESSOA", uf: "RN" },
  },
  { name: "CORONEL JOAO SA", value: { cityName: "CORONEL JOAO SA", uf: "BA" } },
  {
    name: "CORONEL JOSE DIAS",
    value: { cityName: "CORONEL JOSE DIAS", uf: "PI" },
  },
  { name: "COTIPORA", value: { cityName: "COTIPORA", uf: "RS" } },
  { name: "HIDROLANDIA", value: { cityName: "HIDROLANDIA", uf: "CE" } },
  { name: "CORONEL MACEDO", value: { cityName: "CORONEL MACEDO", uf: "SP" } },
  { name: "HORIZONTE", value: { cityName: "HORIZONTE", uf: "CE" } },
  {
    name: "CORONEL SAPUCAIA",
    value: { cityName: "CORONEL SAPUCAIA", uf: "MS" },
  },
  { name: "CORONEL VIVIDA", value: { cityName: "CORONEL VIVIDA", uf: "PR" } },
  { name: "IBIAPINA", value: { cityName: "IBIAPINA", uf: "CE" } },
  { name: "CORREGO DO OURO", value: { cityName: "CORREGO DO OURO", uf: "GO" } },
  { name: "CORREGO FUNDO", value: { cityName: "CORREGO FUNDO", uf: "MG" } },
  { name: "IBICUITINGA", value: { cityName: "IBICUITINGA", uf: "CE" } },
  {
    name: "CORUMBATAI DO SUL",
    value: { cityName: "CORUMBATAI DO SUL", uf: "PR" },
  },
  { name: "PONTE SERRADA", value: { cityName: "PONTE SERRADA", uf: "SC" } },
  { name: "CORUMBIARA", value: { cityName: "CORUMBIARA", uf: "RO" } },
  { name: "ICAPUI", value: { cityName: "ICAPUI", uf: "CE" } },
  { name: "ICO", value: { cityName: "ICO", uf: "CE" } },
  { name: "COSTA MARQUES", value: { cityName: "COSTA MARQUES", uf: "RO" } },
  { name: "COSTA RICA", value: { cityName: "COSTA RICA", uf: "MS" } },
  { name: "COTEGIPE", value: { cityName: "COTEGIPE", uf: "BA" } },
  { name: "IGUATU", value: { cityName: "IGUATU", uf: "CE" } },
  { name: "INDEPENDENCIA", value: { cityName: "INDEPENDENCIA", uf: "CE" } },
  { name: "COTRIGUACU", value: { cityName: "COTRIGUACU", uf: "MT" } },
  {
    name: "COUTO DE MAGALHAES",
    value: { cityName: "COUTO DE MAGALHAES", uf: "TO" },
  },
  { name: "COXIM", value: { cityName: "COXIM", uf: "MS" } },
  { name: "COXIXOLA", value: { cityName: "COXIXOLA", uf: "PB" } },
  { name: "MAGISTERIO", value: { cityName: "MAGISTERIO", uf: "RS" } },
  { name: "IPAPORANGA", value: { cityName: "IPAPORANGA", uf: "CE" } },
  { name: "IPAUMIRIM", value: { cityName: "IPAUMIRIM", uf: "CE" } },
  { name: "IPU", value: { cityName: "IPU", uf: "CE" } },
  { name: "IPUEIRAS", value: { cityName: "IPUEIRAS", uf: "CE" } },
  { name: "CRAVOLANDIA", value: { cityName: "CRAVOLANDIA", uf: "BA" } },
  { name: "IRACEMA", value: { cityName: "IRACEMA", uf: "CE" } },
  { name: "CRISOLITA", value: { cityName: "CRISOLITA", uf: "MG" } },
  { name: "CRISTALANDIA", value: { cityName: "CRISTALANDIA", uf: "TO" } },
  { name: "CAMPINAS", value: { cityName: "CAMPINAS", uf: "SP" } },
  {
    name: "CRISTALANDIA DO PIAUI",
    value: { cityName: "CRISTALANDIA DO PIAUI", uf: "PI" },
  },
  { name: "IRAUCUBA", value: { cityName: "IRAUCUBA", uf: "CE" } },
  { name: "CRISTALIA", value: { cityName: "CRISTALIA", uf: "MG" } },
  { name: "CRISTIANOPOLIS", value: { cityName: "CRISTIANOPOLIS", uf: "GO" } },
  { name: "CRISTINAPOLIS", value: { cityName: "CRISTINAPOLIS", uf: "SE" } },
  { name: "ITAITINGA", value: { cityName: "ITAITINGA", uf: "CE" } },
  { name: "CRISTINO CASTRO", value: { cityName: "CRISTINO CASTRO", uf: "PI" } },
  { name: "ITAPAGE", value: { cityName: "ITAPAGE", uf: "CE" } },
  { name: "CRISTOPOLIS", value: { cityName: "CRISTOPOLIS", uf: "BA" } },
  {
    name: "CRIXAS DO TOCANTINS",
    value: { cityName: "CRIXAS DO TOCANTINS", uf: "TO" },
  },
  { name: "CRUZALTENSE", value: { cityName: "CRUZALTENSE", uf: "RS" } },
  { name: "MAUA", value: { cityName: "MAUA", uf: "SP" } },
  { name: "ITAPIPOCA", value: { cityName: "ITAPIPOCA", uf: "CE" } },
  { name: "ITAPIUNA", value: { cityName: "ITAPIUNA", uf: "CE" } },
  { name: "PARAIPABA", value: { cityName: "PARAIPABA", uf: "CE" } },
  {
    name: "CRUZEIRO DO IGUACU",
    value: { cityName: "CRUZEIRO DO IGUACU", uf: "PR" },
  },
  { name: "ITATIRA", value: { cityName: "ITATIRA", uf: "CE" } },
  { name: "ITAIOPOLIS", value: { cityName: "ITAIOPOLIS", uf: "SC" } },
  { name: "CRUZMALTINA", value: { cityName: "CRUZMALTINA", uf: "PR" } },
  { name: "CUITE", value: { cityName: "CUITE", uf: "PB" } },
  { name: "BAURU", value: { cityName: "BAURU", uf: "SP" } },
  { name: "CUBATAO", value: { cityName: "CUBATAO", uf: "SP" } },
  {
    name: "CUITE DE MAMANGUAPE",
    value: { cityName: "CUITE DE MAMANGUAPE", uf: "PB" },
  },
  { name: "CUITEGI", value: { cityName: "CUITEGI", uf: "PB" } },
  { name: "JAGUARIBE", value: { cityName: "JAGUARIBE", uf: "CE" } },
  { name: "JAGUARUANA", value: { cityName: "JAGUARUANA", uf: "CE" } },
  { name: "CUJUBIM", value: { cityName: "CUJUBIM", uf: "RO" } },
  { name: "CUMARU", value: { cityName: "CUMARU", uf: "PE" } },
  { name: "JARDIM", value: { cityName: "JARDIM", uf: "CE" } },
  { name: "CUMARU DO NORTE", value: { cityName: "CUMARU DO NORTE", uf: "PA" } },
  { name: "CUNHATAI", value: { cityName: "CUNHATAI", uf: "SC" } },
  { name: "CURIONOPOLIS", value: { cityName: "CURIONOPOLIS", uf: "PA" } },
  { name: "CURRAIS", value: { cityName: "CURRAIS", uf: "PI" } },
  { name: "CERRO GRANDE", value: { cityName: "CERRO GRANDE", uf: "RS" } },
  { name: "PARACURU", value: { cityName: "PARACURU", uf: "CE" } },
  { name: "CURRAL DE CIMA", value: { cityName: "CURRAL DE CIMA", uf: "PB" } },
  {
    name: "CURRAL DE DENTRO",
    value: { cityName: "CURRAL DE DENTRO", uf: "MG" },
  },
  { name: "NOVA ESPERANCA", value: { cityName: "NOVA ESPERANCA", uf: "PR" } },
  {
    name: "CURRAL NOVO DO PIAUI",
    value: { cityName: "CURRAL NOVO DO PIAUI", uf: "PI" },
  },
  { name: "CURRAL VELHO", value: { cityName: "CURRAL VELHO", uf: "PB" } },
  { name: "JUCAS", value: { cityName: "JUCAS", uf: "CE" } },
  { name: "CURRALINHO", value: { cityName: "CURRALINHO", uf: "PA" } },
  { name: "CURRALINHOS", value: { cityName: "CURRALINHOS", uf: "PI" } },
  { name: "CURUA", value: { cityName: "CURUA", uf: "PA" } },
  { name: "CURVELANDIA", value: { cityName: "CURVELANDIA", uf: "MT" } },
  { name: "CUTIAS", value: { cityName: "CUTIAS", uf: "AP" } },
  { name: "DAMIAO", value: { cityName: "DAMIAO", uf: "PB" } },
  { name: "DAMOLANDIA", value: { cityName: "DAMOLANDIA", uf: "GO" } },
  { name: "DARCINOPOLIS", value: { cityName: "DARCINOPOLIS", uf: "TO" } },
  { name: "DELTA", value: { cityName: "DELTA", uf: "MG" } },
  { name: "DEMERVAL LOBAO", value: { cityName: "DEMERVAL LOBAO", uf: "PI" } },
  { name: "DENISE", value: { cityName: "DENISE", uf: "MT" } },
  { name: "DEODAPOLIS", value: { cityName: "DEODAPOLIS", uf: "MS" } },
  {
    name: "DEPUTADO IRAPUAN PINHEIRO",
    value: { cityName: "DEPUTADO IRAPUAN PINHEIRO", uf: "CE" },
  },
  {
    name: "DESTERRO DE ENTRE RIOS",
    value: { cityName: "DESTERRO DE ENTRE RIOS", uf: "MG" },
  },
  {
    name: "DESTERRO DO MELO",
    value: { cityName: "DESTERRO DO MELO", uf: "MG" },
  },
  {
    name: "LAVRAS DA MANGABEIRA",
    value: { cityName: "LAVRAS DA MANGABEIRA", uf: "CE" },
  },
  {
    name: "DEZESSEIS DE NOVEMBRO",
    value: { cityName: "DEZESSEIS DE NOVEMBRO", uf: "RS" },
  },
  { name: "NATAL", value: { cityName: "NATAL", uf: "RN" } },
  {
    name: "LIMOEIRO DO NORTE",
    value: { cityName: "LIMOEIRO DO NORTE", uf: "CE" },
  },
  { name: "DIAMANTE DOESTE", value: { cityName: "DIAMANTE DOESTE", uf: "PR" } },
  { name: "GOIANIA", value: { cityName: "GOIANIA", uf: "GO" } },
  { name: "DIAMANTINO", value: { cityName: "DIAMANTINO", uf: "MT" } },
  { name: "DIANOPOLIS", value: { cityName: "DIANOPOLIS", uf: "TO" } },
  { name: "DIAS DAVILA", value: { cityName: "DIAS DAVILA", uf: "BA" } },
  { name: "DIRCE REIS", value: { cityName: "DIRCE REIS", uf: "SP" } },
  {
    name: "DIRCEU ARCOVERDE",
    value: { cityName: "DIRCEU ARCOVERDE", uf: "PI" },
  },
  {
    name: "DIVINO DE SAO LOURENCO",
    value: { cityName: "DIVINO DE SAO LOURENCO", uf: "ES" },
  },
  {
    name: "SAO JOSE DO RIO PRETO",
    value: { cityName: "SAO JOSE DO RIO PRETO", uf: "SP" },
  },
  {
    name: "DIVINOPOLIS DO TOCANTINS",
    value: { cityName: "DIVINOPOLIS DO TOCANTINS", uf: "TO" },
  },
  { name: "PIQUETE", value: { cityName: "PIQUETE", uf: "SP" } },
  { name: "DIVISA ALEGRE", value: { cityName: "DIVISA ALEGRE", uf: "MG" } },
  { name: "MARACANAU", value: { cityName: "MARACANAU", uf: "CE" } },
  {
    name: "SAO VALERIO DO SUL",
    value: { cityName: "SAO VALERIO DO SUL", uf: "RS" },
  },
  {
    name: "DOIS IRMAOS DO BURITI",
    value: { cityName: "DOIS IRMAOS DO BURITI", uf: "MS" },
  },
  { name: "MARANGUAPE", value: { cityName: "MARANGUAPE", uf: "CE" } },
  { name: "ICOARACI", value: { cityName: "ICOARACI", uf: "PA" } },
  {
    name: "DOIS IRMAOS DO TOCANTINS",
    value: { cityName: "DOIS IRMAOS DO TOCANTINS", uf: "TO" },
  },
  { name: "DOIS RIACHOS", value: { cityName: "DOIS RIACHOS", uf: "AL" } },
  { name: "DOLCINOPOLIS", value: { cityName: "DOLCINOPOLIS", uf: "SP" } },
  { name: "DOM AQUINO", value: { cityName: "DOM AQUINO", uf: "MT" } },
  { name: "MARTINOPOLE", value: { cityName: "MARTINOPOLE", uf: "CE" } },
  { name: "MASSAPE", value: { cityName: "MASSAPE", uf: "CE" } },
  { name: "DOM BASILIO", value: { cityName: "DOM BASILIO", uf: "BA" } },
  { name: "DOM BOSCO", value: { cityName: "DOM BOSCO", uf: "MG" } },
  { name: "MAURITI", value: { cityName: "MAURITI", uf: "CE" } },
  { name: "DOM ELISEU", value: { cityName: "DOM ELISEU", uf: "PA" } },
  { name: "VIDEIRA", value: { cityName: "VIDEIRA", uf: "SC" } },
  { name: "MERUOCA", value: { cityName: "MERUOCA", uf: "CE" } },
  {
    name: "DOM EXPEDITO LOPES",
    value: { cityName: "DOM EXPEDITO LOPES", uf: "PI" },
  },
  { name: "MILAGRES", value: { cityName: "MILAGRES", uf: "CE" } },
  { name: "MILHA", value: { cityName: "MILHA", uf: "CE" } },
  { name: "DOM INOCENCIO", value: { cityName: "DOM INOCENCIO", uf: "PI" } },
  { name: "DOMINGOS MOURAO", value: { cityName: "DOMINGOS MOURAO", uf: "PI" } },
  { name: "ERICO CARDOSO", value: { cityName: "ERICO CARDOSO", uf: "BA" } },
  { name: "MIRAIMA", value: { cityName: "MIRAIMA", uf: "CE" } },
  { name: "DONA EUSEBIA", value: { cityName: "DONA EUSEBIA", uf: "MG" } },
  { name: "DORES DE CAMPOS", value: { cityName: "DORES DE CAMPOS", uf: "MG" } },
  { name: "MISSAO VELHA", value: { cityName: "MISSAO VELHA", uf: "CE" } },
  { name: "DORMENTES", value: { cityName: "DORMENTES", uf: "PE" } },
  { name: "DOURADINA", value: { cityName: "DOURADINA", uf: "MS" } },
  { name: "MOMBACA", value: { cityName: "MOMBACA", uf: "CE" } },
  { name: "DOURADOQUARA", value: { cityName: "DOURADOQUARA", uf: "MG" } },
  { name: "DOUTOR RICARDO", value: { cityName: "DOUTOR RICARDO", uf: "RS" } },
  {
    name: "DOUTOR SEVERIANO",
    value: { cityName: "DOUTOR SEVERIANO", uf: "RN" },
  },
  { name: "DOUTOR ULYSSES", value: { cityName: "DOUTOR ULYSSES", uf: "PR" } },
  { name: "DUAS BARRAS", value: { cityName: "DUAS BARRAS", uf: "RJ" } },
  { name: "DUERE", value: { cityName: "DUERE", uf: "TO" } },
  { name: "MORADA NOVA", value: { cityName: "MORADA NOVA", uf: "CE" } },
  { name: "MORAUJO", value: { cityName: "MORAUJO", uf: "CE" } },
  { name: "EDEIA", value: { cityName: "EDEIA", uf: "GO" } },
  { name: "EIRUNEPE", value: { cityName: "EIRUNEPE", uf: "AM" } },
  {
    name: "ELDORADO DOS CARAJAS",
    value: { cityName: "ELDORADO DOS CARAJAS", uf: "PA" },
  },
  { name: "MUCAMBO", value: { cityName: "MUCAMBO", uf: "CE" } },
  { name: "MULUNGU", value: { cityName: "MULUNGU", uf: "CE" } },
  { name: "ELIAS FAUSTO", value: { cityName: "ELIAS FAUSTO", uf: "SP" } },
  { name: "ELISEU MARTINS", value: { cityName: "ELISEU MARTINS", uf: "PI" } },
  { name: "CAMPO MOURAO", value: { cityName: "CAMPO MOURAO", uf: "PR" } },
  { name: "ELISIARIO", value: { cityName: "ELISIARIO", uf: "SP" } },
  { name: "MURITI", value: { cityName: "MURITI", uf: "CE" } },
  { name: "EMAS", value: { cityName: "EMAS", uf: "PB" } },
  { name: "EMBAUBA", value: { cityName: "EMBAUBA", uf: "SP" } },
  { name: "ITAPIRANGA", value: { cityName: "ITAPIRANGA", uf: "SC" } },
  { name: "ENEAS MARQUES", value: { cityName: "ENEAS MARQUES", uf: "PR" } },
  {
    name: "ENGENHEIRO COELHO",
    value: { cityName: "ENGENHEIRO COELHO", uf: "SP" },
  },
  {
    name: "ENGENHEIRO NAVARRO",
    value: { cityName: "ENGENHEIRO NAVARRO", uf: "MG" },
  },
  {
    name: "ENGENHEIRO PAULO DE FRONTIN",
    value: { cityName: "ENGENHEIRO PAULO DE FRONTIN", uf: "RJ" },
  },
  {
    name: "ENTRE RIOS DO OESTE",
    value: { cityName: "ENTRE RIOS DO OESTE", uf: "PR" },
  },
  { name: "NOVA RUSSAS", value: { cityName: "NOVA RUSSAS", uf: "CE" } },
  { name: "ENVIRA", value: { cityName: "ENVIRA", uf: "AM" } },
  { name: "EPITACIOLANDIA", value: { cityName: "EPITACIOLANDIA", uf: "AC" } },
  { name: "NOVO ORIENTE", value: { cityName: "NOVO ORIENTE", uf: "CE" } },
  { name: "EQUADOR", value: { cityName: "EQUADOR", uf: "RN" } },
  { name: "OCARA", value: { cityName: "OCARA", uf: "CE" } },
  { name: "ERERE", value: { cityName: "ERERE", uf: "CE" } },
  { name: "ESPERANCA NOVA", value: { cityName: "ESPERANCA NOVA", uf: "PR" } },
  { name: "PARAMOTI", value: { cityName: "PARAMOTI", uf: "CE" } },
  { name: "ESPERANTINA", value: { cityName: "ESPERANTINA", uf: "PI" } },
  {
    name: "ESPIGAO ALTO DO IGUACU",
    value: { cityName: "ESPIGAO ALTO DO IGUACU", uf: "PR" },
  },
  { name: "ESPIGAO DOESTE", value: { cityName: "ESPIGAO DOESTE", uf: "RO" } },
  {
    name: "ESPIRITO SANTO DO PINHAL",
    value: { cityName: "ESPIRITO SANTO DO PINHAL", uf: "SP" },
  },
  {
    name: "ESPIRITO SANTO DO TURVO",
    value: { cityName: "ESPIRITO SANTO DO TURVO", uf: "SP" },
  },
  { name: "ESTIVA", value: { cityName: "ESTIVA", uf: "MG" } },
  { name: "ESTIVA GERBI", value: { cityName: "ESTIVA GERBI", uf: "SP" } },
  { name: "ESTRELA DALVA", value: { cityName: "ESTRELA DALVA", uf: "MG" } },
  {
    name: "ESTRELA DE ALAGOAS",
    value: { cityName: "ESTRELA DE ALAGOAS", uf: "AL" },
  },
  {
    name: "EUCLIDES DA CUNHA PAULISTA",
    value: { cityName: "EUCLIDES DA CUNHA PAULISTA", uf: "SP" },
  },
  { name: "EUSEBIO", value: { cityName: "EUSEBIO", uf: "CE" } },
  { name: "PEDRA BRANCA", value: { cityName: "PEDRA BRANCA", uf: "CE" } },
  {
    name: "EWBANK DA CAMARA",
    value: { cityName: "EWBANK DA CAMARA", uf: "MG" },
  },
  {
    name: "FARTURA DO PIAUI",
    value: { cityName: "FARTURA DO PIAUI", uf: "PI" },
  },
  { name: "FATIMA DO SUL", value: { cityName: "FATIMA DO SUL", uf: "MS" } },
  { name: "FEIRA DA MATA", value: { cityName: "FEIRA DA MATA", uf: "BA" } },
  { name: "FEIRA GRANDE", value: { cityName: "FEIRA GRANDE", uf: "AL" } },
  { name: "PENAFORTE", value: { cityName: "PENAFORTE", uf: "CE" } },
  { name: "PENTECOSTE", value: { cityName: "PENTECOSTE", uf: "CE" } },
  { name: "PEREIRO", value: { cityName: "PEREIRO", uf: "CE" } },
  { name: "FEIRA NOVA", value: { cityName: "FEIRA NOVA", uf: "PE" } },
  {
    name: "FEIRA NOVA DO MARANHAO",
    value: { cityName: "FEIRA NOVA DO MARANHAO", uf: "MA" },
  },
  { name: "PINDORETAMA", value: { cityName: "PINDORETAMA", uf: "CE" } },
  { name: "FELIPE GUERRA", value: { cityName: "FELIPE GUERRA", uf: "RN" } },
  { name: "PIQUET CARNEIRO", value: { cityName: "PIQUET CARNEIRO", uf: "CE" } },
  { name: "FELIZ DESERTO", value: { cityName: "FELIZ DESERTO", uf: "AL" } },
  { name: "FELIZ NATAL", value: { cityName: "FELIZ NATAL", uf: "MT" } },
  { name: "PIRES FERREIRA", value: { cityName: "PIRES FERREIRA", uf: "CE" } },
  { name: "FENIX", value: { cityName: "FENIX", uf: "PR" } },
  { name: "PACATUBA", value: { cityName: "PACATUBA", uf: "CE" } },
  {
    name: "FERNANDO DE NORONHA",
    value: { cityName: "FERNANDO DE NORONHA", uf: "PE" },
  },
  { name: "FERNANDO FALCAO", value: { cityName: "FERNANDO FALCAO", uf: "MA" } },
  {
    name: "FERNANDO PEDROZA",
    value: { cityName: "FERNANDO PEDROZA", uf: "RN" },
  },
  { name: "FERNAO", value: { cityName: "FERNAO", uf: "SP" } },
  { name: "FERREIRA GOMES", value: { cityName: "FERREIRA GOMES", uf: "AP" } },
  { name: "PORANGA", value: { cityName: "PORANGA", uf: "CE" } },
  { name: "FERREIROS", value: { cityName: "FERREIROS", uf: "PE" } },
  { name: "PORTEIRAS", value: { cityName: "PORTEIRAS", uf: "CE" } },
  { name: "POTENGI", value: { cityName: "POTENGI", uf: "CE" } },
  { name: "FERVEDOURO", value: { cityName: "FERVEDOURO", uf: "MG" } },
  { name: "FIGUEIRA", value: { cityName: "FIGUEIRA", uf: "PR" } },
  { name: "FIGUEIRAO", value: { cityName: "FIGUEIRAO", uf: "MS" } },
  { name: "FIGUEIROPOLIS", value: { cityName: "FIGUEIROPOLIS", uf: "TO" } },
  {
    name: "FIGUEIROPOLIS DOESTE",
    value: { cityName: "FIGUEIROPOLIS DOESTE", uf: "MT" },
  },
  {
    name: "FLOR DA SERRA DO SUL",
    value: { cityName: "FLOR DA SERRA DO SUL", uf: "PR" },
  },
  { name: "FLORES DE GOIAS", value: { cityName: "FLORES DE GOIAS", uf: "GO" } },
  {
    name: "FLORESTA DO ARAGUAIA",
    value: { cityName: "FLORESTA DO ARAGUAIA", uf: "PA" },
  },
  {
    name: "FLORESTA DO PIAUI",
    value: { cityName: "FLORESTA DO PIAUI", uf: "PI" },
  },
  { name: "QUITERIANOPOLIS", value: { cityName: "QUITERIANOPOLIS", uf: "CE" } },
  { name: "QUIXADA", value: { cityName: "QUIXADA", uf: "CE" } },
  { name: "FLORESTOPOLIS", value: { cityName: "FLORESTOPOLIS", uf: "PR" } },
  { name: "FLORIDA", value: { cityName: "FLORIDA", uf: "PR" } },
  { name: "QUIXERAMOBIM", value: { cityName: "QUIXERAMOBIM", uf: "CE" } },
  { name: "QUIXERE", value: { cityName: "QUIXERE", uf: "CE" } },
  { name: "FLORINEA", value: { cityName: "FLORINEA", uf: "SP" } },
  { name: "REDENCAO", value: { cityName: "REDENCAO", uf: "CE" } },
  { name: "RERIUTABA", value: { cityName: "RERIUTABA", uf: "CE" } },
  { name: "FONTE NOVA", value: { cityName: "FONTE NOVA", uf: "AM" } },
  {
    name: "FORMOSA DA SERRA NEGRA",
    value: { cityName: "FORMOSA DA SERRA NEGRA", uf: "MA" },
  },
  {
    name: "FORMOSA DO RIO PRETO",
    value: { cityName: "FORMOSA DO RIO PRETO", uf: "BA" },
  },
  {
    name: "FORMOSO DO ARAGUAIA",
    value: { cityName: "FORMOSO DO ARAGUAIA", uf: "TO" },
  },
  { name: "RUSSAS", value: { cityName: "RUSSAS", uf: "CE" } },
  {
    name: "FORTALEZA DO TABOCAO",
    value: { cityName: "FORTALEZA DO TABOCAO", uf: "TO" },
  },
  { name: "SABOEIRO", value: { cityName: "SABOEIRO", uf: "CE" } },
  {
    name: "FORTALEZA DOS NOGUEIRAS",
    value: { cityName: "FORTALEZA DOS NOGUEIRAS", uf: "MA" },
  },
  { name: "SALITRE", value: { cityName: "SALITRE", uf: "CE" } },
  {
    name: "FORTUNA DE MINAS",
    value: { cityName: "FORTUNA DE MINAS", uf: "MG" },
  },
  { name: "FRANCISCO ALVES", value: { cityName: "FRANCISCO ALVES", uf: "PR" } },
  { name: "SANTA QUITERIA", value: { cityName: "SANTA QUITERIA", uf: "CE" } },
  {
    name: "FRANCISCO BADARO",
    value: { cityName: "FRANCISCO BADARO", uf: "MG" },
  },
  {
    name: "FRANCISCO DANTAS",
    value: { cityName: "FRANCISCO DANTAS", uf: "RN" },
  },
  {
    name: "FRANCISCO MACEDO",
    value: { cityName: "FRANCISCO MACEDO", uf: "PI" },
  },
  {
    name: "SANTANA DO ACARAU",
    value: { cityName: "SANTANA DO ACARAU", uf: "CE" },
  },
  {
    name: "SANTANA DO CARIRI",
    value: { cityName: "SANTANA DO CARIRI", uf: "CE" },
  },
  { name: "FRANCISCOPOLIS", value: { cityName: "FRANCISCOPOLIS", uf: "MG" } },
  { name: "FRECHEIRINHA", value: { cityName: "FRECHEIRINHA", uf: "CE" } },
  { name: "FREI LAGONEGRO", value: { cityName: "FREI LAGONEGRO", uf: "MG" } },
  { name: "FRUTA DE LEITE", value: { cityName: "FRUTA DE LEITE", uf: "MG" } },
  { name: "SAO BENEDITO", value: { cityName: "SAO BENEDITO", uf: "CE" } },
  { name: "FRUTUOSO GOMES", value: { cityName: "FRUTUOSO GOMES", uf: "RN" } },
  { name: "GADO BRAVO", value: { cityName: "GADO BRAVO", uf: "PB" } },
  { name: "GALINHOS", value: { cityName: "GALINHOS", uf: "RN" } },
  {
    name: "GAMELEIRA DE GOIAS",
    value: { cityName: "GAMELEIRA DE GOIAS", uf: "GO" },
  },
  {
    name: "SAO GONCALO DO AMARANTE",
    value: { cityName: "SAO GONCALO DO AMARANTE", uf: "CE" },
  },
  { name: "GAMELEIRAS", value: { cityName: "GAMELEIRAS", uf: "MG" } },
  {
    name: "SAO JOAO DO JAGUARIBE",
    value: { cityName: "SAO JOAO DO JAGUARIBE", uf: "CE" },
  },
  { name: "GASTAO VIDIGAL", value: { cityName: "GASTAO VIDIGAL", uf: "SP" } },
  { name: "GAUCHA DO NORTE", value: { cityName: "GAUCHA DO NORTE", uf: "MT" } },
  { name: "GAVIAO PEIXOTO", value: { cityName: "GAVIAO PEIXOTO", uf: "SP" } },
  { name: "BLUMENAU", value: { cityName: "BLUMENAU", uf: "SC" } },
  { name: "GEMINIANO", value: { cityName: "GEMINIANO", uf: "PI" } },
  { name: "GENERAL MAYNARD", value: { cityName: "GENERAL MAYNARD", uf: "SE" } },
  {
    name: "GIRAU DO PONCIANO",
    value: { cityName: "GIRAU DO PONCIANO", uf: "AL" },
  },
  { name: "GLAUCILANDIA", value: { cityName: "GLAUCILANDIA", uf: "MG" } },
  {
    name: "GLORIA DE DOURADOS",
    value: { cityName: "GLORIA DE DOURADOS", uf: "MS" },
  },
  { name: "GLORIA DOESTE", value: { cityName: "GLORIA DOESTE", uf: "MT" } },
  { name: "GODOFREDO VIANA", value: { cityName: "GODOFREDO VIANA", uf: "MA" } },
  { name: "GODOY MOREIRA", value: { cityName: "GODOY MOREIRA", uf: "PR" } },
  { name: "GOIABEIRA", value: { cityName: "GOIABEIRA", uf: "MG" } },
  { name: "GOIANDIRA", value: { cityName: "GOIANDIRA", uf: "GO" } },
  {
    name: "GOIANESIA DO PARA",
    value: { cityName: "GOIANESIA DO PARA", uf: "PA" },
  },
  { name: "PACAJUS", value: { cityName: "PACAJUS", uf: "CE" } },
  { name: "GOIANORTE", value: { cityName: "GOIANORTE", uf: "TO" } },
  { name: "GOIATINS", value: { cityName: "GOIATINS", uf: "TO" } },
  { name: "SENADOR POMPEU", value: { cityName: "SENADOR POMPEU", uf: "CE" } },
  { name: "SENADOR SA", value: { cityName: "SENADOR SA", uf: "CE" } },
  { name: "GONCALVES DIAS", value: { cityName: "GONCALVES DIAS", uf: "MA" } },
  { name: "GOUVEIA", value: { cityName: "GOUVEIA", uf: "MG" } },
  {
    name: "GOVERNADOR DIX-SEPT ROSADO",
    value: { cityName: "GOVERNADOR DIX-SEPT ROSADO", uf: "RN" },
  },
  {
    name: "GOVERNADOR EDISON LOBAO",
    value: { cityName: "GOVERNADOR EDISON LOBAO", uf: "MA" },
  },
  {
    name: "GOVERNADOR EUGENIO BARROS",
    value: { cityName: "GOVERNADOR EUGENIO BARROS", uf: "MA" },
  },
  {
    name: "GOVERNADOR JORGE TEIXEIRA",
    value: { cityName: "GOVERNADOR JORGE TEIXEIRA", uf: "RO" },
  },
  {
    name: "GOVERNADOR LINDENBERG",
    value: { cityName: "GOVERNADOR LINDENBERG", uf: "ES" },
  },
  {
    name: "GOVERNADOR LOMANTO JUNIOR",
    value: { cityName: "GOVERNADOR LOMANTO JUNIOR", uf: "BA" },
  },
  {
    name: "GOVERNADOR LUIZ ROCHA",
    value: { cityName: "GOVERNADOR LUIZ ROCHA", uf: "MA" },
  },
  {
    name: "GOVERNADOR MANGABEIRA",
    value: { cityName: "GOVERNADOR MANGABEIRA", uf: "BA" },
  },
  { name: "SOLONOPOLE", value: { cityName: "SOLONOPOLE", uf: "CE" } },
  {
    name: "GOVERNADOR NEWTON BELLO",
    value: { cityName: "GOVERNADOR NEWTON BELLO", uf: "MA" },
  },
  {
    name: "GOVERNADOR NUNES FREIRE",
    value: { cityName: "GOVERNADOR NUNES FREIRE", uf: "MA" },
  },
  { name: "GRACA ARANHA", value: { cityName: "GRACA ARANHA", uf: "MA" } },
  { name: "GRACCHO CARDOSO", value: { cityName: "GRACCHO CARDOSO", uf: "SE" } },
  { name: "GRANDES RIOS", value: { cityName: "GRANDES RIOS", uf: "PR" } },
  {
    name: "TABULEIRO DO NORTE",
    value: { cityName: "TABULEIRO DO NORTE", uf: "CE" },
  },
  { name: "GRANITO", value: { cityName: "GRANITO", uf: "PE" } },
  { name: "TAMBORIL", value: { cityName: "TAMBORIL", uf: "CE" } },
  { name: "GRUPIARA", value: { cityName: "GRUPIARA", uf: "MG" } },
  { name: "GUADALUPE", value: { cityName: "GUADALUPE", uf: "PI" } },
  { name: "GUAIRACA", value: { cityName: "GUAIRACA", uf: "PR" } },
  { name: "GUAJARA", value: { cityName: "GUAJARA", uf: "AM" } },
  { name: "GUAJARA-MIRIM", value: { cityName: "GUAJARA-MIRIM", uf: "RO" } },
  { name: "TAUA", value: { cityName: "TAUA", uf: "CE" } },
  { name: "GUAMIRANGA", value: { cityName: "GUAMIRANGA", uf: "PR" } },
  { name: "TIANGUA", value: { cityName: "TIANGUA", uf: "CE" } },
  { name: "GUAPIRAMA", value: { cityName: "GUAPIRAMA", uf: "PR" } },
  { name: "GUAPOREMA", value: { cityName: "GUAPOREMA", uf: "PR" } },
  { name: "GUARACIAMA", value: { cityName: "GUARACIAMA", uf: "MG" } },
  {
    name: "GUARANI DE GOIAS",
    value: { cityName: "GUARANI DE GOIAS", uf: "GO" },
  },
  { name: "GUARANI D OESTE", value: { cityName: "GUARANI D OESTE", uf: "SP" } },
  { name: "PALMACIA", value: { cityName: "PALMACIA", uf: "CE" } },
  {
    name: "GUARANTA DO NORTE",
    value: { cityName: "GUARANTA DO NORTE", uf: "MT" },
  },
  { name: "TURURU", value: { cityName: "TURURU", uf: "CE" } },
  { name: "UBAJARA", value: { cityName: "UBAJARA", uf: "CE" } },
  { name: "GUARDA-MOR", value: { cityName: "GUARDA-MOR", uf: "MG" } },
  { name: "GUARIBAS", value: { cityName: "GUARIBAS", uf: "PI" } },
  { name: "UMARI", value: { cityName: "UMARI", uf: "CE" } },
  { name: "GUATAPARA", value: { cityName: "GUATAPARA", uf: "SP" } },
  {
    name: "GUIA LOPES DA LAGUNA",
    value: { cityName: "GUIA LOPES DA LAGUNA", uf: "MS" },
  },
  { name: "UMIRIM", value: { cityName: "UMIRIM", uf: "CE" } },
  { name: "URUBURETAMA", value: { cityName: "URUBURETAMA", uf: "CE" } },
  { name: "URUOCA", value: { cityName: "URUOCA", uf: "CE" } },
  { name: "GUIMARAES", value: { cityName: "GUIMARAES", uf: "MA" } },
  { name: "GUIRATINGA", value: { cityName: "GUIRATINGA", uf: "MT" } },
  { name: "GURJAO", value: { cityName: "GURJAO", uf: "PB" } },
  { name: "VARZEA ALEGRE", value: { cityName: "VARZEA ALEGRE", uf: "CE" } },
  { name: "GURUPI", value: { cityName: "GURUPI", uf: "TO" } },
  { name: "GUZOLANDIA", value: { cityName: "GUZOLANDIA", uf: "SP" } },
  { name: "HEITORAI", value: { cityName: "HEITORAI", uf: "GO" } },
  { name: "HELIODORA", value: { cityName: "HELIODORA", uf: "MG" } },
  { name: "HUGO NAPOLEAO", value: { cityName: "HUGO NAPOLEAO", uf: "PI" } },
  { name: "ABDON BATISTA", value: { cityName: "ABDON BATISTA", uf: "SC" } },
  { name: "AGROLANDIA", value: { cityName: "AGROLANDIA", uf: "SC" } },
  { name: "AGUA DOCE", value: { cityName: "AGUA DOCE", uf: "SC" } },
  {
    name: "HUMBERTO DE CAMPOS",
    value: { cityName: "HUMBERTO DE CAMPOS", uf: "MA" },
  },
  {
    name: "AGUAS DE CHAPECO",
    value: { cityName: "AGUAS DE CHAPECO", uf: "SC" },
  },
  { name: "AGUAS FRIAS", value: { cityName: "AGUAS FRIAS", uf: "SC" } },
  { name: "AGUAS MORNAS", value: { cityName: "AGUAS MORNAS", uf: "SC" } },
  { name: "IARAS", value: { cityName: "IARAS", uf: "SP" } },
  { name: "IBAITI", value: { cityName: "IBAITI", uf: "PR" } },
  { name: "ALFREDO WAGNER", value: { cityName: "ALFREDO WAGNER", uf: "SC" } },
  { name: "IBARETAMA", value: { cityName: "IBARETAMA", uf: "CE" } },
  { name: "ANCHIETA", value: { cityName: "ANCHIETA", uf: "SC" } },
  { name: "ANGELINA", value: { cityName: "ANGELINA", uf: "SC" } },
  { name: "ANITA GARIBALDI", value: { cityName: "ANITA GARIBALDI", uf: "SC" } },
  { name: "ANITAPOLIS", value: { cityName: "ANITAPOLIS", uf: "SC" } },
  { name: "IBIAM", value: { cityName: "IBIAM", uf: "SC" } },
  { name: "ANTONIO CARLOS", value: { cityName: "ANTONIO CARLOS", uf: "SC" } },
  { name: "APIUNA", value: { cityName: "APIUNA", uf: "SC" } },
  { name: "ARABUTA", value: { cityName: "ARABUTA", uf: "SC" } },
  { name: "ARAQUARI", value: { cityName: "ARAQUARI", uf: "SC" } },
  { name: "ARMAZEM", value: { cityName: "ARMAZEM", uf: "SC" } },
  { name: "ARROIO TRINTA", value: { cityName: "ARROIO TRINTA", uf: "SC" } },
  { name: "ARVOREDO", value: { cityName: "ARVOREDO", uf: "SC" } },
  { name: "ASCURRA", value: { cityName: "ASCURRA", uf: "SC" } },
  { name: "ATALANTA", value: { cityName: "ATALANTA", uf: "SC" } },
  { name: "IBIASSUCE", value: { cityName: "IBIASSUCE", uf: "BA" } },
  { name: "IBIRACATU", value: { cityName: "IBIRACATU", uf: "MG" } },
  { name: "IBIRAJUBA", value: { cityName: "IBIRAJUBA", uf: "PE" } },
  {
    name: "BALNEARIO BARRA DO SUL",
    value: { cityName: "BALNEARIO BARRA DO SUL", uf: "SC" },
  },
  {
    name: "BALNEARIO CAMBORIU",
    value: { cityName: "BALNEARIO CAMBORIU", uf: "SC" },
  },
  { name: "JARAGUA DO SUL", value: { cityName: "JARAGUA DO SUL", uf: "SC" } },
  { name: "JOINVILLE", value: { cityName: "JOINVILLE", uf: "SC" } },
  { name: "ICARAI DE MINAS", value: { cityName: "ICARAI DE MINAS", uf: "MG" } },
  { name: "IGACI", value: { cityName: "IGACI", uf: "AL" } },
  { name: "BARRA VELHA", value: { cityName: "BARRA VELHA", uf: "SC" } },
  { name: "IGARACY", value: { cityName: "IGARACY", uf: "PB" } },
  { name: "IGARAPE DO MEIO", value: { cityName: "IGARAPE DO MEIO", uf: "MA" } },
  { name: "BELMONTE", value: { cityName: "BELMONTE", uf: "SC" } },
  { name: "BENEDITO NOVO", value: { cityName: "BENEDITO NOVO", uf: "SC" } },
  { name: "IGARAPE-ACU", value: { cityName: "IGARAPE-ACU", uf: "PA" } },
  {
    name: "BOM JARDIM DA SERRA",
    value: { cityName: "BOM JARDIM DA SERRA", uf: "SC" },
  },
  {
    name: "BOM JESUS DO OESTE",
    value: { cityName: "BOM JESUS DO OESTE", uf: "SC" },
  },
  { name: "BOM RETIRO", value: { cityName: "BOM RETIRO", uf: "SC" } },
  { name: "BOMBINHAS", value: { cityName: "BOMBINHAS", uf: "SC" } },
  { name: "BOTUVERA", value: { cityName: "BOTUVERA", uf: "SC" } },
  { name: "BRACO DO NORTE", value: { cityName: "BRACO DO NORTE", uf: "SC" } },
  {
    name: "BRACO DO TROMBUDO",
    value: { cityName: "BRACO DO TROMBUDO", uf: "SC" },
  },
  { name: "CACADOR", value: { cityName: "CACADOR", uf: "SC" } },
  { name: "CAIBI", value: { cityName: "CAIBI", uf: "SC" } },
  { name: "CALMON", value: { cityName: "CALMON", uf: "SC" } },
  { name: "IGRAPIUNA", value: { cityName: "IGRAPIUNA", uf: "BA" } },
  { name: "CAMPO ALEGRE", value: { cityName: "CAMPO ALEGRE", uf: "SC" } },
  { name: "CAMPO ERE", value: { cityName: "CAMPO ERE", uf: "SC" } },
  { name: "CAMPOS NOVOS", value: { cityName: "CAMPOS NOVOS", uf: "SC" } },
  { name: "IJACI", value: { cityName: "IJACI", uf: "MG" } },
  { name: "CANELINHA", value: { cityName: "CANELINHA", uf: "SC" } },
  { name: "CANOINHAS", value: { cityName: "CANOINHAS", uf: "SC" } },
  { name: "CAPINZAL", value: { cityName: "CAPINZAL", uf: "SC" } },
  {
    name: "CAPIVARI DE BAIXO",
    value: { cityName: "CAPIVARI DE BAIXO", uf: "SC" },
  },
  {
    name: "ILHA DE ITAMARACA",
    value: { cityName: "ILHA DE ITAMARACA", uf: "PE" },
  },
  { name: "CATANDUVAS", value: { cityName: "CATANDUVAS", uf: "PR" } },
  { name: "CAXAMBU DO SUL", value: { cityName: "CAXAMBU DO SUL", uf: "SC" } },
  { name: "ILHA GRANDE", value: { cityName: "ILHA GRANDE", uf: "PI" } },
  { name: "CELSO RAMOS", value: { cityName: "CELSO RAMOS", uf: "SC" } },
  { name: "CERRO NEGRO", value: { cityName: "CERRO NEGRO", uf: "SC" } },
  { name: "IMACULADA", value: { cityName: "IMACULADA", uf: "PB" } },
  { name: "COCAL DO SUL", value: { cityName: "COCAL DO SUL", uf: "SC" } },
  { name: "IMBAU", value: { cityName: "IMBAU", uf: "PR" } },
  { name: "CONCORDIA", value: { cityName: "CONCORDIA", uf: "SC" } },
  {
    name: "CORDILHEIRA ALTA",
    value: { cityName: "CORDILHEIRA ALTA", uf: "SC" },
  },
  { name: "CORONEL FREITAS", value: { cityName: "CORONEL FREITAS", uf: "SC" } },
  { name: "CORONEL MARTINS", value: { cityName: "CORONEL MARTINS", uf: "SC" } },
  { name: "CORREIA PINTO", value: { cityName: "CORREIA PINTO", uf: "SC" } },
  { name: "CORUPA", value: { cityName: "CORUPA", uf: "SC" } },
  { name: "CUNHA PORA", value: { cityName: "CUNHA PORA", uf: "SC" } },
  { name: "IMBE DE MINAS", value: { cityName: "IMBE DE MINAS", uf: "MG" } },
  { name: "DESCANSO", value: { cityName: "DESCANSO", uf: "SC" } },
  {
    name: "DIONISIO CERQUEIRA",
    value: { cityName: "DIONISIO CERQUEIRA", uf: "SC" },
  },
  { name: "INACIO MARTINS", value: { cityName: "INACIO MARTINS", uf: "PR" } },
  { name: "DONA EMMA", value: { cityName: "DONA EMMA", uf: "SC" } },
  { name: "DOUTOR PEDRINHO", value: { cityName: "DOUTOR PEDRINHO", uf: "SC" } },
  { name: "INCONFIDENTES", value: { cityName: "INCONFIDENTES", uf: "MG" } },
  { name: "ERVAL VELHO", value: { cityName: "ERVAL VELHO", uf: "SC" } },
  { name: "INDAIABIRA", value: { cityName: "INDAIABIRA", uf: "MG" } },
  {
    name: "FAXINAL DOS GUEDES",
    value: { cityName: "FAXINAL DOS GUEDES", uf: "SC" },
  },
  { name: "INDIAPORA", value: { cityName: "INDIAPORA", uf: "SP" } },
  { name: "FORMOSA DO SUL", value: { cityName: "FORMOSA DO SUL", uf: "SC" } },
  { name: "FORQUILHINHA", value: { cityName: "FORQUILHINHA", uf: "SC" } },
  { name: "FRAIBURGO", value: { cityName: "FRAIBURGO", uf: "SC" } },
  { name: "GALVAO", value: { cityName: "GALVAO", uf: "SC" } },
  { name: "INDIAVAI", value: { cityName: "INDIAVAI", uf: "MT" } },
  { name: "GARUVA", value: { cityName: "GARUVA", uf: "SC" } },
  {
    name: "GOVERNADOR CELSO RAMOS",
    value: { cityName: "GOVERNADOR CELSO RAMOS", uf: "SC" },
  },
  { name: "GRAO PARA", value: { cityName: "GRAO PARA", uf: "SC" } },
  { name: "GRAVATAL", value: { cityName: "GRAVATAL", uf: "SC" } },
  { name: "GUABIRUBA", value: { cityName: "GUABIRUBA", uf: "SC" } },
  { name: "INGA", value: { cityName: "INGA", uf: "PB" } },
  { name: "GUARACIABA", value: { cityName: "GUARACIABA", uf: "SC" } },
  { name: "GUARAMIRIM", value: { cityName: "GUARAMIRIM", uf: "SC" } },
  { name: "GUARUJA DO SUL", value: { cityName: "GUARUJA DO SUL", uf: "SC" } },
  { name: "GUATAMBU", value: { cityName: "GUATAMBU", uf: "SC" } },
  { name: "INGAZEIRA", value: { cityName: "INGAZEIRA", uf: "PE" } },
  { name: "INHANGAPI", value: { cityName: "INHANGAPI", uf: "PA" } },
  { name: "INHAPI", value: { cityName: "INHAPI", uf: "AL" } },
  { name: "IBICARE", value: { cityName: "IBICARE", uf: "SC" } },
  { name: "IBIRAMA", value: { cityName: "IBIRAMA", uf: "SC" } },
  { name: "ILHOTA", value: { cityName: "ILHOTA", uf: "SC" } },
  { name: "IMARUI", value: { cityName: "IMARUI", uf: "SC" } },
  { name: "IMBITUBA", value: { cityName: "IMBITUBA", uf: "SC" } },
  { name: "IMBUIA", value: { cityName: "IMBUIA", uf: "SC" } },
  { name: "INDAIAL", value: { cityName: "INDAIAL", uf: "SC" } },
  { name: "INHUMA", value: { cityName: "INHUMA", uf: "PI" } },
  { name: "INOCENCIA", value: { cityName: "INOCENCIA", uf: "MS" } },
  { name: "IPIRA", value: { cityName: "IPIRA", uf: "SC" } },
  { name: "INUBIA PAULISTA", value: { cityName: "INUBIA PAULISTA", uf: "SP" } },
  { name: "IPORA DO OESTE", value: { cityName: "IPORA DO OESTE", uf: "SC" } },
  { name: "IPUACU", value: { cityName: "IPUACU", uf: "SC" } },
  { name: "IPUMIRIM", value: { cityName: "IPUMIRIM", uf: "SC" } },
  { name: "IRACEMINHA", value: { cityName: "IRACEMINHA", uf: "SC" } },
  { name: "IOMERE", value: { cityName: "IOMERE", uf: "SC" } },
  { name: "IRANI", value: { cityName: "IRANI", uf: "SC" } },
  { name: "IPECAETA", value: { cityName: "IPECAETA", uf: "BA" } },
  { name: "IRATI", value: { cityName: "IRATI", uf: "PR" } },
  { name: "IRINEOPOLIS", value: { cityName: "IRINEOPOLIS", uf: "SC" } },
  { name: "ITA", value: { cityName: "ITA", uf: "SC" } },
  { name: "ITAJAI", value: { cityName: "ITAJAI", uf: "SC" } },
  { name: "IPIGUA", value: { cityName: "IPIGUA", uf: "SP" } },
  { name: "ITAPOA", value: { cityName: "ITAPOA", uf: "SC" } },
  { name: "IPIRANGA", value: { cityName: "IPIRANGA", uf: "PR" } },
  { name: "ITUPORANGA", value: { cityName: "ITUPORANGA", uf: "SC" } },
  { name: "JABORA", value: { cityName: "JABORA", uf: "SC" } },
  { name: "JACINTO MACHADO", value: { cityName: "JACINTO MACHADO", uf: "SC" } },
  { name: "JAGUARUNA", value: { cityName: "JAGUARUNA", uf: "SC" } },
  { name: "JARDINOPOLIS", value: { cityName: "JARDINOPOLIS", uf: "SC" } },
  { name: "JOSE BOITEUX", value: { cityName: "JOSE BOITEUX", uf: "SC" } },
  { name: "LACERDOPOLIS", value: { cityName: "LACERDOPOLIS", uf: "SC" } },
  {
    name: "IPIRANGA DE GOIAS",
    value: { cityName: "IPIRANGA DE GOIAS", uf: "GO" },
  },
  { name: "LAJEADO GRANDE", value: { cityName: "LAJEADO GRANDE", uf: "SC" } },
  { name: "LAURENTINO", value: { cityName: "LAURENTINO", uf: "SC" } },
  { name: "LAURO MULLER", value: { cityName: "LAURO MULLER", uf: "SC" } },
  {
    name: "IPIRANGA DO NORTE",
    value: { cityName: "IPIRANGA DO NORTE", uf: "MT" },
  },
  { name: "LEBON REGIS", value: { cityName: "LEBON REGIS", uf: "SC" } },
  { name: "LEOBERTO LEAL", value: { cityName: "LEOBERTO LEAL", uf: "SC" } },
  { name: "LINDOIA DO SUL", value: { cityName: "LINDOIA DO SUL", uf: "SC" } },
  {
    name: "IPIRANGA DO PIAUI",
    value: { cityName: "IPIRANGA DO PIAUI", uf: "PI" },
  },
  { name: "LONTRAS", value: { cityName: "LONTRAS", uf: "SC" } },
  { name: "IPIXUNA", value: { cityName: "IPIXUNA", uf: "AM" } },
  { name: "LUIZ ALVES", value: { cityName: "LUIZ ALVES", uf: "SC" } },
  { name: "MACIEIRA", value: { cityName: "MACIEIRA", uf: "SC" } },
  { name: "MAJOR GERCINO", value: { cityName: "MAJOR GERCINO", uf: "SC" } },
  { name: "MAJOR VIEIRA", value: { cityName: "MAJOR VIEIRA", uf: "SC" } },
  { name: "MARACAJA", value: { cityName: "MARACAJA", uf: "SC" } },
  { name: "IPIXUNA DO PARA", value: { cityName: "IPIXUNA DO PARA", uf: "PA" } },
  { name: "IPORANGA", value: { cityName: "IPORANGA", uf: "SP" } },
  { name: "MARAVILHA", value: { cityName: "MARAVILHA", uf: "SC" } },
  { name: "IPUBI", value: { cityName: "IPUBI", uf: "PE" } },
  { name: "MAREMA", value: { cityName: "MAREMA", uf: "SC" } },
  { name: "IPUEIRA", value: { cityName: "IPUEIRA", uf: "RN" } },
  { name: "MASSARANDUBA", value: { cityName: "MASSARANDUBA", uf: "SC" } },
  { name: "MATOS COSTA", value: { cityName: "MATOS COSTA", uf: "SC" } },
  { name: "MELEIRO", value: { cityName: "MELEIRO", uf: "SC" } },
  {
    name: "IRACEMA DO OESTE",
    value: { cityName: "IRACEMA DO OESTE", uf: "PR" },
  },
  { name: "IRANDUBA", value: { cityName: "IRANDUBA", uf: "AM" } },
  { name: "MIRIM DOCE", value: { cityName: "MIRIM DOCE", uf: "SC" } },
  { name: "MONTE CARLO", value: { cityName: "MONTE CARLO", uf: "SC" } },
  { name: "MONTE CASTELO", value: { cityName: "MONTE CASTELO", uf: "SC" } },
  { name: "MORRO DA FUMACA", value: { cityName: "MORRO DA FUMACA", uf: "SC" } },
  { name: "IRETAMA", value: { cityName: "IRETAMA", uf: "PR" } },
  { name: "MORRO GRANDE", value: { cityName: "MORRO GRANDE", uf: "SC" } },
  { name: "IRITUIA", value: { cityName: "IRITUIA", uf: "PA" } },
  { name: "ISAIAS COELHO", value: { cityName: "ISAIAS COELHO", uf: "PI" } },
  { name: "NOVA ERECHIM", value: { cityName: "NOVA ERECHIM", uf: "SC" } },
  { name: "NOVA ITABERABA", value: { cityName: "NOVA ITABERABA", uf: "SC" } },
  { name: "ISRAELANDIA", value: { cityName: "ISRAELANDIA", uf: "GO" } },
  { name: "ITABELA", value: { cityName: "ITABELA", uf: "BA" } },
  { name: "NOVA TRENTO", value: { cityName: "NOVA TRENTO", uf: "SC" } },
  { name: "NOVA VENEZA", value: { cityName: "NOVA VENEZA", uf: "SC" } },
  { name: "NOVO HORIZONTE", value: { cityName: "NOVO HORIZONTE", uf: "SC" } },
  { name: "ORLEANS", value: { cityName: "ORLEANS", uf: "SC" } },
  { name: "OTACILIO COSTA", value: { cityName: "OTACILIO COSTA", uf: "SC" } },
  { name: "OURO", value: { cityName: "OURO", uf: "SC" } },
  { name: "PALHOCA", value: { cityName: "PALHOCA", uf: "SC" } },
  { name: "PAPANDUVA", value: { cityName: "PAPANDUVA", uf: "SC" } },
  { name: "PARAISO", value: { cityName: "PARAISO", uf: "SC" } },
  { name: "PASSO DE TORRES", value: { cityName: "PASSO DE TORRES", uf: "SC" } },
  { name: "ITABIRINHA", value: { cityName: "ITABIRINHA", uf: "MG" } },
  { name: "PASSOS MAIA", value: { cityName: "PASSOS MAIA", uf: "SC" } },
  { name: "ITACAJA", value: { cityName: "ITACAJA", uf: "TO" } },
  { name: "PAULO LOPES", value: { cityName: "PAULO LOPES", uf: "SC" } },
  { name: "PEDRAS GRANDES", value: { cityName: "PEDRAS GRANDES", uf: "SC" } },
  { name: "PENHA", value: { cityName: "PENHA", uf: "SC" } },
  { name: "PERITIBA", value: { cityName: "PERITIBA", uf: "SC" } },
  {
    name: "PESCARIA BRAVA (LAGUNA)",
    value: { cityName: "PESCARIA BRAVA (LAGUNA)", uf: "SC" },
  },
  { name: "PETROLANDIA", value: { cityName: "PETROLANDIA", uf: "SC" } },
  { name: "PICARRAS", value: { cityName: "PICARRAS", uf: "SC" } },
  { name: "PINHALZINHO", value: { cityName: "PINHALZINHO", uf: "SC" } },
  { name: "ITACOATIARA", value: { cityName: "ITACOATIARA", uf: "AM" } },
  { name: "PINHEIRO PRETO", value: { cityName: "PINHEIRO PRETO", uf: "SC" } },
  { name: "ITAETE", value: { cityName: "ITAETE", uf: "BA" } },
  { name: "PIRATUBA", value: { cityName: "PIRATUBA", uf: "SC" } },
  { name: "PLANALTO ALEGRE", value: { cityName: "PLANALTO ALEGRE", uf: "SC" } },
  { name: "POMERODE", value: { cityName: "POMERODE", uf: "SC" } },
  { name: "PONTE ALTA", value: { cityName: "PONTE ALTA", uf: "SC" } },
  {
    name: "PONTE ALTA DO NORTE",
    value: { cityName: "PONTE ALTA DO NORTE", uf: "SC" },
  },
  { name: "PORTO BELO", value: { cityName: "PORTO BELO", uf: "SC" } },
  { name: "PORTO UNIAO", value: { cityName: "PORTO UNIAO", uf: "SC" } },
  { name: "POUSO REDONDO", value: { cityName: "POUSO REDONDO", uf: "SC" } },
  {
    name: "PRESIDENTE CASTELO BRANCO",
    value: { cityName: "PRESIDENTE CASTELO BRANCO", uf: "SC" },
  },
  {
    name: "PRESIDENTE GETULIO",
    value: { cityName: "PRESIDENTE GETULIO", uf: "SC" },
  },
  {
    name: "ITAGUACU DA BAHIA",
    value: { cityName: "ITAGUACU DA BAHIA", uf: "BA" },
  },
  { name: "ITAGUAJE", value: { cityName: "ITAGUAJE", uf: "PR" } },
  {
    name: "PRESIDENTE NEREU",
    value: { cityName: "PRESIDENTE NEREU", uf: "SC" },
  },
  { name: "ITAGUARU", value: { cityName: "ITAGUARU", uf: "GO" } },
  { name: "QUILOMBO", value: { cityName: "QUILOMBO", uf: "SC" } },
  { name: "RANCHO QUEIMADO", value: { cityName: "RANCHO QUEIMADO", uf: "SC" } },
  { name: "ITAGUATINS", value: { cityName: "ITAGUATINS", uf: "TO" } },
  { name: "ITAICABA", value: { cityName: "ITAICABA", uf: "CE" } },
  {
    name: "ITAIPAVA DO GRAJAU",
    value: { cityName: "ITAIPAVA DO GRAJAU", uf: "MA" },
  },
  { name: "RIO DAS ANTAS", value: { cityName: "RIO DAS ANTAS", uf: "SC" } },
  {
    name: "ITAJU DO COLONIA",
    value: { cityName: "ITAJU DO COLONIA", uf: "BA" },
  },
  { name: "RIO DO CAMPO", value: { cityName: "RIO DO CAMPO", uf: "SC" } },
  { name: "RIO DO OESTE", value: { cityName: "RIO DO OESTE", uf: "SC" } },
  { name: "RIO DOS CEDROS", value: { cityName: "RIO DOS CEDROS", uf: "SC" } },
  { name: "RIO FORTUNA", value: { cityName: "RIO FORTUNA", uf: "SC" } },
  { name: "RIO NEGRINHO", value: { cityName: "RIO NEGRINHO", uf: "SC" } },
  { name: "RIO RUFINO", value: { cityName: "RIO RUFINO", uf: "SC" } },
  { name: "RIQUEZA", value: { cityName: "RIQUEZA", uf: "SC" } },
  { name: "RODEIO", value: { cityName: "RODEIO", uf: "SC" } },
  { name: "ROMELANDIA", value: { cityName: "ROMELANDIA", uf: "SC" } },
  { name: "SALETE", value: { cityName: "SALETE", uf: "SC" } },
  { name: "SALTO VELOSO", value: { cityName: "SALTO VELOSO", uf: "SC" } },
  { name: "SANGAO", value: { cityName: "SANGAO", uf: "SC" } },
  { name: "SANTA CECILIA", value: { cityName: "SANTA CECILIA", uf: "SC" } },
  { name: "ITALVA", value: { cityName: "ITALVA", uf: "RJ" } },
  { name: "SANTA HELENA", value: { cityName: "SANTA HELENA", uf: "SC" } },
  { name: "ITAMBARACA", value: { cityName: "ITAMBARACA", uf: "PR" } },
  {
    name: "SANTA ROSA DE LIMA",
    value: { cityName: "SANTA ROSA DE LIMA", uf: "SC" },
  },
  {
    name: "SANTA ROSA DO SUL",
    value: { cityName: "SANTA ROSA DO SUL", uf: "SC" },
  },
  { name: "ITANHANGA", value: { cityName: "ITANHANGA", uf: "MT" } },
  {
    name: "SANTO AMARO DA IMPERATRIZ",
    value: { cityName: "SANTO AMARO DA IMPERATRIZ", uf: "SC" },
  },
  { name: "ITAOCARA", value: { cityName: "ITAOCARA", uf: "RJ" } },
  { name: "ITAPAGIPE", value: { cityName: "ITAPAGIPE", uf: "MG" } },
  { name: "SAO BONIFACIO", value: { cityName: "SAO BONIFACIO", uf: "SC" } },
  { name: "SAO CARLOS", value: { cityName: "SAO CARLOS", uf: "SC" } },
  {
    name: "SAO CRISTOVAO DO SUL",
    value: { cityName: "SAO CRISTOVAO DO SUL", uf: "SC" },
  },
  { name: "SAO DOMINGOS", value: { cityName: "SAO DOMINGOS", uf: "SC" } },
  {
    name: "SAO JOAO BATISTA",
    value: { cityName: "SAO JOAO BATISTA", uf: "SC" },
  },
  {
    name: "SAO JOAO DO ITAPERIU",
    value: { cityName: "SAO JOAO DO ITAPERIU", uf: "SC" },
  },
  {
    name: "SAO JOAO DO OESTE",
    value: { cityName: "SAO JOAO DO OESTE", uf: "SC" },
  },
  { name: "SAO JOAO DO SUL", value: { cityName: "SAO JOAO DO SUL", uf: "SC" } },
  { name: "SAO JOAQUIM", value: { cityName: "SAO JOAQUIM", uf: "SC" } },
  {
    name: "SAO JOSE DO CEDRO",
    value: { cityName: "SAO JOSE DO CEDRO", uf: "SC" },
  },
  {
    name: "SAO JOSE DO CERRITO",
    value: { cityName: "SAO JOSE DO CERRITO", uf: "SC" },
  },
  {
    name: "SAO LOURENCO DO OESTE",
    value: { cityName: "SAO LOURENCO DO OESTE", uf: "SC" },
  },
  { name: "SAO LUDGERO", value: { cityName: "SAO LUDGERO", uf: "SC" } },
  { name: "SAO MARTINHO", value: { cityName: "SAO MARTINHO", uf: "SC" } },
  {
    name: "SAO MIGUEL DA BOA VISTA",
    value: { cityName: "SAO MIGUEL DA BOA VISTA", uf: "SC" },
  },
  { name: "SCHROEDER", value: { cityName: "SCHROEDER", uf: "SC" } },
  { name: "SEARA", value: { cityName: "SEARA", uf: "SC" } },
  { name: "SERRA ALTA", value: { cityName: "SERRA ALTA", uf: "SC" } },
  { name: "ITAPAJE", value: { cityName: "ITAPAJE", uf: "CE" } },
  { name: "SIDEROPOLIS", value: { cityName: "SIDEROPOLIS", uf: "SC" } },
  { name: "SUL BRASIL", value: { cityName: "SUL BRASIL", uf: "SC" } },
  { name: "TAIO", value: { cityName: "TAIO", uf: "SC" } },
  { name: "TANGARA", value: { cityName: "TANGARA", uf: "SC" } },
  { name: "ITAPECURU MIRIM", value: { cityName: "ITAPECURU MIRIM", uf: "MA" } },
  { name: "TIJUCAS", value: { cityName: "TIJUCAS", uf: "SC" } },
  { name: "TIMBE DO SUL", value: { cityName: "TIMBE DO SUL", uf: "SC" } },
  { name: "TIMBO", value: { cityName: "TIMBO", uf: "SC" } },
  { name: "TIMBO GRANDE", value: { cityName: "TIMBO GRANDE", uf: "SC" } },
  { name: "TREZE DE MAIO", value: { cityName: "TREZE DE MAIO", uf: "SC" } },
  { name: "TREZE TILIAS", value: { cityName: "TREZE TILIAS", uf: "SC" } },
  {
    name: "TROMBUDO CENTRAL",
    value: { cityName: "TROMBUDO CENTRAL", uf: "SC" },
  },
  { name: "TUNAPOLIS", value: { cityName: "TUNAPOLIS", uf: "SC" } },
  {
    name: "ITAPEJARA DOESTE",
    value: { cityName: "ITAPEJARA DOESTE", uf: "PR" },
  },
  { name: "TURVO", value: { cityName: "TURVO", uf: "SC" } },
  {
    name: "ITAPIRAPUA PAULISTA",
    value: { cityName: "ITAPIRAPUA PAULISTA", uf: "SP" },
  },
  { name: "UNIAO DO OESTE", value: { cityName: "UNIAO DO OESTE", uf: "SC" } },
  { name: "URUBICI", value: { cityName: "URUBICI", uf: "SC" } },
  { name: "URUPEMA", value: { cityName: "URUPEMA", uf: "SC" } },
  { name: "VARGEAO", value: { cityName: "VARGEAO", uf: "SC" } },
  { name: "VARGEM", value: { cityName: "VARGEM", uf: "SC" } },
  { name: "VARGEM BONITA", value: { cityName: "VARGEM BONITA", uf: "SC" } },
  { name: "ITAPIRATINS", value: { cityName: "ITAPIRATINS", uf: "TO" } },
  { name: "VIDAL RAMOS", value: { cityName: "VIDAL RAMOS", uf: "SC" } },
  { name: "VITOR MEIRELES", value: { cityName: "VITOR MEIRELES", uf: "SC" } },
  { name: "ITAPISSUMA", value: { cityName: "ITAPISSUMA", uf: "PE" } },
  { name: "WITMARSUM", value: { cityName: "WITMARSUM", uf: "SC" } },
  { name: "XAVANTINA", value: { cityName: "XAVANTINA", uf: "SC" } },
  { name: "ITAPORA", value: { cityName: "ITAPORA", uf: "MS" } },
  {
    name: "APARECIDA DE GOIANIA",
    value: { cityName: "APARECIDA DE GOIANIA", uf: "GO" },
  },
  { name: "CATALAO", value: { cityName: "CATALAO", uf: "GO" } },
  { name: "ITUMBIARA", value: { cityName: "ITUMBIARA", uf: "GO" } },
  { name: "RIO VERDE", value: { cityName: "RIO VERDE", uf: "GO" } },
  { name: "ABADIANIA", value: { cityName: "ABADIANIA", uf: "GO" } },
  { name: "ADELANDIA", value: { cityName: "ADELANDIA", uf: "GO" } },
  {
    name: "AGUA FRIA DE GOIAS",
    value: { cityName: "AGUA FRIA DE GOIAS", uf: "GO" },
  },
  { name: "AGUA LIMPA", value: { cityName: "AGUA LIMPA", uf: "GO" } },
  { name: "ALEXANIA", value: { cityName: "ALEXANIA", uf: "GO" } },
  { name: "ALOANDIA", value: { cityName: "ALOANDIA", uf: "GO" } },
  {
    name: "ITAPORA DO TOCANTINS",
    value: { cityName: "ITAPORA DO TOCANTINS", uf: "TO" },
  },
  { name: "ITAPOROROCA", value: { cityName: "ITAPOROROCA", uf: "PB" } },
  {
    name: "ALTO PARAISO DE GOIAS",
    value: { cityName: "ALTO PARAISO DE GOIAS", uf: "GO" },
  },
  {
    name: "ALVORADA DO NORTE",
    value: { cityName: "ALVORADA DO NORTE", uf: "GO" },
  },
  {
    name: "AMERICANO DO BRASIL",
    value: { cityName: "AMERICANO DO BRASIL", uf: "GO" },
  },
  { name: "AMORINOPOLIS", value: { cityName: "AMORINOPOLIS", uf: "GO" } },
  { name: "ITAPUA DO OESTE", value: { cityName: "ITAPUA DO OESTE", uf: "RO" } },
  { name: "ANICUNS", value: { cityName: "ANICUNS", uf: "GO" } },
  { name: "ITAQUIRAI", value: { cityName: "ITAQUIRAI", uf: "MS" } },
  { name: "ITAREMA", value: { cityName: "ITAREMA", uf: "CE" } },
  { name: "ITATIM", value: { cityName: "ITATIM", uf: "BA" } },
  { name: "ITAU DE MINAS", value: { cityName: "ITAU DE MINAS", uf: "MG" } },
  { name: "ITAUBA", value: { cityName: "ITAUBA", uf: "MT" } },
  { name: "ITAUBAL", value: { cityName: "ITAUBAL", uf: "AP" } },
  { name: "ITAUEIRA", value: { cityName: "ITAUEIRA", uf: "PI" } },
  { name: "ARAGARCAS", value: { cityName: "ARAGARCAS", uf: "GO" } },
  {
    name: "ITINGA DO MARANHAO",
    value: { cityName: "ITINGA DO MARANHAO", uf: "MA" },
  },
  { name: "ARAGUAPAZ", value: { cityName: "ARAGUAPAZ", uf: "GO" } },
  { name: "ARENOPOLIS", value: { cityName: "ARENOPOLIS", uf: "GO" } },
  { name: "ARUANA", value: { cityName: "ARUANA", uf: "GO" } },
  { name: "AURILANDIA", value: { cityName: "AURILANDIA", uf: "GO" } },
  { name: "ITIQUIRA", value: { cityName: "ITIQUIRA", uf: "MT" } },
  { name: "ITUMIRIM", value: { cityName: "ITUMIRIM", uf: "MG" } },
  { name: "BALIZA", value: { cityName: "BALIZA", uf: "GO" } },
  { name: "IUIU", value: { cityName: "IUIU", uf: "BA" } },
  { name: "BARRO ALTO", value: { cityName: "BARRO ALTO", uf: "GO" } },
  { name: "IVAI", value: { cityName: "IVAI", uf: "PR" } },
  {
    name: "BOM JARDIM DE GOIAS",
    value: { cityName: "BOM JARDIM DE GOIAS", uf: "GO" },
  },
  {
    name: "BOM JESUS DE GOIAS",
    value: { cityName: "BOM JESUS DE GOIAS", uf: "GO" },
  },
  { name: "BONFINOPOLIS", value: { cityName: "BONFINOPOLIS", uf: "GO" } },
  { name: "IVAIPORA", value: { cityName: "IVAIPORA", uf: "PR" } },
  { name: "BRITANIA", value: { cityName: "BRITANIA", uf: "GO" } },
  { name: "IVINHEMA", value: { cityName: "IVINHEMA", uf: "MS" } },
  { name: "IVOLANDIA", value: { cityName: "IVOLANDIA", uf: "GO" } },
  { name: "JABOTI", value: { cityName: "JABOTI", uf: "PR" } },
  { name: "BURITINOPOLIS", value: { cityName: "BURITINOPOLIS", uf: "GO" } },
  { name: "CABECEIRAS", value: { cityName: "CABECEIRAS", uf: "GO" } },
  { name: "CACHOEIRA ALTA", value: { cityName: "CACHOEIRA ALTA", uf: "GO" } },
  {
    name: "CACHOEIRA DE GOIAS",
    value: { cityName: "CACHOEIRA DE GOIAS", uf: "GO" },
  },
  {
    name: "CACHOEIRA DOURADA",
    value: { cityName: "CACHOEIRA DOURADA", uf: "GO" },
  },
  { name: "CACU", value: { cityName: "CACU", uf: "GO" } },
  { name: "CAIAPONIA", value: { cityName: "CAIAPONIA", uf: "GO" } },
  { name: "JACARAU", value: { cityName: "JACARAU", uf: "PB" } },
  {
    name: "JACARE DOS HOMENS",
    value: { cityName: "JACARE DOS HOMENS", uf: "AL" },
  },
  { name: "CALDAS NOVAS", value: { cityName: "CALDAS NOVAS", uf: "GO" } },
  { name: "JACAREACANGA", value: { cityName: "JACAREACANGA", uf: "PA" } },
  { name: "JACIARA", value: { cityName: "JACIARA", uf: "MT" } },
  {
    name: "JACOBINA DO PIAUI",
    value: { cityName: "JACOBINA DO PIAUI", uf: "PI" },
  },
  { name: "CAMPINORTE", value: { cityName: "CAMPINORTE", uf: "GO" } },
  {
    name: "CAMPO ALEGRE DE GOIAS",
    value: { cityName: "CAMPO ALEGRE DE GOIAS", uf: "GO" },
  },
  { name: "JACUIPE", value: { cityName: "JACUIPE", uf: "AL" } },
  { name: "CAMPOS BELOS", value: { cityName: "CAMPOS BELOS", uf: "GO" } },
  { name: "CAMPOS VERDES", value: { cityName: "CAMPOS VERDES", uf: "GO" } },
  { name: "JAGUARETAMA", value: { cityName: "JAGUARETAMA", uf: "CE" } },
  { name: "JAGUARIAIVA", value: { cityName: "JAGUARIAIVA", uf: "PR" } },
  { name: "JAGUARIBARA", value: { cityName: "JAGUARIBARA", uf: "CE" } },
  { name: "CASTELANDIA", value: { cityName: "CASTELANDIA", uf: "GO" } },
  { name: "JANDAIA DO SUL", value: { cityName: "JANDAIA DO SUL", uf: "PR" } },
  { name: "JANDUIS", value: { cityName: "JANDUIS", uf: "RN" } },
  { name: "CATURAI", value: { cityName: "CATURAI", uf: "GO" } },
  { name: "CAVALCANTE", value: { cityName: "CAVALCANTE", uf: "GO" } },
  { name: "JANGADA", value: { cityName: "JANGADA", uf: "MT" } },
  { name: "JAPARATUBA", value: { cityName: "JAPARATUBA", uf: "SE" } },
  { name: "CERES", value: { cityName: "CERES", uf: "GO" } },
  { name: "CEZARINA", value: { cityName: "CEZARINA", uf: "GO" } },
  { name: "CHAPADAO DO CEU", value: { cityName: "CHAPADAO DO CEU", uf: "GO" } },
  { name: "JAPI", value: { cityName: "JAPI", uf: "RN" } },
  { name: "JAPIRA", value: { cityName: "JAPIRA", uf: "PR" } },
  {
    name: "CIDADE OCIDENTAL",
    value: { cityName: "CIDADE OCIDENTAL", uf: "GO" },
  },
  { name: "JAPONVAR", value: { cityName: "JAPONVAR", uf: "MG" } },
  {
    name: "COCALZINHO DE GOIAS",
    value: { cityName: "COCALZINHO DE GOIAS", uf: "GO" },
  },
  { name: "JAPORA", value: { cityName: "JAPORA", uf: "MS" } },
  { name: "JAQUEIRA", value: { cityName: "JAQUEIRA", uf: "PE" } },
  { name: "JARAGUARI", value: { cityName: "JARAGUARI", uf: "MS" } },
  {
    name: "CORUMBA DE GOIAS",
    value: { cityName: "CORUMBA DE GOIAS", uf: "GO" },
  },
  { name: "CORUMBAIBA", value: { cityName: "CORUMBAIBA", uf: "GO" } },
  { name: "CRISTALINA", value: { cityName: "CRISTALINA", uf: "GO" } },
  { name: "JARAMATAIA", value: { cityName: "JARAMATAIA", uf: "AL" } },
  { name: "CRIXAS", value: { cityName: "CRIXAS", uf: "GO" } },
  { name: "CROMINIA", value: { cityName: "CROMINIA", uf: "GO" } },
  { name: "JARDIM ALEGRE", value: { cityName: "JARDIM ALEGRE", uf: "PR" } },
  { name: "CUMARI", value: { cityName: "CUMARI", uf: "GO" } },
  { name: "DAMIANOPOLIS", value: { cityName: "DAMIANOPOLIS", uf: "GO" } },
  {
    name: "JARDIM DE ANGICOS",
    value: { cityName: "JARDIM DE ANGICOS", uf: "RN" },
  },
  {
    name: "JARDIM DE PIRANHAS",
    value: { cityName: "JARDIM DE PIRANHAS", uf: "RN" },
  },
  { name: "DAVINOPOLIS", value: { cityName: "DAVINOPOLIS", uf: "GO" } },
  {
    name: "JARDIM DO SERIDO",
    value: { cityName: "JARDIM DO SERIDO", uf: "RN" },
  },
  { name: "DIORAMA", value: { cityName: "DIORAMA", uf: "GO" } },
  {
    name: "DIVINOPOLIS DE GOIAS",
    value: { cityName: "DIVINOPOLIS DE GOIAS", uf: "GO" },
  },
  { name: "JARDIM OLINDA", value: { cityName: "JARDIM OLINDA", uf: "PR" } },
  { name: "DOVERLANDIA", value: { cityName: "DOVERLANDIA", uf: "GO" } },
  { name: "EDEALINA", value: { cityName: "EDEALINA", uf: "GO" } },
  { name: "JARU", value: { cityName: "JARU", uf: "RO" } },
  {
    name: "ESTRELA DO NORTE",
    value: { cityName: "ESTRELA DO NORTE", uf: "GO" },
  },
  { name: "FAINA", value: { cityName: "FAINA", uf: "GO" } },
  { name: "FAZENDA NOVA", value: { cityName: "FAZENDA NOVA", uf: "GO" } },
  { name: "FIRMINOPOLIS", value: { cityName: "FIRMINOPOLIS", uf: "GO" } },
  { name: "JATAUBA", value: { cityName: "JATAUBA", uf: "PE" } },
  { name: "FORMOSA", value: { cityName: "FORMOSA", uf: "GO" } },
  { name: "FORMOSO", value: { cityName: "FORMOSO", uf: "GO" } },
  { name: "JATEI", value: { cityName: "JATEI", uf: "MS" } },
  { name: "GOIANAPOLIS", value: { cityName: "GOIANAPOLIS", uf: "GO" } },
  { name: "JATI", value: { cityName: "JATI", uf: "CE" } },
  { name: "GOIANESIA", value: { cityName: "GOIANESIA", uf: "GO" } },
  { name: "GOIANIRA", value: { cityName: "GOIANIRA", uf: "GO" } },
  { name: "GOIATUBA", value: { cityName: "GOIATUBA", uf: "GO" } },
  { name: "GOUVELANDIA", value: { cityName: "GOUVELANDIA", uf: "GO" } },
  { name: "GUARAITA", value: { cityName: "GUARAITA", uf: "GO" } },
  { name: "JATOBA", value: { cityName: "JATOBA", uf: "MA" } },
  { name: "GUARINOS", value: { cityName: "GUARINOS", uf: "GO" } },
  { name: "JATOBA DO PIAUI", value: { cityName: "JATOBA DO PIAUI", uf: "PI" } },
  { name: "HIDROLINA", value: { cityName: "HIDROLINA", uf: "GO" } },
  { name: "IACIARA", value: { cityName: "IACIARA", uf: "GO" } },
  { name: "INACIOLANDIA", value: { cityName: "INACIOLANDIA", uf: "GO" } },
  { name: "INDIARA", value: { cityName: "INDIARA", uf: "GO" } },
  { name: "INHUMAS", value: { cityName: "INHUMAS", uf: "GO" } },
  {
    name: "JAU DO TOCANTINS",
    value: { cityName: "JAU DO TOCANTINS", uf: "TO" },
  },
  { name: "IPAMERI", value: { cityName: "IPAMERI", uf: "GO" } },
  { name: "IPORA", value: { cityName: "IPORA", uf: "GO" } },
  { name: "JAUPACI", value: { cityName: "JAUPACI", uf: "GO" } },
  { name: "JAURU", value: { cityName: "JAURU", uf: "MT" } },
  { name: "ITAGUARI", value: { cityName: "ITAGUARI", uf: "GO" } },
  {
    name: "JENIPAPO DE MINAS",
    value: { cityName: "JENIPAPO DE MINAS", uf: "MG" },
  },
  { name: "ITAJA", value: { cityName: "ITAJA", uf: "GO" } },
  { name: "ITAPACI", value: { cityName: "ITAPACI", uf: "GO" } },
  { name: "ITAPIRAPUA", value: { cityName: "ITAPIRAPUA", uf: "GO" } },
  { name: "ITAPURANGA", value: { cityName: "ITAPURANGA", uf: "GO" } },
  { name: "ITARUMA", value: { cityName: "ITARUMA", uf: "GO" } },
  { name: "ITAUCU", value: { cityName: "ITAUCU", uf: "GO" } },
  {
    name: "JENIPAPO DOS VIEIRAS",
    value: { cityName: "JENIPAPO DOS VIEIRAS", uf: "MA" },
  },
  { name: "JEQUIA DA PRAIA", value: { cityName: "JEQUIA DA PRAIA", uf: "AL" } },
  {
    name: "JERONIMO MONTEIRO",
    value: { cityName: "JERONIMO MONTEIRO", uf: "ES" },
  },
  { name: "JANDAIA", value: { cityName: "JANDAIA", uf: "GO" } },
  { name: "JARAGUA", value: { cityName: "JARAGUA", uf: "GO" } },
  { name: "JATAI", value: { cityName: "JATAI", uf: "GO" } },
  { name: "JERUMENHA", value: { cityName: "JERUMENHA", uf: "PI" } },
  { name: "JESUITAS", value: { cityName: "JESUITAS", uf: "PR" } },
  { name: "JESUPOLIS", value: { cityName: "JESUPOLIS", uf: "GO" } },
  {
    name: "JIJOCA DE JERICOACOARA",
    value: { cityName: "JIJOCA DE JERICOACOARA", uf: "CE" },
  },
  { name: "JI-PARANA", value: { cityName: "JI-PARANA", uf: "RO" } },
  { name: "JUSSARA", value: { cityName: "JUSSARA", uf: "GO" } },
  { name: "JOAO COSTA", value: { cityName: "JOAO COSTA", uf: "PI" } },
  { name: "JOAO LISBOA", value: { cityName: "JOAO LISBOA", uf: "MA" } },
  {
    name: "LEOPOLDO DE BULHOES",
    value: { cityName: "LEOPOLDO DE BULHOES", uf: "GO" },
  },
  { name: "JOAO RAMALHO", value: { cityName: "JOAO RAMALHO", uf: "SP" } },
  { name: "LUZIANIA", value: { cityName: "LUZIANIA", uf: "GO" } },
  { name: "MAIRIPOTABA", value: { cityName: "MAIRIPOTABA", uf: "GO" } },
  { name: "MAMBAI", value: { cityName: "MAMBAI", uf: "GO" } },
  { name: "MARA ROSA", value: { cityName: "MARA ROSA", uf: "GO" } },
  { name: "MARZAGAO", value: { cityName: "MARZAGAO", uf: "GO" } },
  { name: "JOAQUIM GOMES", value: { cityName: "JOAQUIM GOMES", uf: "AL" } },
  { name: "JOAQUIM NABUCO", value: { cityName: "JOAQUIM NABUCO", uf: "PE" } },
  { name: "MAURILANDIA", value: { cityName: "MAURILANDIA", uf: "GO" } },
  { name: "JOAQUIM PIRES", value: { cityName: "JOAQUIM PIRES", uf: "PI" } },
  { name: "JOAQUIM TAVORA", value: { cityName: "JOAQUIM TAVORA", uf: "PR" } },
  { name: "JOCA MARQUES", value: { cityName: "JOCA MARQUES", uf: "PI" } },
  {
    name: "JOSE GONCALVES DE MINAS",
    value: { cityName: "JOSE GONCALVES DE MINAS", uf: "MG" },
  },
  { name: "MINEIROS", value: { cityName: "MINEIROS", uf: "GO" } },
  { name: "JOSE RAYDAN", value: { cityName: "JOSE RAYDAN", uf: "MG" } },
  {
    name: "MONTE ALEGRE DE GOIAS",
    value: { cityName: "MONTE ALEGRE DE GOIAS", uf: "GO" },
  },
  {
    name: "MONTES CLAROS DE GOIAS",
    value: { cityName: "MONTES CLAROS DE GOIAS", uf: "GO" },
  },
  { name: "MONTIVIDIU", value: { cityName: "MONTIVIDIU", uf: "GO" } },
  { name: "JOSELANDIA", value: { cityName: "JOSELANDIA", uf: "MA" } },
  { name: "JOSENOPOLIS", value: { cityName: "JOSENOPOLIS", uf: "MG" } },
  { name: "MOSSAMEDES", value: { cityName: "MOSSAMEDES", uf: "GO" } },
  { name: "JOVIANIA", value: { cityName: "JOVIANIA", uf: "GO" } },
  { name: "MUNDO NOVO", value: { cityName: "MUNDO NOVO", uf: "GO" } },
  { name: "MUTUNOPOLIS", value: { cityName: "MUTUNOPOLIS", uf: "GO" } },
  { name: "JUARA", value: { cityName: "JUARA", uf: "MT" } },
  { name: "NAZARIO", value: { cityName: "NAZARIO", uf: "GO" } },
  { name: "NEROPOLIS", value: { cityName: "NEROPOLIS", uf: "GO" } },
  { name: "NIQUELANDIA", value: { cityName: "NIQUELANDIA", uf: "GO" } },
  { name: "NOVA AMERICA", value: { cityName: "NOVA AMERICA", uf: "GO" } },
  { name: "JUARINA", value: { cityName: "JUARINA", uf: "TO" } },
  { name: "NOVA AURORA", value: { cityName: "NOVA AURORA", uf: "GO" } },
  { name: "NOVA CRIXAS", value: { cityName: "NOVA CRIXAS", uf: "GO" } },
  { name: "NOVA GLORIA", value: { cityName: "NOVA GLORIA", uf: "GO" } },
  {
    name: "JUAZEIRO DO PIAUI",
    value: { cityName: "JUAZEIRO DO PIAUI", uf: "PI" },
  },
  { name: "NOVA ROMA", value: { cityName: "NOVA ROMA", uf: "GO" } },
  { name: "NOVA VENEZA", value: { cityName: "NOVA VENEZA", uf: "GO" } },
  { name: "NOVO BRASIL", value: { cityName: "NOVO BRASIL", uf: "GO" } },
  { name: "JUCURUCU", value: { cityName: "JUCURUCU", uf: "BA" } },
  { name: "JUINA", value: { cityName: "JUINA", uf: "MT" } },
  { name: "ORIZONA", value: { cityName: "ORIZONA", uf: "GO" } },
  { name: "JULIO BORGES", value: { cityName: "JULIO BORGES", uf: "PI" } },
  { name: "JUMIRIM", value: { cityName: "JUMIRIM", uf: "SP" } },
  { name: "OUVIDOR", value: { cityName: "OUVIDOR", uf: "GO" } },
  { name: "PADRE BERNARDO", value: { cityName: "PADRE BERNARDO", uf: "GO" } },
  {
    name: "PALESTINA DE GOIAS",
    value: { cityName: "PALESTINA DE GOIAS", uf: "GO" },
  },
  {
    name: "PALMEIRAS DE GOIAS",
    value: { cityName: "PALMEIRAS DE GOIAS", uf: "GO" },
  },
  {
    name: "JUNCO DO MARANHAO",
    value: { cityName: "JUNCO DO MARANHAO", uf: "MA" },
  },
  { name: "JUNCO DO SERIDO", value: { cityName: "JUNCO DO SERIDO", uf: "PB" } },
  { name: "PANAMA", value: { cityName: "PANAMA", uf: "GO" } },
  { name: "PARANAIGUARA", value: { cityName: "PARANAIGUARA", uf: "GO" } },
  { name: "JUNDIAI DO SUL", value: { cityName: "JUNDIAI DO SUL", uf: "PR" } },
  { name: "JURUA", value: { cityName: "JURUA", uf: "AM" } },
  { name: "JURUENA", value: { cityName: "JURUENA", uf: "MT" } },
  { name: "PEROLANDIA", value: { cityName: "PEROLANDIA", uf: "GO" } },
  {
    name: "PETROLINA DE GOIAS",
    value: { cityName: "PETROLINA DE GOIAS", uf: "GO" },
  },
  { name: "JUSCIMEIRA", value: { cityName: "JUSCIMEIRA", uf: "MT" } },
  { name: "PIRACANJUBA", value: { cityName: "PIRACANJUBA", uf: "GO" } },
  { name: "PIRANHAS", value: { cityName: "PIRANHAS", uf: "GO" } },
  { name: "PIRENOPOLIS", value: { cityName: "PIRENOPOLIS", uf: "GO" } },
  { name: "JUSSIAPE", value: { cityName: "JUSSIAPE", uf: "BA" } },
  { name: "PIRES DO RIO", value: { cityName: "PIRES DO RIO", uf: "GO" } },
  { name: "PLANALTINA", value: { cityName: "PLANALTINA", uf: "GO" } },
  { name: "PONTALINA", value: { cityName: "PONTALINA", uf: "GO" } },
  { name: "PORANGATU", value: { cityName: "PORANGATU", uf: "GO" } },
  { name: "JUTI", value: { cityName: "JUTI", uf: "MS" } },
  { name: "PORTELANDIA", value: { cityName: "PORTELANDIA", uf: "GO" } },
  { name: "POSSE", value: { cityName: "POSSE", uf: "GO" } },
  { name: "JUVENILIA", value: { cityName: "JUVENILIA", uf: "MG" } },
  { name: "PROFESSOR JAMIL", value: { cityName: "PROFESSOR JAMIL", uf: "GO" } },
  { name: "QUIRINOPOLIS", value: { cityName: "QUIRINOPOLIS", uf: "GO" } },
  { name: "KALORE", value: { cityName: "KALORE", uf: "PR" } },
  { name: "RIALMA", value: { cityName: "RIALMA", uf: "GO" } },
  { name: "RIANAPOLIS", value: { cityName: "RIANAPOLIS", uf: "GO" } },
  { name: "LADARIO", value: { cityName: "LADARIO", uf: "MS" } },
  { name: "LAGO DO JUNCO", value: { cityName: "LAGO DO JUNCO", uf: "MA" } },
  {
    name: "LAGO DOS RODRIGUES",
    value: { cityName: "LAGO DOS RODRIGUES", uf: "MA" },
  },
  { name: "LAGO VERDE", value: { cityName: "LAGO VERDE", uf: "MA" } },
  { name: "LAGOA ALEGRE", value: { cityName: "LAGOA ALEGRE", uf: "PI" } },
  { name: "RUBIATABA", value: { cityName: "RUBIATABA", uf: "GO" } },
  { name: "SANCLERLANDIA", value: { cityName: "SANCLERLANDIA", uf: "GO" } },
  {
    name: "LAGOA DA CONFUSAO",
    value: { cityName: "LAGOA DA CONFUSAO", uf: "TO" },
  },
  { name: "LAGOA DANTA", value: { cityName: "LAGOA DANTA", uf: "RN" } },
  {
    name: "SANTA FE DE GOIAS",
    value: { cityName: "SANTA FE DE GOIAS", uf: "GO" },
  },
  {
    name: "SANTA HELENA DE GOIAS",
    value: { cityName: "SANTA HELENA DE GOIAS", uf: "GO" },
  },
  { name: "SANTA ISABEL", value: { cityName: "SANTA ISABEL", uf: "GO" } },
  {
    name: "SANTA RITA DO ARAGUAIA",
    value: { cityName: "SANTA RITA DO ARAGUAIA", uf: "GO" },
  },
  { name: "LAGOA DE DENTRO", value: { cityName: "LAGOA DE DENTRO", uf: "PB" } },
  {
    name: "LAGOA DE ITAENGA",
    value: { cityName: "LAGOA DE ITAENGA", uf: "PE" },
  },
  {
    name: "LAGOA DE SAO FRANCISCO",
    value: { cityName: "LAGOA DE SAO FRANCISCO", uf: "PI" },
  },
  {
    name: "SANTA TEREZINHA DE GOIAS",
    value: { cityName: "SANTA TEREZINHA DE GOIAS", uf: "GO" },
  },
  {
    name: "SANTO ANTONIO DA BARRA",
    value: { cityName: "SANTO ANTONIO DA BARRA", uf: "GO" },
  },
  {
    name: "SANTO ANTONIO DE GOIAS",
    value: { cityName: "SANTO ANTONIO DE GOIAS", uf: "GO" },
  },
  {
    name: "SANTO ANTONIO DO DESCOBERTO",
    value: { cityName: "SANTO ANTONIO DO DESCOBERTO", uf: "GO" },
  },
  {
    name: "SAO FRANCISCO DE GOIAS",
    value: { cityName: "SAO FRANCISCO DE GOIAS", uf: "GO" },
  },
  { name: "LAGOA DE VELHOS", value: { cityName: "LAGOA DE VELHOS", uf: "RN" } },
  {
    name: "SAO JOAO DA PARAUNA",
    value: { cityName: "SAO JOAO DA PARAUNA", uf: "GO" },
  },
  {
    name: "SAO LUIS DE MONTES BELOS",
    value: { cityName: "SAO LUIS DE MONTES BELOS", uf: "GO" },
  },
  {
    name: "SAO LUIS DO NORTE",
    value: { cityName: "SAO LUIS DO NORTE", uf: "GO" },
  },
  {
    name: "LAGOA DO BARRO DO PIAUI",
    value: { cityName: "LAGOA DO BARRO DO PIAUI", uf: "PI" },
  },
  {
    name: "SAO MIGUEL DO ARAGUAIA",
    value: { cityName: "SAO MIGUEL DO ARAGUAIA", uf: "GO" },
  },
  { name: "LAGOA DO CARRO", value: { cityName: "LAGOA DO CARRO", uf: "PE" } },
  { name: "SAO SIMAO", value: { cityName: "SAO SIMAO", uf: "GO" } },
  { name: "SENADOR CANEDO", value: { cityName: "SENADOR CANEDO", uf: "GO" } },
  { name: "LAGOA DO MATO", value: { cityName: "LAGOA DO MATO", uf: "MA" } },
  { name: "SERRANOPOLIS", value: { cityName: "SERRANOPOLIS", uf: "GO" } },
  { name: "SILVANIA", value: { cityName: "SILVANIA", uf: "GO" } },
  { name: "SIMOLANDIA", value: { cityName: "SIMOLANDIA", uf: "GO" } },
  { name: "LAGOA DO OURO", value: { cityName: "LAGOA DO OURO", uf: "PE" } },
  { name: "LAGOA DO PIAUI", value: { cityName: "LAGOA DO PIAUI", uf: "PI" } },
  { name: "LAGOA DO SITIO", value: { cityName: "LAGOA DO SITIO", uf: "PI" } },
  {
    name: "LAGOA DO TOCANTINS",
    value: { cityName: "LAGOA DO TOCANTINS", uf: "TO" },
  },
  {
    name: "TERESINA DE GOIAS",
    value: { cityName: "TERESINA DE GOIAS", uf: "GO" },
  },
  {
    name: "LAGOA GRANDE DO MARANHAO",
    value: { cityName: "LAGOA GRANDE DO MARANHAO", uf: "MA" },
  },
  { name: "LAGOA SALGADA", value: { cityName: "LAGOA SALGADA", uf: "RN" } },
  { name: "LAGOA SECA", value: { cityName: "LAGOA SECA", uf: "PB" } },
  {
    name: "LAGOINHA DO PIAUI",
    value: { cityName: "LAGOINHA DO PIAUI", uf: "PI" },
  },
  { name: "LAGUNA CARAPA", value: { cityName: "LAGUNA CARAPA", uf: "MS" } },
  { name: "LAJE DO MURIAE", value: { cityName: "LAJE DO MURIAE", uf: "RJ" } },
  { name: "LAJEADO NOVO", value: { cityName: "LAJEADO NOVO", uf: "MA" } },
  { name: "URUACU", value: { cityName: "URUACU", uf: "GO" } },
  { name: "URUANA", value: { cityName: "URUANA", uf: "GO" } },
  {
    name: "LAJEDO DO TABOCAL",
    value: { cityName: "LAJEDO DO TABOCAL", uf: "BA" },
  },
  { name: "LAJES PINTADAS", value: { cityName: "LAJES PINTADAS", uf: "RN" } },
  { name: "CONTAGEM", value: { cityName: "CONTAGEM", uf: "MG" } },
  { name: "LAMBARI DOESTE", value: { cityName: "LAMBARI DOESTE", uf: "MT" } },
  {
    name: "BARAO DO TRIUNFO",
    value: { cityName: "BARAO DO TRIUNFO", uf: "RS" },
  },
  { name: "VALE VERDE", value: { cityName: "VALE VERDE", uf: "RS" } },
  { name: "LANDRI SALES", value: { cityName: "LANDRI SALES", uf: "PI" } },
  {
    name: "LARANJAL DO JARI",
    value: { cityName: "LARANJAL DO JARI", uf: "AP" },
  },
  { name: "LAVANDEIRA", value: { cityName: "LAVANDEIRA", uf: "TO" } },
  { name: "LEME DO PRADO", value: { cityName: "LEME DO PRADO", uf: "MG" } },
  {
    name: "LIMEIRA DO OESTE",
    value: { cityName: "LIMEIRA DO OESTE", uf: "MG" },
  },
  { name: "VILA BOA", value: { cityName: "VILA BOA", uf: "GO" } },
  {
    name: "LIMOEIRO DO AJURU",
    value: { cityName: "LIMOEIRO DO AJURU", uf: "PA" },
  },
  {
    name: "LIVRAMENTO DE NOSSA SENHORA",
    value: { cityName: "LIVRAMENTO DE NOSSA SENHORA", uf: "BA" },
  },
  {
    name: "CACHOEIRO DE ITAPEMIRIM",
    value: { cityName: "CACHOEIRO DE ITAPEMIRIM", uf: "ES" },
  },
  { name: "CARIACICA", value: { cityName: "CARIACICA", uf: "ES" } },
  { name: "COLATINA", value: { cityName: "COLATINA", uf: "ES" } },
  { name: "LINHARES", value: { cityName: "LINHARES", uf: "ES" } },
  { name: "SERRA", value: { cityName: "SERRA", uf: "ES" } },
  { name: "VILA VELHA", value: { cityName: "VILA VELHA", uf: "ES" } },
  { name: "VITORIA", value: { cityName: "VITORIA", uf: "ES" } },
  { name: "LIZARDA", value: { cityName: "LIZARDA", uf: "TO" } },
  { name: "AFONSO CLAUDIO", value: { cityName: "AFONSO CLAUDIO", uf: "ES" } },
  {
    name: "AGUA DOCE DO NORTE",
    value: { cityName: "AGUA DOCE DO NORTE", uf: "ES" },
  },
  { name: "AGUIA BRANCA", value: { cityName: "AGUIA BRANCA", uf: "ES" } },
  { name: "LOANDA", value: { cityName: "LOANDA", uf: "PR" } },
  { name: "ALEGRE", value: { cityName: "ALEGRE", uf: "ES" } },
  { name: "ALFREDO CHAVES", value: { cityName: "ALFREDO CHAVES", uf: "ES" } },
  { name: "LOBATO", value: { cityName: "LOBATO", uf: "PR" } },
  { name: "LOGRADOURO", value: { cityName: "LOGRADOURO", uf: "PB" } },
  { name: "ALTO RIO NOVO", value: { cityName: "ALTO RIO NOVO", uf: "ES" } },
  { name: "LORETO", value: { cityName: "LORETO", uf: "MA" } },
  { name: "ANCHIETA", value: { cityName: "ANCHIETA", uf: "ES" } },
  {
    name: "LUCAS DO RIO VERDE",
    value: { cityName: "LUCAS DO RIO VERDE", uf: "MT" },
  },
  { name: "LUCENA", value: { cityName: "LUCENA", uf: "PB" } },
  { name: "LUCIARA", value: { cityName: "LUCIARA", uf: "MT" } },
  { name: "LUCRECIA", value: { cityName: "LUCRECIA", uf: "RN" } },
  {
    name: "LUIS EDUARDO MAGALHAES",
    value: { cityName: "LUIS EDUARDO MAGALHAES", uf: "BA" },
  },
  { name: "ARACRUZ", value: { cityName: "ARACRUZ", uf: "ES" } },
  { name: "LUIS GOMES", value: { cityName: "LUIS GOMES", uf: "RN" } },
  { name: "LUISIANA", value: { cityName: "LUISIANA", uf: "PR" } },
  { name: "BAIXO GUANDU", value: { cityName: "BAIXO GUANDU", uf: "ES" } },
  { name: "LUISIANIA", value: { cityName: "LUISIANIA", uf: "SP" } },
  {
    name: "BARRA DE SAO FRANCISCO",
    value: { cityName: "BARRA DE SAO FRANCISCO", uf: "ES" },
  },
  { name: "LUISLANDIA", value: { cityName: "LUISLANDIA", uf: "MG" } },
  { name: "LUMINARIAS", value: { cityName: "LUMINARIAS", uf: "MG" } },
  { name: "LUNARDELLI", value: { cityName: "LUNARDELLI", uf: "PR" } },
  { name: "LUPIONOPOLIS", value: { cityName: "LUPIONOPOLIS", uf: "PR" } },
  { name: "BOA ESPERANCA", value: { cityName: "BOA ESPERANCA", uf: "ES" } },
  { name: "LUZINOPOLIS", value: { cityName: "LUZINOPOLIS", uf: "TO" } },
  {
    name: "BOM JESUS DO NORTE",
    value: { cityName: "BOM JESUS DO NORTE", uf: "ES" },
  },
  { name: "MACAMBIRA", value: { cityName: "MACAMBIRA", uf: "SE" } },
  { name: "MACAPARANA", value: { cityName: "MACAPARANA", uf: "PE" } },
  { name: "MACEDONIA", value: { cityName: "MACEDONIA", uf: "SP" } },
  {
    name: "MACHADINHO DOESTE",
    value: { cityName: "MACHADINHO DOESTE", uf: "RO" },
  },
  { name: "MACHADOS", value: { cityName: "MACHADOS", uf: "PE" } },
  { name: "MACUCO", value: { cityName: "MACUCO", uf: "RJ" } },
  { name: "MADALENA", value: { cityName: "MADALENA", uf: "CE" } },
  { name: "CASTELO", value: { cityName: "CASTELO", uf: "ES" } },
  {
    name: "CONCEICAO DA BARRA",
    value: { cityName: "CONCEICAO DA BARRA", uf: "ES" },
  },
  { name: "MADEIRO", value: { cityName: "MADEIRO", uf: "PI" } },
  {
    name: "MADRE DE DEUS DE MINAS",
    value: { cityName: "MADRE DE DEUS DE MINAS", uf: "MG" },
  },
  { name: "MAE DAGUA", value: { cityName: "MAE DAGUA", uf: "PB" } },
  { name: "MAE DO RIO", value: { cityName: "MAE DO RIO", uf: "PA" } },
  { name: "MAETINGA", value: { cityName: "MAETINGA", uf: "BA" } },
  {
    name: "MAGALHAES BARATA",
    value: { cityName: "MAGALHAES BARATA", uf: "PA" },
  },
  { name: "MAJOR SALES", value: { cityName: "MAJOR SALES", uf: "RN" } },
  {
    name: "MALHADA DE PEDRAS",
    value: { cityName: "MALHADA DE PEDRAS", uf: "BA" },
  },
  {
    name: "MALHADA DOS BOIS",
    value: { cityName: "MALHADA DOS BOIS", uf: "SE" },
  },
  { name: "MALHADOR", value: { cityName: "MALHADOR", uf: "SE" } },
  { name: "MALLET", value: { cityName: "MALLET", uf: "PR" } },
  {
    name: "DORES DO RIO PRETO",
    value: { cityName: "DORES DO RIO PRETO", uf: "ES" },
  },
  { name: "MAMBORE", value: { cityName: "MAMBORE", uf: "PR" } },
  { name: "ECOPORANGA", value: { cityName: "ECOPORANGA", uf: "ES" } },
  { name: "MAMONAS", value: { cityName: "MAMONAS", uf: "MG" } },
  { name: "MANACAPURU", value: { cityName: "MANACAPURU", uf: "AM" } },
  { name: "MANAQUIRI", value: { cityName: "MANAQUIRI", uf: "AM" } },
  { name: "FUNDAO", value: { cityName: "FUNDAO", uf: "10" } },
  { name: "MANARI", value: { cityName: "MANARI", uf: "PE" } },
  { name: "MANCIO LIMA", value: { cityName: "MANCIO LIMA", uf: "AC" } },
  { name: "MANDURI", value: { cityName: "MANDURI", uf: "SP" } },
  { name: "MANFRINOPOLIS", value: { cityName: "MANFRINOPOLIS", uf: "PR" } },
  { name: "GUARAPARI", value: { cityName: "GUARAPARI", uf: "ES" } },
  { name: "MANOEL RIBAS", value: { cityName: "MANOEL RIBAS", uf: "PR" } },
  { name: "IBATIBA", value: { cityName: "IBATIBA", uf: "ES" } },
  { name: "MANOEL URBANO", value: { cityName: "MANOEL URBANO", uf: "AC" } },
  { name: "IBIRACU", value: { cityName: "IBIRACU", uf: "ES" } },
  { name: "IBITIRAMA", value: { cityName: "IBITIRAMA", uf: "ES" } },
  { name: "MANOEL VITORINO", value: { cityName: "MANOEL VITORINO", uf: "BA" } },
  { name: "MANSIDAO", value: { cityName: "MANSIDAO", uf: "BA" } },
  { name: "ICONHA", value: { cityName: "ICONHA", uf: "ES" } },
  { name: "MARAA", value: { cityName: "MARAA", uf: "AM" } },
  { name: "MARACACUME", value: { cityName: "MARACACUME", uf: "MA" } },
  { name: "MARACAJU", value: { cityName: "MARACAJU", uf: "MS" } },
  { name: "IRUPI", value: { cityName: "IRUPI", uf: "ES" } },
  { name: "MARAGOGI", value: { cityName: "MARAGOGI", uf: "AL" } },
  { name: "MARAJA DO SENA", value: { cityName: "MARAJA DO SENA", uf: "MA" } },
  { name: "MARANHAOZINHO", value: { cityName: "MARANHAOZINHO", uf: "MA" } },
  { name: "ITAGUACU", value: { cityName: "ITAGUACU", uf: "ES" } },
  { name: "MARATAIZES", value: { cityName: "MARATAIZES", uf: "ES" } },
  { name: "MARCACAO", value: { cityName: "MARCACAO", uf: "PB" } },
  { name: "MARCELANDIA", value: { cityName: "MARCELANDIA", uf: "MT" } },
  { name: "MARCO", value: { cityName: "MARCO", uf: "CE" } },
  { name: "ITAPEMIRIM", value: { cityName: "ITAPEMIRIM", uf: "ES" } },
  { name: "MARCOLANDIA", value: { cityName: "MARCOLANDIA", uf: "PI" } },
  { name: "MARCOS PARENTE", value: { cityName: "MARCOS PARENTE", uf: "PI" } },
  { name: "ITARANA", value: { cityName: "ITARANA", uf: "ES" } },
  {
    name: "MARECHAL CANDIDO RONDON",
    value: { cityName: "MARECHAL CANDIDO RONDON", uf: "PR" },
  },
  {
    name: "MARECHAL THAUMATURGO",
    value: { cityName: "MARECHAL THAUMATURGO", uf: "AC" },
  },
  { name: "IUNA", value: { cityName: "IUNA", uf: "ES" } },
  { name: "MARIA HELENA", value: { cityName: "MARIA HELENA", uf: "PR" } },
  {
    name: "MARIANOPOLIS DO TOCANTINS",
    value: { cityName: "MARIANOPOLIS DO TOCANTINS", uf: "TO" },
  },
  {
    name: "MARILANDIA DO SUL",
    value: { cityName: "MARILANDIA DO SUL", uf: "PR" },
  },
  { name: "MARILENA", value: { cityName: "MARILENA", uf: "PR" } },
  { name: "JAGUARE", value: { cityName: "JAGUARE", uf: "ES" } },
  { name: "MARINOPOLIS", value: { cityName: "MARINOPOLIS", uf: "SP" } },
  { name: "MARIOPOLIS", value: { cityName: "MARIOPOLIS", uf: "PR" } },
  { name: "JOAO NEIVA", value: { cityName: "JOAO NEIVA", uf: "ES" } },
  { name: "MARIPA", value: { cityName: "MARIPA", uf: "PR" } },
  { name: "MARITUBA", value: { cityName: "MARITUBA", uf: "PA" } },
  { name: "MARIZOPOLIS", value: { cityName: "MARIZOPOLIS", uf: "PB" } },
  { name: "MARQUINHO", value: { cityName: "MARQUINHO", uf: "PR" } },
  {
    name: "LARANJA DA TERRA",
    value: { cityName: "LARANJA DA TERRA", uf: "ES" },
  },
  { name: "MARUMBI", value: { cityName: "MARUMBI", uf: "PR" } },
  { name: "MANTENOPOLIS", value: { cityName: "MANTENOPOLIS", uf: "ES" } },
  {
    name: "MASSAPE DO PIAUI",
    value: { cityName: "MASSAPE DO PIAUI", uf: "PI" },
  },
  {
    name: "MARECHAL FLORIANO",
    value: { cityName: "MARECHAL FLORIANO", uf: "ES" },
  },
  { name: "MARILANDIA", value: { cityName: "MARILANDIA", uf: "ES" } },
  { name: "MATA ROMA", value: { cityName: "MATA ROMA", uf: "MA" } },
  { name: "MATARACA", value: { cityName: "MATARACA", uf: "PB" } },
  { name: "MATEIROS", value: { cityName: "MATEIROS", uf: "TO" } },
  { name: "MIMOSO DO SUL", value: { cityName: "MIMOSO DO SUL", uf: "ES" } },
  { name: "MONTANHA", value: { cityName: "MONTANHA", uf: "ES" } },
  { name: "MATIAS OLIMPIO", value: { cityName: "MATIAS OLIMPIO", uf: "PI" } },
  { name: "MATINHA", value: { cityName: "MATINHA", uf: "MA" } },
  { name: "MUCURICI", value: { cityName: "MUCURICI", uf: "ES" } },
  { name: "MATINHAS", value: { cityName: "MATINHAS", uf: "PB" } },
  { name: "MUNIZ FREIRE", value: { cityName: "MUNIZ FREIRE", uf: "ES" } },
  { name: "MUQUI", value: { cityName: "MUQUI", uf: "ES" } },
  { name: "MATO RICO", value: { cityName: "MATO RICO", uf: "PR" } },
  { name: "NOVA VENECIA", value: { cityName: "NOVA VENECIA", uf: "ES" } },
  { name: "MATO VERDE", value: { cityName: "MATO VERDE", uf: "MG" } },
  { name: "MATOES", value: { cityName: "MATOES", uf: "MA" } },
  { name: "MATOES DO NORTE", value: { cityName: "MATOES DO NORTE", uf: "MA" } },
  { name: "MATRINCHA", value: { cityName: "MATRINCHA", uf: "GO" } },
  {
    name: "MATRIZ DE CAMARAGIBE",
    value: { cityName: "MATRIZ DE CAMARAGIBE", uf: "AL" },
  },
  { name: "PANCAS", value: { cityName: "PANCAS", uf: "ES" } },
  { name: "MATUPA", value: { cityName: "MATUPA", uf: "MT" } },
  { name: "MATUREIA", value: { cityName: "MATUREIA", uf: "PB" } },
  { name: "PEDRO CANARIO", value: { cityName: "PEDRO CANARIO", uf: "ES" } },
  { name: "MAUA DA SERRA", value: { cityName: "MAUA DA SERRA", uf: "PR" } },
  { name: "MAUES", value: { cityName: "MAUES", uf: "AM" } },
  {
    name: "MAURILANDIA DO TOCANTINS",
    value: { cityName: "MAURILANDIA DO TOCANTINS", uf: "TO" },
  },
  { name: "MAXARANGUAPE", value: { cityName: "MAXARANGUAPE", uf: "RN" } },
  { name: "PIUMA", value: { cityName: "PIUMA", uf: "ES" } },
  { name: "MAZAGAO", value: { cityName: "MAZAGAO", uf: "AP" } },
  { name: "MEDICILANDIA", value: { cityName: "MEDICILANDIA", uf: "PA" } },
  { name: "MERCEDES", value: { cityName: "MERCEDES", uf: "PR" } },
  { name: "MESOPOLIS", value: { cityName: "MESOPOLIS", uf: "SP" } },
  { name: "MESSIAS", value: { cityName: "MESSIAS", uf: "AL" } },
  { name: "MESSIAS TARGINO", value: { cityName: "MESSIAS TARGINO", uf: "RN" } },
  { name: "MIGUEL LEAO", value: { cityName: "MIGUEL LEAO", uf: "PI" } },
  {
    name: "MILAGRES DO MARANHAO",
    value: { cityName: "MILAGRES DO MARANHAO", uf: "MA" },
  },
  { name: "MILTON BRANDAO", value: { cityName: "MILTON BRANDAO", uf: "PI" } },
  { name: "MIMOSO DE GOIAS", value: { cityName: "MIMOSO DE GOIAS", uf: "GO" } },
  { name: "MINACU", value: { cityName: "MINACU", uf: "GO" } },
  {
    name: "MINEIROS DO TIETE",
    value: { cityName: "MINEIROS DO TIETE", uf: "SP" },
  },
  {
    name: "MINISTRO ANDREAZZA",
    value: { cityName: "MINISTRO ANDREAZZA", uf: "RO" },
  },
  { name: "RIO NOVO DO SUL", value: { cityName: "RIO NOVO DO SUL", uf: "ES" } },
  { name: "MIRA ESTRELA", value: { cityName: "MIRA ESTRELA", uf: "SP" } },
  { name: "MIRACEMA", value: { cityName: "MIRACEMA", uf: "RJ" } },
  {
    name: "MIRACEMA DO TOCANTINS",
    value: { cityName: "MIRACEMA DO TOCANTINS", uf: "TO" },
  },
  { name: "MIRADOR", value: { cityName: "MIRADOR", uf: "MA" } },
  {
    name: "SANTA LEOPOLDINA",
    value: { cityName: "SANTA LEOPOLDINA", uf: "ES" },
  },
  { name: "MIRANDA", value: { cityName: "MIRANDA", uf: "MS" } },
  { name: "MIRANDIBA", value: { cityName: "MIRANDIBA", uf: "PE" } },
  { name: "MIRANORTE", value: { cityName: "MIRANORTE", uf: "TO" } },
  {
    name: "SANTA MARIA DE JETIBA",
    value: { cityName: "SANTA MARIA DE JETIBA", uf: "ES" },
  },
  { name: "SANTA TERESA", value: { cityName: "SANTA TERESA", uf: "ES" } },
  { name: "MIRANTE", value: { cityName: "MIRANTE", uf: "BA" } },
  {
    name: "MIRANTE DA SERRA",
    value: { cityName: "MIRANTE DA SERRA", uf: "RO" },
  },
  { name: "MIRASELVA", value: { cityName: "MIRASELVA", uf: "PR" } },
  { name: "MIRASSOL DOESTE", value: { cityName: "MIRASSOL DOESTE", uf: "MT" } },
  { name: "MIRAVANIA", value: { cityName: "MIRAVANIA", uf: "MG" } },
  { name: "MIRINZAL", value: { cityName: "MIRINZAL", uf: "MA" } },
  {
    name: "SAO DOMINGOS DO NORTE",
    value: { cityName: "SAO DOMINGOS DO NORTE", uf: "ES" },
  },
  {
    name: "SAO GABRIEL DA PALHA",
    value: { cityName: "SAO GABRIEL DA PALHA", uf: "ES" },
  },
  { name: "MISSAL", value: { cityName: "MISSAL", uf: "PR" } },
  { name: "MOGI GUACU", value: { cityName: "MOGI GUACU", uf: "SP" } },
  { name: "MOGI MIRIM", value: { cityName: "MOGI MIRIM", uf: "SP" } },
  { name: "MOIPORA", value: { cityName: "MOIPORA", uf: "GO" } },
  { name: "MOITA BONITA", value: { cityName: "MOITA BONITA", uf: "SE" } },
  { name: "MOJU", value: { cityName: "MOJU", uf: "PA" } },
  { name: "MOMBUCA", value: { cityName: "MOMBUCA", uf: "SP" } },
  {
    name: "SAO JOSE DO CALCADO",
    value: { cityName: "SAO JOSE DO CALCADO", uf: "ES" },
  },
  { name: "MONCOES", value: { cityName: "MONCOES", uf: "SP" } },
  { name: "SAO MATEUS", value: { cityName: "SAO MATEUS", uf: "ES" } },
  { name: "MONSENHOR GIL", value: { cityName: "MONSENHOR GIL", uf: "PI" } },
  {
    name: "MONSENHOR HIPOLITO",
    value: { cityName: "MONSENHOR HIPOLITO", uf: "PI" },
  },
  {
    name: "MONSENHOR TABOSA",
    value: { cityName: "MONSENHOR TABOSA", uf: "CE" },
  },
  { name: "MONTANHAS", value: { cityName: "MONTANHAS", uf: "RN" } },
  {
    name: "MONTE ALEGRE DE SERGIPE",
    value: { cityName: "MONTE ALEGRE DE SERGIPE", uf: "SE" },
  },
  {
    name: "MONTE ALEGRE DO PIAUI",
    value: { cityName: "MONTE ALEGRE DO PIAUI", uf: "PI" },
  },
  {
    name: "MONTE ALEGRE DO SUL",
    value: { cityName: "MONTE ALEGRE DO SUL", uf: "SP" },
  },
  {
    name: "MONTE DAS GAMELEIRAS",
    value: { cityName: "MONTE DAS GAMELEIRAS", uf: "RN" },
  },
  { name: "MONTE DO CARMO", value: { cityName: "MONTE DO CARMO", uf: "TO" } },
  { name: "MONTE FORMOSO", value: { cityName: "MONTE FORMOSO", uf: "MG" } },
  { name: "MONTE HOREBE", value: { cityName: "MONTE HOREBE", uf: "PB" } },
  { name: "MONTE NEGRO", value: { cityName: "MONTE NEGRO", uf: "RO" } },
  { name: "VARGEM ALTA", value: { cityName: "VARGEM ALTA", uf: "ES" } },
  {
    name: "VENDA NOVA DO IMIGRANTE",
    value: { cityName: "VENDA NOVA DO IMIGRANTE", uf: "ES" },
  },
  { name: "VIANA", value: { cityName: "VIANA", uf: "ES" } },
  { name: "MONTEIROPOLIS", value: { cityName: "MONTEIROPOLIS", uf: "AL" } },
  { name: "MONTES ALTOS", value: { cityName: "MONTES ALTOS", uf: "MA" } },
  { name: "MONTEZUMA", value: { cityName: "MONTEZUMA", uf: "MG" } },
  {
    name: "MONTIVIDIU DO NORTE",
    value: { cityName: "MONTIVIDIU DO NORTE", uf: "GO" },
  },
  { name: "MORRETES", value: { cityName: "MORRETES", uf: "PR" } },
  {
    name: "MORRO AGUDO DE GOIAS",
    value: { cityName: "MORRO AGUDO DE GOIAS", uf: "GO" },
  },
  {
    name: "MORRO CABECA NO TEMPO",
    value: { cityName: "MORRO CABECA NO TEMPO", uf: "PI" },
  },
  {
    name: "MORRO DO CHAPEU DO PIAUI",
    value: { cityName: "MORRO DO CHAPEU DO PIAUI", uf: "PI" },
  },
  { name: "MORRO REUTER", value: { cityName: "MORRO REUTER", uf: "RS" } },
  { name: "MORROS", value: { cityName: "MORROS", uf: "MA" } },
  { name: "MOZARLANDIA", value: { cityName: "MOZARLANDIA", uf: "GO" } },
  { name: "MUANA", value: { cityName: "MUANA", uf: "PA" } },
  { name: "MUCAJAI", value: { cityName: "MUCAJAI", uf: "RR" } },
  {
    name: "MULUNGU DO MORRO",
    value: { cityName: "MULUNGU DO MORRO", uf: "BA" },
  },
  {
    name: "MUQUEM DE SAO FRANCISCO",
    value: { cityName: "MUQUEM DE SAO FRANCISCO", uf: "BA" },
  },
  {
    name: "MURICI DOS PORTELAS",
    value: { cityName: "MURICI DOS PORTELAS", uf: "PI" },
  },
  { name: "MURICILANDIA", value: { cityName: "MURICILANDIA", uf: "TO" } },
  {
    name: "MURUTINGA DO SUL",
    value: { cityName: "MURUTINGA DO SUL", uf: "SP" },
  },
  { name: "NANTES", value: { cityName: "NANTES", uf: "SP" } },
  { name: "NAQUE", value: { cityName: "NAQUE", uf: "MG" } },
  { name: "NATALANDIA", value: { cityName: "NATALANDIA", uf: "MG" } },
  { name: "NATERCIA", value: { cityName: "NATERCIA", uf: "MG" } },
  {
    name: "NATIVIDADE DA SERRA",
    value: { cityName: "NATIVIDADE DA SERRA", uf: "SP" },
  },
  { name: "NAVIRAI", value: { cityName: "NAVIRAI", uf: "MS" } },
  { name: "NAZAREZINHO", value: { cityName: "NAZAREZINHO", uf: "PB" } },
  { name: "NAZARIA", value: { cityName: "NAZARIA", uf: "PI" } },
  { name: "NILO PECANHA", value: { cityName: "NILO PECANHA", uf: "BA" } },
  { name: "NINHEIRA", value: { cityName: "NINHEIRA", uf: "MG" } },
  { name: "NIOAQUE", value: { cityName: "NIOAQUE", uf: "MS" } },
  { name: "NOBRES", value: { cityName: "NOBRES", uf: "MT" } },
  { name: "NORTELANDIA", value: { cityName: "NORTELANDIA", uf: "MT" } },
  {
    name: "NOSSA SENHORA DA GLORIA",
    value: { cityName: "NOSSA SENHORA DA GLORIA", uf: "SE" },
  },
  {
    name: "NOSSA SENHORA DAS GRACAS",
    value: { cityName: "NOSSA SENHORA DAS GRACAS", uf: "PR" },
  },
  {
    name: "NOSSA SENHORA DE LOURDES",
    value: { cityName: "NOSSA SENHORA DE LOURDES", uf: "SE" },
  },
  {
    name: "NOSSA SENHORA DE NAZARE",
    value: { cityName: "NOSSA SENHORA DE NAZARE", uf: "PI" },
  },
  {
    name: "NOSSA SENHORA DO LIVRAMENTO",
    value: { cityName: "NOSSA SENHORA DO LIVRAMENTO", uf: "MT" },
  },
  {
    name: "NOVA ALIANCA DO IVAI",
    value: { cityName: "NOVA ALIANCA DO IVAI", uf: "PR" },
  },
  {
    name: "NOVA ALVORADA DO SUL",
    value: { cityName: "NOVA ALVORADA DO SUL", uf: "MS" },
  },
  {
    name: "NOVA AMERICA DA COLINA",
    value: { cityName: "NOVA AMERICA DA COLINA", uf: "PR" },
  },
  { name: "NOVA ANDRADINA", value: { cityName: "NOVA ANDRADINA", uf: "MS" } },
  {
    name: "NOVA BANDEIRANTES",
    value: { cityName: "NOVA BANDEIRANTES", uf: "MT" },
  },
  { name: "NOVA BELEM", value: { cityName: "NOVA BELEM", uf: "MG" } },
  {
    name: "NOVA BRASILANDIA",
    value: { cityName: "NOVA BRASILANDIA", uf: "MT" },
  },
  {
    name: "NOVA BRASILANDIA DOESTE",
    value: { cityName: "NOVA BRASILANDIA DOESTE", uf: "RO" },
  },
  { name: "NOVA CAMPINA", value: { cityName: "NOVA CAMPINA", uf: "SP" } },
  {
    name: "NOVA CANAA DO NORTE",
    value: { cityName: "NOVA CANAA DO NORTE", uf: "MT" },
  },
  {
    name: "NOVA CANAA PAULISTA",
    value: { cityName: "NOVA CANAA PAULISTA", uf: "SP" },
  },
  { name: "NOVA CANTU", value: { cityName: "NOVA CANTU", uf: "PR" } },
  { name: "NOVA CASTILHO", value: { cityName: "NOVA CASTILHO", uf: "SP" } },
  { name: "NOVA COLINAS", value: { cityName: "NOVA COLINAS", uf: "MA" } },
  {
    name: "NOVA ESPERANCA DO PIRIA",
    value: { cityName: "NOVA ESPERANCA DO PIRIA", uf: "PA" },
  },
  {
    name: "CAMPO BELO DO SUL",
    value: { cityName: "CAMPO BELO DO SUL", uf: "SC" },
  },
  { name: "NOVA GUARITA", value: { cityName: "NOVA GUARITA", uf: "MT" } },
  {
    name: "NOVA GUATAPORANGA",
    value: { cityName: "NOVA GUATAPORANGA", uf: "SP" },
  },
  { name: "NOVA IBIA", value: { cityName: "NOVA IBIA", uf: "BA" } },
  {
    name: "NOVA IGUACU DE GOIAS",
    value: { cityName: "NOVA IGUACU DE GOIAS", uf: "GO" },
  },
  {
    name: "NOVA INDEPENDENCIA",
    value: { cityName: "NOVA INDEPENDENCIA", uf: "SP" },
  },
  { name: "NOVA IORQUE", value: { cityName: "NOVA IORQUE", uf: "MA" } },
  { name: "NOVA IPIXUNA", value: { cityName: "NOVA IPIXUNA", uf: "PA" } },
  { name: "NOVA ITARANA", value: { cityName: "NOVA ITARANA", uf: "BA" } },
  { name: "NOVA LACERDA", value: { cityName: "NOVA LACERDA", uf: "MT" } },
  { name: "NOVA LUZITANIA", value: { cityName: "NOVA LUZITANIA", uf: "SP" } },
  { name: "NOVA MAMORE", value: { cityName: "NOVA MAMORE", uf: "RO" } },
  { name: "NOVA MARILANDIA", value: { cityName: "NOVA MARILANDIA", uf: "MT" } },
  {
    name: "SAO FRANCISCO DO SUL",
    value: { cityName: "SAO FRANCISCO DO SUL", uf: "SC" },
  },
  { name: "NOVA MARINGA", value: { cityName: "NOVA MARINGA", uf: "MT" } },
  {
    name: "NOVA MONTE VERDE",
    value: { cityName: "NOVA MONTE VERDE", uf: "MT" },
  },
  { name: "NOVA MUTUM", value: { cityName: "NOVA MUTUM", uf: "MT" } },
  { name: "NOVA NAZARE", value: { cityName: "NOVA NAZARE", uf: "MT" } },
  { name: "NOVA ODESSA", value: { cityName: "NOVA ODESSA", uf: "SP" } },
  { name: "NOVA OLIMPIA", value: { cityName: "NOVA OLIMPIA", uf: "MT" } },
  { name: "NOVA OLINDA", value: { cityName: "NOVA OLINDA", uf: "CE" } },
  {
    name: "NOVA OLINDA DO MARANHAO",
    value: { cityName: "NOVA OLINDA DO MARANHAO", uf: "MA" },
  },
  {
    name: "NOVA OLINDA DO NORTE",
    value: { cityName: "NOVA OLINDA DO NORTE", uf: "AM" },
  },
  { name: "NOVA PALMEIRA", value: { cityName: "NOVA PALMEIRA", uf: "PB" } },
  {
    name: "NOVA PORTEIRINHA",
    value: { cityName: "NOVA PORTEIRINHA", uf: "MG" },
  },
  {
    name: "NOVA PRATA DO IGUACU",
    value: { cityName: "NOVA PRATA DO IGUACU", uf: "PR" },
  },
  { name: "NOVA ROSALANDIA", value: { cityName: "NOVA ROSALANDIA", uf: "TO" } },
  {
    name: "NOVA SANTA BARBARA",
    value: { cityName: "NOVA SANTA BARBARA", uf: "PR" },
  },
  {
    name: "NOVA SANTA HELENA",
    value: { cityName: "NOVA SANTA HELENA", uf: "MT" },
  },
  { name: "NOVA SANTA ROSA", value: { cityName: "NOVA SANTA ROSA", uf: "PR" } },
  { name: "NOVA TEBAS", value: { cityName: "NOVA TEBAS", uf: "PR" } },
  { name: "NOVA UBIRATA", value: { cityName: "NOVA UBIRATA", uf: "MT" } },
  { name: "NOVA XAVANTINA", value: { cityName: "NOVA XAVANTINA", uf: "MT" } },
  { name: "NOVO ACORDO", value: { cityName: "NOVO ACORDO", uf: "TO" } },
  { name: "NOVO AIRAO", value: { cityName: "NOVO AIRAO", uf: "AM" } },
  { name: "NOVO ALEGRE", value: { cityName: "NOVO ALEGRE", uf: "TO" } },
  { name: "NOVO ARIPUANA", value: { cityName: "NOVO ARIPUANA", uf: "AM" } },
  { name: "NOVO GAMA", value: { cityName: "NOVO GAMA", uf: "GO" } },
  {
    name: "NOVO HORIZONTE DO NORTE",
    value: { cityName: "NOVO HORIZONTE DO NORTE", uf: "MT" },
  },
  {
    name: "NOVO HORIZONTE DO OESTE",
    value: { cityName: "NOVO HORIZONTE DO OESTE", uf: "RO" },
  },
  {
    name: "NOVO HORIZONTE DO SUL",
    value: { cityName: "NOVO HORIZONTE DO SUL", uf: "MS" },
  },
  { name: "NOVO ITACOLOMI", value: { cityName: "NOVO ITACOLOMI", uf: "PR" } },
  { name: "NOVO JARDIM", value: { cityName: "NOVO JARDIM", uf: "TO" } },
  { name: "NOVO LINO", value: { cityName: "NOVO LINO", uf: "AL" } },
  { name: "NOVO MUNDO", value: { cityName: "NOVO MUNDO", uf: "MT" } },
  {
    name: "NOVO ORIENTE DE MINAS",
    value: { cityName: "NOVO ORIENTE DE MINAS", uf: "MG" },
  },
  {
    name: "NOVO ORIENTE DO PIAUI",
    value: { cityName: "NOVO ORIENTE DO PIAUI", uf: "PI" },
  },
  { name: "NOVO PLANALTO", value: { cityName: "NOVO PLANALTO", uf: "GO" } },
  { name: "NOVO PROGRESSO", value: { cityName: "NOVO PROGRESSO", uf: "PA" } },
  {
    name: "NOVO REPARTIMENTO",
    value: { cityName: "NOVO REPARTIMENTO", uf: "PA" },
  },
  {
    name: "NOVO SAO JOAQUIM",
    value: { cityName: "NOVO SAO JOAQUIM", uf: "MT" },
  },
  { name: "NOVO TRIUNFO", value: { cityName: "NOVO TRIUNFO", uf: "BA" } },
  { name: "NOVO XINGU", value: { cityName: "NOVO XINGU", uf: "RS" } },
  { name: "NOVORIZONTE", value: { cityName: "NOVORIZONTE", uf: "MG" } },
  { name: "OEIRAS DO PARA", value: { cityName: "OEIRAS DO PARA", uf: "PA" } },
  { name: "OLEO", value: { cityName: "OLEO", uf: "SP" } },
  { name: "OLHO DAGUA", value: { cityName: "OLHO DAGUA", uf: "PB" } },
  {
    name: "OLHO DAGUA DAS CUNHAS",
    value: { cityName: "OLHO DAGUA DAS CUNHAS", uf: "MA" },
  },
  {
    name: "OLHO DAGUA DAS FLORES",
    value: { cityName: "OLHO DAGUA DAS FLORES", uf: "AL" },
  },
  {
    name: "OLHO DAGUA DO BORGES",
    value: { cityName: "OLHO DAGUA DO BORGES", uf: "RN" },
  },
  {
    name: "OLHO DAGUA DO CASADO",
    value: { cityName: "OLHO DAGUA DO CASADO", uf: "AL" },
  },
  {
    name: "OLHO DAGUA DO PIAUI",
    value: { cityName: "OLHO DAGUA DO PIAUI", uf: "PI" },
  },
  {
    name: "OLHO DAGUA GRANDE",
    value: { cityName: "OLHO DAGUA GRANDE", uf: "AL" },
  },
  { name: "OLHOS-DAGUA", value: { cityName: "OLHOS-DAGUA", uf: "MG" } },
  {
    name: "OLINDA NOVA DO MARANHAO",
    value: { cityName: "OLINDA NOVA DO MARANHAO", uf: "MA" },
  },
  { name: "OLIVEDOS", value: { cityName: "OLIVEDOS", uf: "PB" } },
  {
    name: "OLIVEIRA DE FATIMA",
    value: { cityName: "OLIVEIRA DE FATIMA", uf: "TO" },
  },
  { name: "OLIVENCA", value: { cityName: "OLIVENCA", uf: "AL" } },
  { name: "ORIENTE", value: { cityName: "ORIENTE", uf: "SP" } },
  { name: "ORIZANIA", value: { cityName: "ORIZANIA", uf: "MG" } },
  { name: "OROBO", value: { cityName: "OROBO", uf: "PE" } },
  {
    name: "OURILANDIA DO NORTE",
    value: { cityName: "OURILANDIA DO NORTE", uf: "PA" },
  },
  { name: "OURIZONA", value: { cityName: "OURIZONA", uf: "PR" } },
  {
    name: "OURO PRETO DO OESTE",
    value: { cityName: "OURO PRETO DO OESTE", uf: "RO" },
  },
  {
    name: "OURO VERDE DE GOIAS",
    value: { cityName: "OURO VERDE DE GOIAS", uf: "GO" },
  },
  {
    name: "OURO VERDE DO OESTE",
    value: { cityName: "OURO VERDE DO OESTE", uf: "PR" },
  },
  { name: "OUROESTE", value: { cityName: "OUROESTE", uf: "SP" } },
  { name: "OUROLANDIA", value: { cityName: "OUROLANDIA", uf: "BA" } },
  { name: "PACAJA", value: { cityName: "PACAJA", uf: "PA" } },
  { name: "PACARAIMA", value: { cityName: "PACARAIMA", uf: "RR" } },
  { name: "PADRE CARVALHO", value: { cityName: "PADRE CARVALHO", uf: "MG" } },
  { name: "PADRE MARCOS", value: { cityName: "PADRE MARCOS", uf: "PI" } },
  { name: "PAI PEDRO", value: { cityName: "PAI PEDRO", uf: "MG" } },
  { name: "PAIVA", value: { cityName: "PAIVA", uf: "MG" } },
  { name: "PAJEU DO PIAUI", value: { cityName: "PAJEU DO PIAUI", uf: "PI" } },
  {
    name: "PALESTINA DO PARA",
    value: { cityName: "PALESTINA DO PARA", uf: "PA" },
  },
  { name: "PALMARES", value: { cityName: "PALMARES", uf: "PE" } },
  { name: "PALMEIRAIS", value: { cityName: "PALMEIRAIS", uf: "PI" } },
  { name: "PALMEIRANDIA", value: { cityName: "PALMEIRANDIA", uf: "MA" } },
  { name: "PALMEIRANTE", value: { cityName: "PALMEIRANTE", uf: "TO" } },
  {
    name: "PALMEIRAS DO TOCANTINS",
    value: { cityName: "PALMEIRAS DO TOCANTINS", uf: "TO" },
  },
  { name: "PALMEIROPOLIS", value: { cityName: "PALMEIROPOLIS", uf: "TO" } },
  { name: "PALMELO", value: { cityName: "PALMELO", uf: "GO" } },
  { name: "PALMINOPOLIS", value: { cityName: "PALMINOPOLIS", uf: "GO" } },
  { name: "PALOTINA", value: { cityName: "PALOTINA", uf: "PR" } },
  { name: "PAQUETA", value: { cityName: "PAQUETA", uf: "PI" } },
  {
    name: "PARAISO DAS AGUAS",
    value: { cityName: "PARAISO DAS AGUAS", uf: "MS" },
  },
  {
    name: "PARAISO DO TOCANTINS",
    value: { cityName: "PARAISO DO TOCANTINS", uf: "TO" },
  },
  { name: "PARANACITY", value: { cityName: "PARANACITY", uf: "PR" } },
  { name: "PARANAIBA", value: { cityName: "PARANAIBA", uf: "MS" } },
  { name: "PARANAITA", value: { cityName: "PARANAITA", uf: "MT" } },
  { name: "PARANAPUA", value: { cityName: "PARANAPUA", uf: "SP" } },
  { name: "PARANATINGA", value: { cityName: "PARANATINGA", uf: "MT" } },
  { name: "PARANHOS", value: { cityName: "PARANHOS", uf: "MS" } },
  { name: "PARARI", value: { cityName: "PARARI", uf: "PB" } },
  { name: "PARAU", value: { cityName: "PARAU", uf: "RN" } },
  { name: "PARAUNA", value: { cityName: "PARAUNA", uf: "GO" } },
  { name: "PARAZINHO", value: { cityName: "PARAZINHO", uf: "RN" } },
  { name: "PARECIS", value: { cityName: "PARECIS", uf: "RO" } },
  { name: "PARELHAS", value: { cityName: "PARELHAS", uf: "RN" } },
  { name: "PARICONHA", value: { cityName: "PARICONHA", uf: "AL" } },
  { name: "PARIPUEIRA", value: { cityName: "PARIPUEIRA", uf: "AL" } },
  { name: "PARISI", value: { cityName: "PARISI", uf: "SP" } },
  { name: "PARNARAMA", value: { cityName: "PARNARAMA", uf: "MA" } },
  { name: "PASSA E FICA", value: { cityName: "PASSA E FICA", uf: "RN" } },
  { name: "PASSAGEM FRANCA", value: { cityName: "PASSAGEM FRANCA", uf: "MA" } },
  {
    name: "PASSAGEM FRANCA DO PIAUI",
    value: { cityName: "PASSAGEM FRANCA DO PIAUI", uf: "PI" },
  },
  { name: "PASSA-QUATRO", value: { cityName: "PASSA-QUATRO", uf: "MG" } },
  { name: "PASSA-SETE", value: { cityName: "PASSA-SETE", uf: "RS" } },
  { name: "PASSA-VINTE", value: { cityName: "PASSA-VINTE", uf: "MG" } },
  { name: "PASSIRA", value: { cityName: "PASSIRA", uf: "PE" } },
  {
    name: "PASSO DE CAMARAGIBE",
    value: { cityName: "PASSO DE CAMARAGIBE", uf: "AL" },
  },
  { name: "PASTOS BONS", value: { cityName: "PASTOS BONS", uf: "MA" } },
  { name: "PATI DO ALFERES", value: { cityName: "PATI DO ALFERES", uf: "RJ" } },
  { name: "PATIS", value: { cityName: "PATIS", uf: "MG" } },
  { name: "PATO BRAGADO", value: { cityName: "PATO BRAGADO", uf: "PR" } },
  { name: "PATOS DO PIAUI", value: { cityName: "PATOS DO PIAUI", uf: "PI" } },
  { name: "PAU BRASIL", value: { cityName: "PAU BRASIL", uf: "BA" } },
  { name: "PAU DARCO", value: { cityName: "PAU DARCO", uf: "PA" } },
  {
    name: "PAU DARCO DO PIAUI",
    value: { cityName: "PAU DARCO DO PIAUI", uf: "PI" },
  },
  { name: "PAUINI", value: { cityName: "PAUINI", uf: "AM" } },
  { name: "PAULINO NEVES", value: { cityName: "PAULINO NEVES", uf: "MA" } },
  { name: "PAULISTANIA", value: { cityName: "PAULISTANIA", uf: "SP" } },
  { name: "PAULO FRONTIN", value: { cityName: "PAULO FRONTIN", uf: "PR" } },
  { name: "PAVUSSU", value: { cityName: "PAVUSSU", uf: "PI" } },
  { name: "PE DE SERRA", value: { cityName: "PE DE SERRA", uf: "BA" } },
  { name: "PEABIRU", value: { cityName: "PEABIRU", uf: "PR" } },
  { name: "PEDRA BELA", value: { cityName: "PEDRA BELA", uf: "SP" } },
  { name: "PEDRA BONITA", value: { cityName: "PEDRA BONITA", uf: "MG" } },
  {
    name: "PEDRA BRANCA DO AMAPARI",
    value: { cityName: "PEDRA BRANCA DO AMAPARI", uf: "AP" },
  },
  { name: "PEDRA MOLE", value: { cityName: "PEDRA MOLE", uf: "SE" } },
  { name: "PEDRA PRETA", value: { cityName: "PEDRA PRETA", uf: "MT" } },
  {
    name: "PEDRAS DE MARIA DA CRUZ",
    value: { cityName: "PEDRAS DE MARIA DA CRUZ", uf: "MG" },
  },
  { name: "PEDRINHAS", value: { cityName: "PEDRINHAS", uf: "SE" } },
  {
    name: "PEDRINHAS PAULISTA",
    value: { cityName: "PEDRINHAS PAULISTA", uf: "SP" },
  },
  { name: "PEDRO AFONSO", value: { cityName: "PEDRO AFONSO", uf: "TO" } },
  { name: "PEDRO ALEXANDRE", value: { cityName: "PEDRO ALEXANDRE", uf: "BA" } },
  { name: "PEDRO AVELINO", value: { cityName: "PEDRO AVELINO", uf: "RN" } },
  {
    name: "PEDRO DO ROSARIO",
    value: { cityName: "PEDRO DO ROSARIO", uf: "MA" },
  },
  { name: "PEDRO GOMES", value: { cityName: "PEDRO GOMES", uf: "MS" } },
  {
    name: "PEDRO LAURENTINO",
    value: { cityName: "PEDRO LAURENTINO", uf: "PI" },
  },
  { name: "PEDRO OSORIO", value: { cityName: "PEDRO OSORIO", uf: "RS" } },
  { name: "PEDRO REGIS", value: { cityName: "PEDRO REGIS", uf: "PB" } },
  { name: "PEDRO TEIXEIRA", value: { cityName: "PEDRO TEIXEIRA", uf: "MG" } },
  { name: "PEDRO VELHO", value: { cityName: "PEDRO VELHO", uf: "RN" } },
  { name: "PEIXE", value: { cityName: "PEIXE", uf: "TO" } },
  {
    name: "PEIXOTO DE AZEVEDO",
    value: { cityName: "PEIXOTO DE AZEVEDO", uf: "MT" },
  },
  { name: "PEQUIZEIRO", value: { cityName: "PEQUIZEIRO", uf: "TO" } },
  { name: "PERIQUITO", value: { cityName: "PERIQUITO", uf: "MG" } },
  { name: "PERITORO", value: { cityName: "PERITORO", uf: "MA" } },
  { name: "PEROLA DOESTE", value: { cityName: "PEROLA DOESTE", uf: "PR" } },
  { name: "PETROLINA", value: { cityName: "PETROLINA", uf: "PE" } },
  { name: "PIACABUCU", value: { cityName: "PIACABUCU", uf: "AL" } },
  { name: "PICARRA", value: { cityName: "PICARRA", uf: "PA" } },
  { name: "PICUI", value: { cityName: "PICUI", uf: "PB" } },
  {
    name: "PIEDADE DE CARATINGA",
    value: { cityName: "PIEDADE DE CARATINGA", uf: "MG" },
  },
  { name: "PIEN", value: { cityName: "PIEN", uf: "PR" } },
  { name: "PILOES", value: { cityName: "PILOES", uf: "PB" } },
  { name: "PILOEZINHOS", value: { cityName: "PILOEZINHOS", uf: "PB" } },
  { name: "PIMENTA BUENO", value: { cityName: "PIMENTA BUENO", uf: "RO" } },
  {
    name: "PIMENTEIRAS DO OESTE",
    value: { cityName: "PIMENTEIRAS DO OESTE", uf: "RO" },
  },
  { name: "PINDARE-MIRIM", value: { cityName: "PINDARE-MIRIM", uf: "MA" } },
  {
    name: "PINDORAMA DO TOCANTINS",
    value: { cityName: "PINDORAMA DO TOCANTINS", uf: "TO" },
  },
  { name: "PINGO DAGUA", value: { cityName: "PINGO DAGUA", uf: "MG" } },
  { name: "PINHAL DA SERRA", value: { cityName: "PINHAL DA SERRA", uf: "RS" } },
  {
    name: "PINHAL DE SAO BENTO",
    value: { cityName: "PINHAL DE SAO BENTO", uf: "PR" },
  },
  { name: "PINHALAO", value: { cityName: "PINHALAO", uf: "PR" } },
  { name: "PINHEIRAL", value: { cityName: "PINHEIRAL", uf: "RJ" } },
  { name: "PINTOPOLIS", value: { cityName: "PINTOPOLIS", uf: "MG" } },
  { name: "PIO IX", value: { cityName: "PIO IX", uf: "PI" } },
  { name: "PIO XII", value: { cityName: "PIO XII", uf: "MA" } },
  { name: "PIRAI DO NORTE", value: { cityName: "PIRAI DO NORTE", uf: "BA" } },
  { name: "PIRAMBU", value: { cityName: "PIRAMBU", uf: "SE" } },
  { name: "PIRANGI", value: { cityName: "PIRANGI", uf: "SP" } },
  { name: "PIRANGUCU", value: { cityName: "PIRANGUCU", uf: "MG" } },
  { name: "PIRAPETINGA", value: { cityName: "PIRAPETINGA", uf: "MG" } },
  { name: "PIRAQUE", value: { cityName: "PIRAQUE", uf: "TO" } },
  { name: "PIRIPA", value: { cityName: "PIRIPA", uf: "BA" } },
  { name: "PITANGA", value: { cityName: "PITANGA", uf: "PR" } },
  { name: "PIUM", value: { cityName: "PIUM", uf: "TO" } },
  { name: "PLACAS", value: { cityName: "PLACAS", uf: "PA" } },
  {
    name: "PLACIDO DE CASTRO",
    value: { cityName: "PLACIDO DE CASTRO", uf: "AC" },
  },
  {
    name: "PLANALTINA DO PARANA",
    value: { cityName: "PLANALTINA DO PARANA", uf: "PR" },
  },
  { name: "PLANALTINO", value: { cityName: "PLANALTINO", uf: "BA" } },
  {
    name: "PLANALTO DA SERRA",
    value: { cityName: "PLANALTO DA SERRA", uf: "MT" },
  },
  { name: "POCAO", value: { cityName: "POCAO", uf: "PE" } },
  { name: "POCINHOS", value: { cityName: "POCINHOS", uf: "PB" } },
  { name: "POCO BRANCO", value: { cityName: "POCO BRANCO", uf: "RN" } },
  { name: "POCO DANTAS", value: { cityName: "POCO DANTAS", uf: "PB" } },
  {
    name: "POCO DAS TRINCHEIRAS",
    value: { cityName: "POCO DAS TRINCHEIRAS", uf: "AL" },
  },
  {
    name: "POCO DE JOSE DE MOURA",
    value: { cityName: "POCO DE JOSE DE MOURA", uf: "PB" },
  },
  { name: "POCO REDONDO", value: { cityName: "POCO REDONDO", uf: "SE" } },
  { name: "POCO VERDE", value: { cityName: "POCO VERDE", uf: "SE" } },
  { name: "PONTA DE PEDRAS", value: { cityName: "PONTA DE PEDRAS", uf: "PA" } },
  {
    name: "PONTAL DO ARAGUAIA",
    value: { cityName: "PONTAL DO ARAGUAIA", uf: "MT" },
  },
  { name: "PONTALINDA", value: { cityName: "PONTALINDA", uf: "SP" } },
  {
    name: "PONTE ALTA DO BOM JESUS",
    value: { cityName: "PONTE ALTA DO BOM JESUS", uf: "TO" },
  },
  {
    name: "PONTE ALTA DO TOCANTINS",
    value: { cityName: "PONTE ALTA DO TOCANTINS", uf: "TO" },
  },
  { name: "PONTE BRANCA", value: { cityName: "PONTE BRANCA", uf: "MT" } },
  { name: "PONTE PRETA", value: { cityName: "PONTE PRETA", uf: "RS" } },
  {
    name: "PONTES E LACERDA",
    value: { cityName: "PONTES E LACERDA", uf: "MT" },
  },
  { name: "PONTO BELO", value: { cityName: "PONTO BELO", uf: "ES" } },
  { name: "PONTO CHIQUE", value: { cityName: "PONTO CHIQUE", uf: "MG" } },
  {
    name: "PONTO DOS VOLANTES",
    value: { cityName: "PONTO DOS VOLANTES", uf: "MG" },
  },
  { name: "PONTO NOVO", value: { cityName: "PONTO NOVO", uf: "BA" } },
  { name: "PORTEIRAO", value: { cityName: "PORTEIRAO", uf: "GO" } },
  { name: "PORTEL", value: { cityName: "PORTEL", uf: "PA" } },
  {
    name: "PORTO ALEGRE DO NORTE",
    value: { cityName: "PORTO ALEGRE DO NORTE", uf: "MT" },
  },
  {
    name: "PORTO ALEGRE DO PIAUI",
    value: { cityName: "PORTO ALEGRE DO PIAUI", uf: "PI" },
  },
  {
    name: "PORTO ALEGRE DO TOCANTINS",
    value: { cityName: "PORTO ALEGRE DO TOCANTINS", uf: "TO" },
  },
  { name: "PORTO BARREIRO", value: { cityName: "PORTO BARREIRO", uf: "PR" } },
  { name: "PORTO DE MOZ", value: { cityName: "PORTO DE MOZ", uf: "PA" } },
  { name: "PORTO DE PEDRAS", value: { cityName: "PORTO DE PEDRAS", uf: "AL" } },
  { name: "PORTO DO MANGUE", value: { cityName: "PORTO DO MANGUE", uf: "RN" } },
  {
    name: "PORTO DOS GAUCHOS",
    value: { cityName: "PORTO DOS GAUCHOS", uf: "MT" },
  },
  {
    name: "PORTO ESPERIDIAO",
    value: { cityName: "PORTO ESPERIDIAO", uf: "MT" },
  },
  { name: "PORTO ESTRELA", value: { cityName: "PORTO ESTRELA", uf: "MT" } },
  { name: "PORTO FRANCO", value: { cityName: "PORTO FRANCO", uf: "MA" } },
  { name: "PORTO MURTINHO", value: { cityName: "PORTO MURTINHO", uf: "MS" } },
  { name: "PORTO NACIONAL", value: { cityName: "PORTO NACIONAL", uf: "TO" } },
  { name: "PORTO REAL", value: { cityName: "PORTO REAL", uf: "RJ" } },
  {
    name: "PORTO REAL DO COLEGIO",
    value: { cityName: "PORTO REAL DO COLEGIO", uf: "AL" },
  },
  { name: "PORTO RICO", value: { cityName: "PORTO RICO", uf: "PR" } },
  {
    name: "PORTO RICO DO MARANHAO",
    value: { cityName: "PORTO RICO DO MARANHAO", uf: "MA" },
  },
  { name: "PORTO VITORIA", value: { cityName: "PORTO VITORIA", uf: "PR" } },
  { name: "PORTO WALTER", value: { cityName: "PORTO WALTER", uf: "AC" } },
  { name: "POTIRETAMA", value: { cityName: "POTIRETAMA", uf: "CE" } },
  { name: "POXOREO", value: { cityName: "POXOREO", uf: "MT" } },
  { name: "PRACINHA", value: { cityName: "PRACINHA", uf: "SP" } },
  { name: "PRACUUBA", value: { cityName: "PRACUUBA", uf: "AP" } },
  { name: "PRADOS", value: { cityName: "PRADOS", uf: "MG" } },
  { name: "PRAIA NORTE", value: { cityName: "PRAIA NORTE", uf: "TO" } },
  { name: "PRATA DO PIAUI", value: { cityName: "PRATA DO PIAUI", uf: "PI" } },
  { name: "PRATANIA", value: { cityName: "PRATANIA", uf: "SP" } },
  {
    name: "PRESIDENTE FIGUEIREDO",
    value: { cityName: "PRESIDENTE FIGUEIREDO", uf: "AM" },
  },
  {
    name: "PRESIDENTE KENNEDY",
    value: { cityName: "PRESIDENTE KENNEDY", uf: "ES" },
  },
  {
    name: "PRESIDENTE MEDICI",
    value: { cityName: "PRESIDENTE MEDICI", uf: "MA" },
  },
  {
    name: "PRESIDENTE SARNEY",
    value: { cityName: "PRESIDENTE SARNEY", uf: "MA" },
  },
  {
    name: "PRESIDENTE TANCREDO NEVES",
    value: { cityName: "PRESIDENTE TANCREDO NEVES", uf: "BA" },
  },
  {
    name: "PRIMAVERA DE RONDONIA",
    value: { cityName: "PRIMAVERA DE RONDONIA", uf: "RO" },
  },
  {
    name: "PRIMAVERA DO LESTE",
    value: { cityName: "PRIMAVERA DO LESTE", uf: "MT" },
  },
  { name: "PRIMEIRA CRUZ", value: { cityName: "PRIMEIRA CRUZ", uf: "MA" } },
  {
    name: "PRIMEIRO DE MAIO",
    value: { cityName: "PRIMEIRO DE MAIO", uf: "PR" },
  },
  { name: "PUGMIL", value: { cityName: "PUGMIL", uf: "TO" } },
  { name: "PUREZA", value: { cityName: "PUREZA", uf: "RN" } },
  { name: "QUADRA", value: { cityName: "QUADRA", uf: "SP" } },
  { name: "QUATIGUA", value: { cityName: "QUATIGUA", uf: "PR" } },
  { name: "QUATIPURU", value: { cityName: "QUATIPURU", uf: "PA" } },
  { name: "QUATIS", value: { cityName: "QUATIS", uf: "RJ" } },
  { name: "QUATRO PONTES", value: { cityName: "QUATRO PONTES", uf: "PR" } },
  {
    name: "QUEDAS DO IGUACU",
    value: { cityName: "QUEDAS DO IGUACU", uf: "PR" },
  },
  { name: "QUEIMADA NOVA", value: { cityName: "QUEIMADA NOVA", uf: "PI" } },
  { name: "QUELUZITO", value: { cityName: "QUELUZITO", uf: "MG" } },
  { name: "QUIXABA", value: { cityName: "QUIXABA", uf: "PB" } },
  { name: "QUIXABEIRA", value: { cityName: "QUIXABEIRA", uf: "BA" } },
  { name: "QUIXELO", value: { cityName: "QUIXELO", uf: "CE" } },
  { name: "RAFAEL GODEIRO", value: { cityName: "RAFAEL GODEIRO", uf: "RN" } },
  { name: "RAFAEL JAMBEIRO", value: { cityName: "RAFAEL JAMBEIRO", uf: "BA" } },
  { name: "RAMILANDIA", value: { cityName: "RAMILANDIA", uf: "PR" } },
  {
    name: "RANCHO ALEGRE D OESTE",
    value: { cityName: "RANCHO ALEGRE D OESTE", uf: "PR" },
  },
  { name: "RAPOSA", value: { cityName: "RAPOSA", uf: "MA" } },
  { name: "REBOUCAS", value: { cityName: "REBOUCAS", uf: "PR" } },
  { name: "RECURSOLANDIA", value: { cityName: "RECURSOLANDIA", uf: "TO" } },
  { name: "REDUTO", value: { cityName: "REDUTO", uf: "MG" } },
  { name: "REGENERACAO", value: { cityName: "REGENERACAO", uf: "PI" } },
  { name: "RENASCENCA", value: { cityName: "RENASCENCA", uf: "PR" } },
  { name: "RESERVA", value: { cityName: "RESERVA", uf: "PR" } },
  {
    name: "RESERVA DO CABACAL",
    value: { cityName: "RESERVA DO CABACAL", uf: "MT" },
  },
  { name: "RETIROLANDIA", value: { cityName: "RETIROLANDIA", uf: "BA" } },
  {
    name: "RIACHAO DAS NEVES",
    value: { cityName: "RIACHAO DAS NEVES", uf: "BA" },
  },
  {
    name: "RIACHAO DO BACAMARTE",
    value: { cityName: "RIACHAO DO BACAMARTE", uf: "PB" },
  },
  { name: "RIACHAO DO POCO", value: { cityName: "RIACHAO DO POCO", uf: "PB" } },
  { name: "RIACHINHO", value: { cityName: "RIACHINHO", uf: "MG" } },
  { name: "RIACHO DA CRUZ", value: { cityName: "RIACHO DA CRUZ", uf: "RN" } },
  {
    name: "RIACHO DAS ALMAS",
    value: { cityName: "RIACHO DAS ALMAS", uf: "PE" },
  },
  {
    name: "RIACHO DE SANTO ANTONIO",
    value: { cityName: "RIACHO DE SANTO ANTONIO", uf: "PB" },
  },
  {
    name: "RIACHO DOS CAVALOS",
    value: { cityName: "RIACHO DOS CAVALOS", uf: "PB" },
  },
  { name: "RIACHO FRIO", value: { cityName: "RIACHO FRIO", uf: "PI" } },
  { name: "RIBAMAR FIQUENE", value: { cityName: "RIBAMAR FIQUENE", uf: "MA" } },
  {
    name: "RIBAS DO RIO PARDO",
    value: { cityName: "RIBAS DO RIO PARDO", uf: "MS" },
  },
  {
    name: "RIBEIRA DO PIAUI",
    value: { cityName: "RIBEIRA DO PIAUI", uf: "PI" },
  },
  {
    name: "RIBEIRAO CASCALHEIRA",
    value: { cityName: "RIBEIRAO CASCALHEIRA", uf: "MT" },
  },
  { name: "RIBEIRAO CLARO", value: { cityName: "RIBEIRAO CLARO", uf: "PR" } },
  {
    name: "RIBEIRAO DO LARGO",
    value: { cityName: "RIBEIRAO DO LARGO", uf: "BA" },
  },
  { name: "RIBEIRAO DO SUL", value: { cityName: "RIBEIRAO DO SUL", uf: "SP" } },
  {
    name: "RIBEIRAO DOS INDIOS",
    value: { cityName: "RIBEIRAO DOS INDIOS", uf: "SP" },
  },
  { name: "RIBEIRAOZINHO", value: { cityName: "RIBEIRAOZINHO", uf: "MT" } },
  {
    name: "RIBEIRO GONCALVES",
    value: { cityName: "RIBEIRO GONCALVES", uf: "PI" },
  },
  { name: "RIBEIROPOLIS", value: { cityName: "RIBEIROPOLIS", uf: "SE" } },
  { name: "RIO BANANAL", value: { cityName: "RIO BANANAL", uf: "ES" } },
  { name: "RIO BOM", value: { cityName: "RIO BOM", uf: "PR" } },
  {
    name: "RIO BONITO DO IGUACU",
    value: { cityName: "RIO BONITO DO IGUACU", uf: "PR" },
  },
  {
    name: "RIO BRANCO DO IVAI",
    value: { cityName: "RIO BRANCO DO IVAI", uf: "PR" },
  },
  { name: "RIO BRILHANTE", value: { cityName: "RIO BRILHANTE", uf: "MS" } },
  { name: "RIO CRESPO", value: { cityName: "RIO CRESPO", uf: "RO" } },
  {
    name: "RIO DA CONCEICAO",
    value: { cityName: "RIO DA CONCEICAO", uf: "TO" },
  },
  { name: "RIO DO ANTONIO", value: { cityName: "RIO DO ANTONIO", uf: "BA" } },
  { name: "RIO DO FOGO", value: { cityName: "RIO DO FOGO", uf: "RN" } },
  { name: "RIO DOS BOIS", value: { cityName: "RIO DOS BOIS", uf: "TO" } },
  {
    name: "RIO GRANDE DO PIAUI",
    value: { cityName: "RIO GRANDE DO PIAUI", uf: "PI" },
  },
  { name: "RIO MARIA", value: { cityName: "RIO MARIA", uf: "PA" } },
  {
    name: "RIO PRETO DA EVA",
    value: { cityName: "RIO PRETO DA EVA", uf: "AM" },
  },
  { name: "RIO QUENTE", value: { cityName: "RIO QUENTE", uf: "GO" } },
  { name: "RIO SONO", value: { cityName: "RIO SONO", uf: "TO" } },
  {
    name: "RIO VERDE DE MATO GROSSO",
    value: { cityName: "RIO VERDE DE MATO GROSSO", uf: "MS" },
  },
  { name: "ROCHEDO", value: { cityName: "ROCHEDO", uf: "MS" } },
  { name: "RODELAS", value: { cityName: "RODELAS", uf: "BA" } },
  { name: "RODRIGUES ALVES", value: { cityName: "RODRIGUES ALVES", uf: "AC" } },
  { name: "ROLIM DE MOURA", value: { cityName: "ROLIM DE MOURA", uf: "RO" } },
  { name: "RONCADOR", value: { cityName: "RONCADOR", uf: "PR" } },
  { name: "RONDOLANDIA", value: { cityName: "RONDOLANDIA", uf: "MT" } },
  { name: "RONDON", value: { cityName: "RONDON", uf: "PR" } },
  { name: "RONDONOPOLIS", value: { cityName: "RONDONOPOLIS", uf: "MT" } },
  { name: "RORAINOPOLIS", value: { cityName: "RORAINOPOLIS", uf: "RR" } },
  { name: "ROSANA", value: { cityName: "ROSANA", uf: "SP" } },
  {
    name: "ROSARIO DA LIMEIRA",
    value: { cityName: "ROSARIO DA LIMEIRA", uf: "MG" },
  },
  { name: "ROSARIO DO IVAI", value: { cityName: "ROSARIO DO IVAI", uf: "PR" } },
  { name: "ROSARIO OESTE", value: { cityName: "ROSARIO OESTE", uf: "MT" } },
  { name: "RUBINEIA", value: { cityName: "RUBINEIA", uf: "SP" } },
  { name: "RUROPOLIS", value: { cityName: "RUROPOLIS", uf: "PA" } },
  { name: "SAGRES", value: { cityName: "SAGRES", uf: "SP" } },
  { name: "SAIRE", value: { cityName: "SAIRE", uf: "PE" } },
  {
    name: "SALGADO DE SAO FELIX",
    value: { cityName: "SALGADO DE SAO FELIX", uf: "PB" },
  },
  { name: "SALGADO FILHO", value: { cityName: "SALGADO FILHO", uf: "PR" } },
  { name: "SALMOURAO", value: { cityName: "SALMOURAO", uf: "SP" } },
  { name: "SALOA", value: { cityName: "SALOA", uf: "PE" } },
  { name: "SALTO DO CEU", value: { cityName: "SALTO DO CEU", uf: "MT" } },
  {
    name: "SALTO DO ITARARE",
    value: { cityName: "SALTO DO ITARARE", uf: "PR" },
  },
  { name: "SALTO DO LONTRA", value: { cityName: "SALTO DO LONTRA", uf: "PR" } },
  { name: "SALVATERRA", value: { cityName: "SALVATERRA", uf: "PA" } },
  { name: "SAMBAIBA", value: { cityName: "SAMBAIBA", uf: "MA" } },
  { name: "SAMPAIO", value: { cityName: "SAMPAIO", uf: "TO" } },
  { name: "SANDOLANDIA", value: { cityName: "SANDOLANDIA", uf: "TO" } },
  { name: "SANTA AMELIA", value: { cityName: "SANTA AMELIA", uf: "PR" } },
  {
    name: "SANTA BARBARA DO MONTE VERDE",
    value: { cityName: "SANTA BARBARA DO MONTE VERDE", uf: "MG" },
  },
  {
    name: "SANTA BARBARA DO PARA",
    value: { cityName: "SANTA BARBARA DO PARA", uf: "PA" },
  },
  {
    name: "SANTA BARBARA DO TUGURIO",
    value: { cityName: "SANTA BARBARA DO TUGURIO", uf: "MG" },
  },
  { name: "SANTA CARMEM", value: { cityName: "SANTA CARMEM", uf: "MT" } },
  {
    name: "SANTA CECILIA DO PAVAO",
    value: { cityName: "SANTA CECILIA DO PAVAO", uf: "PR" },
  },
  {
    name: "SANTA CLARA DOESTE",
    value: { cityName: "SANTA CLARA DOESTE", uf: "SP" },
  },
  { name: "SANTA CRUZ", value: { cityName: "SANTA CRUZ", uf: "PB" } },
  {
    name: "SANTA CRUZ CABRALIA",
    value: { cityName: "SANTA CRUZ CABRALIA", uf: "BA" },
  },
  {
    name: "SANTA CRUZ DA BAIXA VERDE",
    value: { cityName: "SANTA CRUZ DA BAIXA VERDE", uf: "PE" },
  },
  {
    name: "SANTA CRUZ DA CONCEICAO",
    value: { cityName: "SANTA CRUZ DA CONCEICAO", uf: "SP" },
  },
  {
    name: "SANTA CRUZ DA ESPERANCA",
    value: { cityName: "SANTA CRUZ DA ESPERANCA", uf: "SP" },
  },
  {
    name: "SANTA CRUZ DA VITORIA",
    value: { cityName: "SANTA CRUZ DA VITORIA", uf: "BA" },
  },
  {
    name: "SANTA CRUZ DE GOIAS",
    value: { cityName: "SANTA CRUZ DE GOIAS", uf: "GO" },
  },
  {
    name: "SANTA CRUZ DE MINAS",
    value: { cityName: "SANTA CRUZ DE MINAS", uf: "MG" },
  },
  {
    name: "SANTA CRUZ DE SALINAS",
    value: { cityName: "SANTA CRUZ DE SALINAS", uf: "MG" },
  },
  {
    name: "SANTA CRUZ DO ARARI",
    value: { cityName: "SANTA CRUZ DO ARARI", uf: "PA" },
  },
  {
    name: "SANTA CRUZ DO CAPIBARIBE",
    value: { cityName: "SANTA CRUZ DO CAPIBARIBE", uf: "PE" },
  },
  {
    name: "SANTA CRUZ DO PIAUI",
    value: { cityName: "SANTA CRUZ DO PIAUI", uf: "PI" },
  },
  {
    name: "SANTA CRUZ DO XINGU",
    value: { cityName: "SANTA CRUZ DO XINGU", uf: "MT" },
  },
  {
    name: "SANTA CRUZ DOS MILAGRES",
    value: { cityName: "SANTA CRUZ DOS MILAGRES", uf: "PI" },
  },
  { name: "SANTA ERNESTINA", value: { cityName: "SANTA ERNESTINA", uf: "SP" } },
  {
    name: "SANTA FE DO ARAGUAIA",
    value: { cityName: "SANTA FE DO ARAGUAIA", uf: "TO" },
  },
  {
    name: "SANTA FILOMENA DO MARANHAO",
    value: { cityName: "SANTA FILOMENA DO MARANHAO", uf: "MA" },
  },
  {
    name: "SANTA HELENA DE MINAS",
    value: { cityName: "SANTA HELENA DE MINAS", uf: "MG" },
  },
  {
    name: "SANTA ISABEL DO IVAI",
    value: { cityName: "SANTA ISABEL DO IVAI", uf: "PR" },
  },
  {
    name: "SANTA ISABEL DO PARA",
    value: { cityName: "SANTA ISABEL DO PARA", uf: "PA" },
  },
  {
    name: "SANTA ISABEL DO RIO NEGRO",
    value: { cityName: "SANTA ISABEL DO RIO NEGRO", uf: "AM" },
  },
  {
    name: "SANTA IZABEL DO OESTE",
    value: { cityName: "SANTA IZABEL DO OESTE", uf: "PR" },
  },
  {
    name: "SANTA LUZIA DO ITANHY",
    value: { cityName: "SANTA LUZIA DO ITANHY", uf: "SE" },
  },
  {
    name: "SANTA LUZIA DO NORTE",
    value: { cityName: "SANTA LUZIA DO NORTE", uf: "AL" },
  },
  {
    name: "SANTA LUZIA DO PARA",
    value: { cityName: "SANTA LUZIA DO PARA", uf: "PA" },
  },
  {
    name: "SANTA LUZIA DO PARUA",
    value: { cityName: "SANTA LUZIA DO PARUA", uf: "MA" },
  },
  {
    name: "SANTA LUZIA DOESTE",
    value: { cityName: "SANTA LUZIA DOESTE", uf: "RO" },
  },
  {
    name: "SANTA MARIA DA VITORIA",
    value: { cityName: "SANTA MARIA DA VITORIA", uf: "BA" },
  },
  {
    name: "SANTA MARIA DAS BARREIRAS",
    value: { cityName: "SANTA MARIA DAS BARREIRAS", uf: "PA" },
  },
  {
    name: "SANTA MARIA DO PARA",
    value: { cityName: "SANTA MARIA DO PARA", uf: "PA" },
  },
  {
    name: "SANTA MARIA DO TOCANTINS",
    value: { cityName: "SANTA MARIA DO TOCANTINS", uf: "TO" },
  },
  { name: "SANTA MERCEDES", value: { cityName: "SANTA MERCEDES", uf: "SP" } },
  { name: "SANTA MONICA", value: { cityName: "SANTA MONICA", uf: "PR" } },
  {
    name: "SANTA RITA DE CALDAS",
    value: { cityName: "SANTA RITA DE CALDAS", uf: "MG" },
  },
  {
    name: "SANTA RITA DE IBITIPOCA",
    value: { cityName: "SANTA RITA DE IBITIPOCA", uf: "MG" },
  },
  {
    name: "SANTA RITA DE MINAS",
    value: { cityName: "SANTA RITA DE MINAS", uf: "MG" },
  },
  {
    name: "SANTA RITA DO ITUETO",
    value: { cityName: "SANTA RITA DO ITUETO", uf: "MG" },
  },
  {
    name: "SANTA RITA DO NOVO DESTINO",
    value: { cityName: "SANTA RITA DO NOVO DESTINO", uf: "GO" },
  },
  {
    name: "SANTA RITA DO PARDO",
    value: { cityName: "SANTA RITA DO PARDO", uf: "MS" },
  },
  {
    name: "SANTA RITA DO PASSA-QUATRO",
    value: { cityName: "SANTA RITA DO PASSA-QUATRO", uf: "SP" },
  },
  {
    name: "SANTA RITA DO TOCANTINS",
    value: { cityName: "SANTA RITA DO TOCANTINS", uf: "TO" },
  },
  {
    name: "SANTA RITA DO TRIVELATO",
    value: { cityName: "SANTA RITA DO TRIVELATO", uf: "MT" },
  },
  {
    name: "SANTA ROSA DA SERRA",
    value: { cityName: "SANTA ROSA DA SERRA", uf: "MG" },
  },
  {
    name: "SANTA ROSA DE GOIAS",
    value: { cityName: "SANTA ROSA DE GOIAS", uf: "GO" },
  },
  {
    name: "SANTA ROSA DO PIAUI",
    value: { cityName: "SANTA ROSA DO PIAUI", uf: "PI" },
  },
  {
    name: "SANTA ROSA DO PURUS",
    value: { cityName: "SANTA ROSA DO PURUS", uf: "AC" },
  },
  {
    name: "SANTA ROSA DO TOCANTINS",
    value: { cityName: "SANTA ROSA DO TOCANTINS", uf: "TO" },
  },
  { name: "SANTA SALETE", value: { cityName: "SANTA SALETE", uf: "SP" } },
  {
    name: "SANTA TEREZA DE GOIAS",
    value: { cityName: "SANTA TEREZA DE GOIAS", uf: "GO" },
  },
  {
    name: "SANTA TEREZA DO TOCANTINS",
    value: { cityName: "SANTA TEREZA DO TOCANTINS", uf: "TO" },
  },
  {
    name: "SANTA TEREZINHA DO TOCANTINS",
    value: { cityName: "SANTA TEREZINHA DO TOCANTINS", uf: "TO" },
  },
  {
    name: "SANTANA DA PONTE PENSA",
    value: { cityName: "SANTANA DA PONTE PENSA", uf: "SP" },
  },
  {
    name: "SANTANA DE MANGUEIRA",
    value: { cityName: "SANTANA DE MANGUEIRA", uf: "PB" },
  },
  {
    name: "SANTANA DO ARAGUAIA",
    value: { cityName: "SANTANA DO ARAGUAIA", uf: "PA" },
  },
  {
    name: "SANTANA DO DESERTO",
    value: { cityName: "SANTANA DO DESERTO", uf: "MG" },
  },
  {
    name: "SANTANA DO GARAMBEU",
    value: { cityName: "SANTANA DO GARAMBEU", uf: "MG" },
  },
  {
    name: "SANTANA DO ITARARE",
    value: { cityName: "SANTANA DO ITARARE", uf: "PR" },
  },
  {
    name: "SANTANA DO MARANHAO",
    value: { cityName: "SANTANA DO MARANHAO", uf: "MA" },
  },
  {
    name: "SANTANA DO MUNDAU",
    value: { cityName: "SANTANA DO MUNDAU", uf: "AL" },
  },
  {
    name: "SANTANA DO PIAUI",
    value: { cityName: "SANTANA DO PIAUI", uf: "PI" },
  },
  {
    name: "SANTANA DO SAO FRANCISCO",
    value: { cityName: "SANTANA DO SAO FRANCISCO", uf: "SE" },
  },
  {
    name: "SANTANA DO SERIDO",
    value: { cityName: "SANTANA DO SERIDO", uf: "RN" },
  },
  {
    name: "SANTANA DOS GARROTES",
    value: { cityName: "SANTANA DOS GARROTES", uf: "PB" },
  },
  { name: "SANTAREM NOVO", value: { cityName: "SANTAREM NOVO", uf: "PA" } },
  { name: "SANTO AFONSO", value: { cityName: "SANTO AFONSO", uf: "MT" } },
  {
    name: "SANTO AMARO DAS BROTAS",
    value: { cityName: "SANTO AMARO DAS BROTAS", uf: "SE" },
  },
  {
    name: "SANTO AMARO DO MARANHAO",
    value: { cityName: "SANTO AMARO DO MARANHAO", uf: "MA" },
  },
  {
    name: "SANTO ANTONIO DE LISBOA",
    value: { cityName: "SANTO ANTONIO DE LISBOA", uf: "PI" },
  },
  {
    name: "SANTO ANTONIO DE POSSE",
    value: { cityName: "SANTO ANTONIO DE POSSE", uf: "SP" },
  },
  {
    name: "SANTO ANTONIO DO AVENTUREIRO",
    value: { cityName: "SANTO ANTONIO DO AVENTUREIRO", uf: "MG" },
  },
  {
    name: "SANTO ANTONIO DO CAIUA",
    value: { cityName: "SANTO ANTONIO DO CAIUA", uf: "PR" },
  },
  {
    name: "SANTO ANTONIO DO ICA",
    value: { cityName: "SANTO ANTONIO DO ICA", uf: "AM" },
  },
  {
    name: "SANTO ANTONIO DO JARDIM",
    value: { cityName: "SANTO ANTONIO DO JARDIM", uf: "SP" },
  },
  {
    name: "SANTO ANTONIO DO LESTE",
    value: { cityName: "SANTO ANTONIO DO LESTE", uf: "MT" },
  },
  {
    name: "SANTO ANTONIO DO LEVERGER",
    value: { cityName: "SANTO ANTONIO DO LEVERGER", uf: "MT" },
  },
  {
    name: "SANTO ANTONIO DO PARAISO",
    value: { cityName: "SANTO ANTONIO DO PARAISO", uf: "PR" },
  },
  {
    name: "SANTO ANTONIO DO RETIRO",
    value: { cityName: "SANTO ANTONIO DO RETIRO", uf: "MG" },
  },
  {
    name: "SANTO ANTONIO DO SUDOESTE",
    value: { cityName: "SANTO ANTONIO DO SUDOESTE", uf: "PR" },
  },
  {
    name: "SANTO ANTONIO DOS LOPES",
    value: { cityName: "SANTO ANTONIO DOS LOPES", uf: "MA" },
  },
  {
    name: "SANTO ANTONIO DOS MILAGRES",
    value: { cityName: "SANTO ANTONIO DOS MILAGRES", uf: "PI" },
  },
  { name: "SANTO EXPEDITO", value: { cityName: "SANTO EXPEDITO", uf: "SP" } },
  { name: "SANTO INACIO", value: { cityName: "SANTO INACIO", uf: "PR" } },
  {
    name: "SANTO INACIO DO PIAUI",
    value: { cityName: "SANTO INACIO DO PIAUI", uf: "PI" },
  },
  {
    name: "SANTOPOLIS DO AGUAPEI",
    value: { cityName: "SANTOPOLIS DO AGUAPEI", uf: "SP" },
  },
  {
    name: "SAO BENEDITO DO RIO PRETO",
    value: { cityName: "SAO BENEDITO DO RIO PRETO", uf: "MA" },
  },
  {
    name: "SAO BENTO DE POMBAL",
    value: { cityName: "SAO BENTO DE POMBAL", uf: "PB" },
  },
  {
    name: "SAO BENTO DO TOCANTINS",
    value: { cityName: "SAO BENTO DO TOCANTINS", uf: "TO" },
  },
  {
    name: "SAO BENTO DO TRAIRI",
    value: { cityName: "SAO BENTO DO TRAIRI", uf: "RN" },
  },
  {
    name: "SAO BRAZ DO PIAUI",
    value: { cityName: "SAO BRAZ DO PIAUI", uf: "PI" },
  },
  { name: "SAO CAETANO", value: { cityName: "SAO CAETANO", uf: "PE" } },
  {
    name: "SAO CARLOS DO IVAI",
    value: { cityName: "SAO CARLOS DO IVAI", uf: "PR" },
  },
  { name: "SAO DESIDERIO", value: { cityName: "SAO DESIDERIO", uf: "BA" } },
  {
    name: "SAO DOMINGOS DAS DORES",
    value: { cityName: "SAO DOMINGOS DAS DORES", uf: "MG" },
  },
  {
    name: "SAO DOMINGOS DO AZEITAO",
    value: { cityName: "SAO DOMINGOS DO AZEITAO", uf: "MA" },
  },
  {
    name: "SAO DOMINGOS DO CAPIM",
    value: { cityName: "SAO DOMINGOS DO CAPIM", uf: "PA" },
  },
  {
    name: "SAO DOMINGOS DO CARIRI",
    value: { cityName: "SAO DOMINGOS DO CARIRI", uf: "PB" },
  },
  {
    name: "SAO DOMINGOS DO MARANHAO",
    value: { cityName: "SAO DOMINGOS DO MARANHAO", uf: "MA" },
  },
  {
    name: "SAO FELIPE DOESTE",
    value: { cityName: "SAO FELIPE DOESTE", uf: "RO" },
  },
  {
    name: "SAO FELIX DO ARAGUAIA",
    value: { cityName: "SAO FELIX DO ARAGUAIA", uf: "MT" },
  },
  {
    name: "SAO FELIX DO CORIBE",
    value: { cityName: "SAO FELIX DO CORIBE", uf: "BA" },
  },
  {
    name: "SAO FELIX DO PIAUI",
    value: { cityName: "SAO FELIX DO PIAUI", uf: "PI" },
  },
  {
    name: "SAO FELIX DO TOCANTINS",
    value: { cityName: "SAO FELIX DO TOCANTINS", uf: "TO" },
  },
  {
    name: "SAO FELIX DO XINGU",
    value: { cityName: "SAO FELIX DO XINGU", uf: "PA" },
  },
  {
    name: "SAO FRANCISCO DE ASSIS DO PIAUI",
    value: { cityName: "SAO FRANCISCO DE ASSIS DO PIAUI", uf: "PI" },
  },
  {
    name: "SAO FRANCISCO DE ITABAPOANA",
    value: { cityName: "SAO FRANCISCO DE ITABAPOANA", uf: "RJ" },
  },
  {
    name: "SAO FRANCISCO DO BREJAO",
    value: { cityName: "SAO FRANCISCO DO BREJAO", uf: "MA" },
  },
  {
    name: "SAO FRANCISCO DO GUAPORE",
    value: { cityName: "SAO FRANCISCO DO GUAPORE", uf: "RO" },
  },
  {
    name: "SAO FRANCISCO DO OESTE",
    value: { cityName: "SAO FRANCISCO DO OESTE", uf: "RN" },
  },
  {
    name: "SAO FRANCISCO DO PARA",
    value: { cityName: "SAO FRANCISCO DO PARA", uf: "PA" },
  },
  {
    name: "SAO GABRIEL DO OESTE",
    value: { cityName: "SAO GABRIEL DO OESTE", uf: "MS" },
  },
  {
    name: "SAO GERALDO DO BAIXIO",
    value: { cityName: "SAO GERALDO DO BAIXIO", uf: "MG" },
  },
  {
    name: "SAO GONCALO DO GURGUEIA",
    value: { cityName: "SAO GONCALO DO GURGUEIA", uf: "PI" },
  },
  {
    name: "SAO GONCALO DO PIAUI",
    value: { cityName: "SAO GONCALO DO PIAUI", uf: "PI" },
  },
  {
    name: "SAO JOAO BATISTA DO GLORIA",
    value: { cityName: "SAO JOAO BATISTA DO GLORIA", uf: "MG" },
  },
  {
    name: "SAO JOAO DA CANABRAVA",
    value: { cityName: "SAO JOAO DA CANABRAVA", uf: "PI" },
  },
  {
    name: "SAO JOAO DA FRONTEIRA",
    value: { cityName: "SAO JOAO DA FRONTEIRA", uf: "PI" },
  },
  {
    name: "SAO JOAO DA LAGOA",
    value: { cityName: "SAO JOAO DA LAGOA", uf: "MG" },
  },
  {
    name: "SAO JOAO DA MATA",
    value: { cityName: "SAO JOAO DA MATA", uf: "MG" },
  },
  {
    name: "SAO JOAO DA PONTA",
    value: { cityName: "SAO JOAO DA PONTA", uf: "PA" },
  },
  {
    name: "SAO JOAO DA SERRA",
    value: { cityName: "SAO JOAO DA SERRA", uf: "PI" },
  },
  {
    name: "SAO JOAO DA VARJOTA",
    value: { cityName: "SAO JOAO DA VARJOTA", uf: "PI" },
  },
  {
    name: "SAO JOAO DAS DUAS PONTES",
    value: { cityName: "SAO JOAO DAS DUAS PONTES", uf: "SP" },
  },
  {
    name: "SAO JOAO DAS MISSOES",
    value: { cityName: "SAO JOAO DAS MISSOES", uf: "MG" },
  },
  {
    name: "SAO JOAO DE IRACEMA",
    value: { cityName: "SAO JOAO DE IRACEMA", uf: "SP" },
  },
  {
    name: "SAO JOAO DEL-REI",
    value: { cityName: "SAO JOAO DEL-REI", uf: "MG" },
  },
  {
    name: "SAO JOAO DO ARAGUAIA",
    value: { cityName: "SAO JOAO DO ARAGUAIA", uf: "PA" },
  },
  {
    name: "SAO JOAO DO ARRAIAL",
    value: { cityName: "SAO JOAO DO ARRAIAL", uf: "PI" },
  },
  {
    name: "SAO JOAO DO CAIUA",
    value: { cityName: "SAO JOAO DO CAIUA", uf: "PR" },
  },
  {
    name: "SAO JOAO DO CARU",
    value: { cityName: "SAO JOAO DO CARU", uf: "MA" },
  },
  {
    name: "SAO JOAO DO IVAI",
    value: { cityName: "SAO JOAO DO IVAI", uf: "PR" },
  },
  {
    name: "SAO JOAO DO PACUI",
    value: { cityName: "SAO JOAO DO PACUI", uf: "MG" },
  },
  {
    name: "SAO JOAO DO PAU DALHO",
    value: { cityName: "SAO JOAO DO PAU DALHO", uf: "SP" },
  },
  {
    name: "SAO JOAO DO SABUGI",
    value: { cityName: "SAO JOAO DO SABUGI", uf: "RN" },
  },
  {
    name: "SAO JOAO DO SOTER",
    value: { cityName: "SAO JOAO DO SOTER", uf: "MA" },
  },
  {
    name: "SAO JOAQUIM DO MONTE",
    value: { cityName: "SAO JOAQUIM DO MONTE", uf: "PE" },
  },
  {
    name: "SAO JORGE DO IVAI",
    value: { cityName: "SAO JORGE DO IVAI", uf: "PR" },
  },
  {
    name: "SAO JORGE DO PATROCINIO",
    value: { cityName: "SAO JORGE DO PATROCINIO", uf: "PR" },
  },
  {
    name: "SAO JORGE DOESTE",
    value: { cityName: "SAO JORGE DOESTE", uf: "PR" },
  },
  {
    name: "SAO JOSE DA BELA VISTA",
    value: { cityName: "SAO JOSE DA BELA VISTA", uf: "SP" },
  },
  {
    name: "SAO JOSE DA BOA VISTA",
    value: { cityName: "SAO JOSE DA BOA VISTA", uf: "PR" },
  },
  {
    name: "SAO JOSE DA COROA GRANDE",
    value: { cityName: "SAO JOSE DA COROA GRANDE", uf: "PE" },
  },
  {
    name: "SAO JOSE DA LAGOA TAPADA",
    value: { cityName: "SAO JOSE DA LAGOA TAPADA", uf: "PB" },
  },
  {
    name: "SAO JOSE DA SAFIRA",
    value: { cityName: "SAO JOSE DA SAFIRA", uf: "MG" },
  },
  {
    name: "SAO JOSE DA VITORIA",
    value: { cityName: "SAO JOSE DA VITORIA", uf: "BA" },
  },
  {
    name: "SAO JOSE DE ESPINHARAS",
    value: { cityName: "SAO JOSE DE ESPINHARAS", uf: "PB" },
  },
  {
    name: "SAO JOSE DE PRINCESA",
    value: { cityName: "SAO JOSE DE PRINCESA", uf: "PB" },
  },
  { name: "SAO JOSE DE UBA", value: { cityName: "SAO JOSE DE UBA", uf: "RJ" } },
  {
    name: "SAO JOSE DO BARREIRO",
    value: { cityName: "SAO JOSE DO BARREIRO", uf: "SP" },
  },
  {
    name: "SAO JOSE DO BREJO DO CRUZ",
    value: { cityName: "SAO JOSE DO BREJO DO CRUZ", uf: "PB" },
  },
  {
    name: "SAO JOSE DO DIVINO",
    value: { cityName: "SAO JOSE DO DIVINO", uf: "MG" },
  },
  {
    name: "SAO JOSE DO PEIXE",
    value: { cityName: "SAO JOSE DO PEIXE", uf: "PI" },
  },
  {
    name: "SAO JOSE DO POVO",
    value: { cityName: "SAO JOSE DO POVO", uf: "MT" },
  },
  {
    name: "SAO JOSE DO RIO CLARO",
    value: { cityName: "SAO JOSE DO RIO CLARO", uf: "MT" },
  },
  {
    name: "SAO JOSE DO XINGU",
    value: { cityName: "SAO JOSE DO XINGU", uf: "MT" },
  },
  {
    name: "SAO JOSE DOS BASILIOS",
    value: { cityName: "SAO JOSE DOS BASILIOS", uf: "MA" },
  },
  {
    name: "SAO JOSE DOS CORDEIROS",
    value: { cityName: "SAO JOSE DOS CORDEIROS", uf: "PB" },
  },
  {
    name: "SAO JOSE DOS QUATRO MARCOS",
    value: { cityName: "SAO JOSE DOS QUATRO MARCOS", uf: "MT" },
  },
  {
    name: "SAO JOSE DOS RAMOS",
    value: { cityName: "SAO JOSE DOS RAMOS", uf: "PB" },
  },
  {
    name: "SAO LOURENCO DO PIAUI",
    value: { cityName: "SAO LOURENCO DO PIAUI", uf: "PI" },
  },
  {
    name: "SAO LUIS DO CURU",
    value: { cityName: "SAO LUIS DO CURU", uf: "CE" },
  },
  {
    name: "SAO LUIS DO PIAUI",
    value: { cityName: "SAO LUIS DO PIAUI", uf: "PI" },
  },
  {
    name: "SAO LUIS DO QUITUNDE",
    value: { cityName: "SAO LUIS DO QUITUNDE", uf: "AL" },
  },
  {
    name: "SAO LUIZ DO PARAITINGA",
    value: { cityName: "SAO LUIZ DO PARAITINGA", uf: "SP" },
  },
  {
    name: "SAO MANOEL DO PARANA",
    value: { cityName: "SAO MANOEL DO PARANA", uf: "PR" },
  },
  {
    name: "SAO MARTINHO DA SERRA",
    value: { cityName: "SAO MARTINHO DA SERRA", uf: "RS" },
  },
  {
    name: "SAO MIGUEL DA BAIXA GRANDE",
    value: { cityName: "SAO MIGUEL DA BAIXA GRANDE", uf: "PI" },
  },
  {
    name: "SAO MIGUEL DE TAIPU",
    value: { cityName: "SAO MIGUEL DE TAIPU", uf: "PB" },
  },
  {
    name: "SAO MIGUEL DO ALEIXO",
    value: { cityName: "SAO MIGUEL DO ALEIXO", uf: "SE" },
  },
  {
    name: "SAO MIGUEL DO FIDALGO",
    value: { cityName: "SAO MIGUEL DO FIDALGO", uf: "PI" },
  },
  {
    name: "SAO MIGUEL DO GOSTOSO",
    value: { cityName: "SAO MIGUEL DO GOSTOSO", uf: "RN" },
  },
  {
    name: "SAO MIGUEL DO GUAPORE",
    value: { cityName: "SAO MIGUEL DO GUAPORE", uf: "RO" },
  },
  {
    name: "SAO MIGUEL DO IGUACU",
    value: { cityName: "SAO MIGUEL DO IGUACU", uf: "PR" },
  },
  {
    name: "SAO MIGUEL DO PASSA-QUATRO",
    value: { cityName: "SAO MIGUEL DO PASSA-QUATRO", uf: "GO" },
  },
  {
    name: "SAO MIGUEL DO TAPUIO",
    value: { cityName: "SAO MIGUEL DO TAPUIO", uf: "PI" },
  },
  {
    name: "SAO MIGUEL DO TOCANTINS",
    value: { cityName: "SAO MIGUEL DO TOCANTINS", uf: "TO" },
  },
  {
    name: "SAO MIGUEL DOS MILAGRES",
    value: { cityName: "SAO MIGUEL DOS MILAGRES", uf: "AL" },
  },
  { name: "SAO PATRICIO", value: { cityName: "SAO PATRICIO", uf: "SP" } },
  {
    name: "SAO PAULO DE OLIVENCA",
    value: { cityName: "SAO PAULO DE OLIVENCA", uf: "AM" },
  },
  {
    name: "SAO PAULO DO POTENGI",
    value: { cityName: "SAO PAULO DO POTENGI", uf: "RN" },
  },
  {
    name: "SAO PEDRO DA AGUA BRANCA",
    value: { cityName: "SAO PEDRO DA AGUA BRANCA", uf: "MA" },
  },
  {
    name: "SAO PEDRO DA CIPA",
    value: { cityName: "SAO PEDRO DA CIPA", uf: "MT" },
  },
  {
    name: "SAO PEDRO DO IGUACU",
    value: { cityName: "SAO PEDRO DO IGUACU", uf: "PR" },
  },
  {
    name: "SAO PEDRO DO IVAI",
    value: { cityName: "SAO PEDRO DO IVAI", uf: "PR" },
  },
  {
    name: "SAO PEDRO DO PARANA",
    value: { cityName: "SAO PEDRO DO PARANA", uf: "PR" },
  },
  {
    name: "SAO PEDRO DO PIAUI",
    value: { cityName: "SAO PEDRO DO PIAUI", uf: "PI" },
  },
  {
    name: "SAO PEDRO DO TURVO",
    value: { cityName: "SAO PEDRO DO TURVO", uf: "SP" },
  },
  {
    name: "SAO PEDRO DOS CRENTES",
    value: { cityName: "SAO PEDRO DOS CRENTES", uf: "MA" },
  },
  {
    name: "SAO RAIMUNDO DO DOCA BEZERRA",
    value: { cityName: "SAO RAIMUNDO DO DOCA BEZERRA", uf: "MA" },
  },
  { name: "SAO ROBERTO", value: { cityName: "SAO ROBERTO", uf: "MA" } },
  {
    name: "SAO ROQUE DE MINAS",
    value: { cityName: "SAO ROQUE DE MINAS", uf: "MG" },
  },
  {
    name: "SAO ROQUE DO CANAA",
    value: { cityName: "SAO ROQUE DO CANAA", uf: "ES" },
  },
  {
    name: "SAO SALVADOR DO TOCANTINS",
    value: { cityName: "SAO SALVADOR DO TOCANTINS", uf: "TO" },
  },
  {
    name: "SAO SEBASTIAO DA AMOREIRA",
    value: { cityName: "SAO SEBASTIAO DA AMOREIRA", uf: "PR" },
  },
  {
    name: "SAO SEBASTIAO DA BELA VISTA",
    value: { cityName: "SAO SEBASTIAO DA BELA VISTA", uf: "MG" },
  },
  {
    name: "SAO SEBASTIAO DA BOA VISTA",
    value: { cityName: "SAO SEBASTIAO DA BOA VISTA", uf: "PA" },
  },
  {
    name: "SAO SEBASTIAO DA VARGEM ALEGRE",
    value: { cityName: "SAO SEBASTIAO DA VARGEM ALEGRE", uf: "MG" },
  },
  {
    name: "SAO SEBASTIAO DO ANTA",
    value: { cityName: "SAO SEBASTIAO DO ANTA", uf: "MG" },
  },
  {
    name: "SAO SEBASTIAO DO RIO VERDE",
    value: { cityName: "SAO SEBASTIAO DO RIO VERDE", uf: "MG" },
  },
  {
    name: "SAO SEBASTIAO DO TOCANTINS",
    value: { cityName: "SAO SEBASTIAO DO TOCANTINS", uf: "TO" },
  },
  {
    name: "SAO SEBASTIAO DO UATUMA",
    value: { cityName: "SAO SEBASTIAO DO UATUMA", uf: "AM" },
  },
  {
    name: "SAO SEBASTIAO DO UMBUZEIRO",
    value: { cityName: "SAO SEBASTIAO DO UMBUZEIRO", uf: "PB" },
  },
  {
    name: "SAO VALERIO DA NATIVIDADE",
    value: { cityName: "SAO VALERIO DA NATIVIDADE", uf: "TO" },
  },
  {
    name: "SAO VICENTE FERRER",
    value: { cityName: "SAO VICENTE FERRER", uf: "MA" },
  },
  { name: "SAPEZAL", value: { cityName: "SAPEZAL", uf: "MT" } },
  { name: "SAPOPEMA", value: { cityName: "SAPOPEMA", uf: "PR" } },
  { name: "SAPUCAIA", value: { cityName: "SAPUCAIA", uf: "PA" } },
  { name: "SAPUCAI-MIRIM", value: { cityName: "SAPUCAI-MIRIM", uf: "MG" } },
  { name: "SARUTAIA", value: { cityName: "SARUTAIA", uf: "SP" } },
  { name: "SATIRO DIAS", value: { cityName: "SATIRO DIAS", uf: "BA" } },
  { name: "SATUBA", value: { cityName: "SATUBA", uf: "AL" } },
  { name: "SATUBINHA", value: { cityName: "SATUBINHA", uf: "MA" } },
  {
    name: "SAUDADE DO IGUACU",
    value: { cityName: "SAUDADE DO IGUACU", uf: "PR" },
  },
  {
    name: "SEBASTIAO BARROS",
    value: { cityName: "SEBASTIAO BARROS", uf: "PI" },
  },
  {
    name: "SEBASTIAO LARANJEIRAS",
    value: { cityName: "SEBASTIAO LARANJEIRAS", uf: "BA" },
  },
  { name: "SEBASTIAO LEAL", value: { cityName: "SEBASTIAO LEAL", uf: "PI" } },
  { name: "SELVIRIA", value: { cityName: "SELVIRIA", uf: "MS" } },
  { name: "SEM-PEIXE", value: { cityName: "SEM-PEIXE", uf: "MG" } },
  {
    name: "SENADOR ALEXANDRE COSTA",
    value: { cityName: "SENADOR ALEXANDRE COSTA", uf: "MA" },
  },
  { name: "SENADOR AMARAL", value: { cityName: "SENADOR AMARAL", uf: "MG" } },
  { name: "SENADOR CORTES", value: { cityName: "SENADOR CORTES", uf: "MG" } },
  {
    name: "SENADOR ELOI DE SOUZA",
    value: { cityName: "SENADOR ELOI DE SOUZA", uf: "RN" },
  },
  {
    name: "SENADOR GEORGINO AVELINO",
    value: { cityName: "SENADOR GEORGINO AVELINO", uf: "RN" },
  },
  {
    name: "SENADOR GUIOMARD",
    value: { cityName: "SENADOR GUIOMARD", uf: "AC" },
  },
  {
    name: "SENADOR JOSE BENTO",
    value: { cityName: "SENADOR JOSE BENTO", uf: "MG" },
  },
  {
    name: "SENADOR JOSE PORFIRIO",
    value: { cityName: "SENADOR JOSE PORFIRIO", uf: "PA" },
  },
  {
    name: "SENADOR LA ROCQUE",
    value: { cityName: "SENADOR LA ROCQUE", uf: "MA" },
  },
  {
    name: "SENADOR RUI PALMEIRA",
    value: { cityName: "SENADOR RUI PALMEIRA", uf: "AL" },
  },
  { name: "SENGES", value: { cityName: "SENGES", uf: "PR" } },
  {
    name: "SENHORA DE OLIVEIRA",
    value: { cityName: "SENHORA DE OLIVEIRA", uf: "MG" },
  },
  { name: "SERIDO", value: { cityName: "SERIDO", uf: "PB" } },
  { name: "SERINGUEIRAS", value: { cityName: "SERINGUEIRAS", uf: "RO" } },
  { name: "SERITINGA", value: { cityName: "SERITINGA", uf: "MG" } },
  { name: "SEROPEDICA", value: { cityName: "SEROPEDICA", uf: "RJ" } },
  { name: "SERRA DA RAIZ", value: { cityName: "SERRA DA RAIZ", uf: "PB" } },
  {
    name: "SERRA DE SAO BENTO",
    value: { cityName: "SERRA DE SAO BENTO", uf: "RN" },
  },
  { name: "SERRA DO NAVIO", value: { cityName: "SERRA DO NAVIO", uf: "AP" } },
  {
    name: "SERRA DO RAMALHO",
    value: { cityName: "SERRA DO RAMALHO", uf: "BA" },
  },
  { name: "SERRA DOURADA", value: { cityName: "SERRA DOURADA", uf: "BA" } },
  {
    name: "SERRA NEGRA DO NORTE",
    value: { cityName: "SERRA NEGRA DO NORTE", uf: "RN" },
  },
  {
    name: "SERRA NOVA DOURADA",
    value: { cityName: "SERRA NOVA DOURADA", uf: "MT" },
  },
  {
    name: "SERRANO DO MARANHAO",
    value: { cityName: "SERRANO DO MARANHAO", uf: "MA" },
  },
  {
    name: "SERRANOPOLIS DE MINAS",
    value: { cityName: "SERRANOPOLIS DE MINAS", uf: "MG" },
  },
  {
    name: "SERRANOPOLIS DO IGUACU",
    value: { cityName: "SERRANOPOLIS DO IGUACU", uf: "PR" },
  },
  {
    name: "SERRINHA DOS PINTOS",
    value: { cityName: "SERRINHA DOS PINTOS", uf: "RN" },
  },
  { name: "SERROLANDIA", value: { cityName: "SERROLANDIA", uf: "BA" } },
  { name: "SERTANEJA", value: { cityName: "SERTANEJA", uf: "PR" } },
  { name: "SETE QUEDAS", value: { cityName: "SETE QUEDAS", uf: "MS" } },
  { name: "SETUBINHA", value: { cityName: "SETUBINHA", uf: "MG" } },
  { name: "SEVERIANO MELO", value: { cityName: "SEVERIANO MELO", uf: "RN" } },
  { name: "SIDROLANDIA", value: { cityName: "SIDROLANDIA", uf: "MS" } },
  {
    name: "SIGEFREDO PACHECO",
    value: { cityName: "SIGEFREDO PACHECO", uf: "PI" },
  },
  { name: "SILVANOPOLIS", value: { cityName: "SILVANOPOLIS", uf: "TO" } },
  { name: "SILVES", value: { cityName: "SILVES", uf: "AM" } },
  { name: "SINOP", value: { cityName: "SINOP", uf: "MT" } },
  { name: "SIQUEIRA CAMPOS", value: { cityName: "SIQUEIRA CAMPOS", uf: "PR" } },
  { name: "SIRINHAEM", value: { cityName: "SIRINHAEM", uf: "PE" } },
  { name: "SITIO DABADIA", value: { cityName: "SITIO DABADIA", uf: "GO" } },
  { name: "SITIO DO MATO", value: { cityName: "SITIO DO MATO", uf: "BA" } },
  { name: "SITIO DO QUINTO", value: { cityName: "SITIO DO QUINTO", uf: "BA" } },
  {
    name: "SITIO NOVO DO TOCANTINS",
    value: { cityName: "SITIO NOVO DO TOCANTINS", uf: "TO" },
  },
  { name: "SOBRADO", value: { cityName: "SOBRADO", uf: "PB" } },
  { name: "SONORA", value: { cityName: "SONORA", uf: "MS" } },
  { name: "SOORETAMA", value: { cityName: "SOORETAMA", uf: "ES" } },
  { name: "SORRISO", value: { cityName: "SORRISO", uf: "MT" } },
  { name: "SOSSEGO", value: { cityName: "SOSSEGO", uf: "PB" } },
  { name: "SOURE", value: { cityName: "SOURE", uf: "PA" } },
  { name: "SUCUPIRA", value: { cityName: "SUCUPIRA", uf: "TO" } },
  {
    name: "SUCUPIRA DO RIACHAO",
    value: { cityName: "SUCUPIRA DO RIACHAO", uf: "MA" },
  },
  { name: "SUD MENNUCCI", value: { cityName: "SUD MENNUCCI", uf: "SP" } },
  { name: "SULINA", value: { cityName: "SULINA", uf: "PR" } },
  { name: "SUSSUAPARA", value: { cityName: "SUSSUAPARA", uf: "PI" } },
  { name: "SUZANAPOLIS", value: { cityName: "SUZANAPOLIS", uf: "SP" } },
  { name: "TABAPORA", value: { cityName: "TABAPORA", uf: "MT" } },
  {
    name: "TABOCAS DO BREJO VELHO",
    value: { cityName: "TABOCAS DO BREJO VELHO", uf: "BA" },
  },
  {
    name: "TABOLEIRO GRANDE",
    value: { cityName: "TABOLEIRO GRANDE", uf: "RN" },
  },
  { name: "TACARATU", value: { cityName: "TACARATU", uf: "PE" } },
  { name: "TACURU", value: { cityName: "TACURU", uf: "MS" } },
  { name: "TAGUATINGA", value: { cityName: "TAGUATINGA", uf: "TO" } },
  { name: "TAIACU", value: { cityName: "TAIACU", uf: "SP" } },
  { name: "TAILANDIA", value: { cityName: "TAILANDIA", uf: "PA" } },
  {
    name: "TAIPAS DO TOCANTINS",
    value: { cityName: "TAIPAS DO TOCANTINS", uf: "TO" },
  },
  { name: "TALISMA", value: { cityName: "TALISMA", uf: "TO" } },
  { name: "TAMANDARE", value: { cityName: "TAMANDARE", uf: "PE" } },
  { name: "TAMARANA", value: { cityName: "TAMARANA", uf: "PR" } },
  {
    name: "TAMBORIL DO PIAUI",
    value: { cityName: "TAMBORIL DO PIAUI", uf: "PI" },
  },
  {
    name: "TANGARA DA SERRA",
    value: { cityName: "TANGARA DA SERRA", uf: "MT" },
  },
  { name: "TANGUA", value: { cityName: "TANGUA", uf: "RJ" } },
  { name: "TANQUE DO PIAUI", value: { cityName: "TANQUE DO PIAUI", uf: "PI" } },
  { name: "TANQUE NOVO", value: { cityName: "TANQUE NOVO", uf: "BA" } },
  { name: "TAPARUBA", value: { cityName: "TAPARUBA", uf: "MG" } },
  { name: "TAPAUA", value: { cityName: "TAPAUA", uf: "AM" } },
  { name: "TAPURAH", value: { cityName: "TAPURAH", uf: "MT" } },
  { name: "TAQUARAL", value: { cityName: "TAQUARAL", uf: "SP" } },
  {
    name: "TAQUARAL DE GOIAS",
    value: { cityName: "TAQUARAL DE GOIAS", uf: "GO" },
  },
  {
    name: "TAQUARITINGA DO NORTE",
    value: { cityName: "TAQUARITINGA DO NORTE", uf: "PE" },
  },
  { name: "TAQUARITUBA", value: { cityName: "TAQUARITUBA", uf: "SP" } },
  { name: "TAQUARUSSU", value: { cityName: "TAQUARUSSU", uf: "MS" } },
  { name: "TARRAFAS", value: { cityName: "TARRAFAS", uf: "CE" } },
  { name: "TARTARUGALZINHO", value: { cityName: "TARTARUGALZINHO", uf: "AP" } },
  { name: "TASSO FRAGOSO", value: { cityName: "TASSO FRAGOSO", uf: "MA" } },
  { name: "TEIXEIROPOLIS", value: { cityName: "TEIXEIROPOLIS", uf: "RO" } },
  { name: "TEJUCUOCA", value: { cityName: "TEJUCUOCA", uf: "CE" } },
  { name: "TELHA", value: { cityName: "TELHA", uf: "SE" } },
  {
    name: "TENENTE LAURENTINO CRUZ",
    value: { cityName: "TENENTE LAURENTINO CRUZ", uf: "RN" },
  },
  { name: "TENORIO", value: { cityName: "TENORIO", uf: "PB" } },
  { name: "TEOLANDIA", value: { cityName: "TEOLANDIA", uf: "BA" } },
  { name: "TEOTONIO VILELA", value: { cityName: "TEOTONIO VILELA", uf: "AL" } },
  { name: "TERENOS", value: { cityName: "TERENOS", uf: "MS" } },
  {
    name: "TEREZOPOLIS DE GOIAS",
    value: { cityName: "TEREZOPOLIS DE GOIAS", uf: "GO" },
  },
  {
    name: "TERRA NOVA DO NORTE",
    value: { cityName: "TERRA NOVA DO NORTE", uf: "MT" },
  },
  { name: "TERRA SANTA", value: { cityName: "TERRA SANTA", uf: "PA" } },
  { name: "TESOURO", value: { cityName: "TESOURO", uf: "MT" } },
  { name: "THEOBROMA", value: { cityName: "THEOBROMA", uf: "RO" } },
  { name: "TIBAGI", value: { cityName: "TIBAGI", uf: "PR" } },
  { name: "TIBAU", value: { cityName: "TIBAU", uf: "RN" } },
  { name: "TIBAU DO SUL", value: { cityName: "TIBAU DO SUL", uf: "RN" } },
  { name: "TIGRINHOS", value: { cityName: "TIGRINHOS", uf: "SC" } },
  {
    name: "TIMBAUBA DOS BATISTAS",
    value: { cityName: "TIMBAUBA DOS BATISTAS", uf: "RN" },
  },
  { name: "TIMBIRAS", value: { cityName: "TIMBIRAS", uf: "MA" } },
  { name: "TOCANTINIA", value: { cityName: "TOCANTINIA", uf: "TO" } },
  { name: "TOCANTINOPOLIS", value: { cityName: "TOCANTINOPOLIS", uf: "TO" } },
  { name: "TOCOS DO MOJI", value: { cityName: "TOCOS DO MOJI", uf: "MG" } },
  { name: "TOMAZINA", value: { cityName: "TOMAZINA", uf: "PR" } },
  { name: "TONANTINS", value: { cityName: "TONANTINS", uf: "AM" } },
  { name: "TORITAMA", value: { cityName: "TORITAMA", uf: "PE" } },
  { name: "TORIXOREU", value: { cityName: "TORIXOREU", uf: "MT" } },
  { name: "TOUROS", value: { cityName: "TOUROS", uf: "RN" } },
  { name: "TRABIJU", value: { cityName: "TRABIJU", uf: "SP" } },
  { name: "TRACUATEUA", value: { cityName: "TRACUATEUA", uf: "PA" } },
  { name: "TRACUNHAEM", value: { cityName: "TRACUNHAEM", uf: "PE" } },
  { name: "TRAIRAO", value: { cityName: "TRAIRAO", uf: "PA" } },
  { name: "TRAIRI", value: { cityName: "TRAIRI", uf: "CE" } },
  {
    name: "TRAJANO DE MORAES",
    value: { cityName: "TRAJANO DE MORAES", uf: "RJ" },
  },
  {
    name: "TRES BARRAS DO PARANA",
    value: { cityName: "TRES BARRAS DO PARANA", uf: "PR" },
  },
  { name: "TRES RANCHOS", value: { cityName: "TRES RANCHOS", uf: "GO" } },
  { name: "TREVISO", value: { cityName: "TREVISO", uf: "SC" } },
  {
    name: "TRIUNFO POTIGUAR",
    value: { cityName: "TRIUNFO POTIGUAR", uf: "RN" },
  },
  {
    name: "TRIZIDELA DO VALE",
    value: { cityName: "TRIZIDELA DO VALE", uf: "MA" },
  },
  { name: "TROMBAS", value: { cityName: "TROMBAS", uf: "GO" } },
  { name: "TUCUMA", value: { cityName: "TUCUMA", uf: "PA" } },
  { name: "TUFILANDIA", value: { cityName: "TUFILANDIA", uf: "MA" } },
  { name: "TUIUTI", value: { cityName: "TUIUTI", uf: "SP" } },
  { name: "TUNAS DO PARANA", value: { cityName: "TUNAS DO PARANA", uf: "PR" } },
  { name: "TUNTUM", value: { cityName: "TUNTUM", uf: "MA" } },
  { name: "TUPIRAMA", value: { cityName: "TUPIRAMA", uf: "TO" } },
  { name: "TUPIRATINS", value: { cityName: "TUPIRATINS", uf: "TO" } },
  { name: "TURIACU", value: { cityName: "TURIACU", uf: "MA" } },
  { name: "TURILANDIA", value: { cityName: "TURILANDIA", uf: "MA" } },
  { name: "TURIUBA", value: { cityName: "TURIUBA", uf: "SP" } },
  { name: "TURVANIA", value: { cityName: "TURVANIA", uf: "GO" } },
  { name: "TURVELANDIA", value: { cityName: "TURVELANDIA", uf: "GO" } },
  { name: "OLINDA", value: { cityName: "OLINDA", uf: "PE" } },
  { name: "CONTENDA", value: { cityName: "CONTENDA", uf: "PR" } },
  { name: "UBIRATA", value: { cityName: "UBIRATA", uf: "PR" } },
  { name: "UIRAMUTA", value: { cityName: "UIRAMUTA", uf: "RR" } },
  { name: "UIRAPURU", value: { cityName: "UIRAPURU", uf: "GO" } },
  { name: "UIRAUNA", value: { cityName: "UIRAUNA", uf: "PB" } },
  { name: "ULIANOPOLIS", value: { cityName: "ULIANOPOLIS", uf: "PA" } },
  { name: "UMARIZAL", value: { cityName: "UMARIZAL", uf: "RN" } },
  { name: "UNIAO DE MINAS", value: { cityName: "UNIAO DE MINAS", uf: "MG" } },
  { name: "UNIAO DO SUL", value: { cityName: "UNIAO DO SUL", uf: "MT" } },
  { name: "UPANEMA", value: { cityName: "UPANEMA", uf: "RN" } },
  { name: "URU", value: { cityName: "URU", uf: "SP" } },
  { name: "URUANA DE MINAS", value: { cityName: "URUANA DE MINAS", uf: "MG" } },
  { name: "URUARA", value: { cityName: "URUARA", uf: "PA" } },
  { name: "URUCURITUBA", value: { cityName: "URUCURITUBA", uf: "AM" } },
  { name: "URUPA", value: { cityName: "URUPA", uf: "RO" } },
  { name: "URUPES", value: { cityName: "URUPES", uf: "SP" } },
  { name: "URUTAI", value: { cityName: "URUTAI", uf: "GO" } },
  {
    name: "VALE DE SAO DOMINGOS",
    value: { cityName: "VALE DE SAO DOMINGOS", uf: "MT" },
  },
  { name: "VALE DO ANARI", value: { cityName: "VALE DO ANARI", uf: "RO" } },
  { name: "VALE DO PARAISO", value: { cityName: "VALE DO PARAISO", uf: "RO" } },
  { name: "VALENTE", value: { cityName: "VALENTE", uf: "BA" } },
  { name: "VALENTIM GENTIL", value: { cityName: "VALENTIM GENTIL", uf: "SP" } },
  {
    name: "VALPARAISO DE GOIAS",
    value: { cityName: "VALPARAISO DE GOIAS", uf: "GO" },
  },
  {
    name: "VARGEM GRANDE DO RIO PARDO",
    value: { cityName: "VARGEM GRANDE DO RIO PARDO", uf: "MG" },
  },
  { name: "VARJAO", value: { cityName: "VARJAO", uf: "GO" } },
  { name: "VARJAO DE MINAS", value: { cityName: "VARJAO DE MINAS", uf: "MG" } },
  { name: "VARJOTA", value: { cityName: "VARJOTA", uf: "CE" } },
  { name: "VARRE-SAI", value: { cityName: "VARRE-SAI", uf: "RJ" } },
  { name: "VARZEA BRANCA", value: { cityName: "VARZEA BRANCA", uf: "PI" } },
  { name: "VARZEA DA ROCA", value: { cityName: "VARZEA DA ROCA", uf: "BA" } },
  { name: "VARZEA DO POCO", value: { cityName: "VARZEA DO POCO", uf: "BA" } },
  { name: "VARZEA GRANDE", value: { cityName: "VARZEA GRANDE", uf: "MT" } },
  { name: "VARZEA NOVA", value: { cityName: "VARZEA NOVA", uf: "BA" } },
  { name: "VENHA-VER", value: { cityName: "VENHA-VER", uf: "RN" } },
  { name: "VENTANIA", value: { cityName: "VENTANIA", uf: "PR" } },
  { name: "VERA", value: { cityName: "VERA", uf: "MT" } },
  { name: "VERA MENDES", value: { cityName: "VERA MENDES", uf: "PI" } },
  { name: "VERDELANDIA", value: { cityName: "VERDELANDIA", uf: "MG" } },
  { name: "VEREDA", value: { cityName: "VEREDA", uf: "BA" } },
  { name: "VEREDINHA", value: { cityName: "VEREDINHA", uf: "MG" } },
  { name: "VERMELHO NOVO", value: { cityName: "VERMELHO NOVO", uf: "MG" } },
  {
    name: "VERTENTE DO LERIO",
    value: { cityName: "VERTENTE DO LERIO", uf: "PE" },
  },
  {
    name: "VESPASIANO CORREA",
    value: { cityName: "VESPASIANO CORREA", uf: "RS" },
  },
  { name: "VIANOPOLIS", value: { cityName: "VIANOPOLIS", uf: "GO" } },
  { name: "VICENTINA", value: { cityName: "VICENTINA", uf: "MS" } },
  { name: "VICENTINOPOLIS", value: { cityName: "VICENTINOPOLIS", uf: "GO" } },
  { name: "VIEIRAS", value: { cityName: "VIEIRAS", uf: "MG" } },
  { name: "VIEIROPOLIS", value: { cityName: "VIEIROPOLIS", uf: "PB" } },
  { name: "VIGIA", value: { cityName: "VIGIA", uf: "PA" } },
  {
    name: "VILA BELA DA SANTISSIMA TRINDADE",
    value: { cityName: "VILA BELA DA SANTISSIMA TRINDADE", uf: "MT" },
  },
  {
    name: "VILA NOVA DO PIAUI",
    value: { cityName: "VILA NOVA DO PIAUI", uf: "PI" },
  },
  {
    name: "VILA NOVA DOS MARTIRIOS",
    value: { cityName: "VILA NOVA DOS MARTIRIOS", uf: "MA" },
  },
  { name: "VILA PAVAO", value: { cityName: "VILA PAVAO", uf: "ES" } },
  { name: "VILA PROPICIO", value: { cityName: "VILA PROPICIO", uf: "GO" } },
  { name: "VILA RICA", value: { cityName: "VILA RICA", uf: "MT" } },
  { name: "VILA VALERIO", value: { cityName: "VILA VALERIO", uf: "ES" } },
  { name: "VILHENA", value: { cityName: "VILHENA", uf: "RO" } },
  { name: "VIRMOND", value: { cityName: "VIRMOND", uf: "PR" } },
  {
    name: "VISTA ALEGRE DO ALTO",
    value: { cityName: "VISTA ALEGRE DO ALTO", uf: "SP" },
  },
  { name: "VISTA SERRANA", value: { cityName: "VISTA SERRANA", uf: "PB" } },
  { name: "VITORIA BRASIL", value: { cityName: "VITORIA BRASIL", uf: "SP" } },
  { name: "VITORIA DO JARI", value: { cityName: "VITORIA DO JARI", uf: "AP" } },
  {
    name: "VITORIA DO XINGU",
    value: { cityName: "VITORIA DO XINGU", uf: "PA" },
  },
  { name: "VITORINO", value: { cityName: "VITORINO", uf: "PR" } },
  { name: "WALL FERRAZ", value: { cityName: "WALL FERRAZ", uf: "PI" } },
  { name: "WANDERLANDIA", value: { cityName: "WANDERLANDIA", uf: "TO" } },
  { name: "WANDERLEY", value: { cityName: "WANDERLEY", uf: "BA" } },
  { name: "WESTFALIA", value: { cityName: "WESTFALIA", uf: "RS" } },
  { name: "XAMBIOA", value: { cityName: "XAMBIOA", uf: "TO" } },
  { name: "XAMBRE", value: { cityName: "XAMBRE", uf: "PR" } },
  { name: "XINGUARA", value: { cityName: "XINGUARA", uf: "PA" } },
  { name: "ZABELE", value: { cityName: "ZABELE", uf: "PB" } },
  { name: "ZE DOCA", value: { cityName: "ZE DOCA", uf: "MA" } },
  { name: "AGUA BOA", value: { cityName: "AGUA BOA", uf: "MT" } },
  { name: "AGUA BRANCA", value: { cityName: "AGUA BRANCA", uf: "PB" } },
  { name: "AGUA BRANCA", value: { cityName: "AGUA BRANCA", uf: "PI" } },
  { name: "ALAGOINHA", value: { cityName: "ALAGOINHA", uf: "PB" } },
  { name: "ALTO ALEGRE", value: { cityName: "ALTO ALEGRE", uf: "RR" } },
  { name: "ALTO ALEGRE", value: { cityName: "ALTO ALEGRE", uf: "SP" } },
  { name: "ALTO PARAISO", value: { cityName: "ALTO PARAISO", uf: "RO" } },
  { name: "ALVORADA", value: { cityName: "ALVORADA", uf: "TO" } },
  { name: "AMPARO", value: { cityName: "AMPARO", uf: "PB" } },
  { name: "ANTONIO CARLOS", value: { cityName: "ANTONIO CARLOS", uf: "MG" } },
  { name: "APARECIDA", value: { cityName: "APARECIDA", uf: "PB" } },
  { name: "ARACAJU", value: { cityName: "ARACAJU", uf: "SE" } },
  { name: "ARACOIABA", value: { cityName: "ARACOIABA", uf: "PE" } },
  { name: "ARAGUANA", value: { cityName: "ARAGUANA", uf: "TO" } },
  { name: "ARAPUA", value: { cityName: "ARAPUA", uf: "PR" } },
  { name: "ARARIPINA", value: { cityName: "ARARIPINA", uf: "PE" } },
  { name: "ARARUNA", value: { cityName: "ARARUNA", uf: "PR" } },
  { name: "AREIA BRANCA", value: { cityName: "AREIA BRANCA", uf: "RN" } },
  { name: "ARICANDUVA", value: { cityName: "ARICANDUVA", uf: "MG" } },
  { name: "ASSIS", value: { cityName: "ASSIS", uf: "SP" } },
  { name: "ATALAIA", value: { cityName: "ATALAIA", uf: "AL" } },
  { name: "AURORA", value: { cityName: "AURORA", uf: "SC" } },
  { name: "BANDEIRANTES", value: { cityName: "BANDEIRANTES", uf: "MS" } },
  { name: "BARAUNA", value: { cityName: "BARAUNA", uf: "PB" } },
  { name: "CATAG", value: { cityName: "CATAG", uf: "MG" } },
  { name: "BARRA BONITA", value: { cityName: "BARRA BONITA", uf: "SC" } },
  {
    name: "BARRA DE SAO MIGUEL",
    value: { cityName: "BARRA DE SAO MIGUEL", uf: "PB" },
  },
  { name: "BARRA DO OURO", value: { cityName: "BARRA DO OURO", uf: "TO" } },
  { name: "BARRACAO", value: { cityName: "BARRACAO", uf: "PR" } },
  { name: "BARREIRAS", value: { cityName: "BARREIRAS", uf: "BA" } },
  { name: "BARRETOS", value: { cityName: "BARRETOS", uf: "SP" } },
  { name: "BARRO ALTO", value: { cityName: "BARRO ALTO", uf: "BA" } },
  { name: "BATALHA", value: { cityName: "BATALHA", uf: "PI" } },
  { name: "BELEM", value: { cityName: "BELEM", uf: "AL" } },
  { name: "BELEM", value: { cityName: "BELEM", uf: "PB" } },
  { name: "BELMONTE", value: { cityName: "BELMONTE", uf: "BA" } },
  { name: "BOA ESPERANCA", value: { cityName: "BOA ESPERANCA", uf: "MG" } },
  { name: "BOA ESPERANCA", value: { cityName: "BOA ESPERANCA", uf: "PR" } },
  { name: "BOA VISTA", value: { cityName: "BOA VISTA", uf: "PB" } },
  { name: "BOCAINA", value: { cityName: "BOCAINA", uf: "PI" } },
  { name: "BOCAINA DO SUL", value: { cityName: "BOCAINA DO SUL", uf: "SC" } },
  { name: "BOM JARDIM", value: { cityName: "BOM JARDIM", uf: "MA" } },
  { name: "BOM JARDIM", value: { cityName: "BOM JARDIM", uf: "PE" } },
  { name: "BOM JESUS", value: { cityName: "BOM JESUS", uf: "PB" } },
  { name: "BOM JESUS", value: { cityName: "BOM JESUS", uf: "PI" } },
  { name: "BOM JESUS", value: { cityName: "BOM JESUS", uf: "RN" } },
  { name: "BOM JESUS", value: { cityName: "BOM JESUS", uf: "SC" } },
  {
    name: "BOM JESUS DO TOCANTINS",
    value: { cityName: "BOM JESUS DO TOCANTINS", uf: "TO" },
  },
  { name: "BOM SUCESSO", value: { cityName: "BOM SUCESSO", uf: "PB" } },
  { name: "BOM SUCESSO", value: { cityName: "BOM SUCESSO", uf: "PR" } },
  { name: "BONFIM", value: { cityName: "BONFIM", uf: "RR" } },
  { name: "BONITO", value: { cityName: "BONITO", uf: "MS" } },
  { name: "BONITO", value: { cityName: "BONITO", uf: "PA" } },
  { name: "BONITO", value: { cityName: "BONITO", uf: "PE" } },
  { name: "BORBOREMA", value: { cityName: "BORBOREMA", uf: "PB" } },
  { name: "BREJINHO", value: { cityName: "BREJINHO", uf: "RN" } },
  { name: "BURITIS", value: { cityName: "BURITIS", uf: "RO" } },
  {
    name: "CACHOEIRA DOURADA",
    value: { cityName: "CACHOEIRA DOURADA", uf: "MG" },
  },
  { name: "CACHOEIRINHA", value: { cityName: "CACHOEIRINHA", uf: "PE" } },
  { name: "CACHOEIRINHA", value: { cityName: "CACHOEIRINHA", uf: "TO" } },
  { name: "CAFELANDIA", value: { cityName: "CAFELANDIA", uf: "PR" } },
  { name: "CAICARA", value: { cityName: "CAICARA", uf: "PB" } },
  { name: "CAMPESTRE", value: { cityName: "CAMPESTRE", uf: "AL" } },
  { name: "CAMPO ALEGRE", value: { cityName: "CAMPO ALEGRE", uf: "AL" } },
  { name: "CAMPO GRANDE", value: { cityName: "CAMPO GRANDE", uf: "AL" } },
  { name: "CAMPO GRANDE", value: { cityName: "CAMPO GRANDE", uf: "RN" } },
  { name: "CANAPOLIS", value: { cityName: "CANAPOLIS", uf: "BA" } },
  { name: "CANARANA", value: { cityName: "CANARANA", uf: "MT" } },
  { name: "CANDEIAS", value: { cityName: "CANDEIAS", uf: "BA" } },
  { name: "CANTAGALO", value: { cityName: "CANTAGALO", uf: "MG" } },
  { name: "CANTAGALO", value: { cityName: "CANTAGALO", uf: "PR" } },
  { name: "CAPANEMA", value: { cityName: "CAPANEMA", uf: "PA" } },
  { name: "CAPELA", value: { cityName: "CAPELA", uf: "AL" } },
  { name: "CAPIVARI", value: { cityName: "CAPIVARI", uf: "SP" } },
  { name: "CARACOL", value: { cityName: "CARACOL", uf: "MS" } },
  { name: "CARAUBAS", value: { cityName: "CARAUBAS", uf: "PB" } },
  { name: "CATANDUVA", value: { cityName: "CATANDUVA", uf: "SP" } },
  { name: "CATANDUVAS", value: { cityName: "CATANDUVAS", uf: "SC" } },
  { name: "CEDRAL", value: { cityName: "CEDRAL", uf: "MA" } },
  { name: "CEDRO", value: { cityName: "CEDRO", uf: "PE" } },
  { name: "CENTENARIO", value: { cityName: "CENTENARIO", uf: "TO" } },
  { name: "COLINAS", value: { cityName: "COLINAS", uf: "MA" } },
  { name: "CONDADO", value: { cityName: "CONDADO", uf: "PB" } },
  { name: "CONDE", value: { cityName: "CONDE", uf: "PB" } },
  { name: "CRUZEIRO DO SUL", value: { cityName: "CRUZEIRO DO SUL", uf: "AC" } },
  { name: "CRUZEIRO DO SUL", value: { cityName: "CRUZEIRO DO SUL", uf: "PR" } },
  { name: "DAVINOPOLIS", value: { cityName: "DAVINOPOLIS", uf: "MA" } },
  { name: "DESTERRO", value: { cityName: "DESTERRO", uf: "PB" } },
  {
    name: "DOMINGOS MARTINS",
    value: { cityName: "DOMINGOS MARTINS", uf: "ES" },
  },
  { name: "DOURADINA", value: { cityName: "DOURADINA", uf: "PR" } },
  { name: "DOURADOS", value: { cityName: "DOURADOS", uf: "MS" } },
  { name: "ELDORADO", value: { cityName: "ELDORADO", uf: "MS" } },
  { name: "ENTRE RIOS", value: { cityName: "ENTRE RIOS", uf: "SC" } },
  { name: "ESPERANTINA", value: { cityName: "ESPERANTINA", uf: "TO" } },
  { name: "ESPIRITO SANTO", value: { cityName: "ESPIRITO SANTO", uf: "RN" } },
  {
    name: "ESTRELA DO NORTE",
    value: { cityName: "ESTRELA DO NORTE", uf: "SP" },
  },
  { name: "FATIMA", value: { cityName: "FATIMA", uf: "TO" } },
  { name: "FEIRA NOVA", value: { cityName: "FEIRA NOVA", uf: "SE" } },
  { name: "FILADELFIA", value: { cityName: "FILADELFIA", uf: "TO" } },
  { name: "FLORESTA", value: { cityName: "FLORESTA", uf: "PR" } },
  { name: "FORMOSO", value: { cityName: "FORMOSO", uf: "MG" } },
  {
    name: "GENERAL CARNEIRO",
    value: { cityName: "GENERAL CARNEIRO", uf: "MT" },
  },
  { name: "GOIANA", value: { cityName: "GOIANA", uf: "MG" } },
  { name: "GUAIRA", value: { cityName: "GUAIRA", uf: "PR" } },
  { name: "GUARACI", value: { cityName: "GUARACI", uf: "PR" } },
  { name: "GUARACIABA", value: { cityName: "GUARACIABA", uf: "MG" } },
  { name: "GUARAI", value: { cityName: "GUARAI", uf: "TO" } },
  { name: "GUAXUPE", value: { cityName: "GUAXUPE", uf: "MG" } },
  { name: "HERVAL DOESTE", value: { cityName: "HERVAL DOESTE", uf: "SC" } },
  { name: "HUMAITA", value: { cityName: "HUMAITA", uf: "AM" } },
  { name: "IGUATU", value: { cityName: "IGUATU", uf: "PR" } },
  { name: "INAJA", value: { cityName: "INAJA", uf: "PR" } },
  { name: "INDIANOPOLIS", value: { cityName: "INDIANOPOLIS", uf: "PR" } },
  { name: "IPIRA", value: { cityName: "IPIRA", uf: "BA" } },
  { name: "IPORA", value: { cityName: "IPORA", uf: "PR" } },
  { name: "IPUEIRAS", value: { cityName: "IPUEIRAS", uf: "TO" } },
  { name: "IRACEMA", value: { cityName: "IRACEMA", uf: "RR" } },
  { name: "IRATI", value: { cityName: "IRATI", uf: "SC" } },
  { name: "ITABAIANA", value: { cityName: "ITABAIANA", uf: "PB" } },
  { name: "ITAITUBA", value: { cityName: "ITAITUBA", uf: "PA" } },
  { name: "ITAJA", value: { cityName: "ITAJA", uf: "RN" } },
  { name: "ITAMBE", value: { cityName: "ITAMBE", uf: "PE" } },
  { name: "ITAMBE", value: { cityName: "ITAMBE", uf: "PR" } },
  { name: "ITAPEVA", value: { cityName: "ITAPEVA", uf: "MG" } },
  { name: "ITAPIRANGA", value: { cityName: "ITAPIRANGA", uf: "AM" } },
  { name: "ITAPORANGA", value: { cityName: "ITAPORANGA", uf: "PB" } },
  { name: "JABORANDI", value: { cityName: "JABORANDI", uf: "BA" } },
  { name: "JACUTINGA", value: { cityName: "JACUTINGA", uf: "MG" } },
  { name: "JANDAIRA", value: { cityName: "JANDAIRA", uf: "RN" } },
  { name: "JAPOATA", value: { cityName: "JAPOATA", uf: "SE" } },
  { name: "JAPURA", value: { cityName: "JAPURA", uf: "AM" } },
  { name: "JARDIM", value: { cityName: "JARDIM", uf: "MS" } },
  { name: "JARDINOPOLIS", value: { cityName: "JARDINOPOLIS", uf: "SP" } },
  { name: "JATOBA", value: { cityName: "JATOBA", uf: "PE" } },
  { name: "JORDANIA", value: { cityName: "JORDANIA", uf: "MG" } },
  { name: "JUNDIA", value: { cityName: "JUNDIA", uf: "RN" } },
  { name: "JUREMA", value: { cityName: "JUREMA", uf: "PI" } },
  { name: "JUSSARA", value: { cityName: "JUSSARA", uf: "BA" } },
  { name: "JUSSARA", value: { cityName: "JUSSARA", uf: "PR" } },
  { name: "LAGOA GRANDE", value: { cityName: "LAGOA GRANDE", uf: "PE" } },
  { name: "LAGOA SANTA", value: { cityName: "LAGOA SANTA", uf: "GO" } },
  { name: "LAJEADO", value: { cityName: "LAJEADO", uf: "TO" } },
  { name: "LARANJAL", value: { cityName: "LARANJAL", uf: "PR" } },
  { name: "LEOPOLIS", value: { cityName: "LEOPOLIS", uf: "PR" } },
  { name: "LIMOEIRO", value: { cityName: "LIMOEIRO", uf: "PE" } },
  { name: "LORENA", value: { cityName: "LORENA", uf: "SP" } },
  { name: "MACAE", value: { cityName: "MACAE", uf: "RJ" } },
  { name: "MANAUS", value: { cityName: "MANAUS", uf: "AM" } },
  { name: "MARACAS", value: { cityName: "MARACAS", uf: "BA" } },
  { name: "MARAU", value: { cityName: "MARAU", uf: "BA" } },
  { name: "MARAVILHA", value: { cityName: "MARAVILHA", uf: "AL" } },
  { name: "MARILUZ", value: { cityName: "MARILUZ", uf: "PR" } },
  { name: "MASSARANDUBA", value: { cityName: "MASSARANDUBA", uf: "PB" } },
  { name: "MATO GROSSO", value: { cityName: "MATO GROSSO", uf: "PB" } },
  { name: "MESQUITA", value: { cityName: "MESQUITA", uf: "RJ" } },
  { name: "MILAGRES", value: { cityName: "MILAGRES", uf: "BA" } },
  { name: "MIRADOR", value: { cityName: "MIRADOR", uf: "PR" } },
  { name: "MONTE ALEGRE", value: { cityName: "MONTE ALEGRE", uf: "PA" } },
  { name: "MONTE CASTELO", value: { cityName: "MONTE CASTELO", uf: "SP" } },
  { name: "MONTE SANTO", value: { cityName: "MONTE SANTO", uf: "TO" } },
  { name: "MORRINHOS", value: { cityName: "MORRINHOS", uf: "CE" } },
  { name: "MULUNGU", value: { cityName: "MULUNGU", uf: "PB" } },
  { name: "MUNDO NOVO", value: { cityName: "MUNDO NOVO", uf: "BA" } },
  { name: "MUNDO NOVO", value: { cityName: "MUNDO NOVO", uf: "MS" } },
  { name: "NATIVIDADE", value: { cityName: "NATIVIDADE", uf: "TO" } },
  { name: "NAZARE", value: { cityName: "NAZARE", uf: "TO" } },
  { name: "NILOPOLIS", value: { cityName: "NILOPOLIS", uf: "RJ" } },
  { name: "NOVA AURORA", value: { cityName: "NOVA AURORA", uf: "PR" } },
  { name: "NOVA FATIMA", value: { cityName: "NOVA FATIMA", uf: "PR" } },
  { name: "NOVA IGUACU", value: { cityName: "NOVA IGUACU", uf: "RJ" } },
  { name: "NOVA OLIMPIA", value: { cityName: "NOVA OLIMPIA", uf: "PR" } },
  { name: "NOVA OLINDA", value: { cityName: "NOVA OLINDA", uf: "PB" } },
  { name: "NOVA OLINDA", value: { cityName: "NOVA OLINDA", uf: "TO" } },
  { name: "NOVA SANTA RITA", value: { cityName: "NOVA SANTA RITA", uf: "PI" } },
  { name: "NOVA UNIAO", value: { cityName: "NOVA UNIAO", uf: "RO" } },
  { name: "NOVO HORIZONTE", value: { cityName: "NOVO HORIZONTE", uf: "BA" } },
  { name: "NOVO HORIZONTE", value: { cityName: "NOVO HORIZONTE", uf: "SP" } },
  {
    name: "NOVO SANTO ANTONIO",
    value: { cityName: "NOVO SANTO ANTONIO", uf: "PI" },
  },
  { name: "OURO BRANCO", value: { cityName: "OURO BRANCO", uf: "AL" } },
  { name: "OURO BRANCO", value: { cityName: "OURO BRANCO", uf: "RN" } },
  { name: "OURO VERDE", value: { cityName: "OURO VERDE", uf: "SP" } },
  { name: "PACATUBA", value: { cityName: "PACATUBA", uf: "SE" } },
  { name: "PALESTINA", value: { cityName: "PALESTINA", uf: "AL" } },
  { name: "PALMAS", value: { cityName: "PALMAS", uf: "TO" } },
  { name: "PALMEIRA", value: { cityName: "PALMEIRA", uf: "PR" } },
  { name: "PALMEIRA", value: { cityName: "PALMEIRA", uf: "SC" } },
  { name: "PALMITAL", value: { cityName: "PALMITAL", uf: "SP" } },
  { name: "PARAISO", value: { cityName: "PARAISO", uf: "SP" } },
  {
    name: "PARAISO DO NORTE",
    value: { cityName: "PARAISO DO NORTE", uf: "PR" },
  },
  { name: "PARANA", value: { cityName: "PARANA", uf: "RN" } },
  { name: "PARANA", value: { cityName: "PARANA", uf: "TO" } },
  { name: "PARNAMIRIM", value: { cityName: "PARNAMIRIM", uf: "RN" } },
  { name: "PASSAGEM", value: { cityName: "PASSAGEM", uf: "RN" } },
  { name: "PAU DARCO", value: { cityName: "PAU DARCO", uf: "TO" } },
  { name: "PAULISTA", value: { cityName: "PAULISTA", uf: "PB" } },
  { name: "PAULISTA", value: { cityName: "PAULISTA", uf: "PE" } },
  { name: "PEDRA BRANCA", value: { cityName: "PEDRA BRANCA", uf: "PB" } },
  { name: "PEDRA PRETA", value: { cityName: "PEDRA PRETA", uf: "RN" } },
  { name: "PETROLANDIA", value: { cityName: "PETROLANDIA", uf: "PE" } },
  { name: "PILAR", value: { cityName: "PILAR", uf: "PB" } },
  { name: "PILOES", value: { cityName: "PILOES", uf: "RN" } },
  { name: "PINHALZINHO", value: { cityName: "PINHALZINHO", uf: "SP" } },
  { name: "PINHAO", value: { cityName: "PINHAO", uf: "PR" } },
  { name: "PINHEIROS", value: { cityName: "PINHEIROS", uf: "ES" } },
  { name: "PIRANHAS", value: { cityName: "PIRANHAS", uf: "AL" } },
  { name: "PITANGUEIRAS", value: { cityName: "PITANGUEIRAS", uf: "PR" } },
  { name: "PLANALTO", value: { cityName: "PLANALTO", uf: "BA" } },
  { name: "PLANALTO", value: { cityName: "PLANALTO", uf: "PR" } },
  { name: "PLANALTO", value: { cityName: "PLANALTO", uf: "SP" } },
  { name: "PONTA GROSSA", value: { cityName: "PONTA GROSSA", uf: "PR" } },
  { name: "PONTA PORA", value: { cityName: "PONTA PORA", uf: "MS" } },
  { name: "PORTO VELHO", value: { cityName: "PORTO VELHO", uf: "RO" } },
  { name: "PRAIA GRANDE", value: { cityName: "PRAIA GRANDE", uf: "SC" } },
  { name: "PRATA", value: { cityName: "PRATA", uf: "PB" } },
  {
    name: "PRESIDENTE BERNARDES",
    value: { cityName: "PRESIDENTE BERNARDES", uf: "MG" },
  },
  {
    name: "PRESIDENTE CASTELO BRANCO",
    value: { cityName: "PRESIDENTE CASTELO BRANCO", uf: "PR" },
  },
  {
    name: "PRESIDENTE DUTRA",
    value: { cityName: "PRESIDENTE DUTRA", uf: "MA" },
  },
  {
    name: "PRESIDENTE JUSCELINO",
    value: { cityName: "PRESIDENTE JUSCELINO", uf: "MA" },
  },
  {
    name: "PRESIDENTE KENNEDY",
    value: { cityName: "PRESIDENTE KENNEDY", uf: "TO" },
  },
  {
    name: "PRESIDENTE MEDICI",
    value: { cityName: "PRESIDENTE MEDICI", uf: "RO" },
  },
  {
    name: "PRESIDENTE VARGAS",
    value: { cityName: "PRESIDENTE VARGAS", uf: "MA" },
  },
  { name: "PRIMAVERA", value: { cityName: "PRIMAVERA", uf: "PA" } },
  { name: "QUEIMADAS", value: { cityName: "QUEIMADAS", uf: "PB" } },
  {
    name: "QUERENCIA DO NORTE",
    value: { cityName: "QUERENCIA DO NORTE", uf: "PR" },
  },
  { name: "QUIXABA", value: { cityName: "QUIXABA", uf: "PE" } },
  { name: "REDENCAO", value: { cityName: "REDENCAO", uf: "PA" } },
  { name: "RIACHAO", value: { cityName: "RIACHAO", uf: "PB" } },
  { name: "RIACHINHO", value: { cityName: "RIACHINHO", uf: "TO" } },
  {
    name: "RIACHO DE SANTANA",
    value: { cityName: "RIACHO DE SANTANA", uf: "RN" },
  },
  { name: "RIACHUELO", value: { cityName: "RIACHUELO", uf: "RN" } },
  { name: "RIO BRANCO", value: { cityName: "RIO BRANCO", uf: "AC" } },
  { name: "RIO BRANCO", value: { cityName: "RIO BRANCO", uf: "MT" } },
  {
    name: "RIO BRANCO DO SUL",
    value: { cityName: "RIO BRANCO DO SUL", uf: "PR" },
  },
  { name: "RIO CLARO", value: { cityName: "RIO CLARO", uf: "RJ" } },
  { name: "RIO NEGRO", value: { cityName: "RIO NEGRO", uf: "MS" } },
  { name: "RUY BARBOSA", value: { cityName: "RUY BARBOSA", uf: "RN" } },
  { name: "SALGADINHO", value: { cityName: "SALGADINHO", uf: "PB" } },
  { name: "SALTINHO", value: { cityName: "SALTINHO", uf: "SC" } },
  { name: "SALTO GRANDE", value: { cityName: "SALTO GRANDE", uf: "SP" } },
  { name: "SANTA BARBARA", value: { cityName: "SANTA BARBARA", uf: "BA" } },
  { name: "SANTA CECILIA", value: { cityName: "SANTA CECILIA", uf: "PB" } },
  { name: "SANTA CRUZ", value: { cityName: "SANTA CRUZ", uf: "PE" } },
  { name: "SANTA CRUZ", value: { cityName: "SANTA CRUZ", uf: "RN" } },
  { name: "SANTA FILOMENA", value: { cityName: "SANTA FILOMENA", uf: "PE" } },
  { name: "SANTA HELENA", value: { cityName: "SANTA HELENA", uf: "MA" } },
  { name: "SANTA HELENA", value: { cityName: "SANTA HELENA", uf: "PB" } },
  { name: "SANTA HELENA", value: { cityName: "SANTA HELENA", uf: "PR" } },
  { name: "SANTA INES", value: { cityName: "SANTA INES", uf: "MA" } },
  { name: "SANTA INES", value: { cityName: "SANTA INES", uf: "PB" } },
  { name: "SANTA INES", value: { cityName: "SANTA INES", uf: "PR" } },
  { name: "SANTA ISABEL", value: { cityName: "SANTA ISABEL", uf: "SP" } },
  { name: "SANTA LUCIA", value: { cityName: "SANTA LUCIA", uf: "SP" } },
  { name: "SANTA LUZIA", value: { cityName: "SANTA LUZIA", uf: "BA" } },
  { name: "SANTA LUZIA", value: { cityName: "SANTA LUZIA", uf: "MA" } },
  { name: "SANTA LUZIA", value: { cityName: "SANTA LUZIA", uf: "PB" } },
  { name: "SANTA MARIA", value: { cityName: "SANTA MARIA", uf: "RN" } },
  { name: "SANTA RITA", value: { cityName: "SANTA RITA", uf: "MA" } },
  { name: "SANTA RITA", value: { cityName: "SANTA RITA", uf: "PB" } },
  {
    name: "SANTA ROSA DE LIMA",
    value: { cityName: "SANTA ROSA DE LIMA", uf: "SE" },
  },
  { name: "SANTA TERESINHA", value: { cityName: "SANTA TERESINHA", uf: "PB" } },
  { name: "SANTA TEREZINHA", value: { cityName: "SANTA TEREZINHA", uf: "MT" } },
  { name: "SANTA TEREZINHA", value: { cityName: "SANTA TEREZINHA", uf: "PE" } },
  { name: "SANTA TEREZINHA", value: { cityName: "SANTA TEREZINHA", uf: "SC" } },
  { name: "SANTANA", value: { cityName: "SANTANA", uf: "AP" } },
  { name: "SANTAREM", value: { cityName: "SANTAREM", uf: "PB" } },
  { name: "SANTO AMARO", value: { cityName: "SANTO AMARO", uf: "BA" } },
  { name: "SANTO ANDRE", value: { cityName: "SANTO ANDRE", uf: "PB" } },
  { name: "SANTO ANTONIO", value: { cityName: "SANTO ANTONIO", uf: "RN" } },
  { name: "SANTOS DUMONT", value: { cityName: "SANTOS DUMONT", uf: "MG" } },
  { name: "SAO BENTO", value: { cityName: "SAO BENTO", uf: "MA" } },
  { name: "SAO BENTO", value: { cityName: "SAO BENTO", uf: "PB" } },
  { name: "SAO CARLOS", value: { cityName: "SAO CARLOS", uf: "SP" } },
  { name: "SAO DOMINGOS", value: { cityName: "SAO DOMINGOS", uf: "BA" } },
  { name: "SAO DOMINGOS", value: { cityName: "SAO DOMINGOS", uf: "PB" } },
  { name: "SAO DOMINGOS", value: { cityName: "SAO DOMINGOS", uf: "SE" } },
  { name: "SAO FRANCISCO", value: { cityName: "SAO FRANCISCO", uf: "PB" } },
  { name: "SAO FRANCISCO", value: { cityName: "SAO FRANCISCO", uf: "SE" } },
  { name: "SAO FRANCISCO", value: { cityName: "SAO FRANCISCO", uf: "SP" } },
  {
    name: "SAO FRANCISCO DE PAULA",
    value: { cityName: "SAO FRANCISCO DE PAULA", uf: "MG" },
  },
  { name: "SAO GABRIEL", value: { cityName: "SAO GABRIEL", uf: "BA" } },
  {
    name: "SAO GONCALO DO AMARANTE",
    value: { cityName: "SAO GONCALO DO AMARANTE", uf: "RN" },
  },
  { name: "SAO JOAO", value: { cityName: "SAO JOAO", uf: "PR" } },
  {
    name: "SAO JOAO BATISTA",
    value: { cityName: "SAO JOAO BATISTA", uf: "MA" },
  },
  {
    name: "SAO JOAO DALIANCA",
    value: { cityName: "SAO JOAO DALIANCA", uf: "GO" },
  },
  {
    name: "SAO JOAO DO PARAISO",
    value: { cityName: "SAO JOAO DO PARAISO", uf: "MA" },
  },
  {
    name: "SAO JOSE DO DIVINO",
    value: { cityName: "SAO JOSE DO DIVINO", uf: "PI" },
  },
  { name: "SAO LUIS", value: { cityName: "SAO LUIS", uf: "RR" } },
  { name: "SAO PEDRO", value: { cityName: "SAO PEDRO", uf: "RN" } },
  {
    name: "SAO RAIMUNDO NONATO",
    value: { cityName: "SAO RAIMUNDO NONATO", uf: "PI" },
  },
  { name: "SAO SEBASTIAO", value: { cityName: "SAO SEBASTIAO", uf: "AL" } },
  { name: "SAO SIMAO", value: { cityName: "SAO SIMAO", uf: "SP" } },
  { name: "SAO TIAGO", value: { cityName: "SAO TIAGO", uf: "MG" } },
  { name: "SAO TOME", value: { cityName: "SAO TOME", uf: "PR" } },
  { name: "SAO VICENTE", value: { cityName: "SAO VICENTE", uf: "RN" } },
  {
    name: "SAO VICENTE FERRER",
    value: { cityName: "SAO VICENTE FERRER", uf: "PE" },
  },
  { name: "SAPUCAIA", value: { cityName: "SAPUCAIA", uf: "RJ" } },
  { name: "SARANDI", value: { cityName: "SARANDI", uf: "PR" } },
  { name: "SERRINHA", value: { cityName: "SERRINHA", uf: "RN" } },
  { name: "SERTAOZINHO", value: { cityName: "SERTAOZINHO", uf: "PB" } },
  { name: "SITIO NOVO", value: { cityName: "SITIO NOVO", uf: "MA" } },
  { name: "SOBRADINHO", value: { cityName: "SOBRADINHO", uf: "BA" } },
  { name: "TABATINGA", value: { cityName: "TABATINGA", uf: "AM" } },
  { name: "TANGARA", value: { cityName: "TANGARA", uf: "RN" } },
  { name: "TAPEJARA", value: { cityName: "TAPEJARA", uf: "PR" } },
  { name: "TAPEROA", value: { cityName: "TAPEROA", uf: "PB" } },
  { name: "TAPIRA", value: { cityName: "TAPIRA", uf: "PR" } },
  { name: "TAPIRAI", value: { cityName: "TAPIRAI", uf: "MG" } },
  { name: "TAVARES", value: { cityName: "TAVARES", uf: "PB" } },
  { name: "TEIXEIRA", value: { cityName: "TEIXEIRA", uf: "PB" } },
  { name: "TEODORO SAMPAIO", value: { cityName: "TEODORO SAMPAIO", uf: "BA" } },
  { name: "TERRA NOVA", value: { cityName: "TERRA NOVA", uf: "PE" } },
  { name: "TERRA ROXA", value: { cityName: "TERRA ROXA", uf: "PR" } },
  { name: "TERRA ROXA", value: { cityName: "TERRA ROXA", uf: "SP" } },
  { name: "TOLEDO", value: { cityName: "TOLEDO", uf: "MG" } },
  { name: "TOLEDO", value: { cityName: "TOLEDO", uf: "PR" } },
  { name: "TRES LAGOAS", value: { cityName: "TRES LAGOAS", uf: "MS" } },
  { name: "TRES RIOS", value: { cityName: "TRES RIOS", uf: "RJ" } },
  { name: "TRINDADE", value: { cityName: "TRINDADE", uf: "PE" } },
  { name: "TRIUNFO", value: { cityName: "TRIUNFO", uf: "PB" } },
  { name: "TRIUNFO", value: { cityName: "TRIUNFO", uf: "PE" } },
  {
    name: "TUNEIRAS DO OESTE",
    value: { cityName: "TUNEIRAS DO OESTE", uf: "PR" },
  },
  { name: "TUPA", value: { cityName: "TUPA", uf: "SP" } },
  { name: "TURMALINA", value: { cityName: "TURMALINA", uf: "MG" } },
  { name: "TURVO", value: { cityName: "TURVO", uf: "PR" } },
  { name: "VALENCA", value: { cityName: "VALENCA", uf: "BA" } },
  { name: "VARGEM", value: { cityName: "VARGEM", uf: "SP" } },
  { name: "VARGEM BONITA", value: { cityName: "VARGEM BONITA", uf: "MG" } },
  { name: "VARZEA", value: { cityName: "VARZEA", uf: "RN" } },
  { name: "VARZEA GRANDE", value: { cityName: "VARZEA GRANDE", uf: "PI" } },
  { name: "VERA CRUZ", value: { cityName: "VERA CRUZ", uf: "BA" } },
  { name: "VERA CRUZ", value: { cityName: "VERA CRUZ", uf: "RN" } },
  { name: "VERA CRUZ", value: { cityName: "VERA CRUZ", uf: "SP" } },
  { name: "VIANA", value: { cityName: "VIANA", uf: "MA" } },
  { name: "VICOSA", value: { cityName: "VICOSA", uf: "AL" } },
  { name: "VICOSA", value: { cityName: "VICOSA", uf: "RN" } },
  { name: "WENCESLAU BRAZ", value: { cityName: "WENCESLAU BRAZ", uf: "PR" } },
  {
    name: "NOVO SANTO ANTONIO",
    value: { cityName: "NOVO SANTO ANTONIO", uf: "MT" },
  },
  { name: "PAULO BENTO", value: { cityName: "PAULO BENTO", uf: "RS" } },
  {
    name: "CAMPESTRE DA SERRA",
    value: { cityName: "CAMPESTRE DA SERRA", uf: "RS" },
  },
  { name: "CRISTAL", value: { cityName: "CRISTAL", uf: "RS" } },
  {
    name: "SAO FRANCISCO DE PAULA",
    value: { cityName: "SAO FRANCISCO DE PAULA", uf: "RS" },
  },
  { name: "SANTA ROSA", value: { cityName: "SANTA ROSA", uf: "RS" } },
  {
    name: "SANTA CECILIA DO SUL",
    value: { cityName: "SANTA CECILIA DO SUL", uf: "RS" },
  },
  { name: "IGUATEMI", value: { cityName: "IGUATEMI", uf: "MS" } },
  { name: "BIGUACU", value: { cityName: "BIGUACU", uf: "SC" } },
  { name: "VILA MARIA", value: { cityName: "VILA MARIA", uf: "RS" } },
  { name: "BARAO", value: { cityName: "BARAO", uf: "RS" } },
  {
    name: "SILVEIRA MARTINS",
    value: { cityName: "SILVEIRA MARTINS", uf: "RS" },
  },
  { name: "QUATRO IRMAOS", value: { cityName: "QUATRO IRMAOS", uf: "RS" } },
  { name: "SAO JORGE", value: { cityName: "SAO JORGE", uf: "RS" } },
  { name: "TERESINA", value: { cityName: "TERESINA", uf: "PI" } },
  { name: "ARAMBARE", value: { cityName: "ARAMBARE", uf: "RS" } },
  { name: "ILHA COMPRIDA", value: { cityName: "ILHA COMPRIDA", uf: "SP" } },
  {
    name: "JARDIM DO MULATO",
    value: { cityName: "JARDIM DO MULATO", uf: "PI" },
  },
  {
    name: "AGUA AZUL DO NORTE",
    value: { cityName: "AGUA AZUL DO NORTE", uf: "PA" },
  },
  {
    name: "RECANTO DAS EMAS",
    value: { cityName: "RECANTO DAS EMAS", uf: "DF" },
  },
  { name: "OSASCO", value: { cityName: "OSASCO", uf: "SP" } },
  { name: "CACAPA", value: { cityName: "CACAPA", uf: "SP" } },
  { name: "BARUERI", value: { cityName: "BARUERI", uf: "SP" } },
  {
    name: "SANTANA DE PARNAIBA",
    value: { cityName: "SANTANA DE PARNAIBA", uf: "SP" },
  },
  {
    name: "PIRAPORA DO BOM JESUS",
    value: { cityName: "PIRAPORA DO BOM JESUS", uf: "SP" },
  },
  { name: "JANDIRA", value: { cityName: "JANDIRA", uf: "SP" } },
  { name: "ITAPEVI", value: { cityName: "ITAPEVI", uf: "SP" } },
  { name: "COTIA", value: { cityName: "COTIA", uf: "SP" } },
  {
    name: "VARGEM GRANDE PAULISTA",
    value: { cityName: "VARGEM GRANDE PAULISTA", uf: "SP" },
  },
  { name: "TABOAO DA SERRA", value: { cityName: "TABOAO DA SERRA", uf: "SP" } },
  { name: "EMBU", value: { cityName: "EMBU", uf: "SP" } },
  {
    name: "SAO LOURENCO DA SERRA",
    value: { cityName: "SAO LOURENCO DA SERRA", uf: "SP" },
  },
  { name: "EMBU-GUACU", value: { cityName: "EMBU-GUACU", uf: "SP" } },
  { name: "JUQUITIBA", value: { cityName: "JUQUITIBA", uf: "SP" } },
  { name: "GUARULHOS", value: { cityName: "GUARULHOS", uf: "SP" } },
  { name: "ARUJA", value: { cityName: "ARUJA", uf: "SP" } },
  { name: "MAIRIPORA", value: { cityName: "MAIRIPORA", uf: "SP" } },
  { name: "CAIEIRAS", value: { cityName: "CAIEIRAS", uf: "SP" } },
  { name: "CAJAMAR", value: { cityName: "CAJAMAR", uf: "SP" } },
  { name: "FRANCO DA ROCHA", value: { cityName: "FRANCO DA ROCHA", uf: "SP" } },
  {
    name: "FERRAZ DE VASCONCELOS",
    value: { cityName: "FERRAZ DE VASCONCELOS", uf: "SP" },
  },
  { name: "ITAQUAQUECETUBA", value: { cityName: "ITAQUAQUECETUBA", uf: "SP" } },
  { name: "SUZANO", value: { cityName: "SUZANO", uf: "SP" } },
  { name: "MOGI DAS CRUZES", value: { cityName: "MOGI DAS CRUZES", uf: "SP" } },
  { name: "GUARAREMA", value: { cityName: "GUARAREMA", uf: "SP" } },
  { name: "SALESOPOLIS", value: { cityName: "SALESOPOLIS", uf: "SP" } },
  { name: "SANTO ANDRE", value: { cityName: "SANTO ANDRE", uf: "SP" } },
  { name: "RIBEIRAO PIRES", value: { cityName: "RIBEIRAO PIRES", uf: "SP" } },
  {
    name: "RIO GRANDE DA SERRA",
    value: { cityName: "RIO GRANDE DA SERRA", uf: "SP" },
  },
  {
    name: "SAO CAETANO DO SUL",
    value: { cityName: "SAO CAETANO DO SUL", uf: "SP" },
  },
  { name: "BERTIOGA", value: { cityName: "BERTIOGA", uf: "SP" } },
  { name: "SAO VICENTE", value: { cityName: "SAO VICENTE", uf: "SP" } },
  { name: "GUARUJA", value: { cityName: "GUARUJA", uf: "SP" } },
  { name: "SAO SEBASTIAO", value: { cityName: "SAO SEBASTIAO", uf: "SP" } },
  { name: "ILHABELA", value: { cityName: "ILHABELA", uf: "SP" } },
  { name: "CARAGUATATUBA", value: { cityName: "CARAGUATATUBA", uf: "SP" } },
  { name: "UBATUBA", value: { cityName: "UBATUBA", uf: "SP" } },
  { name: "MONGAGUA", value: { cityName: "MONGAGUA", uf: "SP" } },
  { name: "ITANHAEM", value: { cityName: "ITANHAEM", uf: "SP" } },
  { name: "PERUIBE", value: { cityName: "PERUIBE", uf: "SP" } },
  { name: "ITARIRI", value: { cityName: "ITARIRI", uf: "SP" } },
  { name: "PEDRO DE TOLEDO", value: { cityName: "PEDRO DE TOLEDO", uf: "SP" } },
  { name: "JUQUIA", value: { cityName: "JUQUIA", uf: "SP" } },
  { name: "MIRACATU", value: { cityName: "MIRACATU", uf: "SP" } },
  { name: "REGISTRO", value: { cityName: "REGISTRO", uf: "SP" } },
  { name: "SETE BARRAS", value: { cityName: "SETE BARRAS", uf: "SP" } },
  { name: "IGUAPE", value: { cityName: "IGUAPE", uf: "SP" } },
  { name: "PARIQUERA-ACU", value: { cityName: "PARIQUERA-ACU", uf: "SP" } },
  { name: "JACUPIRANGA", value: { cityName: "JACUPIRANGA", uf: "SP" } },
  { name: "ELDORADO", value: { cityName: "ELDORADO", uf: "SP" } },
  { name: "CANANEIA", value: { cityName: "CANANEIA", uf: "SP" } },
  { name: "TAUBATE", value: { cityName: "TAUBATE", uf: "SP" } },
  { name: "TREMEMBE", value: { cityName: "TREMEMBE", uf: "SP" } },
  { name: "LAGOINHA", value: { cityName: "LAGOINHA", uf: "SP" } },
  {
    name: "REDENCAO DA SERRA",
    value: { cityName: "REDENCAO DA SERRA", uf: "SP" },
  },
  { name: "MONTEIRO LOBATO", value: { cityName: "MONTEIRO LOBATO", uf: "SP" } },
  { name: "PARAIBUNA", value: { cityName: "PARAIBUNA", uf: "SP" } },
  { name: "CACAPAVA", value: { cityName: "CACAPAVA", uf: "SP" } },
  { name: "JAMBEIRO", value: { cityName: "JAMBEIRO", uf: "SP" } },
  { name: "JACAREI", value: { cityName: "JACAREI", uf: "SP" } },
  { name: "IGARATA", value: { cityName: "IGARATA", uf: "SP" } },
  { name: "SANTA BRANCA", value: { cityName: "SANTA BRANCA", uf: "SP" } },
  { name: "PINDAMONHANGABA", value: { cityName: "PINDAMONHANGABA", uf: "SP" } },
  {
    name: "SANTO ANTONIO DO PINHAL",
    value: { cityName: "SANTO ANTONIO DO PINHAL", uf: "SP" },
  },
  {
    name: "CAMPOS DO JORDAO",
    value: { cityName: "CAMPOS DO JORDAO", uf: "SP" },
  },
  {
    name: "SAO BENTO DO SAPUCAI",
    value: { cityName: "SAO BENTO DO SAPUCAI", uf: "SP" },
  },
  { name: "GUARATINGUETA", value: { cityName: "GUARATINGUETA", uf: "SP" } },
  { name: "POTIM", value: { cityName: "POTIM", uf: "SP" } },
  { name: "CUNHA", value: { cityName: "CUNHA", uf: "SP" } },
  { name: "APARECIDA", value: { cityName: "APARECIDA", uf: "SP" } },
  { name: "ROSEIRA", value: { cityName: "ROSEIRA", uf: "SP" } },
  {
    name: "CACHOEIRA PAULISTA",
    value: { cityName: "CACHOEIRA PAULISTA", uf: "SP" },
  },
  { name: "SILVEIRAS", value: { cityName: "SILVEIRAS", uf: "SP" } },
  { name: "CRUZEIRO", value: { cityName: "CRUZEIRO", uf: "SP" } },
  { name: "LAVRINHAS", value: { cityName: "LAVRINHAS", uf: "SP" } },
  { name: "QUELUZ", value: { cityName: "QUELUZ", uf: "SP" } },
  { name: "AREIAS", value: { cityName: "AREIAS", uf: "SP" } },
  { name: "BANANAL", value: { cityName: "BANANAL", uf: "SP" } },
  {
    name: "BRAGANCA PAULISTA",
    value: { cityName: "BRAGANCA PAULISTA", uf: "SP" },
  },
  { name: "ATIBAIA", value: { cityName: "ATIBAIA", uf: "SP" } },
  { name: "NAZARE PAULISTA", value: { cityName: "NAZARE PAULISTA", uf: "SP" } },
  { name: "PIRACAIA", value: { cityName: "PIRACAIA", uf: "SP" } },
  { name: "JOANOPOLIS", value: { cityName: "JOANOPOLIS", uf: "SP" } },
  { name: "PAULINIA", value: { cityName: "PAULINIA", uf: "SP" } },
  { name: "COSMOPOLIS", value: { cityName: "COSMOPOLIS", uf: "SP" } },
  { name: "ARTUR NOGUEIRA", value: { cityName: "ARTUR NOGUEIRA", uf: "SP" } },
  { name: "SUMARE", value: { cityName: "SUMARE", uf: "SP" } },
  { name: "HORTOLANDIA", value: { cityName: "HORTOLANDIA", uf: "SP" } },
  { name: "TORRES", value: { cityName: "TORRES", uf: "SP" } },
  { name: "ITUPEVA", value: { cityName: "ITUPEVA", uf: "SP" } },
  { name: "VARZEA PAULISTA", value: { cityName: "VARZEA PAULISTA", uf: "SP" } },
  {
    name: "CAMPO LIMPO PAULISTA",
    value: { cityName: "CAMPO LIMPO PAULISTA", uf: "SP" },
  },
  { name: "JARINU", value: { cityName: "JARINU", uf: "SP" } },
  { name: "ITATIBA", value: { cityName: "ITATIBA", uf: "SP" } },
  { name: "MORUNGABA", value: { cityName: "MORUNGABA", uf: "SP" } },
  { name: "VALINHOS", value: { cityName: "VALINHOS", uf: "SP" } },
  { name: "VINHEDO", value: { cityName: "VINHEDO", uf: "SP" } },
  { name: "LOUVEIRA", value: { cityName: "LOUVEIRA", uf: "SP" } },
  { name: "ITU", value: { cityName: "ITU", uf: "SP" } },
  { name: "CABREUVA", value: { cityName: "CABREUVA", uf: "SP" } },
  { name: "SALTO", value: { cityName: "SALTO", uf: "SP" } },
  { name: "INDAIATUBA", value: { cityName: "INDAIATUBA", uf: "SP" } },
  { name: "RAFARD", value: { cityName: "RAFARD", uf: "SP" } },
  { name: "RIO DAS PEDRAS", value: { cityName: "RIO DAS PEDRAS", uf: "SP" } },
  { name: "SALTINHO", value: { cityName: "SALTINHO", uf: "SP" } },
  { name: "CHARQUEADA", value: { cityName: "CHARQUEADA", uf: "SP" } },
  {
    name: "SANTA BARBARA D OESTE",
    value: { cityName: "SANTA BARBARA D OESTE", uf: "SP" },
  },
  { name: "AMERICANA", value: { cityName: "AMERICANA", uf: "SP" } },
  { name: "LIMEIRA", value: { cityName: "LIMEIRA", uf: "SP" } },
  { name: "CORDEIROPOLIS", value: { cityName: "CORDEIROPOLIS", uf: "SP" } },
  { name: "RIO CLARO", value: { cityName: "RIO CLARO", uf: "SP" } },
  { name: "IPEUNA", value: { cityName: "IPEUNA", uf: "SP" } },
  { name: "SANTA GERTRUDES", value: { cityName: "SANTA GERTRUDES", uf: "SP" } },
  { name: "SAO PEDRO", value: { cityName: "SAO PEDRO", uf: "SP" } },
  { name: "ITIRAPINA", value: { cityName: "ITIRAPINA", uf: "SP" } },
  { name: "CORUMBATAI", value: { cityName: "CORUMBATAI", uf: "SP" } },
  { name: "ANALANDIA", value: { cityName: "ANALANDIA", uf: "SP" } },
  { name: "RIBEIRAO BONITO", value: { cityName: "RIBEIRAO BONITO", uf: "SP" } },
  { name: "DOURADO", value: { cityName: "DOURADO", uf: "SP" } },
  { name: "ARARAS", value: { cityName: "ARARAS", uf: "SP" } },
  { name: "LEME", value: { cityName: "LEME", uf: "SP" } },
  { name: "PIRASSUNUNGA", value: { cityName: "PIRASSUNUNGA", uf: "SP" } },
  {
    name: "SANTA CRUZ DAS PALMEIRAS",
    value: { cityName: "SANTA CRUZ DAS PALMEIRAS", uf: "SP" },
  },
  { name: "PORTO FERREIRA", value: { cityName: "PORTO FERREIRA", uf: "SP" } },
  {
    name: "SANTA RITA DO PASSA QUATRO",
    value: { cityName: "SANTA RITA DO PASSA QUATRO", uf: "SP" },
  },
  { name: "DESCALVADO", value: { cityName: "DESCALVADO", uf: "SP" } },
  { name: "CASA BRANCA", value: { cityName: "CASA BRANCA", uf: "SP" } },
  { name: "TAMBAU", value: { cityName: "TAMBAU", uf: "SP" } },
  { name: "ITOBI", value: { cityName: "ITOBI", uf: "SP" } },
  {
    name: "SAO JOSE DO RIO PARDO",
    value: { cityName: "SAO JOSE DO RIO PARDO", uf: "SP" },
  },
  { name: "MOCOCA", value: { cityName: "MOCOCA", uf: "SP" } },
  { name: "TAPIRATIBA", value: { cityName: "TAPIRATIBA", uf: "SP" } },
  { name: "CACONDE", value: { cityName: "CACONDE", uf: "SP" } },
  { name: "DIVINOLANDIA", value: { cityName: "DIVINOLANDIA", uf: "SP" } },
  {
    name: "SAO SEBASTIAO DA GRAMA",
    value: { cityName: "SAO SEBASTIAO DA GRAMA", uf: "SP" },
  },
  { name: "JAGUARIUNA", value: { cityName: "JAGUARIUNA", uf: "SP" } },
  { name: "HOLAMBRA", value: { cityName: "HOLAMBRA", uf: "SP" } },
  { name: "AGUAI", value: { cityName: "AGUAI", uf: "SP" } },
  {
    name: "SAO JOAO DA BOA VISTA",
    value: { cityName: "SAO JOAO DA BOA VISTA", uf: "SP" },
  },
  {
    name: "VARGEM GRANDE DO SUL",
    value: { cityName: "VARGEM GRANDE DO SUL", uf: "SP" },
  },
  { name: "AGUAS DA PRATA", value: { cityName: "AGUAS DA PRATA", uf: "SP" } },
  { name: "AMPARO", value: { cityName: "AMPARO", uf: "SP" } },
  { name: "PEDREIRA", value: { cityName: "PEDREIRA", uf: "SP" } },
  { name: "SERRA NEGRA", value: { cityName: "SERRA NEGRA", uf: "SP" } },
  { name: "LINDOIA", value: { cityName: "LINDOIA", uf: "SP" } },
  { name: "SOCORRO", value: { cityName: "SOCORRO", uf: "SP" } },
  { name: "ITAPIRA", value: { cityName: "ITAPIRA", uf: "SP" } },
  { name: "RIBEIRAO PRETO", value: { cityName: "RIBEIRAO PRETO", uf: "SP" } },
  { name: "DUMONT", value: { cityName: "DUMONT", uf: "SP" } },
  { name: "CRAVINHOS", value: { cityName: "CRAVINHOS", uf: "SP" } },
  { name: "SERRANA", value: { cityName: "SERRANA", uf: "SP" } },
  { name: "SERTAOZINHO", value: { cityName: "SERTAOZINHO", uf: "SP" } },
  { name: "PONTAL", value: { cityName: "PONTAL", uf: "SP" } },
  { name: "LUIS ANTONIO", value: { cityName: "LUIS ANTONIO", uf: "SP" } },
  { name: "SERRA AZUL", value: { cityName: "SERRA AZUL", uf: "SP" } },
  { name: "CAJURU", value: { cityName: "CAJURU", uf: "SP" } },
  {
    name: "CASSIA DOS COQUEIROS",
    value: { cityName: "CASSIA DOS COQUEIROS", uf: "SP" },
  },
  {
    name: "SANTA ROSA DE VITERBO",
    value: { cityName: "SANTA ROSA DE VITERBO", uf: "SP" },
  },
  { name: "BATATAIS", value: { cityName: "BATATAIS", uf: "SP" } },
  { name: "BRODOWSKI", value: { cityName: "BRODOWSKI", uf: "SP" } },
  { name: "ALTINOPOLIS", value: { cityName: "ALTINOPOLIS", uf: "SP" } },
  {
    name: "SANTO ANTONIO DA ALEGRIA",
    value: { cityName: "SANTO ANTONIO DA ALEGRIA", uf: "SP" },
  },
  { name: "FRANCA", value: { cityName: "FRANCA", uf: "SP" } },
  {
    name: "PATROCINIO PAULISTA",
    value: { cityName: "PATROCINIO PAULISTA", uf: "SP" },
  },
  { name: "ITIRAPUA", value: { cityName: "ITIRAPUA", uf: "SP" } },
  { name: "RESTINGA", value: { cityName: "RESTINGA", uf: "SP" } },
  {
    name: "RIBEIRAO CORRENTE",
    value: { cityName: "RIBEIRAO CORRENTE", uf: "SP" },
  },
  { name: "JERIQUARA", value: { cityName: "JERIQUARA", uf: "SP" } },
  {
    name: "CRISTAIS PAULISTA",
    value: { cityName: "CRISTAIS PAULISTA", uf: "SP" },
  },
  { name: "PEDREGULHO", value: { cityName: "PEDREGULHO", uf: "SP" } },
  { name: "RIFAINA", value: { cityName: "RIFAINA", uf: "SP" } },
  { name: "ITUVERAVA", value: { cityName: "ITUVERAVA", uf: "SP" } },
  { name: "MIGUELOPOLIS", value: { cityName: "MIGUELOPOLIS", uf: "SP" } },
  { name: "IGARAPAVA", value: { cityName: "IGARAPAVA", uf: "SP" } },
  { name: "BURITIZAL", value: { cityName: "BURITIZAL", uf: "SP" } },
  { name: "GUARA", value: { cityName: "GUARA", uf: "SP" } },
  {
    name: "SAO JOAQUIM DA BARRA",
    value: { cityName: "SAO JOAQUIM DA BARRA", uf: "SP" },
  },
  { name: "IPUA", value: { cityName: "IPUA", uf: "SP" } },
  { name: "ORLANDIA", value: { cityName: "ORLANDIA", uf: "SP" } },
  { name: "MORRO AGUDO", value: { cityName: "MORRO AGUDO", uf: "SP" } },
  { name: "SALES OLIVEIRA", value: { cityName: "SALES OLIVEIRA", uf: "SP" } },
  { name: "NUPORANGA", value: { cityName: "NUPORANGA", uf: "SP" } },
  { name: "BEBEDOURO", value: { cityName: "BEBEDOURO", uf: "SP" } },
  { name: "TAIUVA", value: { cityName: "TAIUVA", uf: "SP" } },
  {
    name: "MONTE AZUL PAULISTA",
    value: { cityName: "MONTE AZUL PAULISTA", uf: "SP" },
  },
  { name: "SEVERINIA", value: { cityName: "SEVERINIA", uf: "SP" } },
  { name: "VIRADOURO", value: { cityName: "VIRADOURO", uf: "SP" } },
  { name: "PITANGUEIRAS", value: { cityName: "PITANGUEIRAS", uf: "SP" } },
  { name: "COLINA", value: { cityName: "COLINA", uf: "SP" } },
  { name: "JABORANDI", value: { cityName: "JABORANDI", uf: "SP" } },
  { name: "GUAIRA", value: { cityName: "GUAIRA", uf: "SP" } },
  { name: "ARARAQUARA", value: { cityName: "ARARAQUARA", uf: "SP" } },
  { name: "MOTUCA", value: { cityName: "MOTUCA", uf: "SP" } },
  { name: "IBATE", value: { cityName: "IBATE", uf: "SP" } },
  { name: "RINCAO", value: { cityName: "RINCAO", uf: "SP" } },
  { name: "GUARIBA", value: { cityName: "GUARIBA", uf: "SP" } },
  { name: "PRADOPOLIS", value: { cityName: "PRADOPOLIS", uf: "SP" } },
  { name: "JABOTICABAL", value: { cityName: "JABOTICABAL", uf: "SP" } },
  { name: "ITAPOLIS", value: { cityName: "ITAPOLIS", uf: "SP" } },
  { name: "TABATINGA", value: { cityName: "TABATINGA", uf: "SP" } },
  { name: "NOVA EUROPA", value: { cityName: "NOVA EUROPA", uf: "SP" } },
  {
    name: "BOA ESPERANCA DO SUL",
    value: { cityName: "BOA ESPERANCA DO SUL", uf: "SP" },
  },
  { name: "IBITINGA", value: { cityName: "IBITINGA", uf: "SP" } },
  { name: "BORBOREMA", value: { cityName: "BORBOREMA", uf: "SP" } },
  { name: "SALES", value: { cityName: "SALES", uf: "SP" } },
  { name: "IRAPUA", value: { cityName: "IRAPUA", uf: "SP" } },
  { name: "POTIRENDABA", value: { cityName: "POTIRENDABA", uf: "SP" } },
  { name: "GUAPIACU", value: { cityName: "GUAPIACU", uf: "SP" } },
  { name: "BADY BASSITT", value: { cityName: "BADY BASSITT", uf: "SP" } },
  { name: "NEVES PAULISTA", value: { cityName: "NEVES PAULISTA", uf: "SP" } },
  { name: "MIRASSOL", value: { cityName: "MIRASSOL", uf: "SP" } },
  { name: "BALSAMO", value: { cityName: "BALSAMO", uf: "SP" } },
  { name: "MIRASSOLANDIA", value: { cityName: "MIRASSOLANDIA", uf: "SP" } },
  { name: "MONTE APRAZIVEL", value: { cityName: "MONTE APRAZIVEL", uf: "SP" } },
  { name: "JACI", value: { cityName: "JACI", uf: "SP" } },
  { name: "POLONI", value: { cityName: "POLONI", uf: "SP" } },
  { name: "TANABI", value: { cityName: "TANABI", uf: "SP" } },
  {
    name: "SEBASTIANOPOLIS DO SUL",
    value: { cityName: "SEBASTIANOPOLIS DO SUL", uf: "SP" },
  },
  { name: "NHANDEARA", value: { cityName: "NHANDEARA", uf: "SP" } },
  { name: "JOSE BONIFACIO", value: { cityName: "JOSE BONIFACIO", uf: "SP" } },
  { name: "UBARANA", value: { cityName: "UBARANA", uf: "SP" } },
  { name: "NOVA ALIANCA", value: { cityName: "NOVA ALIANCA", uf: "SP" } },
  { name: "MENDONCA", value: { cityName: "MENDONCA", uf: "SP" } },
  { name: "ADOLFO", value: { cityName: "ADOLFO", uf: "SP" } },
  { name: "NIPOA", value: { cityName: "NIPOA", uf: "SP" } },
  { name: "UNIAO PAULISTA", value: { cityName: "UNIAO PAULISTA", uf: "SP" } },
  { name: "ZACARIAS", value: { cityName: "ZACARIAS", uf: "SP" } },
  { name: "MACAUBAL", value: { cityName: "MACAUBAL", uf: "SP" } },
  { name: "LOURDES", value: { cityName: "LOURDES", uf: "SP" } },
  { name: "GENERAL SALGADO", value: { cityName: "GENERAL SALGADO", uf: "SP" } },
  { name: "MAGDA", value: { cityName: "MAGDA", uf: "SP" } },
  { name: "FLOREAL", value: { cityName: "FLOREAL", uf: "SP" } },
  { name: "AURIFLAMA", value: { cityName: "AURIFLAMA", uf: "SP" } },
  { name: "PEREIRA BARRETO", value: { cityName: "PEREIRA BARRETO", uf: "SP" } },
  { name: "ILHA SOLTEIRA", value: { cityName: "ILHA SOLTEIRA", uf: "SP" } },
  { name: "ITAPURA", value: { cityName: "ITAPURA", uf: "SP" } },
  { name: "OLIMPIA", value: { cityName: "OLIMPIA", uf: "SP" } },
  { name: "CAJOBI", value: { cityName: "CAJOBI", uf: "SP" } },
  { name: "GUARACI", value: { cityName: "GUARACI", uf: "SP" } },
  { name: "ALTAIR", value: { cityName: "ALTAIR", uf: "SP" } },
  { name: "NOVA GRANADA", value: { cityName: "NOVA GRANADA", uf: "SP" } },
  { name: "ONDA VERDE", value: { cityName: "ONDA VERDE", uf: "SP" } },
  { name: "ICEM", value: { cityName: "ICEM", uf: "SP" } },
  { name: "PALESTINA", value: { cityName: "PALESTINA", uf: "SP" } },
  { name: "ORINDIUVA", value: { cityName: "ORINDIUVA", uf: "SP" } },
  { name: "PAULO DE FARIA", value: { cityName: "PAULO DE FARIA", uf: "SP" } },
  { name: "RIOLANDIA", value: { cityName: "RIOLANDIA", uf: "RJ" } },
  { name: "VOTUPORANGA", value: { cityName: "VOTUPORANGA", uf: "SP" } },
  { name: "COSMORAMA", value: { cityName: "COSMORAMA", uf: "SP" } },
  {
    name: "ALVARES FLORENCE",
    value: { cityName: "ALVARES FLORENCE", uf: "SP" },
  },
  {
    name: "AMERICO DE CAMPOS",
    value: { cityName: "AMERICO DE CAMPOS", uf: "SP" },
  },
  { name: "PONTES GESTAL", value: { cityName: "PONTES GESTAL", uf: "SP" } },
  { name: "CARDOSO", value: { cityName: "CARDOSO", uf: "SP" } },
  { name: "FERNANDOPOLIS", value: { cityName: "FERNANDOPOLIS", uf: "SP" } },
  { name: "MERIDIANO", value: { cityName: "MERIDIANO", uf: "SP" } },
  { name: "PEDRANOPOLIS", value: { cityName: "PEDRANOPOLIS", uf: "SP" } },
  { name: "ESTRELA DOESTE", value: { cityName: "ESTRELA DOESTE", uf: "SP" } },
  { name: "POPULINA", value: { cityName: "POPULINA", uf: "SP" } },
  { name: "JALES", value: { cityName: "JALES", uf: "SP" } },
  { name: "PALMEIRA DOESTE", value: { cityName: "PALMEIRA DOESTE", uf: "SP" } },
  { name: "SANTA ALBERTINA", value: { cityName: "SANTA ALBERTINA", uf: "SP" } },
  { name: "TURMALINA", value: { cityName: "TURMALINA", uf: "SP" } },
  { name: "URANIA", value: { cityName: "URANIA", uf: "SP" } },
  { name: "ASPASIA", value: { cityName: "ASPASIA", uf: "SP" } },
  { name: "TRES FRONTEIRAS", value: { cityName: "TRES FRONTEIRAS", uf: "SP" } },
  { name: "SANTA FE DO SUL", value: { cityName: "SANTA FE DO SUL", uf: "SP" } },
  {
    name: "SANTA RITA DOESTE",
    value: { cityName: "SANTA RITA DOESTE", uf: "SP" },
  },
  {
    name: "PALMARES PAULISTA",
    value: { cityName: "PALMARES PAULISTA", uf: "SP" },
  },
  { name: "PINDORAMA", value: { cityName: "PINDORAMA", uf: "SP" } },
  { name: "ITAJOBI", value: { cityName: "ITAJOBI", uf: "SP" } },
  { name: "MARAPOAMA", value: { cityName: "MARAPOAMA", uf: "SP" } },
  { name: "IBIRA", value: { cityName: "IBIRA", uf: "SP" } },
  { name: "CATIGUA", value: { cityName: "CATIGUA", uf: "SP" } },
  { name: "TABAPUA", value: { cityName: "TABAPUA", uf: "SP" } },
  { name: "NOVAIS", value: { cityName: "NOVAIS", uf: "SP" } },
  { name: "UCHOA", value: { cityName: "UCHOA", uf: "SP" } },
  { name: "CEDRAL", value: { cityName: "CEDRAL", uf: "SP" } },
  { name: "TAQUARITINGA", value: { cityName: "TAQUARITINGA", uf: "SP" } },
  { name: "MONTE ALTO", value: { cityName: "MONTE ALTO", uf: "SP" } },
  {
    name: "CANDIDO RODRIGUES",
    value: { cityName: "CANDIDO RODRIGUES", uf: "SP" },
  },
  {
    name: "FERNANDO PRESTES",
    value: { cityName: "FERNANDO PRESTES", uf: "SP" },
  },
  { name: "SANTA ADELIA", value: { cityName: "SANTA ADELIA", uf: "SP" } },
  { name: "DOBRADA", value: { cityName: "DOBRADA", uf: "SP" } },
  { name: "MATAO", value: { cityName: "MATAO", uf: "SP" } },
  { name: "ARACATUBA", value: { cityName: "ARACATUBA", uf: "SP" } },
  {
    name: "SANTO ANTONIO DO ARACANGUA",
    value: { cityName: "SANTO ANTONIO DO ARACANGUA", uf: "SP" },
  },
  { name: "BIRIGUI", value: { cityName: "BIRIGUI", uf: "SP" } },
  { name: "BILAC", value: { cityName: "BILAC", uf: "SP" } },
  {
    name: "GABRIEL MONTEIRO",
    value: { cityName: "GABRIEL MONTEIRO", uf: "SP" },
  },
  { name: "PIACATU", value: { cityName: "PIACATU", uf: "SP" } },
  { name: "COROADOS", value: { cityName: "COROADOS", uf: "SP" } },
  { name: "GLICERIO", value: { cityName: "GLICERIO", uf: "SP" } },
  { name: "BRAUNA", value: { cityName: "BRAUNA", uf: "SP" } },
  { name: "PENAPOLIS", value: { cityName: "PENAPOLIS", uf: "SP" } },
  { name: "LUIZIANIA", value: { cityName: "LUIZIANIA", uf: "SP" } },
  { name: "BARBOSA", value: { cityName: "BARBOSA", uf: "SP" } },
  { name: "AVANHANDAVA", value: { cityName: "AVANHANDAVA", uf: "SP" } },
  { name: "PROMISSAO", value: { cityName: "PROMISSAO", uf: "SP" } },
  { name: "GUAICARA", value: { cityName: "GUAICARA", uf: "SP" } },
  { name: "SABINO", value: { cityName: "SABINO", uf: "SP" } },
  { name: "GETULINA", value: { cityName: "GETULINA", uf: "SP" } },
  { name: "GUAIMBE", value: { cityName: "GUAIMBE", uf: "SP" } },
  { name: "CAFELANDIA", value: { cityName: "CAFELANDIA", uf: "SP" } },
  { name: "GUARANTA", value: { cityName: "GUARANTA", uf: "SP" } },
  { name: "PIRAJUI", value: { cityName: "PIRAJUI", uf: "SP" } },
  { name: "BALBINOS", value: { cityName: "BALBINOS", uf: "SP" } },
  { name: "PONGAI", value: { cityName: "PONGAI", uf: "SP" } },
  {
    name: "PRESIDENTE ALVES",
    value: { cityName: "PRESIDENTE ALVES", uf: "SP" },
  },
  { name: "AVAI", value: { cityName: "AVAI", uf: "SP" } },
  { name: "GUARARAPES", value: { cityName: "GUARARAPES", uf: "SP" } },
  { name: "RUBIACEA", value: { cityName: "RUBIACEA", uf: "SP" } },
  { name: "BENTO DE ABREU", value: { cityName: "BENTO DE ABREU", uf: "SP" } },
  { name: "MIRANDOPOLIS", value: { cityName: "MIRANDOPOLIS", uf: "SP" } },
  { name: "LAVINIA", value: { cityName: "LAVINIA", uf: "SP" } },
  { name: "VALPARAISO", value: { cityName: "VALPARAISO", uf: "SP" } },
  { name: "ANDRADINA", value: { cityName: "ANDRADINA", uf: "SP" } },
  { name: "CASTILHO", value: { cityName: "CASTILHO", uf: "SP" } },
  { name: "GUARACAI", value: { cityName: "GUARACAI", uf: "SP" } },
  { name: "AGUDOS", value: { cityName: "AGUDOS", uf: "SP" } },
  { name: "AREALVA", value: { cityName: "AREALVA", uf: "SP" } },
  { name: "IACANGA", value: { cityName: "IACANGA", uf: "SP" } },
  { name: "REGINOPOLIS", value: { cityName: "REGINOPOLIS", uf: "SP" } },
  { name: "JAU", value: { cityName: "JAU", uf: "SP" } },
  { name: "ITAPUI", value: { cityName: "ITAPUI", uf: "SP" } },
  { name: "BOCAINA", value: { cityName: "BOCAINA", uf: "SP" } },
  { name: "BARIRI", value: { cityName: "BARIRI", uf: "SP" } },
  { name: "ITAJU", value: { cityName: "ITAJU", uf: "SP" } },
  { name: "PEDERNEIRAS", value: { cityName: "PEDERNEIRAS", uf: "SP" } },
  { name: "MACATUBA", value: { cityName: "MACATUBA", uf: "SP" } },
  { name: "DOIS CORREGOS", value: { cityName: "DOIS CORREGOS", uf: "SP" } },
  { name: "BARRA BONITA", value: { cityName: "BARRA BONITA", uf: "SP" } },
  {
    name: "IGARACU DO TIETE",
    value: { cityName: "IGARACU DO TIETE", uf: "SP" },
  },
  { name: "TORRINHA", value: { cityName: "TORRINHA", uf: "SP" } },
  {
    name: "SANTA MARIA DA SERRA",
    value: { cityName: "SANTA MARIA DA SERRA", uf: "SP" },
  },
  { name: "BROTAS", value: { cityName: "BROTAS", uf: "SP" } },
  { name: "GARCA", value: { cityName: "GARCA", uf: "SP" } },
  {
    name: "ALVARO DE CARVALHO",
    value: { cityName: "ALVARO DE CARVALHO", uf: "SP" },
  },
  { name: "LUPERCIO", value: { cityName: "LUPERCIO", uf: "SP" } },
  { name: "UBIRAJARA", value: { cityName: "UBIRAJARA", uf: "SP" } },
  { name: "GALIA", value: { cityName: "GALIA", uf: "SP" } },
  { name: "DUARTINA", value: { cityName: "DUARTINA", uf: "SP" } },
  { name: "LUCIANOPOLIS", value: { cityName: "LUCIANOPOLIS", uf: "SP" } },
  {
    name: "CABRALIA PAULISTA",
    value: { cityName: "CABRALIA PAULISTA", uf: "SP" },
  },
  { name: "PIRATININGA", value: { cityName: "PIRATININGA", uf: "SP" } },
  { name: "MARILIA", value: { cityName: "MARILIA", uf: "SP" } },
  { name: "OCAUCU", value: { cityName: "OCAUCU", uf: "SP" } },
  { name: "JULIO MESQUITA", value: { cityName: "JULIO MESQUITA", uf: "SP" } },
  { name: "POMPEIA", value: { cityName: "POMPEIA", uf: "SP" } },
  { name: "QUEIROZ", value: { cityName: "QUEIROZ", uf: "SP" } },
  { name: "HERCULANDIA", value: { cityName: "HERCULANDIA", uf: "SP" } },
  { name: "QUINTANA", value: { cityName: "QUINTANA", uf: "SP" } },
  { name: "IACRI", value: { cityName: "IACRI", uf: "SP" } },
  { name: "BASTOS", value: { cityName: "BASTOS", uf: "SP" } },
  { name: "OSVALDO CRUZ", value: { cityName: "OSVALDO CRUZ", uf: "SP" } },
  { name: "PARAPUA", value: { cityName: "PARAPUA", uf: "SP" } },
  { name: "RINOPOLIS", value: { cityName: "RINOPOLIS", uf: "SP" } },
  { name: "LUCELIA", value: { cityName: "LUCELIA", uf: "SP" } },
  { name: "ADAMANTINA", value: { cityName: "ADAMANTINA", uf: "SP" } },
  { name: "MARIAPOLIS", value: { cityName: "MARIAPOLIS", uf: "SP" } },
  {
    name: "FLORIDA PAULISTA",
    value: { cityName: "FLORIDA PAULISTA", uf: "SP" },
  },
  { name: "PACAEMBU", value: { cityName: "PACAEMBU", uf: "SP" } },
  { name: "FLORA RICA", value: { cityName: "FLORA RICA", uf: "SP" } },
  { name: "IRAPURU", value: { cityName: "IRAPURU", uf: "SP" } },
  { name: "JUNQUEIROPOLIS", value: { cityName: "JUNQUEIROPOLIS", uf: "SP" } },
  { name: "DRACENA", value: { cityName: "DRACENA", uf: "SP" } },
  { name: "TUPI PAULISTA", value: { cityName: "TUPI PAULISTA", uf: "SP" } },
  { name: "PANORAMA", value: { cityName: "PANORAMA", uf: "SP" } },
  { name: "PAULICEIA", value: { cityName: "PAULICEIA", uf: "SP" } },
  { name: "SOROCABA", value: { cityName: "SOROCABA", uf: "SP" } },
  { name: "VOTORANTIM", value: { cityName: "VOTORANTIM", uf: "SP" } },
  { name: "MAIRINQUE", value: { cityName: "MAIRINQUE", uf: "SP" } },
  { name: "ALUMINIO", value: { cityName: "ALUMINIO", uf: "SP" } },
  { name: "SAO ROQUE", value: { cityName: "SAO ROQUE", uf: "SP" } },
  { name: "IBIUNA", value: { cityName: "IBIUNA", uf: "SP" } },
  {
    name: "ARACOIABA DA SERRA",
    value: { cityName: "ARACOIABA DA SERRA", uf: "SP" },
  },
  { name: "CAPELA DO ALTO", value: { cityName: "CAPELA DO ALTO", uf: "SP" } },
  {
    name: "SALTO DE PIRAPORA",
    value: { cityName: "SALTO DE PIRAPORA", uf: "SP" },
  },
  { name: "PIEDADE", value: { cityName: "PIEDADE", uf: "SP" } },
  { name: "TAPIRAI", value: { cityName: "TAPIRAI", uf: "SP" } },
  { name: "PILAR DO SUL", value: { cityName: "PILAR DO SUL", uf: "SP" } },
  { name: "SARAPUI", value: { cityName: "SARAPUI", uf: "SP" } },
  {
    name: "SAO MIGUEL ARCANJO",
    value: { cityName: "SAO MIGUEL ARCANJO", uf: "SP" },
  },
  { name: "ANGATUBA", value: { cityName: "ANGATUBA", uf: "SP" } },
  { name: "GUAREI", value: { cityName: "GUAREI", uf: "SP" } },
  { name: "PORANGABA", value: { cityName: "PORANGABA", uf: "SP" } },
  { name: "TORRE DE PEDRA", value: { cityName: "TORRE DE PEDRA", uf: "SP" } },
  { name: "TATUI", value: { cityName: "TATUI", uf: "SP" } },
  { name: "BURI", value: { cityName: "BURI", uf: "SP" } },
  { name: "CAPAO BONITO", value: { cityName: "CAPAO BONITO", uf: "SP" } },
  { name: "RIBEIRAO GRANDE", value: { cityName: "RIBEIRAO GRANDE", uf: "SP" } },
  { name: "GUAPIARA", value: { cityName: "GUAPIARA", uf: "SP" } },
  { name: "APIAI", value: { cityName: "APIAI", uf: "SP" } },
  { name: "BARRA DO TURVO", value: { cityName: "BARRA DO TURVO", uf: "SP" } },
  { name: "ITAOCA", value: { cityName: "ITAOCA", uf: "SP" } },
  { name: "RIBEIRA", value: { cityName: "RIBEIRA", uf: "SP" } },
  { name: "ITAPEVA", value: { cityName: "ITAPEVA", uf: "SP" } },
  { name: "TAQUARIVAI", value: { cityName: "TAQUARIVAI", uf: "SP" } },
  { name: "RIBEIRAO BRANCO", value: { cityName: "RIBEIRAO BRANCO", uf: "SP" } },
  { name: "ITABERA", value: { cityName: "ITABERA", uf: "SP" } },
  { name: "ITARARE", value: { cityName: "ITARARE", uf: "SP" } },
  { name: "RIVERSUL", value: { cityName: "RIVERSUL", uf: "SP" } },
  { name: "ITAPORANGA", value: { cityName: "ITAPORANGA", uf: "SP" } },
  {
    name: "LARANJAL PAULISTA",
    value: { cityName: "LARANJAL PAULISTA", uf: "SP" },
  },
  { name: "CERQUILHO", value: { cityName: "CERQUILHO", uf: "SP" } },
  { name: "TIETE", value: { cityName: "TIETE", uf: "SP" } },
  { name: "PORTO FELIZ", value: { cityName: "PORTO FELIZ", uf: "SP" } },
  { name: "BOITUVA", value: { cityName: "BOITUVA", uf: "SP" } },
  { name: "IPERO", value: { cityName: "IPERO", uf: "SP" } },
  { name: "CONCHAS", value: { cityName: "CONCHAS", uf: "SP" } },
  { name: "PEREIRAS", value: { cityName: "PEREIRAS", uf: "SP" } },
  { name: "BOTUCATU", value: { cityName: "BOTUCATU", uf: "SP" } },
  { name: "ANHEMBI", value: { cityName: "ANHEMBI", uf: "SP" } },
  { name: "PARDINHO", value: { cityName: "PARDINHO", uf: "SP" } },
  { name: "SAO MANUEL", value: { cityName: "SAO MANUEL", uf: "SP" } },
  { name: "AREIOPOLIS", value: { cityName: "AREIOPOLIS", uf: "SP" } },
  {
    name: "LENCOIS PAULISTA",
    value: { cityName: "LENCOIS PAULISTA", uf: "SP" },
  },
  { name: "BOREBI", value: { cityName: "BOREBI", uf: "SP" } },
  { name: "ITATINGA", value: { cityName: "ITATINGA", uf: "SP" } },
  { name: "AVARE", value: { cityName: "AVARE", uf: "SP" } },
  { name: "PARANAPANEMA", value: { cityName: "PARANAPANEMA", uf: "SP" } },
  { name: "ITAI", value: { cityName: "ITAI", uf: "SP" } },
  { name: "CERQUEIRA CESAR", value: { cityName: "CERQUEIRA CESAR", uf: "SP" } },
  { name: "PIRAJU", value: { cityName: "PIRAJU", uf: "SP" } },
  { name: "TEJUPA", value: { cityName: "TEJUPA", uf: "SP" } },
  { name: "TIMBURI", value: { cityName: "TIMBURI", uf: "SP" } },
  { name: "FARTURA", value: { cityName: "FARTURA", uf: "SP" } },
  { name: "TAGUAI", value: { cityName: "TAGUAI", uf: "SP" } },
  {
    name: "SANTA CRUZ DO RIO PARDO",
    value: { cityName: "SANTA CRUZ DO RIO PARDO", uf: "SP" },
  },
  { name: "IPAUSSU", value: { cityName: "IPAUSSU", uf: "SP" } },
  {
    name: "BERNARDINO DE CAMPOS",
    value: { cityName: "BERNARDINO DE CAMPOS", uf: "SP" },
  },
  { name: "CHAVANTES", value: { cityName: "CHAVANTES", uf: "SP" } },
  {
    name: "PRESIDENTE PRUDENTE",
    value: { cityName: "PRESIDENTE PRUDENTE", uf: "SP" },
  },
  { name: "ALVARES MACHADO", value: { cityName: "ALVARES MACHADO", uf: "SP" } },
  {
    name: "ALFREDO MARCONDES",
    value: { cityName: "ALFREDO MARCONDES", uf: "SP" },
  },
  { name: "PIRAPOZINHO", value: { cityName: "PIRAPOZINHO", uf: "SP" } },
  { name: "TARABAI", value: { cityName: "TARABAI", uf: "SP" } },
  { name: "NARANDIBA", value: { cityName: "NARANDIBA", uf: "SP" } },
  { name: "SANDOVALINA", value: { cityName: "SANDOVALINA", uf: "SP" } },
  {
    name: "MIRANTE DO PARANAPANEMA",
    value: { cityName: "MIRANTE DO PARANAPANEMA", uf: "SP" },
  },
  { name: "TEODORO SAMPAIO", value: { cityName: "TEODORO SAMPAIO", uf: "SP" } },
  { name: "EMILIANOPOLIS", value: { cityName: "EMILIANOPOLIS", uf: "SP" } },
  { name: "SANTO ANASTACIO", value: { cityName: "SANTO ANASTACIO", uf: "SP" } },
  {
    name: "PRESIDENTE VENCESLAU",
    value: { cityName: "PRESIDENTE VENCESLAU", uf: "SP" },
  },
  { name: "PIQUEROBI", value: { cityName: "PIQUEROBI", uf: "SP" } },
  { name: "MARABA PAULISTA", value: { cityName: "MARABA PAULISTA", uf: "SP" } },
  {
    name: "PRESIDENTE EPITACIO",
    value: { cityName: "PRESIDENTE EPITACIO", uf: "SP" },
  },
  { name: "MARTINOPOLIS", value: { cityName: "MARTINOPOLIS", uf: "SP" } },
  { name: "CAIABU", value: { cityName: "CAIABU", uf: "SP" } },
  { name: "INDIANA", value: { cityName: "INDIANA", uf: "SP" } },
  { name: "REGENTE FEIJO", value: { cityName: "REGENTE FEIJO", uf: "SP" } },
  { name: "ANHUMAS", value: { cityName: "ANHUMAS", uf: "SP" } },
  { name: "TACIBA", value: { cityName: "TACIBA", uf: "SP" } },
  { name: "RANCHARIA", value: { cityName: "RANCHARIA", uf: "SP" } },
  { name: "IEPE", value: { cityName: "IEPE", uf: "SP" } },
  {
    name: "PARAGUACU PAULISTA",
    value: { cityName: "PARAGUACU PAULISTA", uf: "SP" },
  },
  { name: "BORA", value: { cityName: "BORA", uf: "SP" } },
  { name: "LUTECIA", value: { cityName: "LUTECIA", uf: "SP" } },
  { name: "OSCAR BRESSANE", value: { cityName: "OSCAR BRESSANE", uf: "SP" } },
  { name: "QUATA", value: { cityName: "QUATA", uf: "SP" } },
  { name: "TARUMA", value: { cityName: "TARUMA", uf: "SP" } },
  { name: "ECHAPORA", value: { cityName: "ECHAPORA", uf: "SP" } },
  { name: "MARACAI", value: { cityName: "MARACAI", uf: "SP" } },
  { name: "CRUZALIA", value: { cityName: "CRUZALIA", uf: "SP" } },
  { name: "CANDIDO MOTA", value: { cityName: "CANDIDO MOTA", uf: "SP" } },
  {
    name: "SANTO ANTONIO DO PARANAPANEMA",
    value: { cityName: "SANTO ANTONIO DO PARANAPANEMA", uf: "SP" },
  },
  { name: "IBIRAREMA", value: { cityName: "IBIRAREMA", uf: "SP" } },
  {
    name: "CAMPOS NOVOS PAULISTA",
    value: { cityName: "CAMPOS NOVOS PAULISTA", uf: "SP" },
  },
  { name: "ITAGUAI", value: { cityName: "ITAGUAI", uf: "RJ" } },
  { name: "ANGRA DOS REIS", value: { cityName: "ANGRA DOS REIS", uf: "RJ" } },
  { name: "PARATI", value: { cityName: "PARATI", uf: "RJ" } },
  { name: "SAO GONCALO", value: { cityName: "SAO GONCALO", uf: "RJ" } },
  { name: "ITABORAI", value: { cityName: "ITABORAI", uf: "RJ" } },
  { name: "MARICA", value: { cityName: "MARICA", uf: "RJ" } },
  {
    name: "SAO JOAO DE MERITI",
    value: { cityName: "SAO JOAO DE MERITI", uf: "RJ" },
  },
  {
    name: "SAO JOSE DO VALE DO RIO PRETO",
    value: { cityName: "SAO JOSE DO VALE DO RIO PRETO", uf: "RJ" },
  },
  { name: "AREAL", value: { cityName: "AREAL", uf: "RJ" } },
  { name: "PARAIBA DO SUL", value: { cityName: "PARAIBA DO SUL", uf: "RJ" } },
  { name: "MAGE", value: { cityName: "MAGE", uf: "RJ" } },
  { name: "GUAPIMIRIM", value: { cityName: "GUAPIMIRIM", uf: "RJ" } },
  { name: "TERESOPOLIS", value: { cityName: "TERESOPOLIS", uf: "RJ" } },
  { name: "BELFORD ROXO", value: { cityName: "BELFORD ROXO", uf: "RJ" } },
  { name: "JAPERI", value: { cityName: "JAPERI", uf: "RJ" } },
  { name: "QUEIMADOS", value: { cityName: "QUEIMADOS", uf: "RJ" } },
  { name: "PARACAMBI", value: { cityName: "PARACAMBI", uf: "RJ" } },
  { name: "MENDES", value: { cityName: "MENDES", uf: "RJ" } },
  { name: "MIGUEL PEREIRA", value: { cityName: "MIGUEL PEREIRA", uf: "RJ" } },
  { name: "BARRA DO PIRAI", value: { cityName: "BARRA DO PIRAI", uf: "RJ" } },
  { name: "PIRAI", value: { cityName: "PIRAI", uf: "RJ" } },
  { name: "RESENDE", value: { cityName: "RESENDE", uf: "RJ" } },
  { name: "ITATIAIA", value: { cityName: "ITATIAIA", uf: "RJ" } },
  { name: "VALENCA", value: { cityName: "VALENCA", uf: "RJ" } },
  { name: "RIO DAS FLORES", value: { cityName: "RIO DAS FLORES", uf: "RJ" } },
  { name: "VASSOURAS", value: { cityName: "VASSOURAS", uf: "RJ" } },
  {
    name: "SAO JOAO DA BARRA",
    value: { cityName: "SAO JOAO DA BARRA", uf: "RJ" },
  },
  { name: "ITAPERUNA", value: { cityName: "ITAPERUNA", uf: "RJ" } },
  {
    name: "BOM JESUS DO ITABAPOANA",
    value: { cityName: "BOM JESUS DO ITABAPOANA", uf: "RJ" },
  },
  { name: "NATIVIDADE", value: { cityName: "NATIVIDADE", uf: "RJ" } },
  { name: "PORCIUNCULA", value: { cityName: "PORCIUNCULA", uf: "RJ" } },
  { name: "SAO FIDELIS", value: { cityName: "SAO FIDELIS", uf: "RJ" } },
  { name: "CAMBUCI", value: { cityName: "CAMBUCI", uf: "RJ" } },
  {
    name: "SANTO ANTONIO DE PADUA",
    value: { cityName: "SANTO ANTONIO DE PADUA", uf: "RJ" },
  },
  { name: "CANTAGALO", value: { cityName: "CANTAGALO", uf: "RJ" } },
  { name: "CORDEIRO", value: { cityName: "CORDEIRO", uf: "RJ" } },
  {
    name: "SAO SEBASTIAO DO ALTO",
    value: { cityName: "SAO SEBASTIAO DO ALTO", uf: "RJ" },
  },
  { name: "SUMIDOURO", value: { cityName: "SUMIDOURO", uf: "RJ" } },
  { name: "CARMO", value: { cityName: "CARMO", uf: "RJ" } },
  { name: "BOM JARDIM", value: { cityName: "BOM JARDIM", uf: "RJ" } },
  {
    name: "CACHOEIRAS DE MACACU",
    value: { cityName: "CACHOEIRAS DE MACACU", uf: "RJ" },
  },
  { name: "QUISSAMA", value: { cityName: "QUISSAMA", uf: "RJ" } },
  {
    name: "CONCEICAO DE MACABU",
    value: { cityName: "CONCEICAO DE MACABU", uf: "RJ" },
  },
  {
    name: "SANTA MARIA MADALENA",
    value: { cityName: "SANTA MARIA MADALENA", uf: "RJ" },
  },
  { name: "RIO BONITO", value: { cityName: "RIO BONITO", uf: "RJ" } },
  { name: "SILVA JARDIM", value: { cityName: "SILVA JARDIM", uf: "RJ" } },
  {
    name: "CASIMIRO DE ABREU",
    value: { cityName: "CASIMIRO DE ABREU", uf: "RJ" },
  },
  { name: "RIO DAS OSTRAS", value: { cityName: "RIO DAS OSTRAS", uf: "RJ" } },
  { name: "CABO FRIO", value: { cityName: "CABO FRIO", uf: "RJ" } },
  { name: "ARRAIAL DO CABO", value: { cityName: "ARRAIAL DO CABO", uf: "RJ" } },
  {
    name: "SAO PEDRO DA ALDEIA",
    value: { cityName: "SAO PEDRO DA ALDEIA", uf: "RJ" },
  },
  { name: "ARARUAMA", value: { cityName: "ARARUAMA", uf: "RJ" } },
  { name: "SAQUAREMA", value: { cityName: "SAQUAREMA", uf: "RJ" } },
  { name: "VESPASIANO", value: { cityName: "VESPASIANO", uf: "MG" } },
  {
    name: "SAO JOSE DA LAPA",
    value: { cityName: "SAO JOSE DA LAPA", uf: "MG" },
  },
  {
    name: "TAQUARACU DE MINAS",
    value: { cityName: "TAQUARACU DE MINAS", uf: "MG" },
  },
  { name: "NOVA UNIAO", value: { cityName: "NOVA UNIAO", uf: "MG" } },
  { name: "MATHIAS LOBATO", value: { cityName: "MATHIAS LOBATO", uf: "MG" } },
  { name: "FREI INOCENCIO", value: { cityName: "FREI INOCENCIO", uf: "MG" } },
  { name: "NOVA MODICA", value: { cityName: "NOVA MODICA", uf: "MG" } },
  { name: "MARILAC", value: { cityName: "MARILAC", uf: "MG" } },
  { name: "CAPITAO ANDRADE", value: { cityName: "CAPITAO ANDRADE", uf: "MG" } },
  {
    name: "ENGENHEIRO CALDAS",
    value: { cityName: "ENGENHEIRO CALDAS", uf: "MG" },
  },
  {
    name: "FERNANDES TOURINHO",
    value: { cityName: "FERNANDES TOURINHO", uf: "MG" },
  },
  { name: "ALPERCATA", value: { cityName: "ALPERCATA", uf: "MG" } },
  { name: "TARUMIRIM", value: { cityName: "TARUMIRIM", uf: "MG" } },
  { name: "SOBRALIA", value: { cityName: "SOBRALIA", uf: "MG" } },
  {
    name: "SAO JOAO DO ORIENTE",
    value: { cityName: "SAO JOAO DO ORIENTE", uf: "MG" },
  },
  { name: "DOM CAVATI", value: { cityName: "DOM CAVATI", uf: "MG" } },
  { name: "ACUCENA", value: { cityName: "ACUCENA", uf: "MG" } },
  { name: "BELO ORIENTE", value: { cityName: "BELO ORIENTE", uf: "MG" } },
  { name: "MESQUITA", value: { cityName: "MESQUITA", uf: "MG" } },
  {
    name: "SANTANA DO PARAISO",
    value: { cityName: "SANTANA DO PARAISO", uf: "MG" },
  },
  { name: "JOANESIA", value: { cityName: "JOANESIA", uf: "MG" } },
  { name: "BRAUNAS", value: { cityName: "BRAUNAS", uf: "MG" } },
  { name: "MARLIERIA", value: { cityName: "MARLIERIA", uf: "MG" } },
  { name: "JAGUARACU", value: { cityName: "JAGUARACU", uf: "MG" } },
  { name: "AIMORES", value: { cityName: "AIMORES", uf: "MG" } },
  { name: "ITUETA", value: { cityName: "ITUETA", uf: "MG" } },
  {
    name: "CENTRAL DE MINAS",
    value: { cityName: "CENTRAL DE MINAS", uf: "MG" },
  },
  {
    name: "DIVINO DAS LARANJEIRAS",
    value: { cityName: "DIVINO DAS LARANJEIRAS", uf: "MG" },
  },
  {
    name: "ITABIRINHA DE MANTENA",
    value: { cityName: "ITABIRINHA DE MANTENA", uf: "MG" },
  },
  {
    name: "SANTA BARBARA DO LESTE",
    value: { cityName: "SANTA BARBARA DO LESTE", uf: "MG" },
  },
  { name: "UBAPORANGA", value: { cityName: "UBAPORANGA", uf: "MG" } },
  { name: "IPABA", value: { cityName: "IPABA", uf: "MG" } },
  { name: "ENTRE FOLHAS", value: { cityName: "ENTRE FOLHAS", uf: "MG" } },
  {
    name: "BOM JESUS DO GALHO",
    value: { cityName: "BOM JESUS DO GALHO", uf: "MG" },
  },
  { name: "CORREGO NOVO", value: { cityName: "CORREGO NOVO", uf: "MG" } },
  { name: "SERICITA", value: { cityName: "SERICITA", uf: "MG" } },
  { name: "RIO CASCA", value: { cityName: "RIO CASCA", uf: "MG" } },
  {
    name: "PIEDADE DE PONTE NOVA",
    value: { cityName: "PIEDADE DE PONTE NOVA", uf: "MG" },
  },
  {
    name: "SANTO ANTONIO DO GRAMA",
    value: { cityName: "SANTO ANTONIO DO GRAMA", uf: "MG" },
  },
  { name: "MARIANA", value: { cityName: "MARIANA", uf: "MG" } },
  { name: "AMPARO DA SERRA", value: { cityName: "AMPARO DA SERRA", uf: "MG" } },
  {
    name: "DIOGO DE VASCONCELOS",
    value: { cityName: "DIOGO DE VASCONCELOS", uf: "MG" },
  },
  { name: "ACAIACA", value: { cityName: "ACAIACA", uf: "MG" } },
  { name: "BARRA LONGA", value: { cityName: "BARRA LONGA", uf: "MG" } },
  { name: "ITABIRITO", value: { cityName: "ITABIRITO", uf: "MG" } },
  { name: "MOEDA", value: { cityName: "MOEDA", uf: "MG" } },
  {
    name: "ENTRE RIOS DE MINAS",
    value: { cityName: "ENTRE RIOS DE MINAS", uf: "MG" },
  },
  {
    name: "SAO BRAS DO SUACUI",
    value: { cityName: "SAO BRAS DO SUACUI", uf: "MG" },
  },
  { name: "JECEABA", value: { cityName: "JECEABA", uf: "MG" } },
  { name: "PEDRA DO INDAIA", value: { cityName: "PEDRA DO INDAIA", uf: "MG" } },
  {
    name: "SAO SEBASTIAO DO OESTE",
    value: { cityName: "SAO SEBASTIAO DO OESTE", uf: "MG" },
  },
  { name: "CARMO DO CAJURU", value: { cityName: "CARMO DO CAJURU", uf: "MG" } },
  { name: "ITAGUARA", value: { cityName: "ITAGUARA", uf: "MG" } },
  {
    name: "SAO GONCALO DO PARA",
    value: { cityName: "SAO GONCALO DO PARA", uf: "MG" },
  },
  { name: "PERDIGAO", value: { cityName: "PERDIGAO", uf: "MG" } },
  { name: "NOVA SERRANA", value: { cityName: "NOVA SERRANA", uf: "MG" } },
  { name: "RIO MANSO", value: { cityName: "RIO MANSO", uf: "MG" } },
  {
    name: "SANTO ANTONIO DA VARGEM ALEGRE",
    value: { cityName: "SANTO ANTONIO DA VARGEM ALEGRE", uf: "MG" },
  },
  {
    name: "PIEDADE DOS GERAIS",
    value: { cityName: "PIEDADE DOS GERAIS", uf: "MG" },
  },
  { name: "PIRACEMA", value: { cityName: "PIRACEMA", uf: "MG" } },
  { name: "BOM SUCESSO", value: { cityName: "BOM SUCESSO", uf: "MG" } },
  { name: "IBITURUNA", value: { cityName: "IBITURUNA", uf: "MG" } },
  { name: "ITAPECERICA", value: { cityName: "ITAPECERICA", uf: "MG" } },
  { name: "CAMACHO", value: { cityName: "CAMACHO", uf: "MG" } },
  {
    name: "SANTO ANTONIO DO MONTE",
    value: { cityName: "SANTO ANTONIO DO MONTE", uf: "MG" },
  },
  { name: "ARAUJOS", value: { cityName: "ARAUJOS", uf: "MG" } },
  { name: "MOEMA", value: { cityName: "MOEMA", uf: "MG" } },
  { name: "MARTINHO CAMPOS", value: { cityName: "MARTINHO CAMPOS", uf: "MG" } },
  {
    name: "ESTRELA DO INDAIA",
    value: { cityName: "ESTRELA DO INDAIA", uf: "MG" },
  },
  {
    name: "SERRA DA SAUDADE",
    value: { cityName: "SERRA DA SAUDADE", uf: "MG" },
  },
  { name: "BIQUINHAS", value: { cityName: "BIQUINHAS", uf: "MG" } },
  { name: "PAINEIRAS", value: { cityName: "PAINEIRAS", uf: "MG" } },
  { name: "CEDRO DO ABAETE", value: { cityName: "CEDRO DO ABAETE", uf: "MG" } },
  { name: "QUARTEL GERAL", value: { cityName: "QUARTEL GERAL", uf: "MG" } },
  {
    name: "MORADA NOVA DE MINAS",
    value: { cityName: "MORADA NOVA DE MINAS", uf: "MG" },
  },
  { name: "POMPEU", value: { cityName: "POMPEU", uf: "MG" } },
  {
    name: "ONCA DE PITANGUI",
    value: { cityName: "ONCA DE PITANGUI", uf: "MG" },
  },
  {
    name: "LEANDRO FERREIRA",
    value: { cityName: "LEANDRO FERREIRA", uf: "MG" },
  },
  { name: "IGARATINGA", value: { cityName: "IGARATINGA", uf: "MG" } },
  { name: "FLORESTAL", value: { cityName: "FLORESTAL", uf: "MG" } },
  {
    name: "SAO JOSE DA VARGINHA",
    value: { cityName: "SAO JOSE DA VARGINHA", uf: "MG" },
  },
  { name: "MARAVILHAS", value: { cityName: "MARAVILHAS", uf: "MG" } },
  {
    name: "CONCEICAO DO PARA",
    value: { cityName: "CONCEICAO DO PARA", uf: "MG" },
  },
  { name: "PAPAGAIOS", value: { cityName: "PAPAGAIOS", uf: "MG" } },
  { name: "ITAUNA", value: { cityName: "ITAUNA", uf: "MG" } },
  { name: "ITATIAIUCU", value: { cityName: "ITATIAIUCU", uf: "MG" } },
  { name: "JEQUITIBA", value: { cityName: "JEQUITIBA", uf: "MG" } },
  { name: "FUNILANDIA", value: { cityName: "FUNILANDIA", uf: "MG" } },
  { name: "INHAUMA", value: { cityName: "INHAUMA", uf: "MG" } },
  {
    name: "PRUDENTE DE MORAIS",
    value: { cityName: "PRUDENTE DE MORAIS", uf: "MG" },
  },
  { name: "CAPIM BRANCO", value: { cityName: "CAPIM BRANCO", uf: "MG" } },
  { name: "ESMERALDAS", value: { cityName: "ESMERALDAS", uf: "MG" } },
  { name: "PARAOPEBA", value: { cityName: "PARAOPEBA", uf: "MG" } },
  { name: "INIMUTABA", value: { cityName: "INIMUTABA", uf: "MG" } },
  {
    name: "PRESIDENTE JUSCELINO",
    value: { cityName: "PRESIDENTE JUSCELINO", uf: "MG" },
  },
  { name: "MORRO DA GARCA", value: { cityName: "MORRO DA GARCA", uf: "MG" } },
  { name: "PASSABEM", value: { cityName: "PASSABEM", uf: "MG" } },
  {
    name: "SAO SEBASTIAO DO RIO PRETO",
    value: { cityName: "SAO SEBASTIAO DO RIO PRETO", uf: "MG" },
  },
  {
    name: "ITAMBE DO MATO DENTRO",
    value: { cityName: "ITAMBE DO MATO DENTRO", uf: "MG" },
  },
  {
    name: "SANTANA DO RIACHO",
    value: { cityName: "SANTANA DO RIACHO", uf: "MG" },
  },
  {
    name: "CONGONHAS DO NORTE",
    value: { cityName: "CONGONHAS DO NORTE", uf: "MG" },
  },
  { name: "MORRO DO PILAR", value: { cityName: "MORRO DO PILAR", uf: "MG" } },
  {
    name: "SANTO ANTONIO DO RIO ABAIXO",
    value: { cityName: "SANTO ANTONIO DO RIO ABAIXO", uf: "MG" },
  },
  {
    name: "DORES DE GUANHAES",
    value: { cityName: "DORES DE GUANHAES", uf: "MG" },
  },
  {
    name: "BOM JESUS DO AMPARO",
    value: { cityName: "BOM JESUS DO AMPARO", uf: "MG" },
  },
  {
    name: "SANTA MARIA DE ITABIRA",
    value: { cityName: "SANTA MARIA DE ITABIRA", uf: "MG" },
  },
  { name: "DIONISIO", value: { cityName: "DIONISIO", uf: "MG" } },
  {
    name: "SAO JOSE DO GOIABAL",
    value: { cityName: "SAO JOSE DO GOIABAL", uf: "MG" },
  },
  {
    name: "SAO DOMINGOS DO PRATA",
    value: { cityName: "SAO DOMINGOS DO PRATA", uf: "MG" },
  },
  { name: "CHACARA", value: { cityName: "CHACARA", uf: "MG" } },
  { name: "MATIAS BARBOSA", value: { cityName: "MATIAS BARBOSA", uf: "MG" } },
  { name: "SIMAO PEREIRA", value: { cityName: "SIMAO PEREIRA", uf: "MG" } },
  { name: "RIO PRETO", value: { cityName: "RIO PRETO", uf: "MG" } },
  {
    name: "SANTA RITA DE JACUTINGA",
    value: { cityName: "SANTA RITA DE JACUTINGA", uf: "MG" },
  },
  { name: "OLARIA", value: { cityName: "OLARIA", uf: "MG" } },
  { name: "RIO NOVO", value: { cityName: "RIO NOVO", uf: "MG" } },
  { name: "CORONEL PACHECO", value: { cityName: "CORONEL PACHECO", uf: "MG" } },
  { name: "PIAU", value: { cityName: "PIAU", uf: "MG" } },
  { name: "GUARANI", value: { cityName: "GUARANI", uf: "MG" } },
  { name: "TABULEIRO", value: { cityName: "TABULEIRO", uf: "MG" } },
  { name: "RIO POMBA", value: { cityName: "RIO POMBA", uf: "MG" } },
  { name: "SILVEIRANIA", value: { cityName: "SILVEIRANIA", uf: "MG" } },
  { name: "MERCES", value: { cityName: "MERCES", uf: "MG" } },
  { name: "BARROSO", value: { cityName: "BARROSO", uf: "MG" } },
  { name: "IBERTIOGA", value: { cityName: "IBERTIOGA", uf: "MG" } },
  {
    name: "PIEDADE DO RIO GRANDE",
    value: { cityName: "PIEDADE DO RIO GRANDE", uf: "MG" },
  },
  { name: "BIAS FORTES", value: { cityName: "BIAS FORTES", uf: "MG" } },
  { name: "OLIVEIRA FORTES", value: { cityName: "OLIVEIRA FORTES", uf: "MG" } },
  { name: "ARACITABA", value: { cityName: "ARACITABA", uf: "MG" } },
  { name: "CIPOTANEA", value: { cityName: "CIPOTANEA", uf: "MG" } },
  { name: "RESSAQUINHA", value: { cityName: "RESSAQUINHA", uf: "MG" } },
  {
    name: "ALFREDO VASCONCELOS",
    value: { cityName: "ALFREDO VASCONCELOS", uf: "MG" },
  },
  {
    name: "SENHORA DOS REMEDIOS",
    value: { cityName: "SENHORA DOS REMEDIOS", uf: "MG" },
  },
  { name: "CAPELA NOVA", value: { cityName: "CAPELA NOVA", uf: "MG" } },
  { name: "TIRADENTES", value: { cityName: "TIRADENTES", uf: "MG" } },
  {
    name: "CORONEL XAVIER CHAVES",
    value: { cityName: "CORONEL XAVIER CHAVES", uf: "MG" },
  },
  { name: "RITAPOLIS", value: { cityName: "RITAPOLIS", uf: "MG" } },
  { name: "RESENDE COSTA", value: { cityName: "RESENDE COSTA", uf: "MG" } },
  { name: "LAGOA DOURADA", value: { cityName: "LAGOA DOURADA", uf: "MG" } },
  { name: "NAZARENO", value: { cityName: "NAZARENO", uf: "MG" } },
  { name: "CARRANCAS", value: { cityName: "CARRANCAS", uf: "MG" } },
  { name: "ITUTINGA", value: { cityName: "ITUTINGA", uf: "MG" } },
  { name: "OURO BRANCO", value: { cityName: "OURO BRANCO", uf: "MG" } },
  { name: "QUELUZITA", value: { cityName: "QUELUZITA", uf: "MG" } },
  { name: "CRISTIANO OTONI", value: { cityName: "CRISTIANO OTONI", uf: "MG" } },
  {
    name: "CATAS ALTAS DA NORUEGA",
    value: { cityName: "CATAS ALTAS DA NORUEGA", uf: "MG" },
  },
  { name: "LAMIM", value: { cityName: "LAMIM", uf: "MG" } },
  { name: "RIO ESPERA", value: { cityName: "RIO ESPERA", uf: "MG" } },
  { name: "PIRANGA", value: { cityName: "PIRANGA", uf: "MG" } },
  { name: "GUIRICEMA", value: { cityName: "GUIRICEMA", uf: "MG" } },
  { name: "SAO GERALDO", value: { cityName: "SAO GERALDO", uf: "MG" } },
  { name: "SENADOR FIRMINO", value: { cityName: "SENADOR FIRMINO", uf: "MG" } },
  { name: "BRAS PIRES", value: { cityName: "BRAS PIRES", uf: "MG" } },
  { name: "PAULA CANDIDO", value: { cityName: "PAULA CANDIDO", uf: "MG" } },
  { name: "DIVINESIA", value: { cityName: "DIVINESIA", uf: "MG" } },
  { name: "COIMBRA", value: { cityName: "COIMBRA", uf: "MG" } },
  { name: "CAJURI", value: { cityName: "CAJURI", uf: "MG" } },
  { name: "PORTO FIRME", value: { cityName: "PORTO FIRME", uf: "MG" } },
  { name: "TEIXEIRAS", value: { cityName: "TEIXEIRAS", uf: "MG" } },
  { name: "PEDRA DO ANTA", value: { cityName: "PEDRA DO ANTA", uf: "MG" } },
  {
    name: "SAO MIGUEL DO ANTA",
    value: { cityName: "SAO MIGUEL DO ANTA", uf: "MG" },
  },
  { name: "CANAA", value: { cityName: "CANAA", uf: "MG" } },
  { name: "ARAPONGA", value: { cityName: "ARAPONGA", uf: "MG" } },
  { name: "BICAS", value: { cityName: "BICAS", uf: "MG" } },
  {
    name: "ROCHEDO DE MINAS",
    value: { cityName: "ROCHEDO DE MINAS", uf: "MG" },
  },
  { name: "GUARARA", value: { cityName: "GUARARA", uf: "MG" } },
  { name: "MARIPA DE MINAS", value: { cityName: "MARIPA DE MINAS", uf: "MG" } },
  { name: "PEQUERI", value: { cityName: "PEQUERI", uf: "MG" } },
  { name: "CHIADOR", value: { cityName: "CHIADOR", uf: "MG" } },
  { name: "MAR DE ESPANHA", value: { cityName: "MAR DE ESPANHA", uf: "MG" } },
  {
    name: "SAO JOAO NEPOMUCENO",
    value: { cityName: "SAO JOAO NEPOMUCENO", uf: "MG" },
  },
  { name: "DESCOBERTO", value: { cityName: "DESCOBERTO", uf: "MG" } },
  { name: "LEOPOLDINA", value: { cityName: "LEOPOLDINA", uf: "MG" } },
  { name: "ARGIRITA", value: { cityName: "ARGIRITA", uf: "MG" } },
  { name: "VOLTA GRANDE", value: { cityName: "VOLTA GRANDE", uf: "MG" } },
  { name: "RECREIO", value: { cityName: "RECREIO", uf: "MG" } },
  { name: "PALMA", value: { cityName: "PALMA", uf: "MG" } },
  { name: "CATAGUASES", value: { cityName: "CATAGUASES", uf: "MG" } },
  {
    name: "ITAMARATI DE MINAS",
    value: { cityName: "ITAMARATI DE MINAS", uf: "MG" },
  },
  { name: "ASTOLFO DUTRA", value: { cityName: "ASTOLFO DUTRA", uf: "MG" } },
  { name: "DONA EUZEBIA", value: { cityName: "DONA EUZEBIA", uf: "MG" } },
  { name: "MIRAI", value: { cityName: "MIRAI", uf: "MG" } },
  {
    name: "SANTANA DE CATAGUASES",
    value: { cityName: "SANTANA DE CATAGUASES", uf: "MG" },
  },
  {
    name: "SAO FRANCISCO DO GLORIA",
    value: { cityName: "SAO FRANCISCO DO GLORIA", uf: "MG" },
  },
  { name: "DIVINO", value: { cityName: "DIVINO", uf: "MG" } },
  { name: "FARIA LEMOS", value: { cityName: "FARIA LEMOS", uf: "MG" } },
  { name: "TOMBOS", value: { cityName: "TOMBOS", uf: "MG" } },
  { name: "PEDRA DOURADA", value: { cityName: "PEDRA DOURADA", uf: "MG" } },
  {
    name: "ANTONIO PRADO DE MINAS",
    value: { cityName: "ANTONIO PRADO DE MINAS", uf: "MG" },
  },
  { name: "EUGENOPOLIS", value: { cityName: "EUGENOPOLIS", uf: "MG" } },
  {
    name: "PATROCINIO DO MURIAE",
    value: { cityName: "PATROCINIO DO MURIAE", uf: "MG" },
  },
  { name: "MIRADOURO", value: { cityName: "MIRADOURO", uf: "MG" } },
  {
    name: "SAO JOAO DO MANHUACU",
    value: { cityName: "SAO JOAO DO MANHUACU", uf: "MG" },
  },
  { name: "SANTA MARGARIDA", value: { cityName: "SANTA MARGARIDA", uf: "MG" } },
  { name: "CAPUTIRA", value: { cityName: "CAPUTIRA", uf: "MG" } },
  { name: "SIMONESIA", value: { cityName: "SIMONESIA", uf: "MG" } },
  {
    name: "SANTANA DO MANHUACU",
    value: { cityName: "SANTANA DO MANHUACU", uf: "MG" },
  },
  { name: "IPANEMA", value: { cityName: "IPANEMA", uf: "MG" } },
  {
    name: "CONCEICAO DE IPANEMA",
    value: { cityName: "CONCEICAO DE IPANEMA", uf: "MG" },
  },
  { name: "POCRANE", value: { cityName: "POCRANE", uf: "MG" } },
  { name: "DURANDE", value: { cityName: "DURANDE", uf: "MG" } },
  { name: "ALTO JEQUITIBA", value: { cityName: "ALTO JEQUITIBA", uf: "MG" } },
  { name: "LAJINHA", value: { cityName: "LAJINHA", uf: "MG" } },
  { name: "CHALE", value: { cityName: "CHALE", uf: "MG" } },
  {
    name: "SAO JOSE DO MANTIMENTO",
    value: { cityName: "SAO JOSE DO MANTIMENTO", uf: "MG" },
  },
  { name: "ELOI MENDES", value: { cityName: "ELOI MENDES", uf: "MG" } },
  { name: "MONTE BELO", value: { cityName: "MONTE BELO", uf: "MG" } },
  { name: "DIVISA NOVA", value: { cityName: "DIVISA NOVA", uf: "MG" } },
  { name: "SERRANIA", value: { cityName: "SERRANIA", uf: "MG" } },
  { name: "FAMA", value: { cityName: "FAMA", uf: "MG" } },
  { name: "AREADO", value: { cityName: "AREADO", uf: "MG" } },
  { name: "ALTEROSA", value: { cityName: "ALTEROSA", uf: "MG" } },
  { name: "CAMPOS GERAIS", value: { cityName: "CAMPOS GERAIS", uf: "MG" } },
  { name: "CAMPO DO MEIO", value: { cityName: "CAMPO DO MEIO", uf: "MG" } },
  { name: "ILICINEA", value: { cityName: "ILICINEA", uf: "MG" } },
  { name: "GUAPE", value: { cityName: "GUAPE", uf: "MG" } },
  { name: "TRES PONTAS", value: { cityName: "TRES PONTAS", uf: "MG" } },
  {
    name: "SANTANA DA VARGEM",
    value: { cityName: "SANTANA DA VARGEM", uf: "MG" },
  },
  { name: "LAVRAS", value: { cityName: "LAVRAS", uf: "MG" } },
  { name: "INGAI", value: { cityName: "INGAI", uf: "MG" } },
  {
    name: "CARMO DA CACHOEIRA",
    value: { cityName: "CARMO DA CACHOEIRA", uf: "MG" },
  },
  { name: "COQUEIRAL", value: { cityName: "COQUEIRAL", uf: "MG" } },
  { name: "NEPOMUCENO", value: { cityName: "NEPOMUCENO", uf: "MG" } },
  { name: "PERDOES", value: { cityName: "PERDOES", uf: "MG" } },
  {
    name: "SANTO ANTONIO DO AMPARO",
    value: { cityName: "SANTO ANTONIO DO AMPARO", uf: "MG" },
  },
  { name: "CANA VERDE", value: { cityName: "CANA VERDE", uf: "MG" } },
  { name: "CAMPO BELO", value: { cityName: "CAMPO BELO", uf: "MG" } },
  { name: "CRISTAIS", value: { cityName: "CRISTAIS", uf: "MG" } },
  {
    name: "SANTANA DO JACARE",
    value: { cityName: "SANTANA DO JACARE", uf: "MG" },
  },
  { name: "CANDEIAS", value: { cityName: "CANDEIAS", uf: "MG" } },
  { name: "JAPARAIBA", value: { cityName: "JAPARAIBA", uf: "MG" } },
  { name: "PAINS", value: { cityName: "PAINS", uf: "MG" } },
  { name: "PIMENTA", value: { cityName: "PIMENTA", uf: "MG" } },
  { name: "ANDRELANDIA", value: { cityName: "ANDRELANDIA", uf: "MG" } },
  {
    name: "BOM JARDIM DE MINAS",
    value: { cityName: "BOM JARDIM DE MINAS", uf: "MG" },
  },
  { name: "PASSA VINTE", value: { cityName: "PASSA VINTE", uf: "MG" } },
  { name: "LIBERDADE", value: { cityName: "LIBERDADE", uf: "MG" } },
  {
    name: "SAO VICENTE DE MINAS",
    value: { cityName: "SAO VICENTE DE MINAS", uf: "MG" },
  },
  { name: "CAMPANHA", value: { cityName: "CAMPANHA", uf: "MG" } },
  { name: "MONSENHOR PAULO", value: { cityName: "MONSENHOR PAULO", uf: "MG" } },
  { name: "SAO BENTO ABADE", value: { cityName: "SAO BENTO ABADE", uf: "MG" } },
  {
    name: "SAO TOME DAS LETRAS",
    value: { cityName: "SAO TOME DAS LETRAS", uf: "MG" },
  },
  { name: "CAMBUQUIRA", value: { cityName: "CAMBUQUIRA", uf: "MG" } },
  { name: "CAXAMBU", value: { cityName: "CAXAMBU", uf: "MG" } },
  { name: "BAEPENDI", value: { cityName: "BAEPENDI", uf: "MG" } },
  { name: "CRUZILIA", value: { cityName: "CRUZILIA", uf: "MG" } },
  { name: "MINDURI", value: { cityName: "MINDURI", uf: "MG" } },
  { name: "AIURUOCA", value: { cityName: "AIURUOCA", uf: "MG" } },
  { name: "SERRANOS", value: { cityName: "SERRANOS", uf: "MG" } },
  { name: "ALAGOA", value: { cityName: "ALAGOA", uf: "MG" } },
  { name: "PASSA QUATRO", value: { cityName: "PASSA QUATRO", uf: "MG" } },
  { name: "ITANHANDU", value: { cityName: "ITANHANDU", uf: "MG" } },
  { name: "VIRGINIA", value: { cityName: "VIRGINIA", uf: "MG" } },
  { name: "ITAMONTE", value: { cityName: "ITAMONTE", uf: "MG" } },
  { name: "POUSO ALTO", value: { cityName: "POUSO ALTO", uf: "MG" } },
  { name: "SAO LOURENCO", value: { cityName: "SAO LOURENCO", uf: "MG" } },
  { name: "CARMO DE MINAS", value: { cityName: "CARMO DE MINAS", uf: "MG" } },
  { name: "DOM VICOSO", value: { cityName: "DOM VICOSO", uf: "MG" } },
  { name: "CRISTINA", value: { cityName: "CRISTINA", uf: "MG" } },
  {
    name: "SOLEDADE DE MINAS",
    value: { cityName: "SOLEDADE DE MINAS", uf: "MG" },
  },
  { name: "LAMBARI", value: { cityName: "LAMBARI", uf: "MG" } },
  { name: "JESUANIA", value: { cityName: "JESUANIA", uf: "MG" } },
  { name: "OLIMPIO NORONHA", value: { cityName: "OLIMPIO NORONHA", uf: "MG" } },
  {
    name: "SAO GONCALO DO SAPUCAI",
    value: { cityName: "SAO GONCALO DO SAPUCAI", uf: "MG" },
  },
  { name: "TURVOLANDIA", value: { cityName: "TURVOLANDIA", uf: "MG" } },
  { name: "CORDISLANDIA", value: { cityName: "CORDISLANDIA", uf: "MG" } },
  { name: "PIRANGUINHO", value: { cityName: "PIRANGUINHO", uf: "MG" } },
  {
    name: "SAO JOSE DO ALEGRE",
    value: { cityName: "SAO JOSE DO ALEGRE", uf: "MG" },
  },
  { name: "WENCESLAU BRAZ", value: { cityName: "WENCESLAU BRAZ", uf: "MG" } },
  { name: "DELFIM MOREIRA", value: { cityName: "DELFIM MOREIRA", uf: "MG" } },
  { name: "MARMELOPOLIS", value: { cityName: "MARMELOPOLIS", uf: "MG" } },
  { name: "MARIA DA FE", value: { cityName: "MARIA DA FE", uf: "MG" } },
  { name: "PEDRALVA", value: { cityName: "PEDRALVA", uf: "MG" } },
  { name: "BRASOPOLIS", value: { cityName: "BRASOPOLIS", uf: "MG" } },
  {
    name: "SANTA RITA DO SAPUCAI",
    value: { cityName: "SANTA RITA DO SAPUCAI", uf: "MG" },
  },
  {
    name: "CACHOEIRA DE MINAS",
    value: { cityName: "CACHOEIRA DE MINAS", uf: "MG" },
  },
  { name: "BORDA DA MATA", value: { cityName: "BORDA DA MATA", uf: "MG" } },
  { name: "CONGONHAL", value: { cityName: "CONGONHAL", uf: "MG" } },
  { name: "IPUIUNA", value: { cityName: "IPUIUNA", uf: "MG" } },
  { name: "SILVIANOPOLIS", value: { cityName: "SILVIANOPOLIS", uf: "MG" } },
  { name: "CAREACU", value: { cityName: "CAREACU", uf: "MG" } },
  {
    name: "ESPIRITO SANTO DO DOURADO",
    value: { cityName: "ESPIRITO SANTO DO DOURADO", uf: "MG" },
  },
  { name: "OURO FINO", value: { cityName: "OURO FINO", uf: "MG" } },
  { name: "BUENO BRANDAO", value: { cityName: "BUENO BRANDAO", uf: "MG" } },
  { name: "MONTE SIAO", value: { cityName: "MONTE SIAO", uf: "MG" } },
  { name: "CAMBUI", value: { cityName: "CAMBUI", uf: "MG" } },
  {
    name: "CORREGO DO BOM JESUS",
    value: { cityName: "CORREGO DO BOM JESUS", uf: "MG" },
  },
  { name: "MUNHOZ", value: { cityName: "MUNHOZ", uf: "MG" } },
  { name: "EXTREMA", value: { cityName: "EXTREMA", uf: "MG" } },
  { name: "CAMANDUCAIA", value: { cityName: "CAMANDUCAIA", uf: "MG" } },
  { name: "PARAISOPOLIS", value: { cityName: "PARAISOPOLIS", uf: "MG" } },
  { name: "CONSOLACAO", value: { cityName: "CONSOLACAO", uf: "MG" } },
  { name: "GONCALVES", value: { cityName: "GONCALVES", uf: "MG" } },
  { name: "POCOS DE CALDAS", value: { cityName: "POCOS DE CALDAS", uf: "MG" } },
  { name: "BOTELHOS", value: { cityName: "BOTELHOS", uf: "MG" } },
  { name: "CAMPESTRE", value: { cityName: "CAMPESTRE", uf: "MG" } },
  { name: "MACHADO", value: { cityName: "MACHADO", uf: "MG" } },
  { name: "POCO FUNDO", value: { cityName: "POCO FUNDO", uf: "MG" } },
  { name: "CARVALHOPOLIS", value: { cityName: "CARVALHOPOLIS", uf: "MG" } },
  { name: "CALDAS", value: { cityName: "CALDAS", uf: "MG" } },
  {
    name: "IBITIURA DE MINAS",
    value: { cityName: "IBITIURA DE MINAS", uf: "MG" },
  },
  { name: "ANDRADAS", value: { cityName: "ANDRADAS", uf: "MG" } },
  { name: "JURUAIA", value: { cityName: "JURUAIA", uf: "MG" } },
  { name: "GUARANESIA", value: { cityName: "GUARANESIA", uf: "MG" } },
  { name: "ARCEBURGO", value: { cityName: "ARCEBURGO", uf: "MG" } },
  {
    name: "BOM JESUS DA PENHA",
    value: { cityName: "BOM JESUS DA PENHA", uf: "MG" },
  },
  {
    name: "SAO PEDRO DA UNIAO",
    value: { cityName: "SAO PEDRO DA UNIAO", uf: "MG" },
  },
  { name: "NOVA RESENDE", value: { cityName: "NOVA RESENDE", uf: "MG" } },
  { name: "CABO VERDE", value: { cityName: "CABO VERDE", uf: "MG" } },
  { name: "MUZAMBINHO", value: { cityName: "MUZAMBINHO", uf: "MG" } },
  { name: "PASSOS", value: { cityName: "PASSOS", uf: "MG" } },
  {
    name: "FORTALEZA DE MINAS",
    value: { cityName: "FORTALEZA DE MINAS", uf: "MG" },
  },
  { name: "DELFINOPOLIS", value: { cityName: "DELFINOPOLIS", uf: "MG" } },
  { name: "DORESOPOLIS", value: { cityName: "DORESOPOLIS", uf: "MG" } },
  { name: "CAPITOLIO", value: { cityName: "CAPITOLIO", uf: "MG" } },
  { name: "ALPINOPOLIS", value: { cityName: "ALPINOPOLIS", uf: "MG" } },
  {
    name: "SAO SEBASTIAO DO PARAISO",
    value: { cityName: "SAO SEBASTIAO DO PARAISO", uf: "MG" },
  },
  { name: "ITAMOGI", value: { cityName: "ITAMOGI", uf: "MG" } },
  {
    name: "MONTE SANTO DE MINAS",
    value: { cityName: "MONTE SANTO DE MINAS", uf: "MG" },
  },
  {
    name: "SAO TOMAS DE AQUINO",
    value: { cityName: "SAO TOMAS DE AQUINO", uf: "MG" },
  },
  { name: "PRATAPOLIS", value: { cityName: "PRATAPOLIS", uf: "MG" } },
  { name: "CASSIA", value: { cityName: "CASSIA", uf: "MG" } },
  { name: "IBIRACI", value: { cityName: "IBIRACI", uf: "MG" } },
  { name: "CAPETINGA", value: { cityName: "CAPETINGA", uf: "MG" } },
  { name: "CLARAVAL", value: { cityName: "CLARAVAL", uf: "MG" } },
  {
    name: "CONCEICAO DAS ALAGOAS",
    value: { cityName: "CONCEICAO DAS ALAGOAS", uf: "MG" },
  },
  { name: "CAMPO FLORIDO", value: { cityName: "CAMPO FLORIDO", uf: "MG" } },
  { name: "PRATA", value: { cityName: "PRATA", uf: "MG" } },
  { name: "VERISSIMO", value: { cityName: "VERISSIMO", uf: "MG" } },
  { name: "PERDIZES", value: { cityName: "PERDIZES", uf: "MG" } },
  { name: "SANTA JULIANA", value: { cityName: "SANTA JULIANA", uf: "MG" } },
  { name: "PEDRINOPOLIS", value: { cityName: "PEDRINOPOLIS", uf: "MG" } },
  { name: "TAPIRA", value: { cityName: "TAPIRA", uf: "MG" } },
  { name: "SACRAMENTO", value: { cityName: "SACRAMENTO", uf: "MG" } },
  { name: "FRUTAL", value: { cityName: "FRUTAL", uf: "MG" } },
  { name: "PIRAJUBA", value: { cityName: "PIRAJUBA", uf: "MG" } },
  { name: "FRONTEIRA", value: { cityName: "FRONTEIRA", uf: "MG" } },
  {
    name: "COMENDADOR GOMES",
    value: { cityName: "COMENDADOR GOMES", uf: "MG" },
  },
  {
    name: "SAO FRANCISCO DE SALES",
    value: { cityName: "SAO FRANCISCO DE SALES", uf: "MG" },
  },
  { name: "CAMPINA VERDE", value: { cityName: "CAMPINA VERDE", uf: "MG" } },
  { name: "ITURAMA", value: { cityName: "ITURAMA", uf: "MG" } },
  { name: "CARNEIRINHO", value: { cityName: "CARNEIRINHO", uf: "MG" } },
  { name: "ITUIUTABA", value: { cityName: "ITUIUTABA", uf: "MG" } },
  { name: "GURINHATA", value: { cityName: "GURINHATA", uf: "MG" } },
  { name: "SANTA VITORIA", value: { cityName: "SANTA VITORIA", uf: "MG" } },
  { name: "IPIACU", value: { cityName: "IPIACU", uf: "MG" } },
  { name: "CAPINOPOLIS", value: { cityName: "CAPINOPOLIS", uf: "MG" } },
  { name: "CANAPOLIS", value: { cityName: "CANAPOLIS", uf: "MG" } },
  { name: "CENTRALINA", value: { cityName: "CENTRALINA", uf: "MG" } },
  { name: "UBERLANDIA", value: { cityName: "UBERLANDIA", uf: "MG" } },
  {
    name: "MONTE ALEGRE DE MINAS",
    value: { cityName: "MONTE ALEGRE DE MINAS", uf: "MG" },
  },
  { name: "TUPACIGUARA", value: { cityName: "TUPACIGUARA", uf: "MG" } },
  { name: "INDIANOPOLIS", value: { cityName: "INDIANOPOLIS", uf: "MG" } },
  { name: "MONTE CARMELO", value: { cityName: "MONTE CARMELO", uf: "MG" } },
  { name: "IRAI DE MINAS", value: { cityName: "IRAI DE MINAS", uf: "MG" } },
  { name: "ROMARIA", value: { cityName: "ROMARIA", uf: "MG" } },
  { name: "ESTRELA DO SUL", value: { cityName: "ESTRELA DO SUL", uf: "MG" } },
  {
    name: "ABADIA DOS DOURADOS",
    value: { cityName: "ABADIA DOS DOURADOS", uf: "MG" },
  },
  { name: "COROMANDEL", value: { cityName: "COROMANDEL", uf: "MG" } },
  { name: "PARACATU", value: { cityName: "PARACATU", uf: "MG" } },
  { name: "UNAI", value: { cityName: "UNAI", uf: "MG" } },
  { name: "BURITIS", value: { cityName: "BURITIS", uf: "MG" } },
  { name: "PATOS DE MINAS", value: { cityName: "PATOS DE MINAS", uf: "MG" } },
  { name: "LAGOA FORMOSA", value: { cityName: "LAGOA FORMOSA", uf: "MG" } },
  { name: "GUIMARANIA", value: { cityName: "GUIMARANIA", uf: "MG" } },
  {
    name: "CRUZEIRO DA FORTALEZA",
    value: { cityName: "CRUZEIRO DA FORTALEZA", uf: "MG" },
  },
  { name: "PATROCINIO", value: { cityName: "PATROCINIO", uf: "MG" } },
  {
    name: "SERRA DO SALITRE",
    value: { cityName: "SERRA DO SALITRE", uf: "MG" },
  },
  { name: "LAGOA GRANDE", value: { cityName: "LAGOA GRANDE", uf: "MG" } },
  { name: "JOAO PINHEIRO", value: { cityName: "JOAO PINHEIRO", uf: "MG" } },
  { name: "VAZANTE", value: { cityName: "VAZANTE", uf: "MG" } },
  { name: "LAGAMAR", value: { cityName: "LAGAMAR", uf: "MG" } },
  {
    name: "SAO GONCALO DO ABAETE",
    value: { cityName: "SAO GONCALO DO ABAETE", uf: "MG" },
  },
  { name: "RIO PARANAIBA", value: { cityName: "RIO PARANAIBA", uf: "MG" } },
  {
    name: "CARMO DO PARANAIBA",
    value: { cityName: "CARMO DO PARANAIBA", uf: "MG" },
  },
  { name: "MATUTINA", value: { cityName: "MATUTINA", uf: "MG" } },
  { name: "TIROS", value: { cityName: "TIROS", uf: "MG" } },
  { name: "BAMBUI", value: { cityName: "BAMBUI", uf: "MG" } },
  { name: "IBIA", value: { cityName: "IBIA", uf: "MG" } },
  { name: "PRATINHA", value: { cityName: "PRATINHA", uf: "MG" } },
  { name: "CAMPOS ALTOS", value: { cityName: "CAMPOS ALTOS", uf: "MG" } },
  { name: "CORREGO DANTA", value: { cityName: "CORREGO DANTA", uf: "MG" } },
  { name: "GOUVEA", value: { cityName: "GOUVEA", uf: "MG" } },
  { name: "DATAS", value: { cityName: "DATAS", uf: "MG" } },
  {
    name: "PRESIDENTE KUBITSCHEK",
    value: { cityName: "PRESIDENTE KUBITSCHEK", uf: "MG" },
  },
  {
    name: "ALVORADA DE MINAS",
    value: { cityName: "ALVORADA DE MINAS", uf: "" },
  },
  {
    name: "SAO GONCALO DO RIO DAS PEDRAS",
    value: { cityName: "SAO GONCALO DO RIO DAS PEDRAS", uf: "MG" },
  },
  {
    name: "SANTO ANTONIO DO ITAMBE",
    value: { cityName: "SANTO ANTONIO DO ITAMBE", uf: "MG" },
  },
  {
    name: "SERRA AZUL DE MINAS",
    value: { cityName: "SERRA AZUL DE MINAS", uf: "MG" },
  },
  { name: "RIO VERMELHO", value: { cityName: "RIO VERMELHO", uf: "MG" } },
  {
    name: "FELICIO DOS SANTOS",
    value: { cityName: "FELICIO DOS SANTOS", uf: "MG" },
  },
  {
    name: "SAO GONCALO DO RIO PRETO",
    value: { cityName: "SAO GONCALO DO RIO PRETO", uf: "MG" },
  },
  {
    name: "COUTO DE MAGALHAES DE MINAS",
    value: { cityName: "COUTO DE MAGALHAES DE MINAS", uf: "MG" },
  },
  {
    name: "SENADOR MODESTINO GONCALVES",
    value: { cityName: "SENADOR MODESTINO GONCALVES", uf: "MG" },
  },
  { name: "SANTO HIPOLITO", value: { cityName: "SANTO HIPOLITO", uf: "MG" } },
  { name: "MONJOLOS", value: { cityName: "MONJOLOS", uf: "MG" } },
  { name: "AUGUSTO DE LIMA", value: { cityName: "AUGUSTO DE LIMA", uf: "MG" } },
  { name: "JOAQUIM FELICIO", value: { cityName: "JOAQUIM FELICIO", uf: "MG" } },
  { name: "LASSANCE", value: { cityName: "LASSANCE", uf: "MG" } },
  { name: "VARZEA DA PALMA", value: { cityName: "VARZEA DA PALMA", uf: "MG" } },
  { name: "PIRAPORA", value: { cityName: "PIRAPORA", uf: "MG" } },
  { name: "BURITIZEIRO", value: { cityName: "BURITIZEIRO", uf: "MG" } },
  { name: "SAO ROMAO", value: { cityName: "SAO ROMAO", uf: "MG" } },
  {
    name: "SANTA FE DE MINAS",
    value: { cityName: "SANTA FE DE MINAS", uf: "MG" },
  },
  { name: "URUCUIA", value: { cityName: "URUCUIA", uf: "MG" } },
  { name: "UBAI", value: { cityName: "UBAI", uf: "MG" } },
  {
    name: "CORACAO DE JESUS",
    value: { cityName: "CORACAO DE JESUS", uf: "MG" },
  },
  { name: "IBIAI", value: { cityName: "IBIAI", uf: "MG" } },
  { name: "LAGOA DOS PATOS", value: { cityName: "LAGOA DOS PATOS", uf: "MG" } },
  { name: "JEQUITAI", value: { cityName: "JEQUITAI", uf: "MG" } },
  { name: "MONTES CLAROS", value: { cityName: "MONTES CLAROS", uf: "MG" } },
  { name: "BOCAIUVA", value: { cityName: "BOCAIUVA", uf: "MG" } },
  { name: "MIRABELA", value: { cityName: "MIRABELA", uf: "MG" } },
  {
    name: "SAO JOAO DA PONTE",
    value: { cityName: "SAO JOAO DA PONTE", uf: "MG" },
  },
  { name: "LONTRA", value: { cityName: "LONTRA", uf: "MG" } },
  { name: "JANAUBA", value: { cityName: "JANAUBA", uf: "MG" } },
  { name: "CAPITAO ENEAS", value: { cityName: "CAPITAO ENEAS", uf: "MG" } },
  { name: "VARZELANDIA", value: { cityName: "VARZELANDIA", uf: "MG" } },
  { name: "MATIAS CARDOSO", value: { cityName: "MATIAS CARDOSO", uf: "MG" } },
  { name: "JAIBA", value: { cityName: "JAIBA", uf: "MG" } },
  { name: "ITACARAMBI", value: { cityName: "ITACARAMBI", uf: "MG" } },
  { name: "MONTALVANIA", value: { cityName: "MONTALVANIA", uf: "MG" } },
  { name: "MONTE AZUL", value: { cityName: "MONTE AZUL", uf: "MG" } },
  { name: "ESPINOSA", value: { cityName: "ESPINOSA", uf: "MG" } },
  { name: "PORTEIRINHA", value: { cityName: "PORTEIRINHA", uf: "MG" } },
  {
    name: "RIACHO DOS MACHADOS",
    value: { cityName: "RIACHO DOS MACHADOS", uf: "MG" },
  },
  {
    name: "RIO PARDO DE MINAS",
    value: { cityName: "RIO PARDO DE MINAS", uf: "MG" },
  },
  {
    name: "SAO JOAO DO PARAISO",
    value: { cityName: "SAO JOAO DO PARAISO", uf: "MG" },
  },
  { name: "TAIOBEIRAS", value: { cityName: "TAIOBEIRAS", uf: "MG" } },
  { name: "RUBELITA", value: { cityName: "RUBELITA", uf: "MG" } },
  { name: "GRAO MOGOL", value: { cityName: "GRAO MOGOL", uf: "MG" } },
  { name: "FRANCISCO SA", value: { cityName: "FRANCISCO SA", uf: "MG" } },
  { name: "JURAMENTO", value: { cityName: "JURAMENTO", uf: "MG" } },
  { name: "ITACAMBIRA", value: { cityName: "ITACAMBIRA", uf: "MG" } },
  { name: "MEDINA", value: { cityName: "MEDINA", uf: "MG" } },
  { name: "ITAOBIM", value: { cityName: "ITAOBIM", uf: "MG" } },
  { name: "COMERCINHO", value: { cityName: "COMERCINHO", uf: "MG" } },
  { name: "VIRGEM DA LAPA", value: { cityName: "VIRGEM DA LAPA", uf: "MG" } },
  { name: "CORONEL MURTA", value: { cityName: "CORONEL MURTA", uf: "MG" } },
  { name: "BERILO", value: { cityName: "BERILO", uf: "MG" } },
  {
    name: "CHAPADA DO NORTE",
    value: { cityName: "CHAPADA DO NORTE", uf: "MG" },
  },
  { name: "MINAS NOVAS", value: { cityName: "MINAS NOVAS", uf: "MG" } },
  { name: "CARBONITA", value: { cityName: "CARBONITA", uf: "MG" } },
  { name: "ITAMARANDIBA", value: { cityName: "ITAMARANDIBA", uf: "MG" } },
  { name: "CAPELINHA", value: { cityName: "CAPELINHA", uf: "MG" } },
  { name: "MALACACHETA", value: { cityName: "MALACACHETA", uf: "MG" } },
  {
    name: "SAO JOAO EVANGELISTA",
    value: { cityName: "SAO JOAO EVANGELISTA", uf: "MG" },
  },
  { name: "COROACI", value: { cityName: "COROACI", uf: "MG" } },
  { name: "GONZAGA", value: { cityName: "GONZAGA", uf: "MG" } },
  {
    name: "SAO GERALDO DA PIEDADE",
    value: { cityName: "SAO GERALDO DA PIEDADE", uf: "MG" },
  },
  {
    name: "SANTA EFIGENIA DE MINAS",
    value: { cityName: "SANTA EFIGENIA DE MINAS", uf: "MG" },
  },
  { name: "SARDOA", value: { cityName: "SARDOA", uf: "MG" } },
  { name: "VIRGINOPOLIS", value: { cityName: "VIRGINOPOLIS", uf: "MG" } },
  {
    name: "DIVINOLANDIA DE MINAS",
    value: { cityName: "DIVINOLANDIA DE MINAS", uf: "MG" },
  },
  { name: "SABINOPOLIS", value: { cityName: "SABINOPOLIS", uf: "MG" } },
  { name: "MATERLANDIA", value: { cityName: "MATERLANDIA", uf: "MG" } },
  { name: "PAULISTAS", value: { cityName: "PAULISTAS", uf: "MG" } },
  { name: "COLUNA", value: { cityName: "COLUNA", uf: "MG" } },
  {
    name: "SANTA MARIA DO SUACUI",
    value: { cityName: "SANTA MARIA DO SUACUI", uf: "MG" },
  },
  {
    name: "SAO SEBASTIAO DO MARANHAO",
    value: { cityName: "SAO SEBASTIAO DO MARANHAO", uf: "MG" },
  },
  { name: "PAVAO", value: { cityName: "PAVAO", uf: "MG" } },
  { name: "CARAI", value: { cityName: "CARAI", uf: "MG" } },
  { name: "ITAIPE", value: { cityName: "ITAIPE", uf: "MG" } },
  { name: "CATUJI", value: { cityName: "CATUJI", uf: "MG" } },
  { name: "NOVO CRUZEIRO", value: { cityName: "NOVO CRUZEIRO", uf: "MG" } },
  { name: "LADAINHA", value: { cityName: "LADAINHA", uf: "MG" } },
  { name: "CAMPANARIO", value: { cityName: "CAMPANARIO", uf: "MG" } },
  { name: "ATALEIA", value: { cityName: "ATALEIA", uf: "MG" } },
  {
    name: "OURO VERDE DE MINAS",
    value: { cityName: "OURO VERDE DE MINAS", uf: "MG" },
  },
  {
    name: "SERRA DOS AIMORES",
    value: { cityName: "SERRA DOS AIMORES", uf: "MG" },
  },
  {
    name: "FRONTEIRA DOS VALES",
    value: { cityName: "FRONTEIRA DOS VALES", uf: "MG" },
  },
  { name: "MACHACALIS", value: { cityName: "MACHACALIS", uf: "MG" } },
  { name: "BERTOPOLIS", value: { cityName: "BERTOPOLIS", uf: "MG" } },
  { name: "UMBURATIBA", value: { cityName: "UMBURATIBA", uf: "MG" } },
  { name: "FELISBURGO", value: { cityName: "FELISBURGO", uf: "MG" } },
  { name: "DIVISOPOLIS", value: { cityName: "DIVISOPOLIS", uf: "MG" } },
  { name: "MATA VERDE", value: { cityName: "MATA VERDE", uf: "MG" } },
  { name: "BANDEIRA", value: { cityName: "BANDEIRA", uf: "MG" } },
  { name: "SALTO DA DIVISA", value: { cityName: "SALTO DA DIVISA", uf: "MG" } },
  {
    name: "SANTO ANTONIO DO JACINTO",
    value: { cityName: "SANTO ANTONIO DO JACINTO", uf: "MG" },
  },
  { name: "RIO DO PRADO", value: { cityName: "RIO DO PRADO", uf: "MG" } },
  { name: "PALMOPOLIS", value: { cityName: "PALMOPOLIS", uf: "MG" } },
  { name: "AGUAS VERMELHAS", value: { cityName: "AGUAS VERMELHAS", uf: "MG" } },
  { name: "MADRE DE DEUS", value: { cityName: "MADRE DE DEUS", uf: "BA" } },
  {
    name: "LAURO DE FREITAS",
    value: { cityName: "LAURO DE FREITAS", uf: "BA" },
  },
  { name: "CAMACARI", value: { cityName: "CAMACARI", uf: "BA" } },
  { name: "DIAS D AVILA", value: { cityName: "DIAS D AVILA", uf: "BA" } },
  { name: "SIMOES FILHO", value: { cityName: "SIMOES FILHO", uf: "BA" } },
  {
    name: "SAO SEBASTIAO DO PASSE",
    value: { cityName: "SAO SEBASTIAO DO PASSE", uf: "BA" },
  },
  {
    name: "SAO FRANCISCO DO CONDE",
    value: { cityName: "SAO FRANCISCO DO CONDE", uf: "BA" },
  },
  { name: "TANQUINHO", value: { cityName: "TANQUINHO", uf: "BA" } },
  { name: "ANTONIO CARDOSO", value: { cityName: "ANTONIO CARDOSO", uf: "BA" } },
  { name: "SANTO ESTEVAO", value: { cityName: "SANTO ESTEVAO", uf: "BA" } },
  { name: "SAUBARA", value: { cityName: "SAUBARA", uf: "BA" } },
  {
    name: "AMELIA RODRIGUES",
    value: { cityName: "AMELIA RODRIGUES", uf: "BA" },
  },
  {
    name: "CONCEICAO DO JACUIPE",
    value: { cityName: "CONCEICAO DO JACUIPE", uf: "BA" },
  },
  {
    name: "CORACAO DE MARIA",
    value: { cityName: "CORACAO DE MARIA", uf: "BA" },
  },
  { name: "IRARA", value: { cityName: "IRARA", uf: "BA" } },
  { name: "SANTANOPOLIS", value: { cityName: "SANTANOPOLIS", uf: "BA" } },
  { name: "TERRA NOVA", value: { cityName: "TERRA NOVA", uf: "BA" } },
  { name: "CACHOEIRA", value: { cityName: "CACHOEIRA", uf: "BA" } },
  {
    name: "CONCEICAO DA FEIRA",
    value: { cityName: "CONCEICAO DA FEIRA", uf: "BA" },
  },
  {
    name: "SAO GONCALO DOS CAMPOS",
    value: { cityName: "SAO GONCALO DOS CAMPOS", uf: "BA" },
  },
  { name: "MURITIBA", value: { cityName: "MURITIBA", uf: "BA" } },
  { name: "SAO FELIX", value: { cityName: "SAO FELIX", uf: "BA" } },
  { name: "CRUZ DAS ALMAS", value: { cityName: "CRUZ DAS ALMAS", uf: "BA" } },
  { name: "NAZARE", value: { cityName: "NAZARE", uf: "BA" } },
  { name: "MARAGOGIPE", value: { cityName: "MARAGOGIPE", uf: "BA" } },
  {
    name: "SALINAS DA MARGARIDA",
    value: { cityName: "SALINAS DA MARGARIDA", uf: "BA" },
  },
  { name: "ITAPARICA", value: { cityName: "ITAPARICA", uf: "BA" } },
  { name: "JAGUARIPE", value: { cityName: "JAGUARIPE", uf: "BA" } },
  { name: "ARATUIPE", value: { cityName: "ARATUIPE", uf: "BA" } },
  { name: "SAPEACU", value: { cityName: "SAPEACU", uf: "BA" } },
  {
    name: "CONCEICAO DO ALMEIDA",
    value: { cityName: "CONCEICAO DO ALMEIDA", uf: "BA" },
  },
  { name: "SAO FELIPE", value: { cityName: "SAO FELIPE", uf: "BA" } },
  {
    name: "DOM MACEDO COSTA",
    value: { cityName: "DOM MACEDO COSTA", uf: "BA" },
  },
  {
    name: "SANTO ANTONIO DE JESUS",
    value: { cityName: "SANTO ANTONIO DE JESUS", uf: "BA" },
  },
  { name: "VARZEDO", value: { cityName: "VARZEDO", uf: "BA" } },
  { name: "MUNIZ FERREIRA", value: { cityName: "MUNIZ FERREIRA", uf: "BA" } },
  {
    name: "SAO MIGUEL DAS MATAS",
    value: { cityName: "SAO MIGUEL DAS MATAS", uf: "BA" },
  },
  { name: "PINTADAS", value: { cityName: "PINTADAS", uf: "BA" } },
  { name: "BAIXA GRANDE", value: { cityName: "BAIXA GRANDE", uf: "BA" } },
  { name: "MAIRI", value: { cityName: "MAIRI", uf: "BA" } },
  {
    name: "RIACHAO DO JACUIPE",
    value: { cityName: "RIACHAO DO JACUIPE", uf: "BA" },
  },
  { name: "NOVA FATIMA", value: { cityName: "NOVA FATIMA", uf: "BA" } },
  { name: "GAVIAO", value: { cityName: "GAVIAO", uf: "BA" } },
  { name: "SERRA PRETA", value: { cityName: "SERRA PRETA", uf: "BA" } },
  { name: "ANGUERA", value: { cityName: "ANGUERA", uf: "BA" } },
  {
    name: "SAO JOSE DO JACUIPE",
    value: { cityName: "SAO JOSE DO JACUIPE", uf: "BA" },
  },
  { name: "JACOBINA", value: { cityName: "JACOBINA", uf: "BA" } },
  { name: "MIGUEL CALMON", value: { cityName: "MIGUEL CALMON", uf: "BA" } },
  { name: "CAEM", value: { cityName: "CAEM", uf: "BA" } },
  { name: "SAUDE", value: { cityName: "SAUDE", uf: "BA" } },
  { name: "MIRANGABA", value: { cityName: "MIRANGABA", uf: "BA" } },
  {
    name: "CALDEIRAO GRANDE",
    value: { cityName: "CALDEIRAO GRANDE", uf: "BA" },
  },
  { name: "PINDOBACU", value: { cityName: "PINDOBACU", uf: "BA" } },
  { name: "FILADELFIA", value: { cityName: "FILADELFIA", uf: "BA" } },
  {
    name: "ANTONIO GONCALVES",
    value: { cityName: "ANTONIO GONCALVES", uf: "BA" },
  },
  { name: "CAMPO FORMOSO", value: { cityName: "CAMPO FORMOSO", uf: "BA" } },
  { name: "UMBURANAS", value: { cityName: "UMBURANAS", uf: "BA" } },
  { name: "PIRITIBA", value: { cityName: "PIRITIBA", uf: "BA" } },
  { name: "TAPIRAMUTA", value: { cityName: "TAPIRAMUTA", uf: "BA" } },
  { name: "MORRO DO CHAPEU", value: { cityName: "MORRO DO CHAPEU", uf: "BA" } },
  { name: "CAFARNAUM", value: { cityName: "CAFARNAUM", uf: "BA" } },
  { name: "CANARANA", value: { cityName: "CANARANA", uf: "BA" } },
  { name: "IRECE", value: { cityName: "IRECE", uf: "BA" } },
  { name: "LAPAO", value: { cityName: "LAPAO", uf: "BA" } },
  { name: "AMERICA DOURADA", value: { cityName: "AMERICA DOURADA", uf: "BA" } },
  { name: "JOAO DOURADO", value: { cityName: "JOAO DOURADO", uf: "BA" } },
  {
    name: "PRESIDENTE DUTRA",
    value: { cityName: "PRESIDENTE DUTRA", uf: "BA" },
  },
  { name: "CENTRAL", value: { cityName: "CENTRAL", uf: "BA" } },
  { name: "UIBAI", value: { cityName: "UIBAI", uf: "BA" } },
  { name: "IBITITA", value: { cityName: "IBITITA", uf: "BA" } },
  { name: "IBIPEBA", value: { cityName: "IBIPEBA", uf: "BA" } },
  { name: "BARRA DO MENDES", value: { cityName: "BARRA DO MENDES", uf: "BA" } },
  { name: "CAATIBA", value: { cityName: "CAATIBA", uf: "BA" } },
  { name: "ITAMBE", value: { cityName: "ITAMBE", uf: "BA" } },
  { name: "ENCRUZILHADA", value: { cityName: "ENCRUZILHADA", uf: "BA" } },
  { name: "CANDIDO SALES", value: { cityName: "CANDIDO SALES", uf: "BA" } },
  { name: "BELO CAMPO", value: { cityName: "BELO CAMPO", uf: "BA" } },
  { name: "TREMEDAL", value: { cityName: "TREMEDAL", uf: "BA" } },
  { name: "ANAGE", value: { cityName: "ANAGE", uf: "BA" } },
  { name: "JEQUIE", value: { cityName: "JEQUIE", uf: "BA" } },
  {
    name: "LAFAIETE COUTINHO",
    value: { cityName: "LAFAIETE COUTINHO", uf: "BA" },
  },
  { name: "AIQUARA", value: { cityName: "AIQUARA", uf: "BA" } },
  { name: "JITAUNA", value: { cityName: "JITAUNA", uf: "BA" } },
  { name: "ITAGI", value: { cityName: "ITAGI", uf: "BA" } },
  { name: "BOA NOVA", value: { cityName: "BOA NOVA", uf: "BA" } },
  { name: "POCOES", value: { cityName: "POCOES", uf: "BA" } },
  {
    name: "BOM JESUS DA SERRA",
    value: { cityName: "BOM JESUS DA SERRA", uf: "BA" },
  },
  { name: "NOVA CANAA", value: { cityName: "NOVA CANAA", uf: "BA" } },
  { name: "IGUAI", value: { cityName: "IGUAI", uf: "BA" } },
  { name: "IBICUI", value: { cityName: "IBICUI", uf: "BA" } },
  { name: "AMARGOSA", value: { cityName: "AMARGOSA", uf: "BA" } },
  { name: "ELISIO MEDRADO", value: { cityName: "ELISIO MEDRADO", uf: "BA" } },
  { name: "UBAIRA", value: { cityName: "UBAIRA", uf: "BA" } },
  { name: "SANTA INES", value: { cityName: "SANTA INES", uf: "BA" } },
  { name: "BREJOES", value: { cityName: "BREJOES", uf: "BA" } },
  { name: "ITAQUARA", value: { cityName: "ITAQUARA", uf: "BA" } },
  { name: "JAGUAQUARA", value: { cityName: "JAGUAQUARA", uf: "BA" } },
  { name: "APUAREMA", value: { cityName: "APUAREMA", uf: "BA" } },
  { name: "ITIRUCU", value: { cityName: "ITIRUCU", uf: "BA" } },
  { name: "IRAJUBA", value: { cityName: "IRAJUBA", uf: "BA" } },
  { name: "TAPEROA", value: { cityName: "TAPEROA", uf: "BA" } },
  { name: "ITUBERA", value: { cityName: "ITUBERA", uf: "BA" } },
  { name: "CAMAMU", value: { cityName: "CAMAMU", uf: "BA" } },
  { name: "GANDU", value: { cityName: "GANDU", uf: "BA" } },
  { name: "ITAMARI", value: { cityName: "ITAMARI", uf: "BA" } },
  {
    name: "WENCESLAU GUIMARAES",
    value: { cityName: "WENCESLAU GUIMARAES", uf: "BA" },
  },
  { name: "JIQUIRICA", value: { cityName: "JIQUIRICA", uf: "BA" } },
  { name: "MUTUIPE", value: { cityName: "MUTUIPE", uf: "BA" } },
  { name: "LAJE", value: { cityName: "LAJE", uf: "BA" } },
  { name: "IBIRAPITANGA", value: { cityName: "IBIRAPITANGA", uf: "BA" } },
  { name: "ITACARE", value: { cityName: "ITACARE", uf: "BA" } },
  { name: "GONGOGI", value: { cityName: "GONGOGI", uf: "BA" } },
  { name: "UBAITABA", value: { cityName: "UBAITABA", uf: "BA" } },
  { name: "UBATA", value: { cityName: "UBATA", uf: "BA" } },
  { name: "IPIAU", value: { cityName: "IPIAU", uf: "BA" } },
  { name: "IBIRATAIA", value: { cityName: "IBIRATAIA", uf: "BA" } },
  { name: "ITAGIBA", value: { cityName: "ITAGIBA", uf: "BA" } },
  { name: "DARIO MEIRA", value: { cityName: "DARIO MEIRA", uf: "BA" } },
  { name: "ITABUNA", value: { cityName: "ITABUNA", uf: "BA" } },
  { name: "FERRADAS", value: { cityName: "FERRADAS", uf: "BA" } },
  { name: "JUSSARI", value: { cityName: "JUSSARI", uf: "BA" } },
  { name: "BUERAREMA", value: { cityName: "BUERAREMA", uf: "BA" } },
  { name: "BARRO PRETO", value: { cityName: "BARRO PRETO", uf: "BA" } },
  { name: "ITAJUIPE", value: { cityName: "ITAJUIPE", uf: "BA" } },
  { name: "COARACI", value: { cityName: "COARACI", uf: "BA" } },
  { name: "ALMADINA", value: { cityName: "ALMADINA", uf: "BA" } },
  { name: "ITAPITANGA", value: { cityName: "ITAPITANGA", uf: "BA" } },
  { name: "ILHEUS", value: { cityName: "ILHEUS", uf: "BA" } },
  { name: "AURELINO LEAL", value: { cityName: "AURELINO LEAL", uf: "BA" } },
  { name: "URUCUCA", value: { cityName: "URUCUCA", uf: "BA" } },
  { name: "UNA", value: { cityName: "UNA", uf: "BA" } },
  { name: "ITAPETINGA", value: { cityName: "ITAPETINGA", uf: "BA" } },
  { name: "ITORORO", value: { cityName: "ITORORO", uf: "BA" } },
  { name: "FIRMINO ALVES", value: { cityName: "FIRMINO ALVES", uf: "BA" } },
  { name: "FLORESTA AZUL", value: { cityName: "FLORESTA AZUL", uf: "BA" } },
  { name: "IBICARAI", value: { cityName: "IBICARAI", uf: "BA" } },
  { name: "ITAPE", value: { cityName: "ITAPE", uf: "BA" } },
  { name: "MACARANI", value: { cityName: "MACARANI", uf: "BA" } },
  { name: "MAIQUINIQUE", value: { cityName: "MAIQUINIQUE", uf: "BA" } },
  { name: "ITARANTIM", value: { cityName: "ITARANTIM", uf: "BA" } },
  { name: "POTIRAGUA", value: { cityName: "POTIRAGUA", uf: "BA" } },
  { name: "PORTO SEGURO", value: { cityName: "PORTO SEGURO", uf: "BA" } },
  { name: "EUNAPOLIS", value: { cityName: "EUNAPOLIS", uf: "BA" } },
  { name: "ITAMARAJU", value: { cityName: "ITAMARAJU", uf: "BA" } },
  { name: "ITAGIMIRIM", value: { cityName: "ITAGIMIRIM", uf: "BA" } },
  { name: "ITAPEBI", value: { cityName: "ITAPEBI", uf: "BA" } },
  { name: "CANAVIEIRAS", value: { cityName: "CANAVIEIRAS", uf: "BA" } },
  { name: "MASCOTE", value: { cityName: "MASCOTE", uf: "BA" } },
  { name: "CAMACAN", value: { cityName: "CAMACAN", uf: "BA" } },
  { name: "NOVA VICOSA", value: { cityName: "NOVA VICOSA", uf: "BA" } },
  { name: "MUCURI", value: { cityName: "MUCURI", uf: "BA" } },
  { name: "IBIRAPUA", value: { cityName: "IBIRAPUA", uf: "BA" } },
  { name: "LAJEDAO", value: { cityName: "LAJEDAO", uf: "BA" } },
  { name: "MEDEIROS NETO", value: { cityName: "MEDEIROS NETO", uf: "BA" } },
  { name: "ITANHEM", value: { cityName: "ITANHEM", uf: "BA" } },
  { name: "PRADO", value: { cityName: "PRADO", uf: "BA" } },
  {
    name: "TEIXEIRA DE FREITAS",
    value: { cityName: "TEIXEIRA DE FREITAS", uf: "BA" },
  },
  { name: "BRUMADO", value: { cityName: "BRUMADO", uf: "BA" } },
  { name: "RIO DE CONTAS", value: { cityName: "RIO DE CONTAS", uf: "BA" } },
  { name: "PARAMIRIM", value: { cityName: "PARAMIRIM", uf: "BA" } },
  { name: "CONDEUBA", value: { cityName: "CONDEUBA", uf: "BA" } },
  { name: "GUAJERU", value: { cityName: "GUAJERU", uf: "BA" } },
  {
    name: "PRESIDENTE JANIO QUADROS",
    value: { cityName: "PRESIDENTE JANIO QUADROS", uf: "BA" },
  },
  { name: "MORTUGABA", value: { cityName: "MORTUGABA", uf: "BA" } },
  { name: "CACULE", value: { cityName: "CACULE", uf: "BA" } },
  { name: "JACARACI", value: { cityName: "JACARACI", uf: "BA" } },
  {
    name: "LICINIO DE ALMEIDA",
    value: { cityName: "LICINIO DE ALMEIDA", uf: "BA" },
  },
  { name: "URANDI", value: { cityName: "URANDI", uf: "BA" } },
  { name: "PINDAI", value: { cityName: "PINDAI", uf: "BA" } },
  { name: "CAETITE", value: { cityName: "CAETITE", uf: "BA" } },
  { name: "LAGOA REAL", value: { cityName: "LAGOA REAL", uf: "BA" } },
  { name: "GUANAMBI", value: { cityName: "GUANAMBI", uf: "BA" } },
  { name: "MALHADA", value: { cityName: "MALHADA", uf: "BA" } },
  { name: "CARINHANHA", value: { cityName: "CARINHANHA", uf: "BA" } },
  {
    name: "PALMAS DE MONTE ALTO",
    value: { cityName: "PALMAS DE MONTE ALTO", uf: "BA" },
  },
  {
    name: "RIACHO DE SANTANA",
    value: { cityName: "RIACHO DE SANTANA", uf: "BA" },
  },
  { name: "MATINA", value: { cityName: "MATINA", uf: "BA" } },
  { name: "IGAPORA", value: { cityName: "IGAPORA", uf: "BA" } },
  { name: "MACAUBAS", value: { cityName: "MACAUBAS", uf: "BA" } },
  { name: "BOQUIRA", value: { cityName: "BOQUIRA", uf: "BA" } },
  { name: "IBIPITANGA", value: { cityName: "IBIPITANGA", uf: "BA" } },
  { name: "RIO DO PIRES", value: { cityName: "RIO DO PIRES", uf: "BA" } },
  { name: "BOTUPORA", value: { cityName: "BOTUPORA", uf: "BA" } },
  { name: "TANHACU", value: { cityName: "TANHACU", uf: "BA" } },
  { name: "ITUACU", value: { cityName: "ITUACU", uf: "BA" } },
  { name: "BARRA DA ESTIVA", value: { cityName: "BARRA DA ESTIVA", uf: "BA" } },
  { name: "ABAIRA", value: { cityName: "ABAIRA", uf: "BA" } },
  { name: "IBITIARA", value: { cityName: "IBITIARA", uf: "BA" } },
  { name: "BONINAL", value: { cityName: "BONINAL", uf: "BA" } },
  { name: "MUCUGE", value: { cityName: "MUCUGE", uf: "BA" } },
  { name: "IBICOARA", value: { cityName: "IBICOARA", uf: "BA" } },
  { name: "PIATA", value: { cityName: "PIATA", uf: "BA" } },
  { name: "IRAMAIA", value: { cityName: "IRAMAIA", uf: "BA" } },
  {
    name: "MARCIONILIO SOUZA",
    value: { cityName: "MARCIONILIO SOUZA", uf: "BA" },
  },
  { name: "RUY BARBOSA", value: { cityName: "RUY BARBOSA", uf: "BA" } },
  { name: "MACAJUBA", value: { cityName: "MACAJUBA", uf: "BA" } },
  { name: "UTINGA", value: { cityName: "UTINGA", uf: "BA" } },
  { name: "BONITO", value: { cityName: "BONITO", uf: "BA" } },
  { name: "LAJEDINHO", value: { cityName: "LAJEDINHO", uf: "BA" } },
  { name: "ANDARAI", value: { cityName: "ANDARAI", uf: "BA" } },
  { name: "NOVA REDENCAO", value: { cityName: "NOVA REDENCAO", uf: "BA" } },
  { name: "IBIQUERA", value: { cityName: "IBIQUERA", uf: "BA" } },
  {
    name: "BOA VISTA DO TUPIM",
    value: { cityName: "BOA VISTA DO TUPIM", uf: "BA" },
  },
  { name: "IACU", value: { cityName: "IACU", uf: "BA" } },
  { name: "SANTA TERESINHA", value: { cityName: "SANTA TERESINHA", uf: "BA" } },
  { name: "ITABERABA", value: { cityName: "ITABERABA", uf: "BA" } },
  { name: "SEABRA", value: { cityName: "SEABRA", uf: "BA" } },
  { name: "PALMEIRAS", value: { cityName: "PALMEIRAS", uf: "BA" } },
  { name: "LENCOIS", value: { cityName: "LENCOIS", uf: "BA" } },
  { name: "WAGNER", value: { cityName: "WAGNER", uf: "BA" } },
  { name: "IRAQUARA", value: { cityName: "IRAQUARA", uf: "BA" } },
  { name: "SOUTO SOARES", value: { cityName: "SOUTO SOARES", uf: "BA" } },
  { name: "BARRA", value: { cityName: "BARRA", uf: "BA" } },
  { name: "BURITIRAMA", value: { cityName: "BURITIRAMA", uf: "BA" } },
  {
    name: "SANTA RITA DE CASSIA",
    value: { cityName: "SANTA RITA DE CASSIA", uf: "BA" },
  },
  { name: "REMANSO", value: { cityName: "REMANSO", uf: "BA" } },
  {
    name: "CAMPO ALEGRE DE LOURDES",
    value: { cityName: "CAMPO ALEGRE DE LOURDES", uf: "BA" },
  },
  { name: "PILAO ARCADO", value: { cityName: "PILAO ARCADO", uf: "BA" } },
  { name: "CASA NOVA", value: { cityName: "CASA NOVA", uf: "BA" } },
  { name: "SENTO SE", value: { cityName: "SENTO SE", uf: "BA" } },
  { name: "XIQUE-XIQUE", value: { cityName: "XIQUE-XIQUE", uf: "BA" } },
  { name: "GENTIO DO OURO", value: { cityName: "GENTIO DO OURO", uf: "BA" } },
  { name: "PARATINGA", value: { cityName: "PARATINGA", uf: "BA" } },
  { name: "IBOTIRAMA", value: { cityName: "IBOTIRAMA", uf: "BA" } },
  {
    name: "OLIVEIRA DOS BREJINHOS",
    value: { cityName: "OLIVEIRA DOS BREJINHOS", uf: "BA" },
  },
  {
    name: "BROTAS DE MACAUBAS",
    value: { cityName: "BROTAS DE MACAUBAS", uf: "BA" },
  },
  { name: "MORPARA", value: { cityName: "MORPARA", uf: "BA" } },
  { name: "IPUPIARA", value: { cityName: "IPUPIARA", uf: "BA" } },
  {
    name: "BOM JESUS DA LAPA",
    value: { cityName: "BOM JESUS DA LAPA", uf: "BA" },
  },
  { name: "COCOS", value: { cityName: "COCOS", uf: "BA" } },
  { name: "CORIBE", value: { cityName: "CORIBE", uf: "BA" } },
  { name: "SANTANA", value: { cityName: "SANTANA", uf: "BA" } },
  { name: "ANGICAL", value: { cityName: "ANGICAL", uf: "BA" } },
  { name: "ALAGOINHAS", value: { cityName: "ALAGOINHAS", uf: "BA" } },
  { name: "ARACAS", value: { cityName: "ARACAS", uf: "BA" } },
  { name: "CATU", value: { cityName: "CATU", uf: "BA" } },
  { name: "POJUCA", value: { cityName: "POJUCA", uf: "BA" } },
  { name: "ARAMARI", value: { cityName: "ARAMARI", uf: "BA" } },
  { name: "PEDRAO", value: { cityName: "PEDRAO", uf: "BA" } },
  { name: "OURICANGAS", value: { cityName: "OURICANGAS", uf: "BA" } },
  { name: "AGUA FRIA", value: { cityName: "AGUA FRIA", uf: "BA" } },
  { name: "ENTRE RIOS", value: { cityName: "ENTRE RIOS", uf: "BA" } },
  {
    name: "MATA DE SAO JOAO",
    value: { cityName: "MATA DE SAO JOAO", uf: "BA" },
  },
  { name: "ITANAGRA", value: { cityName: "ITANAGRA", uf: "BA" } },
  { name: "CONDE", value: { cityName: "CONDE", uf: "BA" } },
  { name: "JANDAIRA", value: { cityName: "JANDAIRA", uf: "BA" } },
  { name: "RIO REAL", value: { cityName: "RIO REAL", uf: "BA" } },
  { name: "APORA", value: { cityName: "APORA", uf: "BA" } },
  { name: "ACAJUTIBA", value: { cityName: "ACAJUTIBA", uf: "BA" } },
  { name: "ESPLANADA", value: { cityName: "ESPLANADA", uf: "BA" } },
  {
    name: "CARDEAL DA SILVA",
    value: { cityName: "CARDEAL DA SILVA", uf: "BA" },
  },
  {
    name: "RIBEIRA DO POMBAL",
    value: { cityName: "RIBEIRA DO POMBAL", uf: "BA" },
  },
  { name: "CICERO DANTAS", value: { cityName: "CICERO DANTAS", uf: "BA" } },
  { name: "FATIMA", value: { cityName: "FATIMA", uf: "BA" } },
  { name: "ANTAS", value: { cityName: "ANTAS", uf: "BA" } },
  { name: "PARIPIRANGA", value: { cityName: "PARIPIRANGA", uf: "BA" } },
  { name: "ADUSTINA", value: { cityName: "ADUSTINA", uf: "BA" } },
  {
    name: "RIBEIRA DO AMPARO",
    value: { cityName: "RIBEIRA DO AMPARO", uf: "BA" },
  },
  { name: "HELIOPOLIS", value: { cityName: "HELIOPOLIS", uf: "BA" } },
  { name: "CIPO", value: { cityName: "CIPO", uf: "BA" } },
  { name: "NOVA SOURE", value: { cityName: "NOVA SOURE", uf: "BA" } },
  { name: "OLINDINA", value: { cityName: "OLINDINA", uf: "BA" } },
  { name: "ITAPICURU", value: { cityName: "ITAPICURU", uf: "BA" } },
  { name: "CRISOPOLIS", value: { cityName: "CRISOPOLIS", uf: "BA" } },
  { name: "INHAMBUPE", value: { cityName: "INHAMBUPE", uf: "BA" } },
  {
    name: "EUCLIDES DA CUNHA",
    value: { cityName: "EUCLIDES DA CUNHA", uf: "BA" },
  },
  { name: "CANUDOS", value: { cityName: "CANUDOS", uf: "BA" } },
  { name: "JEREMOABO", value: { cityName: "JEREMOABO", uf: "BA" } },
  { name: "SANTA BRIGIDA", value: { cityName: "SANTA BRIGIDA", uf: "BA" } },
  { name: "PAULO AFONSO", value: { cityName: "PAULO AFONSO", uf: "BA" } },
  { name: "GLORIA", value: { cityName: "GLORIA", uf: "BA" } },
  { name: "MACURURE", value: { cityName: "MACURURE", uf: "BA" } },
  { name: "CHORROCHO", value: { cityName: "CHORROCHO", uf: "BA" } },
  { name: "ABARE", value: { cityName: "ABARE", uf: "BA" } },
  { name: "SERRINHA", value: { cityName: "SERRINHA", uf: "BA" } },
  { name: "CANDEAL", value: { cityName: "CANDEAL", uf: "BA" } },
  { name: "LAMARAO", value: { cityName: "LAMARAO", uf: "BA" } },
  { name: "ICHU", value: { cityName: "ICHU", uf: "BA" } },
  {
    name: "CONCEICAO DO COITE",
    value: { cityName: "CONCEICAO DO COITE", uf: "BA" },
  },
  { name: "ARACI", value: { cityName: "ARACI", uf: "BA" } },
  { name: "TEOFILANDIA", value: { cityName: "TEOFILANDIA", uf: "BA" } },
  { name: "BIRITINGA", value: { cityName: "BIRITINGA", uf: "BA" } },
  { name: "TUCANO", value: { cityName: "TUCANO", uf: "BA" } },
  { name: "QUIJINGUE", value: { cityName: "QUIJINGUE", uf: "BA" } },
  { name: "CANSANCAO", value: { cityName: "CANSANCAO", uf: "BA" } },
  { name: "ITIUBA", value: { cityName: "ITIUBA", uf: "BA" } },
  { name: "QUEIMADAS", value: { cityName: "QUEIMADAS", uf: "BA" } },
  { name: "NORDESTINA", value: { cityName: "NORDESTINA", uf: "BA" } },
  { name: "SANTALUZ", value: { cityName: "SANTALUZ", uf: "BA" } },
  { name: "JUAZEIRO", value: { cityName: "JUAZEIRO", uf: "BA" } },
  { name: "CURACA", value: { cityName: "CURACA", uf: "BA" } },
  { name: "UAUA", value: { cityName: "UAUA", uf: "BA" } },
  { name: "JAGUARARI", value: { cityName: "JAGUARARI", uf: "BA" } },
  {
    name: "SENHOR DO BONFIM",
    value: { cityName: "SENHOR DO BONFIM", uf: "BA" },
  },
  { name: "SAO CRISTOVAO", value: { cityName: "SAO CRISTOVAO", uf: "SE" } },
  {
    name: "ITAPORANGA DAJUDA",
    value: { cityName: "ITAPORANGA DAJUDA", uf: "SE" },
  },
  {
    name: "BARRA DOS COQUEIROS",
    value: { cityName: "BARRA DOS COQUEIROS", uf: "SE" },
  },
  {
    name: "NOSSA SENHORA DO SOCORRO",
    value: { cityName: "NOSSA SENHORA DO SOCORRO", uf: "SE" },
  },
  { name: "LARANJEIRAS", value: { cityName: "LARANJEIRAS", uf: "SE" } },
  { name: "ESTANCIA", value: { cityName: "ESTANCIA", uf: "SE" } },
  { name: "ARAUA", value: { cityName: "ARAUA", uf: "SE" } },
  { name: "INDIAROBA", value: { cityName: "INDIAROBA", uf: "SE" } },
  { name: "UMBAUBA", value: { cityName: "UMBAUBA", uf: "SE" } },
  { name: "TOMAR DO GERU", value: { cityName: "TOMAR DO GERU", uf: "SE" } },
  { name: "ITABAIANINHA", value: { cityName: "ITABAIANINHA", uf: "SE" } },
  { name: "TOBIAS BARRETO", value: { cityName: "TOBIAS BARRETO", uf: "SE" } },
  {
    name: "RIACHAO DO DANTAS",
    value: { cityName: "RIACHAO DO DANTAS", uf: "SE" },
  },
  { name: "BOQUIM", value: { cityName: "BOQUIM", uf: "SE" } },
  { name: "SALGADO", value: { cityName: "SALGADO", uf: "SE" } },
  { name: "LAGARTO", value: { cityName: "LAGARTO", uf: "SE" } },
  { name: "SIMAO DIAS", value: { cityName: "SIMAO DIAS", uf: "SE" } },
  { name: "ITABAIANA", value: { cityName: "ITABAIANA", uf: "SE" } },
  { name: "FREI PAULO", value: { cityName: "FREI PAULO", uf: "SE" } },
  { name: "PINHAO", value: { cityName: "PINHAO", uf: "SE" } },
  { name: "CAMPO DO BRITO", value: { cityName: "CAMPO DO BRITO", uf: "SE" } },
  {
    name: "NOSSA SENHORA APARECIDA",
    value: { cityName: "NOSSA SENHORA APARECIDA", uf: "SE" },
  },
  { name: "CARIRA", value: { cityName: "CARIRA", uf: "SE" } },
  { name: "AREIA BRANCA", value: { cityName: "AREIA BRANCA", uf: "SE" } },
  {
    name: "NOSSA SENHORA DAS DORES",
    value: { cityName: "NOSSA SENHORA DAS DORES", uf: "SE" },
  },
  { name: "SIRIRI", value: { cityName: "SIRIRI", uf: "SE" } },
  { name: "DIVINA PASTORA", value: { cityName: "DIVINA PASTORA", uf: "SE" } },
  { name: "CUMBE", value: { cityName: "CUMBE", uf: "SE" } },
  { name: "CAPELA", value: { cityName: "CAPELA", uf: "SE" } },
  { name: "CARMOPOLIS", value: { cityName: "CARMOPOLIS", uf: "SE" } },
  {
    name: "ROSARIO DO CATETE",
    value: { cityName: "ROSARIO DO CATETE", uf: "SE" },
  },
  { name: "MARUIM", value: { cityName: "MARUIM", uf: "SE" } },
  { name: "MURIBECA", value: { cityName: "MURIBECA", uf: "SE" } },
  { name: "AQUIDABA", value: { cityName: "AQUIDABA", uf: "SE" } },
  { name: "PORTO DA FOLHA", value: { cityName: "PORTO DA FOLHA", uf: "SE" } },
  {
    name: "CANINDE DE SAO FRANCISCO",
    value: { cityName: "CANINDE DE SAO FRANCISCO", uf: "SE" },
  },
  { name: "GARARU", value: { cityName: "GARARU", uf: "SE" } },
  { name: "ITABI", value: { cityName: "ITABI", uf: "SE" } },
  { name: "CANHOBA", value: { cityName: "CANHOBA", uf: "SE" } },
  { name: "PROPRIA", value: { cityName: "PROPRIA", uf: "SE" } },
  {
    name: "CEDRO DE SAO JOAO",
    value: { cityName: "CEDRO DE SAO JOAO", uf: "SE" },
  },
  { name: "NEOPOLIS", value: { cityName: "NEOPOLIS", uf: "SE" } },
  { name: "ILHA DAS FLORES", value: { cityName: "ILHA DAS FLORES", uf: "SE" } },
  { name: "BREJO GRANDE", value: { cityName: "BREJO GRANDE", uf: "SE" } },
  { name: "ABREU E LIMA", value: { cityName: "ABREU E LIMA", uf: "PE" } },
  { name: "IGARASSU", value: { cityName: "IGARASSU", uf: "PE" } },
  { name: "ITAMARACA", value: { cityName: "ITAMARACA", uf: "PE" } },
  { name: "CAMPINAS", value: { cityName: "CAMPINAS", uf: "PE" } },
  {
    name: "SAO LOURENCO DA MATA",
    value: { cityName: "SAO LOURENCO DA MATA", uf: "PE" },
  },
  { name: "CAMARAGIBE", value: { cityName: "CAMARAGIBE", uf: "PE" } },
  { name: "MORENO", value: { cityName: "MORENO", uf: "PE" } },
  { name: "CARUARU", value: { cityName: "CARUARU", uf: "PE" } },
  { name: "SAO CAITANO", value: { cityName: "SAO CAITANO", uf: "PE" } },
  { name: "TACAIMBO", value: { cityName: "TACAIMBO", uf: "PE" } },
  { name: "BELO JARDIM", value: { cityName: "BELO JARDIM", uf: "PE" } },
  {
    name: "BREJO DA MADRE DE DEUS",
    value: { cityName: "BREJO DA MADRE DE DEUS", uf: "PE" },
  },
  { name: "PESQUEIRA", value: { cityName: "PESQUEIRA", uf: "PE" } },
  { name: "SANHARO", value: { cityName: "SANHARO", uf: "PE" } },
  { name: "ALAGOINHA", value: { cityName: "ALAGOINHA", uf: "PE" } },
  { name: "VENTUROSA", value: { cityName: "VENTUROSA", uf: "PE" } },
  { name: "PEDRA", value: { cityName: "PEDRA", uf: "PE" } },
  { name: "GARANHUNS", value: { cityName: "GARANHUNS", uf: "PE" } },
  { name: "TEREZINHA", value: { cityName: "TEREZINHA", uf: "PE" } },
  { name: "PALMEIRINA", value: { cityName: "PALMEIRINA", uf: "PE" } },
  { name: "CORRENTES", value: { cityName: "CORRENTES", uf: "PE" } },
  { name: "BREJAO", value: { cityName: "BREJAO", uf: "PE" } },
  { name: "BOM CONSELHO", value: { cityName: "BOM CONSELHO", uf: "PE" } },
  { name: "AGUAS BELAS", value: { cityName: "AGUAS BELAS", uf: "PE" } },
  { name: "IATI", value: { cityName: "IATI", uf: "PE" } },
  { name: "PARANATAMA", value: { cityName: "PARANATAMA", uf: "PE" } },
  { name: "CAETES", value: { cityName: "CAETES", uf: "PE" } },
  { name: "CAPOEIRAS", value: { cityName: "CAPOEIRAS", uf: "PE" } },
  {
    name: "SAO BENTO DO UNA",
    value: { cityName: "SAO BENTO DO UNA", uf: "PE" },
  },
  { name: "CALCADO", value: { cityName: "CALCADO", uf: "PE" } },
  { name: "LAJEDO", value: { cityName: "LAJEDO", uf: "PE" } },
  { name: "JUPI", value: { cityName: "JUPI", uf: "PE" } },
  { name: "JUCATI", value: { cityName: "JUCATI", uf: "PE" } },
  { name: "CATENDE", value: { cityName: "CATENDE", uf: "PE" } },
  { name: "MARAIAL", value: { cityName: "MARAIAL", uf: "PE" } },
  {
    name: "SAO BENEDITO DO SUL",
    value: { cityName: "SAO BENEDITO DO SUL", uf: "PE" },
  },
  { name: "QUIPAPA", value: { cityName: "QUIPAPA", uf: "PE" } },
  { name: "CANHOTINHO", value: { cityName: "CANHOTINHO", uf: "PE" } },
  { name: "ANGELIM", value: { cityName: "ANGELIM", uf: "PE" } },
  { name: "SAO JOAO", value: { cityName: "SAO JOAO", uf: "PE" } },
  { name: "BELEM DE MARIA", value: { cityName: "BELEM DE MARIA", uf: "PE" } },
  { name: "LAGOA DOS GATOS", value: { cityName: "LAGOA DOS GATOS", uf: "PE" } },
  { name: "CUPIRA", value: { cityName: "CUPIRA", uf: "PE" } },
  { name: "PANELAS", value: { cityName: "PANELAS", uf: "PE" } },
  { name: "JUREMA", value: { cityName: "JUREMA", uf: "PE" } },
  { name: "ALTINHO", value: { cityName: "ALTINHO", uf: "PE" } },
  { name: "ESCADA", value: { cityName: "ESCADA", uf: "PE" } },
  { name: "PRIMAVERA", value: { cityName: "PRIMAVERA", uf: "PE" } },
  { name: "RIBEIRAO", value: { cityName: "RIBEIRAO", uf: "PE" } },
  { name: "CORTES", value: { cityName: "CORTES", uf: "PE" } },
  { name: "GAMELEIRA", value: { cityName: "GAMELEIRA", uf: "PE" } },
  { name: "AGUA PRETA", value: { cityName: "AGUA PRETA", uf: "PE" } },
  { name: "XEXEU", value: { cityName: "XEXEU", uf: "PE" } },
  { name: "BARREIROS", value: { cityName: "BARREIROS", uf: "PE" } },
  { name: "RIO FORMOSO", value: { cityName: "RIO FORMOSO", uf: "PE" } },
  { name: "IPOJUCA", value: { cityName: "IPOJUCA", uf: "PE" } },
  {
    name: "VITORIA DE SANTO ANTAO",
    value: { cityName: "VITORIA DE SANTO ANTAO", uf: "PE" },
  },
  { name: "GLORIA DO GOITA", value: { cityName: "GLORIA DO GOITA", uf: "PE" } },
  { name: "POMBOS", value: { cityName: "POMBOS", uf: "PE" } },
  { name: "GRAVATA", value: { cityName: "GRAVATA", uf: "PE" } },
  { name: "BEZERROS", value: { cityName: "BEZERROS", uf: "PE" } },
  { name: "SALGADINHO", value: { cityName: "SALGADINHO", uf: "PE" } },
  { name: "JOAO ALFREDO", value: { cityName: "JOAO ALFREDO", uf: "PE" } },
  { name: "SURUBIM", value: { cityName: "SURUBIM", uf: "PE" } },
  {
    name: "SANTA MARIA DO CAMBUCA",
    value: { cityName: "SANTA MARIA DO CAMBUCA", uf: "PE" },
  },
  { name: "VERTENTES", value: { cityName: "VERTENTES", uf: "PE" } },
  { name: "FREI MIGUELINHO", value: { cityName: "FREI MIGUELINHO", uf: "PE" } },
  { name: "NAZARE DA MATA", value: { cityName: "NAZARE DA MATA", uf: "PE" } },
  { name: "CARPINA", value: { cityName: "CARPINA", uf: "PE" } },
  { name: "PAUDALHO", value: { cityName: "PAUDALHO", uf: "PE" } },
  { name: "CHA DE ALEGRIA", value: { cityName: "CHA DE ALEGRIA", uf: "PE" } },
  { name: "BUENOS AIRES", value: { cityName: "BUENOS AIRES", uf: "PE" } },
  { name: "VICENCIA", value: { cityName: "VICENCIA", uf: "PE" } },
  { name: "TIMBAUBA", value: { cityName: "TIMBAUBA", uf: "PE" } },
  { name: "ALIANCA", value: { cityName: "ALIANCA", uf: "PE" } },
  { name: "GOIANA", value: { cityName: "GOIANA", uf: "PE" } },
  { name: "CONDADO", value: { cityName: "CONDADO", uf: "PE" } },
  { name: "ITAQUITINGA", value: { cityName: "ITAQUITINGA", uf: "PE" } },
  { name: "SALGUEIRO", value: { cityName: "SALGUEIRO", uf: "PE" } },
  { name: "VERDEJANTE", value: { cityName: "VERDEJANTE", uf: "PE" } },
  { name: "SERRITA", value: { cityName: "SERRITA", uf: "PE" } },
  { name: "MOREILANDIA", value: { cityName: "MOREILANDIA", uf: "PE" } },
  { name: "PARNAMIRIM", value: { cityName: "PARNAMIRIM", uf: "PE" } },
  { name: "OROCO", value: { cityName: "OROCO", uf: "PE" } },
  { name: "CABROBO", value: { cityName: "CABROBO", uf: "PE" } },
  { name: "OURICURI", value: { cityName: "OURICURI", uf: "PE" } },
  { name: "BODOCO", value: { cityName: "BODOCO", uf: "PE" } },
  { name: "EXU", value: { cityName: "EXU", uf: "PE" } },
  { name: "AFRANIO", value: { cityName: "AFRANIO", uf: "PE" } },
  {
    name: "SANTA MARIA DA BOA VISTA",
    value: { cityName: "SANTA MARIA DA BOA VISTA", uf: "PE" },
  },
  { name: "FLORESTA", value: { cityName: "FLORESTA", uf: "PE" } },
  { name: "ITACURUBA", value: { cityName: "ITACURUBA", uf: "PE" } },
  {
    name: "BELEM DE SAO FRANCISCO",
    value: { cityName: "BELEM DE SAO FRANCISCO", uf: "PE" },
  },
  { name: "BUIQUE", value: { cityName: "BUIQUE", uf: "PE" } },
  { name: "TUPANATINGA", value: { cityName: "TUPANATINGA", uf: "PE" } },
  { name: "ITAIBA", value: { cityName: "ITAIBA", uf: "PE" } },
  { name: "INAJA", value: { cityName: "INAJA", uf: "PE" } },
  { name: "IBIMIRIM", value: { cityName: "IBIMIRIM", uf: "PE" } },
  { name: "SERTANIA", value: { cityName: "SERTANIA", uf: "PE" } },
  { name: "CUSTODIA", value: { cityName: "CUSTODIA", uf: "PE" } },
  { name: "BETANIA", value: { cityName: "BETANIA", uf: "PE" } },
  {
    name: "SAO JOSE DO EGITO",
    value: { cityName: "SAO JOSE DO EGITO", uf: "PE" },
  },
  { name: "ITAPETIM", value: { cityName: "ITAPETIM", uf: "PE" } },
  { name: "TUPARETAMA", value: { cityName: "TUPARETAMA", uf: "PE" } },
  { name: "TABIRA", value: { cityName: "TABIRA", uf: "PE" } },
  { name: "SOLIDAO", value: { cityName: "SOLIDAO", uf: "PE" } },
  {
    name: "AFOGADOS DA INGAZEIRA",
    value: { cityName: "AFOGADOS DA INGAZEIRA", uf: "PE" },
  },
  { name: "CARNAIBA", value: { cityName: "CARNAIBA", uf: "PE" } },
  { name: "IGUARACI", value: { cityName: "IGUARACI", uf: "PE" } },
  { name: "FLORES", value: { cityName: "FLORES", uf: "PE" } },
  { name: "SERRA TALHADA", value: { cityName: "SERRA TALHADA", uf: "PE" } },
  { name: "CALUMBI", value: { cityName: "CALUMBI", uf: "PE" } },
  {
    name: "SAO JOSE DO BELMONTE",
    value: { cityName: "SAO JOSE DO BELMONTE", uf: "PE" },
  },
  { name: "MACEIO", value: { cityName: "MACEIO", uf: "AL" } },
  { name: "RIO LARGO", value: { cityName: "RIO LARGO", uf: "AL" } },
  { name: "PILAR", value: { cityName: "PILAR", uf: "AL" } },
  {
    name: "MARECHAL DEODORO",
    value: { cityName: "MARECHAL DEODORO", uf: "AL" },
  },
  {
    name: "BARRA DE SAO MIGUEL",
    value: { cityName: "BARRA DE SAO MIGUEL", uf: "AL" },
  },
  { name: "PENEDO", value: { cityName: "PENEDO", uf: "AL" } },
  { name: "CORURIPE", value: { cityName: "CORURIPE", uf: "AL" } },
  {
    name: "SAO MIGUEL DOS CAMPOS",
    value: { cityName: "SAO MIGUEL DOS CAMPOS", uf: "AL" },
  },
  { name: "ROTEIRO", value: { cityName: "ROTEIRO", uf: "AL" } },
  {
    name: "LIMOEIRO DE ANADIA",
    value: { cityName: "LIMOEIRO DE ANADIA", uf: "AL" },
  },
  { name: "JUNQUEIRO", value: { cityName: "JUNQUEIRO", uf: "AL" } },
  { name: "IGREJA NOVA", value: { cityName: "IGREJA NOVA", uf: "AL" } },
  { name: "ARAPIRACA", value: { cityName: "ARAPIRACA", uf: "AL" } },
  { name: "CRAIBAS", value: { cityName: "CRAIBAS", uf: "AL" } },
  { name: "LAGOA DA CANOA", value: { cityName: "LAGOA DA CANOA", uf: "AL" } },
  { name: "TRAIPU", value: { cityName: "TRAIPU", uf: "AL" } },
  { name: "SAO BRAS", value: { cityName: "SAO BRAS", uf: "AL" } },
  {
    name: "OLHO D AGUA GRANDE",
    value: { cityName: "OLHO D AGUA GRANDE", uf: "AL" },
  },
  { name: "PAO DE ACUCAR", value: { cityName: "PAO DE ACUCAR", uf: "AL" } },
  { name: "BATALHA", value: { cityName: "BATALHA", uf: "AL" } },
  {
    name: "SAO JOSE DA TAPERA",
    value: { cityName: "SAO JOSE DA TAPERA", uf: "AL" },
  },
  { name: "DELMIRO GOUVEIA", value: { cityName: "DELMIRO GOUVEIA", uf: "AL" } },
  { name: "AGUA BRANCA", value: { cityName: "AGUA BRANCA", uf: "AL" } },
  {
    name: "SANTANA DO IPANEMA",
    value: { cityName: "SANTANA DO IPANEMA", uf: "AL" },
  },
  { name: "MATA GRANDE", value: { cityName: "MATA GRANDE", uf: "AL" } },
  { name: "CACIMBINHAS", value: { cityName: "CACIMBINHAS", uf: "AL" } },
  { name: "MAJOR ISIDORO", value: { cityName: "MAJOR ISIDORO", uf: "AL" } },
  {
    name: "PALMEIRA DOS INDIOS",
    value: { cityName: "PALMEIRA DOS INDIOS", uf: "AL" },
  },
  {
    name: "MINADOR DO NEGRAO",
    value: { cityName: "MINADOR DO NEGRAO", uf: "AL" },
  },
  { name: "TANQUE DARCA", value: { cityName: "TANQUE DARCA", uf: "AL" } },
  { name: "TAQUARANA", value: { cityName: "TAQUARANA", uf: "AL" } },
  { name: "ANADIA", value: { cityName: "ANADIA", uf: "AL" } },
  { name: "MARIBONDO", value: { cityName: "MARIBONDO", uf: "AL" } },
  { name: "PINDOBA", value: { cityName: "PINDOBA", uf: "AL" } },
  { name: "MAR VERMELHO", value: { cityName: "MAR VERMELHO", uf: "AL" } },
  { name: "PAULO JACINTO", value: { cityName: "PAULO JACINTO", uf: "AL" } },
  { name: "QUEBRANGULO", value: { cityName: "QUEBRANGULO", uf: "AL" } },
  { name: "CHA PRETA", value: { cityName: "CHA PRETA", uf: "AL" } },
  {
    name: "UNIAO DOS PALMARES",
    value: { cityName: "UNIAO DOS PALMARES", uf: "AL" },
  },
  { name: "MURICI", value: { cityName: "MURICI", uf: "AL" } },
  { name: "BRANQUINHA", value: { cityName: "BRANQUINHA", uf: "AL" } },
  {
    name: "SAO JOSE DA LAJE",
    value: { cityName: "SAO JOSE DA LAJE", uf: "AL" },
  },
  { name: "IBATEGUARA", value: { cityName: "IBATEGUARA", uf: "AL" } },
  { name: "PORTO CALVO", value: { cityName: "PORTO CALVO", uf: "AL" } },
  { name: "JAPARATINGA", value: { cityName: "JAPARATINGA", uf: "AL" } },
  { name: "JUNDIA", value: { cityName: "JUNDIA", uf: "AL" } },
  { name: "FLEXEIRAS", value: { cityName: "FLEXEIRAS", uf: "AL" } },
  { name: "CAMPINA GRANDE", value: { cityName: "CAMPINA GRANDE", uf: "PB" } },
  { name: "PUXINANA", value: { cityName: "PUXINANA", uf: "PB" } },
  {
    name: "SAO SEBASTIAO DE LAGOA DE ROCA",
    value: { cityName: "SAO SEBASTIAO DE LAGOA DE ROCA", uf: "PB" },
  },
  { name: "ALAGOA NOVA", value: { cityName: "ALAGOA NOVA", uf: "PB" } },
  { name: "ESPERANCA", value: { cityName: "ESPERANCA", uf: "PB" } },
  { name: "MONTADAS", value: { cityName: "MONTADAS", uf: "PB" } },
  { name: "CUBATI", value: { cityName: "CUBATI", uf: "PB" } },
  {
    name: "BARRA DE SANTA ROSA",
    value: { cityName: "BARRA DE SANTA ROSA", uf: "PB" },
  },
  { name: "NOVA FLORESTA", value: { cityName: "NOVA FLORESTA", uf: "PB" } },
  { name: "PEDRA LAVRADA", value: { cityName: "PEDRA LAVRADA", uf: "PB" } },
  { name: "FREI MARTINHO", value: { cityName: "FREI MARTINHO", uf: "PB" } },
  { name: "GUARABIRA", value: { cityName: "GUARABIRA", uf: "PB" } },
  { name: "PIRPIRITUBA", value: { cityName: "PIRPIRITUBA", uf: "PB" } },
  { name: "SOLANEA", value: { cityName: "SOLANEA", uf: "PB" } },
  { name: "DONA INES", value: { cityName: "DONA INES", uf: "PB" } },
  { name: "ARARUNA", value: { cityName: "ARARUNA", uf: "PB" } },
  { name: "TACIMA", value: { cityName: "TACIMA", uf: "PB" } },
  { name: "DUAS ESTRADAS", value: { cityName: "DUAS ESTRADAS", uf: "PB" } },
  { name: "MAMANGUAPE", value: { cityName: "MAMANGUAPE", uf: "PB" } },
  { name: "RIO TINTO", value: { cityName: "RIO TINTO", uf: "PB" } },
  { name: "BAYEUX", value: { cityName: "BAYEUX", uf: "PB" } },
  { name: "CABEDELO", value: { cityName: "CABEDELO", uf: "PB" } },
  { name: "PITIMBU", value: { cityName: "PITIMBU", uf: "PB" } },
  { name: "CAAPORA", value: { cityName: "CAAPORA", uf: "PB" } },
  { name: "PEDRAS DE FOGO", value: { cityName: "PEDRAS DE FOGO", uf: "PB" } },
  { name: "JURIPIRANGA", value: { cityName: "JURIPIRANGA", uf: "PB" } },
  {
    name: "CRUZ DO ESPIRITO SANTO",
    value: { cityName: "CRUZ DO ESPIRITO SANTO", uf: "PB" },
  },
  { name: "SAPE", value: { cityName: "SAPE", uf: "PB" } },
  { name: "MARI", value: { cityName: "MARI", uf: "PB" } },
  { name: "GURINHEM", value: { cityName: "GURINHEM", uf: "PB" } },
  { name: "MOGEIRO", value: { cityName: "MOGEIRO", uf: "PB" } },
  { name: "ITATUBA", value: { cityName: "ITATUBA", uf: "PB" } },
  { name: "SERRA REDONDA", value: { cityName: "SERRA REDONDA", uf: "PB" } },
  { name: "JUAREZ TAVORA", value: { cityName: "JUAREZ TAVORA", uf: "PB" } },
  { name: "ALAGOA GRANDE", value: { cityName: "ALAGOA GRANDE", uf: "PB" } },
  { name: "SERRARIA", value: { cityName: "SERRARIA", uf: "PB" } },
  { name: "ARARA", value: { cityName: "ARARA", uf: "PB" } },
  { name: "AREIA", value: { cityName: "AREIA", uf: "PB" } },
  { name: "REMIGIO", value: { cityName: "REMIGIO", uf: "PB" } },
  { name: "AROEIRAS", value: { cityName: "AROEIRAS", uf: "PB" } },
  { name: "NATUBA", value: { cityName: "NATUBA", uf: "PB" } },
  { name: "UMBUZEIRO", value: { cityName: "UMBUZEIRO", uf: "PB" } },
  { name: "FAGUNDES", value: { cityName: "FAGUNDES", uf: "PB" } },
  { name: "BOQUEIRAO", value: { cityName: "BOQUEIRAO", uf: "PB" } },
  { name: "CABACEIRAS", value: { cityName: "CABACEIRAS", uf: "PB" } },
  { name: "MONTEIRO", value: { cityName: "MONTEIRO", uf: "PB" } },
  {
    name: "SAO JOAO DO TIGRE",
    value: { cityName: "SAO JOAO DO TIGRE", uf: "PB" },
  },
  { name: "CAMALAU", value: { cityName: "CAMALAU", uf: "PB" } },
  { name: "SUME", value: { cityName: "SUME", uf: "PB" } },
  { name: "OURO VELHO", value: { cityName: "OURO VELHO", uf: "PB" } },
  { name: "SERRA BRANCA", value: { cityName: "SERRA BRANCA", uf: "PB" } },
  {
    name: "SAO JOAO DO CARIRI",
    value: { cityName: "SAO JOAO DO CARIRI", uf: "PB" },
  },
  {
    name: "SAO JOSE DO SABUGI",
    value: { cityName: "SAO JOSE DO SABUGI", uf: "PB" },
  },
  { name: "VARZEA", value: { cityName: "VARZEA", uf: "PB" } },
  { name: "SAO MAMEDE", value: { cityName: "SAO MAMEDE", uf: "PB" } },
  { name: "JUAZEIRINHO", value: { cityName: "JUAZEIRINHO", uf: "PB" } },
  { name: "LIVRAMENTO", value: { cityName: "LIVRAMENTO", uf: "PB" } },
  { name: "PATOS", value: { cityName: "PATOS", uf: "PB" } },
  { name: "MALTA", value: { cityName: "MALTA", uf: "PB" } },
  {
    name: "SAO JOSE DO BONFIM",
    value: { cityName: "SAO JOSE DO BONFIM", uf: "PB" },
  },
  {
    name: "CACIMBA DE AREIA",
    value: { cityName: "CACIMBA DE AREIA", uf: "PB" },
  },
  { name: "PASSAGEM", value: { cityName: "PASSAGEM", uf: "PB" } },
  { name: "JURU", value: { cityName: "JURU", uf: "PB" } },
  { name: "PRINCESA ISABEL", value: { cityName: "PRINCESA ISABEL", uf: "PB" } },
  { name: "PIANCO", value: { cityName: "PIANCO", uf: "PB" } },
  { name: "COREMAS", value: { cityName: "COREMAS", uf: "PB" } },
  { name: "AGUIAR", value: { cityName: "AGUIAR", uf: "PB" } },
  {
    name: "SAO JOSE DE CAIANA",
    value: { cityName: "SAO JOSE DE CAIANA", uf: "PB" },
  },
  { name: "SOUSA", value: { cityName: "SOUSA", uf: "PB" } },
  { name: "LASTRO", value: { cityName: "LASTRO", uf: "PB" } },
  { name: "JERICO", value: { cityName: "JERICO", uf: "PB" } },
  { name: "LAGOA", value: { cityName: "LAGOA", uf: "PB" } },
  { name: "POMBAL", value: { cityName: "POMBAL", uf: "PB" } },
  { name: "CATOLE DO ROCHA", value: { cityName: "CATOLE DO ROCHA", uf: "PB" } },
  {
    name: "BELEM DO BREJO DO CRUZ",
    value: { cityName: "BELEM DO BREJO DO CRUZ", uf: "PB" },
  },
  { name: "CAJAZEIRAS", value: { cityName: "CAJAZEIRAS", uf: "PB" } },
  {
    name: "SAO JOAO DO RIO DO PEIXE",
    value: { cityName: "SAO JOAO DO RIO DO PEIXE", uf: "PB" },
  },
  {
    name: "SAO JOSE DE PIRANHAS",
    value: { cityName: "SAO JOSE DE PIRANHAS", uf: "PB" },
  },
  { name: "SERRA GRANDE", value: { cityName: "SERRA GRANDE", uf: "PB" } },
  {
    name: "BONITO DE SANTA FE",
    value: { cityName: "BONITO DE SANTA FE", uf: "PB" },
  },
  { name: "CONCEICAO", value: { cityName: "CONCEICAO", uf: "PB" } },
  { name: "IBIARA", value: { cityName: "IBIARA", uf: "PB" } },
  { name: "DIAMANTE", value: { cityName: "DIAMANTE", uf: "PB" } },
  { name: "MANAIRA", value: { cityName: "MANAIRA", uf: "PB" } },
  { name: "NOVA CRUZ", value: { cityName: "NOVA CRUZ", uf: "RN" } },
  {
    name: "SAO JOSE DO CAMPESTRE",
    value: { cityName: "SAO JOSE DO CAMPESTRE", uf: "RN" },
  },
  { name: "LAGOA DE PEDRAS", value: { cityName: "LAGOA DE PEDRAS", uf: "RN" } },
  { name: "MONTE ALEGRE", value: { cityName: "MONTE ALEGRE", uf: "RN" } },
  {
    name: "SAO JOSE DE MIPIBU",
    value: { cityName: "SAO JOSE DE MIPIBU", uf: "RN" },
  },
  { name: "NISIA FLORESTA", value: { cityName: "NISIA FLORESTA", uf: "RN" } },
  { name: "GOIANINHA", value: { cityName: "GOIANINHA", uf: "RN" } },
  { name: "CANGUARETAMA", value: { cityName: "CANGUARETAMA", uf: "RN" } },
  { name: "VILA FLOR", value: { cityName: "VILA FLOR", uf: "RN" } },
  { name: "JACANA", value: { cityName: "JACANA", uf: "RN" } },
  { name: "CAMPO REDONDO", value: { cityName: "CAMPO REDONDO", uf: "RN" } },
  { name: "SERRA CAIADA", value: { cityName: "SERRA CAIADA", uf: "RN" } },
  { name: "MACAIBA", value: { cityName: "MACAIBA", uf: "RN" } },
  { name: "CAICO", value: { cityName: "CAICO", uf: "RN" } },
  { name: "SAO FERNANDO", value: { cityName: "SAO FERNANDO", uf: "RN" } },
  { name: "JUCURUTU", value: { cityName: "JUCURUTU", uf: "RN" } },
  { name: "FLORANIA", value: { cityName: "FLORANIA", uf: "RN" } },
  { name: "ACARI", value: { cityName: "ACARI", uf: "RN" } },
  {
    name: "CARNAUBA DOS DANTAS",
    value: { cityName: "CARNAUBA DOS DANTAS", uf: "RN" },
  },
  { name: "CRUZETA", value: { cityName: "CRUZETA", uf: "RN" } },
  {
    name: "SAO JOSE DO SERIDO",
    value: { cityName: "SAO JOSE DO SERIDO", uf: "RN" },
  },
  { name: "CURRAIS NOVOS", value: { cityName: "CURRAIS NOVOS", uf: "RN" } },
  { name: "LAGOA NOVA", value: { cityName: "LAGOA NOVA", uf: "RN" } },
  { name: "SAO TOME", value: { cityName: "SAO TOME", uf: "RN" } },
  { name: "BARCELONA", value: { cityName: "BARCELONA", uf: "RN" } },
  { name: "SITIO NOVO", value: { cityName: "SITIO NOVO", uf: "RN" } },
  { name: "IELMO MARINHO", value: { cityName: "IELMO MARINHO", uf: "RN" } },
  { name: "MACAU", value: { cityName: "MACAU", uf: "RN" } },
  { name: "PENDENCIAS", value: { cityName: "PENDENCIAS", uf: "RN" } },
  {
    name: "ALTO DO RODRIGUES",
    value: { cityName: "ALTO DO RODRIGUES", uf: "RN" },
  },
  { name: "IPANGUACU", value: { cityName: "IPANGUACU", uf: "RN" } },
  { name: "ANGICOS", value: { cityName: "ANGICOS", uf: "RN" } },
  {
    name: "SANTANA DO MATOS",
    value: { cityName: "SANTANA DO MATOS", uf: "RN" },
  },
  { name: "LAJES", value: { cityName: "LAJES", uf: "RN" } },
  { name: "JOAO CAMARA", value: { cityName: "JOAO CAMARA", uf: "RN" } },
  { name: "TAIPU", value: { cityName: "TAIPU", uf: "RN" } },
  { name: "CEARA-MIRIM", value: { cityName: "CEARA-MIRIM", uf: "RN" } },
  { name: "EXTREMOZ", value: { cityName: "EXTREMOZ", uf: "RN" } },
  { name: "PEDRA GRANDE", value: { cityName: "PEDRA GRANDE", uf: "RN" } },
  {
    name: "SAO BENTO DO NORTE",
    value: { cityName: "SAO BENTO DO NORTE", uf: "RN" },
  },
  { name: "GUAMARE", value: { cityName: "GUAMARE", uf: "RN" } },
  { name: "MOSSORO", value: { cityName: "MOSSORO", uf: "RN" } },
  { name: "BARAUNA", value: { cityName: "BARAUNA", uf: "RN" } },
  { name: "GROSSOS", value: { cityName: "GROSSOS", uf: "RN" } },
  { name: "SERRA DO MEL", value: { cityName: "SERRA DO MEL", uf: "RN" } },
  { name: "APODI", value: { cityName: "APODI", uf: "RN" } },
  { name: "PATU", value: { cityName: "PATU", uf: "RN" } },
  { name: "CARAUBAS", value: { cityName: "CARAUBAS", uf: "RN" } },
  { name: "MARTINS", value: { cityName: "MARTINS", uf: "RN" } },
  { name: "PORTALEGRE", value: { cityName: "PORTALEGRE", uf: "RN" } },
  {
    name: "RODOLFO FERNANDES",
    value: { cityName: "RODOLFO FERNANDES", uf: "RN" },
  },
  { name: "ITAU", value: { cityName: "ITAU", uf: "RN" } },
  { name: "JOAO DIAS", value: { cityName: "JOAO DIAS", uf: "RN" } },
  { name: "PAU DOS FERROS", value: { cityName: "PAU DOS FERROS", uf: "RN" } },
  { name: "ENCANTO", value: { cityName: "ENCANTO", uf: "RN" } },
  { name: "SAO MIGUEL", value: { cityName: "SAO MIGUEL", uf: "RN" } },
  { name: "TENENTE ANANIAS", value: { cityName: "TENENTE ANANIAS", uf: "RN" } },
  { name: "ALEXANDRIA", value: { cityName: "ALEXANDRIA", uf: "RN" } },
  {
    name: "MARCELINO VIEIRA",
    value: { cityName: "MARCELINO VIEIRA", uf: "RN" },
  },
  { name: "JOSE DA PENHA", value: { cityName: "JOSE DA PENHA", uf: "RN" } },
  {
    name: "RAFAEL FERNANDES",
    value: { cityName: "RAFAEL FERNANDES", uf: "RN" },
  },
  { name: "AGUA NOVA", value: { cityName: "AGUA NOVA", uf: "RN" } },
  { name: "PACUJA", value: { cityName: "PACUJA", uf: "CE" } },
  { name: "VICOSA DO CEARA", value: { cityName: "VICOSA DO CEARA", uf: "CE" } },
  { name: "PALHANO", value: { cityName: "PALHANO", uf: "CE" } },
  { name: "PARAMBU", value: { cityName: "PARAMBU", uf: "CE" } },
  { name: "BARRAS", value: { cityName: "BARRAS", uf: "PI" } },
  { name: "JOSE DE FREITAS", value: { cityName: "JOSE DE FREITAS", uf: "PI" } },
  { name: "UNIAO", value: { cityName: "UNIAO", uf: "PI" } },
  { name: "MIGUEL ALVES", value: { cityName: "MIGUEL ALVES", uf: "PI" } },
  {
    name: "NOSSA SENHORA DOS REMEDIOS",
    value: { cityName: "NOSSA SENHORA DOS REMEDIOS", uf: "PI" },
  },
  { name: "PORTO", value: { cityName: "PORTO", uf: "PI" } },
  { name: "LUZILANDIA", value: { cityName: "LUZILANDIA", uf: "PI" } },
  { name: "PARNAIBA", value: { cityName: "PARNAIBA", uf: "PI" } },
  { name: "LUIS CORREIA", value: { cityName: "LUIS CORREIA", uf: "PI" } },
  { name: "COCAL", value: { cityName: "COCAL", uf: "PI" } },
  { name: "PIRACURUCA", value: { cityName: "PIRACURUCA", uf: "PI" } },
  { name: "PEDRO II", value: { cityName: "PEDRO II", uf: "PI" } },
  { name: "PIRIPIRI", value: { cityName: "PIRIPIRI", uf: "PI" } },
  { name: "CAMPO MAIOR", value: { cityName: "CAMPO MAIOR", uf: "PI" } },
  { name: "ALTOS", value: { cityName: "ALTOS", uf: "PI" } },
  {
    name: "VALENCA DO PIAUI",
    value: { cityName: "VALENCA DO PIAUI", uf: "PI" },
  },
  { name: "AROAZES", value: { cityName: "AROAZES", uf: "PI" } },
  { name: "PIMENTEIRAS", value: { cityName: "PIMENTEIRAS", uf: "PI" } },
  { name: "AMARANTE", value: { cityName: "AMARANTE", uf: "PI" } },
  { name: "FRANCISCO AYRES", value: { cityName: "FRANCISCO AYRES", uf: "PI" } },
  {
    name: "ANGICAL DO PIAUI",
    value: { cityName: "ANGICAL DO PIAUI", uf: "PI" },
  },
  { name: "ARRAIAL", value: { cityName: "ARRAIAL", uf: "PI" } },
  { name: "OEIRAS", value: { cityName: "OEIRAS", uf: "PI" } },
  { name: "ELESBAO VELOSO", value: { cityName: "ELESBAO VELOSO", uf: "PI" } },
  { name: "FRANCINOPOLIS", value: { cityName: "FRANCINOPOLIS", uf: "PI" } },
  { name: "PICOS", value: { cityName: "PICOS", uf: "PI" } },
  {
    name: "SAO JOSE DO PIAUI",
    value: { cityName: "SAO JOSE DO PIAUI", uf: "PI" },
  },
  {
    name: "FRANCISCO SANTOS",
    value: { cityName: "FRANCISCO SANTOS", uf: "PI" },
  },
  { name: "SAO JULIAO", value: { cityName: "SAO JULIAO", uf: "PI" } },
  { name: "FRONTEIRAS", value: { cityName: "FRONTEIRAS", uf: "PI" } },
  { name: "SIMOES", value: { cityName: "SIMOES", uf: "PI" } },
  { name: "JAICOS", value: { cityName: "JAICOS", uf: "PI" } },
  { name: "ITAINOPOLIS", value: { cityName: "ITAINOPOLIS", uf: "PI" } },
  {
    name: "SIMPLICIO MENDES",
    value: { cityName: "SIMPLICIO MENDES", uf: "PI" },
  },
  { name: "PAES LANDIM", value: { cityName: "PAES LANDIM", uf: "PI" } },
  {
    name: "SOCORRO DO PIAUI",
    value: { cityName: "SOCORRO DO PIAUI", uf: "PI" },
  },
  {
    name: "CAMPINAS DO PIAUI",
    value: { cityName: "CAMPINAS DO PIAUI", uf: "PI" },
  },
  {
    name: "CONCEICAO DO CANINDE",
    value: { cityName: "CONCEICAO DO CANINDE", uf: "PI" },
  },
  { name: "PAULISTANA", value: { cityName: "PAULISTANA", uf: "PI" } },
  {
    name: "SAO JOAO DO PIAUI",
    value: { cityName: "SAO JOAO DO PIAUI", uf: "PI" },
  },
  { name: "ANISIO DE ABREU", value: { cityName: "ANISIO DE ABREU", uf: "PI" } },
  { name: "CARACOL", value: { cityName: "CARACOL", uf: "PI" } },
  { name: "FLORIANO", value: { cityName: "FLORIANO", uf: "PI" } },
  { name: "FLORES DO PIAUI", value: { cityName: "FLORES DO PIAUI", uf: "PI" } },
  { name: "CANTO DO BURITI", value: { cityName: "CANTO DO BURITI", uf: "PI" } },
  { name: "NAZARE DO PIAUI", value: { cityName: "NAZARE DO PIAUI", uf: "PI" } },
  { name: "CANAVIEIRA", value: { cityName: "CANAVIEIRA", uf: "PI" } },
  {
    name: "SAO FRANCISCO DO PIAUI",
    value: { cityName: "SAO FRANCISCO DO PIAUI", uf: "PI" },
  },
  { name: "ANTONIO ALMEIDA", value: { cityName: "ANTONIO ALMEIDA", uf: "PI" } },
  { name: "URUCUI", value: { cityName: "URUCUI", uf: "PI" } },
  { name: "MANOEL EMIDIO", value: { cityName: "MANOEL EMIDIO", uf: "PI" } },
  { name: "SANTA LUZ", value: { cityName: "SANTA LUZ", uf: "PI" } },
  {
    name: "PALMEIRA DO PIAUI",
    value: { cityName: "PALMEIRA DO PIAUI", uf: "PI" },
  },
  {
    name: "REDENCAO DO GURGUEIA",
    value: { cityName: "REDENCAO DO GURGUEIA", uf: "PI" },
  },
  { name: "SANTA FILOMENA", value: { cityName: "SANTA FILOMENA", uf: "PI" } },
  { name: "GILBUES", value: { cityName: "GILBUES", uf: "PI" } },
  { name: "CURIMATA", value: { cityName: "CURIMATA", uf: "PI" } },
  { name: "AVELINO LOPES", value: { cityName: "AVELINO LOPES", uf: "PI" } },
  { name: "PARNAGUA", value: { cityName: "PARNAGUA", uf: "PI" } },
  { name: "CORRENTE", value: { cityName: "CORRENTE", uf: "PI" } },
  { name: "ROSARIO", value: { cityName: "ROSARIO", uf: "MA" } },
  {
    name: "SAO JOSE DE RIBAMAR",
    value: { cityName: "SAO JOSE DE RIBAMAR", uf: "MA" },
  },
  { name: "PACO DO LUMIAR", value: { cityName: "PACO DO LUMIAR", uf: "MA" } },
  { name: "ICATU", value: { cityName: "ICATU", uf: "MA" } },
  { name: "PINHEIRO", value: { cityName: "PINHEIRO", uf: "MA" } },
  { name: "PENALVA", value: { cityName: "PENALVA", uf: "MA" } },
  { name: "CAJAPIO", value: { cityName: "CAJAPIO", uf: "MA" } },
  { name: "PERI MIRIM", value: { cityName: "PERI MIRIM", uf: "MA" } },
  { name: "JEQUIRICA", value: { cityName: "JEQUIRICA", uf: "BA" } },
  { name: "ALCANTARA", value: { cityName: "ALCANTARA", uf: "MA" } },
  { name: "CURURUPU", value: { cityName: "CURURUPU", uf: "MA" } },
  { name: "BACURI", value: { cityName: "BACURI", uf: "MA" } },
  { name: "LUIS DOMINGUES", value: { cityName: "LUIS DOMINGUES", uf: "MA" } },
  { name: "CARUTAPERA", value: { cityName: "CARUTAPERA", uf: "MA" } },
  { name: "VITORINO FREIRE", value: { cityName: "VITORINO FREIRE", uf: "MA" } },
  {
    name: "VITORIA DO MEARIM",
    value: { cityName: "VITORIA DO MEARIM", uf: "MA" },
  },
  { name: "MONCAO", value: { cityName: "MONCAO", uf: "MA" } },
  { name: "PINDARE MIRIM", value: { cityName: "PINDARE MIRIM", uf: "MA" } },
  { name: "VARGEM GRANDE", value: { cityName: "VARGEM GRANDE", uf: "MA" } },
  { name: "NINA RODRIGUES", value: { cityName: "NINA RODRIGUES", uf: "MA" } },
  { name: "PIRAPEMAS", value: { cityName: "PIRAPEMAS", uf: "MA" } },
  { name: "CANTANHEDE", value: { cityName: "CANTANHEDE", uf: "MA" } },
  {
    name: "SAO MATEUS DO MARANHAO",
    value: { cityName: "SAO MATEUS DO MARANHAO", uf: "MA" },
  },
  { name: "ARARI", value: { cityName: "ARARI", uf: "MA" } },
  {
    name: "MIRANDA DO NORTE",
    value: { cityName: "MIRANDA DO NORTE", uf: "MA" },
  },
  { name: "BURITI", value: { cityName: "BURITI", uf: "MA" } },
  { name: "BREJO", value: { cityName: "BREJO", uf: "MA" } },
  { name: "URBANO SANTOS", value: { cityName: "URBANO SANTOS", uf: "MA" } },
  {
    name: "SANTA QUITERIA DO MARANHAO",
    value: { cityName: "SANTA QUITERIA DO MARANHAO", uf: "MA" },
  },
  { name: "SAO BERNARDO", value: { cityName: "SAO BERNARDO", uf: "MA" } },
  {
    name: "MAGALHAES DE ALMEIDA",
    value: { cityName: "MAGALHAES DE ALMEIDA", uf: "MA" },
  },
  { name: "ARAIOSES", value: { cityName: "ARAIOSES", uf: "MA" } },
  { name: "TUTOIA", value: { cityName: "TUTOIA", uf: "MA" } },
  { name: "CAXIAS", value: { cityName: "CAXIAS", uf: "MA" } },
  { name: "COELHO NETO", value: { cityName: "COELHO NETO", uf: "MA" } },
  { name: "DUQUE BACELAR", value: { cityName: "DUQUE BACELAR", uf: "MA" } },
  { name: "TIMON", value: { cityName: "TIMON", uf: "MA" } },
  {
    name: "SAO FRANCISCO DO MARANHAO",
    value: { cityName: "SAO FRANCISCO DO MARANHAO", uf: "MA" },
  },
  { name: "BARAO DE GRAJAU", value: { cityName: "BARAO DE GRAJAU", uf: "MA" } },
  {
    name: "SAO JOAO DOS PATOS",
    value: { cityName: "SAO JOAO DOS PATOS", uf: "MA" },
  },
  { name: "PARAIBANO", value: { cityName: "PARAIBANO", uf: "MA" } },
  { name: "FORTUNA", value: { cityName: "FORTUNA", uf: "MA" } },
  {
    name: "SAO LUIS GONZAGA DO MARANHAO",
    value: { cityName: "SAO LUIS GONZAGA DO MARANHAO", uf: "MA" },
  },
  { name: "LAGO DA PEDRA", value: { cityName: "LAGO DA PEDRA", uf: "MA" } },
  { name: "PAULO RAMOS", value: { cityName: "PAULO RAMOS", uf: "MA" } },
  { name: "IGARAPE GRANDE", value: { cityName: "IGARAPE GRANDE", uf: "MA" } },
  { name: "PEDREIRAS", value: { cityName: "PEDREIRAS", uf: "MA" } },
  { name: "LIMA CAMPOS", value: { cityName: "LIMA CAMPOS", uf: "MA" } },
  { name: "POCAO DE PEDRAS", value: { cityName: "POCAO DE PEDRAS", uf: "MA" } },
  {
    name: "ESPERANTINOPOLIS",
    value: { cityName: "ESPERANTINOPOLIS", uf: "MA" },
  },
  { name: "DOM PEDRO", value: { cityName: "DOM PEDRO", uf: "MA" } },
  {
    name: "GOVERNADOR ARCHER",
    value: { cityName: "GOVERNADOR ARCHER", uf: "MA" },
  },
  { name: "BALSAS", value: { cityName: "BALSAS", uf: "MA" } },
  { name: "ALTO PARNAIBA", value: { cityName: "ALTO PARNAIBA", uf: "MA" } },
  {
    name: "SAO RAIMUNDO DAS MANGABEIRAS",
    value: { cityName: "SAO RAIMUNDO DAS MANGABEIRAS", uf: "MA" },
  },
  {
    name: "SUCUPIRA DO NORTE",
    value: { cityName: "SUCUPIRA DO NORTE", uf: "MA" },
  },
  {
    name: "SAO FELIX DE BALSAS",
    value: { cityName: "SAO FELIX DE BALSAS", uf: "MA" },
  },
  { name: "IMPERATRIZ", value: { cityName: "IMPERATRIZ", uf: "MA" } },
  { name: "GRAJAU", value: { cityName: "GRAJAU", uf: "MA" } },
  { name: "ESTREITO", value: { cityName: "ESTREITO", uf: "MA" } },
  { name: "CAROLINA", value: { cityName: "CAROLINA", uf: "MA" } },
  { name: "RIACHAO", value: { cityName: "RIACHAO", uf: "MA" } },
  { name: "ANANINDEUA", value: { cityName: "ANANINDEUA", uf: "PA" } },
  { name: "SANTAREM", value: { cityName: "SANTAREM", uf: "PA" } },
  { name: "PRAINHA", value: { cityName: "PRAINHA", uf: "PA" } },
  { name: "BRASIL NOVO", value: { cityName: "BRASIL NOVO", uf: "PA" } },
  { name: "AVEIRO", value: { cityName: "AVEIRO", uf: "PA" } },
  { name: "JURUTI", value: { cityName: "JURUTI", uf: "PA" } },
  { name: "ALENQUER", value: { cityName: "ALENQUER", uf: "PA" } },
  { name: "OBIDOS", value: { cityName: "OBIDOS", uf: "PA" } },
  { name: "ORIXIMINA", value: { cityName: "ORIXIMINA", uf: "PA" } },
  { name: "FARO", value: { cityName: "FARO", uf: "PA" } },
  { name: "GURUPA", value: { cityName: "GURUPA", uf: "PA" } },
  { name: "ALTAMIRA", value: { cityName: "ALTAMIRA", uf: "PA" } },
  { name: "CAMETA", value: { cityName: "CAMETA", uf: "PA" } },
  { name: "MOCAJUBA", value: { cityName: "MOCAJUBA", uf: "PA" } },
  { name: "IGARAPE-MIRI", value: { cityName: "IGARAPE-MIRI", uf: "PA" } },
  { name: "ABAETETUBA", value: { cityName: "ABAETETUBA", uf: "PA" } },
  { name: "TUCURUI", value: { cityName: "TUCURUI", uf: "PA" } },
  { name: "BAIAO", value: { cityName: "BAIAO", uf: "PA" } },
  { name: "MELGACO", value: { cityName: "MELGACO", uf: "PA" } },
  { name: "MARABA", value: { cityName: "MARABA", uf: "PA" } },
  {
    name: "SAO DOMINGOS DO ARAGUAIA",
    value: { cityName: "SAO DOMINGOS DO ARAGUAIA", uf: "PA" },
  },
  {
    name: "SAO GERALDO DO ARAGUAIA",
    value: { cityName: "SAO GERALDO DO ARAGUAIA", uf: "PA" },
  },
  { name: "ITUPIRANGA", value: { cityName: "ITUPIRANGA", uf: "PA" } },
  { name: "JACUNDA", value: { cityName: "JACUNDA", uf: "PA" } },
  { name: "AUGUSTO CORREA", value: { cityName: "AUGUSTO CORREA", uf: "PA" } },
  { name: "VISEU", value: { cityName: "VISEU", uf: "PA" } },
  { name: "PARAGOMINAS", value: { cityName: "PARAGOMINAS", uf: "PA" } },
  { name: "RONDON DO PARA", value: { cityName: "RONDON DO PARA", uf: "PA" } },
  { name: "OUREM", value: { cityName: "OUREM", uf: "PA" } },
  { name: "CAPITAO POCO", value: { cityName: "CAPITAO POCO", uf: "PA" } },
  {
    name: "SAO MIGUEL DO GUAMA",
    value: { cityName: "SAO MIGUEL DO GUAMA", uf: "PA" },
  },
  {
    name: "GARRAFAO DO NORTE",
    value: { cityName: "GARRAFAO DO NORTE", uf: "PA" },
  },
  { name: "BUJARU", value: { cityName: "BUJARU", uf: "PA" } },
  { name: "TOME-ACU", value: { cityName: "TOME-ACU", uf: "PA" } },
  { name: "ACARA", value: { cityName: "ACARA", uf: "PA" } },
  {
    name: "SAO JOAO DE PIRABAS",
    value: { cityName: "SAO JOAO DE PIRABAS", uf: "PA" },
  },
  { name: "SALINOPOLIS", value: { cityName: "SALINOPOLIS", uf: "PA" } },
  { name: "MARACANA", value: { cityName: "MARACANA", uf: "PA" } },
  { name: "NOVA TIMBOTEUA", value: { cityName: "NOVA TIMBOTEUA", uf: "PA" } },
  { name: "PEIXE-BOI", value: { cityName: "PEIXE-BOI", uf: "PA" } },
  { name: "CASTANHAL", value: { cityName: "CASTANHAL", uf: "PA" } },
  { name: "CURUCA", value: { cityName: "CURUCA", uf: "PA" } },
  { name: "TERRA ALTA", value: { cityName: "TERRA ALTA", uf: "PA" } },
  { name: "MARAPANIM", value: { cityName: "MARAPANIM", uf: "PA" } },
  {
    name: "SAO CAETANO DE ODIVELAS",
    value: { cityName: "SAO CAETANO DE ODIVELAS", uf: "PA" },
  },
  {
    name: "SANTO ANTONIO DO TAUA",
    value: { cityName: "SANTO ANTONIO DO TAUA", uf: "PA" },
  },
  { name: "BENEVIDES", value: { cityName: "BENEVIDES", uf: "PA" } },
  { name: "AFUA", value: { cityName: "AFUA", uf: "PA" } },
  { name: "PORTO GRANDE", value: { cityName: "PORTO GRANDE", uf: "AP" } },
  { name: "AMAPA", value: { cityName: "AMAPA", uf: "AP" } },
  { name: "OIAPOQUE", value: { cityName: "OIAPOQUE", uf: "AP" } },
  { name: "URUCARA", value: { cityName: "URUCARA", uf: "AM" } },
  { name: "NHAMUNDA", value: { cityName: "NHAMUNDA", uf: "AM" } },
  { name: "PARINTINS", value: { cityName: "PARINTINS", uf: "AM" } },
  { name: "BARREIRINHA", value: { cityName: "BARREIRINHA", uf: "AM" } },
  {
    name: "BOA VISTA DO RAMOS",
    value: { cityName: "BOA VISTA DO RAMOS", uf: "AM" },
  },
  { name: "AUTAZES", value: { cityName: "AUTAZES", uf: "AM" } },
  { name: "BOA VISTA", value: { cityName: "BOA VISTA", uf: "RR" } },
  { name: "SAO LUIZ", value: { cityName: "SAO LUIZ", uf: "RR" } },
  {
    name: "SAO JOAO DA BALIZA",
    value: { cityName: "SAO JOAO DA BALIZA", uf: "RR" },
  },
  { name: "NORMANDIA", value: { cityName: "NORMANDIA", uf: "RR" } },
  { name: "CAAPIRANGA", value: { cityName: "CAAPIRANGA", uf: "AM" } },
  { name: "ANORI", value: { cityName: "ANORI", uf: "AM" } },
  { name: "ANAMA", value: { cityName: "ANAMA", uf: "AM" } },
  { name: "TEFE", value: { cityName: "TEFE", uf: "AM" } },
  { name: "UARINI", value: { cityName: "UARINI", uf: "AM" } },
  { name: "CARAUARI", value: { cityName: "CARAUARI", uf: "AM" } },
  { name: "ITAMARATI", value: { cityName: "ITAMARATI", uf: "AM" } },
  {
    name: "BENJAMIN CONSTANT",
    value: { cityName: "BENJAMIN CONSTANT", uf: "AM" },
  },
  { name: "JUTAI", value: { cityName: "JUTAI", uf: "AM" } },
  { name: "FONTE BOA", value: { cityName: "FONTE BOA", uf: "AM" } },
  {
    name: "SAO GABRIEL DA CACHOEIRA",
    value: { cityName: "SAO GABRIEL DA CACHOEIRA", uf: "AM" },
  },
  { name: "LABREA", value: { cityName: "LABREA", uf: "AM" } },
  { name: "PORTO ACRE", value: { cityName: "PORTO ACRE", uf: "AC" } },
  { name: "BUJARI", value: { cityName: "BUJARI", uf: "AC" } },
  { name: "XAPURI", value: { cityName: "XAPURI", uf: "AC" } },
  { name: "BRASILEIA", value: { cityName: "BRASILEIA", uf: "AC" } },
  { name: "ASSIS BRASIL", value: { cityName: "ASSIS BRASIL", uf: "AC" } },
  { name: "SENA MADUREIRA", value: { cityName: "SENA MADUREIRA", uf: "AC" } },
  { name: "FEIJO", value: { cityName: "FEIJO", uf: "AC" } },
  { name: "TARAUACA", value: { cityName: "TARAUACA", uf: "AC" } },
  { name: "JORDAO", value: { cityName: "JORDAO", uf: "AC" } },
  { name: "CASCATA", value: { cityName: "CASCATA", uf: "RS" } },
  { name: "VILA REIS", value: { cityName: "VILA REIS", uf: "PR" } },
  { name: "CLEMENTE ARGOLO", value: { cityName: "CLEMENTE ARGOLO", uf: "RS" } },
  { name: "DUQUE DE CAXIAS", value: { cityName: "DUQUE DE CAXIAS", uf: "RJ" } },
  { name: "SAO JOSE", value: { cityName: "SAO JOSE", uf: "SC" } },
  { name: "CAMBORIU", value: { cityName: "CAMBORIU", uf: "SC" } },
  { name: "MINAS DO LEAO", value: { cityName: "MINAS DO LEAO", uf: "RS" } },
  { name: "SAO RAFAEL", value: { cityName: "SAO RAFAEL", uf: "RN" } },
  { name: "TAVARES", value: { cityName: "TAVARES", uf: "RS" } },
  { name: "LINDOLFO COLLOR", value: { cityName: "LINDOLFO COLLOR", uf: "RS" } },
  {
    name: "SANTO EXPEDITO DO SUL",
    value: { cityName: "SANTO EXPEDITO DO SUL", uf: "RS" },
  },
  { name: "SAO DOMINGOS", value: { cityName: "SAO DOMINGOS", uf: "GO" } },
  { name: "AGUA SANTA", value: { cityName: "AGUA SANTA", uf: "RS" } },
  {
    name: "NOVA ESPERANCA DO SUL",
    value: { cityName: "NOVA ESPERANCA DO SUL", uf: "RS" },
  },
  {
    name: "SAO PEDRO DAS MISSOES",
    value: { cityName: "SAO PEDRO DAS MISSOES", uf: "RS" },
  },
  { name: "ANTONINA", value: { cityName: "ANTONINA", uf: "PR" } },
  { name: "VILA FLORES", value: { cityName: "VILA FLORES", uf: "RS" } },
  { name: "GUABIJU", value: { cityName: "GUABIJU", uf: "RS" } },
  { name: "AUREA", value: { cityName: "AUREA", uf: "RS" } },
  { name: "RIOZINHO", value: { cityName: "RIOZINHO", uf: "RS" } },
  { name: "CHARRUA", value: { cityName: "CHARRUA", uf: "RS" } },
  { name: "PASSINHOS", value: { cityName: "PASSINHOS", uf: "RS" } },
  {
    name: "ENTRE RIOS DO SUL",
    value: { cityName: "ENTRE RIOS DO SUL", uf: "RS" },
  },
  { name: "TRES FORQUILHAS", value: { cityName: "TRES FORQUILHAS", uf: "RS" } },
  { name: "GRAMADO XAVIER", value: { cityName: "GRAMADO XAVIER", uf: "RS" } },
  { name: "PAVERAMA", value: { cityName: "PAVERAMA", uf: "RS" } },
  {
    name: "SAO JOAO DO POLESINE",
    value: { cityName: "SAO JOAO DO POLESINE", uf: "RS" },
  },
  { name: "IPE", value: { cityName: "IPE", uf: "RS" } },
  {
    name: "MARIANA PIMENTEL",
    value: { cityName: "MARIANA PIMENTEL", uf: "RS" },
  },
  { name: "MORMACO", value: { cityName: "MORMACO", uf: "RS" } },
  { name: "INHACORA", value: { cityName: "INHACORA", uf: "RS" } },
  { name: "BOZANO", value: { cityName: "BOZANO", uf: "RS" } },
  { name: "CURITIBANOS", value: { cityName: "CURITIBANOS", uf: "SC" } },
  { name: "ENEIDA", value: { cityName: "ENEIDA", uf: "SP" } },
  { name: "PORTO VERA CRUZ", value: { cityName: "PORTO VERA CRUZ", uf: "RS" } },
  { name: "XANXERE", value: { cityName: "XANXERE", uf: "SC" } },
  { name: "PALMA SOLA", value: { cityName: "PALMA SOLA", uf: "SC" } },
  { name: "RIACHUELO", value: { cityName: "RIACHUELO", uf: "SE" } },
  { name: "CORBELIA", value: { cityName: "CORBELIA", uf: "PR" } },
  { name: "MONDAI", value: { cityName: "MONDAI", uf: "SC" } },
  { name: "SERIO", value: { cityName: "SERIO", uf: "RS" } },
  { name: "MARIANO MORO", value: { cityName: "MARIANO MORO", uf: "RS" } },
  {
    name: "SAO JOSE DO HERVAL",
    value: { cityName: "SAO JOSE DO HERVAL", uf: "RS" },
  },
  {
    name: "DOUTOR MAURICIO CARDOSO",
    value: { cityName: "DOUTOR MAURICIO CARDOSO", uf: "RS" },
  },
  { name: "SEDE NOVA", value: { cityName: "SEDE NOVA", uf: "RS" } },
  {
    name: "LAJEADO DO BUGRE",
    value: { cityName: "LAJEADO DO BUGRE", uf: "RS" },
  },
  { name: "VALE DO SOL", value: { cityName: "VALE DO SOL", uf: "RS" } },
  {
    name: "SEVERIANO DE ALMEIDA",
    value: { cityName: "SEVERIANO DE ALMEIDA", uf: "RS" },
  },
  {
    name: "SAO PEDRO DO BUTIA",
    value: { cityName: "SAO PEDRO DO BUTIA", uf: "RS" },
  },
  { name: "MANDAGUARI", value: { cityName: "MANDAGUARI", uf: "PR" } },
  { name: "MOREIRA SALES", value: { cityName: "MOREIRA SALES", uf: "PR" } },
  { name: "JAPURA", value: { cityName: "JAPURA", uf: "PR" } },
  { name: "ATALAIA", value: { cityName: "ATALAIA", uf: "PR" } },
  {
    name: "PASSO DO SOBRADO",
    value: { cityName: "PASSO DO SOBRADO", uf: "RS" },
  },
  { name: "NOVA BRESCIA", value: { cityName: "NOVA BRESCIA", uf: "RS" } },
  { name: "TRES ARROIOS", value: { cityName: "TRES ARROIOS", uf: "RS" } },
  { name: "HIDROLANDIA", value: { cityName: "HIDROLANDIA", uf: "GO" } },
  { name: "GUAPO", value: { cityName: "GUAPO", uf: "GO" } },
  { name: "CAMPO BONITO", value: { cityName: "CAMPO BONITO", uf: "PR" } },
  { name: "ANAPOLIS", value: { cityName: "ANAPOLIS", uf: "GO" } },
  {
    name: "SANTA BARBARA DE GOIAS",
    value: { cityName: "SANTA BARBARA DE GOIAS", uf: "GO" },
  },
  { name: "ITABERAI", value: { cityName: "ITABERAI", uf: "GO" } },
  { name: "CORRENTINA", value: { cityName: "CORRENTINA", uf: "BA" } },
  { name: "CASTRO ALVES", value: { cityName: "CASTRO ALVES", uf: "BA" } },
  { name: "ACREUNA", value: { cityName: "ACREUNA", uf: "GO" } },
  { name: "HERVEIRAS", value: { cityName: "HERVEIRAS", uf: "RS" } },
  {
    name: "TAQUARUCU DO SUL",
    value: { cityName: "TAQUARUCU DO SUL", uf: "RS" },
  },
  { name: "PILAR DE GOIAS", value: { cityName: "PILAR DE GOIAS", uf: "GO" } },
  { name: "TRINDADE", value: { cityName: "TRINDADE", uf: "GO" } },
  { name: "GASPAR", value: { cityName: "GASPAR", uf: "SC" } },
  { name: "CAMPINAS DO SUL", value: { cityName: "CAMPINAS DO SUL", uf: "RS" } },
  { name: "VISTA ALEGRE", value: { cityName: "VISTA ALEGRE", uf: "RS" } },
  { name: "GAROPABA", value: { cityName: "GAROPABA", uf: "SC" } },
  {
    name: "SAO BENTO DO SUL",
    value: { cityName: "SAO BENTO DO SUL", uf: "SC" },
  },
  { name: "NAVEGANTES", value: { cityName: "NAVEGANTES", uf: "SC" } },
  { name: "NOVO TIRADENTES", value: { cityName: "NOVO TIRADENTES", uf: "RS" } },
  {
    name: "SAO JOSE DO HORTENCIO",
    value: { cityName: "SAO JOSE DO HORTENCIO", uf: "RS" },
  },
  { name: "TRINDADE DO SUL", value: { cityName: "TRINDADE DO SUL", uf: "RS" } },
  { name: "FAXINALZINHO", value: { cityName: "FAXINALZINHO", uf: "RS" } },
  { name: "MARIALVA", value: { cityName: "MARIALVA", uf: "PR" } },
  { name: "CAMBIRA", value: { cityName: "CAMBIRA", uf: "PR" } },
  { name: "RIO DOS INDIOS", value: { cityName: "RIO DOS INDIOS", uf: "RS" } },
  { name: "QUEVEDOS", value: { cityName: "QUEVEDOS", uf: "RS" } },
  {
    name: "PINHEIRINHO DO VALE",
    value: { cityName: "PINHEIRINHO DO VALE", uf: "RS" },
  },
  { name: "MACAPA", value: { cityName: "MACAPA", uf: "AP" } },
  {
    name: "ASSIS CHATEAUBRIAND",
    value: { cityName: "ASSIS CHATEAUBRIAND", uf: "PR" },
  },
  {
    name: "BALNEARIO ARROIO DO SILVA",
    value: { cityName: "BALNEARIO ARROIO DO SILVA", uf: "SC" },
  },
  {
    name: "CAMPINA GRANDE DO SUL",
    value: { cityName: "CAMPINA GRANDE DO SUL", uf: "PR" },
  },
  { name: "GOIOERE", value: { cityName: "GOIOERE", uf: "PR" } },
  { name: "ARAUCARIA", value: { cityName: "ARAUCARIA", uf: "PR" } },
  { name: "PIRAQUARA", value: { cityName: "PIRAQUARA", uf: "PR" } },
  {
    name: "FORMOSA DO OESTE",
    value: { cityName: "FORMOSA DO OESTE", uf: "PR" },
  },
  {
    name: "SAO JOSE DOS PINHAIS",
    value: { cityName: "SAO JOSE DOS PINHAIS", uf: "PR" },
  },
  { name: "PAICANDU", value: { cityName: "PAICANDU", uf: "PR" } },
  { name: "LINDOESTE", value: { cityName: "LINDOESTE", uf: "PR" } },
  { name: "JURANDA", value: { cityName: "JURANDA", uf: "PR" } },
  { name: "FAROL", value: { cityName: "FAROL", uf: "PR" } },
  { name: "ITAPERUCU", value: { cityName: "ITAPERUCU", uf: "PR" } },
  { name: "MANDIRITUBA", value: { cityName: "MANDIRITUBA", uf: "PR" } },
  { name: "MATINHOS", value: { cityName: "MATINHOS", uf: "PR" } },
  { name: "CANDOI", value: { cityName: "CANDOI", uf: "PR" } },
  { name: "PLATINA", value: { cityName: "PLATINA", uf: "SP" } },
  { name: "PEDRAS ALTAS", value: { cityName: "PEDRAS ALTAS", uf: "RS" } },
  { name: "GUIDOVAL", value: { cityName: "GUIDOVAL", uf: "MG" } },
  { name: "UBA", value: { cityName: "UBA", uf: "MG" } },
  {
    name: "COLONIA MUNICIPAL",
    value: { cityName: "COLONIA MUNICIPAL", uf: "RS" },
  },
  { name: "RODEIRO", value: { cityName: "RODEIRO", uf: "MG" } },
  { name: "SEIVAL", value: { cityName: "SEIVAL", uf: "RS" } },
  { name: "FAZENDA FIALHO", value: { cityName: "FAZENDA FIALHO", uf: "RS" } },
  {
    name: "SANTA TEREZA DO OESTE",
    value: { cityName: "SANTA TEREZA DO OESTE", uf: "PR" },
  },
  { name: "JARI", value: { cityName: "JARI", uf: "RS" } },
  {
    name: "VISCONDE DO RIO BRANCO",
    value: { cityName: "VISCONDE DO RIO BRANCO", uf: "MG" },
  },
  { name: "TABAI", value: { cityName: "TABAI", uf: "RS" } },
  { name: "BETIM", value: { cityName: "BETIM", uf: "MG" } },
  { name: "AMARANTINA", value: { cityName: "AMARANTINA", uf: "MG" } },
  { name: "LUZ", value: { cityName: "LUZ", uf: "MG" } },
  {
    name: "RIBEIRAO DAS NEVES",
    value: { cityName: "RIBEIRAO DAS NEVES", uf: "MG" },
  },
  { name: "PIRAUBA", value: { cityName: "PIRAUBA", uf: "MG" } },
  { name: "PONTE NOVA", value: { cityName: "PONTE NOVA", uf: "MG" } },
  { name: "GUANHAES", value: { cityName: "GUANHAES", uf: "MG" } },
  { name: "TEOFILO OTONI", value: { cityName: "TEOFILO OTONI", uf: "MG" } },
  {
    name: "CARMOPOLIS DE MINAS",
    value: { cityName: "CARMOPOLIS DE MINAS", uf: "MG" },
  },
  { name: "CONGONHAS", value: { cityName: "CONGONHAS", uf: "MG" } },
  {
    name: "MUNDO NOVO DE MINAS",
    value: { cityName: "MUNDO NOVO DE MINAS", uf: "MG" },
  },
  { name: "DIAMANTINA", value: { cityName: "DIAMANTINA", uf: "MG" } },
  { name: "BOM DESPACHO", value: { cityName: "BOM DESPACHO", uf: "MG" } },
  {
    name: "BOA VISTA DA APARECIDA",
    value: { cityName: "BOA VISTA DA APARECIDA", uf: "PR" },
  },
  { name: "CARMESIA", value: { cityName: "CARMESIA", uf: "MG" } },
  { name: "ITANHOMI", value: { cityName: "ITANHOMI", uf: "MG" } },
  { name: "SALINAS", value: { cityName: "SALINAS", uf: "MG" } },
  { name: "AMAPORA", value: { cityName: "AMAPORA", uf: "PR" } },
  { name: "URAI", value: { cityName: "URAI", uf: "PR" } },
  { name: "CRISTAL DO SUL", value: { cityName: "CRISTAL DO SUL", uf: "RS" } },
  { name: "CARLOS CHAGAS", value: { cityName: "CARLOS CHAGAS", uf: "MG" } },
  { name: "CORINTO", value: { cityName: "CORINTO", uf: "MG" } },
  {
    name: "QUARTEL DO SACRAMENTO",
    value: { cityName: "QUARTEL DO SACRAMENTO", uf: "MG" },
  },
  { name: "DIAMANTE DO SUL", value: { cityName: "DIAMANTE DO SUL", uf: "PR" } },
  { name: "FAZ. RIO GRANDE", value: { cityName: "FAZ. RIO GRANDE", uf: "PR" } },
  { name: "BALDIM", value: { cityName: "BALDIM", uf: "MG" } },
  { name: "BRAGANEY", value: { cityName: "BRAGANEY", uf: "PR" } },
  {
    name: "SAO JOAO DO MANTENINHA",
    value: { cityName: "SAO JOAO DO MANTENINHA", uf: "MG" },
  },
  { name: "ABAETE", value: { cityName: "ABAETE", uf: "MG" } },
  { name: "FOZ DO JORDAO", value: { cityName: "FOZ DO JORDAO", uf: "PR" } },
  { name: "ARACUAI", value: { cityName: "ARACUAI", uf: "MG" } },
  {
    name: "FRANCISCO BELTRAO",
    value: { cityName: "FRANCISCO BELTRAO", uf: "PR" },
  },
  { name: "POUSO ALEGRE", value: { cityName: "POUSO ALEGRE", uf: "MG" } },
  { name: "OURO PRETO", value: { cityName: "OURO PRETO", uf: "MG" } },
  { name: "JOAO PE", value: { cityName: "JOAO PE", uf: "MG" } },
  { name: "CARATINGA", value: { cityName: "CARATINGA", uf: "MG" } },
  { name: "PEDRO LEOPOLDO", value: { cityName: "PEDRO LEOPOLDO", uf: "MG" } },
  { name: "NOVA LIMA", value: { cityName: "NOVA LIMA", uf: "MG" } },
  { name: "ERMO", value: { cityName: "ERMO", uf: "SC" } },
  { name: "PALMAS", value: { cityName: "PALMAS", uf: "PR" } },
  { name: "LAGOA SANTA", value: { cityName: "LAGOA SANTA", uf: "MG" } },
  { name: "MUTUM", value: { cityName: "MUTUM", uf: "MG" } },
  { name: "ALTO RIO DOCE", value: { cityName: "ALTO RIO DOCE", uf: "MG" } },
  {
    name: "CONSELHEIRO PENA",
    value: { cityName: "CONSELHEIRO PENA", uf: "MG" },
  },
  { name: "JOCA TAVARES", value: { cityName: "JOCA TAVARES", uf: "RS" } },
  {
    name: "FAZENDA VILANOVA",
    value: { cityName: "FAZENDA VILANOVA", uf: "RS" },
  },
  { name: "DOIS VIZINHOS", value: { cityName: "DOIS VIZINHOS", uf: "PR" } },
  { name: "IGARAPE", value: { cityName: "IGARAPE", uf: "MG" } },
  { name: "MANTENA", value: { cityName: "MANTENA", uf: "MG" } },
  { name: "JURUMIRIM", value: { cityName: "JURUMIRIM", uf: "MG" } },
  { name: "SEM PEIXE", value: { cityName: "SEM PEIXE", uf: "MG" } },
  { name: "QUATRO BARRAS", value: { cityName: "QUATRO BARRAS", uf: "PR" } },
  { name: "ADRIANOPOLIS", value: { cityName: "ADRIANOPOLIS", uf: "PR" } },
  {
    name: "PONTAL DO PARANA",
    value: { cityName: "PONTAL DO PARANA", uf: "PR" },
  },
  { name: "JABOTICATUBAS", value: { cityName: "JABOTICATUBAS", uf: "MG" } },
  { name: "ITABIRA", value: { cityName: "ITABIRA", uf: "MG" } },
  { name: "ITAMBACURI", value: { cityName: "ITAMBACURI", uf: "MG" } },
  { name: "VIRGOLANDIA", value: { cityName: "VIRGOLANDIA", uf: "MG" } },
  { name: "CLEVELANDIA", value: { cityName: "CLEVELANDIA", uf: "PR" } },
  {
    name: "CONCEICAO DO MATO DENTRO",
    value: { cityName: "CONCEICAO DO MATO DENTRO", uf: "MG" },
  },
  { name: "BUENOPOLIS", value: { cityName: "BUENOPOLIS", uf: "MG" } },
  { name: "JOAO MONLEVADE", value: { cityName: "JOAO MONLEVADE", uf: "MG" } },
  { name: "PARAGUACU", value: { cityName: "PARAGUACU", uf: "MG" } },
  {
    name: "SANTANA DE PIRAPAMA",
    value: { cityName: "SANTANA DE PIRAPAMA", uf: "MG" },
  },
  {
    name: "SAO JOSE DO JACURI",
    value: { cityName: "SAO JOSE DO JACURI", uf: "MG" },
  },
  { name: "BELO VALE", value: { cityName: "BELO VALE", uf: "MG" } },
  { name: "JEQUITINHONHA", value: { cityName: "JEQUITINHONHA", uf: "MG" } },
  { name: "ARAXA", value: { cityName: "ARAXA", uf: "MG" } },
  { name: "CAMPO AZUL", value: { cityName: "CAMPO AZUL", uf: "MG" } },
  { name: "IPATINGA", value: { cityName: "IPATINGA", uf: "MG" } },
  { name: "SERRO", value: { cityName: "SERRO", uf: "MG" } },
  { name: "VICOSA", value: { cityName: "VICOSA", uf: "MG" } },
  {
    name: "BELA VISTA DO TOLDO",
    value: { cityName: "BELA VISTA DO TOLDO", uf: "SC" },
  },
  { name: "IBIRITE", value: { cityName: "IBIRITE", uf: "MG" } },
  { name: "FERROS", value: { cityName: "FERROS", uf: "MG" } },
  { name: "SAO VITOR", value: { cityName: "SAO VITOR", uf: "MG" } },
  { name: "MATEUS LEME", value: { cityName: "MATEUS LEME", uf: "MG" } },
  { name: "JUATUBA", value: { cityName: "JUATUBA", uf: "MG" } },
  {
    name: "SENHORA DO PORTO",
    value: { cityName: "SENHORA DO PORTO", uf: "MG" },
  },
  { name: "NOVA CONCORDIA", value: { cityName: "NOVA CONCORDIA", uf: "PR" } },
  { name: "FELIXLANDIA", value: { cityName: "FELIXLANDIA", uf: "MG" } },
  { name: "CLAUDIO", value: { cityName: "CLAUDIO", uf: "MG" } },
  { name: "UBERABA", value: { cityName: "UBERABA", uf: "MG" } },
  { name: "MANHUACU", value: { cityName: "MANHUACU", uf: "MG" } },
  {
    name: "PRESIDENTE OLEGARIO",
    value: { cityName: "PRESIDENTE OLEGARIO", uf: "MG" },
  },
  {
    name: "SAO JOAO DEL REI",
    value: { cityName: "SAO JOAO DEL REI", uf: "MG" },
  },
  { name: "PALMITAL", value: { cityName: "PALMITAL", uf: "PR" } },
  { name: "TOPAZIO", value: { cityName: "TOPAZIO", uf: "MG" } },
  { name: "PIUMHI", value: { cityName: "PIUMHI", uf: "MG" } },
  { name: "FIDALGO", value: { cityName: "FIDALGO", uf: "MG" } },
  { name: "RUBIM", value: { cityName: "RUBIM", uf: "MG" } },
  { name: "ITINGA", value: { cityName: "ITINGA", uf: "MG" } },
  { name: "PARA DE MINAS", value: { cityName: "PARA DE MINAS", uf: "MG" } },
  { name: "MENDES PIMENTEL", value: { cityName: "MENDES PIMENTEL", uf: "MG" } },
  { name: "PESCADOR", value: { cityName: "PESCADOR", uf: "MG" } },
  { name: "CUPARAQUE", value: { cityName: "CUPARAQUE", uf: "MG" } },
  { name: "CARMO DA MATA", value: { cityName: "CARMO DA MATA", uf: "MG" } },
  { name: "ORTIGUEIRA", value: { cityName: "ORTIGUEIRA", uf: "PR" } },
  { name: "BRAGANCA", value: { cityName: "BRAGANCA", uf: "PA" } },
  {
    name: "SAO JERONIMO DA SERRA",
    value: { cityName: "SAO JERONIMO DA SERRA", uf: "PR" },
  },
  { name: "SERTANOPOLIS", value: { cityName: "SERTANOPOLIS", uf: "PR" } },
  { name: "ITAVERAVA", value: { cityName: "ITAVERAVA", uf: "MG" } },
  { name: "ROSEIRAL", value: { cityName: "ROSEIRAL", uf: "MG" } },
  { name: "DOM SILVERIO", value: { cityName: "DOM SILVERIO", uf: "MG" } },
  { name: "IGUATAMA", value: { cityName: "IGUATAMA", uf: "MG" } },
  { name: "JOAO PESSOA", value: { cityName: "JOAO PESSOA", uf: "PB" } },
  { name: "TELEMACO BORBA", value: { cityName: "TELEMACO BORBA", uf: "PR" } },
  {
    name: "SAO JOSE DOS CAMPOS",
    value: { cityName: "SAO JOSE DOS CAMPOS", uf: "SP" },
  },
  { name: "SAO LUIS", value: { cityName: "SAO LUIS", uf: "MA" } },
  { name: "DORES DO INDAIA", value: { cityName: "DORES DO INDAIA", uf: "MG" } },
  { name: "MATOZINHOS", value: { cityName: "MATOZINHOS", uf: "MG" } },
  { name: "CARAVELAS", value: { cityName: "CARAVELAS", uf: "BA" } },
  { name: "RIO PIRACICABA", value: { cityName: "RIO PIRACICABA", uf: "MG" } },
  { name: "CRUCILANDIA", value: { cityName: "CRUCILANDIA", uf: "MG" } },
  { name: "MURIAE", value: { cityName: "MURIAE", uf: "MG" } },
  { name: "INHAPIM", value: { cityName: "INHAPIM", uf: "MG" } },
  {
    name: "SAO BERNARDO DO CAMPO",
    value: { cityName: "SAO BERNARDO DO CAMPO", uf: "SP" },
  },
  { name: "TRES MARIAS", value: { cityName: "TRES MARIAS", uf: "MG" } },
  {
    name: "NOVA LARANJEIRAS",
    value: { cityName: "NOVA LARANJEIRAS", uf: "PR" },
  },
  {
    name: "CONCEICAO DO RIO VERDE",
    value: { cityName: "CONCEICAO DO RIO VERDE", uf: "MG" },
  },
  { name: "IBEMA", value: { cityName: "IBEMA", uf: "PR" } },
  { name: "BONFIM", value: { cityName: "BONFIM", uf: "MG" } },
  { name: "AGUAS FORMOSAS", value: { cityName: "AGUAS FORMOSAS", uf: "MG" } },
  { name: "ARCOS", value: { cityName: "ARCOS", uf: "MG" } },
  { name: "FORMIGA", value: { cityName: "FORMIGA", uf: "MG" } },
  {
    name: "BELA VISTA DE MINAS",
    value: { cityName: "BELA VISTA DE MINAS", uf: "MG" },
  },
  { name: "CONQUISTA", value: { cityName: "CONQUISTA", uf: "MG" } },
  {
    name: "ITAPECERICA DA SERRA",
    value: { cityName: "ITAPECERICA DA SERRA", uf: "SP" },
  },
  { name: "LUISBURGO", value: { cityName: "LUISBURGO", uf: "MG" } },
  { name: "BRUMADINHO", value: { cityName: "BRUMADINHO", uf: "MG" } },
  { name: "NOVA RAMADA", value: { cityName: "NOVA RAMADA", uf: "RS" } },
  { name: "ARACAI", value: { cityName: "ARACAI", uf: "MG" } },
  { name: "CARANAIBA", value: { cityName: "CARANAIBA", uf: "MG" } },
  {
    name: "BENJAMIN CONSTANT DO SUL",
    value: { cityName: "BENJAMIN CONSTANT DO SUL", uf: "RS" },
  },
  {
    name: "RIBEIRAO VERMELHO",
    value: { cityName: "RIBEIRAO VERMELHO", uf: "MG" },
  },
  { name: "NACIP RAYDAN", value: { cityName: "NACIP RAYDAN", uf: "MG" } },
  { name: "ALCOBACA", value: { cityName: "ALCOBACA", uf: "BA" } },
  { name: "DIVINOPOLIS", value: { cityName: "DIVINOPOLIS", uf: "MG" } },
  { name: "PEDRO VERSIANI", value: { cityName: "PEDRO VERSIANI", uf: "MG" } },
  { name: "PEDRA AZUL", value: { cityName: "PEDRA AZUL", uf: "MG" } },
  { name: "MARTINS SOARES", value: { cityName: "MARTINS SOARES", uf: "MG" } },
  { name: "ALVINOPOLIS", value: { cityName: "ALVINOPOLIS", uf: "MG" } },
  { name: "PENHA DO NORTE", value: { cityName: "PENHA DO NORTE", uf: "MG" } },
  {
    name: "SAO GONCALO DO RIO ABAIXO",
    value: { cityName: "SAO GONCALO DO RIO ABAIXO", uf: "MG" },
  },
  { name: "NOVA ERA", value: { cityName: "NOVA ERA", uf: "MG" } },
  { name: "BARAO DE COCAIS", value: { cityName: "BARAO DE COCAIS", uf: "MG" } },
  { name: "RIO DOCE", value: { cityName: "RIO DOCE", uf: "MG" } },
  { name: "JACUI", value: { cityName: "JACUI", uf: "MG" } },
  {
    name: "SANTA CRUZ DO ESCALVADO",
    value: { cityName: "SANTA CRUZ DO ESCALVADO", uf: "MG" },
  },
  { name: "SANTA LUCIA", value: { cityName: "SANTA LUCIA", uf: "PR" } },
  { name: "PIRAI DO SUL", value: { cityName: "PIRAI DO SUL", uf: "PR" } },
  {
    name: "BALNEARIO GAIVOTA",
    value: { cityName: "BALNEARIO GAIVOTA", uf: "SC" },
  },
  { name: "REALEZA", value: { cityName: "REALEZA", uf: "PR" } },
  {
    name: "CORONEL FABRICIANO",
    value: { cityName: "CORONEL FABRICIANO", uf: "MG" },
  },
  {
    name: "LARANJEIRAS DO SUL",
    value: { cityName: "LARANJEIRAS DO SUL", uf: "PR" },
  },
  { name: "CURIUVA", value: { cityName: "CURIUVA", uf: "PR" } },
  { name: "ALMENARA", value: { cityName: "ALMENARA", uf: "MG" } },
  { name: "GUARATINGA", value: { cityName: "GUARATINGA", uf: "BA" } },
  { name: "DOIS DE ABRIL", value: { cityName: "DOIS DE ABRIL", uf: "MG" } },
  {
    name: "NOVA ESPERANCA DO SUDOESTE",
    value: { cityName: "NOVA ESPERANCA DO SUDOESTE", uf: "PR" },
  },
  {
    name: "CAPAO DA PORTEIRA",
    value: { cityName: "CAPAO DA PORTEIRA", uf: "RS" },
  },
  { name: "GALILEIA", value: { cityName: "GALILEIA", uf: "MG" } },
  { name: "SABARA", value: { cityName: "SABARA", uf: "MG" } },
  { name: "SANTA LUZIA", value: { cityName: "SANTA LUZIA", uf: "MG" } },
  {
    name: "CLARO DOS POCOES",
    value: { cityName: "CLARO DOS POCOES", uf: "MG" },
  },
  { name: "ALVARENGA", value: { cityName: "ALVARENGA", uf: "MG" } },
  { name: "ARAGUARI", value: { cityName: "ARAGUARI", uf: "MG" } },
  { name: "CARANDAI", value: { cityName: "CARANDAI", uf: "MG" } },
  { name: "PENHA DE FRANCA", value: { cityName: "PENHA DE FRANCA", uf: "MG" } },
  { name: "BORDA DO CAMPO", value: { cityName: "BORDA DO CAMPO", uf: "PR" } },
  { name: "CAPIVARI DO SUL", value: { cityName: "CAPIVARI DO SUL", uf: "RS" } },
  { name: "MANGA", value: { cityName: "MANGA", uf: "MG" } },
  { name: "JANUARIA", value: { cityName: "JANUARIA", uf: "MG" } },
  {
    name: "PARQUE INDUSTRIAL",
    value: { cityName: "PARQUE INDUSTRIAL", uf: "MG" },
  },
  {
    name: "VITORIA DA CONQUISTA",
    value: { cityName: "VITORIA DA CONQUISTA", uf: "BA" },
  },
  { name: "RESPLENDOR", value: { cityName: "RESPLENDOR", uf: "MG" } },
  { name: "MARIO CAMPOS", value: { cityName: "MARIO CAMPOS", uf: "MG" } },
  { name: "SAO GOTARDO", value: { cityName: "SAO GOTARDO", uf: "MG" } },
  {
    name: "GOVERNADOR VALADARES",
    value: { cityName: "GOVERNADOR VALADARES", uf: "MG" },
  },
  { name: "RIO ACIMA", value: { cityName: "RIO ACIMA", uf: "MG" } },
  { name: "MATIPO", value: { cityName: "MATIPO", uf: "MG" } },
  { name: "MEDEIROS", value: { cityName: "MEDEIROS", uf: "MG" } },
  { name: "PLANURA", value: { cityName: "PLANURA", uf: "MG" } },
  { name: "COLOMBIA", value: { cityName: "COLOMBIA", uf: "SP" } },
  { name: "IAPU", value: { cityName: "IAPU", uf: "MG" } },
  { name: "PECANHA", value: { cityName: "PECANHA", uf: "MG" } },
  { name: "URUCANIA", value: { cityName: "URUCANIA", uf: "MG" } },
  { name: "SANTA BARBARA", value: { cityName: "SANTA BARBARA", uf: "MG" } },
  { name: "PASSA TEMPO", value: { cityName: "PASSA TEMPO", uf: "MG" } },
  {
    name: "SANTANA DOS MONTES",
    value: { cityName: "SANTANA DOS MONTES", uf: "MG" },
  },
  { name: "VERE", value: { cityName: "VERE", uf: "PR" } },
  {
    name: "SANTA MARIA DO OESTE",
    value: { cityName: "SANTA MARIA DO OESTE", uf: "PR" },
  },
  { name: "CORDISBURGO", value: { cityName: "CORDISBURGO", uf: "MG" } },
  { name: "PARAUAPEBAS", value: { cityName: "PARAUAPEBAS", uf: "PA" } },
  { name: "CAETE", value: { cityName: "CAETE", uf: "MG" } },
  { name: "GUARATUBA", value: { cityName: "GUARATUBA", uf: "PR" } },
  {
    name: "FRANCISCO DUMONT",
    value: { cityName: "FRANCISCO DUMONT", uf: "MG" },
  },
  {
    name: "SANTA MARIA DO SALTO",
    value: { cityName: "SANTA MARIA DO SALTO", uf: "MG" },
  },
  { name: "JAMPRUCA", value: { cityName: "JAMPRUCA", uf: "MG" } },
  { name: "JOAIMA", value: { cityName: "JOAIMA", uf: "MG" } },
  { name: "RAUL SOARES", value: { cityName: "RAUL SOARES", uf: "MG" } },
  { name: "FREI GASPAR", value: { cityName: "FREI GASPAR", uf: "MG" } },
  { name: "JUSTINOPOLIS", value: { cityName: "JUSTINOPOLIS", uf: "MG" } },
  { name: "NANUQUE", value: { cityName: "NANUQUE", uf: "MG" } },
  { name: "TOCANTINS", value: { cityName: "TOCANTINS", uf: "MG" } },
  { name: "JACINTO", value: { cityName: "JACINTO", uf: "MG" } },
  { name: "SAO FRANCISCO", value: { cityName: "SAO FRANCISCO", uf: "ES" } },
  { name: "SETE LAGOAS", value: { cityName: "SETE LAGOAS", uf: "MG" } },
  { name: "LARANJAL", value: { cityName: "LARANJAL", uf: "MG" } },
  { name: "BELMIRO BRAGA", value: { cityName: "BELMIRO BRAGA", uf: "MG" } },
  { name: "CARANGOLA", value: { cityName: "CARANGOLA", uf: "MG" } },
  { name: "PEQUI", value: { cityName: "PEQUI", uf: "MG" } },
  { name: "ORATORIOS", value: { cityName: "ORATORIOS", uf: "MG" } },
  {
    name: "SAO PEDRO DOS FERROS",
    value: { cityName: "SAO PEDRO DOS FERROS", uf: "MG" },
  },
  { name: "CATAS ALTAS", value: { cityName: "CATAS ALTAS", uf: "MG" } },
  { name: "ACURUI", value: { cityName: "ACURUI", uf: "MG" } },
  {
    name: "BELA VISTA DA CAROBA",
    value: { cityName: "BELA VISTA DA CAROBA", uf: "PR" },
  },
  { name: "GUARANIACU", value: { cityName: "GUARANIACU", uf: "PR" } },
  { name: "LAGOA DA PRATA", value: { cityName: "LAGOA DA PRATA", uf: "MG" } },
  { name: "LIMA DUARTE", value: { cityName: "LIMA DUARTE", uf: "MG" } },
  { name: "AGUA BOA", value: { cityName: "AGUA BOA", uf: "MG" } },
  { name: "JEQUERI", value: { cityName: "JEQUERI", uf: "MG" } },
  { name: "MANGARATIBA", value: { cityName: "MANGARATIBA", uf: "RJ" } },
  { name: "ESPERA FELIZ", value: { cityName: "ESPERA FELIZ", uf: "MG" } },
  { name: "NOVA PONTE", value: { cityName: "NOVA PONTE", uf: "MG" } },
  { name: "MAJOR EZEQUIEL", value: { cityName: "MAJOR EZEQUIEL", uf: "MG" } },
  { name: "RAPOSOS", value: { cityName: "RAPOSOS", uf: "MG" } },
  { name: "POTE", value: { cityName: "POTE", uf: "MG" } },
  { name: "CAMBARA", value: { cityName: "CAMBARA", uf: "PR" } },
  { name: "PADRE PARAISO", value: { cityName: "PADRE PARAISO", uf: "MG" } },
  {
    name: "CARMO DO RIO CLARO",
    value: { cityName: "CARMO DO RIO CLARO", uf: "MG" },
  },
  { name: "ALFENAS", value: { cityName: "ALFENAS", uf: "MG" } },
  {
    name: "BRASILIA DE MINAS",
    value: { cityName: "BRASILIA DE MINAS", uf: "MG" },
  },
  { name: "DORES DO TURVO", value: { cityName: "DORES DO TURVO", uf: "MG" } },
  { name: "SAO FRANCISCO", value: { cityName: "SAO FRANCISCO", uf: "MG" } },
  { name: "SARZEDO", value: { cityName: "SARZEDO", uf: "MG" } },
  { name: "OLIVEIRA", value: { cityName: "OLIVEIRA", uf: "MG" } },
  { name: "CAETANOPOLIS", value: { cityName: "CAETANOPOLIS", uf: "MG" } },
  { name: "DOM JOAQUIM", value: { cityName: "DOM JOAQUIM", uf: "MG" } },
  { name: "CURVELO", value: { cityName: "CURVELO", uf: "MG" } },
  {
    name: "SAO PEDRO DO SUACUI",
    value: { cityName: "SAO PEDRO DO SUACUI", uf: "MG" },
  },
  { name: "PITANGUI", value: { cityName: "PITANGUI", uf: "MG" } },
  { name: "TUMIRITINGA", value: { cityName: "TUMIRITINGA", uf: "MG" } },
  { name: "ALEM PARAIBA", value: { cityName: "ALEM PARAIBA", uf: "MG" } },
  { name: "BARRA MANSA", value: { cityName: "BARRA MANSA", uf: "RJ" } },
  { name: "MANHUMIRIM", value: { cityName: "MANHUMIRIM", uf: "MG" } },
  { name: "ERVALIA", value: { cityName: "ERVALIA", uf: "MG" } },
  {
    name: "CACHOEIRA DA PRATA",
    value: { cityName: "CACHOEIRA DA PRATA", uf: "MG" },
  },
  { name: "QUINTA", value: { cityName: "QUINTA", uf: "RS" } },
  { name: "MANGUEIRINHA", value: { cityName: "MANGUEIRINHA", uf: "PR" } },
  { name: "BALSA NOVA", value: { cityName: "BALSA NOVA", uf: "PR" } },
  {
    name: "GENERAL CARNEIRO",
    value: { cityName: "GENERAL CARNEIRO", uf: "PR" },
  },
  { name: "PARANAGUA", value: { cityName: "PARANAGUA", uf: "PR" } },
  {
    name: "CAMPINA DO SIMAO",
    value: { cityName: "CAMPINA DO SIMAO", uf: "PR" },
  },
  { name: "VILA LANGARO", value: { cityName: "VILA LANGARO", uf: "RS" } },
  {
    name: "DOM PEDRO DE ALCANTARA",
    value: { cityName: "DOM PEDRO DE ALCANTARA", uf: "RS" },
  },
  {
    name: "DILERMANDO DE AGUIAR",
    value: { cityName: "DILERMANDO DE AGUIAR", uf: "RS" },
  },
  { name: "BELA VISTA", value: { cityName: "BELA VISTA", uf: "MS" } },
  {
    name: "ESPERANCA DO SUL",
    value: { cityName: "ESPERANCA DO SUL", uf: "RS" },
  },
  { name: "TIO HUGO", value: { cityName: "TIO HUGO", uf: "RS" } },
  { name: "CEDRO MARCADO", value: { cityName: "CEDRO MARCADO", uf: "RS" } },
  { name: "CASTRO", value: { cityName: "CASTRO", uf: "PR" } },
  { name: "JATAIZINHO", value: { cityName: "JATAIZINHO", uf: "PR" } },
  {
    name: "SENADOR SALGADO FILHO",
    value: { cityName: "SENADOR SALGADO FILHO", uf: "RS" },
  },
  { name: "BRUNOPOLIS", value: { cityName: "BRUNOPOLIS", uf: "SC" } },
  { name: "CARAA", value: { cityName: "CARAA", uf: "RS" } },
  { name: "SAMAMBAIA", value: { cityName: "SAMAMBAIA", uf: "DF" } },
  { name: "CERRITO ALEGRE", value: { cityName: "CERRITO ALEGRE", uf: "RS" } },
  { name: "SANTA SILVANA", value: { cityName: "SANTA SILVANA", uf: "RS" } },
  { name: "MARCILIO DIAS", value: { cityName: "MARCILIO DIAS", uf: "SC" } },
  { name: "TAQUARA VERDE", value: { cityName: "TAQUARA VERDE", uf: "SC" } },
  {
    name: "PENHA DO CASSIANO",
    value: { cityName: "PENHA DO CASSIANO", uf: "MG" },
  },
  {
    name: "COLONIA CASTROLANDA",
    value: { cityName: "COLONIA CASTROLANDA", uf: "PR" },
  },
  {
    name: "CORREGO DOS MONOS",
    value: { cityName: "CORREGO DOS MONOS", uf: "ES" },
  },
  { name: "ALMEIDA", value: { cityName: "ALMEIDA", uf: "MG" } },
  { name: "ABRANTES", value: { cityName: "ABRANTES", uf: "BA" } },
  { name: "PAULA FREITAS", value: { cityName: "PAULA FREITAS", uf: "PR" } },
  { name: "PARANAVAI", value: { cityName: "PARANAVAI", uf: "PR" } },
  { name: "VENANCIO AIRES", value: { cityName: "VENANCIO AIRES", uf: "RS" } },
  { name: "CORONEL PILAR", value: { cityName: "CORONEL PILAR", uf: "RS" } },
  { name: "UBIRETAMA", value: { cityName: "UBIRETAMA", uf: "RS" } },
  { name: "COLONIA NOVA", value: { cityName: "COLONIA NOVA", uf: "RS" } },
  {
    name: "BOA VISTA DO CADEADO",
    value: { cityName: "BOA VISTA DO CADEADO", uf: "RS" },
  },
  { name: "CAMBE", value: { cityName: "CAMBE", uf: "PR" } },
  { name: "ROLANDIA", value: { cityName: "ROLANDIA", uf: "PR" } },
  { name: "CAPAO DO CIPO", value: { cityName: "CAPAO DO CIPO", uf: "RS" } },
  {
    name: "MONTE ALEGRE DOS CAMPOS",
    value: { cityName: "MONTE ALEGRE DOS CAMPOS", uf: "RS" },
  },
  { name: "UNISTALDA", value: { cityName: "UNISTALDA", uf: "RS" } },
  { name: "MATO QUEIMADO", value: { cityName: "MATO QUEIMADO", uf: "RS" } },
  { name: "UMUARAMA", value: { cityName: "UMUARAMA", uf: "PR" } },
  { name: "ROLADOR", value: { cityName: "ROLADOR", uf: "RS" } },
  {
    name: "CORNELIO PROCOPIO",
    value: { cityName: "CORNELIO PROCOPIO", uf: "PR" },
  },
  { name: "ITATI", value: { cityName: "ITATI", uf: "RS" } },
  { name: "MORRO ALTO", value: { cityName: "MORRO ALTO", uf: "RS" } },
  { name: "MAMPITUBA", value: { cityName: "MAMPITUBA", uf: "RS" } },
  { name: "FREI ROGERIO", value: { cityName: "FREI ROGERIO", uf: "SC" } },
  {
    name: "BALNEARIO PICARRAS",
    value: { cityName: "BALNEARIO PICARRAS", uf: "SC" },
  },
  { name: "MARMELEIRO", value: { cityName: "MARMELEIRO", uf: "PR" } },
  { name: "CRUZ MACHADO", value: { cityName: "CRUZ MACHADO", uf: "PR" } },
  { name: "CAPAO ALTO", value: { cityName: "CAPAO ALTO", uf: "SC" } },
  { name: "HERVAL D OESTE", value: { cityName: "HERVAL D OESTE", uf: "SC" } },
  { name: "JANIOPOLIS", value: { cityName: "JANIOPOLIS", uf: "PR" } },
  { name: "PAINEL", value: { cityName: "PAINEL", uf: "SC" } },
  {
    name: "CAMPO DO TENENTE",
    value: { cityName: "CAMPO DO TENENTE", uf: "PR" },
  },
  { name: "JUPIA", value: { cityName: "JUPIA", uf: "SC" } },
  { name: "BITURUNA", value: { cityName: "BITURUNA", uf: "PR" } },
  { name: "BANDEIRANTE", value: { cityName: "BANDEIRANTE", uf: "SC" } },
  { name: "QUITANDINHA", value: { cityName: "QUITANDINHA", uf: "PR" } },
  { name: "PAIAL", value: { cityName: "PAIAL", uf: "SC" } },
  { name: "INDIOS", value: { cityName: "INDIOS", uf: "SC" } },
  {
    name: "MARQUES DE SOUZA",
    value: { cityName: "MARQUES DE SOUZA", uf: "RS" },
  },
  { name: "FORQUETINHA", value: { cityName: "FORQUETINHA", uf: "RS" } },
  { name: "CANUDOS DO VALE", value: { cityName: "CANUDOS DO VALE", uf: "RS" } },
  {
    name: "SAO MATEUS DO SUL",
    value: { cityName: "SAO MATEUS DO SUL", uf: "PR" },
  },
  { name: "VILA NOVA", value: { cityName: "VILA NOVA", uf: "SC" } },
  { name: "PRAIA GRANDE", value: { cityName: "PRAIA GRANDE", uf: "SP" } },
  {
    name: "CORONEL DOMINGOS SOARES",
    value: { cityName: "CORONEL DOMINGOS SOARES", uf: "PR" },
  },
  { name: "ZORTEA", value: { cityName: "ZORTEA", uf: "SC" } },
  { name: "PORECATU", value: { cityName: "PORECATU", uf: "PR" } },
  {
    name: "RINCAO DOS MENDES",
    value: { cityName: "RINCAO DOS MENDES", uf: "RS" },
  },
  { name: "PASSO NOVO", value: { cityName: "PASSO NOVO", uf: "RS" } },
  {
    name: "SAO PEDRO DE ALCANTARA",
    value: { cityName: "SAO PEDRO DE ALCANTARA", uf: "SC" },
  },
  { name: "GUARAI", value: { cityName: "GUARAI", uf: "PR" } },
  { name: "ASTORGA", value: { cityName: "ASTORGA", uf: "PR" } },
  { name: "JAGUAPITA", value: { cityName: "JAGUAPITA", uf: "PR" } },
  { name: "CAMPO LARGO", value: { cityName: "CAMPO LARGO", uf: "PR" } },
  {
    name: "CHAPADAO DO LAGEADO",
    value: { cityName: "CHAPADAO DO LAGEADO", uf: "SC" },
  },
  { name: "POA", value: { cityName: "POA", uf: "SP" } },
  {
    name: "ENSEADA DE BRITO",
    value: { cityName: "ENSEADA DE BRITO", uf: "SC" },
  },
  { name: "JACUIZINHO", value: { cityName: "JACUIZINHO", uf: "RS" } },
  {
    name: "CACHOEIRA DO VALE",
    value: { cityName: "CACHOEIRA DO VALE", uf: "MG" },
  },
  { name: "ESPIGAO", value: { cityName: "ESPIGAO", uf: "RS" } },
  { name: "GUARAQUECABA", value: { cityName: "GUARAQUECABA", uf: "PR" } },
  { name: "CEU AZUL", value: { cityName: "CEU AZUL", uf: "PR" } },
  { name: "SANTA ZELIA", value: { cityName: "SANTA ZELIA", uf: "PR" } },
  {
    name: "FRANCISCO MORATO",
    value: { cityName: "FRANCISCO MORATO", uf: "SP" },
  },
  { name: "CONFINS", value: { cityName: "CONFINS", uf: "MG" } },
  { name: "BANDEIRANTES", value: { cityName: "BANDEIRANTES", uf: "PR" } },
  {
    name: "LUIZLANDIA DO OESTE",
    value: { cityName: "LUIZLANDIA DO OESTE", uf: "MG" },
  },
  { name: "PASSO DO VERDE", value: { cityName: "PASSO DO VERDE", uf: "RS" } },
  { name: "ITAUNA DO SUL", value: { cityName: "ITAUNA DO SUL", uf: "PR" } },
  { name: "TEIXEIRA SOARES", value: { cityName: "TEIXEIRA SOARES", uf: "PR" } },
  { name: "PORTO AMAZONAS", value: { cityName: "PORTO AMAZONAS", uf: "PR" } },
  { name: "ALTONIA", value: { cityName: "ALTONIA", uf: "PR" } },
  { name: "QUERENCIA", value: { cityName: "QUERENCIA", uf: "MT" } },
  { name: "SAGRADA FAMILIA", value: { cityName: "SAGRADA FAMILIA", uf: "RS" } },
  { name: "MUNHOZ DE MELO", value: { cityName: "MUNHOZ DE MELO", uf: "PR" } },
  { name: "HONORIO SERPA", value: { cityName: "HONORIO SERPA", uf: "PR" } },
  {
    name: "FLORIANO PEIXOTO",
    value: { cityName: "FLORIANO PEIXOTO", uf: "RS" },
  },
  { name: "ALTO PIQUIRI", value: { cityName: "ALTO PIQUIRI", uf: "PR" } },
  {
    name: "SANTA CRUZ DE MONTE CASTELO",
    value: { cityName: "SANTA CRUZ DE MONTE CASTELO", uf: "PR" },
  },
  { name: "PARANAPOEMA", value: { cityName: "PARANAPOEMA", uf: "PR" } },
  {
    name: "BOA VISTA DO INCRA",
    value: { cityName: "BOA VISTA DO INCRA", uf: "RS" },
  },
  {
    name: "RIBEIRAO DO PINHAL",
    value: { cityName: "RIBEIRAO DO PINHAL", uf: "PR" },
  },
  {
    name: "SERRA DOS DOURADOS",
    value: { cityName: "SERRA DOS DOURADOS", uf: "PR" },
  },
  { name: "PEROLA D OESTE", value: { cityName: "PEROLA D OESTE", uf: "PR" } },
  { name: "IVATE", value: { cityName: "IVATE", uf: "PR" } },
  { name: "IGUARACU", value: { cityName: "IGUARACU", uf: "PR" } },
  { name: "ALTO PARANA", value: { cityName: "ALTO PARANA", uf: "PR" } },
  { name: "PATO BRANCO", value: { cityName: "PATO BRANCO", uf: "PR" } },
  { name: "PINHAIS", value: { cityName: "PINHAIS", uf: "PR" } },
  { name: "SUSPIRO", value: { cityName: "SUSPIRO", uf: "RS" } },
  { name: "ARICANDUVA", value: { cityName: "ARICANDUVA", uf: "PR" } },
  { name: "ARAPONGAS", value: { cityName: "ARAPONGAS", uf: "PR" } },
  { name: "PEROLA", value: { cityName: "PEROLA", uf: "PR" } },
  { name: "NOVA LONDRINA", value: { cityName: "NOVA LONDRINA", uf: "PR" } },
  { name: "MARINGA", value: { cityName: "MARINGA", uf: "PR" } },
  { name: "VARGEM ALEGRE", value: { cityName: "VARGEM ALEGRE", uf: "MG" } },
  { name: "TAMBOARA", value: { cityName: "TAMBOARA", uf: "PR" } },
  { name: "SAO BERNARDINO", value: { cityName: "SAO BERNARDINO", uf: "SC" } },
  { name: "MANDAGUACU", value: { cityName: "MANDAGUACU", uf: "PR" } },
  { name: "PRANCHITA", value: { cityName: "PRANCHITA", uf: "PR" } },
  { name: "ANGULO", value: { cityName: "ANGULO", uf: "PR" } },
  {
    name: "SAO LOURENCO D OESTE",
    value: { cityName: "SAO LOURENCO D OESTE", uf: "SC" },
  },
  { name: "DOUTOR CAMARGO", value: { cityName: "DOUTOR CAMARGO", uf: "PR" } },
  { name: "COQUEIRO BAIXO", value: { cityName: "COQUEIRO BAIXO", uf: "RS" } },
  { name: "IVATUBA", value: { cityName: "IVATUBA", uf: "PR" } },
  { name: "PORTAO", value: { cityName: "PORTAO", uf: "RS" } },
  {
    name: "SAO FELIX DE MINAS",
    value: { cityName: "SAO FELIX DE MINAS", uf: "MG" },
  },
  {
    name: "DIAMANTE DO NORTE",
    value: { cityName: "DIAMANTE DO NORTE", uf: "PR" },
  },
  { name: "MATELANDIA", value: { cityName: "MATELANDIA", uf: "PR" } },
  {
    name: "VERA CRUZ DO OESTE",
    value: { cityName: "VERA CRUZ DO OESTE", uf: "PR" },
  },
  {
    name: "RESERVA DO IGUACU",
    value: { cityName: "RESERVA DO IGUACU", uf: "PR" },
  },
  { name: "TERRA RICA", value: { cityName: "TERRA RICA", uf: "PR" } },
  { name: "NOVO SARANDI", value: { cityName: "NOVO SARANDI", uf: "PR" } },
  {
    name: "ADHEMAR DE BARROS",
    value: { cityName: "ADHEMAR DE BARROS", uf: "PR" },
  },
  { name: "CHONIN DE BAIXO", value: { cityName: "CHONIN DE BAIXO", uf: "MG" } },
  { name: "LUZERNA", value: { cityName: "LUZERNA", uf: "SC" } },
  {
    name: "SAO JOAQUIM DE BICAS",
    value: { cityName: "SAO JOAQUIM DE BICAS", uf: "MG" },
  },
  { name: "EDGARD MELO", value: { cityName: "EDGARD MELO", uf: "MG" } },
  {
    name: "SANTA TEREZINHA DE ITAIPU",
    value: { cityName: "SANTA TEREZINHA DE ITAIPU", uf: "PR" },
  },
  { name: "IRACEMAPOLIS", value: { cityName: "IRACEMAPOLIS", uf: "SP" } },
  { name: "CHONIM", value: { cityName: "CHONIM", uf: "SC" } },
  { name: "TIJUCAS DO SUL", value: { cityName: "TIJUCAS DO SUL", uf: "PR" } },
  {
    name: "CRUZEIRO DO OESTE",
    value: { cityName: "CRUZEIRO DO OESTE", uf: "PR" },
  },
  {
    name: "ENGENHEIRO BELTRAO",
    value: { cityName: "ENGENHEIRO BELTRAO", uf: "PR" },
  },
  {
    name: "SANTO ANTONIO DA PLATINA",
    value: { cityName: "SANTO ANTONIO DA PLATINA", uf: "PR" },
  },
  { name: "PAIOL DE BAIXO", value: { cityName: "PAIOL DE BAIXO", uf: "PR" } },
  { name: "BARBOSA FERRAZ", value: { cityName: "BARBOSA FERRAZ", uf: "PR" } },
  { name: "POLVILHO", value: { cityName: "POLVILHO", uf: "SP" } },
  { name: "OSVALDO KROEFF", value: { cityName: "OSVALDO KROEFF", uf: "RS" } },
  { name: "GOIOXIM", value: { cityName: "GOIOXIM", uf: "PR" } },
  { name: "TURUCU", value: { cityName: "TURUCU", uf: "RS" } },
  {
    name: "QUARTO CENTENARIO",
    value: { cityName: "QUARTO CENTENARIO", uf: "PR" },
  },
  { name: "ITAIPULANDIA", value: { cityName: "ITAIPULANDIA", uf: "PR" } },
  { name: "BOCAIUVA DO SUL", value: { cityName: "BOCAIUVA DO SUL", uf: "PR" } },
  { name: "RIO AZUL", value: { cityName: "RIO AZUL", uf: "PR" } },
  {
    name: "SAO JOAO DO TRIUNFO",
    value: { cityName: "SAO JOAO DO TRIUNFO", uf: "PR" },
  },
  { name: "RANCHO ALEGRE", value: { cityName: "RANCHO ALEGRE", uf: "PR" } },
  {
    name: "FERNANDES PINHEIRO",
    value: { cityName: "FERNANDES PINHEIRO", uf: "PR" },
  },
  { name: "ESTRELA VELHA", value: { cityName: "ESTRELA VELHA", uf: "RS" } },
  {
    name: "SAO MIGUEL DO OESTE",
    value: { cityName: "SAO MIGUEL DO OESTE", uf: "SC" },
  },
  { name: "PRINCESA", value: { cityName: "PRINCESA", uf: "SC" } },
  {
    name: "SAO JOSE DA BARRA",
    value: { cityName: "SAO JOSE DA BARRA", uf: "MG" },
  },
  { name: "LUIS ALVES", value: { cityName: "LUIS ALVES", uf: "SC" } },
  { name: "TIMOTEO", value: { cityName: "TIMOTEO", uf: "MG" } },
  {
    name: "SANTA TEREZINHA DO PROGRESSO",
    value: { cityName: "SANTA TEREZINHA DO PROGRESSO", uf: "SC" },
  },
  {
    name: "SAO PEDRO DO SUL",
    value: { cityName: "SAO PEDRO DO SUL", uf: "RS" },
  },
  { name: "MILAGRE", value: { cityName: "MILAGRE", uf: "MG" } },
  { name: "MOCAMBEIRO", value: { cityName: "MOCAMBEIRO", uf: "MG" } },
  { name: "QUINTA DO SOL", value: { cityName: "QUINTA DO SOL", uf: "PR" } },
  { name: "TUNEL VERDE", value: { cityName: "TUNEL VERDE", uf: "RS" } },
  { name: "IMBITUVA", value: { cityName: "IMBITUVA", uf: "PR" } },
  { name: "NOVA CANDELARIA", value: { cityName: "NOVA CANDELARIA", uf: "RS" } },
  { name: "SANTIAGO DO SUL", value: { cityName: "SANTIAGO DO SUL", uf: "SC" } },
  { name: "FLOR DO SERTAO", value: { cityName: "FLOR DO SERTAO", uf: "SC" } },
  { name: "TUBARAO", value: { cityName: "TUBARAO", uf: "SC" } },
  {
    name: "UNIAO DA VITORIA",
    value: { cityName: "UNIAO DA VITORIA", uf: "PR" },
  },
  {
    name: "ALMIRANTE TAMANDARE",
    value: { cityName: "ALMIRANTE TAMANDARE", uf: "PR" },
  },
  { name: "ICARAIMA", value: { cityName: "ICARAIMA", uf: "PR" } },
  { name: "ANTONIO DIAS", value: { cityName: "ANTONIO DIAS", uf: "MG" } },
  { name: "PRUDENTOPOLIS", value: { cityName: "PRUDENTOPOLIS", uf: "PR" } },
  { name: "TOROPI", value: { cityName: "TOROPI", uf: "RS" } },
  { name: "CLARAIBA", value: { cityName: "CLARAIBA", uf: "SC" } },
  { name: "TERRA BOA", value: { cityName: "TERRA BOA", uf: "PR" } },
  { name: "TUPASSI", value: { cityName: "TUPASSI", uf: "PR" } },
  { name: "CAMPO MAGRO", value: { cityName: "CAMPO MAGRO", uf: "PR" } },
  { name: "PEDRA CORRIDA", value: { cityName: "PEDRA CORRIDA", uf: "MG" } },
  { name: "COLOMBO", value: { cityName: "COLOMBO", uf: "PR" } },
  { name: "RIO DO SALTO", value: { cityName: "RIO DO SALTO", uf: "PR" } },
  {
    name: "SANTA MARGARIDA DO SUL",
    value: { cityName: "SANTA MARGARIDA DO SUL", uf: "RS" },
  },
  {
    name: "MARECHAL BORMANN",
    value: { cityName: "MARECHAL BORMANN", uf: "SC" },
  },
  { name: "SANTA FE", value: { cityName: "SANTA FE", uf: "PR" } },
  { name: "CERRO AZUL", value: { cityName: "CERRO AZUL", uf: "PR" } },
  { name: "AMAMBAI", value: { cityName: "AMAMBAI", uf: "MS" } },
  { name: "NICOLANDIA", value: { cityName: "NICOLANDIA", uf: "MG" } },
  { name: "TRES CORACOES", value: { cityName: "TRES CORACOES", uf: "MG" } },
  {
    name: "ARMACAO DOS BUZIOS",
    value: { cityName: "ARMACAO DOS BUZIOS", uf: "RJ" },
  },
  { name: "PRADO FERREIRA", value: { cityName: "PRADO FERREIRA", uf: "PR" } },
  { name: "IGUABA GRANDE", value: { cityName: "IGUABA GRANDE", uf: "RJ" } },
  { name: "LAGO SUL", value: { cityName: "LAGO SUL", uf: "DF" } },
  { name: "SANTA MARIANA", value: { cityName: "SANTA MARIANA", uf: "PR" } },
  { name: "PEROBAL", value: { cityName: "PEROBAL", uf: "PR" } },
  { name: "IBIPORA", value: { cityName: "IBIPORA", uf: "PR" } },
  {
    name: "SAO JOSE DAS PALMEIRAS",
    value: { cityName: "SAO JOSE DAS PALMEIRAS", uf: "PR" },
  },
  { name: "TRES BARRAS", value: { cityName: "TRES BARRAS", uf: "SC" } },
  { name: "SABAUDIA", value: { cityName: "SABAUDIA", uf: "PR" } },
  { name: "FLORAI", value: { cityName: "FLORAI", uf: "PR" } },
  { name: "AMPERE", value: { cityName: "AMPERE", uf: "PR" } },
  {
    name: "CATANDUVAS DO SUL",
    value: { cityName: "CATANDUVAS DO SUL", uf: "PR" },
  },
  { name: "FAXINAL", value: { cityName: "FAXINAL", uf: "PR" } },
  { name: "LIDIANOPOLIS", value: { cityName: "LIDIANOPOLIS", uf: "PR" } },
  { name: "PRATOS", value: { cityName: "PRATOS", uf: "RS" } },
  { name: "GARIBALDI", value: { cityName: "GARIBALDI", uf: "RS" } },
  {
    name: "VITORIA DAS MISSOES",
    value: { cityName: "VITORIA DAS MISSOES", uf: "RS" },
  },
  { name: "TAMOIOS", value: { cityName: "TAMOIOS", uf: "RJ" } },
  { name: "IGARAI", value: { cityName: "IGARAI", uf: "SP" } },
  {
    name: "BARRA DO GUARITA",
    value: { cityName: "BARRA DO GUARITA", uf: "RS" },
  },
  { name: "CAUCAIA DO ALTO", value: { cityName: "CAUCAIA DO ALTO", uf: "SP" } },
  { name: "CARAPICUIBA", value: { cityName: "CARAPICUIBA", uf: "SP" } },
  { name: "ENTRE-IJUIS", value: { cityName: "ENTRE-IJUIS", uf: "RS" } },
  { name: "AGUAS CLARAS", value: { cityName: "AGUAS CLARAS", uf: "RS" } },
  { name: "ITAPUA", value: { cityName: "ITAPUA", uf: "RS" } },
  { name: "MORUNGAVA", value: { cityName: "MORUNGAVA", uf: "RS" } },
  { name: "PASSA SETE", value: { cityName: "PASSA SETE", uf: "RS" } },
  { name: "ITAQUARI", value: { cityName: "ITAQUARI", uf: "ES" } },
  { name: "BOCA DO MONTE", value: { cityName: "BOCA DO MONTE", uf: "RS" } },
  {
    name: "BARRA DE SAO JOAO",
    value: { cityName: "BARRA DE SAO JOAO", uf: "RJ" },
  },
  { name: "MELO VIANA", value: { cityName: "MELO VIANA", uf: "MG" } },
  { name: "TORQUATO SEVERO", value: { cityName: "TORQUATO SEVERO", uf: "RS" } },
  { name: "PAIQUERE", value: { cityName: "PAIQUERE", uf: "PR" } },
  { name: "ARROIO DO SO", value: { cityName: "ARROIO DO SO", uf: "RS" } },
  {
    name: "PALMEIRA DAS MISSÃƒâ€¢ES",
    value: { cityName: "PALMEIRA DAS MISSÃƒâ€¢ES", uf: "RS" },
  },
  { name: "XANGRI LA", value: { cityName: "XANGRI LA", uf: "RS" } },
  { name: "GUAJUVIRAS", value: { cityName: "GUAJUVIRAS", uf: "RS" } },
  { name: "RESIDENCIA FUCK", value: { cityName: "RESIDENCIA FUCK", uf: "SC" } },
  { name: "TIMBUI", value: { cityName: "TIMBUI", uf: "ES" } },
  { name: "EMBU DAS ARTES", value: { cityName: "EMBU DAS ARTES", uf: "SP" } },
  { name: "ITACOLOMI", value: { cityName: "ITACOLOMI", uf: "RS" } },
  { name: "CRIUVA", value: { cityName: "CRIUVA", uf: "RS" } },
  { name: "PINGO D AGUA", value: { cityName: "PINGO D AGUA", uf: "MG" } },
  { name: "CAMOBI", value: { cityName: "CAMOBI", uf: "RS" } },
  {
    name: "LAGOA DO ITAENGA",
    value: { cityName: "LAGOA DO ITAENGA", uf: "PE" },
  },
  { name: "AZURITA", value: { cityName: "AZURITA", uf: "MG" } },
  {
    name: "SANTO ANTONIO DO LEITE",
    value: { cityName: "SANTO ANTONIO DO LEITE", uf: "MG" },
  },
  { name: "FURQUIM", value: { cityName: "FURQUIM", uf: "MG" } },
  { name: "RANCHO VELHO", value: { cityName: "RANCHO VELHO", uf: "RS" } },
  {
    name: "JABOATAO DOS GUARARAPES",
    value: { cityName: "JABOATAO DOS GUARARAPES", uf: "PE" },
  },
  { name: "QUINTAO", value: { cityName: "QUINTAO", uf: "RS" } },
  { name: "CARAPINA", value: { cityName: "CARAPINA", uf: "ES" } },
  { name: "PAPUCAIA", value: { cityName: "PAPUCAIA", uf: "RJ" } },
  { name: "MARIANTE", value: { cityName: "MARIANTE", uf: "RS" } },
  { name: "JABOATAO", value: { cityName: "JABOATAO", uf: "PE" } },
  { name: "SIRIJI", value: { cityName: "SIRIJI", uf: "PE" } },
  { name: "EMBU GUACU", value: { cityName: "EMBU GUACU", uf: "SP" } },
  { name: "VITORIA", value: { cityName: "VITORIA", uf: "MG" } },
  {
    name: "AREIA BRANCA DOS ASSIS",
    value: { cityName: "AREIA BRANCA DOS ASSIS", uf: "PR" },
  },
  { name: "POVO NOVO", value: { cityName: "POVO NOVO", uf: "RS" } },
  { name: "MARIENTAL", value: { cityName: "MARIENTAL", uf: "PR" } },
  { name: "CEILANDIA", value: { cityName: "CEILANDIA", uf: "DF" } },
  { name: "LERROVILLE", value: { cityName: "LERROVILLE", uf: "PR" } },
  { name: "SANTO AGOSTINHO", value: { cityName: "SANTO AGOSTINHO", uf: "PE" } },
  { name: "AGUAS DE ARAXA", value: { cityName: "AGUAS DE ARAXA", uf: "MG" } },
  { name: "NOVA SARDENHA", value: { cityName: "NOVA SARDENHA", uf: "RS" } },
  { name: "CITROLANDIA", value: { cityName: "CITROLANDIA", uf: "MG" } },
  { name: "CURUMIM", value: { cityName: "CURUMIM", uf: "RS" } },
  { name: "MONTE BONITO", value: { cityName: "MONTE BONITO", uf: "RS" } },
  { name: "FLORINIA", value: { cityName: "FLORINIA", uf: "SP" } },
  { name: "CaraA", value: { cityName: "CaraA", uf: "RS" } },
  { name: "SAO LORENCO", value: { cityName: "SAO LORENCO", uf: "PE" } },
  { name: "MONTE MOR", value: { cityName: "MONTE MOR", uf: "SP" } },
  { name: "GIRONDA", value: { cityName: "GIRONDA", uf: "ES" } },
  {
    name: "NOSSA SENHORA DO O",
    value: { cityName: "NOSSA SENHORA DO O", uf: "PE" },
  },
  { name: "PINTO BANDEIRA", value: { cityName: "PINTO BANDEIRA", uf: "RS" } },
  {
    name: "SAO GERALDO DE TUMIRITINGA",
    value: { cityName: "SAO GERALDO DE TUMIRITINGA", uf: "MG" },
  },
  { name: "BREJAUBINHA", value: { cityName: "BREJAUBINHA", uf: "MG" } },
  { name: "SOCAVAO", value: { cityName: "SOCAVAO", uf: "PR" } },
  { name: "BAGUARI", value: { cityName: "BAGUARI", uf: "MG" } },
  { name: "ABAPA", value: { cityName: "ABAPA", uf: "PR" } },
  {
    name: "BANCO DA VITORIA",
    value: { cityName: "BANCO DA VITORIA", uf: "BA" },
  },
  { name: "PORTO NOVO", value: { cityName: "PORTO NOVO", uf: "BA" } },
  { name: "AREMBEPE", value: { cityName: "AREMBEPE", uf: "BA" } },
  { name: "GOIO-EN", value: { cityName: "GOIO-EN", uf: "SC" } },
  { name: "MARIA QUITERIA", value: { cityName: "MARIA QUITERIA", uf: "BA" } },
  { name: "MIRAGUAIA", value: { cityName: "MIRAGUAIA", uf: "RS" } },
  { name: "MONTE ALVERNE", value: { cityName: "MONTE ALVERNE", uf: "RS" } },
  {
    name: "BELEM DO SAO FRANCISCO",
    value: { cityName: "BELEM DO SAO FRANCISCO", uf: "PE" },
  },
  {
    name: "BOA UNIAO DE ITABIRINHA",
    value: { cityName: "BOA UNIAO DE ITABIRINHA", uf: "MG" },
  },
  { name: "BURARAMA", value: { cityName: "BURARAMA", uf: "ES" } },
  { name: "DOM LARA", value: { cityName: "DOM LARA", uf: "MG" } },
  { name: "taubatE", value: { cityName: "taubatE", uf: "SP" } },
  { name: "VILA SECA", value: { cityName: "VILA SECA", uf: "RS" } },
  { name: "FUNDAO", value: { cityName: "FUNDAO", uf: "ES" } },
  {
    name: "NOSSA SENHORA DA LUZ",
    value: { cityName: "NOSSA SENHORA DA LUZ", uf: "PE" },
  },
  {
    name: "SAO PEDRO TOBIAS",
    value: { cityName: "SAO PEDRO TOBIAS", uf: "SC" },
  },
  { name: "FERRARIA", value: { cityName: "FERRARIA", uf: "PR" } },
  {
    name: "OLHO D AGUA DAS CUNHAS",
    value: { cityName: "OLHO D AGUA DAS CUNHAS", uf: "MA" },
  },
  { name: "GUAPIRANGA", value: { cityName: "GUAPIRANGA", uf: "SP" } },
  {
    name: "BalneArio Gaivota",
    value: { cityName: "BalneArio Gaivota", uf: "SC" },
  },
  {
    name: "VARGEM GRANDE DO SOTURNO",
    value: { cityName: "VARGEM GRANDE DO SOTURNO", uf: "ES" },
  },
  { name: "MAIOBA", value: { cityName: "MAIOBA", uf: "MA" } },
  {
    name: "SALTINHO DO OESTE",
    value: { cityName: "SALTINHO DO OESTE", uf: "PR" },
  },
  { name: "CALDAS DO JORRO", value: { cityName: "CALDAS DO JORRO", uf: "BA" } },
  { name: "ATAFONA", value: { cityName: "ATAFONA", uf: "RS" } },
  {
    name: "MIRASSOL D OESTE",
    value: { cityName: "MIRASSOL D OESTE", uf: "MT" },
  },
  { name: "IPOMEIA", value: { cityName: "IPOMEIA", uf: "SC" } },
  { name: "TUPINAMBA", value: { cityName: "TUPINAMBA", uf: "RS" } },
  { name: "ARAMIRIM", value: { cityName: "ARAMIRIM", uf: "MG" } },
  {
    name: "ILHA DOS MARINHEIROS",
    value: { cityName: "ILHA DOS MARINHEIROS", uf: "RS" },
  },
  { name: "TAGUATINGA", value: { cityName: "TAGUATINGA", uf: "DF" } },
  {
    name: "SAO JOAO DE CORTES",
    value: { cityName: "SAO JOAO DE CORTES", uf: "MA" },
  },
  { name: "IRAPE", value: { cityName: "IRAPE", uf: "SP" } },
  { name: "SANTA FLORA", value: { cityName: "SANTA FLORA", uf: "RS" } },
  { name: "DERRIBADINHA", value: { cityName: "DERRIBADINHA", uf: "MG" } },
  { name: "ESQUINA GAUCHA", value: { cityName: "ESQUINA GAUCHA", uf: "RS" } },
  { name: "CAETANO MENDES", value: { cityName: "CAETANO MENDES", uf: "PR" } },
  { name: "COQUEIRO", value: { cityName: "COQUEIRO", uf: "PA" } },
  { name: "XUCURU", value: { cityName: "XUCURU", uf: "PE" } },
  { name: "JI PARANA", value: { cityName: "JI PARANA", uf: "RO" } },
  { name: "APINAGES", value: { cityName: "APINAGES", uf: "PA" } },
  { name: "OTELO", value: { cityName: "OTELO", uf: "PA" } },
  {
    name: "VILA DOS CABANOS",
    value: { cityName: "VILA DOS CABANOS", uf: "PA" },
  },
  { name: "PAU A PIQUE", value: { cityName: "PAU A PIQUE", uf: "BA" } },
  {
    name: "SAO JOSE DO PANTANO",
    value: { cityName: "SAO JOSE DO PANTANO", uf: "MG" },
  },
  {
    name: "PINHEIRO MARCADO",
    value: { cityName: "PINHEIRO MARCADO", uf: "RS" },
  },
  { name: "CANDIDO FREIRE", value: { cityName: "CANDIDO FREIRE", uf: "RS" } },
  { name: "PACOTUBA", value: { cityName: "PACOTUBA", uf: "ES" } },
  { name: "JORDANESIA", value: { cityName: "JORDANESIA", uf: "SP" } },
  { name: "RAVENA", value: { cityName: "RAVENA", uf: "MG" } },
  { name: "FREIRE", value: { cityName: "FREIRE", uf: "RS" } },
  { name: "JAMBUACU", value: { cityName: "JAMBUACU", uf: "PA" } },
  { name: "JUBAIA", value: { cityName: "JUBAIA", uf: "CE" } },
  { name: "JAPUIBA", value: { cityName: "JAPUIBA", uf: "RJ" } },
  { name: "VILA MURIQUI", value: { cityName: "VILA MURIQUI", uf: "RJ" } },
  { name: "BONFIM DA FEIRA", value: { cityName: "BONFIM DA FEIRA", uf: "BA" } },
  { name: "RIACHO FUNDO", value: { cityName: "RIACHO FUNDO", uf: "DF" } },
  { name: "FELICINA", value: { cityName: "FELICINA", uf: "MG" } },
  { name: "NATINGUI", value: { cityName: "NATINGUI", uf: "PR" } },
  { name: "CAPONGA", value: { cityName: "CAPONGA", uf: "CE" } },
  { name: "BRACO DO RIO", value: { cityName: "BRACO DO RIO", uf: "ES" } },
  { name: "NOVA MILANO", value: { cityName: "NOVA MILANO", uf: "RS" } },
  { name: "HUMILDES", value: { cityName: "HUMILDES", uf: "BA" } },
  { name: "LUSTOSA", value: { cityName: "LUSTOSA", uf: "BA" } },
  {
    name: "HIDRELETRICA TUCURUI",
    value: { cityName: "HIDRELETRICA TUCURUI", uf: "PA" },
  },
  { name: "GAMA", value: { cityName: "GAMA", uf: "DF" } },
  { name: "COQUEIROS", value: { cityName: "COQUEIROS", uf: "BA" } },
  { name: "PIABETA", value: { cityName: "PIABETA", uf: "RJ" } },
  { name: "PARAFUSO", value: { cityName: "PARAFUSO", uf: "BA" } },
  { name: "QUATRO BOCAS", value: { cityName: "QUATRO BOCAS", uf: "PA" } },
  { name: "PESCARIA BRAVA", value: { cityName: "PESCARIA BRAVA", uf: "SC" } },
  { name: "TAIM", value: { cityName: "TAIM", uf: "RS" } },
  { name: "BIRITIBA-MIRIM", value: { cityName: "BIRITIBA-MIRIM", uf: "SP" } },
  { name: "APEU", value: { cityName: "APEU", uf: "PA" } },
  { name: "RIO PARDINHO", value: { cityName: "RIO PARDINHO", uf: "RS" } },
  { name: "ITAIM", value: { cityName: "ITAIM", uf: "MG" } },
  { name: "ANGUSTURA", value: { cityName: "ANGUSTURA", uf: "MG" } },
  {
    name: "BARRA DO JACUIPE",
    value: { cityName: "BARRA DO JACUIPE", uf: "BA" },
  },
  { name: "ACUPE", value: { cityName: "ACUPE", uf: "BA" } },
  { name: "JANDINOPOLIS", value: { cityName: "JANDINOPOLIS", uf: "PR" } },
  { name: "BUGRE", value: { cityName: "BUGRE", uf: "PR" } },
  { name: "AREZ", value: { cityName: "AREZ", uf: "RN" } },
  { name: "MAJOR PORTO", value: { cityName: "MAJOR PORTO", uf: "MG" } },
  {
    name: "PORTO DAS CAIXAS",
    value: { cityName: "PORTO DAS CAIXAS", uf: "RJ" },
  },
  { name: "ROSALIA", value: { cityName: "ROSALIA", uf: "SP" } },
  {
    name: "BOM SUCESSO DE PATOS",
    value: { cityName: "BOM SUCESSO DE PATOS", uf: "MG" },
  },
  { name: "AGRONOMICA", value: { cityName: "AGRONOMICA", uf: "SC" } },
  { name: "JOANA PERES", value: { cityName: "JOANA PERES", uf: "PA" } },
  { name: "PINDAIBAS", value: { cityName: "PINDAIBAS", uf: "MG" } },
  { name: "CARAIBA", value: { cityName: "CARAIBA", uf: "GO" } },
  { name: "ALEXANDRA", value: { cityName: "ALEXANDRA", uf: "PR" } },
  { name: "ANTA", value: { cityName: "ANTA", uf: "RJ" } },
  {
    name: "ILHA DOS VALADARES",
    value: { cityName: "ILHA DOS VALADARES", uf: "PR" },
  },
  { name: "MIRASSELVAS", value: { cityName: "MIRASSELVAS", uf: "PA" } },
  {
    name: "BALNEARIO RINCAO",
    value: { cityName: "BALNEARIO RINCAO", uf: "SC" },
  },
  { name: "PRIMAVERA", value: { cityName: "PRIMAVERA", uf: "SP" } },
  { name: "IGARAPE ACU", value: { cityName: "IGARAPE ACU", uf: "PA" } },
  { name: "GOITACAZES", value: { cityName: "GOITACAZES", uf: "RJ" } },
  { name: "ONHA", value: { cityName: "ONHA", uf: "BA" } },
  {
    name: "TAGUATINGA NORTE",
    value: { cityName: "TAGUATINGA NORTE", uf: "DF" },
  },
  { name: "LINDENBERG", value: { cityName: "LINDENBERG", uf: "ES" } },
  {
    name: "SANTA LUCIA DO PIAI",
    value: { cityName: "SANTA LUCIA DO PIAI", uf: "RS" },
  },
  { name: "IGUA", value: { cityName: "IGUA", uf: "BA" } },
  { name: "SANTA ELVIRA", value: { cityName: "SANTA ELVIRA", uf: "MT" } },
  { name: "PARANGABA", value: { cityName: "PARANGABA", uf: "CE" } },
  { name: "SOLEDADE", value: { cityName: "SOLEDADE", uf: "PB" } },
  { name: "MONTE SANTO", value: { cityName: "MONTE SANTO", uf: "BA" } },
  {
    name: "MOJUI DOS CAMPOS",
    value: { cityName: "MOJUI DOS CAMPOS", uf: "PA" },
  },
  {
    name: "MONTE SANTO DO TOCANTINS",
    value: { cityName: "MONTE SANTO DO TOCANTINS", uf: "TO" },
  },
  { name: "GRACHO CARDOSO", value: { cityName: "GRACHO CARDOSO", uf: "SE" } },
  { name: "PATY DO ALFERES", value: { cityName: "PATY DO ALFERES", uf: "RJ" } },
  { name: "ABRE CAMPO", value: { cityName: "ABRE CAMPO", uf: "MG" } },
  { name: "ALTO CAPARAO", value: { cityName: "ALTO CAPARAO", uf: "MG" } },
  { name: "AMPARO DO SERRA", value: { cityName: "AMPARO DO SERRA", uf: "MG" } },
  { name: "BATAGUASSU", value: { cityName: "BATAGUASSU", uf: "MS" } },
  { name: "ALTO BOA VISTA", value: { cityName: "ALTO BOA VISTA", uf: "MT" } },
  {
    name: "AROEIRAS DO ITAIM",
    value: { cityName: "AROEIRAS DO ITAIM", uf: "PI" },
  },
  { name: "BELA CRUZ", value: { cityName: "BELA CRUZ", uf: "CE" } },
  { name: "OROS", value: { cityName: "OROS", uf: "CE" } },
  { name: "LUIZIANA", value: { cityName: "LUIZIANA", uf: "PR" } },
  {
    name: "SAO LUIZ DO NORTE",
    value: { cityName: "SAO LUIZ DO NORTE", uf: "GO" },
  },
  {
    name: "SAO MIGUEL DO PASSA QUATRO",
    value: { cityName: "SAO MIGUEL DO PASSA QUATRO", uf: "GO" },
  },
  { name: "JANUARIO CICCO", value: { cityName: "JANUARIO CICCO", uf: "RN" } },
];
