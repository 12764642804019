import { BankIcon } from "components/vectors/bank-icon";
import { CheckListIcon } from "components/vectors/checklist-icon";
import { ContractIcon } from "components/vectors/contract-icon";
import { DolarIcon } from "components/vectors/dolar-icon";
import { ForecastIcon } from "components/vectors/forecast-icon";
import { KeyboardIcon } from "components/vectors/keyboard-icon";
import { LampIcon } from "components/vectors/lamp-icon";
import { Attendance } from "../types";
import { ContractsFields } from "./tabs-components/contracts-fields";
import { DadosBancariosFields } from "./tabs-components/dados-bancarios-fields";
import { DadosCadastraisFields } from "./tabs-components/dados-cadastrais-fields";
import { DigitacaoFields } from "./tabs-components/digitacao-fields";
import { SimulacoesFields } from "./tabs-components/simulacoes-fields";
import { User } from "contexts/types/application-context-types";
import { isColomboUser } from "utils/is-colombo-user";

export type Step =
  | "Contratos"
  | "Oportunidades"
  | "Dados Bancários"
  | "Dados Cadastrais"
  | "Simulações"
  | "Digitação";

export type TabDataProps = {
  resetFieldsTabelaDigitacao: (
    fieldName: keyof Attendance,
    value: string
  ) => void;
  onChangeField: (
    fieldName: keyof Attendance,
    value: string | boolean | null
  ) => void;
};

export type TabData = {
  component: ({
    resetFieldsTabelaDigitacao,
    onChangeField,
  }: TabDataProps) => JSX.Element;
  fields: (keyof Attendance)[];
};

type TabFields = [Step, TabData];

export const fieldsSchema: TabFields[] = [
  [
    "Contratos",
    {
      component: ContractsFields,
      fields: [
        "contratosEmprestimosInss",
        "produtosFgts",
        "contratosRCCInss",
        "contratosRMCInss",
      ],
    },
  ],
  [
    "Simulações",
    {
      component: SimulacoesFields,
      fields: [
        "valorMargemLivreNovo",
        "coeficienteNovo",
        "qtdEmprestimos",
        "possuiCartaoBeneficio",
        "possuiCartaoRmc",
        "atendimentoFactaFGTS",
      ],
    },
  ],
  [
    "Dados Bancários",
    {
      component: DadosBancariosFields,
      fields: [
        "tipoPagamento",
        "codBancoPagamento",
        "tipoContaPagamento",
        "agenciaPagamento",
        "dvAgenciaPagamento",
        "contaPagamento",
        "dvContaPagamento",
      ],
    },
  ],
  [
    "Dados Cadastrais",
    {
      component: DadosCadastraisFields,
      fields: [
        "cep",
        "nb",
        "ufNB",
        "situacaoNB",
        "nome",
        "sexo",
        "documento",
        "orgaoEmissorDoc",
        "ufEmissaoDoc",
        "dataEmissaoDoc",
        "dataNascimento",
        "ddb",
        "nomeMae",
        "nomePai",
        "especieNB",
        "valorSalario",
        "pensaoAlimenticia",
        "possuiRepresentante",
        "bloqueadoEmprestimo",
        "elegivelEmprestimo",
        "estadoCivil",
        "naturalidade",
        "ufNaturalidade",
        "endereco",
        "numero",
        "complemento",
        "bairro",
        "cidade",
        "uf",
        "telefoneResidencial",
        "telefoneComercial",
        "telefoneCelular",
        "cpfRepresentanteLegal",
        "nomeRepresentanteLegal",
        "orgaoSIAPE",
        "situacaoFuncionalSIAPE",
        "unidadePagadoraSIAPE",
      ],
    },
  ],
  [
    "Digitação",
    {
      component: DigitacaoFields,
      fields: [
        "bancoDigitacao",
        "valorSolicitadoCliente",
        "prazo",
        "codigoTabela",
        "descricaoTabela",
        "ade",
        "statusProposta",
        "valorLiquidoCliente",
        "linkFormalizacao",
        "atividade",
        "statusAssinatura",
        "observacao",
      ],
    },
  ],
];

interface StepFormProps {
  fieldName: Step;
  icon: JSX.Element;
  isVisible?: (
    attendanceFormValues: Partial<Attendance>,
    user: User
  ) => boolean;
}

export const stepsArray: StepFormProps[] = [
  { fieldName: "Dados Cadastrais", icon: <CheckListIcon /> },
  { fieldName: "Dados Bancários", icon: <BankIcon /> },
  {
    fieldName: "Contratos",
    icon: <ContractIcon />,
    isVisible: ({ convenio }) => convenio !== "ANTECIPACAO_FGTS",
  },
  { fieldName: "Simulações", icon: <ForecastIcon /> },
  {
    fieldName: "Digitação",
    icon: <KeyboardIcon />,
    isVisible: ({ convenio }, user) =>
      isColomboUser(user) && ["INSS", "ANTECIPACAO_FGTS"].includes(convenio!),
  },
];
