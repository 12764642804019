import { Icon, IconProps } from "@chakra-ui/react";

export function WalletIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="25px"
      height="21px"
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.499 3.93665L16.856 0.090145C18.2569 -0.28523 19.6962 0.546022 20.0707 1.94602L20.6036 3.93402H21.875C23.3249 3.93402 24.5 5.10915 24.5 6.55902V17.934C24.5 19.3839 23.3249 20.559 21.875 20.559C17.6374 20.559 6.86263 20.559 2.625 20.559C1.17513 20.559 0 19.3839 0 17.934C0 15.0719 0 9.42115 0 6.55902C0 5.15202 1.10775 4.00315 2.499 3.93665ZM22.75 9.18402V6.55902C22.75 6.07603 22.358 5.68402 21.875 5.68402H2.64075H2.63287H2.61275C2.135 5.69015 1.75 6.07952 1.75 6.55902V17.934C1.75 18.417 2.142 18.809 2.625 18.809H21.875C22.358 18.809 22.75 18.417 22.75 17.934V15.309H16.625C16.1612 15.309 15.7159 15.1244 15.3878 14.7963C15.0596 14.4682 14.875 14.0228 14.875 13.559C14.875 12.7969 14.875 11.6961 14.875 10.934C14.875 10.4703 15.0596 10.0249 15.3878 9.69677C15.7159 9.36865 16.1612 9.18402 16.625 9.18402H22.75ZM16.625 10.934V13.559H22.75V10.934H16.625ZM18.7924 3.93402L18.3811 2.39928C18.256 1.93203 17.7756 1.65553 17.3092 1.78065L9.2715 3.93402H18.7924Z"
        fill="currentColor"
      />
    </Icon>
  );
}
