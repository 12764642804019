import { Icon, IconProps } from "@chakra-ui/react";

export function ChangeIcon({ ...rest }: IconProps) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 122.88 101.8"
      width="20px"
      height="20px"
      {...rest}
    >
      <path
        fill="currentColor"
        d="M13.56,48.8c-.7-10.22-.24-18.51,3.25-24.34s10.13-9.25,21.58-9.25h2v-5h0c0-2,.36-3.21,1.25-3.7s2.14-.09,3.66,1.36L57.26,19.24A3.44,3.44,0,0,1,58.61,22a4,4,0,0,1-1.48,2.52L45.37,35.78c-1.43,1.44-2.63,1.87-3.61,1.38s-1.35-1.69-1.33-3.59h0V27.49c-11.61-.44-14.11,4.35-14.51,10.72-.12,2-.06,4.23,0,6.46,0,1.44.08,2.89.08,4.12A1.18,1.18,0,0,1,24.83,50H14.73a1.17,1.17,0,0,1-1.17-1.17ZM76.42,0h40.7a5.77,5.77,0,0,1,5.76,5.76V37.4a5.74,5.74,0,0,1-1.69,4.07,3.9,3.9,0,0,1-.38.33,5.68,5.68,0,0,1-3.69,1.36H76.42a5.75,5.75,0,0,1-5.75-5.76V5.76A5.73,5.73,0,0,1,72.35,1.7h0A5.78,5.78,0,0,1,76.42,0ZM5.76,58.64h40.7a5.73,5.73,0,0,1,4,1.69h0a5.75,5.75,0,0,1,1.68,4.06V96a5.74,5.74,0,0,1-1.68,4.07,3.9,3.9,0,0,1-.38.33,5.72,5.72,0,0,1-3.69,1.36H5.76A5.77,5.77,0,0,1,0,96V64.4a5.72,5.72,0,0,1,1.7-4.06h0a5.74,5.74,0,0,1,4.07-1.69Zm97.61-3.51c.7,10.23.24,18.52-3.25,24.35S90,88.72,78.54,88.72H76.49v5h0c0,2-.35,3.21-1.24,3.7s-2.14.09-3.66-1.36L59.67,84.69A3.42,3.42,0,0,1,58.32,82a4.06,4.06,0,0,1,1.48-2.53L71.56,68.16c1.43-1.44,2.63-1.88,3.61-1.38s1.35,1.68,1.32,3.59h0v6.08c11.61.44,14.1-4.35,14.5-10.73.13-2,.07-4.22,0-6.46,0-1.43-.08-2.89-.08-4.12A1.17,1.17,0,0,1,92.09,54h10.1a1.18,1.18,0,0,1,1.18,1.17Z"
      />
    </Icon>
  );
}
