import { Flex, Grid, Text } from "@chakra-ui/react";
import { SaldosConfig } from "components/config-components/saldos";
import { GetLayout } from "components/get-layout";

export function ConfigPage() {
  return (
    <GetLayout>
      <Flex justifyContent="space-between" px="10px" mb="30px">
        <Text fontSize="25" fontWeight="bold">
          Configurações
        </Text>
      </Flex>
      <Flex alignItems="start" flexDir="column" px="10px">
        <SaldosConfig />
      </Flex>
    </GetLayout>
  );
}
