import apiConsulta from "api/api-consulta";
import axios from "axios";
import { dropdownTipoPagamento } from "components/atendimentos-components/atendimento-form/fields-data";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { openModalConfirm } from "components/modal-confirm-new";
import { openModalError } from "components/modal-error";
import { Dispatch, SetStateAction } from "react";
import { getToken } from "Session";
import { formatData } from "utils/string-formats";
import { getErrorByMessage, mapObject } from ".";
import { AttendanceActionsTypes } from "../actions-reducer";
import { KeyForward } from "./types";
import apiIn100 from "api/api-in100";

export function in100Map(
  data: any,
  dispatch: Dispatch<AttendanceActionsTypes>
) {
  const keyForward: KeyForward<Attendance> = {
    cpf: "cpf",
    cbcIfPagadora: "codBancoPagamento",
    especieBeneficio: "especieNB",
    // situacaoBeneficio: "situacaoNB",
    nomeBeneficiario: "nome",
    dataNascimento: (data) => ({
      data: data ? new Date(data).toLocaleDateString() : undefined,
      field: "dataNascimento",
    }),
    ufPagamento: "ufNB",
    contaCorrente: "contaPagamento",
    pensaoAlimenticia: (data) => ({ data: !!data, field: "pensaoAlimenticia" }),
    possuiRepresentanteLegal: (data) => ({
      data: !!data,
      field: "possuiRepresentante",
    }),
    // valorLimiteCartao: "valor",
    bloqueadoParaEmprestimo: "bloqueadoEmprestimo",
    nomeRepresentanteLegal: "nomeRepresentanteLegal",
    cpfRepresentanteLegal: "cpfRepresentanteLegal",
    elegivelEmprestimo: "elegivelEmprestimo",
    margemDisponivel: "valorMargemLivreNovo",
    margemDisponivelCartao: (value) => {
      return {
        data: value === 0 ? true : false,
        field: "possuiCartaoRmc",
      };
    },
    margemDisponivelRcc: (value) => {
      return {
        data: value === 0 ? true : false,
        field: "possuiCartaoBeneficio",
      };
    },
  };

  mapObject({ keyForward, data, dispatch });
}
export async function in100(
  dispatch: Dispatch<AttendanceActionsTypes>,
  formValues: Attendance,
  loading: Dispatch<SetStateAction<boolean>>,
  { signal }: AbortController
) {
  const { possuiRepresentante, cpfRepresentanteLegal, nb, cpf } = formValues;
  loading(true);
  try {
    const { data } = await apiIn100.get(`/consulta/3/pre`, {
      signal,
      params: {
        nb,
        cpf: cpf,
        cpfRepresentante: possuiRepresentante
          ? cpfRepresentanteLegal
          : undefined,
      },
    });
    openModalConfirm({
      onConfirm: () => {
        in100Map(data, dispatch);
      },
      message: "Deseja sobrescrever com os dados da consulta IN100?",
    });
  } catch (e: any) {
    const errorMessage = e?.response?.data;
    openModalError({ message: errorMessage ?? "Erro ao consultar IN100" });
  } finally {
    loading(false);
  }
}
