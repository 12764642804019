import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react";
import { CustomModal } from "components/custom-modal";
import { createEventListener, makeEvent } from "services/events";
import { useState } from "react";
import { Checkbox } from "components/checkbox";
import { CloseIcon } from "@chakra-ui/icons";
import { FaSave } from "react-icons/fa";
import { useRef } from "react";
import { Popover } from "components/popover";
import {
  initialFilterValues,
  mailingFilterArray,
} from "./mailing-options-steps/filters/filters-array";
import {
  initialHigienizacaoValues,
  mailingHigienizacaoArray,
} from "./mailing-options-steps/higienizacao";
import { toastDefaultStyle } from "chakra/theme";
import { MailingTableRow } from "../mailing-table";
import { MailingOptionsAutoComplete } from "../mailing-table/mailing-table-row-details";
import api from "api/api";
import { mailingOptionsRules } from "./mailing-options-steps/filters/modal-rules";

export interface MailingData extends MailingTableRow {
  enabledFields?: MailingOptionsAutoComplete;
}
export interface MailingOptionFilter {
  [key: string]: {
    isChecked: boolean;
    value: any;
  };
}
export interface Stage {
  stage: string;
  configJson: any;
}

export function MailingOptionsModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [mailingData, setMailingData] = useState<Partial<MailingData>>({});
  const [isSaving, setIsSaving] = useState(false);
  const [addedFilters, setAddedFilters] =
    useState<MailingOptionFilter>(initialFilterValues);
  const [addedHigienizacoes, setAddedHigienizacoes] =
    useState<MailingOptionFilter>(initialHigienizacaoValues);
  const [enviarParaCampanhaWhatsApp, setEnviarParaCampanhaWhatsApp] =
    useState(false);
  const [invalidFields, setInvalidFields] = useState<{
    [field: string]: string;
  }>({});
  const [enviarParaDiscadora, setEnviarParaDiscadora] = useState(false);
  const [enviarParaCampanhaSMS, setEnviarParaCampanhaSMS] = useState(false);
  const [telefoniaApi, setTelefoniaApi] = useState(false);
  const downloadRef = useRef<HTMLAnchorElement>(null);
  const allFields: MailingOptionFilter = {
    ...addedFilters,
    ...addedHigienizacoes,
    enviarParaCampanhaWhatsApp: {
      isChecked: enviarParaCampanhaWhatsApp,
      value: enviarParaCampanhaWhatsApp,
    },
    enviarParaCampanhaSMS: {
      isChecked: enviarParaCampanhaSMS,
      value: enviarParaCampanhaSMS,
    },
    enviarParaDiscadora: {
      isChecked: enviarParaDiscadora,
      value: enviarParaDiscadora,
    },
    telefoniaApi: {
      isChecked: telefoniaApi,
      value: telefoniaApi,
    },
  };
  const isUpdate = !!mailingData.enabledFields;
  const isDisabled = !Object.keys(allFields).find(
    (key) => allFields[key].isChecked
  );
  const isDisabledFields = !!mailingData.enabledFields;
  const toast = useToast();

  const onOpen = (mailingData: MailingData) => {
    setIsOpen(true);
    setMailingData(mailingData);
    if (mailingData.enabledFields) {
      const addedFilters = {
        ...initialFilterValues,
        ...mailingData.enabledFields.filters,
      } as MailingOptionFilter;
      const addedHigienizacoes = {
        ...initialHigienizacaoValues,
        ...mailingData.enabledFields.higienizacao,
      } as MailingOptionFilter;
      setAddedHigienizacoes(addedHigienizacoes);
      setAddedFilters(addedFilters);
    }
  };

  const onClose = () => {
    setIsOpen(false);
    setAddedFilters(initialFilterValues);
    setAddedHigienizacoes(initialHigienizacaoValues);
    setEnviarParaCampanhaWhatsApp(false);
    setEnviarParaDiscadora(false);
    setTelefoniaApi(false);
    setEnviarParaCampanhaSMS(false);
    setInvalidFields({});
  };

  createEventListener("open-mailing-options", onOpen);

  const filters = mailingFilterArray({
    addedFilters,
    setFilters: setAddedFilters,
    allModalData: allFields,
    isDisabledFields: false,
    setInvalidFields,
    mailingData,
  });

  const higienizacaoFilters = mailingHigienizacaoArray({
    addedHigienizacoes,
    setHigienizacao: setAddedHigienizacoes,
    allModalData: allFields,
    isDisabledFields: isDisabledFields,
    mailingId: mailingData.id,
    setInvalidFields,
    mailingData,
  });

  const handleSubmit = async () => {
    const { isInvalid, errors } = mailingOptionsRules(allFields);
    if (isInvalid) return setInvalidFields(errors);

    const body: { mailingId: number; pipelines: Stage[] } = {
      mailingId: mailingData.id!,
      pipelines: [],
    };
    setIsSaving(true);
    // HIGIENIZACAO
    let isSendHigienizacao = false;
    let higienizacaoData: any = {};
    Object.keys(addedHigienizacoes).forEach((key) => {
      if (addedHigienizacoes[key].isChecked) {
        higienizacaoData[key] = addedHigienizacoes[key].value;
        isSendHigienizacao = true;
      }
    });
    const higienizacao: Stage = {
      stage: "HIGIENIZACAO",
      configJson: higienizacaoData,
    };
    if (isSendHigienizacao) body.pipelines.push(higienizacao);
    // FILTROS
    let isSendFilters = false;
    let filtersData: any = {};
    Object.keys(addedFilters).forEach((key) => {
      if (addedFilters[key].isChecked) {
        filtersData[key] = addedFilters[key].value;
        isSendFilters = true;
      }
    });
    const filters: Stage = { stage: "FILTRO", configJson: filtersData };
    if (isSendFilters) body.pipelines.push(filters);
    // TELEFONIA
    if (telefoniaApi) {
      const telefoniaStage: Stage = {
        stage: "TELEFONIA",
        configJson: { api: "API_TELEFONIA" },
      };
      body.pipelines.push(telefoniaStage);
    }
    // ENVIAR PARA CAMPANHA
    if (enviarParaCampanhaWhatsApp) {
      const enviaWhats: Stage = { stage: "ENVIA_WHATS", configJson: {} };
      body.pipelines.push(enviaWhats);
    }
    if (enviarParaCampanhaSMS) {
      const enviaSMS: Stage = { stage: "ENVIA_CAMPANHA_SMS", configJson: {} };
      body.pipelines.push(enviaSMS);
    }
    if (enviarParaDiscadora) {
      const enviaDiscadora: Stage = {
        stage: "ENVIA_DISCADORA",
        configJson: {},
      };
      body.pipelines.push(enviaDiscadora);
    }
    body.pipelines = body.pipelines.map((p, i) => ({ ...p, ordem: i + 1 }));
    try {
      const { data } = isUpdate
        ? await api.put(`/pipeline/${mailingData.id}`, filters)
        : await api.post("/pipeline", body);
      toast({
        title: `Pipeline ${isUpdate ? "atualizado" : "criado"} com sucesso`,
        status: "success",
        ...toastDefaultStyle,
      });
      makeEvent("update-mailings-table");
      onClose();
    } catch (e: any) {
      toast({
        title: `Erro ao ${isUpdate ? "atualizado" : "criado"} pipeline`,
        status: "error",
        ...toastDefaultStyle,
      });
    } finally {
      setIsSaving(false);
      makeEvent(`mailing-update-${mailingData.id}`);
    }
  };

  const modalFooter = (
    <>
      <Button
        isLoading={isSaving}
        loadingText="Salvando"
        onClick={handleSubmit}
        leftIcon={<FaSave />}
        isDisabled={isDisabled}
      >
        Salvar
      </Button>
      <Button
        leftIcon={<CloseIcon w="12px" h="12px" />}
        variant="outline"
        onClick={onClose}
      >
        Cancelar
      </Button>
    </>
  );

  return (
    <CustomModal
      modalTitle="Opções do Mailing"
      isOpen={isOpen}
      onClose={onClose}
      modalFooter={modalFooter}
      size="6xl"
      closeOnOverlayClick={false}
    >
      <Link display="none" ref={downloadRef} />
      <Text fontSize="20" fontWeight="bold">
        1 - Higienizações:
      </Text>
      <hr style={{ margin: "4px 0 8px" }} />
      <Grid templateColumns="repeat(4,1fr)" gap="10px">
        {higienizacaoFilters.map(
          ({ name, render, field, onClick, isVisible = true }) => {
            const key = render?.().props.filterKey ?? field;
            const isDevelopment = ["ANTECIPACAO_FGTS", "IN_100"].includes(key);
            if (!isVisible) return null;
            if (isDevelopment)
              return (
                <button key={key}>
                  <Checkbox
                    onClick={() => {
                      toast({
                        title: "Serviço em desenvolvimento",
                        ...toastDefaultStyle,
                      });
                    }}
                    isChecked={!!addedHigienizacoes[key].isChecked}
                    isDisabled={isUpdate}
                    pointerEvents="auto"
                    errorMessage={invalidFields[key]}
                    boxProps={{ borderRadius: "full" }}
                  >
                    {name}
                  </Checkbox>
                </button>
              );
            else {
              if (render) {
                return (
                  <Flex justifyContent="flex-start" key={key}>
                    <Popover
                      position="right-bottom"
                      closeOnBlur
                      title={name}
                      button={
                        <button>
                          <Checkbox
                            onClick={() => {
                              onClick?.();
                            }}
                            isChecked={!!addedHigienizacoes[key].isChecked}
                            boxProps={{ borderRadius: "full" }}
                            errorMessage={invalidFields[key]}
                            checkedIcon={(isChecked) => (
                              <Center
                                w="100%"
                                h="100%"
                                borderRadius="full"
                                bg="primary.300"
                                opacity={isChecked ? "1" : "0"}
                                children={
                                  <Box
                                    w="50%"
                                    h="50%"
                                    bg="#fff"
                                    borderRadius="full"
                                  />
                                }
                              />
                            )}
                          >
                            {name}
                          </Checkbox>
                        </button>
                      }
                      popupStyles={{ minWidth: "350px" }}
                      isDisabled={isDisabledFields}
                    >
                      {render?.()}
                    </Popover>
                  </Flex>
                );
              } else {
                return (
                  <button>
                    <Checkbox
                      onClick={() => {
                        onClick?.();
                      }}
                      isChecked={!!addedHigienizacoes[key].isChecked}
                      boxProps={{ borderRadius: "full" }}
                      errorMessage={invalidFields[key]}
                      checkedIcon={(isChecked) => (
                        <Center
                          w="100%"
                          h="100%"
                          borderRadius="full"
                          bg="primary.300"
                          opacity={isChecked ? "1" : "0"}
                          children={
                            <Box
                              w="50%"
                              h="50%"
                              bg="#fff"
                              borderRadius="full"
                            />
                          }
                        />
                      )}
                    >
                      {name}
                    </Checkbox>
                  </button>
                );
              }
            }
          }
        )}
      </Grid>
      <Text mt="10px" fontSize="20" fontWeight="bold">
        2 - Filtros{" "}
        <Text as="span" fontSize="16px">
          (Baseado no resultado anterior)
        </Text>
        :
      </Text>
      <hr style={{ margin: "4px 0 8px" }} />
      <Grid templateColumns="1fr 1fr 1fr 1fr" gap="10px" w="100%">
        {filters.map(({ name, key, render, isVisible = true }) => {
          if (!isVisible) return null;
          return !render ? (
            <button key={key}>
              <Checkbox
                onClick={() => {
                  setAddedFilters((addedFilters) => ({
                    ...addedFilters,
                    [key]: {
                      ...addedFilters[key],
                      isChecked: !addedFilters[key].isChecked,
                      value: !addedFilters[key].isChecked,
                    },
                  }));
                }}
                errorMessage={invalidFields[key]}
                isChecked={addedFilters[key].isChecked}
              >
                {name}
              </Checkbox>
            </button>
          ) : (
            <Flex key={key} justifyContent="flex-start">
              <Popover
                position="right-bottom"
                closeOnBlur
                title={name}
                key={key}
                button={
                  <button>
                    <Checkbox
                      onClick={() => {}}
                      isChecked={addedFilters[key].isChecked}
                      errorMessage={invalidFields[key]}
                    >
                      {name}
                    </Checkbox>
                  </button>
                }
                popupStyles={{ minWidth: "350px" }}
              >
                {render()}
              </Popover>
            </Flex>
          );
        })}
      </Grid>
      <Text mt="10px" fontSize="20" fontWeight="bold">
        3 - Telefonia{" "}
        <Text as="span" fontSize="16px">
          (Baseado no resultado anterior)
        </Text>
        :
      </Text>
      <hr style={{ margin: "4px 0 8px" }} />
      <Grid templateColumns="1fr 1fr 1fr" gap="10px" w="100%">
        <Checkbox
          onChange={setTelefoniaApi}
          isChecked={telefoniaApi}
          isDisabled={isDisabledFields}
        >
          API Telefonia
        </Checkbox>
      </Grid>
      <Text mt="10px" fontSize="20" fontWeight="bold">
        4 - Enviar para Campanha{" "}
        <Text as="span" fontSize="16px">
          (Baseado no resultado anterior)
        </Text>
        :
      </Text>
      <hr style={{ margin: "4px 0 8px" }} />
      <Grid
        templateColumns={{ base: "1fr 1fr 1fr 1fr" }}
        justifyContent="flex-start"
      >
        <Checkbox
          onChange={(isChecked) => setEnviarParaCampanhaWhatsApp(isChecked)}
          isChecked={enviarParaCampanhaWhatsApp}
          isDisabled={isDisabledFields}
        >
          WhatsApp
        </Checkbox>

        <Checkbox
          onClick={() => {
            toast({
              title: "Serviço em desenvolvimento",
              ...toastDefaultStyle,
            });
          }}
          // onChange={(isChecked) => setEnviarParaDiscadora(isChecked)}
          isChecked={enviarParaDiscadora}
          isDisabled={isDisabledFields}
        >
          Discadora
        </Checkbox>

        {/* <Checkbox
          onClick={() => {
            toast({
              title: "Serviço em desenvolvimento",
              ...toastDefaultStyle,
            });
          }}
          isDisabled={isDisabledFields}
        >
          URA - WhatsApp
        </Checkbox> */}

        <Checkbox
          onChange={setEnviarParaCampanhaSMS}
          isChecked={enviarParaCampanhaSMS}
          isDisabled={isDisabledFields}
        >
          SMS
        </Checkbox>
      </Grid>
    </CustomModal>
  );
}

export const openMailingOptions = (data: MailingData) =>
  makeEvent("open-mailing-options", data);
