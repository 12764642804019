import {
  DeleteIcon,
  RepeatIcon,
  SettingsIcon,
  WarningTwoIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Progress,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import {
  ModalCampanhaSMS,
  openModalCampanhaSMS,
} from "components/campanha-sms-components/modal-campanha-sms";
import {
  ModalErrorsCampanhaSMS,
  openModalErrorsCampanhaSMS,
} from "components/campanha-sms-components/modal-errors-campanha-sms";
import DynamicTable from "components/dynamic-table";
import { RowStatus } from "components/dynamic-table/row-status";
import { Column } from "components/dynamic-table/types";
import { GetLayout } from "components/get-layout";
import { IconButtonWithLoading } from "components/mailing-components/mailing-table/icon-button-with-loading";
import { openModalConfirm } from "components/modal-confirm-new";
import { useEffect, useState } from "react";
import { FaPlay } from "react-icons/fa";

export interface CampanhaSMSRow {
  id: string;
  nome: string;
  mensagem: string | null;
  idUser: number;
  idCustomer: number;
  idMailing: number | null;
  statusSms:
    | "AGUARDANDO CONFIGURAÇÃO"
    | "FINALIZADO"
    | "EM ANDAMENTO"
    | "AGUARDANDO INÍCIO"
    | null;
  statusCampanha:
    | "EM_ANDAMENTO"
    | "AGUARDANDO_CONFIGURACAO"
    | "NAO_INICIADA"
    | "CRIANDO_SMS"
    | "PROCESSANDO"
    | "ERRO"
    | "FINALIZADO"
    | null;
  quantidade: number | null;
  quantidadeMaximaTelLead: number | null;
  errorsLength: number;
  successLength: number;
  waitingLength: number;
  paused: boolean;
  dataCriacao: string;
  errorsMessages: { message: string; count: number }[];
  errorMessageCampanha: string | null;
  quantidadeCpf: number;
}

export const CampanhaSMS = () => {
  const [loading, setLoading] = useState(false);
  const [campanhas, setCampanhas] = useState<CampanhaSMSRow[]>([]);

  const toast = useToast();

  const formatCampanhas = (data: any) => {
    return Object.keys(data.result).map((key) => {
      const result = data.result as any;
      const crr = result[key] as any[];
      const firstData = crr[0];
      const errorsLength =
        crr.find((crr) => crr.statusSms === "ERRO")?.quantidade || 0;
      const successLength =
        crr.find((crr) => crr.statusSms === "ENVIADO")?.quantidade || 0;
      const waitingLength =
        crr.find((crr) => crr.statusSms === "AGUARDANDO_ENVIO")?.quantidade ||
        0;

      const totalMessages = errorsLength + successLength + waitingLength;
      const isFinally = (errorsLength + successLength) / totalMessages === 1;
      const isWaitingConfig = firstData.mensagem == null;
      const isPaused = firstData.paused === true;
      let status = "AGUARDANDO INÍCIO";
      if (isWaitingConfig) status = "AGUARDANDO CONFIGURAÇÃO";
      else if (isFinally) status = "FINALIZADO";
      else if (!isPaused) status = "EM ANDAMENTO";
      return {
        ...firstData,
        statusCampanha: firstData.statusCampanha,
        errorsLength,
        successLength,
        waitingLength,
        errorsMessages: crr
          .filter((campanha) => campanha.errorMessage)
          .map((c) => ({ message: c.errorMessage, count: c.quantidade })),
      };
    });
  };

  const initialLoading = async () => {
    setLoading(true);
    try {
      const { data } = await api.get("/campanha-sms");
      setCampanhas(formatCampanhas(data));
    } catch (e) {
      toast({
        title: "Erro ao obter lista de campanhas",
        status: "error",
        ...toastDefaultStyle,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initialLoading();
  }, []);

  const retry =
    ({ id }: CampanhaSMSRow) =>
    async () => {
      try {
        const { data } = await api.put(`/campanha-sms/${id}/replay`);
        setCampanhas((prev) =>
          prev.map((row) => {
            if (row.id === id) row.statusCampanha = "CRIANDO_SMS";
            return row;
          })
        );
      } catch (e) {
        toast({
          title: "Erro ao continuar campanha",
          status: "error",
          ...toastDefaultStyle,
        });
      } finally {
      }
    };

  const play =
    ({ id }: CampanhaSMSRow) =>
    async () => {
      try {
        const { data } = await api.put(`/campanha-sms/${id}/start`);
        setCampanhas((prev) =>
          prev.map((row) => {
            if (row.id === id) {
              row.statusCampanha = "CRIANDO_SMS";
            }
            return row;
          })
        );
      } catch (e) {
        toast({
          title: "Erro ao continuar campanha",
          status: "error",
          ...toastDefaultStyle,
        });
      } finally {
      }
    };

  const loadCampanhas = async (withLoading = false) => {
    if (withLoading) setLoading(true);
    try {
      const response = await api.get("/campanha-sms");
      setCampanhas(formatCampanhas(response.data));
    } catch (e) {
      toast({
        title: "Erro ao carregar campanhas",
        status: "error",
        ...toastDefaultStyle,
      });
    } finally {
      if (withLoading) setLoading(false);
    }
  };

  const acceptDeleteCampanha = async (row: any) => {
    setLoading(true);
    try {
      await api.delete(`/campanha-sms/${row.id}`);
      await initialLoading();
    } catch (error) {
      toast({
        title: "Erro ao excluir campanha!",
        status: "error",
        ...toastDefaultStyle,
      });
    }
    await loadCampanhas();
    setLoading(false);
  };

  const renderRowCampanhaStatus = (statusCampanha: any) => {
    switch (statusCampanha) {
      case "AGUARDANDO_CONFIGURACAO":
        return "AGUARDANDO CONFIGURAÇÃO";
      case "NAO_INICIADA":
        return "NÃO INICIADA";
      case "CRIANDO_SMS":
        return "CRIANDO SMSs";
      case "PROCESSANDO":
        return "PROCESSANDO";
      case "ERRO":
        return "ERRO";
      case "EM_ANDAMENTO":
        return "EM ANDAMENTO";
      case "FINALIZADO":
        return "FINALIZADO";
      default:
        break;
    }
  };

  const columns: Column[] = [
    {
      name: "Nome",
      key: "nome",
    },
    {
      name: "Dt. Criação",
      render: (row) => {
        return row.dataCriacao
          ? new Date(row.dataCriacao).toLocaleDateString()
          : null;
      },
    },
    {
      name: "Status",
      render: (row) => {
        return row.statusCampanha ? (
          <RowStatus status={row.statusCampanha}>
            {renderRowCampanhaStatus(row.statusCampanha)}
          </RowStatus>
        ) : null;
      },
      key: "status",
      sortClick: true,
    },
    {
      name: "Qtd Erros",
      render: (row) => {
        return row.errorsLength;
      },
    },
    {
      name: "Qtd de cpfs enviados",
      render: (row) => {
        return row.quantidadeCpf;
      },
    },
    {
      name: "Qtd Telefones Enviados",
      render: (row) => {
        const totalSended = row.errorsLength + row.successLength;
        const totalMessages =
          row.errorsLength + row.successLength + row.waitingLength;
        return (
          <Box>
            <Text mb="4px">
              {totalSended}/{totalMessages}
            </Text>
            <Progress
              value={(totalSended / totalMessages) * 100}
              size="xs"
              bg="gray.200"
              borderRadius="10"
              colorScheme="gray"
            />
          </Box>
        );
      },
    },

    {
      name: "Ações",
      render: (row: CampanhaSMSRow) => {
        const enableRetry =
          (row.statusCampanha !== "PROCESSANDO" &&
            row.errorsMessages
              .map(({ message: m }) => {
                m = m.toLowerCase();
                return m.includes("saldo") || m.includes("indisponível");
              })
              .includes(true)) ||
          row.errorMessageCampanha;
        const isPlay = row.statusCampanha === "NAO_INICIADA";

        return (
          <Flex>
            <IconButtonWithLoading
              tooltipMessage="Ver erros da campanha"
              aria-label=""
              icon={<WarningTwoIcon />}
              mr="8px"
              onClick={() => openModalErrorsCampanhaSMS(row)}
              isDisabled={
                !row.errorsMessages.length && !row.errorMessageCampanha
              }
            />
            <IconButtonWithLoading
              tooltipMessage="Opções da campanha"
              aria-label=""
              icon={<SettingsIcon />}
              mr="8px"
              onClick={() => openModalCampanhaSMS(row)}
              isDisabled={
                !(
                  row.statusCampanha === "FINALIZADO" ||
                  row.statusCampanha === "AGUARDANDO_CONFIGURACAO" ||
                  row.statusCampanha === "NAO_INICIADA"
                )
              }
            />
            <IconButtonWithLoading
              tooltipMessage={
                isPlay ? "Iniciar Campanha" : "Refazer envio de SMSs com erros"
              }
              aria-label=""
              icon={isPlay ? <FaPlay /> : <RepeatIcon />}
              mr="8px"
              onClick={isPlay ? play(row) : retry(row)}
              isDisabled={isPlay ? false : !enableRetry}
            />
            <Tooltip label="Deletar campanha">
              <IconButton
                aria-label=""
                icon={<DeleteIcon />}
                variant="danger"
                isDisabled={
                  row.statusCampanha === "CRIANDO_SMS" ||
                  row.statusCampanha === "PROCESSANDO" ||
                  row.statusCampanha === "EM_ANDAMENTO"
                }
                onClick={() =>
                  openModalConfirm({
                    message: `Deseja excluir "${row.nome}" ?`,
                    onConfirm: () => acceptDeleteCampanha(row),
                    confirmButtonStyle: {
                      variant: "danger",
                      leftIcon: <DeleteIcon />,
                      loadingText: "Excluindo",
                    },
                  })
                }
              />
            </Tooltip>
          </Flex>
        );
      },
    },
  ];

  return (
    <GetLayout>
      <Flex justifyContent="space-between" px="10px" mb="30px">
        <Text fontSize="25" fontWeight="bold">
          Campanhas SMS
        </Text>

        <Flex gap="20px" alignItems="center">
          <Button
            leftIcon={<RepeatIcon />}
            onClick={() => loadCampanhas(true)}
            isLoading={loading}
            loadingText="Atualizando"
          >
            Atualizar
          </Button>
        </Flex>
      </Flex>
      <DynamicTable columns={columns} rows={campanhas} isLoading={loading} />

      <ModalCampanhaSMS setCampanhas={setCampanhas} />
      <ModalErrorsCampanhaSMS />
    </GetLayout>
  );
};
