import { dropdownBancos } from "components/usuarios-bancos-components/consts";
import { MailingData, MailingOptionFilter } from "../..";
import { Dispatch, SetStateAction } from "react";
import { Filter } from "../../filter";

export const initialFilterValues: MailingOptionFilter = {
  idade: { isChecked: false, value: { gte: "", lte: "" } },
  possui_rmc: { isChecked: false, value: false },
  margem_rmc: { isChecked: false, value: { gte: "", lte: "" } },
  possui_rcc: { isChecked: false, value: false },
  margem_rcc: { isChecked: false, value: { gte: "", lte: "" } },
  banco_operacao: { isChecked: false, value: [] },
  saldo_devedor: { isChecked: false, value: { gte: "", lte: "" } },
  taxa_contrato: { isChecked: false, value: { gte: "", lte: "" } },
  valor_parcela: { isChecked: false, value: { gte: "", lte: "" } },
  valor_liberado: { isChecked: false, value: { gte: "", lte: "" } },
  quantidade_contratos: { isChecked: false, value: { gte: "", lte: "" } },
  numero_parcelas_pagas: { isChecked: false, value: { gte: "", lte: "" } },
  valor_antecipacao: { isChecked: false, value: "" },
  valor_liberado_saque_rcc: { isChecked: false, value: "" },
  valor_liberado_saque_rmc: { isChecked: false, value: "" },
  margem_aumento: { isChecked: false, value: "" },
};

interface MailingFilterArrayProps {
  addedFilters: MailingOptionFilter;
  setFilters: Dispatch<SetStateAction<MailingOptionFilter>>;
  allModalData: MailingOptionFilter;
  isDisabledFields: boolean;
  setInvalidFields: Dispatch<SetStateAction<{ [field: string]: string }>>;
  mailingData: Partial<MailingData>;
}

export const mailingFilterArray = ({
  addedFilters,
  setFilters,
  allModalData,
  isDisabledFields,
  setInvalidFields,
  mailingData,
}: MailingFilterArrayProps): {
  name: string;
  key: string;
  isVisible?: boolean;
  render?: () => JSX.Element;
}[] => {
  const onChangeFilter = (
    value: SetStateAction<MailingOptionFilter>,
    key: string
  ) => {
    const getValue = typeof value === "function" ? value(addedFilters) : value;
    console.log(getValue);
    setFilters(getValue);
    setInvalidFields((errors) => {
      delete errors[key];
      return errors;
    });
  };

  let noneChecked =
    !allModalData.REFIN.isChecked &&
    !allModalData.SAQUE_COMPLEMENTAR.isChecked &&
    !allModalData.ANTECIPACAO_FGTS.isChecked &&
    !allModalData.SIMULACAO_AUMENTO.isChecked;

  return [
    {
      name: "Idade",
      key: "idade",
      render: () => {
        return (
          <Filter
            type="between"
            setAddedFilters={onChangeFilter}
            addedFilters={addedFilters}
            valueType="number"
            filterKey="idade"
            isDisabled={isDisabledFields}
          />
        );
      },
      isVisible: allModalData.REFIN.isChecked || noneChecked,
    },
    // {
    //   name: "Possui RMC",
    //   key: "possui_rmc",
    //   isVisible: allModalData.REFIN.isChecked || noneChecked,
    // },
    // {
    //   name: "Margem RMC",
    //   key: "margem_rmc",
    //   render: () => {
    //     return (
    //       <Filter
    //         type="between"
    //         setAddedFilters={onChangeFilter}
    //         addedFilters={addedFilters}
    //         valueType="number"
    //         filterKey="margem_rmc"
    //         isDisabled={isDisabledFields}
    //       />
    //     );
    //   },
    //   isVisible: allModalData.REFIN.isChecked || noneChecked,
    // },
    // {
    //   name: "Possui RCC",
    //   key: "possui_rcc",
    //   isVisible: allModalData.REFIN.isChecked || noneChecked,
    // },
    // {
    //   name: "Margem RCC",
    //   key: "margem_rcc",
    //   render: () => {
    //     return (
    //       <Filter
    //         type="between"
    //         setAddedFilters={onChangeFilter}
    //         addedFilters={addedFilters}
    //         valueType="number"
    //         filterKey="margem_rcc"
    //         isDisabled={isDisabledFields}
    //       />
    //     );
    //   },
    //   isVisible: allModalData.REFIN.isChecked || noneChecked,
    // },
    {
      name: "Quantidade de Contratos",
      key: "quantidade_contratos",
      render: () => {
        return (
          <Filter
            type="between"
            setAddedFilters={onChangeFilter}
            addedFilters={addedFilters}
            valueType="number"
            filterKey="quantidade_contratos"
            isDisabled={isDisabledFields}
          />
        );
      },
      isVisible: allModalData.REFIN.isChecked || noneChecked,
    },
    {
      name: "Banco Operação",
      key: "banco_operacao",
      render: () => {
        return (
          <Filter
            type="dropdown"
            setAddedFilters={onChangeFilter}
            addedFilters={addedFilters}
            filterKey="banco_operacao"
            componentProps={{ options: dropdownBancos }}
            isDisabled={isDisabledFields}
          />
        );
      },
      isVisible: allModalData.REFIN.isChecked || noneChecked,
    },
    {
      name: "Saldo Devedor",
      key: "saldo_devedor",
      render: () => {
        return (
          <Filter
            type="between"
            setAddedFilters={onChangeFilter}
            addedFilters={addedFilters}
            valueType="number"
            filterKey="saldo_devedor"
            isDisabled={isDisabledFields}
          />
        );
      },
      isVisible: allModalData.REFIN.isChecked || noneChecked,
    },
    {
      name: "Taxa Contrato",
      key: "taxa_contrato",
      render: () => {
        return (
          <Filter
            type="between"
            setAddedFilters={onChangeFilter}
            addedFilters={addedFilters}
            valueType="number"
            filterKey="taxa_contrato"
            isDisabled={isDisabledFields}
          />
        );
      },
      isVisible: allModalData.REFIN.isChecked || noneChecked,
    },
    {
      name: "Valor Parcela",
      key: "valor_parcela",
      render: () => {
        return (
          <Filter
            type="between"
            setAddedFilters={onChangeFilter}
            addedFilters={addedFilters}
            valueType="number"
            filterKey="valor_parcela"
            isDisabled={isDisabledFields}
          />
        );
      },
      isVisible: allModalData.REFIN.isChecked || noneChecked,
    },
    {
      name: "Valor Liberado",
      key: "valor_liberado",
      render: () => {
        return (
          <Filter
            type="between"
            setAddedFilters={onChangeFilter}
            addedFilters={addedFilters}
            valueType="number"
            filterKey="valor_liberado"
            isDisabled={isDisabledFields}
          />
        );
      },
      isVisible: allModalData.REFIN.isChecked || noneChecked,
    },
    {
      name: "Valor Máximo Saque RMC",
      key: "valor_liberado_saque_rmc",
      render: () => {
        return (
          <Filter
            type="between"
            setAddedFilters={onChangeFilter}
            addedFilters={addedFilters}
            valueType="number"
            filterKey="valor_liberado_saque_rmc"
            isDisabled={isDisabledFields}
          />
        );
      },
      isVisible:
        allModalData.SAQUE_COMPLEMENTAR?.value?.[0]?.tipoCartao === "RMC",
    },
    {
      name: "Valor Máximo Saque RCC",
      key: "valor_liberado_saque_rcc",
      render: () => {
        return (
          <Filter
            type="between"
            setAddedFilters={onChangeFilter}
            addedFilters={addedFilters}
            valueType="number"
            filterKey="valor_liberado_saque_rcc"
            isDisabled={isDisabledFields}
          />
        );
      },
      isVisible:
        allModalData.SAQUE_COMPLEMENTAR?.value?.[0]?.tipoCartao === "RCC",
    },
    {
      name: "Número de Parcelas Pagas",
      key: "numero_parcelas_pagas",
      render: () => {
        return (
          <Filter
            type="between"
            setAddedFilters={onChangeFilter}
            addedFilters={addedFilters}
            valueType="number"
            filterKey="numero_parcelas_pagas"
            isDisabled={isDisabledFields}
          />
        );
      },
      isVisible: allModalData.REFIN.isChecked || noneChecked,
    },
    {
      name: "Valor Antecipação",
      key: "valor_antecipacao",
      render: () => {
        return (
          <Filter
            type="input"
            setAddedFilters={onChangeFilter}
            addedFilters={addedFilters}
            valueType="number"
            filterKey="valor_antecipacao"
            isDisabled={isDisabledFields}
          />
        );
      },
      isVisible: allModalData.ANTECIPACAO_FGTS.isChecked,
    },
    {
      name: "Margem Aumento",
      key: "margem_aumento",
      render: () => {
        return (
          <Filter
            type="between"
            setAddedFilters={onChangeFilter}
            addedFilters={addedFilters}
            valueType="number"
            filterKey="margem_aumento"
            isDisabled={isDisabledFields}
          />
        );
      },
      isVisible: allModalData.SIMULACAO_AUMENTO.isChecked,
    },
    // {
    //   name: "Convenios",
    //   key: "convenios",
    //   render: () => {
    //     return (
    //       <Filter
    //         type="dropdown"
    //         setAddedFilters={setAddedFilters}
    //         addedFilters={addedFilters}
    //         filterKey="convenios"
    //         componentProps={{
    //           asyncOptions: async (setLoading?: (s: boolean) => void) => {
    //             setLoading?.(true);
    //             try {
    //               // const { data } = await api.get(`/`);
    //               // return data.map((key: string) => {
    //               //   return { name: key, value: key };
    //               // });
    //             } catch (e) {
    //               return [];
    //             } finally {
    //               setLoading?.(false);
    //             }
    //           },
    //         }}
    //         isDisabled={isDisabledFields}
    //       />
    //     );
    //   },
    //   isVisible: allModalData.SAQUE_COMPLEMENTAR.isChecked,
    // },
  ];
};
