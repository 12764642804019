export type Listener = (a: any) => any;
export type ListenerList = Listener | Map<number | string, Listener>;
let listeners: Map<number | string, ListenerList> = new Map();

export function createEventListener(
  eventType: string,
  listener: (e: any) => any,
  id?: string
) {
  const target = listeners.get(eventType);
  if (id) {
    if (target === undefined) listeners.set(eventType, new Map());
    const targetMap = listeners.get(eventType);
    if (typeof targetMap === "object") targetMap.set(id, listener);
  } else listeners.set(eventType, listener);
}

export function makeEvent(
  eventType: string,
  data?: any,
  { throwsError = true }: { throwsError?: boolean } = {}
) {
  const target = listeners.get(eventType);
  if (typeof target === "object") {
    target.forEach((listener) => listener(data));
  } else if (target) {
    return target(data);
  } else if (throwsError) throw new Error(`Listerner Not Found ${eventType}`);
}

export function getEventListeners(key: string) {
  return listeners.get(key);
}
