import {
  ButtonProps,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Spinner,
} from "@chakra-ui/react";
import { defaultScroll } from "chakra/theme";
import { ReactNode, RefObject } from "react";

interface CustomModalProps
  extends Omit<ModalContentProps, "closeOnOverlayClick"> {
  children: ReactNode;
  modalFooter?: string | ReactNode;
  modalTitle?: string | ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  size?:
    | "xs"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "full";
  withOutBlur?: boolean;
  scroll?: "outside" | "inside";
  isLoading?: boolean;
  finalFocusRef?: RefObject<any>;
  closeOnOverlayClick?: ModalProps["closeOnOverlayClick"];
  id?: string;
  modalCloseButtonProps?: ButtonProps;
}

export function CustomModal({
  children,
  modalFooter,
  modalTitle,
  isOpen,
  onClose,
  size,
  withOutBlur,
  isLoading,
  scroll,
  finalFocusRef,
  closeOnOverlayClick,
  modalCloseButtonProps,
  id,
  ...rest
}: CustomModalProps) {
  const isFull = size === "full";
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose || (() => {})}
      isCentered
      size={size}
      scrollBehavior={scroll}
      finalFocusRef={finalFocusRef}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay backdropFilter={withOutBlur ? undefined : "blur(8px)"} />
      <ModalContent my="0" {...rest}>
        {modalTitle ? <ModalHeader>{modalTitle}</ModalHeader> : null}
        {onClose ? (
          <ModalCloseButton zIndex="1" {...modalCloseButtonProps} />
        ) : null}
        <ModalBody sx={defaultScroll} pos="relative" id={id}>
          {isLoading ? (
            <Flex
              pos={isFull ? "fixed" : "absolute"}
              top={isFull ? "62px" : "0"}
              right="0"
              bottom={isFull ? "72px" : "0"}
              left="0"
              backdropFilter="blur(8px)"
              bg="rgba(255,255,255, 0.1)"
              justifyContent="center"
              alignItems="center"
              zIndex="100"
            >
              <Spinner zIndex="101" />
            </Flex>
          ) : null}
          {children}
        </ModalBody>
        {modalFooter ? (
          <ModalFooter gap="15px">{modalFooter}</ModalFooter>
        ) : null}
      </ModalContent>
    </Modal>
  );
}
