import { Box, Grid, Text } from "@chakra-ui/react";
import AttendanceDropdown from "components/performance/attendance-dropdown";
import OptimizedInput from "components/performance/attendance-input";
import { useAttendanceContext } from "contexts/AttendanceContext";
import { currencyMaskFormat, numberMask } from "utils/string-formats";
import {
  dropdownEspecies,
  dropdownEstadoCivil,
  dropdownSexoAtendimento,
  dropdownSimNao,
  dropdownSituacaoBeneficio,
  numerosdeBenefico,
} from "../../../fields-data";
import { Attendance } from "../../../types";
import { TabDataProps } from "../../consts";

export function DadosCadastraisFields({
  onChangeField,
  resetFieldsTabelaDigitacao,
}: TabDataProps) {
  const { formControl } = useAttendanceContext();
  const formValues = formControl.values as Attendance;

  const convenio = formValues.convenio;
  return (
    <>
      <Grid templateColumns="repeat(4, 1fr)" gap="16px">
        {formValues.convenio !== "ANTECIPACAO_FGTS" ? (
          <Box>
            <Text mb="4px">
              {numerosdeBenefico[formValues.convenio ?? "default"] ??
                "Número do Benefício"}
            </Text>
            <OptimizedInput
              value={formValues.nb || ""}
              onChangeField={onChangeField}
              fieldName="nb"
            />
          </Box>
        ) : null}
        {!formValues.convenio || formValues.convenio === "INSS" ? (
          <Box gridArea="auto / span 3">
            <Text mb="4px">Espécie Benefício</Text>
            <AttendanceDropdown
              value={formValues.especieNB || ""}
              fieldName="especieNB"
              onChangeField={onChangeField}
              options={dropdownEspecies}
              placeholder="Selecione"
              w="full"
            />
          </Box>
        ) : null}

        <Box>
          <Text mb="4px">Sexo</Text>
          <AttendanceDropdown
            value={formValues.sexo || ""}
            fieldName="sexo"
            onChangeField={onChangeField}
            options={dropdownSexoAtendimento}
            placeholder="Selecione"
            w="full"
          />
        </Box>
        <Box gridArea="auto / span 2">
          <Text mb="4px">Estado Civil</Text>
          <AttendanceDropdown
            value={formValues.estadoCivil}
            onChangeField={onChangeField}
            fieldName="estadoCivil"
            options={dropdownEstadoCivil}
            w="full"
          />
        </Box>

        {!formValues.convenio || formValues.convenio === "INSS" ? (
          <>
            <Box>
              <Text mb="4px">Situação Ben.</Text>
              <AttendanceDropdown
                value={formValues.situacaoNB || ""}
                fieldName="situacaoNB"
                onChangeField={onChangeField}
                options={dropdownSituacaoBeneficio}
                placeholder="Selecione"
                w="full"
              />
            </Box>
            <Box gridArea="auto / span 2">
              <Text mb="4px">DDB</Text>
              <OptimizedInput
                value={formValues.ddb || ""}
                onChangeField={onChangeField}
                fieldName="ddb"
              />
            </Box>
            <Box gridArea="auto / span 2">
              <Text mb="4px">UF Ben.</Text>
              <OptimizedInput
                value={formValues.ufNB || ""}
                onChangeField={onChangeField}
                fieldName="ufNB"
              />
            </Box>
          </>
        ) : null}

        <Box>
          <Text mb="4px">Documento</Text>
          <OptimizedInput
            value={formValues.documento || ""}
            onChangeField={onChangeField}
            fieldName="documento"
          />
        </Box>
        <Box gridArea={convenio === "ANTECIPACAO_FGTS" ? "auto / span 2" : ""}>
          <Text mb="4px">Órgão Emissor Doc.</Text>
          <OptimizedInput
            value={formValues.orgaoEmissorDoc || ""}
            onChangeField={onChangeField}
            fieldName="orgaoEmissorDoc"
          />
        </Box>
        <Box>
          <Text mb="4px">UF Emissão Doc.</Text>
          <OptimizedInput
            value={formValues.ufEmissaoDoc || ""}
            onChangeField={onChangeField}
            fieldName="ufEmissaoDoc"
          />
        </Box>
        <Box>
          <Text mb="4px">Data Emissão Doc.</Text>
          <OptimizedInput
            // value={maskDate(formValues.dataEmissaoDoc || "").mask}
            value={formValues.dataEmissaoDoc || ""}
            onChangeField={onChangeField}
            fieldName="dataEmissaoDoc"
            // type="date"
            // mask={(value) => maskDate(value).raw}
          />
        </Box>
        <Box gridArea="auto / span 2">
          <Text mb="4px">Nome Mãe</Text>
          <OptimizedInput
            value={formValues.nomeMae || ""}
            onChangeField={onChangeField}
            fieldName="nomeMae"
          />
        </Box>
        <Box gridArea="auto / span 2">
          <Text mb="4px">Nome Pai</Text>
          <OptimizedInput
            value={formValues.nomePai || ""}
            onChangeField={onChangeField}
            fieldName="nomePai"
          />
        </Box>
        <Box>
          <Text mb="4px">Nascimento</Text>
          <OptimizedInput
            // value={maskDate(formValues.dataNascimento || "").mask}
            value={formValues.dataNascimento || ""}
            onChangeField={onChangeField}
            fieldName="dataNascimento"
            // type="date"
            // mask={(value) => maskDate(value).raw}
          />
        </Box>
        <Box>
          <Text mb="4px">Idade</Text>
          <OptimizedInput
            value={"XX"}
            readOnly
            fontWeight="600"
            cursor="not-allowed"
          />
        </Box>

        <Box>
          <Text mb="4px">Naturalidade</Text>
          <OptimizedInput
            value={formValues.naturalidade || ""}
            onChangeField={onChangeField}
            fieldName="naturalidade"
          />
        </Box>
        <Box>
          <Text mb="4px">UF Naturalidade</Text>
          <OptimizedInput
            value={formValues.ufNaturalidade || ""}
            onChangeField={onChangeField}
            fieldName="ufNaturalidade"
          />
        </Box>
      </Grid>
      <Grid templateColumns="1fr 1fr 1fr" gap="16px">
        <Box>
          <Text mb="4px">Telefone Residencial</Text>
          <OptimizedInput
            value={formValues.telefoneResidencial || ""}
            onChangeField={onChangeField}
            fieldName="telefoneResidencial"
          />
        </Box>
        <Box>
          <Text mb="4px">Telefone Comercial</Text>
          <OptimizedInput
            value={formValues.telefoneComercial || ""}
            onChangeField={onChangeField}
            fieldName="telefoneComercial"
          />
        </Box>
        <Box>
          <Text mb="4px">Telefone Celular</Text>
          <OptimizedInput
            value={formValues.telefoneCelular || ""}
            onChangeField={onChangeField}
            fieldName="telefoneCelular"
          />
        </Box>
      </Grid>
      <Grid templateColumns="repeat(4, 1fr)" gap="16px">
        <Box>
          <Text mb="4px">CEP</Text>
          <OptimizedInput
            value={formValues.cep || ""}
            onChangeField={onChangeField}
            fieldName="cep"
          />
        </Box>
        <Box gridArea="auto / span 2">
          <Text mb="4px">Endereço</Text>
          <OptimizedInput
            value={formValues.endereco || ""}
            onChangeField={onChangeField}
            fieldName="endereco"
          />
        </Box>
        <Box>
          <Text mb="4px">Número</Text>
          <OptimizedInput
            value={formValues.numero || ""}
            onChangeField={onChangeField}
            fieldName="numero"
          />
        </Box>
        <Box>
          <Text mb="4px">Complemento</Text>
          <OptimizedInput
            value={formValues.complemento || ""}
            onChangeField={onChangeField}
            fieldName="complemento"
          />
        </Box>
        <Box>
          <Text mb="4px">Bairro</Text>
          <OptimizedInput
            value={formValues.bairro || ""}
            onChangeField={onChangeField}
            fieldName="bairro"
          />
        </Box>
        <Box>
          <Text mb="4px">Cidade</Text>
          <OptimizedInput
            value={formValues.cidade || ""}
            onChangeField={onChangeField}
            fieldName="cidade"
          />
        </Box>
        <Box>
          <Text mb="4px">UF</Text>
          <OptimizedInput
            value={formValues.uf || ""}
            onChangeField={onChangeField}
            fieldName="uf"
          />
        </Box>

        <Box>
          <Text mb="4px">Valor Salário (MR)</Text>
          <OptimizedInput
            value={currencyMaskFormat(formValues.valorSalario || "").value}
            onChangeField={onChangeField}
            fieldName="valorSalario"
            mask={(value) => currencyMaskFormat(value || "").raw}
          />
        </Box>

        {!formValues.convenio ||
        formValues.convenio === "INSS" ||
        formValues.convenio === "SIAPE" ? (
          <>
            <Box>
              <Text mb="4px">Pensão Alimentícia</Text>
              <AttendanceDropdown
                value={
                  formValues.pensaoAlimenticia === undefined ||
                  formValues.pensaoAlimenticia === null
                    ? ""
                    : formValues.pensaoAlimenticia
                }
                fieldName="pensaoAlimenticia"
                onChangeField={onChangeField}
                options={dropdownSimNao}
                w="full"
              />
            </Box>
            <Box>
              <Text mb="4px">Possui Representante</Text>
              <AttendanceDropdown
                value={
                  formValues.possuiRepresentante === undefined ||
                  formValues.possuiRepresentante === null
                    ? ""
                    : formValues.possuiRepresentante
                }
                fieldName="possuiRepresentante"
                onChangeField={onChangeField}
                options={dropdownSimNao}
                w="full"
              />
            </Box>
            {formValues.possuiRepresentante ? (
              <>
                <Box>
                  <Text mb="4px">CPF Representante Legal</Text>
                  <OptimizedInput
                    value={formValues.cpfRepresentanteLegal || ""}
                    onChangeField={onChangeField}
                    fieldName="cpfRepresentanteLegal"
                    readOnly={!formValues.possuiRepresentante}
                  />
                </Box>
                <Box>
                  <Text mb="4px">Nome Representante Legal</Text>
                  <OptimizedInput
                    value={formValues.nomeRepresentanteLegal || ""}
                    onChangeField={onChangeField}
                    fieldName="nomeRepresentanteLegal"
                    readOnly={!formValues.possuiRepresentante}
                  />
                </Box>
              </>
            ) : null}
            <Box>
              <Text mb="4px">Bloqueado Empréstimo</Text>
              <AttendanceDropdown
                value={
                  formValues.bloqueadoEmprestimo === undefined ||
                  formValues.bloqueadoEmprestimo === null
                    ? ""
                    : formValues.bloqueadoEmprestimo
                }
                fieldName="bloqueadoEmprestimo"
                onChangeField={onChangeField}
                options={dropdownSimNao}
                w="full"
              />
            </Box>
            <Box>
              <Text mb="4px">Elegível Empréstimo</Text>
              <AttendanceDropdown
                value={
                  formValues.elegivelEmprestimo === undefined ||
                  formValues.elegivelEmprestimo === null
                    ? ""
                    : formValues.elegivelEmprestimo
                }
                fieldName="elegivelEmprestimo"
                onChangeField={onChangeField}
                options={dropdownSimNao}
                w="full"
              />
            </Box>
          </>
        ) : null}
        {formValues.convenio === "SIAPE" ? (
          <>
            <Box>
              <Text mb="4px">Orgão (SIAPE)</Text>
              <OptimizedInput
                value={formValues.orgaoSIAPE || ""}
                onChangeField={onChangeField}
                fieldName="orgaoSIAPE"
              />
            </Box>
            <Box>
              <Text mb="4px">Situacão Funcional (SIAPE)</Text>
              <OptimizedInput
                value={formValues.situacaoFuncionalSIAPE || ""}
                onChangeField={onChangeField}
                fieldName="situacaoFuncionalSIAPE"
              />
            </Box>
            <Box>
              <Text mb="4px">Unidade Pagadora (SIAPE)</Text>
              <OptimizedInput
                value={formValues.unidadePagadoraSIAPE || ""}
                onChangeField={onChangeField}
                fieldName="unidadePagadoraSIAPE"
              />
            </Box>
          </>
        ) : null}
      </Grid>
    </>
  );
}
