import { Template } from "../mailing-template";
import { InputProps, RadioGroupProps } from "@chakra-ui/react";
import { MailingFilterOption, MailingFilterProps } from "../../types";
import { RadioFilter } from "./radio-filter";

export function BooleanRadioFilter({
  title,
  description,
  mapKey,
  onChangeFilter,
  operators,
  filtersValues,
  radioGroupProps,
  parseValue,
}: {
  radioGroupProps?: Omit<RadioGroupProps, "children">;
  operators: any;
  parseValue: { [key: string]: MailingFilterOption };
} & Omit<MailingFilterProps, "operators">) {
  const filter = filtersValues.get(mapKey);
  const radioProps = radioGroupProps as RadioGroupProps;
  return (
    <Template title={title} description={description} mapKey={mapKey}>
      <RadioFilter
        mapKey={mapKey}
        operators={operators}
        onChangeFilter={onChangeFilter}
        radioGroupProps={{
          onChange: (value: string) => {
            if (value !== "") {
              const op = parseValue[value];
              onChangeFilter(mapKey, (prev) => {
                prev.set(mapKey, { op, value: [Number(value)] });
                return new Map(prev.entries());
              });
            } else
              onChangeFilter(mapKey, (prev) => {
                prev.delete(mapKey);
                return new Map(prev.entries());
              });
          },
          value: filter?.op ? filter?.value?.[0] : "",
          ...radioProps,
        }}
      />
    </Template>
  );
}
