import { Icon, IconProps } from "@chakra-ui/react";

export function CheckListIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 115 153"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.2362 6.69118C49.3709 2.82389 52.9458 0 57.1801 0C61.4145 0 64.9894 2.82389 66.1241 6.69118H75.1029C77.7425 6.69118 79.8824 8.83099 79.8824 11.4706V19.5956C79.8824 22.2352 77.7425 24.375 75.1029 24.375H38.7794C36.1398 24.375 34 22.2352 34 19.5956V11.4706C34 8.83099 36.1398 6.69118 38.7794 6.69118H48.2362ZM56.9412 12.9045C58.7889 12.9045 60.2868 11.4066 60.2868 9.55887C60.2868 7.71115 58.7889 6.21328 56.9412 6.21328C55.0935 6.21328 53.5956 7.71115 53.5956 9.55887C53.5956 11.4066 55.0935 12.9045 56.9412 12.9045Z"
        fill="currentColor"
      />
      <path
        d="M32 65L20 53L23.7647 48.7647L32 57L48 41L52 45L32 65Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <rect x="52" y="51" width="42" height="6" rx="1" fill="currentColor" />
      <path
        d="M32 96L20 84L23.7647 79.7647L32 88L48 72L52 76L32 96Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <rect x="52" y="82" width="42" height="6" rx="1" fill="currentColor" />
      <path
        d="M32 127L20 115L23.7647 110.765L32 119L48 103L52 107L32 127Z"
        fill="currentColor"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <rect x="52" y="113" width="42" height="6" rx="1" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85 20H97C101.971 20 106 24.0294 106 29V135C106 139.971 101.971 144 97 144H18C13.0294 144 9 139.971 9 135V29C9 24.0294 13.0294 20 18 20H29V11H18C8.05888 11 0 19.0589 0 29V135C0 144.941 8.05888 153 18 153H97C106.941 153 115 144.941 115 135V29C115 19.0589 106.941 11 97 11H85V20Z"
        fill="currentColor"
      />
    </Icon>
  );
}
