import { Icon, IconProps } from "@chakra-ui/react";

export function PhoneIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="18px"
      height="25px"
      viewBox="0 0 18 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16.1837 18.8536L15.7042 18.053C14.8419 16.6312 13.6517 14.9993 12.0907 14.9993C11.8015 14.9993 11.515 15.0574 11.2315 15.1752L10.3936 15.5346C10.3171 15.5663 10.2428 15.6024 10.1641 15.6406C9.94961 15.745 9.70643 15.8631 9.45631 15.8631C8.83931 15.8631 8.12441 15.0601 7.44363 13.6022C6.77551 12.1713 6.81812 11.4212 6.97149 11.0437C7.14073 10.6272 7.53427 10.4492 7.95704 10.2892C8.01584 10.2669 8.06893 10.2467 8.12058 10.2254L8.96904 9.86817C11.1795 8.94381 10.3571 5.7135 10.0875 4.65443L9.85887 3.74386C9.6634 2.99339 9.14511 1 7.426 1C7.10775 1 6.76825 1.07414 6.41723 1.22046C6.18691 1.31192 3.01748 2.60566 1.86923 4.87852C0.496885 7.58391 0.750635 11.2118 2.6227 15.6591C4.48077 20.112 6.8902 22.8356 9.7841 23.7542C10.2805 23.9119 10.8415 23.9918 11.4517 23.9918H11.452C13.4491 23.9918 15.4205 23.1421 15.5805 23.0716C16.2691 22.7799 16.7142 22.3365 16.9033 21.7539C17.2239 20.7657 16.6861 19.6831 16.1837 18.8536ZM15.4236 21.2737C15.3797 21.4092 15.2268 21.5328 14.9696 21.641C14.9654 21.6429 14.9602 21.6451 14.9558 21.6471C14.938 21.6549 13.1536 22.4365 11.4516 22.4364C11.0013 22.4364 10.5987 22.381 10.2548 22.2717C7.81674 21.4978 5.73199 19.0716 4.05716 15.058C2.36988 11.0493 2.10027 7.8612 3.25692 5.58108C4.15505 3.80338 6.96683 2.67566 6.99441 2.66488C7.00001 2.66259 7.00551 2.66042 7.011 2.65814C7.17111 2.59094 7.31463 2.55547 7.426 2.55547C7.76873 2.55547 8.08065 3.08703 8.35172 4.12889L8.57934 5.03563C9.07045 6.96441 8.99569 8.17084 8.36696 8.43382L7.52255 8.7895C7.48895 8.8034 7.44965 8.81802 7.4063 8.83451C6.93987 9.01111 5.96925 9.37841 5.5303 10.4581C5.13199 11.4379 5.29687 12.6816 6.03385 14.2605C7.02645 16.3856 8.14588 17.4187 9.45611 17.4187C10.0642 17.4187 10.5523 17.1814 10.8439 17.0397C10.8976 17.0136 10.9456 16.9897 10.9973 16.9683L11.8364 16.6085C11.923 16.5724 12.0062 16.5548 12.0906 16.5548C12.4945 16.5548 13.2187 16.9548 14.372 18.856L14.8512 19.6562C15.4418 20.6312 15.4884 21.074 15.4236 21.2737Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </Icon>
  );
}
