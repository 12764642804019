import { Dispatch, SetStateAction } from "react";
import { MailingData, MailingOptionFilter } from "..";
import { Filter } from "../filter";
import api from "api/api";
import { DropdownOption } from "components/dropdown";
import { SaqueComplementar } from "./filters/saque-complementar";
import { currencyFloatMaskFormat } from "utils/string-formats";
import { Box, Text } from "@chakra-ui/react";

export const initialHigienizacaoValues: MailingOptionFilter = {
  REFIN: { isChecked: false, value: [] },
  IN_100: { isChecked: false, value: [] },
  SAQUE_COMPLEMENTAR: { isChecked: false, value: [] },
  ANTECIPACAO_FGTS: { isChecked: false, value: [] },
  SIMULACAO_AUMENTO: { isChecked: false, value: { coeficiente: 0 } },
};

export const dropdownBancosREFIN: DropdownOption[] = [
  { name: "BANRISUL", value: "BANRISUL" },
  { name: "BMG", value: "BMG" },
  { name: "BRADESCO", value: "BRADESCO" },
  { name: "C6", value: "C6" },
  { name: "DAYCOVAL", value: "DAYCOVAL" },
  { name: "FACTA", value: "FACTA" },
  { name: "ITAÚ", value: "ITAU" },
  { name: "MASTER", value: "MASTER" },
  { name: "OLÉ", value: "OLE" },
  { name: "PAGBANK", value: "PAGBANK" },
  { name: "SAFRA", value: "SAFRA" },
];

export const dropdownBancosSaqueComplementar = [
  { name: "BMG", value: "BMG" },
  { name: "MASTER", value: "MASTER" },
  { name: "PAN", value: "PAN" },
];

const defineHigienizationRadio = (value: MailingOptionFilter, key: string) => {
  const checkedCount = Object.keys(value).filter(
    (k) => value[k].isChecked
  ).length;
  if (checkedCount > 1) {
    return Object.keys(value).reduce((acc, crrKey) => {
      const crrValue = value[crrKey];
      if (crrKey === key) {
        acc[crrKey] = crrValue;
        return acc;
      } else {
        crrValue.isChecked = false;
        crrValue.value = [];
        acc[crrKey] = crrValue;
        return acc;
      }
    }, {} as MailingOptionFilter);
  } else {
    return value;
  }
};

interface MailingHigienizacaoArray {
  addedHigienizacoes: MailingOptionFilter;
  setHigienizacao: Dispatch<SetStateAction<MailingOptionFilter>>;
  allModalData: MailingOptionFilter;
  isDisabledFields: boolean;
  mailingId: number | undefined;
  setInvalidFields: Dispatch<SetStateAction<{ [field: string]: string }>>;
  mailingData: Partial<MailingData>;
}

export const mailingHigienizacaoArray = ({
  addedHigienizacoes,
  setHigienizacao,
  allModalData,
  isDisabledFields,
  mailingId,
  setInvalidFields,
  mailingData,
}: MailingHigienizacaoArray): {
  name: string;
  isVisible?: boolean;
  onClick?: () => void;
  render?: () => JSX.Element;
  field?: string;
}[] => {
  const onChangeHigienizacao = (
    value: SetStateAction<MailingOptionFilter>,
    key: string
  ) => {
    const getValue =
      typeof value === "function" ? value(addedHigienizacoes) : value;
    console.log(getValue);
    setHigienizacao(defineHigienizationRadio(getValue, key));
    setInvalidFields((errors) => {
      delete errors[key];
      return errors;
    });
  };

  return [
    {
      name: "Refin. Bancos",
      render: () => {
        return (
          <Filter
            type="dropdown"
            setAddedFilters={onChangeHigienizacao}
            addedFilters={addedHigienizacoes}
            filterKey="REFIN"
            componentProps={{
              asyncOptions: async (setLoading?: (s: boolean) => void) => {
                setLoading?.(true);
                try {
                  const { data } = await api.get(
                    `/datafast/mailing/${mailingId}`
                  );
                  return data.map((key: string) => {
                    return { name: key, value: key };
                  });
                } catch (e) {
                  return [];
                } finally {
                  setLoading?.(false);
                }
              },
            }}
            isDisabled={isDisabledFields}
          />
        );
      },
    },
    // {
    //   name: "IN100",
    //   render: () => {
    //     return (
    //       <Filter
    //         type="dropdown"
    //         setAddedFilters={onChangeHigienizacao}
    //         addedFilters={addedHigienizacoes}
    //         filterKey="IN_100"
    //         componentProps={{ options: dropdownBancos }}
    //         isDisabled={isDisabledFields}
    //       />
    //     );
    //   },
    // },
    {
      name: "Saque Complementar",
      field: "SAQUE_COMPLEMENTAR",
      render: () => {
        return (
          <SaqueComplementar
            allModalData={allModalData}
            setAddedFilters={onChangeHigienizacao}
            addedFilters={addedHigienizacoes}
            filterKey="SAQUE_COMPLEMENTAR"
            isDisabled={isDisabledFields}
          />
        );
      },
    },
    // {
    //   name: "Antecipação de FGTS",
    //   render: () => {
    //     return (
    //       <Filter
    //         type="dropdown"
    //         setAddedFilters={onChangeHigienizacao}
    //         addedFilters={addedHigienizacoes}
    //         filterKey="ANTECIPACAO_FGTS"
    //         componentProps={{ options: dropdownBancos }}
    //         isDisabled={isDisabledFields}
    //       />
    //     );
    //   },
    // },
    {
      name: "Simulação Aumento",
      field: "SIMULACAO_AUMENTO",
      onClick: () => {
        const key = "SIMULACAO_AUMENTO";
        const isChecked = !addedHigienizacoes[key].isChecked;
        onChangeHigienizacao(
          { ...addedHigienizacoes, [key]: { isChecked, value: [] } },
          key
        );
      },
      render: () => (
        <Box>
          <Text mb="6px">Coeficiente:</Text>
          <Filter
            type="input"
            setAddedFilters={onChangeHigienizacao}
            addedFilters={addedHigienizacoes}
            filterKey="SIMULACAO_AUMENTO"
            isDisabled={isDisabledFields}
            mask={(value) => {
              if (
                typeof value?.coeficiente === "number" ||
                value?.coeficiente != null
              )
                value = value.coeficiente;
              const result = currencyFloatMaskFormat(value, 1, 5);
              return { raw: { coeficiente: result.raw }, value: result.value };
            }}
            isChecked={(value) => true}
            componentProps={{ type: "text" }}
          />
        </Box>
      ),
      // isVisible: mailingData?.origem === "API_NOVA_VENDAS",
    },
  ];
};
