import { Icon, IconProps } from "@chakra-ui/react";

export function PlayIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="11px"
      height="13px"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M10 6L0 0V12L10 6Z" fill="#EB4E3D" />
    </Icon>
  );
}
