import { Icon } from "@chakra-ui/react";

export function PhoneIcon({ ...rest }) {
  return (
    <Icon
      width="25px"
      height="25px"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.0234 25.8404C13.2492 25.3729 9.78403 23.8586 6.88435 21.4225C3.25482 18.3676 0.868006 13.3901 0.545819 8.19538C0.493217 7.37238 0.58527 6.85884 0.868006 6.37162C0.960059 6.22019 1.90032 5.2326 2.95893 4.17917C5.03671 2.10522 5.01698 2.11839 5.62848 2.21056C5.93094 2.25007 5.9967 2.31591 8.67939 4.99558C11.704 8.0242 11.6646 7.98469 11.4936 8.58383C11.4278 8.83402 11.2372 9.05788 10.264 10.0323L9.11336 11.1911L9.29747 11.7375C9.54733 12.5013 9.87609 13.2321 10.2443 13.8576C10.5994 14.4567 11.2109 15.2402 11.441 15.3982C11.5265 15.4575 11.6054 15.5233 11.6251 15.5497C11.7172 15.6813 12.6048 16.2278 13.3018 16.5833C14.0053 16.9389 14.9851 17.3207 15.1889 17.3207C15.2349 17.3207 15.7872 16.8072 16.4185 16.1751C17.6612 14.9505 17.8058 14.8518 18.3187 14.9505C18.4634 14.9769 18.6606 15.0559 18.7658 15.1349C19.1472 15.4048 24.1115 20.4547 24.1772 20.6325C24.2824 20.8958 24.2693 21.3172 24.1509 21.5608C24.0918 21.6793 23.1975 22.6208 22.1586 23.6545C19.8573 25.9457 19.9165 25.9062 18.6277 25.926C18.1609 25.9326 17.4376 25.8931 17.0234 25.8404ZM20.3241 22.4694L21.6918 21.0999L19.8968 19.3091L18.1017 17.5117L17.1812 18.4663C16.2672 19.421 15.9845 19.6251 15.5965 19.6251C15.2283 19.6251 13.8673 19.2235 13.1703 18.9009C13.0256 18.8351 12.7889 18.7297 12.6443 18.6639C11.8881 18.3281 10.5336 17.4327 9.85636 16.8269C9.51445 16.5241 8.7583 15.5167 8.35721 14.8386C7.49585 13.3638 6.83175 11.5005 6.91065 10.7434L6.95011 10.3813L7.95612 9.37391L8.96213 8.36656L7.21969 6.60865C6.2597 5.6408 5.45095 4.83756 5.41807 4.81781C5.39177 4.80464 4.75397 5.3972 4.01755 6.1346L2.66962 7.47773L2.70907 8.2678C2.88661 12.1062 4.64877 16.1488 7.3512 18.9206C9.84979 21.4818 13.5845 23.2924 17.2141 23.7006C18.036 23.7928 18.1675 23.8059 18.5949 23.8257L18.9565 23.8389L20.3241 22.4694Z"
        fill="currentColor"
      />
      <path
        d="M18.2725 11.3296C18.1871 10.5329 18.0029 9.99961 17.6545 9.49265C17.1219 8.73549 16.2276 8.2812 15.077 8.19561L14.5181 8.14952V7.06975V5.9834L14.9652 6.0229C16.3591 6.16117 17.5887 6.54962 18.2725 7.0895C18.404 7.19485 18.5816 7.32653 18.6605 7.3792C18.9498 7.57672 19.535 8.39313 19.8177 8.98568C20.1728 9.74284 20.3766 10.5658 20.3898 11.3559L20.4029 11.955H19.3772H18.3449L18.2725 11.3296Z"
        fill="currentColor"
      />
      <path
        d="M23.4209 11.9285C23.4078 11.9154 23.3946 11.7179 23.3946 11.5006C23.3881 10.4142 23.0462 9.06453 22.5004 7.96501C21.764 6.48362 20.3043 4.9298 19.009 4.24507C18.838 4.1529 18.5881 4.02122 18.4632 3.95538C17.5427 3.46816 15.8528 3.04679 14.8008 3.03362H14.5181V1.98019V0.926758H14.952C16.2539 0.926758 18.0095 1.37447 19.4035 2.0592C20.0084 2.35548 21.1393 3.04679 21.2248 3.1653C21.2445 3.19164 21.4221 3.34307 21.6193 3.50767C23.9075 5.39068 25.4198 8.4193 25.5119 11.3294L25.5316 11.9549H24.4927C23.9207 11.9549 23.4407 11.9417 23.4209 11.9285Z"
        fill="currentColor"
      />
    </Icon>
  );
}
