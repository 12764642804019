import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Button, useToast } from "@chakra-ui/react";
import { toastDefaultStyle } from "chakra/theme";
import { Checkbox } from "components/checkbox";
import { CustomModal } from "components/custom-modal";
import DynamicTable from "components/dynamic-table";
import { Column } from "components/dynamic-table/types";
import { useApplicationContext } from "contexts/ApplicationContext";
import { use3cPlusContext } from "contexts/Context3cPlus";
import { useEffect, useState } from "react";
import {
  enterCampaign,
  listCampaigns,
  logoutCampaign,
} from "services/api3cplus";
import { createEventListener, makeEvent } from "services/events";

export function Modal3cPlusCampanhas() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEnter, setIsLoadingEnter] = useState(false);
  const [selectedCampanha, setSelectedCampanha] = useState<null | {
    id: number;
    name: string;
  }>(null);
  const [campanhas, setCampanhas] = useState<{ name: string; value: string }[]>(
    []
  );
  const { user } = useApplicationContext();
  const { dispatch3cPlus, controls3cPlus } = use3cPlusContext();
  const toast = useToast({ ...toastDefaultStyle });

  const onOpen = () => {
    setIsOpen(true);
    handleSubmit();
  };
  const onClose = () => {
    setIsOpen(false);
    setSelectedCampanha(null);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const data = await listCampaigns();
      setCampanhas(data);
    } catch (e) {
      toast({ title: "Erro ao obter campanhas", status: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleEnter = async (tentouNovamente?: boolean) => {
    setIsLoadingEnter(true);
    try {
      await enterCampaign(selectedCampanha?.id!, "dialer");
      const campanhaName = selectedCampanha!.name;
      dispatch3cPlus({ type: "campaignLogin", payload: campanhaName });
      onClose();
    } catch (e: any) {
      const errorMessage = e?.response?.data?.detail;
      if (errorMessage == "O agente já está logado" && !tentouNovamente) {
        await logoutCampaign();
        await handleEnter(true);
      } else {
        toast({ title: errorMessage ?? "Erro ao entrar", status: "error" });
      }
    } finally {
      setIsLoadingEnter(false);
    }
  };

  createEventListener("openModal3cPlusCampanhas", onOpen);

  useEffect(() => {
    if (user.userData.enabled3cplus && !controls3cPlus.campaign.isLogged)
      onOpen();
  }, []);

  const modalFooter = (
    <>
      <Button
        onClick={() => handleEnter()}
        isDisabled={selectedCampanha === null}
        leftIcon={<CheckIcon />}
        isLoading={isLoadingEnter}
        loadingText="Entrando"
      >
        Entrar
      </Button>
      <Button
        variant="outline"
        onClick={onClose}
        leftIcon={<CloseIcon w="12px" h="12px" />}
      >
        Cancelar
      </Button>
    </>
  );

  const columns: Column[] = [
    {
      name: "Nome da Campanha",
      render: (row) => {
        return row.name;
      },
    },
    {
      name: "",
      render: (row) => {
        const isSelected = selectedCampanha?.id === row.id;
        return (
          <Checkbox
            isChecked={isSelected}
            onChange={() => {
              setSelectedCampanha(
                isSelected ? null : { id: row.id, name: row.name }
              );
            }}
          />
        );
      },
    },
  ];

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Selecione uma campanha para logar"
      modalFooter={modalFooter}
      size="2xl"
      isLoading={isLoading}
    >
      <DynamicTable
        columns={columns}
        rows={campanhas}
        pageSize={6}
        onClickRow={(row) =>
          setSelectedCampanha(
            selectedCampanha?.id === row.id
              ? null
              : { id: row.id, name: row.name }
          )
        }
      />
    </CustomModal>
  );
}

export const openModal3cPlusCampanhas = () =>
  makeEvent("openModal3cPlusCampanhas");
