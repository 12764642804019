import { Box, BoxProps, keyframes } from "@chakra-ui/react";
import { useEffect, useRef } from "react";

export function SkeletonLoading({
  children,
  isLoading,
  ...rest
}: { isLoading: boolean } & BoxProps) {
  // const animatedDivRef = useRef<HTMLDivElement>(null);
  // useEffect(() => {
  //   const target = animatedDivRef.current!;
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.isIntersecting) {
  //         target.style.animation = "skeleton 1.2s infinite";
  //       } else {
  //         target.style.animation = "";
  //       }
  //     },
  //     { rootMargin: "50px" }
  //   );
  //   observer.observe(target);
  //   return () => observer.unobserve(target);
  // }, []);
  return isLoading ? (
    <Box overflow="hidden" pos="relative" {...rest}>
      <Box zIndex="-1">{children}</Box>
      <Box pos="absolute" inset="0" zIndex="9" bg="gray.300" />
      <Box
        // ref={animatedDivRef}
        pos="absolute"
        inset="0"
        zIndex="10"
        animation="skeleton 1.2s infinite"
        bgImage="linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0))"
      />
    </Box>
  ) : (
    <>{children}</>
  );
}
