import "components/global-styles/index.css";
import { ApplicationContextProvider } from "contexts/ApplicationContext";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import PrimeReact from "primereact/api";
import "primereact/resources/primereact.min.css";
import "styles/imports.scss";
import { Routes } from "./routes/routes";
import { getTitle } from "components/white-label";

const App = () => {
  PrimeReact.ripple = true;

  document.title = getTitle();
  return (
    <ApplicationContextProvider>
      <Routes />
    </ApplicationContextProvider>
  );
};

export default App;
