import { CloseIcon } from "@chakra-ui/icons";
import { Button, Input, Text, useDisclosure } from "@chakra-ui/react";
import { enviarTelefoneContato } from "components/chat-window/functions";
import { CustomModal } from "components/custom-modal";
import { SendIcon } from "components/vectors/send-icon";
import { useAttendanceContext } from "contexts/AttendanceContext";
import { Dispatch, SetStateAction, useState } from "react";
import { createEventListener } from "services/events";
import { maskPhone } from "utils/string-formats";

export function TelefoneContatoDialog({
  isLoading,
  setLoading,
}: {
  isLoading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { dispatch, formControl } = useAttendanceContext();
  const [telefoneContato, setTelefoneContato] = useState("");
  const { id } = formControl.values;
  const saveNumber = () => {
    enviarTelefoneContato(telefoneContato, id!, setLoading, dispatch, onClose);
    // onSend(telefoneContato.replace(/\D/g, ""));
  };

  createEventListener("open-modal-change-number", onOpen);

  const footer = (
    <>
      <Button
        leftIcon={<SendIcon />}
        onClick={saveNumber}
        isLoading={isLoading}
        loadingText="Salvando"
        disabled={!telefoneContato}
        bg="#076058"
        _hover={{ bg: "#25736c" }}
        _disabled={{
          _hover: { bg: "#076058!important" },
          opacity: 0.5,
          cursor: "not-allowed",
        }}
      >
        Salvar
      </Button>
      <Button
        leftIcon={<CloseIcon w="12px" h="12px" />}
        onClick={onClose}
        variant="outline"
      >
        Cancelar
      </Button>
    </>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      modalTitle="Alterar WhatsApp"
      onClose={onClose}
      modalFooter={footer}
      size="lg"
    >
      <Text mb="10px">Informe o novo telefone (WhatsApp) do contato</Text>
      <Input
        value={telefoneContato}
        placeholder="Novo número"
        onChange={(e) => setTelefoneContato(maskPhone(e.target.value))}
        onKeyDown={(e) => (e.key === "Enter" ? saveNumber() : undefined)}
      />
    </CustomModal>
  );
}
