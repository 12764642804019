import { Input, InputProps } from "@chakra-ui/react";
import type { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { useAttendanceContext } from "contexts/AttendanceContext";
import { forwardRef, memo } from "react";

const AttendanceInput = forwardRef<
  HTMLInputElement,
  Omit<InputProps, "readOnly" | "onChange" | "fieldName"> & {
    readOnly?: boolean;
    fieldName?: keyof Attendance;
    onChangeField?: (field: keyof Attendance, value: string) => void;
    mask?: (a: string) => any;
  }
>(({ fieldName, onChangeField, readOnly, mask, ...rest }, ref) => {
  const { formControl } = useAttendanceContext();
  return (
    <Input
      ref={ref}
      id={fieldName}
      fontWeight="600"
      autoComplete="new-password"
      onChange={(e) => {
        e.target.readOnly = false;
        e.target.classList.remove("invalid-input");
        const value = mask ? mask(e.target.value) : e.target.value;
        onChangeField?.(fieldName!, value);
      }}
      _hover={{ cursor: readOnly ? "not-allowed" : undefined, bg: "bg-gray" }}
      onFocus={({ currentTarget }) => {
        if (readOnly) currentTarget.blur();
      }}
      className={
        formControl.invalidFields?.includes(fieldName!)
          ? "invalid-input"
          : undefined
      }
      {...rest}
    />
  );
});

export default memo(AttendanceInput);
