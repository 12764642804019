// passar array, texto para pesquisa e as chaves (nomes das propriedades) que seram procuradas

export function filterArrayBy(
  array: any[] = [{}],
  searchText: string = "",
  findKeys: string[] = []
) {
  let resultArray: any[] = [];
  let indexes: number[] = [];
  const search = searchText.toLowerCase();

  for (let key of findKeys) {
    array.forEach((current, index) => {
      if (
        current[key]
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(search)
      )
        indexes.push(index);
    });
  }
  let uniqIndexes: number[] = [...new Set(indexes)];
  for (let index of uniqIndexes) resultArray.push(array[index]);

  return resultArray;
}

export function sortArrayBy(
  array: any[],
  sortBy: string,
  sortOrder: "ASC" | "DESC" = "ASC"
) {
  return array.sort((a, b) => {
    let curr = a[sortBy];
    let next = b[sortBy];
    if (curr === null) return sortOrder === "ASC" ? -1 : 1;
    if (next === null) return sortOrder === "ASC" ? 1 : -1;
    if (curr < next) return sortOrder === "ASC" ? -1 : 1;
    if (curr > next) return sortOrder === "ASC" ? 1 : -1;

    return 0;
    // return sortOrder === "ASC" ? curr - next : next - curr;
  });
}
