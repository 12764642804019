import { Box, BoxProps, Flex } from "@chakra-ui/react";
import { ReactNode } from "react";
import { MessageArrow } from "../arrow-message";

export const MessageContainer = ({
  children,
  isMyMessage,
  containerProps,
}: {
  children: ReactNode;
  isMyMessage: boolean;
  containerProps?: BoxProps;
}) => (
  <Flex
    w="100%"
    justifyContent={isMyMessage ? "flex-end" : "flex-start"}
    p="2px 8px"
  >
    <Box
      maxW="300px"
      w="60%"
      bg={isMyMessage ? "#d9fdd3" : "#ffffff"}
      borderRadius={isMyMessage ? "6px 0px 6px 6px" : "0px 6px 6px 6px"}
      p="6px 7px 8px 9px"
      textAlign="start"
      pos="relative"
      {...containerProps}
    >
      <Box
        transform={
          isMyMessage ? "translate(100%,0) rotate(270deg)" : "translate(0%,0)"
        }
        top="0"
        right={isMyMessage ? "0" : "100%"}
        w="12px"
        h="12px"
        pos="absolute"
        color={isMyMessage ? "#d9fdd3" : "#ffffff"}
      >
        <MessageArrow />
      </Box>
      {children}
    </Box>
  </Flex>
);
