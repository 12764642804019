import { Icon, IconProps } from "@chakra-ui/react";
import { useRef } from "react";
import { changeProgressListener } from "./change-progress-interface";

export function CircleProgress({
  id,
  isVisible,
  ...rest
}: { isVisible: boolean } & IconProps) {
  const ref = useRef<SVGCircleElement>(null);

  changeProgressListener(`circle-progress-${id}`, ({ value, isVisible }) => {
    requestAnimationFrame(() => {
      const progress = 135 - (value / 100) * 135;
      ref.current?.setAttribute("stroke-dashoffset", `${progress}`);
    });
    if (isVisible === false && ref.current) ref.current.style.display = "none";
    else if (ref.current) ref.current.style.display = "block";
  });

  return (
    <Icon
      width="50px"
      height="50px"
      fill="none"
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
      transform="rotate(270deg)"
      display={isVisible ? "block" : "none"}
      {...rest}
    >
      <circle
        ref={ref}
        cx="25"
        cy="25"
        r="21.5"
        stroke="#00a884"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray={135}
        strokeDashoffset={135}
      />
    </Icon>
  );
}
