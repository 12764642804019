export function fileToBase64(file: File | Blob): Promise<string> {
  return new Promise((res, rej) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const result = reader.result as string;
      res(result.split(",")[1] as string);
    };
    reader.onerror = function (error) {
      rej(error);
    };
  });
}
