import { DeleteIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Box,
  Grid,
  IconButton,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { slimScroll, toastDefaultStyle } from "chakra/theme";
import { SendIcon2 } from "components/vectors/send-icon";
import { useAttendanceContext } from "contexts/AttendanceContext";
import { useEffect, useRef, useState } from "react";
import { FaMicrophone } from "react-icons/fa";
import { sendMessageChat } from "../message-template/functions";
import { Dropdown } from "components/dropdown";
import api from "api/api";
import { ChatStatus } from "..";
import { fileToBase64 } from "utils/file-to-base64";
//@ts-ignore
import MicRecorder from "mic-recorder-to-mp3";
import { getExportedFunction } from "services/get-external-function";
import { makeId } from "utils/make-id";
import { PauseIcon } from "components/vectors/pause-icon";
import { PlayIcon } from "components/vectors/play-icon";

export interface WhatsAppSession {
  nome: string;
  id: number;
  whatsAppSessionId: string;
}

let audioRecorder: any = {};
let audioParts: Blob[] = [];

export function ChatInput({
  phone,
  chatStatus,
}: {
  chatStatus: ChatStatus;
  phone: string | null;
}) {
  const { formControl, dispatch } = useAttendanceContext();
  const [whatsAppPhones, setWhatsAppPhones] = useState<WhatsAppSession[]>([]);
  const [loadingPhones, setLoadingPhones] = useState(false);
  const textBox = useRef<HTMLDivElement>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [messageBody, setMessageBody] = useState("");
  const [isUsingAudio, setIsUsingAudio] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const toast = useToast();

  const clearChat = () => {
    textBox.current!.textContent = "";
    setMessageBody("");
  };

  const getPhones = async () => {
    setLoadingPhones(true);
    try {
      const { data } = await api.get("/whatsapp-sessions");
      setWhatsAppPhones(data);
    } catch (e: any) {
    } finally {
      setLoadingPhones(false);
    }
  };

  const changeWhatsAppSession = async (id: string, option: WhatsAppSession) => {
    setLoadingPhones(true);
    try {
      const res = await api.get(
        `/atendimentos/update-whatsapp-session/${formControl.values.id}/${id}`
      );
      dispatch({
        type: "changeFieldWithInitial",
        payload: { fieldName: "whatsAppSession", data: option },
      });
    } catch (e: any) {
      toast({
        title: "Não foi possível carregar os telefones whatsapp.",
        status: "error",
        ...toastDefaultStyle,
      });
    } finally {
      setLoadingPhones(false);
    }
  };
  // AUDIO CONTROLS
  const startAudio = async () => {
    try {
      audioRecorder = new MicRecorder({ bitRate: 64 });
      await audioRecorder.start();
      setIsUsingAudio(true);
    } catch (e) {
      toast({
        title: "Erro ao iniciar audio",
        status: "error",
        ...toastDefaultStyle,
      });
      console.error(e);
    }
  };

  const stopAudio = async ({
    finalize = true,
  }: { finalize?: boolean } = {}) => {
    try {
      const audio = await audioRecorder.stop();
      const [mp3Buffer, mp3Blob]: [Buffer[], Blob] = await audio.getMp3();
      let currentAudio: Blob;
      if (audioParts.length) {
        currentAudio = new Blob([...audioParts, mp3Blob]);
        audioParts = [];
      } else {
        currentAudio = new Blob(mp3Buffer);
        audioParts = [];
      }
      if (finalize) setIsUsingAudio(false);
      return { audio: currentAudio };
    } catch (e) {
      console.error(e);
      return { audio: null };
    }
  };

  const pauseAudio = async () => {
    try {
      const { audio } = await stopAudio({ finalize: false });
      setIsPaused(true);
      if (!audio) return;
      audioParts.push(audio);
    } catch (e) {
      console.error(e);
    }
  };

  const playAudio = async () => {
    try {
      await startAudio();
      setIsPaused(false);
    } catch (e) {}
  };

  const deleteAudio = async () => {
    try {
      const { audio } = await stopAudio({ finalize: true });
      audioParts = [];
      setIsPaused(false);
    } catch (e) {}
  };
  // FIM AUDIO CONTROLS

  useEffect(() => {
    getPhones();
  }, []);

  return (
    <>
      <Grid
        w="97%"
        templateColumns={isUsingAudio ? "auto 40px 40px 40px" : "auto 40px"}
        gap="10px"
        flexDir="column"
        alignItems="stretch"
        pt="0px"
        width="97%"
        mx="auto"
      >
        <Dropdown
          options={whatsAppPhones}
          onChange={changeWhatsAppSession}
          value={formControl.values.whatsAppSession?.id}
          leftIcon={<PhoneIcon />}
          borderRadius="4px 4px 0px 0px"
          optionValue="id"
          optionLabel="nome"
          bg="#076058"
          _hover={{ bg: "#00a884" }}
          aria-label=""
          h="41px"
          isLoading={loadingPhones}
          // loadingText="Carregando"
          placeholder="Selecione um telefone"
          _loading={{
            _hover: { bg: "#076058" },
            opacity: 0.5,
            cursor: "not-allowed",
          }}
          color="#fff"
          _disabled={{
            _hover: { bg: "#076058" },
            opacity: 0.5,
            cursor: "not-allowed",
          }}
          width="100%"
          justifyContent="flex-start"
        >
          <Text mr="auto">
            {formControl.values.whatsAppSession?.nome
              ? formControl.values.whatsAppSession?.nome
              : "Selecione um telefone"}
          </Text>
        </Dropdown>
      </Grid>
      {/* <SolicitarInfoMessageDialog
        isOpen={isOpen}
        onClose={onClose}
        phone={phone}
      /> */}
      <Grid
        w="97%"
        mx="auto"
        templateColumns={isUsingAudio ? "auto 40px 40px 40px" : "auto 40px"}
        gap="10px"
        pb="8px"
        borderRadius="10"
      >
        <Box overflow="hidden" bg="#fff" borderRadius="0px 0px 20px 20px">
          <Box
            ref={textBox}
            p="10px 15px"
            minH="40px"
            fontSize="14"
            css={slimScroll}
            overflow="overlay"
            onKeyUp={(e) => {
              const { textContent } = e.currentTarget;
              setMessageBody(textContent || "");
              if (e.key === "Enter" && !e.shiftKey) {
                e.preventDefault();
                const isSended = sendMessageChat({ body: textContent || "" });
                clearChat();
              }
            }}
            onKeyDown={(e) =>
              e.key === "Enter" && !e.shiftKey ? e.preventDefault() : undefined
            }
            whiteSpace="pre-wrap"
            _focus={{ border: 0, outline: "none" }}
            pos="relative"
            role="textbox"
            cursor={chatStatus === "authenticated" ? undefined : "not-allowed"}
            _empty={{
              _hover: { cursor: "text" },
              _before: {
                content:
                  chatStatus === "authenticated"
                    ? '"Mensagem"'
                    : '"Sessão whatsapp não autenticada"',
              },
            }}
            maxH="132px"
            contentEditable={chatStatus === "authenticated"}
          />
        </Box>
        {isUsingAudio ? (
          <>
            <IconButton
              mt="auto"
              icon={<DeleteIcon />}
              borderRadius="full"
              bg="#076058"
              w="40px"
              _hover={{ bg: "#00a884" }}
              aria-label=""
              onClick={deleteAudio}
              _disabled={{
                _hover: { bg: "#076058" },
                opacity: 0.5,
                cursor: "not-allowed",
              }}
            />
            <IconButton
              mt="auto"
              icon={isPaused ? <PlayIcon /> : <PauseIcon />}
              borderRadius="full"
              bg="transparent"
              w="40px"
              _hover={{ bg: "transparent" }}
              aria-label=""
              color="#EB4E3D"
              border="3px solid #EB4E3D"
              onClick={isPaused ? playAudio : pauseAudio}
              _disabled={{
                _hover: { bg: "#076058" },
                opacity: 0.5,
                cursor: "not-allowed",
              }}
            />
          </>
        ) : null}
        <IconButton
          mt="auto"
          icon={
            messageBody.trim() ? (
              <SendIcon2 />
            ) : isUsingAudio ? (
              <SendIcon2 />
            ) : (
              <FaMicrophone />
            )
          }
          borderRadius="full"
          animation={isUsingAudio ? "recording 1.6s linear infinite" : ""}
          bg="#076058"
          w="40px"
          _hover={{ bg: "#00a884" }}
          aria-label=""
          onClick={async () => {
            if (messageBody.trim()) {
              const isSended = sendMessageChat({
                body: messageBody || "",
              });
              clearChat();
            } else {
              if (isUsingAudio) {
                const { audio } = await stopAudio({ finalize: true });
                if (audio) {
                  const stringfy = await fileToBase64(audio);
                  // SEND VIA WEBSOCKET
                  const sendMessage = getExportedFunction("sendJsonMessage");
                  const partSize = 1000;
                  const amountParts = Math.ceil(stringfy.length / partSize);
                  const hashId = makeId(10);
                  //media.data
                  const length = stringfy.length;
                  for (let i = 0; i < amountParts; i++) {
                    let dataPart = stringfy.substring(
                      i * 2000,
                      (i + 1) * 2000 < length ? (i + 1) * 2000 : length
                    );
                    const message = {
                      contentType: "action",
                      action: "send-voice",
                      voiceMedia: {
                        hashId,
                        part: i,
                        amountParts,
                        dataPart,
                      },
                    };
                    sendMessage(message);
                  }
                  // DOWNLOADS
                  // const convertedToBlob = new Blob([stringfy] as string[]);
                  // createLinkAndDownload(convertedToBlob, "audio.txt");
                  // createLinkAndDownload(audio, "audio.mp3");
                }
              } else startAudio();
            }
          }}
          disabled={chatStatus !== "authenticated"}
          _disabled={{
            _hover: { bg: "#076058" },
            opacity: 0.5,
            cursor: "not-allowed",
          }}
        />
      </Grid>
    </>
  );
}
