import { Box, Flex } from "@chakra-ui/react";
import { getToken } from "Session";
import { TelefoneContatoDialog } from "atendimento/TelefoneContatoDialog";
import { defaultScroll } from "chakra/theme";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { FormControl } from "components/atendimentos-components/modal-atendimento/actions-reducer";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useEffect, useRef, useState } from "react";
import { ReadyState } from "react-use-websocket";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import { exportFunction } from "services/get-external-function";
import {
  createWebSocketEventListener,
  makeWebSocketEvent,
} from "utils/web-socket-events";
import { ChatInput } from "./chat-input";
import { ChatHeader } from "./components/chat-header";
import { MessagesChat } from "./components/messages-chat";
export interface Message {
  body: string;
  direction: "i" | "o";
  from: string;
  id: string;
  to: string;
  type: "chat" | "revoked" | "document" | "image" | "video" | "ptt";
  mimetype?: string;
  filename?: string;
  duration?: string;
  userFullName?: string;
}

export interface ContactInfo {
  isWAContact: boolean;
  name: string;
  profilePicUrl: string;
}

export type ChatStatus = "authenticated" | "not-authenticated";

function ChatWindow({ formControl }: { formControl: FormControl }) {
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [isUpdatingNumber, setIsUpdatingNumber] = useState(false);
  const { user } = useApplicationContext();
  const [chatStatus, setChatStatus] = useState<ChatStatus>("not-authenticated");
  const { telefoneContatoNormalizado: phone, whatsAppSession } =
    formControl.values as Attendance;
  const url = process.env.REACT_APP_PUBLIC_WEB_SOCKET!;
  const { sendJsonMessage, readyState } = useWebSocket<any>(
    url,
    {
      retryOnError: true,
      queryParams: {
        info: window.btoa(
          JSON.stringify({
            token: getToken(),
            phone,
            client: "tela-atendimento",
            whatsAppObjectId: whatsAppSession?.id,
            attendanceId: formControl.values.id,
          })
        ),
      },
      reconnectInterval: 5000,
      reconnectAttempts: Number.MAX_VALUE,
      shouldReconnect: () => true,
      onMessage: ({ data }) => {
        const parsedData = JSON.parse(data);
        if (parsedData.contentType === "requested-media") {
          const listenerId = parsedData.requestedMedia.messageId;
          makeWebSocketEvent("requested-media", parsedData, listenerId);
        } else
          makeWebSocketEvent(
            parsedData.state ?? parsedData.contentType,
            parsedData
          );
      },
    },
    user.isLogged && !!phone
  );

  exportFunction("sendJsonMessage", sendJsonMessage);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting", // 0
    [ReadyState.OPEN]: "Open", // 1
    [ReadyState.CLOSING]: "Closing", // 2
    [ReadyState.CLOSED]: "Closed", // 3
    [ReadyState.UNINSTANTIATED]: "Uninstantiated", //-1
  }[readyState];

  useEffect(() => {
    console.log(`${readyState} (${connectionStatus}) Websocket Whatsapp`);
  }, [readyState, connectionStatus]);

  createWebSocketEventListener("whatsapp-qrcode", (data) => {
    setChatStatus("not-authenticated");
  });

  createWebSocketEventListener("chat", (state) =>
    setChatStatus("authenticated")
  );

  createWebSocketEventListener("qrcode-generated", () =>
    setChatStatus("not-authenticated")
  );

  createWebSocketEventListener("qrcode-not-generated", (state) => {
    setChatStatus("not-authenticated");
  });

  return (
    <Box>
      <Flex
        flexDir="column"
        w="100%"
        h="100%"
        maxH="calc(100vh - 62px - 16px - 72px)"
        bgImg="/assets/layout/images/back-whatsapp.png"
        bgSize="cover"
        borderRadius="10px"
        overflow="hidden"
      >
        <ChatHeader />
        {/* MESSAGES CONTAINER */}
        <Flex
          ref={chatContainerRef}
          flexDir="column-reverse"
          maxH={"calc(100% - 50px - 53px - 37px)"}
          h="100%"
          overflow="auto"
          p="15px 10px 10px 10px"
          sx={defaultScroll}
          pos="relative"
        >
          <MessagesChat
            setChatStatus={setChatStatus}
            isUpdatingNumber={isUpdatingNumber}
          />
        </Flex>
        {/* END MESSAGES CONTAINER */}

        <ChatInput chatStatus={chatStatus} phone={phone} />
      </Flex>
      <TelefoneContatoDialog
        isLoading={isUpdatingNumber}
        setLoading={setIsUpdatingNumber}
      />
    </Box>
  );
}

export default ChatWindow;
