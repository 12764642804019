import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  useColorModeValue,
} from "@chakra-ui/react";
import { defaultScroll } from "chakra/theme";
import { RefObject, memo, useEffect, useRef, useState } from "react";
import { DropdownItem } from "./options";
import "./style.css";
export interface DropdownOption {
  name: string;
  value?: any;
  subitems?: { name: string; value: any }[];
}

export interface DropdownProps extends Omit<ButtonProps, "onChange" | "value"> {
  options?: DropdownOption[] | any[];
  placeholder?: string;
  onChange?: (a: any, b: any) => void;
  optionLabel?: "value" | "name" | string;
  value?: any | any[];
  openHover?: boolean;
  isInvalid?: boolean;
  multiSelect?: boolean;
  openTop?: boolean;
  optionValue?: string;
  containerStyles?: BoxProps;
  onCloseEvent?: (target: HTMLDivElement) => void;
  onOpenEvent?: (target: HTMLDivElement) => void;
  menuListProps?: BoxProps;
}

export function Dropdown({
  value,
  onChange,
  options = [],
  openHover = false,
  placeholder = "Selecione",
  optionLabel = "name",
  isInvalid,
  multiSelect,
  optionValue = "value",
  openTop,
  containerStyles,
  children,
  onCloseEvent,
  onOpenEvent,
  menuListProps,
  ...rest
}: DropdownProps) {
  const [isOpen, setIsOpen] = useState(openHover);
  const menuList = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const findSelected = multiSelect
    ? undefined
    : options.find(
        (curr) =>
          curr[optionValue] === value ||
          curr.subitems?.find((subI: any) => subI[optionValue] === value)
      );

  const selectedName =
    findSelected && !multiSelect
      ? findSelected[optionLabel]
      : multiSelect
      ? value?.join(",")
      : null;

  const bg = useColorModeValue("#fff", "#1c1c1c");
  const color = useColorModeValue("primary.500", "#ededed");
  const border = useColorModeValue("default-border", "default-border-dark");

  const onOpen = () => {
    setIsOpen(true);
  };

  function onOpenEffect() {
    if (menuList.current) {
      const { style, clientHeight, clientWidth } = menuList.current!;
      const { top, right } = buttonRef.current!.getBoundingClientRect();
      const isOpenOnTop = top > window.innerHeight - (clientHeight + 117);
      const isOpenOnLeft = right > window.innerWidth - (clientWidth + 40);
      if (isOpenOnLeft) {
        style.transformOrigin = "0 100";
        style.left = "auto";
        style.right = "0";
      } else {
        style.transformOrigin = "0 0";
        style.left = "0";
        style.right = "auto";
      }
      if (isOpenOnTop || openTop) {
        style.top = "auto";
        style.bottom = "calc(100% + 2px)";
      } else {
        style.top = "calc(100% + 2px)";
        style.bottom = "auto";
      }
      onOpenEvent?.(menuList.current);
    }
  }

  function onClose() {
    if (menuList.current) {
      const { style } = menuList.current;
      style.opacity = "0";
      style.transform = "scale(0.9)";
      onCloseEvent?.(menuList.current);
    }
  }

  useEffect(() => {
    if (menuList.current) {
      const { clientWidth } = buttonRef.current!;
      if (clientWidth > 180) {
        menuList.current.style.minWidth = `${clientWidth}px`;
      }
    }
    if (isOpen) {
      onOpenEffect();
    }
  }, [isOpen]);

  return (
    <Box
      pos="relative"
      role="group"
      onBlur={openHover ? undefined : onClose}
      color={color}
      {...containerStyles}
    >
      <Button
        ref={buttonRef}
        rightIcon={<ChevronDownIcon />}
        bg="none"
        color="primary.500"
        _hover={{ bg: "bg-gray" }}
        _disabled={{
          _hover: { bg: "gray.200" },
          opacity: "50%",
          cursor: "not-allowed",
        }}
        onClick={() => {
          if (!openHover) {
            if (isOpen) onClose();
            else onOpen();
          }
        }}
        border="1px solid var(--chakra-colors-custom-gray)"
        justifyContent="space-between"
        className={isInvalid ? "invalid-input" : undefined}
        spinnerPlacement="end"
        {...rest}
      >
        {children ? children : selectedName ? selectedName : placeholder}
      </Button>
      {isOpen ? (
        <Box
          ref={menuList}
          pos="absolute"
          top="calc(100% + 2px)"
          left="0"
          minW="180px"
          bg={bg}
          borderRadius="4"
          visibility={openHover ? "hidden" : undefined}
          opacity={openHover ? "0" : undefined}
          transition="visibility .2s, opacity .2s, transform .2s"
          _groupHover={
            openHover
              ? {
                  opacity: 1,
                  visibility: "visible",
                  transform: "scale(1)",
                }
              : undefined
          }
          overflow="auto"
          transform={openHover ? "scale(0.9)" : undefined}
          transformOrigin="0 0"
          zIndex="10000"
          border={border}
          whiteSpace="nowrap"
          userSelect="none"
          maxH="200px"
          sx={defaultScroll}
          onMouseDown={(e) => e.preventDefault()}
          animation="DropdownListOpen 0.2s"
          onTransitionEnd={({ currentTarget, target }) => {
            if (currentTarget === target)
              if (menuList.current && !openHover) setIsOpen(false);
          }}
          {...menuListProps}
        >
          {options.map((option, index) => {
            if (multiSelect && option[optionValue] == null) return null;
            return (
              <DropdownItem
                key={index}
                option={option}
                onChange={onChange}
                selectedValue={value}
                onClose={onClose}
                multiSelect={multiSelect}
                optionValueKey={optionValue}
                optionLabelKey={optionLabel}
              />
            );
          })}
        </Box>
      ) : null}
    </Box>
  );
}

export default memo(Dropdown);
