import { Text, TextProps } from "@chakra-ui/react";

export type Status =
  | "PENDENTE"
  | "EM_ATENDIMENTO"
  | "AGUARDANDO_DOCUMENTOS"
  | "DIGITACAO_PENDENTE"
  | "EM_DIGITACAO"
  | "DIGITADO"
  | "AGUARDANDO_FORNALIZACAO"
  | "AGUARDANDO_AVERBACAO"
  | "PAGO"
  | "SEM_INTERESSE"
  | "SEM_SALDO"
  | "EM_ANDAMENTO"
  | "COMPLETO"
  | "CONCLUIDO"
  | "REPROVADO"
  | "PENDING"
  | "RUNNING"
  | "FINISHED"
  | "ERROR"
  | "PENDING_GENERATION"
  | "PENDING_INSERT"
  | "ERROR_GENERATION"
  | "ERROR_INSERT"
  | "FINALIZADA"
  | "CONFIGURACAO_PENDENTE"
  | "PAUSADA"
  | "NAO_E_O_CLIENTE"
  | "PERSISTIR_MUITO_TOP"
  | "NADA_A_OFERECER"
  | "EM_NEGOCIACAO"
  | "SEM_INTERESSE_QUENTE"
  | "ENVIADO";

export function RowStatus({
  children,
  status,
  ...rest
}: TextProps & { status: Status }) {
  const bg = rowColorStatus.get(status);
  return (
    <Text
      borderRadius="5"
      p="5px"
      textAlign="center"
      fontSize="13"
      fontWeight="bold"
      boxShadow={"0 0 1em rgba(0,0,0, 0.05)"}
      textTransform="capitalize"
      bg={bg ?? "gray.200"}
      color={bg === "#00c455" || bg === "#f13426" ? "#fff" : "#000"}
      minW="188px"
      {...rest}
    >
      {children}
    </Text>
  );
}

export const rowColorStatus: Map<string, string> = new Map([
  ["PENDENTE", "gray.200"],
  ["EM_ATENDIMENTO", "#f6bc76"],
  ["AGUARDANDO_DOCUMENTOS", "#f6bc76"],
  ["DIGITACAO_PENDENTE", "#f6bc76"],
  ["EM_DIGITACAO", "gray.200"],
  ["DIGITADO", "gray.200"],
  ["AGUARDANDO_FORNALIZACAO", "#f6bc76"],
  ["AGUARDANDO_AVERBACAO", "#f6bc76"],
  ["PAGO", "#00c455"],
  ["SEM_INTERESSE", "#f13426"],
  ["SEM_SALDO", "#f13426"],
  ["EM_ANDAMENTO", "#f6bc76"],
  ["CRIANDO_SMS", "#f6bc76"],
  ["PROCESSANDO", "#f6bc76"],
  ["COMPLETO", "#00c455"],
  ["REPROVADO", "#f13426"],
  ["CONCLUIDO", "#00c455"],
  ["PENDING", "gray.200"],
  ["RUNNING", "gray.200"],
  ["FINISHED", "#00c455"],
  ["ERROR", "#f13426"],
  ["PENDING_GENERATION", "#f6bc76"],
  ["PENDING_INSERT", "gray.200"],
  ["ERROR_GENERATION", "#f13426"],
  ["ERROR_INSERT", "#f13426"],
  ["FINALIZADA", "#00c455"],
  ["CONFIGURACAO_PENDENTE", "#f6bc76"],
  ["PAUSADA", "gray.200"],
  ["NAO_E_O_CLIENTE", "#f13426"],
  ["PERSISTIR_MUITO_TOP", "#f6bc76"],
  ["NADA_A_OFERECER", "#f6bc76"],
  ["EM_NEGOCIACAO", "#00c455"],
  ["SEM_INTERESSE_QUENTE", "#f6bc76"],
  ["ENVIADO", "#00c455"],
  ["ERRO", "#f13426"],
  ["AGUARDANDO_ENVIO", "gray.200"],
  ["AGUARDANDO CONFIGURAÇÃO", "gray.200"],
  ["FINALIZADO", "#00c455"],
  ["EM ANDAMENTO", "#f6bc76"],
]);
