type UnitTest = (
  a: any,
  fullObject: any
) => { valid: boolean; format?: any; message?: string };

type Type =
  | "string"
  | "number"
  | "bigint"
  | "boolean"
  | "symbol"
  | "undefined"
  | "function"
  | "array"
  | UnitTest;

export interface ObjectSchema {
  [key: string]: Type | ObjectSchema;
}

export function objectSchema(schema: ObjectSchema, object: any) {
  const objectCopy = { ...object };
  let fieldsErrors: string[] = [];
  const errors: { [key: string]: string } = {};
  const keys = Object.keys(schema);
  const objectKeys = Object.keys(objectCopy);
  for (let key of objectKeys)
    if (keys.indexOf(key) === -1) delete objectCopy[key];

  for (let key of keys) {
    if (typeof schema[key] !== "object") {
      switch (schema[key]) {
        case "array":
          if (!Array.isArray(objectCopy[key])) fieldsErrors.push(key);
          break;
        default:
          if (typeof schema[key] === "function") {
            const validate = schema[key] as UnitTest;
            const { valid, format, message } = validate(
              objectCopy[key],
              objectCopy
            );
            if (!valid) {
              fieldsErrors.push(key);
              if (message) errors[key] = message;
            }
            if (format !== undefined) objectCopy[key] = format;
          } else if (!(typeof objectCopy[key] === schema[key]))
            fieldsErrors.push(key);
      }
    } else {
      const objectTest = objectSchema(
        schema[key] as ObjectSchema,
        objectCopy[key]
      );
      fieldsErrors = fieldsErrors.concat(objectTest.fieldsErrors);
      Object.assign(errors, objectTest.errors);
    }
  }

  return {
    isValid: !fieldsErrors.length,
    fieldsErrors,
    body: objectCopy,
    errors,
  };
}

export function isObjectEqual(
  object1: any = {},
  object2: any = {},
  exception?: string[]
) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (!exception?.includes(key))
      if (Array.isArray(object1[key])) {
        if (object1[key].length !== object2[key].length) return false;
        if (!Array.isArray(object2[key])) return false;
        for (let i = 0; i < object1[key].length; i++) {
          if (object1[key][i] !== object2[key][i]) return false;
        }
      } else if (object1[key] !== object2[key]) return false;
  }
  return true;
}

// console.log(
//     objectSchema(
//       {
//         test: "undefined",
//         test1: {
//           test1: "number",
//           testando: "string",
//           testandoa1: "array",
//           adjas: { tejkasjd: "boolean" },
//         },
//         alou: "string",
//         testando: {},
//         testando1: "boolean",
//       },
//       {
//         test: undefined,
//         test1: {
//           test1: 1,
//           testando: "alo",
//           testandoa1: [],
//           adjas: { tejkasjd: true },
//         },
//         alou: "test",
//         testando: {},
//         testando1: true,
//       }
//     )
//   );
