import { Icon, IconProps } from "@chakra-ui/react";

export function FilterIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="18px"
      height="18px"
      viewBox="0 0 117 117"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M67.5 116C74 118.5 75.1666 112.667 75 111V49.5L116 8.5C118.5 3.5 114.833 0.666667 112.5 0.5H5.99996C0.799955 0.5 -0.499982 5.5 1.49996 8.5L43.5 49.5V92.5L67.5 116Z"
        fill="currentColor"
      />
    </Icon>
  );
}
