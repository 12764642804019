import { Box, Button, Flex, Grid, Text, useToast } from "@chakra-ui/react";
import { toastDefaultStyle } from "chakra/theme";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { useAttendanceContext } from "contexts/AttendanceContext";
import OptimizedInput from "components/performance/attendance-input";
import { useState } from "react";
import AttendanceDropdown from "components/performance/attendance-dropdown";
import { TabelaDigitacaoDropdown } from "components/atendimentos-components/atendimento-form/tabela-digitacao";
import { BtnStatusProposta } from "components/atendimentos-components/atendimento-form/field-buttons/btn-update-status-proposta";
import { SendIcon } from "components/vectors/send-icon";
import api from "api/api";
import OptimizedTextarea from "components/performance/attendance-textarea";
import { BtnLinkFormalizacao } from "components/atendimentos-components/atendimento-form/field-buttons/btn-link-formalizacao";
import { numberMask } from "utils/string-formats";
import { TabDataProps } from "../../../consts";
import {
  dropdownBancoDigitacao,
  dropdownPrazo,
} from "components/atendimentos-components/atendimento-form/fields-data";
import { digitationFunction } from "components/atendimentos-components/modal-atendimento/functions";

export function INSSDigitacao({
  onChangeField,
  resetFieldsTabelaDigitacao,
}: TabDataProps) {
  const [isLoadingDigitation, setIsLoadingDigitation] = useState(false);
  const { formControl, dispatch } = useAttendanceContext();
  const toast = useToast({ ...toastDefaultStyle });
  const formValues = formControl.values as Attendance;

  return (
    <>
      <Grid templateColumns={"1fr 1fr 1fr 1fr"} gap="16px">
        <Box>
          <Text mb="4px">Banco Digitação</Text>
          <AttendanceDropdown
            value={formValues.bancoDigitacao}
            onChangeField={resetFieldsTabelaDigitacao}
            fieldName="bancoDigitacao"
            options={dropdownBancoDigitacao}
            w="full"
            disabled={!!formValues.digitadoApi}
          />
        </Box>
        <Box>
          <Text mb="4px">Valor Solicitado Cliente</Text>
          <OptimizedInput
            value={formValues.valorSolicitadoCliente || ""}
            onChangeField={onChangeField}
            fieldName={"valorSolicitadoCliente"}
            mask={numberMask}
          />
        </Box>

        <Box>
          <Text mb="4px">Prazo</Text>
          <AttendanceDropdown
            value={formValues.prazo}
            onChangeField={resetFieldsTabelaDigitacao}
            fieldName="prazo"
            options={dropdownPrazo}
            w="full"
            disabled={!!formValues.digitadoApi}
          />
        </Box>

        <Box>
          <Text mb="4px">&nbsp;</Text>
          <Button
            rightIcon={<SendIcon />}
            w="100%"
            onClick={async () => {
              const {
                values: { bancoDigitacao, tipoOperacao, possuiCartaoBeneficio },
                pendingChanges,
              } = formControl;
              if (!bancoDigitacao)
                return toast({
                  title: <>Selecione o Banco Digitação! &#128512;</>,
                  status: "error",
                  ...toastDefaultStyle,
                });
              if (pendingChanges)
                return toast({
                  title: "Há alterações não salvas!",
                  description:
                    "Salve as alterações antes de digitar a proposta.",
                  status: "error",
                  ...toastDefaultStyle,
                });
              if (
                tipoOperacao === "CARTAO_BENEFICIO_INSS" &&
                possuiCartaoBeneficio === true
              )
                return toast({
                  title: "O Cliente já possui Cartao Benefício!",
                  status: "error",
                  ...toastDefaultStyle,
                });

              const bDigitacao = bancoDigitacao as
                | "PAN"
                | "FACTA"
                | "BMG"
                | "MASTER";
              const digitacao = digitationFunction[bDigitacao!];
              digitacao(setIsLoadingDigitation, formControl, dispatch);
            }}
            isLoading={isLoadingDigitation}
            loadingText="Digitando Proposta"
            disabled={!!formControl.values.digitadoApi}
          >
            Digitar Proposta
          </Button>
        </Box>
      </Grid>
      <Grid templateColumns={"1fr 1fr 1fr"} gap="16px">
        <TabelaDigitacaoDropdown
          formControl={formControl}
          onChangeField={onChangeField}
        />

        <Box>
          <Text mb="4px">ADE</Text>
          <OptimizedInput
            value={formValues.ade || ""}
            onChangeField={onChangeField}
            fieldName="ade"
            readOnly={!!formValues.digitadoApi}
          />
        </Box>
        <Box>
          <Text mb="4px">Situação Proposta</Text>
          <Flex alignItems="center" gap="10px">
            <OptimizedInput
              value={formValues.statusProposta || ""}
              onChangeField={onChangeField}
              fieldName="statusProposta"
              readOnly
            />
            <BtnStatusProposta formValues={formValues} dispatch={dispatch} />
          </Flex>
        </Box>
        <Box>
          <Text mb="4px">Valor Líquido Cliente</Text>
          <OptimizedInput
            value={formValues.valorLiquidoCliente || ""}
            // onChangeField={onChangeField}
            fieldName="valorLiquidoCliente"
            readOnly
            fontWeight="600"
            cursor="not-allowed"
          />
        </Box>
      </Grid>

      <Box>
        <Text mb="4px">Link Formalização</Text>
        <Flex alignItems="center" gap="10px">
          <OptimizedInput
            value={formValues.linkFormalizacao || ""}
            onChangeField={onChangeField}
            fieldName="linkFormalizacao"
          />
          <BtnLinkFormalizacao formValues={formValues} dispatch={dispatch} />
        </Flex>
      </Box>
      <Grid templateColumns="1fr 1fr" gap="16px">
        <Box>
          <Text mb="4px">Atividade</Text>
          <OptimizedInput
            value={formValues.atividade || ""}
            fieldName="atividade"
            readOnly
            fontWeight="600"
            cursor="not-allowed"
          />
        </Box>
        <Box>
          <Text mb="4px">Status Assinatura</Text>
          <Flex alignItems="center" gap="10px">
            <OptimizedInput
              value={formValues.statusAssinatura || ""}
              readOnly
              fieldName="statusAssinatura"
              fontWeight="600"
              cursor="not-allowed"
            />
          </Flex>
        </Box>
      </Grid>
      <Box>
        <Text mb="4px">Observação</Text>
        <OptimizedTextarea
          value={formValues.observacao || ""}
          onChangeField={onChangeField}
          fieldName="observacao"
        />
      </Box>
    </>
  );
}
