import { MailingOptionFilter } from "../..";

export function mailingOptionsRules(allFields: MailingOptionFilter) {
  let isInvalid = false;
  let errors: { [field: string]: string } = {};
  const isRCC = allFields.SAQUE_COMPLEMENTAR?.value?.[0]?.tipoCartao === "RCC";
  const rules: {
    isValidCondition: boolean;
    errorMessage: string;
    fieldError: string;
  }[] = [
    {
      isValidCondition: allFields.SAQUE_COMPLEMENTAR.isChecked
        ? allFields.SAQUE_COMPLEMENTAR.isChecked &&
          (isRCC
            ? allFields.valor_liberado_saque_rcc.isChecked
            : allFields.valor_liberado_saque_rmc.isChecked)
        : true,
      errorMessage: `Preencha o campo: "Valor Liberado Saque"`,
      fieldError: "valor_liberado_saque",
    },
  ];

  rules.forEach(({ errorMessage, fieldError, isValidCondition }) => {
    if (!isValidCondition) {
      isInvalid = true;
      errors[fieldError] = errorMessage;
    }
  });

  return { isInvalid, errors };
}
