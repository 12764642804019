import { Icon, IconProps } from "@chakra-ui/react";

export function ContractIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 140 132"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.3867 131.516C7.21875 130.258 2.48828 125.828 0.820312 120.688L0.136719 118.637V66V13.3633L0.792969 11.3398C1.58594 8.93359 3.60938 5.76172 5.30469 4.28516C6.86328 2.91797 9.07812 1.60547 11.1016 0.839844L12.7148 0.238281L42.082 0.15625L71.4219 0.101562L72.8438 0.730469C74.8672 1.6875 103.277 29.0039 104.234 30.918C104.836 32.1484 104.863 32.5586 104.945 38.4102L105.027 44.6172L101.254 50.8516L97.4805 57.0586L97.3984 46.7773L97.3438 36.4688H89.5781C82.7695 36.4688 81.5938 36.4141 79.7344 35.9219C74.2383 34.5273 69.9727 30.5078 68.168 25.1211C67.457 23.0156 67.4297 22.6875 67.3203 15.3594L67.2109 7.75781H40.7969H14.3555L12.7148 8.52344C10.8555 9.42578 9.24219 11.0391 8.36719 12.9258L7.79297 14.1836V65.8633V117.543L8.69531 119.375C9.65234 121.344 10.8008 122.465 12.8516 123.477L14.082 124.105H52.5H90.918L92.4766 123.34C94.1992 122.492 95.6484 120.988 96.5781 119.074C97.1523 117.926 97.207 117.379 97.2891 112.129L97.3984 106.441L99.6133 103.652C100.844 102.094 102.539 99.9336 103.359 98.8125L104.863 96.7891L104.945 107.152C105 116.75 104.973 117.707 104.453 119.703C103.305 124.297 100.105 128.207 95.9219 130.203C94.6641 130.805 92.9961 131.434 92.2031 131.598C89.9609 132.062 14.3008 131.98 12.3867 131.516Z"
        fill="currentColor"
      />
      <path
        d="M79.5703 114.48C78.2851 114.043 78.1758 112.375 79.1328 108.629C82.3594 96.2969 96.332 69.5 114.105 41.5273C119.82 32.5312 123.594 28.1562 126.273 27.3359C128.488 26.6797 130.539 27.3633 132.836 29.4414C134.34 30.8359 134.805 31.875 134.805 33.8437C134.805 35.1289 134.039 38 133.055 40.2969C132.945 40.5976 133.684 41.1445 135.898 42.4297C139.371 44.4258 140.109 45.1914 139.918 46.5859C139.727 48.0351 121.078 80.0547 120.367 80.164C119.547 80.3008 117.906 78.7148 117.578 77.457C117.359 76.6367 117.387 76.1992 117.715 75.4062C117.934 74.8594 121.652 68.3242 125.945 60.914C130.266 53.5039 133.738 47.3515 133.711 47.2148C133.684 47.1054 132.973 46.6406 132.152 46.1758C130.867 45.4648 130.594 45.4101 130.402 45.7656C130.238 45.9844 127.941 49.8672 125.289 54.3789C111.672 77.3476 100.27 93.9179 89.5508 106.305C84.4375 112.184 81.0195 115 79.5703 114.48Z"
        fill="currentColor"
      />
      <path
        d="M18.4023 104.938C15.75 103.625 15.75 99.4414 18.4023 98.1836C19.2773 97.7461 21.8203 97.7188 47.9609 97.7188H76.5625L75.9883 99.168C75.6602 99.9336 75.0312 101.656 74.5938 102.996L73.7734 105.375H46.5391C22.1211 105.375 19.1953 105.32 18.4023 104.938Z"
        fill="currentColor"
      />
      <path
        d="M18.293 89.4883C17.7461 89.1602 17.1172 88.5586 16.8711 88.1485C16.2422 87.1094 16.2969 85.1953 16.9531 84.1289C18.0742 82.2969 15.7773 82.4063 51.2695 82.4063C68.9336 82.4063 83.3984 82.461 83.3984 82.5156C83.3984 82.5703 82.6055 84.293 81.6484 86.3438L79.8711 90.0625H49.5742C19.3594 90.0625 19.2773 90.0352 18.293 89.4883Z"
        fill="currentColor"
      />
      <path
        d="M19.1406 74.4766C18.5938 74.3399 17.8008 73.8203 17.3086 73.2734C16.5156 72.4258 16.4062 72.0977 16.4062 70.7852C16.4062 69.5274 16.5156 69.1719 17.2539 68.3242C17.7461 67.7774 18.5391 67.2578 19.1406 67.0938C19.8516 66.9024 31.1719 66.8477 53.1289 66.875C85.9414 66.957 86.1055 66.957 86.8438 67.5313C89.3594 69.3906 89.3594 72.1797 86.8438 74.0391C86.1055 74.6133 85.9688 74.6133 53.1016 74.668C34.9453 74.668 19.6602 74.5859 19.1406 74.4766Z"
        fill="currentColor"
      />
      <path
        d="M47.1133 58.6445C45.418 57.6055 44.8164 55.1445 45.8555 53.4219C46.1289 52.957 46.8399 52.3555 47.4414 52.0547C48.5352 51.5352 49.1914 51.5078 67.2383 51.5078C83.8906 51.5078 86.0234 51.5625 86.8438 51.9453C89.3594 53.1484 89.8242 56.2656 87.7461 58.2344L86.9258 59.0273L67.457 59.1094C48.5352 59.1641 47.9609 59.1641 47.1133 58.6445Z"
        fill="currentColor"
      />
      <path
        d="M29.0391 58.5625C28.8477 58.3711 28.7109 57.5508 28.7109 56.7305C28.7109 55.8828 28.6016 55.0352 28.4648 54.8438C28.3555 54.6524 27.3438 54.2969 26.2227 54.0781C23.3516 53.4766 21.3281 52.5195 21.3281 51.7539C21.3281 50.9336 22.3672 47.461 22.668 47.2696C22.8047 47.1875 24.0352 47.4883 25.375 47.9531C29.2305 49.2383 32.5938 49.0196 33.7422 47.3789C34.2344 46.668 34.3164 45.1641 33.9062 44.3985C33.5234 43.6875 31.8008 42.7305 28.7656 41.5C23.8164 39.5586 21.6562 37.2617 21.3828 33.7617C21.1367 30.0156 22.8594 27.2266 26.5234 25.5586L28.4375 24.6563V22.8242C28.4375 21.7578 28.5742 20.8555 28.7656 20.6641C29.2031 20.2266 32.7031 20.2266 33.0039 20.6914C33.1133 20.8555 33.2773 21.7578 33.3594 22.6602L33.4961 24.3008L34.5898 24.5469C37.1055 25.0938 38.5273 25.5586 39.1016 26.0235L39.7305 26.543L39.1562 28.6758C38.8281 29.8516 38.4453 30.9453 38.3086 31.082C38.1445 31.2461 37.4609 31.1094 36.4492 30.7266C33.332 29.5508 29.5312 29.7149 28.5742 31.082C27.3711 32.7774 28.6836 34.5 32.3203 35.9219C38.8828 38.5195 41.1797 41.0899 41.0977 45.711C41.043 48.8555 38.6641 52.1914 35.6016 53.3125C33.6602 54.0235 33.6328 54.0781 33.6328 56.3203C33.6328 58.0156 33.5508 58.3711 33.1133 58.6172C32.375 59 29.4492 58.9727 29.0391 58.5625Z"
        fill="currentColor"
      />
    </Icon>
  );
}
