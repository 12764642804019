import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useLayoutEffect } from "react";

export interface ConfirmDialogData {
  title?: string;
  message: string;
  accept?: () => void;
  reject?: () => void;
  close: () => void;
  rejectButtonText?: string;
  acceptButtonText?: string;
  rejectButtonStyle?: ButtonProps;
  acceptButtonStyle?: ButtonProps;
}

export function ConfirmDialog({
  dialogData,
}: {
  dialogData: ConfirmDialogData | null;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  useLayoutEffect(() => {
    if (dialogData?.message) onOpen();
  }, [dialogData]);

  if (!dialogData) return null;

  const {
    title = "Confirmação",
    message,
    accept,
    reject,
    close,
    rejectButtonText = "Não",
    acceptButtonText = "Sim",
    rejectButtonStyle,
    acceptButtonStyle,
  } = dialogData;

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => {
        onClose();
        close?.();
      }}
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(8px)" />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        {message ? (
          <ModalBody>
            <Text>{message}</Text>
          </ModalBody>
        ) : null}
        <ModalFooter gap="15px">
          <Button
            onClick={() => {
              reject?.();
              onClose();
              close?.();
            }}
            variant="outline"
            {...rejectButtonStyle}
          >
            {rejectButtonText}
          </Button>
          <Button
            onClick={() => {
              accept?.();
              onClose();
              close?.();
            }}
            {...acceptButtonStyle}
          >
            {acceptButtonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
