import { GetLayout } from "components/get-layout";
import { useApplicationContext } from "contexts/ApplicationContext";
import { createEventListener } from "services/events";
import { Atendimento } from ".";
import SipService from "services/SipService";

import { Modal3cPlusCampanhas } from "components/atendimentos-components/modal-3cplus-campanhas";
import { Navbar3cplusTemplate } from "components/atendimentos-components/navbar-3cplus-template";
import { Context3cPlusProvider } from "contexts/Context3cPlus";

export let isModalAttendanceOpen = false;

export function AtendimentoPage() {
  const {
    user: { userData },
  } = useApplicationContext();

  createEventListener("attendance-modal-state", (state) => {
    isModalAttendanceOpen = state;
  });

  return (
    <Context3cPlusProvider>
      <GetLayout HeadRender={Navbar3cplusTemplate}>
        <Atendimento />
        <Modal3cPlusCampanhas />
        {userData.enabled3cplus ? (
          <SipService
            onRamalChanged={(mutted) => console.log("3cplus mutted", mutted)}
            onLigacaoChanged={(emLigacao) => {}}
            onAuthenticatedUserError={(error) => {}}
            onMicrophonePermissionDenied={() => {}}
          />
        ) : null}
      </GetLayout>
    </Context3cPlusProvider>
  );
}
