import { Icon, IconProps } from "@chakra-ui/react";

export function RepeatIcon({
  isAnimating,
  ...rest
}: IconProps & { isAnimating: boolean }) {
  return (
    <Icon
      width="30px"
      height="30px"
      viewBox="0 0 88 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {isAnimating ? (
        <animateTransform
          attributeName="transform"
          type="rotate"
          values="0;360"
          dur="1s"
          repeatCount="indefinite"
        />
      ) : null}
      <path
        d="M51.4364 39H87C87.5523 39 88 38.5523 88 38V2.89236C88 2.00456 86.9293 1.557 86.2975 2.18071L50.7339 37.2883C50.0977 37.9164 50.5424 39 51.4364 39Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.8495 54C86.4837 54 86.9579 54.5842 86.8114 55.2012C82.2104 74.5827 64.7889 89 44 89C19.6995 89 0 69.3005 0 45C0 20.6995 19.6995 1 44 1C65.8712 1 84.0153 16.9576 87.4245 37.8651C87.522 38.4632 87.0547 39 86.4487 39H77.2889C76.8063 39 76.394 38.6537 76.2947 38.1814C73.1533 23.2277 59.8878 12 44 12C25.7746 12 11 26.7746 11 45C11 63.2254 25.7746 78 44 78C58.8401 78 71.3923 68.2043 75.5433 54.7262C75.675 54.2986 76.0667 54 76.5142 54H85.8495Z"
        fill="currentColor"
      />
    </Icon>
  );
}
