import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import { defaultScroll } from "chakra/theme";
import {
  dropdownConvenioAtendimento,
  dropdownOrigemAtendimento,
  dropdownStatus,
  dropdownTipoAtendimento,
  getDropdownTipoOperacao,
} from "components/atendimentos-components/atendimento-form/fields-data";
import { setAndResetFields } from "components/atendimentos-components/atendimento-form/functions";
import { StepForm } from "components/atendimentos-components/atendimento-form/step-form";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { Dropdown } from "components/dropdown";
import AttendanceDropdown from "components/performance/attendance-dropdown";
import { useAttendanceContext } from "contexts/AttendanceContext";
import { useCallback } from "react";
import OptimizedInput from "components/performance/attendance-input";
import { maskCPF } from "utils/string-formats";

export function LeftSideAttendance() {
  const { formControl, dispatch } = useAttendanceContext();
  const formValues = formControl.values as Attendance;
  const isTipoOperacao =
    formValues.convenio === "SIAPE" || formValues.convenio === "INSS";
  const cpf = formValues.cpf ?? "";

  const onChangeField = useCallback(
    (fieldName: keyof Attendance, value: string | boolean | null) => {
      dispatch({
        type: "changeField",
        payload: { fieldName, data: value },
      });
      dispatch({
        type: "setInvalidField",
        payload: { fieldName, action: "clear" },
      });
    },
    []
  );
  const resetFieldsTabelaDigitacao = useCallback(
    (fieldName: keyof Attendance, value: string) => {
      setAndResetFields(
        fieldName,
        value,
        ["codigoTabela", "descricaoTabela", "needTokenTabela", "tokenTabela"],
        onChangeField
      );
    },
    []
  );

  return (
    <Flex
      flexDir="column"
      w="100%"
      maxH="calc(100vh - 62px - 20px - 72px)"
      minH="calc(100vh - 62px - 20px - 72px)"
      sx={defaultScroll}
    >
      <Grid templateColumns="1fr 1fr 1fr 1fr" w="100%" gap="16px" mb="16px">
        <Box>
          <Text mb="4px">CPF</Text>
          <OptimizedInput
            value={maskCPF(formValues.cpf) || ""}
            onChangeField={onChangeField}
            fieldName="cpf"
            mask={maskCPF}
          />
        </Box>
        <Box gridArea="auto/ span 2">
          <Text mb="4px">Nome</Text>
          <OptimizedInput
            value={formValues.nome || ""}
            onChangeField={onChangeField}
            fieldName="nome"
          />
        </Box>
        <Box>
          <Text mb="4px">Status Atendimento</Text>
          <AttendanceDropdown
            value={formValues.status || ""}
            fieldName="status"
            onChangeField={onChangeField}
            options={dropdownStatus}
            w="full"
          />
        </Box>
      </Grid>
      <Grid
        templateColumns={isTipoOperacao ? "repeat(4,1fr)" : "1fr 1fr 1fr"}
        w="100%"
        gap="16px"
      >
        <Box>
          <Text mb="4px">Convênio</Text>
          <AttendanceDropdown
            value={formValues.convenio || ""}
            fieldName="convenio"
            onChangeField={resetFieldsTabelaDigitacao}
            options={dropdownConvenioAtendimento}
            placeholder="Selecione"
            w="full"
            disabled={!!formValues.digitadoApi}
          />
        </Box>
        {isTipoOperacao ? (
          <Box>
            <Text mb="4px">Tipo Operação</Text>
            <AttendanceDropdown
              value={formValues.tipoOperacao || ""}
              fieldName="tipoOperacao"
              onChangeField={resetFieldsTabelaDigitacao}
              options={getDropdownTipoOperacao(formValues.convenio)}
              placeholder="Selecione"
              w="full"
              disabled={!!formValues.digitadoApi}
            />
          </Box>
        ) : null}
        <Box>
          <Text mb="4px">Tipo Atendimento</Text>
          <AttendanceDropdown
            value={formValues.tipoAtendimento || ""}
            fieldName="tipoAtendimento"
            onChangeField={onChangeField}
            options={dropdownTipoAtendimento}
            w="full"
          />
        </Box>
        <Box>
          <Text mb="4px">Origem Atendimento</Text>
          <Dropdown
            value={formValues.origem || ""}
            options={dropdownOrigemAtendimento}
            w="full"
          />
        </Box>
      </Grid>

      <StepForm
        resetFieldsTabelaDigitacao={resetFieldsTabelaDigitacao}
        onChangeField={onChangeField}
      />
    </Flex>
  );
}
