import { SessoesWhatsApp } from "pages/sessoes-whatsapp";
import { User, UserTypes } from "contexts/types/application-context-types";
import { AtendimentoPage } from "pages/atendimentos-page/get-layout-atendimento";
import { BmgCartaoSaqueLista } from "pages/BmgCartaoSaqueLista";
import { CampanhaNova } from "pages/campanha-page";
import { ConfigPage } from "pages/config";
import { Empresas } from "pages/Empresas";
import { ExtratoOnlineLote } from "pages/extrato-online-lote";
import { ExtratoOnlineAvulso } from "pages/ExtratoOnline";
import { HigienizacaoLista } from "pages/higienizacao-lista";
import { In100Page } from "pages/in100";
import { Login } from "pages/login";
import { MailingPage } from "pages/mailing";
import { MargemAumentoLista } from "pages/MargemAumentoLista";
import { NotFound } from "pages/not-found";
import { SimulacaoLista } from "pages/SimulacaoLista";
import { TebelasComissao } from "pages/tabelas-digitacao";
import { UsuarioDatafast } from "pages/UsuarioDatafast";
import { Usuarios } from "pages/Usuarios";
import { UsuariosBancos } from "pages/usuarios-bancos";
import { WalletPage } from "pages/carteira";
import { CampanhaURAWhatsapp } from "pages/campanha-ura-whatsapp";
import { CampanhaSMS } from "pages/campanha-sms";
import { IndexPage } from "pages/index-page";
import { Consulta } from "pages/consulta";

interface RouterType {
  path: string;
  exact?: boolean;
  Children: (props: any) => JSX.Element;
  permission: (a: User) => boolean;
}

function checkPermission(
  user: User,
  validUserTypes: UserTypes[],
  colomboValidUserTypes?: UserTypes[]
) {
  if (user.userData.customerCodigoNova?.startsWith("1309"))
    return !!colomboValidUserTypes?.includes(user.userData.type!);
  return validUserTypes.includes(user.userData.type!);
}

export const allRouters: RouterType[] = [
  {
    path: "/login",
    Children: Login,
    permission: () => true,
  },
  {
    path: "/",
    exact: true,
    Children: IndexPage,
    permission: (user) => user.isLogged,
  },
  {
    path: "/higienizacao-lista",
    Children: HigienizacaoLista,
    permission: (user) => checkPermission(user, ["SUPER"]),
  },
  {
    path: "/in100",
    Children: In100Page,
    permission: (user) => user.isLogged,
  },
  {
    path: "/mailing",
    Children: MailingPage,
    permission: (user) =>
      checkPermission(user, [
        "SUPER",
        "REGIONAL",
        "COMERCIAL",
        "PARCEIRO_MASTER",
      ]),
  },
  {
    path: "/carteira",
    Children: WalletPage,
    permission: (user) =>
      checkPermission(user, [
        "SUPER",
        "REGIONAL",
        "COMERCIAL",
        "PARCEIRO_MASTER",
        "FINANCEIRO",
      ]),
  },
  {
    path: "/simulacao-lista",
    Children: SimulacaoLista,
    permission: (user) => checkPermission(user, ["SUPER"]),
  },
  {
    path: "/margem-aumento",
    Children: MargemAumentoLista,
    permission: (user) => checkPermission(user, ["SUPER"]),
  },
  {
    path: "/bmg-cartao-saque-lista",
    Children: BmgCartaoSaqueLista,
    permission: (user) =>
      checkPermission(user, [
        "SUPER",
        "REGIONAL",
        "COMERCIAL",
        "PARCEIRO_MASTER",
      ]),
  },
  {
    path: "/usuario-api",
    Children: UsuarioDatafast,
    permission: (user) => user.isLogged,
  },
  {
    path: "/usuarios-bancos",
    Children: UsuariosBancos,
    permission: (user) =>
      checkPermission(user, [
        "SUPER",
        "REGIONAL",
        "COMERCIAL",
        "PARCEIRO_MASTER",
      ]),
  },
  {
    path: "/extrato-online-avulso",
    Children: ExtratoOnlineAvulso,
    permission: (user) =>
      checkPermission(user, [
        "SUPER",
        "REGIONAL",
        "COMERCIAL",
        "PARCEIRO_MASTER",
        "PARCEIRO_VENDEDOR",
        "PARCEIRO_OPERACIONAL",
      ]),
  },
  {
    path: "/extrato-online-lote",
    Children: ExtratoOnlineLote,
    permission: (user) => user.isLogged,
  },
  {
    path: "/campanha-whatsapp",
    Children: CampanhaNova,
    permission: (user) =>
      checkPermission(user, [
        "SUPER",
        "REGIONAL",
        "COMERCIAL",
        "PARCEIRO_MASTER",
      ]),
  },
  {
    path: "/campanha-sms",
    Children: CampanhaSMS,
    permission: (user) =>
      checkPermission(user, [
        "SUPER",
        "REGIONAL",
        "COMERCIAL",
        "PARCEIRO_MASTER",
      ]),
  },
  {
    path: "/campanha-ura-whatsapp",
    Children: CampanhaURAWhatsapp,
    permission: (user) =>
      checkPermission(user, [
        "SUPER",
        "REGIONAL",
        "COMERCIAL",
        "PARCEIRO_MASTER",
      ]),
  },
  {
    path: "/atendimentos",
    Children: AtendimentoPage,
    permission: (user) =>
      checkPermission(
        user,
        [
          "SUPER",
          "REGIONAL",
          "COMERCIAL",
          "PARCEIRO_MASTER",
          "PARCEIRO_VENDEDOR",
          "PARCEIRO_OPERACIONAL",
        ],
        ["PARCEIRO_MASTER", "PARCEIRO_VENDEDOR"]
      ),
  },
  {
    path: "/consulta",
    Children: Consulta,
    permission: (user) =>
      checkPermission(
        user,
        [
          "SUPER",
          "REGIONAL",
          "COMERCIAL",
          "PARCEIRO_MASTER",
          "PARCEIRO_VENDEDOR",
          "PARCEIRO_OPERACIONAL",
        ]
        // ["PARCEIRO_MASTER", "PARCEIRO_VENDEDOR"]
      ),
  },
  {
    path: "/tabelas-digitacao",
    Children: TebelasComissao,
    permission: (user) =>
      checkPermission(user, [
        "SUPER",
        "REGIONAL",
        "COMERCIAL",
        "PARCEIRO_MASTER",
      ]),
  },
  {
    path: "/sessoes-whatsapp",
    Children: SessoesWhatsApp,
    permission: (user) =>
      checkPermission(user, [
        "SUPER",
        "REGIONAL",
        "COMERCIAL",
        "PARCEIRO_MASTER",
        "PARCEIRO_VENDEDOR",
        "PARCEIRO_OPERACIONAL",
      ]),
  },
  {
    path: "/empresas",
    Children: Empresas,
    permission: (user) =>
      checkPermission(
        user,
        ["SUPER", "REGIONAL", "COMERCIAL", "FINANCEIRO"],
        ["COMERCIAL"]
      ),
  },
  {
    path: "/usuarios",
    Children: Usuarios,
    permission: (user) =>
      checkPermission(
        user,
        ["SUPER", "REGIONAL", "COMERCIAL", "PARCEIRO_MASTER"],
        ["COMERCIAL", "PARCEIRO_MASTER"]
      ),
  },
  {
    path: "/config",
    Children: ConfigPage,
    permission: (user) => checkPermission(user, ["SUPER"]),
  },
  {
    path: "*",
    Children: NotFound,
    permission: () => true,
  },
];
