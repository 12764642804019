import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  Input,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { dropdownConvenioAtendimento } from "components/atendimentos-components/atendimento-form/fields-data";
import { getErrorByMessage } from "components/atendimentos-components/modal-atendimento/functions";
import { CustomModal } from "components/custom-modal";
import { Dropdown } from "components/dropdown";
import { DraggableFileField } from "components/input-file";
import { UploadIcon } from "components/vectors/upload-icon";
import { useEffect, useRef, useState } from "react";
import { createEventListener, makeEvent } from "services/events";
import { MailingField, uploadMailingFields } from "./consts";

export function MailingUploadModal() {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [convenio, setConvenio] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [fileError, setFileError] = useState("");
  const [nameError, setNameError] = useState("");
  const [convenioError, setConvenioError] = useState("");
  const [values, setValues] = useState<any>({});
  const [csvHeaderOptions, setCsvHeaderOptions] = useState<
    { name: string; value: string | undefined }[]
  >([]);
  const toast = useToast();
  const nameRef = useRef<HTMLInputElement>(null);

  const onCloseModal = () => {
    onClose();
    setLoading(false);
    setFile(null);
    setValues({});
    setCsvHeaderOptions([]);
    setConvenio("");
    setNameError("");
    setFileError("");
    setConvenioError("");
  };

  const onChangeValues = (field: string, value: any) => {
    setValues({ ...values, [field]: value });
  };

  const getDropdownOptions = async (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        const result = reader.result as string;
        const header = result.split?.("\n")?.[0]?.split?.(";");
        if (header?.length) resolve(header);
        else reject(undefined);
      };
      reader.onerror = function (error) {
        reject(undefined);
      };
    });
  };

  const handleSubmit = async () => {
    const name = nameRef.current!.value;
    if (!name || !file || !convenio) {
      if (!convenio) setConvenioError("Preencha o campo Convênio");
      if (!file) setFileError("Insira um arquivo CSV");
      if (!name) setNameError("Preencha o campo Nome");
      return;
    }
    const formData = new FormData();
    formData.append("convenio", convenio);
    formData.append("files", file!);
    formData.append("filename", file!.name);
    formData.append("name", name);
    Object.keys(values).forEach((key) => {
      if (values[key]) formData.append(key, values[key]);
    });
    setLoading(true);
    try {
      const response = await api.post("/mailings/manual", formData);
      toast({ title: "Mailing criado com sucesso", ...toastDefaultStyle });
      onCloseModal();
      makeEvent("update-mailings-table");
    } catch (error: any) {
      let hasMessage = error.response?.data?.message;
      toast({
        title: getErrorByMessage(error, "Erro ao criar mailing"),
        status: "error",
        ...toastDefaultStyle,
      });
    }
  };

  useEffect(() => {
    Object.keys(values).forEach((key) => {
      let current: undefined | MailingField;
      uploadMailingFields.forEach(({ fields }) => {
        const curr = fields.find(({ field }) => field === key);
        if (curr) current = curr;
      });
      if (!current?.isVisible(convenio))
        setValues({ ...values, [key]: undefined });
    });
  }, [convenio]);

  useEffect(() => {
    if (file)
      getDropdownOptions(file).then((headers) => {
        const headersColumns = headers as string[];
        const headersOptions = [undefined, ...headersColumns];
        setCsvHeaderOptions(
          headersOptions.map((column) => ({
            name: column ?? "Selecione",
            value: column,
          }))
        );
      });
    else setCsvHeaderOptions([]);
  }, [file]);

  const handleFile = (file: File) => {
    if (file) setFileError("");
    setFile(file);
  };

  const handleDelete = () => setFile(null);

  createEventListener("open-mailing-upload", onOpen);

  const modalFooter = (
    <>
      <Button
        disabled={!values.colunaCPF}
        onClick={() => {
          handleSubmit();
        }}
        isLoading={loading}
        leftIcon={<UploadIcon />}
      >
        Upload Mailing
      </Button>
      <Button
        onClick={onCloseModal}
        leftIcon={<CloseIcon w="12px" h="12px" />}
        variant="outline"
      >
        Fechar
      </Button>
    </>
  );

  return (
    <CustomModal
      modalTitle="Upload Mailing"
      isOpen={isOpen}
      onClose={onCloseModal}
      modalFooter={modalFooter}
      isLoading={loading}
      size="4xl"
      scroll="inside"
      maxH="90vh"
    >
      <Grid templateColumns="1fr 1fr" gap="15px">
        <Box>
          <Text fontSize="16" mb="5px">
            Convênio
          </Text>
          <Dropdown
            value={convenio}
            w="full"
            isInvalid={convenioError !== ""}
            options={dropdownConvenioAtendimento}
            onChange={(value) => {
              setConvenioError("");
              setConvenio(value);
            }}
          />
        </Box>
        <Box>
          <Text fontSize="16" mb="5px">
            Nome
          </Text>
          <Input
            isInvalid={nameError !== ""}
            _invalid={{
              border: "3px solid var(--chakra-colors-secondary-600)",
            }}
            onChange={() => setNameError("")}
            ref={nameRef}
          />
        </Box>
      </Grid>
      <DraggableFileField
        handleFile={handleFile}
        handleDelete={handleDelete}
        error={fileError}
        fieldTitle="Selecionar CSV:"
        file={file}
        dragFieldStyle={{ p: file ? "0" : "40px 20px" }}
      />

      {csvHeaderOptions.length ? (
        <>
          {uploadMailingFields.map((fieldsGroup, index) => {
            const { fields, title } = fieldsGroup;
            const isLast = index + 1 === uploadMailingFields.length;
            return (
              <>
                <Box mb={isLast ? undefined : "20px"}>
                  <Text fontSize="18" mb="15px">
                    {title}
                  </Text>
                  <hr style={{ margin: "15px 0" }} />
                  <Grid templateColumns="1fr 1fr 1fr" gap="15px">
                    {fields.map(({ field, isVisible, name, type }) => {
                      if (isVisible(convenio)) {
                        if (type === "multi-select")
                          return (
                            <Flex key={field} flexDir="column">
                              <Text mb="5px">{name}</Text>
                              <Dropdown
                                value={values[field] || []}
                                onChange={(value) => {
                                  if (values[field]?.length) {
                                    const isInclude =
                                      values[field].includes(value);
                                    if (isInclude)
                                      onChangeValues(
                                        field,
                                        values[field].filter(
                                          (curr: any) => curr !== value
                                        )
                                      );
                                    else
                                      onChangeValues(field, [
                                        ...values[field],
                                        value,
                                      ]);
                                  } else onChangeValues(field, [value]);
                                }}
                                options={csvHeaderOptions}
                                w="full"
                                multiSelect={true}
                              />
                            </Flex>
                          );
                        else
                          return (
                            <Flex key={field} flexDir="column">
                              <Text mb="5px">{name}</Text>
                              <Dropdown
                                w="100%"
                                options={csvHeaderOptions}
                                value={values[field]}
                                onChange={(value) =>
                                  onChangeValues(field, value)
                                }
                              />
                            </Flex>
                          );
                      } else return null;
                    })}
                  </Grid>
                </Box>
              </>
            );
          })}
        </>
      ) : null}
    </CustomModal>
  );
}
