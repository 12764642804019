import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  DownloadIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Link,
  Progress,
  Text,
  useToast,
} from "@chakra-ui/react";
import { toastDefaultStyle } from "chakra/theme";
import { CustomModal } from "components/custom-modal";
import DynamicTable from "components/dynamic-table";
import { RowStatus } from "components/dynamic-table/row-status";
import { GetLayout } from "components/get-layout";
import { DraggableFileField } from "components/input-file";
import { ConfirmDialog } from "components/modal-confirm";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import api from "../../api/api";
import { Dropdown } from "components/dropdown";
import { useApplicationContext } from "contexts/ApplicationContext";

export const HigienizacaoLista = () => {
  const [confirmDialogData, setConfirmDialogData] = useState(null);
  const fileUploadRef = useRef(null);
  const [higienizacaoDialog, setHigienizacaoDialog] = useState({
    nome: "",
    banco: "",
    convenio: "",
    atualizacaoMinima: "",
    file: "",
    filename: "",
  });

  const [loading, setLoading] = useState(false);
  const [openHigienizacaoDialog, setOpenHigienizacaoDialog] = useState(false);
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
  const [higienizacoes, setHigienizacoes] = useState([]);
  const [downloadsDialog, setDownloadsDialog] = useState([]);
  const [errors, setErrors] = useState({ nome: false });
  const [openResumoDialog, setOpenResumoDialog] = useState(false);
  const [resumo, setResumo] = useState({});
  const toast = useToast();
  const { user } = useApplicationContext();

  const saveOrUpdateHigienizacao = async () => {
    let hasNomeError =
      !higienizacaoDialog.nome || higienizacaoDialog.nome.trim().length === 0;
    let hasBancoError = !higienizacaoDialog.banco;
    let hasConvenioError = !higienizacaoDialog.convenio;
    let hasFileError = !higienizacaoDialog.file;
    setErrors({
      nome: hasNomeError,
      banco: hasBancoError,
      convenio: hasConvenioError,
      file: hasFileError,
    });
    if (hasNomeError || hasBancoError || hasConvenioError || hasFileError)
      return;
    setLoading(true);
    let formData = new FormData();
    formData.append("files", higienizacaoDialog.file);
    formData.append("filename", higienizacaoDialog.filename);
    formData.append("nome", higienizacaoDialog.nome);
    formData.append("banco", higienizacaoDialog.banco);
    formData.append("convenio", higienizacaoDialog.convenio);
    formData.append("atualizacaoMinima", higienizacaoDialog.atualizacaoMinima);
    try {
      let response = await api.post("/higienizacoes", formData);
      setResumo(response.data);
      setOpenResumoDialog(true);
    } catch (error) {
      let hasMessage =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message;
      if (
        hasMessage &&
        "column_cpf_not_found" === error.response.data.message
      ) {
        toast({
          title: "Erro ao criar higienização: Arquivo não possui coluna CPF!",
          status: "error",
          ...toastDefaultStyle,
        });
      } else if (
        hasMessage &&
        "duplicate_column_cpf_found" === error.response.data.message
      ) {
        toast({
          title:
            "Erro ao criar higienização: Arquivo possui mais de uma coluna CPF!",
          status: "error",
          ...toastDefaultStyle,
        });
      } else {
        toast({
          title: "Erro ao criar higienização!",
          status: "error",
          ...toastDefaultStyle,
        });
      }
    }
    setOpenHigienizacaoDialog(false);
    await loadHigienizacoes();
    setLoading(false);
  };

  const showDownloadDialog = async (id) => {
    setLoading(true);
    await loadDownloadDialogData(id);
    setOpenDownloadDialog(true);
    setLoading(false);
  };

  const loadDownloadDialogData = async (id) => {
    let response = await api.get("/higienizacoes/download/find/" + id);
    setDownloadsDialog(response.data);
  };

  const createDownload = async (id) => {
    setLoading(true);
    try {
      await api.get("/higienizacoes/download/create/" + id);
    } catch (error) {
      toast({
        title: "Nao foi possivel criar o download",
        status: "error",
        ...toastDefaultStyle,
      });

      throw error;
    }
    await loadDownloadDialogData(id);
    setLoading(false);
  };

  const download = async (rowData, type) => {
    setLoading(true);
    let response;
    let filename;
    if (
      rowData.download === "" ||
      rowData.download === undefined ||
      rowData.download === null
    ) {
      response = await api.get(
        "/higienizacoes/download/" + rowData.higienizacaoId + "?type=" + type
      );
      filename = rowData.nome + ".csv";
    } else {
      response = await api.get(
        "/higienizacoes/download/" +
          rowData.higienizacaoId +
          "/" +
          rowData.download +
          "?type=" +
          type
      );
      filename = rowData.nome + "_parte_" + rowData.download + ".csv";
    }
    createLinkAndDownload(response, filename);
    setLoading(false);
  };

  const createLinkAndDownload = (response, filename) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await loadHigienizacoes();
      setLoading(false);
    };
    load();
  }, []);

  const loadHigienizacoes = async () => {
    let response = await api.get("/higienizacoes");
    setHigienizacoes(response.data);
  };

  const atualizar = async () => {
    setLoading(true);
    await loadHigienizacoes();
    setLoading(false);
  };

  const onChangeHigienizacaoDialog = (field, value) => {
    setErrors({ ...errors, [field]: false });
    if ("banco" === field) {
      setHigienizacaoDialog({
        ...higienizacaoDialog,
        [field]: value,
        convenio: "",
      });
    } else {
      setHigienizacaoDialog({
        ...higienizacaoDialog,
        [field]: value,
      });
    }
  };

  const onChangeFile = (file) => {
    setErrors({ ...errors, file: false });
    setHigienizacaoDialog({
      ...higienizacaoDialog,
      file: file,
      filename: file.name,
    });
  };
  const handleDeleteFile = () => {
    setErrors({ ...errors, file: true });
    setHigienizacaoDialog({
      ...higienizacaoDialog,
      file: null,
      filename: "",
    });
  };

  const openNew = () => {
    setErrors({});
    setHigienizacaoDialog({
      nome: "",
      banco: "",
      convenio: "",
      atualizacaoMinima: "",
      file: "",
      filename: "",
    });
    setOpenHigienizacaoDialog(true);
  };

  const dropdownBancosHigienizacao = [
    // { name: "PAN", value: "PAN" },
    { name: "BMG", value: "BMG" },
    { name: "BRADESCO", value: "BRADESCO" },
    { name: "ITAÚ", value: "ITAU" },
    { name: "SAFRA", value: "SAFRA" },
    { name: "OLÉ", value: "OLE" },
    { name: "C6", value: "C6" },
    { name: "DAYCOVAL", value: "DAYCOVAL" },
    { name: "BANRISUL", value: "BANRISUL" },
    // { name: "CETELEM", value: "CETELEM" },
  ];

  const getConveniosByBanco = (banco) => {
    const options = {
      DAYCOVAL: [
        { name: "INSS", value: "INSS" },
        // {name: 'PMSP', value: 'PMSP'},
        { name: "SIAPE", value: "SIAPE" },
        { name: "AERONAUTICA", value: "AERONAUTICA" },
        // {name: 'GOVPE', value: 'GOVPE'}
      ],
      PAN: [
        { name: "INSS", value: "INSS" },
        { name: "SIAPE", value: "SIAPE" },
        { name: "SIAPE_PENSIONISTA", value: "SIAPE_PENSIONISTA" },
        { name: "AERONAUTICA", value: "AERONAUTICA" },
        { name: "MARINHA", value: "MARINHA" },
        { name: "GOVSC", value: "GOVSC" },
        { name: "GOVPR", value: "GOVPR" },
        { name: "GOVBA", value: "GOVBA" },
        { name: "PMSA", value: "PMSA" },
        { name: "GOVMT", value: "GOVMT" },
        { name: "GOVSP", value: "GOVSP" },
        { name: "PMSP", value: "PMSP" },
      ],
      SAFRA: [
        { name: "INSS", value: "INSS" },
        { name: "GOVBA", value: "GOVBA" },
      ],
      C6: [{ name: "INSS", value: "INSS" }],
      OLE: [
        { name: "INSS", value: "INSS" },
        { name: "SIAPE", value: "SIAPE" },
        { name: "AERONAUTICA", value: "AERONAUTICA" },
        { name: "MARINHA", value: "MARINHA" },
        { name: "GOVBA", value: "GOVBA" },
        { name: "GOVPR", value: "GOVPR" },
        { name: "GOVMT", value: "GOVMT" },
        { name: "GOVSP", value: "GOVSP" },
        { name: "PMSP", value: "PMSP" },
        { name: "PMMACAE", value: "PMMACAE" },
        { name: "GOVGO", value: "GOVGO" },
        { name: "GOVMG", value: "GOVMG" },
        { name: "PMGO", value: "PMGO" },
        { name: "PMCUIABA", value: "PMCUIABA" },
      ],
      ITAU: [
        { name: "INSS", value: "INSS" },
        { name: "SIAPE", value: "SIAPE" },
        { name: "PMESP", value: "PMESP" },
        { name: "SPPREV", value: "SPPREV" },
        { name: "SEFAZ", value: "SEFAZ" },
        { name: "PMSP", value: "PMSP" },
        { name: "IPREM", value: "IPREM" },
        { name: "GOVMG", value: "GOVMG" },
        { name: "GOVPR", value: "GOVPR" },
        { name: "GOVMT", value: "GOVMT" },
        { name: "GOVRJ", value: "GOVRJ" },
      ],
      BMG: [
        { name: "INSS", value: "INSS" },
        { name: "SIAPE", value: "SIAPE" },
        { name: "SPPREV", value: "SPPREV" },
        { name: "SEFAZ", value: "SEFAZ" },
        { name: "PMSP", value: "PMSP" },
        { name: "PMMG", value: "PMMG" },
        { name: "GOVMG", value: "GOVMG" },
        { name: "GOVSC", value: "GOVSC" },
        { name: "GOVRJ", value: "GOVRJ" },
        { name: "GOVMT", value: "GOVMT" },
        { name: "IPSM", value: "IPSM" },
        { name: "CBMG", value: "CBMG" },
        { name: "IPSEMG", value: "IPSEMG" },
      ],
      CETELEM: [
        { name: "INSS", value: "INSS" },
        { name: "SIAPE", value: "SIAPE" },
      ],
      BANRISUL: [
        { name: "INSS", value: "INSS" },
        { name: "SIAPE", value: "SIAPE" },
      ],
      BRADESCO: [
        { name: "INSS", value: "INSS" },
        // {name: 'GOVPE', value: 'SIGOVPEAPE'},
        // {name: 'GOVAL', value: 'GOVAL'},
        // {name: 'GOVBA', value: 'GOVBA'}
      ],
    };
    const bancoOptions = options[banco] ?? [];
    if (user.userData.customerId === 1) {
      if (
        [
          "DAYCOVAL",
          "BMG",
          "BRADESCO",
          "CETELEM",
          "ITAU",
          "PAN",
          "SAFRA",
          "BANRISUL",
          "OLE",
          "C6",
        ].includes(banco)
      ) {
        if (!bancoOptions.find(({ value }) => value === "SIAPE"))
          bancoOptions.push({ name: "SIAPE", value: "SIAPE" });
      }
    }
    return bancoOptions;
  };

  const numeroDownloadTemplate = (rowData) => {
    return rowData.download !== "" &&
      rowData.download !== undefined &&
      rowData.download !== null
      ? rowData.download
      : "TODOS";
  };

  const actionDownloadTemplate = (rowData) => {
    let hasDownload =
      rowData.download !== undefined && rowData.download !== null;
    let isCompleted =
      rowData.completed !== undefined &&
      rowData.completed !== null &&
      rowData.completed === true;

    if (hasDownload && isCompleted) {
      return (
        <>
          <Link
            href="#"
            label="download"
            className="p-button-sm p-button-text"
            onClick={() => download(rowData, "colunas")}
          >
            {"Colunas"}
          </Link>
          <Link
            style={{ marginLeft: 20 }}
            href="#"
            label="download"
            className="p-button-sm p-button-text"
            onClick={() => download(rowData, "linhas")}
          >
            {"Linhas"}
          </Link>
        </>
      );
    } else if (!hasDownload && isCompleted) {
      return (
        <Link
          href="#"
          label="download"
          className="p-button-sm p-button-text"
          onClick={() => createDownload(rowData.higienizacaoId)}
        >
          {"adicionar"}
        </Link>
      );
    } else {
      return "";
    }
  };

  const higienizacaoDialogFooter = (
    <>
      <Button
        isLoading={loading}
        loadingText="Salvando"
        leftIcon={<CheckIcon />}
        onClick={() => saveOrUpdateHigienizacao()}
      >
        Salvar
      </Button>
      <Button
        leftIcon={<CloseIcon w="12px" h="12px" />}
        onClick={() => hideHigienizacaoDialog()}
        variant="outline"
      >
        Cancelar
      </Button>
    </>
  );

  const downloadDialogFooter = (
    <>
      <Button
        leftIcon={<CloseIcon w="12px" h="12px" />}
        variant="outline"
        onClick={() => hideDownloadDialog()}
      >
        Fechar
      </Button>
    </>
  );
  const hideResumoDialog = () => {
    setOpenResumoDialog(false);
  };
  const resumoDialogFooter = (
    <Button
      leftIcon={<CloseIcon w="12px" h="12px" />}
      onClick={hideResumoDialog}
      variant="outline"
    >
      Fechar
    </Button>
  );

  const hideHigienizacaoDialog = () => {
    setOpenHigienizacaoDialog(false);
  };

  const hideDownloadDialog = () => {
    setOpenDownloadDialog(false);
  };

  const createDownloadsTableContent = (downloads) => {
    let filtered = downloads.filter(
      (v) => v.download !== undefined && v.download !== null
    );
    let quantidade = 0;
    for (var i = 0; i < filtered.length; i++) {
      quantidade = quantidade + filtered[i].quantidade;
    }
    if (quantidade > 0) {
      filtered.push({
        higienizacaoId: filtered[0].higienizacaoId,
        nome: filtered[0].nome,
        download: "",
        quantidade: quantidade,
        completed: true,
      });
    }
    return filtered;
  };

  const createStatusDownloadTemplate = (rowData) => {
    if (rowData === "" || rowData === undefined || rowData === null) {
      return "";
    } else {
      return rowData.completed === true ? "Completo" : "Em andamento";
    }
  };

  const acceptDeleteHigienizacao = async (rowData) => {
    setLoading(true);
    try {
      await api.delete("/higienizacoes/" + rowData.id);
    } catch (error) {
      toast({
        title: "Erro ao excluir higienização!",
        status: "error",
        ...toastDefaultStyle,
      });
    }
    await loadHigienizacoes();
    setLoading(false);
  };

  const confirmDeleteHigienizacao = (rowData) => {
    setConfirmDialogData({
      message: `Deseja excluir "${rowData.nome}"?`,
      title: "Confirmação",
      accept: () => acceptDeleteHigienizacao(rowData),
      close: () => setConfirmDialogData(null),
      acceptButtonStyle: { variant: "danger", leftIcon: <DeleteIcon /> },
    });
  };

  const columns = [
    {
      name: "Nome",
      key: "nome",
    },
    {
      name: "Banco",
      key: "banco",
    },
    {
      name: "Convênio",
      key: "convenio",
    },
    {
      name: "Criação",
      key: "criacao",
      render: (row) => <>{new Date(row.criacao).toLocaleString()}</>,
    },
    {
      name: "Atividade",
      render: (row) => (
        <Box>
          <Text>
            {row.totalCompleted}/{row.total}
          </Text>
          <Progress
            value={(row.totalCompleted / row.total) * 100}
            size="xs"
            bg="gray.200"
            borderRadius="10"
            colorScheme="gray"
          />
        </Box>
      ),
    },
    {
      name: "Status",
      render: (row) => {
        const status =
          row.totalCompleted / row.total === 1 ? "COMPLETO" : "EM_ANDAMENTO";
        return (
          <RowStatus status={status}>
            {status.replace("_", " ").toLocaleLowerCase()}
          </RowStatus>
        );
      },
    },
    {
      name: "Ações",
      render: (row) => (
        <Flex gap="10px">
          <IconButton
            icon={<DownloadIcon />}
            onClick={() => showDownloadDialog(row.id)}
          />
          <IconButton
            icon={<DeleteIcon />}
            variant="danger"
            onClick={() => confirmDeleteHigienizacao(row)}
          />
        </Flex>
      ),
    },
  ];

  return (
    <>
      <ConfirmDialog dialogData={confirmDialogData} />
      <GetLayout withOutSearch>
        <Flex justifyContent="space-between" px="10px" mb="30px">
          <Text fontSize="25" fontWeight="bold">
            Lista de Higienizações
          </Text>

          <Flex gap="20px" alignItems="center">
            <Button
              leftIcon={<RepeatIcon />}
              onClick={atualizar}
              isLoading={loading}
              loadingText="Atualizando"
            >
              Atualizar
            </Button>
            <Button leftIcon={<AddIcon />} onClick={openNew}>
              Nova
            </Button>
          </Flex>
        </Flex>

        <DynamicTable
          columns={columns}
          rows={higienizacoes}
          isLoading={loading}
          emptyMessage="Não há higienizações cadastradas"
        />

        <CustomModal
          isOpen={openHigienizacaoDialog}
          modalTitle="Higienização"
          modalFooter={higienizacaoDialogFooter}
          onClose={hideHigienizacaoDialog}
        >
          <Box mb="8px">
            <Text mb="8px">Nome</Text>
            <Input
              value={higienizacaoDialog.nome}
              isInvalid={errors.nome}
              onChange={(e) =>
                onChangeHigienizacaoDialog("nome", e.target.value)
              }
            />
          </Box>
          <Box mb="8px">
            <Text mb="8px">Banco</Text>
            <Dropdown
              w="100%"
              value={higienizacaoDialog.banco}
              isInvalid={errors.banco}
              onChange={(e) => onChangeHigienizacaoDialog("banco", e)}
              options={dropdownBancosHigienizacao}
            />
          </Box>
          <Box mb="8px">
            <Text mb="8px">Convênio</Text>
            <Dropdown
              w="100%"
              value={higienizacaoDialog.convenio}
              isInvalid={errors.convenio}
              onChange={(e) => onChangeHigienizacaoDialog("convenio", e)}
              options={getConveniosByBanco(higienizacaoDialog.banco)}
            />
          </Box>
          <DraggableFileField
            error={errors.file ? "Insira um arquivo!" : ""}
            fieldTitle="Selecione um Arquivo"
            file={higienizacaoDialog.file}
            handleFile={onChangeFile}
            handleDelete={handleDeleteFile}
          />
        </CustomModal>

        <Dialog
          visible={openDownloadDialog}
          style={{ width: "450px" }}
          header="Download"
          modal
          className="p-fluid"
          footer={downloadDialogFooter}
          onHide={hideDownloadDialog}
        >
          <div>
            <DataTable
              value={createDownloadsTableContent(downloadsDialog)}
              rowHover
              header={"Downloads"}
              size="small"
              emptyMessage="Não há downloads adicionados"
            >
              <Column
                header="#"
                style={{ width: 80 }}
                body={numeroDownloadTemplate}
              ></Column>
              <Column
                field="quantidade"
                style={{ width: 90 }}
                header="Qtd."
              ></Column>
              <Column
                header="Status"
                style={{ width: 180 }}
                body={createStatusDownloadTemplate}
              ></Column>
              <Column
                header="Download"
                style={{ width: 150 }}
                body={actionDownloadTemplate}
              ></Column>
            </DataTable>
          </div>
          <div style={{ marginTop: 15 }}>
            <DataTable
              value={downloadsDialog.filter(
                (v) => v.download === undefined || v.download === null
              )}
              rowHover
              header={"Não adicionados"}
              size="small"
              emptyMessage="Não há downloads para adicionar"
            >
              <Column header="#" style={{ width: 80 }} body={"#"}></Column>
              <Column
                field="quantidade"
                style={{ width: 90 }}
                header="Qtd."
              ></Column>
              <Column
                header="Status"
                body={createStatusDownloadTemplate}
              ></Column>
              <Column
                header="Ação"
                style={{ width: 80 }}
                body={actionDownloadTemplate}
              ></Column>
            </DataTable>
          </div>
        </Dialog>

        <CustomModal
          isOpen={openResumoDialog}
          size="sm"
          modalTitle="Resumo"
          modalFooter={resumoDialogFooter}
          onClose={hideResumoDialog}
        >
          <p>
            <b>Qtd. de CPFs válidos e adicionados: {resumo.qtdCpfsValidos}</b>
          </p>
          {resumo.qtdCpfsRepetidos > 0 && (
            <p>Qtd. de CPFs repetidos: {resumo.qtdCpfsRepetidos}</p>
          )}
          {resumo.qtdCpfsInvalidos > 0 && (
            <p>Qtd. de CPFs inválidos: {resumo.qtdCpfsInvalidos}</p>
          )}
        </CustomModal>
      </GetLayout>
    </>
  );
};
