import { Link } from "@chakra-ui/react";
import linkify from "linkify-it";
import { getExportedFunction } from "services/get-external-function";
import { getToken } from "Session";

type MessageText = {
  action: "send-whatsapp-chat";
  body: string;
  to: string;
};

export function sendMessageChat({ body }: { body: string }) {
  body = body.trim();
  const isValid = !(body === "");
  const sendMessage = getExportedFunction("sendJsonMessage");
  if (isValid) {
    sendMessage({
      contentType: "action",
      action: "send-message",
      whatsappNewMessage: {
        type: "chat",
        body: body,
      },
    });
    return true;
  }
}

export function addLinks(text: string) {
  const matches = linkify().match(text);
  if (!matches) return text;

  const results = [];
  let lastIndex = 0;

  matches.forEach((match, i) => {
    results.push(text.slice(lastIndex, match.index));
    results.push(
      <Link
        color="blue.500"
        target="_blank"
        rel="noreferrer"
        href={match.url}
        key={i}
      >
        {match.text}
      </Link>
    );
    lastIndex = match.lastIndex;
  });

  results.push(text.slice(lastIndex, text.length));

  return results;
}
