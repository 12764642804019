// import { Link } from 'react-router-dom';
import { Box, Flex, Text } from "@chakra-ui/react";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useHistory } from "react-router-dom";
import { ProfileMenu } from "./profile-menu";
import { getNavbarLogo } from "components/white-label";

export interface NavbarProps {
  withOutSearch?: boolean;
  HeadRender?: () => JSX.Element | null;
}

export const navbarWidth = "80px";

export function Navbar({ HeadRender }: NavbarProps) {
  const { user } = useApplicationContext();
  const { userData } = user;
  const router = useHistory();

  return (
    <Flex
      w="100%"
      h={navbarWidth}
      bg="#fff"
      justifyContent="space-between"
      alignItems="center"
      px="2rem"
      borderBottom="1px solid #E8EAED"
    >
      <Flex alignItems="center">
        <Box mr="70px" onClick={() => router.push("/")} cursor="pointer">
          {getNavbarLogo()}
        </Box>
      </Flex>
      <Flex alignItems="center">
        {HeadRender ? <HeadRender /> : null}
        <Box color="custom-gray">
          <Text mr="10px" fontWeight="400" lineHeight="1.2">
            {userData.name}
          </Text>
          <Text mr="10px" fontWeight="800" lineHeight="1.2">
            {userData.customerName}
          </Text>
        </Box>
        <ProfileMenu />
      </Flex>
    </Flex>
  );
}
