import { useToast } from "@chakra-ui/react";
import { useAttendanceContext } from "contexts/AttendanceContext";
import { Attendance } from "../../../types";
import { TabDataProps } from "../../consts";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useState } from "react";
import { toastDefaultStyle } from "chakra/theme";
import { DigitacaoAntecipacaoFGTSColombo } from "./parts/digitacao-antecipacao-fgts-colombo";
import { INSSDigitacao } from "./parts/inss-digitacao";

export function DigitacaoFields({
  onChangeField,
  resetFieldsTabelaDigitacao,
}: TabDataProps) {
  const [isLoadingDigitation, setIsLoadingDigitation] = useState(false);

  const { formControl, dispatch } = useAttendanceContext();
  const { user } = useApplicationContext();
  const toast = useToast({ ...toastDefaultStyle });

  const formValues = formControl.values as Attendance;
  const convenio = formValues.convenio;

  return (
    <>
      {convenio === "INSS" ? (
        <INSSDigitacao
          onChangeField={onChangeField}
          resetFieldsTabelaDigitacao={resetFieldsTabelaDigitacao}
        />
      ) : convenio === "ANTECIPACAO_FGTS" ? (
        <DigitacaoAntecipacaoFGTSColombo
          onChangeField={onChangeField}
          resetFieldsTabelaDigitacao={resetFieldsTabelaDigitacao}
        />
      ) : null}
    </>
  );
}
