import {
  AddIcon,
  DeleteIcon,
  RepeatIcon,
  SettingsIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Progress,
  Text,
  useToast,
} from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import {
  ModalCampanhaWhatsApp,
  openModalCampanhaWhatsApp,
} from "components/campanha-components/modal-campanha-whatsapp";
import DynamicTable from "components/dynamic-table";
import { RowStatus } from "components/dynamic-table/row-status";
import { GetLayout } from "components/get-layout";
import { IconButtonWithLoading } from "components/mailing-components/mailing-table/icon-button-with-loading";
import { openModalConfirm } from "components/modal-confirm-new";
import { useEffect, useState } from "react";
import { FaPause, FaPlay } from "react-icons/fa";

export const CampanhaNova = () => {
  const [loading, setLoading] = useState(false);
  const [campanhas, setCampanhas] = useState([]);
  const toast = useToast();

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await loadCampanhas();
      setLoading(false);
    };
    load();
  }, []);

  const getCampanhaStatus = (row) => {
    let status;
    if (isNotConfigurada(row)) {
      status = "CONFIGURACAO PENDENTE";
    } else {
      let value = (row.totalCompleted / row.total) * 100;
      status =
        value === 100
          ? "FINALIZADA"
          : isPlaying(row)
          ? "EM ANDAMENTO"
          : "PAUSADA";
    }
    return status;
  };

  const play = async (rowData) => {
    setLoading(true);
    await api.get("/campanhas-nova/" + rowData.id + "/play");
    await loadCampanhas();
    setLoading(false);
  };

  const pause = async (rowData) => {
    setLoading(true);
    await api.get("/campanhas-nova/" + rowData.id + "/pause");
    await loadCampanhas();
    setLoading(false);
  };

  const loadCampanhas = async () => {
    try {
      const response = await api.get("/campanhas-nova");
      setCampanhas(response.data);
    } catch (e) {
      toast({
        title: "Erro ao carregar campanhas",
        status: "error",
        ...toastDefaultStyle,
      });
    }
  };

  const atualizar = async () => {
    setLoading(true);
    await loadCampanhas();
    setLoading(false);
  };

  const openEditCampanhaDialog = async (row) => {
    try {
      const { data } = await api.get(`/campanhas-nova/${row.id}`);
      openModalCampanhaWhatsApp(data);
    } catch (error) {
      toast({
        title: "Não foi possível abrir a campanha!",
        status: "error",
        ...toastDefaultStyle,
      });
    }
  };

  const isNotConfigurada = (campanha) => {
    return campanha.mensagem == null || campanha.mensagem?.trim() === "";
  };

  const isPlaying = (campanha) => {
    return campanha.paused != null && campanha.paused === false;
  };

  const isFinalizada = (campanha) => {
    return campanha.total === campanha.totalCompleted;
  };

  const acceptDeleteCampanha = async (rowData) => {
    setLoading(true);
    try {
      await api.delete("/campanhas-nova/" + rowData.id);
    } catch (error) {
      toast({
        title: "Erro ao excluir campanha!",
        status: "error",
        ...toastDefaultStyle,
      });
    }
    await loadCampanhas();
    setLoading(false);
  };

  const confirmDeleteCampanha = (rowData) => {
    openModalConfirm({
      message: `Deseja excluir "${rowData.nome}" ?`,
      onConfirm: () => acceptDeleteCampanha(rowData),
      confirmButtonStyle: {
        variant: "danger",
        leftIcon: <DeleteIcon />,
        loadingText: "Excluindo",
      },
    });
  };

  const columns = [
    {
      name: "Nome",
      key: "nome",
    },
    {
      name: "Criação",
      key: "criacao",
      render: (row) =>
        row.criacao ? new Date(row.criacao).toLocaleString() : null,
    },
    {
      name: "Atividade",
      render: (row) => {
        let value = (row.totalCompleted / row.total) * 100;
        return (
          <Box>
            <Text>{row.totalCompleted + "/" + row.total}</Text>
            <Progress
              value={value}
              size="xs"
              bg="gray.200"
              borderRadius="10"
              colorScheme="gray"
            />
          </Box>
        );
      },
    },
    {
      name: "Tipo",
      key: "tipo",
      render: (row) =>
        ({ WP_TEXT_MSG: "Mensagem Texto", WP_AUDIO_MSG: "Mensagem Áudio" }[
          row.tipo
        ]),
    },
    {
      name: "Status",
      key: "nome",
      render: (row) => {
        const status = getCampanhaStatus(row);
        return (
          <RowStatus status={status.replace(/ /g, "_")}>{status}</RowStatus>
        );
      },
    },
    {
      name: "Ações",
      render: (row) => {
        return (
          <Flex>
            <IconButtonWithLoading
              icon={<SettingsIcon />}
              mr="8px"
              onClick={async () => openEditCampanhaDialog(row)}
            />
            {isPlaying(row) && !isFinalizada(row) && (
              <IconButton
                icon={<FaPause />}
                mr="8px"
                onClick={() => pause(row)}
              />
            )}
            {!isPlaying(row) && !isFinalizada(row) && (
              <IconButton
                disabled={isNotConfigurada(row)}
                icon={<FaPlay />}
                mr="8px"
                onClick={() => play(row)}
              />
            )}
            <IconButton
              icon={<DeleteIcon />}
              variant="danger"
              onClick={() => confirmDeleteCampanha(row)}
            />
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <GetLayout>
        <Flex justifyContent="space-between" px="10px" mb="30px">
          <Text fontSize="25" fontWeight="bold">
            Campanhas Whatsapp (Nova)
          </Text>

          <Flex gap="20px" alignItems="center">
            <Button
              leftIcon={<RepeatIcon />}
              onClick={atualizar}
              isLoading={loading}
              loadingText="Atualizando"
            >
              Atualizar
            </Button>
            <Button
              leftIcon={<AddIcon />}
              onClick={() => openModalCampanhaWhatsApp()}
            >
              Enviar Novo Arquivo
            </Button>
          </Flex>
        </Flex>
        <DynamicTable columns={columns} rows={campanhas} isLoading={loading} />

        <ModalCampanhaWhatsApp
          setCampanhas={setCampanhas}
          loadCampanhas={loadCampanhas}
          getCampanhaStatus={getCampanhaStatus}
        />
      </GetLayout>
    </>
  );
};
