import { Box } from "@chakra-ui/react";
import { useAttendanceContext } from "contexts/AttendanceContext";
import { ContractsRCCTable } from "../../../contracts-rcc-table";
import { ContractsRMCTable } from "../../../contracts-rmc-table";
import { ContractsTable } from "../../../contracts-table";
import { ProductTable } from "../../../products-table";
import type { Attendance } from "../../../types";
import { TabDataProps } from "../../consts";

export function ContractsFields({
  resetFieldsTabelaDigitacao,
  onChangeField,
}: TabDataProps) {
  return (
    <Box maxW="calc(66vw - 88px)">
      <ContractsRMCTable />
      <ContractsRCCTable />
      <ContractsTable />
      <ProductTable />
    </Box>
  );
}
