import { Text, TextProps } from "@chakra-ui/react";
import { Dropdown, DropdownProps } from "components/dropdown";
import { Popover, PopoverProps } from "components/popover";
import { useEffect, useState } from "react";

export interface DropdownFieldProps {
  title?: string;
  value?: any;
  options?: DropdownProps["options"];
  onChange?: DropdownProps["onChange"];
  isInvalid?: boolean;
  errorMessage?: string;
  dropdownProps?: DropdownProps;
  titleProps?: TextProps;
  popoverProps?: Omit<PopoverProps, "children" | "button">;
  messageErrorProps?: TextProps;
  onOpen?: (target: HTMLDivElement) => void;
  onClose?: (target: HTMLDivElement) => void;
}

export function DropdownField({
  title,
  value,
  isInvalid,
  errorMessage,
  options,
  onChange,
  titleProps,
  dropdownProps,
  popoverProps,
  messageErrorProps,
  onOpen,
  onClose,
}: DropdownFieldProps) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!errorMessage) setIsOpen(false);
    else setIsOpen(true);
  }, [!!errorMessage]);

  return (
    <>
      {title ? (
        <Text mb="8px" {...titleProps}>
          {title}
        </Text>
      ) : null}
      <Popover
        button={
          <Dropdown
            options={options}
            value={value}
            onChange={onChange}
            isInvalid={isInvalid || !!errorMessage}
            onCloseEvent={(target) => {
              if (!!errorMessage) setIsOpen(true);
              onClose?.(target);
            }}
            onOpenEvent={(target) => {
              setIsOpen(false);
              onOpen?.(target);
            }}
            {...dropdownProps}
          />
        }
        isOpen={isOpen}
        customArrowPos={{
          right: "80%",
          transform: "translate(50%, calc(-100% + 1px))",
        }}
        popupStyles={{ width: "100%" }}
        variant="danger"
        {...popoverProps}
      >
        <Text
          color="#fff"
          fontWeight="bold"
          fontSize="13"
          {...messageErrorProps}
        >
          {errorMessage}&nbsp;
        </Text>
      </Popover>
    </>
  );
}
