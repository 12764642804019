import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { Button, Flex, IconButton, Text, useToast } from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import {
  dropdownTipoOperacaoINSS,
  dropdownTipoOperacaoSIAPE,
} from "components/atendimentos-components/atendimento-form/fields-data";
import DynamicTable from "components/dynamic-table";
import { Column } from "components/dynamic-table/types";
import { ConfirmDialog, ConfirmDialogData } from "components/modal-confirm";
import { openModalConfirm } from "components/modal-confirm-new";
import { ModalConfigTables } from "components/tabelas-digitacao-components/modal-config-tables";
import { useEffect, useRef, useState } from "react";

interface Table {
  id: number;
  convenio: string;
  tipoOperacao: string;
  banco: string;
  prazo: string;
  codigo: string;
  descricaoCompleta: string;
}

export function TebelasComissaoPage() {
  const addButtonRef = useRef<HTMLButtonElement>(null);
  const [tables, setTables] = useState<Table[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast();

  async function handleDelete(id: number) {
    setIsLoading(true);
    try {
      const response = await api.delete(`/tabelas-digitacao/${id}`);
      getTables();
      setIsLoading(false);
    } catch (e) {
      toast({
        title: "Ocorreu um erro ao apagar tabelas",
        status: "error",
        ...toastDefaultStyle,
      });
      setIsLoading(false);
    }
  }

  async function getTables() {
    setIsLoading(true);
    try {
      const response = await api.get("/tabelas-digitacao");
      setTables(response.data);
      setIsLoading(false);
    } catch (e: any) {
      toast({
        title: "Ocorreu um erro ao carregar tabelas",
        status: "error",
        ...toastDefaultStyle,
      });
      setIsLoading(false);
    }
  }
  useEffect(() => {
    getTables();
  }, []);

  const operationsTypes = [
    ...dropdownTipoOperacaoINSS,
    ...dropdownTipoOperacaoSIAPE,
  ];

  const columns: Column[] = [
    {
      name: "Convênio",
      key: "convenio",
    },
    {
      name: "Tipo Operação",
      render: (row) =>
        operationsTypes.find((curr) => curr.value === row.tipoOperacao)?.name ??
        row.tipoOperacao,
    },
    {
      name: "Banco",
      key: "banco",
    },
    {
      name: "Prazo",
      key: "prazo",
    },
    {
      name: "Tabela",
      key: "descricaoCompleta",
    },
    {
      name: "Ações",
      render: (row) => (
        <IconButton
          onClick={() =>
            openModalConfirm({
              message: `Deseja excluir este produto "Tabela: ${row.descricaoCompleta}" ?`,
              onConfirm: () => handleDelete(row.id),
              confirmButtonStyle: { leftIcon: <DeleteIcon /> },
            })
          }
          variant="danger"
          icon={<DeleteIcon />}
          aria-label=""
        />
      ),
    },
  ];

  return (
    <>
      <Flex justifyContent="space-between" px="10px" mb="30px">
        <Text fontSize="25" fontWeight="bold">
          Tabelas Digitação
        </Text>

        <Flex gap="20px" alignItems="center">
          <Button leftIcon={<AddIcon />} ref={addButtonRef}>
            Adicionar
          </Button>
        </Flex>
      </Flex>
      <DynamicTable columns={columns} rows={tables} isLoading={isLoading} />
      <ModalConfigTables openRef={addButtonRef} onSaved={getTables} />
    </>
  );
}
