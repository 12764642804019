import { CheckIcon, CloseIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { FormControl } from "components/atendimentos-components/modal-atendimento/actions-reducer";
import { CustomModal } from "components/custom-modal";
import { DropdownOption } from "components/dropdown";
import DynamicTable from "components/dynamic-table";
import { Column } from "components/dynamic-table/types";
import OptimizedInput from "components/performance/attendance-input";
import { DocumentIcon, DocumentIcon2 } from "components/vectors/document-icon";
import { ListIcon } from "components/vectors/list-icon";
import { useEffect, useRef, useState } from "react";
import { Attendance } from "../types";

interface DigitationTableOption {
  banco: string;
  codigo: string;
  convenio: string;
  descricaoCompleta: string;
  id: number;
  prazo: string;
  tipoOperacao: string;
  needToken: boolean;
  token: string | null;
}

export function TabelaDigitacaoDropdown({
  formControl,
  onChangeField,
}: {
  formControl: FormControl;
  onChangeField: (
    fieldName: keyof Attendance,
    value: string | boolean | null
  ) => void;
}) {
  const [tabelasDigitacao, setTabelasDigitacao] = useState<DropdownOption[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const toast = useToast();
  const codigoTabelaRef = useRef<HTMLInputElement>(null);
  const descricaoTabelaRef = useRef<HTMLInputElement>(null);

  const {
    prazo,
    convenio,
    tipoOperacao,
    bancoDigitacao,
    codigoTabela,
    descricaoTabela,
    digitadoApi,
    needTokenTabela,
    tokenTabela,
  } = formControl.values;

  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);

  useEffect(() => {
    const options = async () => {
      setLoading(true);
      try {
        const { data } = await api.get(
          `/tabelas-digitacao/filter?convenio=${convenio}&tipoOperacao=${tipoOperacao}&banco=${bancoDigitacao}&prazo=${prazo}`
        );
        setTabelasDigitacao(data);
        setLoading(false);
      } catch (e) {
        toast({
          title: "Erro ao carregar tabelas",
          status: "error",
          ...toastDefaultStyle,
        });
        setLoading(false);
      }
    };
    if (isOpen) {
      options();
    }
  }, [prazo, convenio, tipoOperacao, bancoDigitacao, isOpen]);

  const columns: Column[] = [
    {
      name: "Ações",
      render: (row: DigitationTableOption) => {
        return (
          <Button
            leftIcon={<CheckIcon />}
            onClick={() => {
              onChangeField("codigoTabela", row.codigo);
              onChangeField("descricaoTabela", row.descricaoCompleta);
              onChangeField("needTokenTabela", row.needToken);
              onChangeField("tokenTabela", row.token);
              descricaoTabelaRef.current?.classList.remove("invalid-input");
              codigoTabelaRef.current?.classList.remove("invalid-input");
              onClose();
            }}
            size="sm"
          >
            Selecionar
          </Button>
        );
      },
    },
    {
      name: "Descrição Tabela",
      key: "descricaoCompleta",
    },
  ];

  return (
    <>
      <Grid
        gridArea="auto / span 3"
        templateColumns={`1fr 32px 3fr${needTokenTabela ? " 1fr" : ""}`}
        gap="16px"
      >
        <Box>
          <Text mb="4px">Codigo Tabela</Text>
          <OptimizedInput
            ref={codigoTabelaRef}
            fieldName="codigoTabela"
            value={codigoTabela || ""}
            readOnly
          />
        </Box>
        <Box>
          <Text mb="8px">&nbsp;</Text>

          <IconButton
            aria-label=""
            size="sm"
            icon={<ListIcon strokeWidth="2" />}
            onClick={() => {
              if (!prazo) {
                return toast({
                  title: "Selecione um prazo",
                  status: "info",
                  ...toastDefaultStyle,
                });
              }
              if (!convenio) {
                return toast({
                  title: "Selecione um convênio",
                  status: "info",
                  ...toastDefaultStyle,
                });
              }
              if (!tipoOperacao) {
                return toast({
                  title: "Selecione um Tipo Operação",
                  status: "info",
                  ...toastDefaultStyle,
                });
              }
              if (!bancoDigitacao) {
                return toast({
                  title: "Selecione um Banco Digitação",
                  status: "info",
                  ...toastDefaultStyle,
                });
              }

              onOpen();
            }}
            disabled={!!digitadoApi}
          />
        </Box>
        <Box>
          <Text mb="4px">Descrição Tabela</Text>
          <OptimizedInput
            ref={descricaoTabelaRef}
            fieldName="codigoTabela"
            value={descricaoTabela || ""}
            readOnly
          />
        </Box>
        {needTokenTabela ? (
          <Box>
            <Text mb="4px">Token</Text>
            <Input
              onChange={({ target: { value } }) =>
                onChangeField("tokenTabela", value)
              }
              value={tokenTabela || ""}
            />
          </Box>
        ) : null}
      </Grid>
      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        modalTitle="Tabelas Digitação"
        modalFooter={
          <Button
            leftIcon={<CloseIcon w="12px" h="12px" />}
            onClick={onClose}
            variant="outline"
          >
            Fechar
          </Button>
        }
        size="5xl"
      >
        <DynamicTable
          isLoading={loading}
          rows={tabelasDigitacao}
          columns={columns}
          containerStyle={{ boxShadow: "0 0 1em rgba(0,0,0,0.1)" }}
        />
      </CustomModal>
    </>
  );
}
