import { createStandaloneToast } from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { openModalConfirm } from "components/modal-confirm-new";
import { openModalError } from "components/modal-error";
import { Dispatch, SetStateAction } from "react";
import { formatToBrazilianDate } from "utils/util";
import {
  calcularMargemCartao,
  calcularSaqueCartao,
} from "../../atendimento-form/fields-data";
import {
  Attendance,
  ContratoRCCInss,
  ContratoRMCInss,
} from "../../atendimento-form/types";
import { AttendanceActionsTypes, FormControl } from "../actions-reducer";
import { KeyForward } from "./types";

const { toast } = createStandaloneToast();

const parseContract = (contract: any) => {
  const { competenciaInicioDesconto, competenciaFimDesconto } = contract;
  contract.codBanco = contract.banco?.codigo;
  delete contract.banco;
  delete contract.dataInclusao;
  contract.competenciaInicioDesconto = `${competenciaInicioDesconto
    .toString()
    .slice(0, 4)}/${competenciaInicioDesconto.toString().slice(4, 6)}`;
  contract.competenciaFimDesconto = `${competenciaFimDesconto
    .toString()
    .slice(0, 4)}/${competenciaFimDesconto.toString().slice(4, 6)}`;
  contract.codTipoEmprestimo = "98";
  contract.valorEmprestado = contract.valorLiberado;
  delete contract.valorLiberado;

  return contract;
};

const parseContractRMCorRCC = (contract: any, isRMC: boolean) => {
  contract.codBanco = contract.banco.codigo;
  contract.codTipoEmprestimo = isRMC ? "76" : "44";
  contract.limiteCartao = contract.valorLimiteCartao;
  contract.dataInicioContrato = contract.dataInclusao.replace(/-/g, "/");

  return contract;
};

export const copyExtratoDataToAtendimento = (
  attendance: Attendance,
  dispatch: React.Dispatch<AttendanceActionsTypes>
) => {
  const attendanceExtratoCopy = { ...attendance.extratoOnline };
  const htmlContent = JSON.parse(attendanceExtratoCopy.htmlContent!);
  const keyForward: KeyForward<Attendance> = {
    "dados.beneficio": (data) => {
      if (data) {
        data = data.toString();
        while (data.length < 10) data = "0" + data;
      }
      return {
        data,
        field: "nb",
      };
    },
    "dados.nome": "nome",
    "dados.especie.codigo": "especieNB",
    "dados.situacao": (data) => {
      const parseData = data === "ATIVO" ? "ATIVO" : null;
      return { data: parseData, field: "situacaoNB" };
    },
    "dados.bloqueioEmprestismo": "bloqueadoEmprestimo",
    "dados.elegivelEmprestimo": "elegivelEmprestimo",
    "dados.possuiRepresentante": "possuiRepresentante",
    "dados.pensaoAlimenticia": "pensaoAlimenticia",
    "dados.meioPagamento": (data) => {
      if (data === "Cartão Magnético") data = "CARTAO_MAGNETICO";
      else if (data === "Conta Corrente") data = "CONTA_CORRENTE";
      else data = null;
      return {
        data,
        field: "tipoPagamento",
      };
    },
    "dados.banco.codigo": (data) => {
      return { data, field: "codBancoPagamento" };
    },
    "dados.agencia": "agenciaPagamento",
    "dados.conta": "contaPagamento",
    "dados.margemDisponivelEmprestimo": "valorMargemLivreNovo",
    contratosEmprestimo: (data) => {
      const parseData = data?.map((contract: any) => parseContract(contract));
      // else data = null
      return {
        data: parseData,
        field: "contratosEmprestimosInss",
      };
    },
    contratosRMC: (data: any[]) => {
      const parseData = data?.map((c) => parseContractRMCorRCC(c, true));
      return {
        data: parseData,
        field: "contratosRMCInss",
      };
    },
    contratosRCC: (data: any[]) => {
      const parseData = data?.map((c) => parseContractRMCorRCC(c, false));
      return {
        data: parseData,
        field: "contratosRCCInss",
      };
    },
  };
  const data = htmlContent.data;
  if (data) mapObject({ keyForward, data, dispatch });
  else mapObject({ keyForward, data: htmlContent, dispatch });
};

export function checkRequiredFields(
  attendance: Attendance,
  dispatch: Dispatch<AttendanceActionsTypes>
) {
  const optionalFields: (keyof Attendance)[] = ["nomePai"];
  const requiredFields: (keyof Attendance)[] = [
    "convenio",
    "cpf",
    "nome",
    "dataNascimento",
    "sexo",
    "documento",
    "especieNB",
    "dataEmissaoDoc",
    "nomeMae",
    "cep",
    "endereco",
    "numero",
    "bairro",
    "cidade",
    "uf",
    "agenciaPagamento",
    "contaPagamento",
    "dvContaPagamento",
    "valorSalario",
    "estadoCivil",
    "prazo",
    "bancoDigitacao",
    "naturalidade",
    "orgaoEmissorDoc",
    "ufEmissaoDoc",
    "ufNaturalidade",
    "tipoOperacao",
    "codBancoPagamento",
    "nb",
    "ufNB",
    "tipoPagamento",
    "codigoTabela",
  ];

  let isValid = true;
  let values: any = {};
  const {
    bancoDigitacao,
    tipoOperacao,
    valorSalario,
    needTokenTabela,
    possuiRepresentante,
  } = attendance;

  //additional values
  values["atendimentoId"] = attendance.id;
  if (tipoOperacao === "CARTAO_BENEFICIO_INSS") {
    values["valorMargemCartaoBeneficio"] = calcularMargemCartao(valorSalario);
    values["valorSaqueCartaoBeneficio"] = calcularSaqueCartao(valorSalario);
  }
  if (possuiRepresentante) {
    requiredFields.push("cpfRepresentanteLegal");
    requiredFields.push("nomeRepresentanteLegal");
  }
  if (tipoOperacao === "NOVO_INSS") {
    requiredFields.push("valorSolicitadoCliente");
  }
  if (104 === attendance.codBancoPagamento) {
    requiredFields.push("tipoContaPagamento");
  }
  if (bancoDigitacao === "FACTA") {
    requiredFields.push("nomePai");
    requiredFields.push("telefoneCelular");
  }
  if (needTokenTabela) {
    requiredFields.push("tokenTabela");
  }
  optionalFields.forEach((key) => {
    if (attendance[key]) {
      values[key] = attendance[key];
    }
  });

  requiredFields.forEach((key) => {
    if (!attendance[key]) {
      dispatch({
        type: "setInvalidField",
        payload: { action: "append", fieldName: key },
      });
      isValid = false;
    } else {
      values[key] = attendance[key];
    }
  });

  return { isValid, values };
}

async function onDigitation(
  dispatch: Dispatch<AttendanceActionsTypes>,
  setLoading: Dispatch<SetStateAction<boolean>>,
  values: any
) {
  setLoading(true);
  try {
    const { data } = await api.post("/propostas/digitar", values);
    setLoading(false);
    toast({
      title: "Proposta criada com sucesso",
      ...toastDefaultStyle,
    });
    if (data.numeroProposta) {
      dispatch({
        type: "changeFieldWithInitial",
        payload: {
          fieldName: "ade",
          data: data.numeroProposta,
        },
      });
    }
    if (data.numeroProposta) {
      dispatch({
        type: "changeFieldWithInitial",
        payload: {
          fieldName: "digitadoApi",
          data: true,
        },
      });
    }
    if (data.linkFormalizacao) {
      dispatch({
        type: "changeFieldWithInitial",
        payload: {
          fieldName: "linkFormalizacao",
          data: data.linkFormalizacao,
        },
      });
    }
  } catch (e: any) {
    setLoading(false);
    openModalError({
      message: getErrorByMessage(e, "Erro ao digitar"),
    });
  }
}

export async function createDigitacaoFACTA(
  setModalLoading: Dispatch<SetStateAction<boolean>>,
  formControl: FormControl,
  dispatch: Dispatch<AttendanceActionsTypes>
) {
  const { isValid, values } = checkRequiredFields(
    formControl.values as Attendance,
    dispatch
  );
  if (isValid) {
    openModalConfirm({
      message: "Gerar proposta?",
      onConfirm: () => onDigitation(dispatch, setModalLoading, values),
      confirmButtonStyle: { variant: undefined },
    });
  } else {
    setModalLoading(false);
    toast({
      title: "Preencher Campos necessários!",
      status: "error",
      ...toastDefaultStyle,
    });
    return "Fields_Error";
  }
}

export async function createDigitacaoPAN(
  setModalLoading: Dispatch<SetStateAction<boolean>>,
  formControl: FormControl,
  dispatch: Dispatch<AttendanceActionsTypes>
) {
  const { isValid, values } = checkRequiredFields(
    formControl.values as Attendance,
    dispatch
  );
  if (isValid) {
    openModalConfirm({
      message: "Gerar proposta?",
      onConfirm: () => onDigitation(dispatch, setModalLoading, values),
    });
  } else {
    setModalLoading(false);
    toast({
      title: "Preencher Campos necessários!",
      status: "error",
      ...toastDefaultStyle,
    });
  }
}

export async function createDigitacao(
  setModalLoading: Dispatch<SetStateAction<boolean>>,
  formControl: FormControl,
  dispatch: Dispatch<AttendanceActionsTypes>
) {
  const { isValid } = checkRequiredFields(
    formControl.values as Attendance,
    dispatch
  );
  const { id: atendimentoId } = formControl.values;
  if (isValid) {
    openModalConfirm({
      message: "Gerar proposta?",
      onConfirm: () =>
        onDigitation(dispatch, setModalLoading, { atendimentoId }),
    });
  } else {
    setModalLoading(false);
    toast({
      title: "Preencher Campos necessários!",
      status: "error",
      ...toastDefaultStyle,
    });
  }
}
const isObject = (data: any) =>
  typeof data === "object" && !Array.isArray(data);

export function mapObject({
  keyForward,
  data,
  dispatch,
  ignoreNullFields = true,
}: {
  keyForward: KeyForward<Attendance>;
  data: any;
  dispatch: Dispatch<AttendanceActionsTypes>;
  ignoreNullFields?: boolean;
}) {
  const keys = Object.keys(keyForward);
  for (let key of keys) {
    const splitKey = key.split(".");
    let currData: any = null;
    splitKey.forEach((subKey) => {
      if (isObject(data?.[subKey])) {
        currData = data?.[subKey];
        return;
      }
      if (currData) {
        if (isObject(currData?.[subKey])) {
          currData = currData?.[subKey];
          return;
        }
      }
      let fieldData = currData?.[subKey] ?? data?.[subKey];

      if (!ignoreNullFields || fieldData != null) {
        let attendanceField = keyForward[key];
        let dispatchOptionsProps: any;
        if (typeof attendanceField === "function") {
          const { data, field, dispatchOptions } = attendanceField(fieldData);
          fieldData = data;
          attendanceField = field;
          dispatchOptionsProps = dispatchOptions;
        }

        dispatch({
          type: "changeField",
          payload: { fieldName: attendanceField, data: fieldData },
          ...dispatchOptionsProps,
        });
      }
    });
  }
}

export function getErrorByMessage(e: any, defaultMessage: string) {
  const statusError = e.response?.data?.message;
  return statusError?.indexOf("msg_interface") === 0
    ? statusError.replace("msg_interface:", "")
    : responseStatusError[statusError] ?? defaultMessage;
}

export const digitationFunction = {
  PAN: createDigitacaoPAN,
  FACTA: createDigitacaoFACTA,
  BMG: createDigitacao,
  MASTER: createDigitacao,
};

export const responseStatusError: any = {
  digitador_not_found: "Não há digitador cadastrado!",
  sem_tabela_configurada:
    "Não há tabela configurada para essa operação e prazo!",
  under_development: <>Serviço em desenvolvimento! &#128512;</>,
  digitacao_pan_especie_nao_consignavel:
    "Erro na digitação PAN: espécie não consignável!",
  digitacao_pan_data_invalida: "Erro na digitação PAN: Data Inválida!",
  digitacao_pan_especie_invalida: "Erro na digitação PAN: Espécie Inválida!",
  digitacao_pan_cartao_magnetico_unavailable:
    "Meio de pagamento CARTÃO MAGNETICO inválido pra digitação no PAN. Válido apenas para CONTA-CORRENTE!",
  attendance_already_has_proposal: "Atendimento já possui proposta!",
  servico_indisponivel_tente_novamente:
    "Não foi possível digitar. Tente novamente!",
  valor_parcela_maior_limite_disponivel:
    "Valor da parcela não pode ser maior que o limite disponível!",
  problema_api_pan_tente_novamente_mais_tarde:
    "Problemas na API PAN - Tente novamente mais tarde!",
};
