import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";
import { ProgressSpinner } from "primereact/progressspinner";
import api from "../api/api";
import { Dialog } from "primereact/dialog";
import { BlockUI } from "primereact/blockui";
import { GetLayout } from "components/get-layout";
import { useToast } from "@chakra-ui/react";
import { toastDefaultStyle } from "chakra/theme";

export const BmgCartaoSaqueLista = () => {
  const fileUploadRef = useRef(null);
  const [bmgCartaoSaqueDialog, setBmgCartaoSaqueDialog] = useState({
    nome: "",
    file: "",
    filename: "",
  });
  // const [globalFilter1, setGlobalFilter1] = useState('');
  const [loading, setLoading] = useState(false);
  const [openBmgCartaoSaqueDialog, setOpenBmgCartaoSaqueDialog] =
    useState(false);
  const [bmgCartaoSaques, setBmgCartaoSaques] = useState([]);
  const [errors, setErrors] = useState({ nome: false });
  const toast = useToast();

  let saveOrUpdateBmgCartaoSaque = async () => {
    let hasNomeError =
      !bmgCartaoSaqueDialog.nome ||
      bmgCartaoSaqueDialog.nome.trim().length === 0;
    let hasFileError = !bmgCartaoSaqueDialog.file;
    setErrors({ nome: hasNomeError, file: hasFileError });
    if (hasNomeError || hasFileError) return;
    setLoading(true);
    let formData = new FormData();
    formData.append("files", bmgCartaoSaqueDialog.file);
    formData.append("filename", bmgCartaoSaqueDialog.filename);
    formData.append("nome", bmgCartaoSaqueDialog.nome);
    try {
      await api.post("/bmg-cartao-saques", formData);
    } catch (error) {
      let hasMessage =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message;
      if (
        hasMessage &&
        "column_cpf_not_found" === error.response.data.message
      ) {
        toast({
          title: "Erro ao enviar: Arquivo não possui coluna CPF!",
          status: "error",
          ...toastDefaultStyle,
        });
      } else if (
        hasMessage &&
        "duplicate_column_cpf_found" === error.response.data.message
      ) {
        toast({
          title: "Erro ao enviar: Arquivo não possui coluna CPF!",
          status: "error",
          ...toastDefaultStyle,
        });
      } else {
        toast({
          title: "Erro ao enviar arquivo!",
          status: "error",
          ...toastDefaultStyle,
        });
      }
    }
    await loadBmgCartaoSaques();
    setOpenBmgCartaoSaqueDialog(false);
    setLoading(false);
  };

  const download = async (rowData) => {
    setLoading(true);
    let response = await api.get("/bmg-cartao-saques/download/" + rowData.id);
    let filename = rowData.nome + ".csv";
    createLinkAndDownload(response, filename);
    setLoading(false);
  };

  const createLinkAndDownload = (response, filename) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await loadBmgCartaoSaques();
      setLoading(false);
    };
    load();
  }, []);

  const play = async (rowData) => {
    setLoading(true);
    await api.get("/bmg-cartao-saques/" + rowData.id + "/play");
    await loadBmgCartaoSaques();
    setLoading(false);
  };

  const pause = async (rowData) => {
    setLoading(true);
    await api.get("/bmg-cartao-saques/" + rowData.id + "/pause");
    await loadBmgCartaoSaques();
    setLoading(false);
  };

  const loadBmgCartaoSaques = async () => {
    let response = await api.get("/bmg-cartao-saques");
    setBmgCartaoSaques(response.data);
  };

  const atualizar = async () => {
    setLoading(true);
    await loadBmgCartaoSaques();
    setLoading(false);
  };

  const onChangeBmgCartaoSaqueDialog = (field, e) => {
    setErrors({ ...errors, [field]: false });
    setBmgCartaoSaqueDialog({
      ...bmgCartaoSaqueDialog,
      [field]: e.target.value,
    });
  };

  const onChangeFile = (e) => {
    setErrors({ ...errors, file: false });
    setBmgCartaoSaqueDialog({
      ...bmgCartaoSaqueDialog,
      file: e.target.files[0],
      filename: e.target.files[0].name,
    });
  };

  const openNew = () => {
    setErrors({});
    setBmgCartaoSaqueDialog({
      nome: "",
      banco: "",
      atualizacaoMinima: "",
      file: "",
      filename: "",
    });
    setOpenBmgCartaoSaqueDialog(true);
  };

  const bmgCartaoSaqueTableTableHeader = (
    <div className="table-header">
      BMG Cartão Saque
      {/* <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilter1} onChange={(e) => setGlobalFilter1(e.target.value)} placeholder="Global Search" />
            </span> */}
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <Button
          label="Atualizar"
          icon="pi pi-plus"
          className="p-button-primary mr-2"
          onClick={atualizar}
          style={{ width: "150px" }}
        />
        <Button
          label="Nova"
          icon="pi pi-plus"
          className="p-button-primary mr-2"
          onClick={openNew}
          style={{ width: "150px" }}
        />
      </span>
    </div>
  );

  const bodyTemplate = (data, props) => (
    <>
      <span className="p-column-title">{props.header}</span>
      {data[props.field]}
    </>
  );
  const dateBodyTemplate = (data, props) => (
    <>
      <span className="p-column-title">{props.header}</span>
      {parseDate(data[props.field])}
    </>
  );

  const parseDate = (value) => {
    return (
      value.substring(8, 10) +
      "/" +
      value.substring(5, 7) +
      "/" +
      value.substring(0, 4)
    );
  };

  const statusBodyTemplate = (hig) => {
    let value = Math.floor((hig.totalCompleted / hig.total) * 10000) / 100;
    let statusBadge = value === 100 ? "qualified" : "proposal";
    let status = value === 100 ? "COMPLETO" : "EM ANDAMENTO";
    return (
      <>
        <span className="p-column-title">Status</span>
        {/* <span className={`customer-badge status-${data.status}`}>{data.status}</span> */}
        <span className={"customer-badge status-" + statusBadge}>{status}</span>
      </>
    );
  };

  const activityBody = (hig) => {
    let value = Math.floor((hig.totalCompleted / hig.total) * 10000) / 100;
    return (
      <>
        {/* <span className="p-column-title">Activity</span> */}
        <span>{hig.totalCompleted + "/" + hig.total}</span>
        <ProgressBar value={value} showValue={false} />
      </>
    );
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="actions">
        {rowData.paused === true &&
          rowData.totalCompleted !== rowData.total && (
            <Button
              icon="pi pi-play"
              className="p-button-rounded p-button-primary mr-1"
              onClick={() => play(rowData)}
            />
          )}
        {rowData.paused === false &&
          rowData.totalCompleted !== rowData.total && (
            <Button
              icon="pi pi-pause"
              className="p-button-rounded p-button-primary mr-1"
              onClick={() => pause(rowData)}
            />
          )}
        {/* {rowData.totalCompleted === rowData.total && <Button icon="pi pi-download" className="p-button-rounded p-button-primary mr-1" onClick={() => download(rowData)}/>} */}
        <Button
          icon="pi pi-download"
          className="p-button-rounded p-button-primary mr-1"
          onClick={() => download(rowData)}
        />
        {/* <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => confirmDeleteBmgCartaoSaque(rowData)}/> */}
      </div>
    );
  };

  const bmgCartaoSaqueDialogFooter = (
    <>
      <Button
        label="Salvar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => saveOrUpdateBmgCartaoSaque()}
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => hideBmgCartaoSaqueDialog()}
      />
    </>
  );

  const hideBmgCartaoSaqueDialog = () => {
    setOpenBmgCartaoSaqueDialog(false);
  };

  const getLoadingTemplate = () => {
    return (
      <ProgressSpinner
        style={{ width: "150px", height: "150px" }}
        strokeWidth="3"
        animationDuration=".5s"
      />
    );
  };

  return (
    <GetLayout>
      <div className="grid table-demo">
        <div className="col-12">
          <div className="card">
            <BlockUI
              blocked={loading}
              fullScreen
              template={getLoadingTemplate}
            ></BlockUI>
            <DataTable
              value={bmgCartaoSaques}
              paginator
              className="p-datatable-customers"
              rows={10}
              dataKey="id"
              rowHover
              // globalFilter={globalFilter1}
              emptyMessage="Não há registros"
              header={bmgCartaoSaqueTableTableHeader}
              size="small"
            >
              <Column
                field="nome"
                header="Nome"
                sortable
                body={bodyTemplate}
              ></Column>
              <Column
                field="criacao"
                header="Criação"
                sortable
                body={dateBodyTemplate}
              ></Column>
              <Column header="Atividade" sortable body={activityBody}></Column>
              <Column
                field="status"
                header="Status"
                sortable
                body={statusBodyTemplate}
              ></Column>
              <Column header="Ações" body={actionTemplate}></Column>
            </DataTable>
            <Dialog
              visible={openBmgCartaoSaqueDialog}
              style={{ width: "450px" }}
              header="BMG Cartão Saque"
              modal
              className="p-fluid"
              footer={bmgCartaoSaqueDialogFooter}
              onHide={hideBmgCartaoSaqueDialog}
            >
              <div className="field">
                <label htmlFor="nomeDialog">Nome</label>
                <InputText
                  id="nomeDialog"
                  value={bmgCartaoSaqueDialog.nome}
                  className={errors.nome && "p-invalid"}
                  onChange={(e) => onChangeBmgCartaoSaqueDialog("nome", e)}
                  autoComplete="new-password"
                />
                {errors.nome && (
                  <small className="p-invalid">Informe um nome</small>
                )}
              </div>
              <input
                ref={fileUploadRef}
                hidden
                type="file"
                onChange={(e) => onChangeFile(e)}
              />
              <div className="grid">
                <div className="field col-6">
                  <Button
                    label="Selecionar arquivo"
                    icon="pi pi-plus"
                    onClick={() => {
                      fileUploadRef.current.click();
                    }}
                  ></Button>
                  {errors.file && (
                    <small className="p-invalid">Selecione um arquivo</small>
                  )}
                </div>
              </div>
              <div className="field">
                <label>{bmgCartaoSaqueDialog.filename}</label>
              </div>
            </Dialog>
          </div>
        </div>
      </div>
    </GetLayout>
  );
};
