import { Box, Button, Grid, Input, Text } from "@chakra-ui/react";
import { Product } from "components/atendimentos-components/atendimento-form/types";
import { CustomModal } from "components/custom-modal";
import { useAttendanceContext } from "contexts/AttendanceContext";
import { useState } from "react";
import { currencyMask } from "utils/string-formats";

export function ProductModal({
  isOpen,
  onClose,
  initialValues,
}: {
  isOpen: boolean;
  onClose: () => void;
  initialValues?: null | { updatedItem: Product; index: number };
}) {
  const { dispatch } = useAttendanceContext();
  const initialValue1 = initialValues?.updatedItem.valorLiberadoSelecionado;
  const initialValue2 = initialValues?.updatedItem.valorParcelaSelecionado;

  const [valorLiberadoSelecionado, setValorLiberadoSelecionado] = useState(
    initialValues ? `${initialValue1}`.replace(".", ",") : "0,00"
  );
  const [valorParcelaSelecionado, setValorParcelaSelecionado] = useState(
    initialValues ? `${initialValue2}`.replace(".", ",") : "0,00"
  );

  const productDispatch: Product = {
    ...initialValues?.updatedItem,
    valorLiberadoSelecionado: parseFloat(
      valorLiberadoSelecionado.replace(".", "").replace(",", ".")
    ),
    valorParcelaSelecionado: parseFloat(
      valorParcelaSelecionado.replace(".", "").replace(",", ".")
    ),
    tipoOperacao: "fgts-saque-aniversario",
  };

  function action() {
    if (initialValues) {
      const { index } = initialValues;
      dispatch({
        type: "updateProduct",
        payload: {
          updatedItem: productDispatch,
          index,
        },
      });
    } else {
      dispatch({ type: "addProduct", payload: productDispatch });
    }
    onClose();
  }

  const modalFooter = (
    <>
      <Button onClick={action}>
        {initialValues ? "Atualizar" : "Adicionar"}
      </Button>
      <Button onClick={onClose} variant="outline">
        Cancelar
      </Button>
    </>
  );

  return (
    <CustomModal
      modalTitle={initialValues ? "Editar Produto" : "Adicionar Novo Produto"}
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      modalFooter={modalFooter}
    >
      <Grid templateColumns="1fr 1fr" gap="20px">
        <Box>
          <Text mb="10px">Valor do Saque</Text>
          <Input
            onChange={(e) =>
              setValorLiberadoSelecionado(currencyMask(e.target.value))
            }
            onKeyDown={(e) => (e.key === "Enter" ? action() : undefined)}
            value={valorLiberadoSelecionado}
          />
        </Box>
        <Box>
          <Text mb="10px">Valor do Saque Antecipado</Text>
          <Input
            onChange={(e) =>
              setValorParcelaSelecionado(currencyMask(e.target.value))
            }
            value={valorParcelaSelecionado}
            onKeyDown={(e) => (e.key === "Enter" ? action() : undefined)}
          />
        </Box>
      </Grid>
    </CustomModal>
  );
}
