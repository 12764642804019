let functionsStore: any = {};
let variablesStore: any = {};

export function exportFunction(key: string, func: any) {
  functionsStore[key] = func;
}

export function getExportedFunction(key: string): (...any: any) => any {
  if (functionsStore[key]) {
    return functionsStore[key];
  } else throw Error("Function Not Found");
}

export function exportVariable(key: string, variable: any): any {
  variablesStore[key] = variable;
}

export function getExportedVariable(
  key: string,
  { throwsError = true } = {}
): any {
  if (variablesStore[key]) {
    return variablesStore[key];
  } else if (throwsError) throw Error("Variable Not Found");
}
