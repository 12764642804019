import { createStandaloneToast } from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import {
  AttendanceActionsTypes,
  FormControl,
} from "components/atendimentos-components/modal-atendimento/actions-reducer";
import { consultaFGTS } from "components/atendimentos-components/modal-atendimento/functions/consulta";
import { DropdownOption } from "components/dropdown";
import { User } from "contexts/types/application-context-types";
import { Dispatch, SetStateAction } from "react";
import { fieldValidation, validateCPF } from "utils/field-validation";
import { isColomboUser } from "utils/is-colombo-user";
import { objectSchema } from "utils/object-methods";

export const operacaoOptions = [
  { name: "Refin.", value: "REFIN" },
  { name: "Port. com Refin.", value: "PORT_REFIN" },
  { name: "Port. Pura", value: "PORT_PURA" },
];

export const novoPrazoOptions: DropdownOption[] = [
  {
    name: "96",
    value: 96,
  },
  {
    name: "84",
    value: 84,
  },
  {
    name: "72",
    value: 72,
  },
  {
    name: "60",
    value: 60,
  },
];

export const valorParcelaReduzida = (
  taxa: number | string | undefined,
  saldoQuitacao: number | string,
  formValues: Attendance,
  quantidadeParcelasEmAberto: number | string
) => {
  if (taxa != null && saldoQuitacao != null)
    try {
      let result: undefined | number;
      const parsedTaxa = Number(taxa);
      const parsedSaldoQuitacao = Number(saldoQuitacao);
      if (
        ["REFIN", "PORT_REFIN"].includes(formValues.simulacaoOperacao) &&
        formValues.simulacaoNovoPrazo
      ) {
        result =
          (parsedTaxa /
            100 /
            (1 -
              Math.pow(1 + parsedTaxa / 100, -formValues.simulacaoNovoPrazo))) *
          parsedSaldoQuitacao;
        return Number(result.toFixed(2));
      } else if (quantidadeParcelasEmAberto) {
        result =
          (parsedTaxa /
            100 /
            (1 -
              Math.pow(
                1 + parsedTaxa / 100,
                -Number(quantidadeParcelasEmAberto)
              ))) *
          parsedSaldoQuitacao;
        return Number(result.toFixed(2));
      }
    } catch (e) {}
};

const { toast } = createStandaloneToast();

export const consultarFGTS = ({
  dispatch,
  formControl,
  user,
  loading,
  codTable,
}: {
  formControl: FormControl;
  dispatch: Dispatch<AttendanceActionsTypes>;
  user: User;
  loading: Dispatch<SetStateAction<boolean>>;
  codTable: string;
}) => {
  const convenio = formControl.values.convenio;
  const cpf = formControl.values.cpf;
  const attendanceId = formControl.values.id;
  if (!fieldValidation({ value: cpf }).cpf().isValid) {
    dispatch({
      type: "setInvalidField",
      payload: { fieldName: "cpf", action: "append" },
    });
    return toast({ title: "Insira um CPF Válido", ...toastDefaultStyle });
  }
  if (formControl.pendingChanges) {
    return toast({
      title: "Salve as alterações para fazer a consulta",
      ...toastDefaultStyle,
    });
  }
  if (convenio === "ANTECIPACAO_FGTS" && !isColomboUser(user)) {
    return toast({
      title: "Serviço em desenvolvimento",
      ...toastDefaultStyle,
    });
  }

  consultaFGTS({
    dispatch,
    formValues: formControl.values as Attendance,
    loading,
    consultar: () =>
      api.get(`/facta/fgts/solicitar-saldo/${attendanceId}`, {
        params: { codTable },
      }),
    verificar: () =>
      api.get(`/facta/fgts/consultar-solicitacao-saldo/${attendanceId}`),
    isEndLoop: (response) => response.data?.statusSaldo !== "PENDING",
  });
};

export const reCalcularFGTS = ({
  dispatch,
  formControl,
  user,
  loading,
  codTable,
}: {
  formControl: FormControl;
  dispatch: Dispatch<AttendanceActionsTypes>;
  user: User;
  loading: Dispatch<SetStateAction<boolean>>;
  codTable: string;
}) => {
  const convenio = formControl.values.convenio;
  const cpf = formControl.values.cpf;
  const attendanceId = formControl.values.id;
  const simulacaoAntecipacaoFactaFGTS =
    formControl.values.simulacaoAntecipacaoFactaFGTS ?? [];
  if (!fieldValidation({ value: cpf }).cpf().isValid) {
    dispatch({
      type: "setInvalidField",
      payload: { fieldName: "cpf", action: "append" },
    });
    return toast({ title: "Insira um CPF Válido", ...toastDefaultStyle });
  }
  if (formControl.pendingChanges) {
    return toast({
      title: "Salve as alterações para fazer a consulta",
      ...toastDefaultStyle,
    });
  }
  if (convenio === "ANTECIPACAO_FGTS" && !isColomboUser(user)) {
    return toast({
      title: "Serviço em desenvolvimento",
      ...toastDefaultStyle,
    });
  }

  consultaFGTS({
    dispatch,
    formValues: formControl.values as Attendance,
    loading,
    consultar: () =>
      api.post(`/facta/fgts/solicitar-recalculo/${attendanceId}`, {
        cpf,
        simulacaoAntecipacaoFactaFGTS,
        codTable: codTable,
      }),
    verificar: () =>
      api.get(`/facta/fgts/consultar-solicitacao-recalculo/${attendanceId}`),
    errorMessage: "Erro ao recalcular",
    isEndLoop: (response) => response.data?.statusRecalculo !== "PENDING",
  });
};
