import { Box, Button, Flex, Grid, Text } from "@chakra-ui/react";
import DynamicTable from "components/dynamic-table";
import { Column } from "components/dynamic-table/types";
import { GetLayout } from "components/get-layout";
import { SkeletonLoading } from "components/skeleton-loading";
import { DolarIcon2 } from "components/vectors/dolar-icon";
import {
  ModalInsertCoin,
  openModalInsertCoin,
} from "components/wallet-components/modal-insert-coin";
import { WalletTable } from "components/wallet-components/wallet-table";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useState } from "react";

export interface SelectedCustomer {
  id: number;
  name: string;
  codigoNova: string;
}

export function WalletPage() {
  const [selectedCustomer, setSelectedCustomer] = useState<SelectedCustomer>({
    id: -1,
    name: "",
    codigoNova: "",
  });
  const { codigoNova, id, name } = selectedCustomer;
  const { user } = useApplicationContext();
  const [saldo, setSaldo] = useState("0.00");

  const columns: Column[] = [
    {
      name: "Produto",
      render: (row) => {
        return row.product;
      },
      cellStyle: () => ({ fontSize: 14 }),
    },
    {
      name: "Preço (Pré-pago)",
      render: (row) => {
        return row.price;
      },
      cellStyle: () => ({ fontSize: 14 }),
    },
  ];

  return (
    <GetLayout>
      <Grid templateColumns="560px auto" w="100%" gap="16px">
        <Box>
          <Text fontSize="25" fontWeight="bold" mb="20px">
            Carteira
          </Text>
          <Flex
            mb="25px"
            // w="400px"
            bg="linear-gradient(110deg, #514BC2, #584FE6, #655EE9)"
            borderRadius="14"
            alignItems="center"
            p="30px 30px"
          >
            <Box fontWeight="bold" fontSize="25px" color="white" w="100%">
              <SkeletonLoading borderRadius="6" isLoading={id === -1} w="80%">
                <Text>
                  {`${codigoNova ? `${codigoNova}  - ` : ""}${name}`}
                  &nbsp;
                </Text>
              </SkeletonLoading>
              <Text mt="10px" fontSize="18" display="flex" alignItems="center">
                Saldo:
              </Text>
              <Flex alignItems="center">
                <SkeletonLoading borderRadius="6" isLoading={id === -1}>
                  <Text>R$ {saldo}</Text>
                </SkeletonLoading>
              </Flex>

              {["SUPER", "FINANCEIRO"].includes(user.userData.type!) ? (
                <Button
                  mt="20px"
                  leftIcon={<DolarIcon2 variant="white" />}
                  bg="#00D008"
                  _hover={{ bg: "#00C308" }}
                  _active={{ bg: "#02B909" }}
                  onClick={openModalInsertCoin}
                  disabled={id === -1}
                >
                  Adicionar
                </Button>
              ) : null}
            </Box>
          </Flex>
          {/* <Text fontSize="25" fontWeight="bold" mb="20px">
            Tabela de Preços
          </Text> */}
          <DynamicTable columns={columns} rows={tabelaCobrancas} />
        </Box>
        <Box>
          <Text fontSize="25" fontWeight="bold" mb="20px">
            Transações
          </Text>
          <WalletTable
            selectedCustomer={selectedCustomer}
            setSaldo={setSaldo}
            setSelectedCustomer={setSelectedCustomer}
          />
        </Box>
      </Grid>{" "}
      <ModalInsertCoin selectedCustomer={selectedCustomer} />
    </GetLayout>
  );
}

const tabelaCobrancas = [
  // { product: "Higienização Refin", price: "R$ 50,00/mês por banco" },
  // { product: "Sessão de WhatsApp", price: "R$ 20,00/mês por sessão" },
  // { product: "Usuário Powerhub",  price: "R$ 30,00/mês por usuário" },
  // { product: "Consulta Saque Complementar", price: "R$ 30,00/mês" },
  // { product: "IN100", price: "R$ 0,20 por consulta" },
  { product: "Higienização Telefones", price: "R$ 0,05 por consulta" },
  { product: "Envio SMS", price: "R$ 0,08 por envio" },
  { product: "Disparo de Ura WhatsApp", price: "R$ 0,08 por ligação" },
  { product: "Extrato", price: "R$ 5,00 por extrato" },
];
