import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { Dropdown, DropdownProps } from "components/dropdown";
import { useAttendanceContext } from "contexts/AttendanceContext";

function AttendanceDropdown({
  onChangeField,
  fieldName,
  ...rest
}: Omit<DropdownProps, "onChange"> & {
  onChangeField: (field: keyof Attendance, value: string) => void;
  fieldName: keyof Attendance;
}) {
  const { formControl } = useAttendanceContext();

  return (
    <Dropdown
      name={fieldName}
      onChange={(value) => {
        onChangeField(fieldName, value);
      }}
      disabled={!onChangeField}
      isInvalid={formControl.invalidFields?.includes(fieldName)}
      {...rest}
    />
  );
}

export default AttendanceDropdown;
