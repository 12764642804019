import { Icon } from "@chakra-ui/react";

export function MaisAgilLogo({ ...rest }) {
  return (
    <Icon
      width="100%"
      height="auto"
      viewBox="0 0 110 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M68.7562 77.9636C63.6295 77.5469 58.7002 75.096 55.1801 71.2304C54.7525 70.7589 54.5277 70.4738 54.5715 70.4518C54.9827 70.2325 61.4253 67.5568 61.4747 67.5842C61.5131 67.6061 61.7379 67.7871 61.9791 67.9845C63.0319 68.8288 64.441 69.6403 65.6692 70.0954C66.4149 70.3751 67.6486 70.6876 68.482 70.8137C69.2935 70.9343 70.8891 70.9782 71.717 70.9014C76.9259 70.408 81.411 67.1181 83.4233 62.315C83.8455 61.3116 84.0868 60.1821 84.2458 58.4769C84.3225 57.6434 84.4377 55.3131 84.4377 54.5674V54.2165L84.0429 54.5729C82.7873 55.686 81.3123 56.6729 79.64 57.5064C77.5016 58.5755 75.4784 59.1896 72.9781 59.5296C71.9363 59.6667 68.8987 59.6667 67.8515 59.5296C64.6494 59.0964 61.7982 58.0382 59.1334 56.2836C54.0122 52.9116 50.7937 47.7191 49.9712 41.4959C49.8561 40.6076 49.8122 38.4199 49.889 37.422C50.4208 30.7052 54.1603 24.6903 59.9339 21.2689C67.7199 16.6522 77.5729 17.6446 84.3116 23.7253L84.816 24.1804H87.9688H91.127V38.7543C91.127 54.88 91.0941 56.8484 90.8199 59.7105C90.5403 62.6275 90.0797 64.3218 88.9502 66.5918C87.2285 70.0625 84.4212 73.1001 81.1478 75.0466C78.5105 76.6093 75.8457 77.525 72.841 77.8923C71.9857 77.9965 69.6444 78.0349 68.7562 77.9636ZM72.4188 53.1035C74.9904 52.7306 77.3426 51.7217 79.3384 50.1317C79.9306 49.6656 81.0821 48.5196 81.5317 47.9494C82.7489 46.4141 83.681 44.5225 84.1635 42.6034C84.4706 41.3807 84.4925 41.1559 84.4925 38.9627V36.934L84.328 36.128C83.7413 33.2713 82.4254 30.8533 80.3747 28.8684C78.0061 26.5765 75.2974 25.3154 71.8815 24.9151C70.9439 24.8109 69.4086 24.8329 68.482 24.9754C65.3622 25.4415 62.5603 26.8616 60.3068 29.1097C57.2801 32.1418 55.7942 36.6105 56.3919 40.9037C56.8305 44.0564 58.1684 46.7705 60.3835 49.0131C62.7632 51.4202 65.7186 52.8238 69.1784 53.1857C69.8363 53.2515 71.728 53.2076 72.4188 53.1035Z"
        fill="#31D1C6"
      />
      <path
        d="M41.5062 56.9634V54.2219L41.3582 54.3316C41.2705 54.3974 40.9579 54.6551 40.6564 54.9128C38.1451 57.0512 34.7511 58.6632 31.4449 59.2937C29.9315 59.5788 29.3723 59.6282 27.4696 59.6282C25.5944 59.6282 25.0845 59.5898 23.7412 59.3485C18.132 58.3342 13.0657 54.8799 9.96227 49.9616C9.46331 49.172 8.63537 47.5161 8.27349 46.595C8.109 46.1673 7.94999 45.8383 7.91161 45.8383C7.87323 45.8383 7.84033 45.7999 7.84033 45.7561C7.84033 45.6903 7.92806 45.6738 8.30091 45.6738C8.71762 45.6738 8.77245 45.6629 8.84921 45.5532C8.92598 45.449 8.93694 45.1365 8.93694 43.2777V41.1284L11.2563 41.1119C12.5283 41.101 13.5811 41.101 13.5975 41.1119C13.6085 41.1229 13.5866 41.1503 13.5482 41.1722C13.4495 41.2326 13.5043 41.5944 13.7401 42.5211C14.3597 44.9665 15.5221 47.0556 17.2712 48.8704C19.5192 51.2117 22.2498 52.6044 25.589 53.1143C26.4114 53.2404 28.5827 53.2404 29.4052 53.1143C32.5195 52.6373 35.1569 51.3652 37.2953 49.3036C39.4282 47.242 40.7715 44.813 41.3637 41.9399C41.5062 41.26 41.5062 41.2051 41.5062 38.9955C41.5062 36.7968 41.5008 36.72 41.3692 36.0895C40.788 33.3315 39.5159 30.9738 37.553 29.0108C34.7511 26.2145 30.9843 24.7231 27.031 24.8547C23.3354 24.9808 20.0072 26.4393 17.3699 29.0986C16.9806 29.4824 16.531 29.9759 16.361 30.1842C15.0615 31.8127 14.0362 33.9949 13.6195 36.0072C13.4714 36.7255 13.4714 36.7365 13.5756 36.8516L13.6798 36.9722L13.3782 36.9393C13.2137 36.9174 12.1445 36.9009 11.0095 36.9009H8.93694V34.7187C8.93694 32.1471 8.99177 32.35 8.27898 32.3062C7.96644 32.2897 7.84033 32.2623 7.84033 32.2075C7.84033 32.1691 7.87871 32.1307 7.92806 32.1197C7.98289 32.1088 8.11448 31.8511 8.27349 31.4453C10.7244 25.2714 16.0978 20.5669 22.4965 18.9988C26.2908 18.0667 30.1399 18.1928 33.7751 19.3662C36.5934 20.2763 39.0444 21.6855 41.2924 23.6978L41.7146 24.0706H44.9277H48.1407V41.8905V59.7104H44.8235H41.5062V56.9634Z"
        fill="#31D1C6"
      />
      <path
        d="M93.3213 41.8355V24.0156H96.8579H100.394V41.8355V59.6554H96.8579H93.3213V41.8355Z"
        fill="#31D1C6"
      />
      <path
        d="M102.587 29.8278V0H106.097H109.606V29.8278V59.6556H106.097H102.587V29.8278Z"
        fill="#31D1C6"
      />
      <path
        d="M93.3213 19.5747V18.0942H96.8579H100.394V19.5747V21.0551H96.8579H93.3213V19.5747Z"
        fill="#31D1C6"
      />
      <path
        d="M35.8209 17.9681C34.9436 17.6007 34.2198 17.2827 34.2144 17.2553C34.2144 17.2333 34.7901 16.0709 35.4974 14.6782C36.2047 13.2856 36.8901 11.9312 37.0162 11.6681C37.2245 11.2459 37.2739 11.1855 37.3945 11.1855C37.548 11.1855 44.0126 11.7229 44.1277 11.7503C44.188 11.7613 37.559 18.6041 37.4603 18.626C37.4384 18.6315 36.7037 18.3354 35.8209 17.9681Z"
        fill="#31D1C6"
      />
      <path
        d="M4.68252 45.723C4.58383 45.6133 4.57835 45.4817 4.5619 43.4475L4.54545 41.2927L2.39061 41.2762C0.356398 41.2598 0.224805 41.2543 0.115144 41.1556C0 41.0514 0 41.013 0 39.0008C0 37.0269 0.00548305 36.9501 0.109661 36.8459C0.213839 36.7418 0.290602 36.7363 2.38513 36.7363H4.55093V34.6253C4.55093 33.1778 4.57286 32.4759 4.61124 32.3827C4.72639 32.136 4.84153 32.125 6.91961 32.1415C8.75094 32.1579 8.87705 32.1634 8.98672 32.2621C9.10186 32.3663 9.10186 32.3992 9.10186 34.554V36.7363H11.2677H13.439L13.5705 36.8733L13.7076 37.0049V38.9405C13.7076 40.0042 13.6912 40.9308 13.6747 41.0021C13.598 41.2762 13.4938 41.2872 11.2128 41.2872H9.10186V43.442C9.10186 45.3008 9.09089 45.6133 9.01413 45.7175L8.93189 45.8381H6.85929C4.82508 45.8381 4.7867 45.8381 4.68252 45.723Z"
        fill="#186A64"
      />
    </Icon>
  );
}
