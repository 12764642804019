import { UA, WebSocketInterface } from "jssip";
import React, { useEffect } from "react";
import { get3CplusAuthenticatedUser } from "./api3cplus";
import { useApplicationContext } from "contexts/ApplicationContext";
import { exportVariable } from "./get-external-function";
import { use3cPlusContext } from "contexts/Context3cPlus";

interface SipServiceType {
  onRamalChanged: (mutted: boolean) => void;
  onLigacaoChanged: (emLigacao: boolean) => void;
  onAuthenticatedUserError?: (error: Error) => void;
  onConnected?: () => void;
  onDisconnected?: () => void;
  onMicrophonePermissionDenied?: () => void;
}

let conexao_jssip: UA | null = null;

export default function SipService({
  onRamalChanged,
  onLigacaoChanged,
  onAuthenticatedUserError,
  onConnected,
  onDisconnected,
  onMicrophonePermissionDenied,
}: SipServiceType) {
  const ref = React.useRef<HTMLAudioElement>(null);
  const { user } = useApplicationContext();
  const { dispatch3cPlus } = use3cPlusContext();

  useEffect(() => {
    console.log("Iniciando conexão ramal Sip..");
    jssipMain();
    return () => {
      console.log("Desconectando ramal Sip..");
      conexao_jssip?.unregister();
      conexao_jssip?.removeAllListeners();
      conexao_jssip?.terminateSessions();
      conexao_jssip?.stop();
      // TODO: deslogar o agente
    };
  }, []);

  const jssipMain = () => {
    get3CplusAuthenticatedUser()
      .then((response) => {
        let dominio_3cplus = "3c.fluxoti.com"; //"3c.fluxoti.com"
        let dominio_da_empresa = response.company.domain; //"novapromotora";
        let host = dominio_da_empresa + "." + dominio_3cplus + ":4443";
        let usuario_do_ramal = response.telephony_id; //"YoQ5glQ7NZ";
        let senha_do_ramal = response.extension_password; //"2dDgfU8S2Z";
        let tentativas_de_conexao = 0;
        let em_ligacao = false;
        let session = null;
        let ramal_mutado = false;
        let registro_do_ramal = false;

        let socket = new WebSocketInterface("wss://" + host);
        let configuration = {
          sockets: [socket],
          uri: "sip:" + usuario_do_ramal + "@" + host,
          password: senha_do_ramal,
          register: true,
          register_expires: 30,
          session_timers: false,
        };

        conexao_jssip = new UA(configuration);
        // inicia o registro do ramal
        conexao_jssip.start();

        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(() => console.log("Permission is granted!"))
          .catch(() => {
            alert("sem permissao para usar o microfone");
            if (onMicrophonePermissionDenied) {
              onMicrophonePermissionDenied();
            }
          });
        // evento de ramal conectado
        conexao_jssip.on("connected", () => {
          console.log("connected JSSIP!");
          if (onConnected) onConnected();
        });

        // evento de ramal desconetado, aqui fazemos um tratamento para tentar reconectar 10x
        conexao_jssip.on("disconnected", (e) => {
          console.log("disconnected, trying reconnect...", e);
          if (onDisconnected) {
            onDisconnected();
            dispatch3cPlus({ type: "campaignLogout" });
          }
          //$("#status").text('Desconectado, tentando conectar...');
          tentativas_de_conexao++;
          let attempts = em_ligacao ? 10 : 5;
          if (tentativas_de_conexao > attempts) {
            alert("Desconectado, porfavor recarregue a pagina");
            conexao_jssip?.stop();
          }
        });

        // evento recebido quando o ramal do 3cplus é atendido para funcionamento
        conexao_jssip.on("newRTCSession", (data: any) => {
          session = data.session;
          exportVariable("session-3cplus", session);

          session.on("accepted", () => {
            console.log("accepted");
            em_ligacao = true;
            onLigacaoChanged(true);
          });

          session.on("peerconnection", (data: any) => {
            data.peerconnection.addEventListener("addstream", (data: any) => {
              console.log("RECEBIDO STREAM AUDIO");
              if (ref.current) {
                ref.current.srcObject = data.stream;
                ref.current.play();
              }
            });
          });

          session.on("getusermediafailed", () => {
            alert("sem permissao para usar o microfone");
            if (onMicrophonePermissionDenied) {
              onMicrophonePermissionDenied();
            }
          });

          session.on("ended", () => {
            console.log("ended");
            session = null;
            em_ligacao = false;
            onLigacaoChanged(false);
            // $("#registrado").show();
            // $("#logado_na_campanha").hide();
            // $("#status").text('Ramal registrado');
          });

          session.on("muted", () => {
            ramal_mutado = true;
            console.log("mutado");
            dispatch3cPlus({ type: "mute" });
            // setRamalMutado(true);
            onRamalChanged(true);
          });

          session.on("unmuted", () => {
            ramal_mutado = false;
            console.log("desmutado");
            dispatch3cPlus({ type: "unMute" });
            // setRamalMutado(false);
            onRamalChanged(false);
          });

          session.answer({
            mediaConstraints: { audio: true, video: false },
          });
        });

        conexao_jssip.on("registered", () => {
          console.log("ramal registrado");
          //   $("#status").text("Ramal registrado");
          registro_do_ramal = true;
          //   $("#registrado").show();
        });

        conexao_jssip.on("unregistered", () => {
          console.log("ramal não registrado");
          //   $("#status").text("Ramal desconectado, tentando conectar...");
          registro_do_ramal = false;
          //   $("#registrado").hide();
        });

        conexao_jssip.on("registrationFailed", (e) => {
          console.log("registrationFailed", e);
          //   $("#status").text("Tentando registrar...");
          registro_do_ramal = false;
          //   $("#registrado").hide();
          setTimeout(() => {
            if (conexao_jssip?.isConnected() && !conexao_jssip.isRegistered()) {
              if (tentativas_de_conexao <= 10) {
                console.log("trying register...");
                // $("#status").text(
                //   "Tentando registrar... tentativa: " + tentativas_de_conexao
                // );
                conexao_jssip.register();
                tentativas_de_conexao++;
              } else {
                console.log("Register 10 attempts");
                alert("Erro no registro, recarregue a página.");
              }
            }
          }, 5000);
        });
      })
      .catch((e) => {
        console.log("Erro ao buscar informações do usuário autenticado 3cplus");
        if (onAuthenticatedUserError) {
          onAuthenticatedUserError(e);
        }
      });
  };

  return <audio ref={ref} id="audio" autoPlay />;
}
