import { Textarea, TextareaProps } from "@chakra-ui/react";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { forwardRef, memo } from "react";

const OptimizedTextarea = forwardRef<
  HTMLTextAreaElement,
  TextareaProps & {
    fieldName?: keyof Attendance;
    onChangeField?: (field: keyof Attendance, value: string) => void;
  }
>(({ fieldName, onChangeField, ...rest }, ref) => {
  return (
    <Textarea
      ref={ref}
      onChange={({ target }) => {
        if (fieldName && onChangeField) onChangeField(fieldName, target.value);
      }}
      h="130px"
      borderStyle="solid"
      borderWidth="1px"
      borderColor="var(--chakra-colors-custom-gray)"
      _focus={{
        outline: "none",
        boxShadow: "none",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "var(--chakra-colors-custom-gray)",
      }}
      _hover={{ bg: "var(--chakra-colors-bg-gray)" }}
      {...rest}
    />
  );
});

OptimizedTextarea.displayName = "OptimizedTextarea";

export default memo(OptimizedTextarea);
