import { Pipeline } from ".";
import {
  Box,
  Flex,
  ListItem,
  Progress,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { Fragment } from "react";

type GroupBancosByTipoHigienizacao = {
  [key: string]: {
    [k: string]: {
      banco: string;
      finished: boolean;
      higienizacaoId: string;
      lastError: null;
      tipoHigienizacao: string;
      total: number;
      totalCompleted: number;
      totalErrors: null;
      mensagem: string | null;
    }[];
  };
};

const parsePipelineStage = new Map<Pipeline["pipelineStage"], string>([
  ["HIGIENIZACAO", "Higienização"],
  ["REFIN", "Refin."],
  ["SAQUE_COMPLEMENTAR", "Saque Complementar"],
  ["FILTRO", "Filtro"],
  ["ENVIA_WHATS", "Envio de campanha Whatsapp"],
  ["ENVIA_CAMPANHA_SMS", "Envio de campanha SMS"],
  ["ENVIA_DISCADORA", "Envio de campanha URA"],
]);

export function HigienizacaoProgress({
  modalData,
}: {
  modalData: Pipeline[] | undefined;
}) {
  let higienizacoes = modalData?.filter(
    (higienizacao) => higienizacao.pipelineStage === "HIGIENIZACAO"
  );

  if (higienizacoes && higienizacoes.length > 0) {
    let higienizacaoRootErrors = higienizacoes
      .filter(
        (h) =>
          h.higienizacaoStatusResultList === null &&
          h.pipelineErrorDescription !== null
      )
      .map((h) => h.pipelineErrorDescription);
    let filteredHigienizacoes = higienizacoes.filter(
      (h) => h.higienizacaoStatusResultList !== null
    );
    let errorsGroupedByBancos: { [key: string]: Array<any> } = {};

    if (filteredHigienizacoes !== null && filteredHigienizacoes.length > 0) {
      errorsGroupedByBancos = filteredHigienizacoes
        ?.map((h) => h.higienizacaoStatusResultList)
        ?.reduce(function (pre: any, cur: any) {
          return pre.concat(cur);
        })
        ?.reduce(function (rv: any, x: any) {
          (rv[x["banco"]] = rv[x["banco"]] || []).push(x);
          return rv;
        }, {});
    }

    const tiposHigienizacoes = higienizacoes?.map(
      ({ pipelineTipoHigienizacao }: any) => pipelineTipoHigienizacao
    );

    let groupBancosByTipoHigienizacao: GroupBancosByTipoHigienizacao = {};

    tiposHigienizacoes.forEach((keyTipoHigienizacao) => {
      const currentTipoHigienizacao: any = {};
      Object.keys(errorsGroupedByBancos).forEach((keyBanco) => {
        const filteredByTiposHigienizacao = errorsGroupedByBancos[
          keyBanco
        ].filter((curr) => curr.tipoHigienizacao === keyTipoHigienizacao);
        if (filteredByTiposHigienizacao.length)
          currentTipoHigienizacao[keyBanco] = filteredByTiposHigienizacao;
      });
      groupBancosByTipoHigienizacao[keyTipoHigienizacao] =
        currentTipoHigienizacao;
    });

    return (
      <Flex flexDir="column" px="5px">
        <UnorderedList my="8px">
          {Object.keys(groupBancosByTipoHigienizacao).map(
            (keyTipoHigienizacao, i) => {
              const tipoHigienizacaoTitle = parsePipelineStage.get(
                keyTipoHigienizacao as Pipeline["pipelineStage"]
              );
              const currentBanco =
                groupBancosByTipoHigienizacao[keyTipoHigienizacao];

              const bancoKeys = Object.keys(currentBanco);

              if (!bancoKeys.length) return null;

              return (
                <ListItem key={`tipoHigienizacao-${i}`}>
                  <Text fontSize="15" mb="5px" fontWeight="medium">
                    {tipoHigienizacaoTitle}
                  </Text>
                  <UnorderedList>
                    {bancoKeys.map((keyBanco, i) => {
                      const currentBankData = currentBanco[keyBanco];
                      const successHigienizacaoProgress =
                        currentBankData?.filter(
                          ({ lastError, mensagem }) => !lastError && !mensagem
                        );
                      const errors = currentBankData?.filter(
                        ({ lastError }) => lastError
                      );
                      const messages = currentBankData?.filter(
                        ({ mensagem }) => mensagem
                      );

                      return (
                        <Fragment key={`banco-${i}`}>
                          <ListItem>
                            <Text mb="5px">Banco: {keyBanco}</Text>
                          </ListItem>
                          <UnorderedList key={`bank-data-${i}`} my="5px">
                            {successHigienizacaoProgress?.map(
                              ({ totalCompleted = 0, total = 0 }, i) => {
                                return (
                                  <ListItem mb="10px" key={`bank-data-${i}`}>
                                    <Text mb="5px">
                                      Progresso {totalCompleted} de {total}
                                    </Text>
                                    <Box>
                                      <Progress
                                        colorScheme="progressColor"
                                        value={(totalCompleted / total) * 100}
                                        hasStripe
                                        borderRadius="full"
                                        size="sm"
                                      />
                                    </Box>
                                  </ListItem>
                                );
                              }
                            )}
                            {errors.length ? (
                              <>
                                <ListItem color="red">
                                  <Text>Erros:</Text>
                                </ListItem>
                                <UnorderedList>
                                  {errors.map(
                                    ({ lastError, totalCompleted }, i) => {
                                      return (
                                        <ListItem
                                          color="red"
                                          key={`error-${i}`}
                                        >
                                          <Text>{lastError}</Text>
                                          <Text>
                                            Quantidade: {totalCompleted}
                                          </Text>
                                        </ListItem>
                                      );
                                    }
                                  )}
                                </UnorderedList>
                              </>
                            ) : null}
                            {messages.length ? (
                              <>
                                <ListItem>
                                  <Text>Mensagens:</Text>
                                </ListItem>
                                <UnorderedList>
                                  {messages.map(
                                    ({ mensagem, totalCompleted }, i) => {
                                      return (
                                        <ListItem key={`error-${i}`}>
                                          <Text
                                            dangerouslySetInnerHTML={{
                                              __html: mensagem!,
                                            }}
                                            whiteSpace="pre-wrap"
                                            sx={{ br: { fontWeight: "bold" } }}
                                          />
                                          <Text>
                                            Quantidade: {totalCompleted}
                                          </Text>
                                        </ListItem>
                                      );
                                    }
                                  )}
                                </UnorderedList>
                              </>
                            ) : null}
                          </UnorderedList>
                        </Fragment>
                      );
                    })}
                  </UnorderedList>
                </ListItem>
              );
            }
          )}
        </UnorderedList>
        {higienizacaoRootErrors?.map((err) => (
          <Flex flexDir="column" p="10px">
            {err !== null ? (
              <>
                <Text>
                  º Outros:{" "}
                  <Text paddingLeft={5} color="tomato">
                    {err}
                  </Text>
                </Text>
              </>
            ) : null}
          </Flex>
        ))}
      </Flex>
    );
  } else return null;
}
