import { createStandaloneToast } from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { AttendanceActionsTypes } from "components/atendimentos-components/modal-atendimento/actions-reducer";
import { Dispatch, SetStateAction } from "react";
import { makeEvent } from "services/events";

const { toast } = createStandaloneToast();

export async function enviarTelefoneContato(
  telefoneContato: string,
  attendanceId: number,
  setLoading: Dispatch<SetStateAction<boolean>>,
  dispatch: Dispatch<AttendanceActionsTypes>,
  onCloseModalChangeNumber: () => void
) {
  setLoading(true);
  try {
    await api.patch(`/atendimentos/${attendanceId}/telefone-contato`, {
      telefoneContatoRaw: telefoneContato.replace(/\D/g, ""),
    });
    dispatch({
      type: "changeFieldWithInitial",
      payload: {
        fieldName: "telefoneContatoNormalizado",
        data: telefoneContato.replace(/\D/g, ""),
      },
    });
    makeEvent("clear-whatsapp-chat");
  } catch (error: any) {
    if (
      error?.response?.data?.message?.includes("not connected with waboxapp")
    ) {
      toast({
        title: "Não foi possível enviar. WhatsApp desconectado!",
        status: "error",
        ...toastDefaultStyle,
      });
    } else {
      toast({
        title: "Erro ao atualizar telefone do contato!",
        status: "error",
        ...toastDefaultStyle,
      });
    }
    setLoading(false);
    return;
  }
  onCloseModalChangeNumber();
  setLoading(false);
}
