import { INITIAL_ATTENDANCE_VALUES } from "contexts/AttendanceContext";
import { Attendance, Product } from "../atendimento-form/types";

export type AttendanceActionsTypes =
  | {
      type: "changeField" | "changeFieldWithInitial";
      payload: { fieldName: keyof Attendance; data: any };
    }
  | {
      type: "closeAttendance" | "discardChanges";
      payload?: undefined;
    }
  | {
      type: "replaceAttendance" | "initAttendance";
      payload: Attendance;
    }
  | {
      type: "addProduct";
      payload: Product;
    }
  | {
      type: "deleteProduct";
      payload: number;
    }
  | {
      type: "updateProduct";
      payload: { updatedItem: Product; index: number };
    }
  | {
      type: "setInvalidField";
      payload:
        | {
            fieldName: keyof Attendance;
            action: "append" | "clear";
          }
        | { fieldName?: undefined; action: "clear" };
    };

export interface FormControl {
  values: Attendance | Partial<Attendance>;
  initialValues: Attendance | Partial<Attendance>;
  pendingChanges: boolean;
  invalidFields: (keyof Attendance)[];
}

interface Operations {
  initAttendance: "initAttendance";
  closeAttendance: "closeAttendance";
  changeField: "changeField";
  changeFieldWithInitial: "changeFieldWithInitial";
  discardChanges: "discardChanges";
  replaceAttendance: "replaceAttendance";
  addProduct: "addProduct";
  deleteProduct: "deleteProduct";
  updateProduct: "updateProduct";
  setInvalidField: "setInvalidField";
}

const operations: Operations = {
  initAttendance: "initAttendance",
  closeAttendance: "closeAttendance",
  changeField: "changeField",
  changeFieldWithInitial: "changeFieldWithInitial",
  discardChanges: "discardChanges",
  replaceAttendance: "replaceAttendance",
  addProduct: "addProduct",
  deleteProduct: "deleteProduct",
  updateProduct: "updateProduct",
  setInvalidField: "setInvalidField",
};

export let controllerAttendanceRequests = new AbortController();

export function dispatchAttendance(
  state: FormControl,
  action: AttendanceActionsTypes
): FormControl {
  const { type, payload } = action;
  const { values, initialValues, invalidFields } = state;
  const produtosFgts = values.produtosFgts as any[];

  switch (type) {
    case operations.initAttendance:
      return {
        ...state,
        values: payload,
        initialValues: { ...payload },
        pendingChanges: false,
      };
    case operations.closeAttendance:
      controllerAttendanceRequests.abort();
      controllerAttendanceRequests = new AbortController();
      return { ...INITIAL_ATTENDANCE_VALUES };
    case operations.changeField:
      if (payload.fieldName === "telefoneContatoNormalizado")
        return {
          ...state,
          values: { ...values, [payload.fieldName]: payload.data },
          pendingChanges: false,
        };
      return {
        ...state,
        values: { ...values, [payload.fieldName]: payload.data },
        pendingChanges: true,
      };
    case operations.changeFieldWithInitial:
      return {
        ...state,
        initialValues: { ...initialValues, [payload.fieldName]: payload.data },
        values: { ...values, [payload.fieldName]: payload.data },
        pendingChanges: false,
      };
    case operations.discardChanges:
      return {
        ...state,
        values: { ...initialValues },
        pendingChanges: false,
        invalidFields: [],
      };
    case operations.replaceAttendance:
      return {
        ...state,
        values: payload,
        pendingChanges: true,
      };
    case operations.setInvalidField:
      let fields = invalidFields;
      if (payload.fieldName) {
        if (payload.action === "append") fields.push(payload.fieldName);
        else if (payload.action === "clear")
          fields = fields.filter((f) => f !== payload.fieldName);
      } else {
        if (payload.action === "clear") fields = [];
      }
      return {
        ...state,
        invalidFields: fields,
      };
    case operations.addProduct:
      return {
        ...state,
        values: {
          ...values,
          produtosFgts: [...produtosFgts, payload],
        },
        pendingChanges: true,
      };
    case operations.deleteProduct:
      const deletedProduct = produtosFgts.filter(
        (_, index) => index !== payload
      );
      return {
        ...state,
        values: {
          ...values,
          produtosFgts: deletedProduct,
        },
        pendingChanges: true,
      };
    case operations.updateProduct:
      const updatedProduct = produtosFgts.map((product, i) =>
        i === payload.index ? payload.updatedItem : product
      );
      return {
        ...state,
        values: {
          ...values,
          produtosFgts: updatedProduct,
        },
        pendingChanges: true,
      };

    default:
      return state;
  }
}
