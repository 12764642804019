import { useColorMode } from "@chakra-ui/react";
import { ChangeEvent, CSSProperties, forwardRef } from "react";

interface SliderProps extends CSSProperties {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => any;
  min: number;
  max: number;
}

export const Slider = forwardRef<HTMLInputElement, SliderProps>(
  ({ onChange, min, max, ...style }: SliderProps, ref) => {
    const { colorMode } = useColorMode();
    return (
      <input
        ref={ref}
        style={{ ...style }}
        className={`slider ${colorMode}`}
        type="range"
        onChange={(e) => {
          e.target.readOnly = false;
          const progress =
            ((parseInt(e.target.value) - min) / (max - min)) * 100;
          e.target.style.backgroundSize = `${progress}% 100%`;
          onChange?.(e);
        }}
        onInput={(e) => {
          const progress =
            ((parseInt(e.currentTarget.value) - min) / (max - min)) * 100;
          e.currentTarget.style.backgroundSize = `${progress}% 100%`;
        }}
        min={min}
        max={max}
        defaultValue={min}
      />
    );
  }
);
