import { InfoIcon, WarningIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { CustomModal } from "components/custom-modal";
import { useState } from "react";
import { createEventListener, makeEvent } from "services/events";

interface ModalErrorOptions {
  title?: string;
  message?: string;
  onClose?: () => any;
}

export function ModalInfo() {
  const [modalErrorData, setModalErrorData] =
    useState<null | ModalErrorOptions>(null);

  createEventListener("open-modal-info", (options: ModalErrorOptions) => {
    setModalErrorData(options);
  });

  function onClose() {
    setModalErrorData(null);
    modalErrorData?.onClose?.();
  }

  return (
    <CustomModal
      isOpen={modalErrorData !== null}
      onClose={onClose}
      modalTitle={
        <Flex alignItems="center">
          <Box mr="8px" transform="translateY(-2px)">
            <InfoIcon color="primary.300" w="20px" h="20px" />
          </Box>
          {modalErrorData?.title ?? "Informação"}
        </Flex>
      }
      modalFooter={
        <Button variant="outline" onClick={onClose}>
          OK
        </Button>
      }
      size={"xl"}
    >
      <Flex alignItems="center">
        <Text>{modalErrorData?.message ?? ""}</Text>
      </Flex>
    </CustomModal>
  );
}

export function openModalInfo(options: ModalErrorOptions) {
  makeEvent("open-modal-info", options);
}
