import { GetLayout } from "components/get-layout";
import { PageContent } from "components/usuarios-bancos-components/page";

export const UsuariosBancos = () => {
  return (
    <GetLayout>
      <PageContent />
    </GetLayout>
  );
};
