import { WarningIcon } from "@chakra-ui/icons";
import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import { defaultScroll, slimScroll } from "chakra/theme";
import { useAttendanceContext } from "contexts/AttendanceContext";
import { useEffect, useRef, useState } from "react";
import { useDraggable } from "react-use-draggable-scroll";
import {
  fieldsSchema,
  Step,
  stepsArray,
  TabData,
  TabDataProps,
} from "./consts";
import "./style.css";
import { useApplicationContext } from "contexts/ApplicationContext";
import { isColomboUser } from "utils/is-colombo-user";

export function StepForm({
  onChangeField,
  resetFieldsTabelaDigitacao,
}: TabDataProps) {
  const [step, setStep] = useState<Step>("Dados Cadastrais");
  const { formControl } = useAttendanceContext();
  const { user } = useApplicationContext();
  const lineRef = useRef<HTMLDivElement>(null);
  const tabsContainerRef = useRef<HTMLDivElement>(null);
  const formSteps: Map<Step, TabData> = new Map(fieldsSchema);
  const Fields = formSteps.get(step)!.component;
  //@ts-ignore
  const { events: tabEvents } = useDraggable(tabsContainerRef);
  const convenio = formControl.values.convenio;

  const moveStepBar = (currentTarget: HTMLDivElement) => {
    const container = tabsContainerRef.current!;
    const { left: containerLeft } = container.getBoundingClientRect();
    const { left, width } = currentTarget.getBoundingClientRect();
    const lineStyle = lineRef.current!.style;
    lineStyle.transform = `translateX(${
      left - containerLeft + container.scrollLeft
    }px)`;
    lineStyle.width = `${width}px`;
  };

  const getStepId = (fieldName: Step) => {
    return fieldName
      .replace(" ", "")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  };

  useEffect(() => {
    if (convenio === "ANTECIPACAO_FGTS" && step === "Contratos") {
      setStep("Dados Cadastrais");
      moveStepBar(
        document.getElementById(getStepId("Dados Cadastrais")) as HTMLDivElement
      );
    } else if (
      (!["INSS", "ANTECIPACAO_FGTS"].includes(convenio!) ||
        !isColomboUser(user)) &&
      step === "Digitação"
    ) {
      setStep("Dados Cadastrais");
      moveStepBar(
        document.getElementById(getStepId("Dados Cadastrais")) as HTMLDivElement
      );
    } else {
      moveStepBar(document.getElementById(getStepId(step)) as HTMLDivElement);
    }
  }, [convenio]);

  return (
    <>
      <Flex
        ref={tabsContainerRef}
        w="100%"
        pos="relative"
        overflowX="auto"
        minH="63px"
        maxW="calc(66vw - 48px - 11px)"
        mt="15px"
        sx={slimScroll}
        {...tabEvents}
      >
        {stepsArray.map(({ fieldName, icon, isVisible }, index) => {
          const id = getStepId(fieldName);
          const last = stepsArray.length - 1 === index;
          const active = fieldName === step;
          const fieldsArray = formSteps.get(fieldName)!.fields;
          const isInvalid = !!formControl.invalidFields.find((curr) =>
            fieldsArray.includes(curr)
          );

          if (isVisible != null && !isVisible(formControl.values || {}, user))
            return null;

          return (
            <Flex
              id={id}
              key={fieldName}
              h="60px"
              pt="10px"
              mr={last ? undefined : "10px"}
              px="12px"
              color={active ? "primary.300" : undefined}
              fontWeight={500}
              _hover={{
                color: "primary.300",
              }}
              pos="relative"
              alignItems="center"
              cursor="pointer"
              onClick={({ currentTarget }) => {
                setStep(fieldName);
                moveStepBar(currentTarget);
              }}
              userSelect="none"
              whiteSpace="nowrap"
            >
              <Box mr="8px">{icon}</Box>
              <Text>{fieldName}</Text>
              {isInvalid ? (
                <WarningIcon
                  display="block"
                  width="15px"
                  height="15px"
                  borderRadius="15px"
                  pos="absolute"
                  top="10px"
                  right="0px"
                  color="secondary.600"
                  animation="pulseRed 2s infinite"
                />
              ) : null}
            </Flex>
          );
        })}
        <Box
          ref={lineRef}
          bg="primary.300"
          w="191.338px"
          pos="absolute"
          bottom={0}
          left={0}
          borderRadius="3"
          transition="0.2s"
          h="3px"
        />
      </Flex>
      <hr />

      <Grid
        border="1px solid var(--chakra-colors-gray-200)"
        mt="10px"
        w="100%"
        gap="16px"
        overflowY="auto"
        p="10px 10px 10px 10px"
        borderRadius="6px"
        sx={defaultScroll}
      >
        <Fields
          resetFieldsTabelaDigitacao={resetFieldsTabelaDigitacao}
          onChangeField={onChangeField}
        />
      </Grid>
    </>
  );
}
