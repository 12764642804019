import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Button,
  Center,
  Flex,
  Grid,
  IconButton,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { defaultScroll } from "chakra/theme";
import { Popover, closePopover } from "components/popover";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { calendarArray } from "utils/calendar-array";

let historyBox: any = {};
export function Calendar({
  insidePopoverId,
  selectedDate,
  setSelectedDate,
  onClick,
  ...rest
}: {
  insidePopoverId?: string;
  selectedDate: string;
  setSelectedDate: (date: Date) => any;
} & BoxProps) {
  const [date, setDate] = useState(
    insidePopoverId ? historyBox[insidePopoverId] ?? new Date() : new Date()
  );
  const buttonInit = useRef<HTMLButtonElement>(null);
  const {
    currentMonthIndex,
    currentMonthName,
    currentMonthDays,
    currentYear,
    months,
    abbreviatedWeekDays,
  } = calendarArray(date);

  const nowYear = new Date().getFullYear();

  const weekDaysBg = useColorModeValue("gray.300", "whiteAlpha.100");
  const hoverBgDay = useColorModeValue("gray.400", "whiteAlpha.200");
  const bgActiveDate = useColorModeValue("primary.400", "primary.400");

  const prevMonth = () => {
    if (currentMonthIndex === 0) {
      const newDate = new Date(currentYear - 1, 11);
      setDate(newDate);
    } else {
      const newDate = new Date(currentYear, currentMonthIndex - 1);
      setDate(newDate);
    }
  };

  const nextMonth = () => {
    if (currentMonthIndex === 11) {
      const newDate = new Date(currentYear + 1, 0);
      setDate(newDate);
    } else {
      const newDate = new Date(currentYear, currentMonthIndex + 1);
      setDate(newDate);
    }
  };

  useEffect(() => {
    if (insidePopoverId) historyBox[insidePopoverId] = date;
  }, [date]);

  return (
    <Box
      w="100%"
      onClick={(e) => {
        onClick?.(e);
      }}
      {...rest}
    >
      {/* HEADER */}
      <Flex
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        mb="16px"
      >
        <IconButton
          aria-label=""
          size="sm"
          icon={<ChevronLeftIcon w="24px" h="24px" />}
          onClick={prevMonth}
          variant="ghost"
        />
        <Flex>
          <Popover
            id="month-popover"
            closeOnBlur
            button={
              <Button
                ref={buttonInit}
                fontWeight="bold"
                h="32px"
                mr="4px"
                borderRadius="7px"
                variant="ghost"
                rightIcon={<ChevronDownIcon display="block" ml="4px" />}
                onClick={() => closePopover("year-popover")}
              >
                {`${currentMonthName}`}
              </Button>
            }
          >
            <Box maxH="120px" overflow="overlay" sx={defaultScroll}>
              {months.map((month, index) => {
                const active = currentMonthIndex === index;
                return (
                  <Text
                    key={month}
                    px="4px"
                    cursor="pointer"
                    bg={active ? bgActiveDate : undefined}
                    _hover={{ bg: active ? undefined : hoverBgDay }}
                    color={active ? "#fff" : undefined}
                    onClick={() => {
                      setDate(new Date(currentYear, index));
                      closePopover("month-popover");
                    }}
                    fontSize="14px"
                  >
                    {month}
                  </Text>
                );
              })}
            </Box>
          </Popover>
          <Popover
            id="year-popover"
            closeOnBlur
            button={
              <Button
                ref={buttonInit}
                fontWeight="bold"
                h="32px"
                borderRadius="7px"
                variant="ghost"
                rightIcon={<ChevronDownIcon display="block" ml="4px" />}
                onClick={() => closePopover("month-popover")}
              >
                {currentYear}
              </Button>
            }
          >
            <Box maxH="120px" overflow="overlay" sx={defaultScroll}>
              {Array.from({ length: 20 }).map((_, index) => {
                const currYear = nowYear - 10 + index;
                const active = currYear === currentYear;
                return (
                  <Text
                    px="4px"
                    cursor="pointer"
                    bg={active ? bgActiveDate : undefined}
                    _hover={{ bg: active ? undefined : hoverBgDay }}
                    color={active ? "#fff" : undefined}
                    key={index}
                    onClick={() => {
                      setDate(new Date(currYear, currentMonthIndex));
                      closePopover("year-popover");
                    }}
                    fontSize="14px"
                  >
                    {currYear}
                  </Text>
                );
              })}
            </Box>
          </Popover>
        </Flex>

        <IconButton
          aria-label=""
          size="sm"
          icon={<ChevronRightIcon w="24px" h="24px" />}
          onClick={nextMonth}
          variant="ghost"
        />
      </Flex>
      {/* WEEK DAYS */}
      <Flex
        w="100%"
        justifyContent="space-between"
        borderRadius="10px"
        bg={weekDaysBg}
        mb="6px"
      >
        {abbreviatedWeekDays.map((weekDay) => {
          return (
            <Text
              flexBasis="14.2857%"
              p="6px"
              textAlign="center"
              textTransform="uppercase"
              fontWeight="600"
              fontSize="13px"
              key={weekDay}
            >
              {weekDay}
            </Text>
          );
        })}
      </Flex>
      {/* MONTH DAYS */}
      <Grid templateColumns="repeat(7,1fr)" w="100%">
        {currentMonthDays.map(({ day, outOfMonth, date }, index) => {
          const currentSelectedDate = selectedDate
            ?.split("-")
            ?.reverse()
            .join("/");
          const active =
            date.toLocaleDateString() ===
            new Date(currentSelectedDate).toLocaleDateString();
          return (
            <Center
              key={index}
              textAlign="center"
              borderRadius="10px"
              h="34px"
              opacity={outOfMonth ? "0.5" : "1"}
              cursor="pointer"
              fontSize="13px"
              _hover={{ bg: active ? undefined : hoverBgDay }}
              onClick={() => {
                if (outOfMonth) {
                  const isNextMonth = index > 7;
                  if (isNextMonth) {
                    nextMonth();
                  } else prevMonth();
                }
                setSelectedDate(date);
                if (insidePopoverId) closePopover(insidePopoverId);
              }}
              bg={active ? bgActiveDate : undefined}
              color={active ? "#fff" : undefined}
            >
              {day}
            </Center>
          );
        })}
      </Grid>
    </Box>
  );
}
