import { Icon } from "@chakra-ui/react";

export function LockIcon({ ...rest }) {
    return (
        <Icon width="18px" height="18px" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M14.625 8.15625H13.4297V4.21875C13.4297 2.97598 12.4225 1.96875 11.1797 1.96875H6.82031C5.57754 1.96875 4.57031 2.97598 4.57031 4.21875V8.15625H3.375C3.06387 8.15625 2.8125 8.40762 2.8125 8.71875V15.4688C2.8125 15.7799 3.06387 16.0312 3.375 16.0312H14.625C14.9361 16.0312 15.1875 15.7799 15.1875 15.4688V8.71875C15.1875 8.40762 14.9361 8.15625 14.625 8.15625ZM5.83594 4.21875C5.83594 3.67559 6.27715 3.23438 6.82031 3.23438H11.1797C11.7229 3.23438 12.1641 3.67559 12.1641 4.21875V8.15625H5.83594V4.21875ZM13.9219 14.7656H4.07812V9.42188H13.9219V14.7656ZM8.50781 12.3223V13.2539C8.50781 13.3313 8.57109 13.3945 8.64844 13.3945H9.35156C9.42891 13.3945 9.49219 13.3313 9.49219 13.2539V12.3223C9.63729 12.2181 9.74559 12.0706 9.80151 11.9009C9.85743 11.7313 9.85807 11.5482 9.80335 11.3782C9.74863 11.2082 9.64137 11.0599 9.49701 10.9547C9.35265 10.8495 9.17863 10.7928 9 10.7928C8.82137 10.7928 8.64735 10.8495 8.50299 10.9547C8.35863 11.0599 8.25137 11.2082 8.19665 11.3782C8.14193 11.5482 8.14257 11.7313 8.19849 11.9009C8.25441 12.0706 8.36271 12.2181 8.50781 12.3223Z"
                fill="#00023E"
            />
        </Icon>
    );
}
