import { ListenerList } from "services/events";

let webSocketListeners: Map<string | number, ListenerList> = new Map();

export function createWebSocketEventListener(
  listenerName: string,
  listener: (data: any) => any,
  id?: string | number
) {
  const target = webSocketListeners.get(listenerName);
  if (id) {
    if (target === undefined) webSocketListeners.set(listenerName, new Map());
    const targetMap = webSocketListeners.get(listenerName);
    if (typeof targetMap === "object") targetMap.set(id, listener);
  } else webSocketListeners.set(listenerName, listener);
}

export function makeWebSocketEvent(
  listenerName: string,
  data: any,
  id?: string
) {
  const target = webSocketListeners.get(listenerName);
  if (typeof target === "object") {
    let listenerFound = false;
    target.forEach((listener, key) => {
      if (id != null) {
        if (key === id) {
          listenerFound = true;
          listener(data);
        }
      } else {
        listenerFound = true;
        listener(data);
      }
    });
    if (!listenerFound)
      throw new Error(`Listerner Not Found ${listenerName} with id: ${id}`);
  } else if (target) {
    return target(data);
  } else throw new Error(`Listerner Not Found ${listenerName}`);
}
