import {
  Button,
  Grid,
  ModalFooter,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { getErrorByMessage } from "components/atendimentos-components/modal-atendimento/functions";
import { CustomModal } from "components/custom-modal";
import { useState } from "react";
import { mailingBanks } from "../mailing-table/consts";
import { Checkbox } from "components/checkbox";
import { createEventListener, makeEvent } from "services/events";
import { SendIcon } from "components/vectors/send-icon";
import { CloseIcon } from "@chakra-ui/icons";

export function SendForCleaningModal() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [loadingGoToHigienization, setLoadingGoToHigienization] =
    useState(false);
  const [selectedMailingId, setSelectedMailingId] = useState<null | number>(
    null
  );
  const [selectedBanks, setSelectedBanks] = useState<string[]>([]);
  const toast = useToast();

  const onCloseModal = () => {
    onClose();
    setSelectedMailingId(null);
    setSelectedBanks([]);
  };

  const goToHigienizacao = async () => {
    setLoadingGoToHigienization(true);
    try {
      const { data } = await api.post("/higienizacoes/create-from-mailing", {
        mailingId: selectedMailingId,
        bancos: selectedBanks,
      });
      toast({
        title: "Mailing enviado para higienização",
        status: "success",
        ...toastDefaultStyle,
      });
    } catch (e: any) {
      toast({
        title: getErrorByMessage(e, "Erro ao Enviar para higienização"),
        status: "error",
        ...toastDefaultStyle,
      });
    } finally {
      setLoadingGoToHigienization(false);
    }
  };

  createEventListener("open-send-for-cleaning-modal", (id: number) => {
    setSelectedMailingId(id);
    onOpen();
  });

  const modalFooter = (
    <>
      <Button
        onClick={async () => {
          await goToHigienizacao();
          onCloseModal();
        }}
        disabled={!selectedBanks?.length}
        leftIcon={<SendIcon />}
        isLoading={loadingGoToHigienization}
      >
        Enviar
      </Button>

      <Button
        variant="outline"
        onClick={onCloseModal}
        leftIcon={<CloseIcon width="11px" height="11px" />}
      >
        Cancelar
      </Button>
    </>
  );
  return (
    <CustomModal
      modalTitle="Enviar para higienização"
      isOpen={isOpen}
      onClose={onCloseModal}
      modalFooter={modalFooter}
    >
      <Text mb="10px">Escolha os Bancos:</Text>
      <Grid templateColumns="1fr 1fr 1fr" gap="10px">
        {mailingBanks.map(({ value: bank }) => {
          return (
            <Checkbox
              key={bank}
              isChecked={selectedBanks.includes(bank)}
              onChange={() => {
                if (selectedBanks?.length) {
                  const isChecked = selectedBanks.includes(bank);
                  if (isChecked)
                    setSelectedBanks(
                      selectedBanks.filter((curr: any) => curr !== bank)
                    );
                  else setSelectedBanks([...selectedBanks, bank]);
                } else setSelectedBanks([bank]);
              }}
            >
              {bank}
            </Checkbox>
          );
        })}
      </Grid>
    </CustomModal>
  );
}

export const openSendForCleaningModal = (id: number) =>
  makeEvent("open-send-for-cleaning-modal", id);
