import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MailingOptionFilter } from "../..";
import { Dropdown } from "components/dropdown";
import {
  Box,
  Center,
  Grid,
  IconButton,
  Text,
  useToast,
} from "@chakra-ui/react";
import { CleanIcon } from "components/vectors/clean-icon";
import { dropdownBancosSaqueComplementar } from "../../mailing-options-steps/higienizacao";
import api from "api/api";
import { InputSearch } from "components/input-search";
import { toastDefaultStyle } from "chakra/theme";
import axios, { AxiosError } from "axios";

type FilterProps = {
  addedFilters: MailingOptionFilter;
  setAddedFilters: (
    newValue: SetStateAction<MailingOptionFilter>,
    key: string
  ) => void;
  filterKey: string;
  isDisabled?: boolean;
  allModalData: MailingOptionFilter;
};

interface Options {
  [key: string]: {
    name: string | undefined;
    value: any;
  }[];
}

interface FilterDataProps {
  banco: "MASTER" | "BMG" | "PAN";
  idConvenio?: string;
  tipoCartao?: string;
}

export function SaqueComplementar({
  addedFilters,
  setAddedFilters,
  filterKey,
  isDisabled,
  allModalData,
}: FilterProps) {
  const [isLoadingConvenio, setIsLoadingConvenio] = useState(false);
  const [convenioOptions, setConvenioOptions] = useState<Options>({});
  const filterValue: FilterDataProps[] = addedFilters[filterKey]?.value ?? [];
  const selectedBank = filterValue?.[0]?.banco;
  const isVisibleConvenios = !!selectedBank;

  const toast = useToast();

  const isInvalid = (s: FilterDataProps) =>
    !s.banco || !s.idConvenio || !s.tipoCartao;

  const onChangeValue = (newValue: FilterDataProps[]) => {
    setAddedFilters(
      (addedFilters) => ({
        ...addedFilters,
        [filterKey]: {
          ...filterValue,
          value: newValue,
          isChecked: !!newValue.length && !newValue.filter(isInvalid).length,
        },
      }),
      filterKey
    );
  };

  const getOption = (bank: "MASTER" | "BMG" | "PAN", data: any) => {
    return {
      MASTER: () => {
        return data.map((item: { nome: string; idConvenio: number }) => ({
          name: item.nome,
          value: item.idConvenio,
        }));
      },
      BMG: () => {
        return Object.keys(data).map((key) => {
          const currentValue = key;
          const currentName = data[key];
          return { name: currentName, value: currentValue };
        });
      },
      PAN: () => {
        return data.map((item: { nome: string; idConvenio: string }) => ({
          name: item.nome,
          value: item.idConvenio,
        }));
      },
    }[bank]();
  };

  const getTipoCartao = (
    bank: "MASTER" | "BMG" | "PAN",
    convenioName: string
  ) => {
    const tipoCartaoMaster = convenioName.toLowerCase().includes("credcesta")
      ? "RCC"
      : "RMC";
    return { PAN: "RMC", BMG: "RMC", MASTER: tipoCartaoMaster }[bank];
  };

  const getConvenioOptions = async (bank: "MASTER" | "BMG" | "PAN") => {
    setIsLoadingConvenio(true);
    try {
      const { data } = await api.get(
        `/pipeline/saque-complementar-convenios?banco=${bank}`
      );
      const options = getOption(bank, data);

      setConvenioOptions({ ...convenioOptions, [bank]: options });
    } catch (err) {
      const errors = err as Error | AxiosError;
      if (axios.isAxiosError(errors)) {
        toast({
          title: errors.response?.data,
          ...toastDefaultStyle,
        });
      } else {
        toast({
          title: errors.message,
          ...toastDefaultStyle,
        });
      }
    } finally {
      setIsLoadingConvenio(false);
    }
  };

  useEffect(() => {
    const bank = filterValue?.[0]?.banco;
    if (bank) getConvenioOptions(bank);
  }, []);

  return (
    <>
      <Grid
        templateColumns="auto 32px"
        gap="8px"
        mb={isVisibleConvenios ? "10px" : undefined}
      >
        <Dropdown
          w="100%"
          onChange={(value) => {
            onChangeValue([{ banco: value }]);
            getConvenioOptions(value);
          }}
          value={selectedBank}
          disabled={isDisabled}
          options={dropdownBancosSaqueComplementar}
          loadingText="Selecione"
          spinnerPlacement="end"
        />
        <Center>
          <IconButton
            aria-label=""
            variant="outline"
            size="sm"
            icon={<CleanIcon />}
            onClick={() => onChangeValue([])}
            disabled={isDisabled}
          />
        </Center>
      </Grid>

      {filterValue.map(({ banco, idConvenio }) => {
        const id = `convenio-${banco}`;
        const convenioName = convenioOptions[banco]?.find(
          (curr) => curr.value === idConvenio
        )?.name;
        console.log(
          convenioOptions[banco]?.find((curr) => curr.value === idConvenio)
        );
        let currentInputValue = convenioName;
        return (
          <Box mb="10px" key={id}>
            <Text mb="10px">Convênios {banco}</Text>
            <InputSearch
              options={convenioOptions[banco] ?? []}
              onChange={(value) => {
                const newConvenioName = convenioOptions[banco].find(
                  (curr) => curr.value === value
                )?.name!;
                currentInputValue = newConvenioName;
                const newValue = filterValue.map((s) => {
                  if (s.banco === banco)
                    return {
                      ...s,
                      idConvenio: value,
                      tipoCartao: getTipoCartao(banco, newConvenioName),
                    };
                  return s;
                });
                onChangeValue(newValue);
              }}
              openOnFocus
              clearInput={false}
              searchKeys={["name"]}
              value={convenioName}
              checkIsInclude={(option) => idConvenio === option.value}
              isLoading={isLoadingConvenio}
              inputProps={{
                id,
                isDisabled: isDisabled || isLoadingConvenio,
                w: "100%",
                autoComplete: "off",
                placeholder: isLoadingConvenio
                  ? "Carregando Convênios"
                  : "Selecione o convênio",
                onBlur: (e) => {
                  e.target.value = currentInputValue ?? "";
                },
              }}
            />
          </Box>
        );
      })}
    </>
  );
}
