interface ValidateStep {
  message?: string;
  onInvalid?: (message?: string) => any;
}

type CustomValidateStep = ValidateStep & { validate: (data: any) => boolean };

interface ValidationReturn {
  string: (a?: ValidateStep) => ValidationReturn;
  min8digit: (a?: ValidateStep) => ValidationReturn;
  required: (a?: ValidateStep) => ValidationReturn;
  number: (a?: ValidateStep) => ValidationReturn;
  email: (a?: ValidateStep) => ValidationReturn;
  array: (a?: ValidateStep) => ValidationReturn;
  boolean: (a?: ValidateStep) => ValidationReturn;
  custom: (a: CustomValidateStep) => ValidationReturn;
  cpf: (a?: ValidateStep) => ValidationReturn;
  date: (a?: ValidateStep) => ValidationReturn;
  isValid: boolean;
  errorMessage: string;
}

interface NewRuleProps extends ValidateStep {
  check: boolean;
}

interface ValidationProps {
  value: any;
  throwsError?: boolean;
  lastData?: { errorMessage: string; isValid: boolean };
}

export function fieldValidation({
  value,
  throwsError,
  lastData,
}: ValidationProps): ValidationReturn {
  let isValid = lastData?.isValid ?? true;
  let errorMessage: string = lastData?.errorMessage ?? "";

  function newRule({ message, onInvalid, check }: NewRuleProps) {
    if (!isValid)
      return fieldValidation({
        value,
        throwsError,
        lastData: { errorMessage, isValid },
      });
    isValid = check;
    if (!isValid) {
      onInvalid?.(message);
      if (message) errorMessage = message;
      if (throwsError) throw new Error(message);
    }
    return fieldValidation({
      value,
      throwsError,
      lastData: { errorMessage, isValid },
    });
  }

  function string({ message, onInvalid }: ValidateStep = {}) {
    return newRule({ message, onInvalid, check: typeof value === "string" });
  }

  function number({ message, onInvalid }: ValidateStep = {}) {
    return newRule({ message, onInvalid, check: typeof value === "number" });
  }

  function array({ message, onInvalid }: ValidateStep = {}) {
    return newRule({ message, onInvalid, check: Array.isArray(value) });
  }

  function min8digit({ message, onInvalid }: ValidateStep = {}) {
    return newRule({
      message,
      onInvalid,
      check: typeof value === "string" ? value.length >= 8 : false,
    });
  }

  function cpf({ message, onInvalid }: ValidateStep = {}) {
    return newRule({
      message,
      onInvalid,
      check: validateCPF(value?.replace(/\D/g, "")),
    });
  }

  function required({ message, onInvalid }: ValidateStep = {}) {
    let check = true;
    if (value?.constructor === Object) check = !!Object.keys(value).length;
    else if (typeof value === "number") check = !!value;
    else if (typeof value === "string") check = !!value.trim();
    else if (Array.isArray(value)) check = !!value.length;
    else if (value == null) check = false;
    else if (isNaN(value)) check = false;
    return newRule({ message, onInvalid, check });
  }

  function email({ message, onInvalid }: ValidateStep = {}) {
    const reg = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+\.([a-z]+)?$/i;
    return newRule({
      message,
      onInvalid,
      check: typeof value === "string" && reg.test(value),
    });
  }

  function date({ message, onInvalid }: ValidateStep = {}) {
    let check = false;
    if (typeof value === "string") {
      const onlyNumbers = value.replace(/\D/g, "");
      if (onlyNumbers.length === 8 && value.length === 10) {
        if (value.split("-").length === 3 || value.split("/").length === 3)
          check = true;
      }
    }

    return newRule({
      message,
      onInvalid,
      check,
    });
  }

  function boolean({ message, onInvalid }: ValidateStep = {}) {
    return newRule({ message, onInvalid, check: typeof value === "boolean" });
  }

  function custom({ message, validate, onInvalid }: CustomValidateStep) {
    return newRule({ message, onInvalid, check: validate(value) });
  }

  return {
    string,
    number,
    min8digit,
    required,
    email,
    array,
    boolean,
    custom,
    cpf,
    date,
    isValid,
    errorMessage,
  };
}

export function validateSchema() {}

export function validateCPF(strCPF: string) {
  let Soma = 0;
  let Resto: number;
  if (typeof strCPF !== "string") return false;
  if (strCPF === "00000000000") return false;
  strCPF = strCPF.replace(/\D/g, "");
  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto == 10 || Resto == 11) Resto = 0;
  if (Resto != parseInt(strCPF.substring(10, 11))) return false;
  return true;
}
