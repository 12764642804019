import axios from "axios";
import { getToken, removeToken } from "../Session";

const api = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  headers: { "Content-Type": "application/json" },
});

api.interceptors.request.use((config) => {
  if (config?.url !== "/usuarios/login") {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Basic ${token}`;
    }
  }
  return config;
});

api.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    if (
      error.config?.url !== "/usuarios/login" &&
      401 === error.response?.status
    ) {
      alert("Sem autorização de acesso!");
      removeToken();
      window.location.href = "/login";
      return Promise.reject(error);
    } else if (
      error.config?.url !== "/usuarios/login" &&
      403 === error.response?.status
    ) {
      alert("Sem permissão!");
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default api;
