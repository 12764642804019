import { Icon } from "@chakra-ui/react";

export function LogoutIcon({ ...rest }) {
  return (
    <Icon
      width="27px"
      height="27px"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M26.4167 13.5L21.25 8.33331V12.2083H10.9167V14.7916H21.25V18.6666L26.4167 13.5ZM23.8334 21.25C22.2068 23.4188 19.939 25.0208 17.3514 25.8291C14.7637 26.6374 11.9873 26.6111 9.41543 25.7538C6.84357 24.8965 4.60664 23.2518 3.0215 21.0525C1.43636 18.8532 0.583374 16.211 0.583374 13.5C0.583374 10.789 1.43636 8.14674 3.0215 5.94747C4.60664 3.74821 6.84357 2.10344 9.41543 1.24615C11.9873 0.388866 14.7637 0.36252 17.3514 1.17085C19.939 1.97917 22.2068 3.58119 23.8334 5.74998H20.3071C18.8157 4.43463 16.9763 3.57758 15.0098 3.28168C13.0433 2.98578 11.0332 3.26359 9.22074 4.08179C7.40824 4.89998 5.87032 6.2238 4.79154 7.89439C3.71275 9.56497 3.13893 11.5114 3.13893 13.5C3.13893 15.4886 3.71275 17.435 4.79154 19.1056C5.87032 20.7762 7.40824 22.1 9.22074 22.9182C11.0332 23.7364 13.0433 24.0142 15.0098 23.7183C16.9763 23.4224 18.8157 22.5653 20.3071 21.25H23.8334Z"
        fill="currentColor"
      />
    </Icon>
  );
}
export function LogoutIcon2({ ...rest }) {
  return (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="17px"
      width="17px"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path fill="none" d="M0 0h24v24H0V0z"></path>
      <path d="M17 8l-1.41 1.41L17.17 11H9v2h8.17l-1.58 1.58L17 16l4-4-4-4zM5 5h7V3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h7v-2H5V5z"></path>
    </svg>
  );
}
