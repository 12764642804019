import { Button, ButtonProps, Flex } from "@chakra-ui/react";
import { CustomModal } from "components/custom-modal";

import { useState } from "react";
import { createEventListener, makeEvent } from "services/events";

export type ModalConfirmOptions = {
  onConfirm?: () => any;
  onOpen?: () => any;
  onClose?: () => any;
  onReject?: () => any;
  message?: string | JSX.Element;
  title?: string;
  confirmButtonStyle?: ButtonProps;
  rejectButtonStyle?: ButtonProps;
};

export function ModalConfirm() {
  const [isLoading, setIsLoading] = useState(false);
  const [modalConfirmOptions, setModalConfirmOptions] =
    useState<ModalConfirmOptions | null>(null);

  createEventListener("open-modal-confirm", (data: ModalConfirmOptions) => {
    if (modalConfirmOptions !== null) {
      new Error("The confirmation modal is already open");
    } else setModalConfirmOptions(data);
  });

  function onCloseModal() {
    modalConfirmOptions?.onClose?.();
    setModalConfirmOptions(null);
  }

  const modalFooter = modalConfirmOptions ? (
    <>
      <Button
        variant="outline"
        onClick={() => {
          modalConfirmOptions.onReject?.();
          onCloseModal();
        }}
        children="Não"
        {...modalConfirmOptions.rejectButtonStyle}
      />
      <Button
        variant="danger"
        onClick={async () => {
          setIsLoading(true);
          try {
            await modalConfirmOptions.onConfirm?.();
          } catch {}
          setIsLoading(false);
          onCloseModal();
        }}
        isLoading={isLoading}
        children="Sim"
        {...modalConfirmOptions.confirmButtonStyle}
      />
    </>
  ) : undefined;

  return (
    <>
      <CustomModal
        isOpen={modalConfirmOptions !== null}
        onClose={onCloseModal}
        modalFooter={modalFooter}
        modalTitle={modalConfirmOptions?.title ?? "Confirmação"}
        size="md"
      >
        <Flex alignItems="center" gap="10px">
          {modalConfirmOptions?.message ?? "Confirmar Operação?"}
        </Flex>
      </CustomModal>
    </>
  );
}

export function openModalConfirm(options: ModalConfirmOptions) {
  makeEvent("open-modal-confirm", options);
}
