import { Icon, IconProps } from "@chakra-ui/react";

export function ChevronDownIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="12px"
      height="8px"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.59 0.589996L6 5.17L1.41 0.589996L0 2L6 8L12 2L10.59 0.589996Z"
        fill="currentColor"
      />
    </Icon>
  );
}
