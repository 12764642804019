import { CheckIcon } from "@chakra-ui/icons";
import { BoxProps, Flex, FlexProps, Text, TextProps } from "@chakra-ui/react";
import { Popover, PopoverProps } from "components/popover";
import { ReactNode, memo, useEffect, useRef, useState } from "react";

export interface CheckboxProps extends Omit<BoxProps, "onChange" | "p"> {
  onChange?: (isChecked: boolean) => void;
  isChecked?: boolean;
  isDisabled?: boolean;
  p?: string;
  children?: JSX.Element | ReactNode;
  boxProps?: FlexProps;
  checkedIcon?: (isChecked: boolean) => JSX.Element | ReactNode;
  popoverProps?: PopoverProps;
  errorMessage?: string;
  messageErrorProps?: TextProps;
  disabled?: boolean;
}

export function Checkbox({
  onChange,
  isChecked,
  p = "4px",
  isDisabled,
  children,
  boxProps,
  checkedIcon,
  errorMessage,
  popoverProps,
  messageErrorProps,
  disabled,
  ...rest
}: CheckboxProps) {
  const [isOpen, setIsOpen] = useState(false);
  const checkRef = useRef<SVGSVGElement>(null);

  let isInvalid = false;
  if (errorMessage) isInvalid = true;
  if (disabled != null) isDisabled = disabled;

  useEffect(() => {
    if (!errorMessage) setIsOpen(false);
  }, [!!errorMessage]);

  return (
    <Popover
      button={
        <Flex
          p={p}
          onClick={(e) => {
            e.stopPropagation();
            if (isDisabled) return;
            const el = checkRef.current!;
            const { style } = el;
            const isChecked = window.getComputedStyle(el).opacity === "1";
            if (isChecked) {
              if (isChecked === undefined) style.opacity = "0";
              onChange?.(false);
            } else {
              if (isChecked === undefined) style.opacity = "1";
              onChange?.(true);
            }
          }}
          onMouseEnter={() => errorMessage && setIsOpen(true)}
          onMouseLeave={() => errorMessage && setIsOpen(false)}
          alignItems="center"
          cursor="pointer"
          userSelect="none"
          borderRadius="6px"
          border={
            isInvalid
              ? "3px solid var(--chakra-colors-secondary-600)"
              : undefined
          }
          opacity={isDisabled ? 0.4 : 1}
          pointerEvents={isDisabled ? "none" : "auto"}
          {...rest}
        >
          <Flex
            w="16px"
            h="16px"
            border="1px solid var(--chakra-colors-gray-300)"
            justifyContent="center"
            alignItems="center"
            mr={children ? "8px" : undefined}
            {...boxProps}
          >
            {checkedIcon?.(!!isChecked) ?? (
              <CheckIcon
                ref={checkRef}
                opacity={isChecked ? "1" : "0"}
                w="10px"
                h="auto"
              />
            )}
          </Flex>

          {children}
        </Flex>
      }
      isOpen={isOpen}
      customArrowPos={{
        right: "80%",
        transform: "translate(50%, calc(-100% + 1px))",
      }}
      popupStyles={{ width: "100%" }}
      variant="danger"
      {...popoverProps}
    >
      <Text
        color="#fff"
        w="100%"
        textAlign="start"
        fontWeight="bold"
        fontSize="13"
        {...messageErrorProps}
      >
        {errorMessage}&nbsp;
      </Text>
    </Popover>
  );
}

export default memo(Checkbox);
