import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import api from "api/api";
import { defaultScroll, toastDefaultStyle } from "chakra/theme";
import { filterArrayBy } from "utils/filter-array-by";
import { useApplicationContext } from "contexts/ApplicationContext";
import {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { makeEvent } from "services/events";
import { getRelativeRect } from "utils/relative-rect";
import { updateWalletTable } from "../wallet-table";

interface Partner {
  id: number;
  codigoNova: string;
  name: string;
}

export function PartnersSearch({
  selectedCustomer,
}: {
  selectedCustomer: { id: number; name: string };
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [allPartners, setAllPartners] = useState<Partner[]>([]);
  const [result, setResult] = useState<Partner[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const partnerListRef = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);
  const { user } = useApplicationContext();
  const userType = user.userData.type;
  const isOpened = isOpen && result.length;

  const toast = useToast();
  const isVisible = ["SUPER", "FINANCEIRO"].includes(userType!);

  const getPartners = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`/customers/short`);
      setAllPartners(data);
    } catch {
      toast({
        title: "Erro ao carregar dados",
        status: "error",
        ...toastDefaultStyle,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleScroll = (element: HTMLElement, parent: HTMLElement) => {
    const relRef = getRelativeRect(element, parent);
    const elHeight = element.clientHeight;
    const top = relRef.top + parent.scrollTop!;
    const scrollPos = parent.scrollTop! + parent.clientHeight;
    if (top + elHeight > scrollPos)
      parent.scrollTop = top + elHeight - parent.clientHeight;
    if (top < parent.scrollTop) parent.scrollTop = top;
  };

  const handleKeyPress = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    const list = partnerListRef.current;
    if (key === "Escape") setIsOpen(false);
    else if (result.length) setIsOpen(true);

    if (key === "Enter" && isOpened) {
      const currResult = result[selectedIndex];
      if (currResult) updateWalletTable(currResult);
    } else if (key === "ArrowUp" && isOpened) {
      const newCurrIndex = selectedIndex - 1;
      const currResult = result[newCurrIndex];
      if (!currResult) {
        const selectedHtml = list!.children[
          result.length - 1
        ] as HTMLDivElement;
        handleScroll(selectedHtml, list!);
        setSelectedIndex(result.length - 1);
        input.current!.value = "";
      } else {
        const selectedHtml = list!.children[newCurrIndex] as HTMLDivElement;
        handleScroll(selectedHtml, list!);
        setSelectedIndex(newCurrIndex);
        input.current!.value = "";
      }
    } else if (key === "ArrowDown" && isOpened) {
      const newCurrIndex = selectedIndex + 1;
      const currResult = result[newCurrIndex];
      if (!currResult) {
        const selectedHtml = list!.children[0] as HTMLDivElement;
        handleScroll(selectedHtml, list!);
        setSelectedIndex(0);
        input.current!.value = "";
      } else {
        const selectedHtml = list!.children[newCurrIndex] as HTMLDivElement;
        handleScroll(selectedHtml, list!);
        setSelectedIndex(newCurrIndex);
        input.current!.value = "";
      }
    }
  };

  useEffect(() => {
    getPartners();
  }, []);

  useEffect(() => {
    if (allPartners.length) {
      const userData = allPartners.find(
        (customer) => customer.id === user.userData.customerId
      );
      updateWalletTable(userData!);
    }
  }, [allPartners]);

  return isVisible ? (
    <Box w="100%" gap="16px" mb="15px">
      <Text mb="8px">Filtrar por parceiros</Text>
      <Box w="100%" pos="relative">
        <InputGroup>
          <Input
            ref={input}
            onBlur={() => setIsOpen(false)}
            onFocus={() => setIsOpen(true)}
            bg="#fff"
            onChange={async ({ target }) => {
              if (target.value) {
                setResult(
                  filterArrayBy(allPartners, target.value, [
                    "name",
                    "codigoNova",
                  ]).slice(0, 20)
                );
              } else setResult([]);
              setSelectedIndex(-1);
            }}
            isDisabled={isLoading}
            onKeyDown={handleKeyPress}
          />
          <InputRightElement>
            {isLoading ? <Spinner w="18px" h="18px" /> : <SearchIcon />}
          </InputRightElement>
        </InputGroup>

        {isOpened ? (
          <Box
            ref={partnerListRef}
            pos="absolute"
            bottom="-2px"
            left="0"
            right="0"
            zIndex="1"
            transform="translateY(100%)"
            bg="#fff"
            borderRadius="6px"
            overflow="auto"
            border="1px solid var(--chakra-colors-gray-200)"
            maxH={"240px"}
            sx={defaultScroll}
            onMouseDown={(e) => e.preventDefault()}
          >
            {result.map(({ id, codigoNova, name }, index) => {
              const isActive = selectedCustomer.id === id;
              const isSelected = index === selectedIndex;
              return (
                <Box
                  p="5px 10px"
                  key={id}
                  w="100%"
                  _hover={{ bg: "gray.100" }}
                  bg={
                    isActive ? "gray.200" : isSelected ? "gray.100" : undefined
                  }
                  cursor="pointer"
                  onClick={(e: any) => {
                    updateWalletTable({ id, name, codigoNova });
                    setIsOpen(false);
                    setSelectedIndex(index);
                    input.current!.value = "";
                    e.currentTarget.focus();
                  }}
                  textAlign="start"
                  as="button"
                >
                  {`${codigoNova ? `${codigoNova}  - ` : ""}${name}`}
                </Box>
              );
            })}
          </Box>
        ) : null}
      </Box>
    </Box>
  ) : null;
}
