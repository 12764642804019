import {
  Box,
  Button,
  Grid,
  Input,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { Column } from "components/dynamic-table/types";
import { useLayoutEffect, useState } from "react";
import { phoneFormat } from "utils/string-formats";
import { Attendance } from "../atendimento-form/types";
import { SearchIcon } from "components/vectors/search-icon";
import { createEventListener } from "services/events";
import DynamicTable from "components/dynamic-table";

interface SearchForCreateAttendanceProps {
  makeAttendance: (data: Attendance) => void;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}

export const SearchForCreateAttendance = ({
  makeAttendance,
  isOpen,
  onClose,
  onOpen,
}: SearchForCreateAttendanceProps) => {
  const [atendimentosTemplates, setAtendimentosTemplates] = useState([]);
  const [nbOrCpfOrTelefoneToSearch, setNbOrCpfOrTelefoneToSearch] =
    useState("");
  const [isSearching, setIsSearching] = useState(false);
  const toast = useToast();

  const searchAtendimentosTemplates = async () => {
    if (nbOrCpfOrTelefoneToSearch.length < 5) {
      toast({
        title: "Informe um benefício ou CPF ou telefone",
        status: "error",
        ...toastDefaultStyle,
      });
      return;
    }
    setIsSearching(true);
    let response = await api.get(
      "/atendimentos/templates?nbOrCpfOrTelefone=" +
        nbOrCpfOrTelefoneToSearch.replace(/\D/g, "")
    );
    setAtendimentosTemplates(response.data);
    setIsSearching(false);
  };

  useLayoutEffect(() => {
    if (isOpen) {
      setNbOrCpfOrTelefoneToSearch("");
      setAtendimentosTemplates([]);
    }
  }, [isOpen]);

  const columns: Column[] = [
    {
      name: "Convênio",
      key: "convenio",
      sortClick: true,
    },
    {
      name: "Código",
      render: (row) => {
        if ("SIAPE" === row.convenio) {
          return row.matricula;
        } else if ("INSS" === row.convenio) {
          return row.nb;
        } else {
          return "";
        }
      },
    },
    {
      name: "CPF",
      key: "cpf",
    },
    {
      name: "Nome",
      key: "nome",
    },
    {
      name: "WhatsApp",
      key: "telefoneContatoNormalizado",
      render: (row) => <>{phoneFormat(row.telefoneContatoNormalizado)}</>,
    },
    {
      name: "Ações",
      render: (row) => (
        <Link
          href="#"
          onClick={(e) => {
            e.stopPropagation();
            makeAttendance(row);
            onClose();
          }}
        >
          Novo Atendimento
        </Link>
      ),
    },
  ];

  createEventListener("openNewAttendanceModal", onOpen);

  return (
    <Box w="100%">
      <Box py="20px">
        <Text mb="0.5rem">Informe o benefício ou CPF ou telefone</Text>
        <Grid templateColumns="7fr 3fr" gap="16px" w="100%">
          <Input
            value={nbOrCpfOrTelefoneToSearch || ""}
            onKeyDown={(e) => {
              if (e.key === "Enter") searchAtendimentosTemplates();
            }}
            onChange={(e) => setNbOrCpfOrTelefoneToSearch(e.target.value)}
          />
          <Button
            leftIcon={<SearchIcon stroke="#fff" />}
            onClick={searchAtendimentosTemplates}
            isLoading={isSearching}
            loadingText="Pesquisando"
          >
            Pesquisar
          </Button>
        </Grid>
      </Box>
      <DynamicTable
        rows={atendimentosTemplates}
        columns={columns}
        onClickRow={(row) => {
          makeAttendance(row);
          onClose();
        }}
        containerStyle={{
          boxShadow: "",
          border: "1px solid var(--chakra-colors-gray-200)",
        }}
        isLoading={isSearching}
        emptyMessage="Não encontrado."
        size="md"
      />
    </Box>
  );
};
