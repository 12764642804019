import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Grid,
  Input,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { CustomModal } from "components/custom-modal";
import { Dropdown } from "components/dropdown";
import { DropdownField } from "components/dropdown-field";
import { InputField } from "components/input-field";
import { DraggableFileField } from "components/input-file";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { createEventListener, makeEvent } from "services/events";
import { fieldValidation } from "utils/field-validation";
import { objectSchema } from "utils/object-methods";
import { CampanhaAudio } from "../campanha-whatsapp-audio";
import { SendIcon } from "components/vectors/send-icon";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { MensagemFields } from "./mensagem-fields";
import { CampanhaSMSRow } from "pages/campanha-sms";

interface WhatsAppSession {
  id: number;
  nome: string;
  whatsappSessionId: string;
  customerName?: string;
  customerCodigoNova?: string;
}

export interface ModalCampanhaWhatsAppData {
  id?: number;
  file?: File;
  filename?: string;
  nome?: string;
  tipo?: "WP_TEXT_MSG" | "WP_AUDIO_MSG";
  mensagem?: string;
  mensagemSaudacao?: string;
  enabledMensagemSaudacao: boolean;
  qtdEstimadaEnvioDia: number;
  columnsValues?: any;
  fonesColunas?: string[];
  whatsAppSessionsParticipantes: WhatsAppSession[];
  paused: boolean;
}

export function ModalCampanhaWhatsApp({
  loadCampanhas,
  setCampanhas,
  getCampanhaStatus,
}: {
  loadCampanhas: () => Promise<void>;
  getCampanhaStatus: (
    e: any
  ) =>
    | "PAUSADA"
    | "CONFIGURACAO PENDENTE"
    | "FINALIZADA"
    | "EM ANDAMENTO"
    | undefined;
  setCampanhas: Dispatch<SetStateAction<ModalCampanhaWhatsAppData[]>>;
}) {
  const [modalData, setModalData] = useState<
    Partial<ModalCampanhaWhatsAppData>
  >({});
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [isSendingFile, setIsSendingFile] = useState(false);
  const [isDisabledFields, setIsDisabledFields] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [loadingPhones, setLoadingPhones] = useState(false);
  const [whatsAppPhones, setWhatsAppPhones] = useState<WhatsAppSession[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const toast = useToast();
  const {
    isValid,
    fieldsErrors,
    body,
    errors: campanhaErrors,
  } = objectSchema(
    {
      id: "number",
      nome: (value) => {
        const message = "Campo Obrigatório";
        const check = fieldValidation({ value }).required({ message });
        return { valid: check.isValid, message: check.errorMessage };
      },
      mensagem: (value) => {
        const message =
          modalData.tipo === "WP_AUDIO_MSG"
            ? "Insira um arquivo MP3"
            : "Campo Obrigatório";
        const check = fieldValidation({ value }).required({ message });
        return { valid: check.isValid, message: check.errorMessage };
      },
      enabledMensagemSaudacao: (value) => {
        const check = fieldValidation({ value }).boolean();
        return { valid: check.isValid };
      },
      mensagemSaudacao: (value) => {
        const message = "Campo Obrigatório";
        const check = fieldValidation({ value }).required({ message });
        return {
          valid: modalData.enabledMensagemSaudacao ? check.isValid : true,
          message: check.errorMessage,
        };
      },
      fonesColunas: (value) => {
        const message = "Escolha pelo menos uma coluna de telefone";
        const check = fieldValidation({ value }).array().required({ message });
        return { valid: check.isValid, message: check.errorMessage };
      },
      whatsAppSessionsParticipantes: (value) => {
        const message = "Escolha pelo menos uma sessão whatsapp";
        const check = fieldValidation({ value }).array().required({ message });
        return { valid: check.isValid, message: check.errorMessage };
      },
      tipo: (value) => {
        const valid = ["WP_TEXT_MSG", "WP_AUDIO_MSG"].includes(value);
        return { valid, message: "Selecione o tipo da campanha" };
      },
      qtdEstimadaEnvioDia: (value) => {
        const message = "Quantidade estimada envio por dia não pode ser zero";
        const check = fieldValidation({ value }).required({ message });
        return { valid: check.isValid, message: check.errorMessage };
      },
    },
    modalData
  );

  const newCampanhaCheck = objectSchema(
    {
      file: (value) => ({ valid: !!value, message: "Insira um Arquivo CSV" }),
      nome: (value) => {
        const message = "Insira o nome da campanha";
        const check = fieldValidation({ value }).required({ message });
        return { valid: check.isValid, message: check.errorMessage };
      },
    },
    modalData
  );

  const errors = modalData.id ? campanhaErrors : newCampanhaCheck.errors;

  const onChangeCampanha = async (
    field:
      | keyof ModalCampanhaWhatsAppData
      | (keyof ModalCampanhaWhatsAppData)[],
    value: any
  ) => {
    if (Array.isArray(field)) {
      let newValue = { ...modalData };
      let newErrors = [...invalidFields];
      field.forEach((key, index) => {
        newErrors = newErrors.filter((curr) => curr !== key);
        newValue[key] = value[index];
      });
      setModalData(newValue);
      setInvalidFields(newErrors);
    } else {
      setInvalidFields(invalidFields.filter((curr) => curr !== field));
      setModalData({ ...modalData, [field]: value });
    }
  };

  const onOpen = (modalData: ModalCampanhaWhatsAppData) => {
    setIsOpen(true);
    setModalData(modalData ?? {});
    if (modalData?.id) {
      const status = getCampanhaStatus(modalData);
      if (status === "EM ANDAMENTO") {
        setIsDisabledFields(true);
        toast({
          title: "É necessário pausar a campanha para altera-la",
          ...toastDefaultStyle,
        });
      }
    }
  };
  const onClose = () => {
    setIsOpen(false);
    setIsDisabledFields(false);
    setInvalidFields([]);
    setTimeout(() => setModalData({}), 300);
  };

  const selectColumnFones = (value: string) => {
    const target = modalData.fonesColunas ?? [];
    if (target?.length) {
      const isInclude = target.includes(value);
      if (isInclude)
        onChangeCampanha(
          "fonesColunas",
          target.filter((curr) => curr !== value)
        );
      else onChangeCampanha("fonesColunas", [...target, value]);
    } else onChangeCampanha("fonesColunas", [value]);
  };

  createEventListener("openModalCampanhaWhatsApp", onOpen);

  const selectWhatsAppSessions = (value: number) => {
    const target = modalData.whatsAppSessionsParticipantes ?? [];
    if (target?.length) {
      const isInclude = target.find(({ id }) => id === value);
      if (isInclude)
        onChangeCampanha(
          "whatsAppSessionsParticipantes",
          target.filter(({ id }) => id !== value)
        );
      else
        onChangeCampanha("whatsAppSessionsParticipantes", [
          ...target,
          { id: value },
        ]);
    } else onChangeCampanha("whatsAppSessionsParticipantes", [{ id: value }]);
  };

  const selectedSessionsLength =
    modalData.whatsAppSessionsParticipantes?.length ?? 0;

  const whatsAppSessionsLabel =
    selectedSessionsLength > 1
      ? `${selectedSessionsLength} Participantes`
      : whatsAppPhones.find(
          ({ id }) => id === modalData.whatsAppSessionsParticipantes?.[0]?.id
        )?.nome ?? "Selecione";

  const getPhones = async () => {
    setLoadingPhones(true);
    try {
      const { data } = await api.get("/whatsapp-sessions");
      setWhatsAppPhones(data);
    } catch (e) {
    } finally {
      setLoadingPhones(false);
    }
  };

  useEffect(() => {
    getPhones();
  }, []);

  const handlePostCampanha = async () => {
    if (!newCampanhaCheck.isValid) {
      setInvalidFields([...newCampanhaCheck.fieldsErrors]);
      return toast({
        title: "Preencha os campos corretamente",
        status: "error",
        ...toastDefaultStyle,
      });
    }
    setIsSaving(true);
    let formData = new FormData();
    formData.append("files", modalData.file!);
    formData.append("filename", modalData.filename!);
    formData.append("nome", modalData.nome!);
    try {
      const { data } = await api.post("/campanhas-nova", formData);
      setModalData(data);
      await loadCampanhas();
    } catch (error: any) {
      const errorTypes: any = {
        zero_lines: "Erro ao criar campanha: Arquivo não possui contatos!",
        limit_20000_lines:
          "Erro ao criar campanha: Arquivo exceceu limite de 20 mil contatos!",
        column_cpf_not_found:
          "Erro ao criar campanha: Arquivo não possui coluna CPF!",
        duplicate_column_cpf_found:
          "Erro ao criar campanha: Arquivo possui mais de uma coluna CPF!",
      };
      const errorMessage =
        errorTypes[error?.response?.data?.message] ?? "Erro ao criar campanha!";
      toast({ title: errorMessage, status: "error", ...toastDefaultStyle });
    } finally {
      setIsSaving(false);
    }
  };

  const handlePutCampanha = async () => {
    if (!isValid) {
      setInvalidFields([...fieldsErrors]);
      return toast({
        title: "Preencha os campos corretamente",
        status: "error",
        ...toastDefaultStyle,
      });
    }
    setIsSaving(true);
    try {
      const response = await api.put(`/campanhas-nova/${modalData.id}`, body);
      await loadCampanhas();
      onClose();
    } catch (error: any) {
      toast({
        title: "Erro ao atualizar campanha!",
        status: "error",
        ...toastDefaultStyle,
      });
    } finally {
      setIsSaving(false);
    }
  };

  const campanhaDialogFooter = (
    <>
      {modalData.id ? (
        <Button
          isLoading={isSaving}
          leftIcon={<CheckIcon />}
          onClick={handlePutCampanha}
          loadingText="Salvando"
          isDisabled={isDisabledFields}
        >
          Salvar
        </Button>
      ) : (
        <Button
          disabled={!newCampanhaCheck.isValid || isSendingFile}
          leftIcon={<SendIcon />}
          onClick={handlePostCampanha}
          isLoading={isSaving}
          loadingText="Enviando Arquivo"
        >
          Enviar Arquivo
        </Button>
      )}

      <Button
        leftIcon={<CloseIcon w="11px" h="11px" />}
        onClick={onClose}
        variant="outline"
      >
        {modalData.id ? "Fechar" : "Cancelar"}
      </Button>
    </>
  );

  const handleFile = (file: File) => {
    onChangeCampanha(["file", "filename"], [file, file.name]);
  };

  const handleDeleteFile = () => {
    onChangeCampanha(["file", "filename"], [null, ""]);
  };
  let CSVcols = modalData?.columnsValues
    ? Object.keys(modalData?.columnsValues)?.map((key) => ({
        name: key,
        value: key,
      }))
    : [];

  return (
    <CustomModal
      isOpen={isOpen}
      size={modalData?.id ? "3xl" : "lg"}
      modalTitle="Campanha WhatsApp"
      modalFooter={campanhaDialogFooter}
      onClose={onClose}
      maxH="95vh"
      scroll="inside"
    >
      <Box>
        <Grid
          templateColumns={modalData.id != null ? "1fr 1fr" : "1fr"}
          gap="20px"
        >
          <Box>
            <Text mb="8px">Nome da Campanha</Text>
            <InputField
              value={modalData?.nome}
              errorMessage={
                invalidFields.includes("nome") ? errors["nome"] : undefined
              }
              onChange={(e) => onChangeCampanha("nome", e.target.value)}
              inputProps={{ isDisabled: isDisabledFields }}
            />
          </Box>
          {modalData.id != null ? (
            <Box>
              <Text mb="8px">Arquivo</Text>
              <Input id="nomeDialog" value={modalData.filename} disabled />
            </Box>
          ) : null}
        </Grid>
        {modalData.id != null ? (
          <>
            <Box mt="10px">
              <Text mb="8px">Adicionar Mensagem Saudação</Text>
              <Dropdown
                w="100%"
                options={[
                  { name: "Sim", value: true },
                  { name: "Não", value: false },
                ]}
                onChange={(value) =>
                  onChangeCampanha("enabledMensagemSaudacao", value)
                }
                value={modalData.enabledMensagemSaudacao}
                isDisabled={isDisabledFields}
              />
            </Box>
            {modalData.enabledMensagemSaudacao ? (
              <MensagemFields
                CSVcols={CSVcols}
                modalData={modalData}
                objectKey="mensagemSaudacao"
                errors={errors}
                invalidFields={invalidFields}
                onChangeCampanha={onChangeCampanha}
                isDisabledFields={isDisabledFields}
              />
            ) : null}
          </>
        ) : null}
        {!modalData.id && (
          <DraggableFileField
            error={invalidFields.includes("file") ? errors["file"] : ""}
            fieldTitle="Arquivo CSV"
            file={modalData.file}
            handleFile={handleFile}
            handleDelete={handleDeleteFile}
            isSending={isSendingFile}
            description={
              <Text userSelect="none">
                Arraste um arquivo CSV para cá ou <b>clique para inserir</b>
              </Text>
            }
            disabled={isDisabledFields}
          />
        )}
        {modalData.id && (
          <>
            <Grid templateColumns="1fr 1fr" mt="10px" w="100%" gap="20px">
              <Box>
                <Text mb="8px">Tipo da mensagem</Text>
                <DropdownField
                  dropdownProps={{ w: "100%", isDisabled: isDisabledFields }}
                  options={[
                    { name: "Texto", value: "WP_TEXT_MSG" },
                    { name: "Áudio", value: "WP_AUDIO_MSG" },
                  ]}
                  value={modalData.tipo}
                  onChange={(value) => {
                    onChangeCampanha(["tipo", "mensagem"], [value, ""]);
                  }}
                  errorMessage={
                    invalidFields.includes("tipo") ? errors["tipo"] : undefined
                  }
                />
              </Box>
              <Box>
                <Text mb="8px">Qtd. estimada de envio por dia</Text>
                <InputField
                  value={modalData.qtdEstimadaEnvioDia?.toString() ?? ""}
                  errorMessage={
                    invalidFields.includes("qtdEstimadaEnvioDia")
                      ? errors["qtdEstimadaEnvioDia"]
                      : undefined
                  }
                  onChange={(e) => {
                    const parse = Number(e.target.value);
                    if (parse > 500) e.currentTarget.value = "500";
                    onChangeCampanha("qtdEstimadaEnvioDia", e.target.value);
                  }}
                  inputProps={{ type: "number", isDisabled: isDisabledFields }}
                />
              </Box>
            </Grid>
            {!modalData.tipo ? null : modalData.tipo === "WP_TEXT_MSG" ? (
              <MensagemFields
                isDisabledFields={isDisabledFields}
                CSVcols={CSVcols}
                modalData={modalData}
                errors={errors}
                invalidFields={invalidFields}
                onChangeCampanha={onChangeCampanha}
              />
            ) : (
              <CampanhaAudio
                onChangeCampanha={onChangeCampanha}
                error={
                  invalidFields.includes("mensagem") ? errors["mensagem"] : ""
                }
                campanha={modalData}
                isDisabled={isDisabledFields}
              />
            )}
            <Grid templateColumns="1fr 1fr" mt="10px" w="100%" gap="20px">
              <Box>
                <Text mb="8px">Selecione Colunas Telefone</Text>
                <DropdownField
                  dropdownProps={{
                    w: "100%",
                    multiSelect: true,
                    openTop: true,
                    isDisabled: isDisabledFields,
                  }}
                  options={CSVcols}
                  value={modalData.fonesColunas}
                  onChange={selectColumnFones}
                  errorMessage={
                    invalidFields.includes("fonesColunas")
                      ? errors["fonesColunas"]
                      : undefined
                  }
                />
              </Box>
              <Box>
                <Tooltip label="Sessões que serão usadas para o disparo de mensagem">
                  <Text mb="8px">WhatsApp participantes</Text>
                </Tooltip>
                <DropdownField
                  dropdownProps={{
                    w: "100%",
                    options: whatsAppPhones,
                    isLoading: loadingPhones,
                    optionValue: "id",
                    optionLabel: "nome",
                    multiSelect: true,
                    openTop: true,
                    children: whatsAppSessionsLabel,
                    isDisabled: isDisabledFields,
                  }}
                  onChange={selectWhatsAppSessions}
                  value={
                    modalData.whatsAppSessionsParticipantes?.map(
                      ({ id }) => id
                    ) || []
                  }
                  errorMessage={
                    invalidFields.includes("whatsAppSessionsParticipantes")
                      ? errors["whatsAppSessionsParticipantes"]
                      : undefined
                  }
                />
              </Box>
            </Grid>
          </>
        )}
      </Box>
    </CustomModal>
  );
}

export const openModalCampanhaWhatsApp = (
  modalData: ModalCampanhaWhatsAppData
) => makeEvent("openModalCampanhaWhatsApp", modalData);
