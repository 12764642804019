import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Text,
  useToast,
} from "@chakra-ui/react";
import { TabDataProps } from "../../../consts";
import { useState } from "react";
import { InputField } from "components/input-field";
import { DropdownField } from "components/dropdown-field";
import {
  checkFieldsDigitacaoFacta,
  copyAttendanceDataToFactaForm,
  digitacaoPropostaErrors,
  fieldsDigitacaoFGTSFacta,
  mapeamentoConsultaPropostaFGTS,
} from "./const";
import OptimizedInput from "components/performance/attendance-input";
import { SendIcon } from "components/vectors/send-icon";
import { toastDefaultStyle } from "chakra/theme";
import { useAttendanceContext } from "contexts/AttendanceContext";
import {
  Attendance,
  ConsultaPropostaFGTS,
  DigitationDataProps,
} from "components/atendimentos-components/atendimento-form/types";
import api from "api/api";
import { CopyIcon } from "@chakra-ui/icons";
import { InputSearch } from "components/input-search";
import { ButtonAcompanharProposta } from "./modal-observacao";
import { DocumentIcon } from "components/vectors/document-icon";

export function DigitacaoAntecipacaoFGTSColombo({
  onChangeField,
  resetFieldsTabelaDigitacao,
}: TabDataProps) {
  const [consultandoProposta, setConsultandoProposta] = useState(false);
  const [isLoadingDigitation, setIsLoadingDigitation] = useState(false);
  const [errors, setErrors] = useState<{ [k: string]: string }>({});
  const { formControl, dispatch } = useAttendanceContext();
  const toast = useToast({ ...toastDefaultStyle });
  const formValues = formControl.values as Attendance;
  let digitationData = formValues?.formularioFactaFGTS ?? {};
  const isDisabledFactaFields =
    !!formValues.atendimentoFactaFGTS?.hasTokenDigitacao;

  const onChangeDigitationData = (
    field: keyof DigitationDataProps,
    data: any
  ) => {
    const newValue = { ...digitationData, [field]: data };
    setErrors((errors) => {
      delete errors[field];
      return errors;
    });
    digitationData = newValue;
    dispatch({
      type: "changeField",
      payload: {
        fieldName: "formularioFactaFGTS",
        data: newValue,
      },
    });
  };

  const digitarProposta = async () => {
    if (!formValues.atendimentoFactaFGTS?.valorLiberadoAntecipacao) {
      return toast({
        title: "Faça uma consulta FGTS para fazer a digitação",
      });
    }
    const { errors, isValid } = checkFieldsDigitacaoFacta(digitationData);
    if (!isValid) {
      toast({ title: "Preencha todos os campos", status: "error" });
      return setErrors(errors);
    }
    if (formControl.pendingChanges)
      return toast({
        title: "Salve as alterações do atendimento para fazer a digitação",
      });
    setIsLoadingDigitation(true);
    try {
      const { data } = await api.get(
        `/facta/fgts/enviar-proposta/${formValues.id}`
      );
      toast({
        title: "Proposta em digitação",
        description: "Consulte em instantes.",
      });
      const atendimentoFactaFGTS = {
        ...formValues.atendimentoFactaFGTS,
        hasTokenDigitacao: true,
      };

      dispatch({
        type: "changeFieldWithInitial",
        payload: {
          data: atendimentoFactaFGTS,
          fieldName: "atendimentoFactaFGTS",
        },
      });
    } catch (e: any) {
      const error = e?.response?.data?.message;
      toast({
        title: digitacaoPropostaErrors.get(error) ?? "Erro ao digitar proposta",
        status: "error",
      });
    } finally {
      setIsLoadingDigitation(false);
    }
  };

  const consultarProposta = async () => {
    setConsultandoProposta(true);
    try {
      const { data }: { data: ConsultaPropostaFGTS } = await api.get(
        `/facta/fgts/consultar-proposta/${formValues.id}`
      );
      mapeamentoConsultaPropostaFGTS({ data, dispatch, formValues });
      if (data.statusDigitacao === "PENDING") {
        toast({
          title: "Digitação ainda está em andamento",
          status: "error",
        });
      } else if (data.statusDigitacao === "FINISHED") {
        if (data.codeRequestDigitacao === "SYSTEM_ERROR")
          toast({
            title: "Ocorreu um erro na digitação da proposta",
            status: "error",
          });
      }
    } catch (e) {
      toast({
        title: "Erro ao consultar proposta",
        description: "Tente novamente",
      });
    } finally {
      setConsultandoProposta(false);
    }
  };

  return (
    <Grid templateColumns="1fr" gap="16px">
      <Grid templateColumns="1fr 1fr 1fr" gap="16px">
        <Box>
          <Text mb="4px">Banco Digitação</Text>
          <OptimizedInput
            value={"FACTA"}
            fieldName="bancoDigitacao"
            isReadOnly
          />
        </Box>
        <Box>
          <Text mb="4px">&nbsp;</Text>
          <Button
            w="100%"
            rightIcon={<SendIcon />}
            onClick={digitarProposta}
            isLoading={isLoadingDigitation}
            loadingText="Digitando Proposta"
            isDisabled={isDisabledFactaFields}
          >
            Digitar Proposta
          </Button>
        </Box>
        <Box>
          <Text mb="4px">&nbsp;</Text>
          <Button
            w="100%"
            rightIcon={<DocumentIcon width="22px" height="22px" />}
            onClick={consultarProposta}
            isLoading={consultandoProposta}
            loadingText="Consultando Proposta"
            isDisabled={
              !!formValues?.atendimentoFactaFGTS?.ade ||
              !formValues.atendimentoFactaFGTS?.hasTokenDigitacao
            }
          >
            Consultar Proposta
          </Button>
        </Box>
      </Grid>
      <Grid templateColumns="repeat(3, 1fr)" gap="16px">
        <Box gridArea="auto / span 1">
          <Text mb="4px">ADE</Text>
          <OptimizedInput
            value={formValues?.atendimentoFactaFGTS?.ade || ""}
            isReadOnly
          />
        </Box>
        <Box gridArea="auto / span 1">
          <Text mb="4px">Link Formalização</Text>
          <Flex alignItems="center" gap="10px">
            <OptimizedInput
              value={formValues?.atendimentoFactaFGTS?.formalizationLink || ""}
              isReadOnly
            />
          </Flex>
        </Box>
        <Box gridArea="auto / span 1">
          <Text mb="4px">Observação</Text>
          <Flex alignItems="center" gap="10px">
            <ButtonAcompanharProposta attendance={formValues} />
          </Flex>
        </Box>
      </Grid>
      <Grid
        templateColumns="repeat(12, 1fr)"
        gap="16px"
        padding="20px 10%"
        borderRadius="6px"
        border="1px solid var(--chakra-colors-custom-gray)"
        bg="gray.100"
        pos="relative"
      >
        <Center flexDir="column" gridArea="auto / span 12" mb="24px">
          <Text fontSize="20px" fontWeight="medium">
            Formulário {formValues.bancoDigitacao ?? "Banco"}
          </Text>
        </Center>
        {isDisabledFactaFields ? null : (
          <Button
            pos="absolute"
            top="16px"
            right={"10px"}
            leftIcon={<CopyIcon />}
            onClick={() => copyAttendanceDataToFactaForm(formValues, dispatch)}
          >
            Copiar dados do atendimento
          </Button>
        )}
        {fieldsDigitacaoFGTSFacta.map(
          ({
            fieldType,
            key,
            label,
            componentProps,
            format,
            containerProps,
            options,
            onChange,
            ufKey,
            isVisible,
          }) => {
            const currentValue = digitationData?.[key];
            const isVisibleField = isVisible?.(formValues) ?? true;
            if (isVisibleField)
              if (fieldType === "input") {
                const { inputProps, ...rest } = componentProps || {};
                return (
                  <Grid
                    templateColumns="220px auto"
                    alignItems="center"
                    key={key}
                    gridArea="auto / span 12"
                    gap="20px"
                    {...containerProps}
                  >
                    <InputField
                      title={label}
                      titleProps={{
                        mb: "0px",
                        whiteSpace: "nowrap",
                        textAlign: "end",
                      }}
                      inputProps={{ w: "100%", bg: "#fff", ...inputProps }}
                      popoverProps={{ containerStyles: { width: "100%" } }}
                      errorMessage={errors[key]}
                      isDisabled={isDisabledFactaFields}
                      // closeErrorOnBlur={false}
                      onChange={(e) => {
                        if (onChange) {
                          onChange(
                            e.target.value,
                            onChangeDigitationData,
                            digitationData
                          );
                        } else
                          onChangeDigitationData(
                            key,
                            format?.(e.target.value, digitationData).raw ??
                              e.target.value
                          );
                      }}
                      value={
                        format?.(digitationData?.[key], digitationData).value ??
                        digitationData?.[key] ??
                        ""
                      }
                      {...rest}
                    />
                  </Grid>
                );
              } else if (fieldType === "dropdown") {
                const { dropdownProps, ...rest } = componentProps || {};
                return (
                  <Grid
                    templateColumns="220px auto"
                    alignItems="center"
                    key={key}
                    gridArea="auto / span 12"
                    gap="20px"
                    {...containerProps}
                  >
                    <DropdownField
                      title={label}
                      titleProps={{
                        mb: "0px",
                        whiteSpace: "nowrap",
                        textAlign: "end",
                      }}
                      errorMessage={errors[key]}
                      dropdownProps={{
                        w: "100%",
                        containerStyles: { w: "100%" },
                        bg: "#fff",
                        isDisabled: isDisabledFactaFields,
                        ...dropdownProps,
                      }}
                      popoverProps={{ containerStyles: { width: "100%" } }}
                      onChange={(value) => onChangeDigitationData(key, value)}
                      value={
                        format?.(digitationData?.[key], digitationData).value ??
                        digitationData?.[key] ??
                        ""
                      }
                      {...rest}
                    />
                  </Grid>
                );
              } else if (fieldType === "inputSearch") {
                const { inputProps, ...rest } = componentProps || {};
                const id = `InputSearch-${key}`;

                return (
                  <Grid
                    templateColumns="220px auto"
                    alignItems="center"
                    key={key}
                    gridArea="auto / span 12"
                    gap="20px"
                    {...containerProps}
                  >
                    <Text mb="0px" whiteSpace="nowrap" textAlign="end">
                      {label}
                    </Text>
                    <InputSearch
                      onChange={(optionValue: {
                        cityName: string;
                        uf: string;
                      }) => {
                        onChangeDigitationData(key, optionValue.cityName);
                        if (ufKey)
                          onChangeDigitationData(ufKey, optionValue.uf);
                      }}
                      clearInput={false}
                      searchKeys={["name"]}
                      errorMessage={errors[key]}
                      checkIsInclude={(option) =>
                        digitationData?.[key] === option.value.cityName
                      }
                      optionLabelRender={(option) =>
                        `${option.name} - ${option.value.uf}`
                      }
                      value={
                        currentValue && ufKey
                          ? `${currentValue} - ${digitationData[ufKey]}`
                          : undefined
                      }
                      options={options}
                      inputProps={{
                        id,
                        bg: "#fff",
                        onBlur: (e) => {
                          if (
                            ufKey &&
                            digitationData[key] &&
                            digitationData[ufKey]
                          )
                            e.target.value = `${digitationData[key]} - ${digitationData[ufKey]}`;
                          else e.target.value = "";
                        },
                        isDisabled: isDisabledFactaFields,
                        ...inputProps,
                      }}
                      {...rest}
                    />
                  </Grid>
                );
              }
          }
        )}
      </Grid>
    </Grid>
  );
}
