import { Flex, Spinner, Text } from "@chakra-ui/react";
import { useApplicationContext } from "contexts/ApplicationContext";

export function NotFound() {
  const { isLogging } = useApplicationContext();
  return isLogging ? (
    <Flex w="100%" h="100vh" justifyContent="center" alignItems="center">
      <Spinner />
    </Flex>
  ) : (
    <Flex w="100%" h="100vh" justifyContent="center" alignItems="center">
      <Text fontSize="16">
        <Text as="span" fontWeight="bold">
          404
        </Text>{" "}
        Not Found
      </Text>
    </Flex>
  );
}
