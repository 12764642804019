import apiConsulta from "api/api-consulta";
import {
  Attendance,
  AttendanceFactaFGTS,
  ContratoInss,
  ContratoRMCInss,
} from "components/atendimentos-components/atendimento-form/types";
import { openModalConfirm } from "components/modal-confirm-new";
import { openModalError } from "components/modal-error";
import { Dispatch, SetStateAction } from "react";
import { formatData } from "utils/string-formats";
import { getErrorByMessage, mapObject } from ".";
import {
  AttendanceActionsTypes,
  controllerAttendanceRequests,
} from "../actions-reducer";
import { ConsultaOfflineINSS, ConsultaOfflineSiape, KeyForward } from "./types";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { AxiosResponse } from "axios";
import { createStandaloneToast } from "@chakra-ui/react";
import { toastDefaultStyle } from "chakra/theme";

interface ConsultaOfflineOptions {
  url: string;
  mapFunction: (res: any, dispatch: Dispatch<AttendanceActionsTypes>) => void;
}

interface ConsultaFGTS {
  valorLiberadoAntecipacaoFGTS: number;
  statusSaldo: AttendanceFactaFGTS["statusSaldo"];
  simulacaoAntecipacaoFGTS: Attendance["simulacaoAntecipacaoFactaFGTS"];
}

function parseContratoEmprestimoInss(contract: any): ContratoInss {
  delete contract.excluidoAps;
  delete contract.excluidoBanco;
  delete contract.dataInclusao;
  contract.codTipoEmprestimo = contract.tipoEmprestimo?.codigo;
  contract.codBanco = contract.banco?.codigo;
  return contract;
}

function parseContratoRMCorRCC(contract: any): ContratoRMCInss {
  contract.codTipoEmprestimo = contract.tipoEmprestimo?.codigo;
  delete contract.tipoEmprestimo;
  contract.codBanco = contract.banco?.codigo;
  delete contract.banco;
  delete contract.excluidoAps;
  delete contract.excluidoBanco;
  return contract;
}

const getConvenioURLAndMapeamento = (formValues: Attendance) => {
  return new Map<Attendance["convenio"], ConsultaOfflineOptions>([
    [
      "INSS",
      {
        url: `/consultas/inss/offline/nb/${formValues.nb}`,
        mapFunction: mapConsultaOfflineINSS,
      },
    ],
    [
      "SIAPE",
      {
        url: `/consultas/siape/offline/cpf/${formValues.cpf}`,
        mapFunction: mapConsultaOfflineSIAPE,
      },
    ],
  ]).get(formValues.convenio);
};

async function requestLoop({
  promise,
  actionByStep,
  onFinish,
  onError,
  delay = 5000,
}: {
  promise: () => Promise<AxiosResponse<any, any>>;
  actionByStep: (response: AxiosResponse<ConsultaFGTS, any>) => {
    finalize: boolean;
  };
  onFinish?: (response: AxiosResponse<any, ConsultaFGTS>) => any;
  onError?: () => any;
  delay?: number;
}) {
  let response: any;
  let finalizeState: any;
  try {
    response = await promise();
    const { finalize } = actionByStep(response);
    finalizeState = finalize;
  } catch (e) {
    onError?.();
  }
  if (!finalizeState) {
    setTimeout(() => requestLoop({ promise, actionByStep, onFinish }), delay);
  } else onFinish?.(response);
}

const { toast } = createStandaloneToast();

export async function consultaOffline(
  dispatch: Dispatch<AttendanceActionsTypes>,
  formValues: Attendance,
  loading: Dispatch<SetStateAction<boolean>>
) {
  const { signal } = controllerAttendanceRequests;
  loading(true);
  const { url, mapFunction } = getConvenioURLAndMapeamento(formValues) || {};
  try {
    const res = await apiConsulta.get(url || "", { signal });
    openModalConfirm({
      onConfirm: () => mapFunction?.(res?.data, dispatch),
      message: "Deseja sobrescrever com os dados da consulta offline?",
      confirmButtonStyle: { leftIcon: <CheckIcon />, variant: undefined },
      rejectButtonStyle: { leftIcon: <CloseIcon w="12px" h="12px" /> },
    });
  } catch (e: any) {
    openModalError({
      message: getErrorByMessage(e, "Erro ao fazer consulta offline"),
    });
  } finally {
    loading(false);
  }
}

export async function consultaFGTS({
  dispatch,
  formValues,
  loading,
  consultar,
  verificar,
  errorMessage = "Erro ao fazer consulta FGTS",
  isEndLoop,
}: {
  dispatch: Dispatch<AttendanceActionsTypes>;
  formValues: Attendance;
  loading: Dispatch<SetStateAction<boolean>>;
  consultar?: () => Promise<AxiosResponse<any, any>>;
  verificar?: () => Promise<AxiosResponse<any, any>>;
  errorMessage?: string;
  isEndLoop: (response: AxiosResponse<any, any>) => boolean;
}) {
  const { signal } = controllerAttendanceRequests;
  loading(true);

  let isStopedConsultation = false;

  signal.addEventListener("abort", () => {
    isStopedConsultation = true;
    loading(false);
  });

  try {
    if (consultar) {
      const res: { data: ConsultaFGTS } = await consultar();
      const data = res?.data;
      if (data) mapConsultaFGTS(data, dispatch, formValues);
    }

    if (verificar) {
      requestLoop({
        promise: verificar,
        actionByStep: (response) => {
          if (isStopedConsultation) return { finalize: true };
          let finalize = isEndLoop(response);
          return { finalize };
        },
        onFinish: (response) => {
          mapConsultaFGTS(response?.data, dispatch, formValues);
          loading(false);
        },
      });
    }
  } catch (e: any) {
    if (e?.message !== "canceled")
      toast({
        title: errorMessage,
        status: "error",
        ...toastDefaultStyle,
      });
    loading(false);
  } finally {
    // loading(false);
  }
}

export function mapConsultaFGTS(
  consulta: ConsultaFGTS,
  dispatch: Dispatch<AttendanceActionsTypes>,
  formValues: Attendance
) {
  let atendimentoFactaFGTS = formValues.atendimentoFactaFGTS!;
  const keyForward: KeyForward<Attendance> = {
    valorLiberadoAntecipacaoFGTS: (data) => {
      atendimentoFactaFGTS = {
        ...atendimentoFactaFGTS,
        valorLiberadoAntecipacao: data,
      };
      return {
        data: atendimentoFactaFGTS,
        field: "atendimentoFactaFGTS",
        dispatchOptions: { type: "changeFieldWithInitial" },
      };
    },
    statusSaldo: (data) => {
      atendimentoFactaFGTS = { ...atendimentoFactaFGTS, statusSaldo: data };
      return {
        data: atendimentoFactaFGTS,
        field: "atendimentoFactaFGTS",
        dispatchOptions: { type: "changeFieldWithInitial" },
      };
    },
    observacaoSaldo: (data) => {
      atendimentoFactaFGTS = { ...atendimentoFactaFGTS, observacaoSaldo: data };
      return {
        data: atendimentoFactaFGTS,
        field: "atendimentoFactaFGTS",
        dispatchOptions: { type: "changeFieldWithInitial" },
      };
    },
    simulacaoAntecipacaoFGTS: (data) => {
      return {
        data: data ?? formValues.simulacaoAntecipacaoFactaFGTS,
        field: "simulacaoAntecipacaoFactaFGTS",
        dispatchOptions: { type: "changeFieldWithInitial" },
      };
    },
  };

  dispatch({
    type: "changeFieldWithInitial",
    payload: { data: "FACTA", fieldName: "bancoDigitacao" },
  });

  mapObject({ keyForward, data: consulta, dispatch, ignoreNullFields: false });
}

export function mapConsultaOfflineSIAPE(
  consulta: ConsultaOfflineSiape,
  dispatch: Dispatch<AttendanceActionsTypes>
) {
  const keyForward: KeyForward<Attendance> = {
    AGENCIA_RECEBIMENTO: (data) => {
      return { data, field: "agenciaPagamento" };
    },
    BAIRRO: (data) => {
      return { data, field: "bairro" };
    },
    BANCO_RECEBIMENTO: (data) => {
      return { data, field: "codBancoPagamento" };
    },
    CELULAR_01: (data) => {
      return { data, field: "telefoneCelular" };
    },
    CEP: (data) => {
      return { data, field: "cep" };
    },
    CIDADE: (data) => {
      return { data, field: "cidade" };
    },
    COMPLEMENTO: (data) => {
      return { data, field: "complemento" };
    },
    CONTA_RECEBIMENTO: (data) => {
      return { data, field: "contaPagamento" };
    },
    CPF: (data) => {
      return { data, field: "cpf" };
    },
    EMAIL: (data) => {
      return { data, field: "email" };
    },
    ENDERECO: (data) => {
      return { data, field: "endereco" };
    },
    // "ESTADO CIVIL": (data) => {
    //   return { data, field: "estadoCivil" };
    // },
    MAE: (data) => {
      return { data, field: "nomeMae" };
    },
    MATRICULA: (data) => {
      return { data, field: "nb" };
    },
    NASC: (data?: string) => {
      const [ano, mes, dia] = data?.split("-") || [];
      return { data: `${dia}/${mes}/${ano}`, field: "dataNascimento" };
    },
    NOME: (data) => {
      return { data, field: "nome" };
    },
    ORGAO: (data) => {
      return { data, field: "orgaoSIAPE" };
    },
    RG: (data) => {
      return { data, field: "documento" };
    },
    SEXO: (data) => {
      return { data, field: "sexo" };
    },
    UF: (data) => {
      return { data, field: "uf" };
    },
    "RESUMO_FINANCEIRO.BASECALC": (data) => {
      return { data, field: "valorSalario" };
    },
    "RESUMO_FINANCEIRO.SALDO35": (data) => {
      return { data, field: "valorMargemLivreNovo" };
    },
    "RESUMO_FINANCEIRO.SITUACAOFUNCIONAL": (data) => {
      return { data, field: "situacaoFuncionalSIAPE" };
    },
    "RESUMO_FINANCEIRO.UPAG": (data) => {
      return { data, field: "unidadePagadoraSIAPE" };
    },
    // RUBRICAS: (data: {
    //   banco: string;
    //   contrato: string;
    //   prazo: string;
    //   rubrica: string;
    //   rubricaDescricao: string;
    //   valorPmt: string;
    // }[]) => {
    //   return { data, field: "sexo" }
    // },
  };

  mapObject({ keyForward, data: consulta, dispatch });
}

export function mapConsultaOfflineINSS(
  consulta: ConsultaOfflineINSS,
  dispatch: Dispatch<AttendanceActionsTypes>
) {
  const keyForward: KeyForward<Attendance> = {
    cpf: (data) => {
      if (data != null) {
        data = data.trim();
        while (data.length < 11) data = "0" + data;
      }
      return {
        data,
        field: "cpf",
      };
    },
    bloqueioEmprestismo: "bloqueadoEmprestimo",
    dataNascimento: (data) => {
      return { data: formatData(data), field: "dataNascimento" };
    },
    ddb: (data) => {
      return { data: formatData(data), field: "ddb" };
    },
    nome: "nome",
    nomeMae: "nomeMae",
    pensaoAlimenticia: "pensaoAlimenticia",
    possuiRepresentanteLegalProcurador: "possuiRepresentante",
    cpfRepresentanteLegal: "cpfRepresentanteLegal",
    sexo: (data: string) => {
      const parseData =
        data?.toUpperCase() === "MASCULINO"
          ? "MASCULINO"
          : data?.toUpperCase() === "FEMININO"
          ? "FEMININO"
          : null;
      return { data: parseData, field: "sexo" };
    },
    situacaoBeneficio: "situacaoNB",
    valorBeneficio: "valorSalario",
    "enderecoPessoal.bairro": "bairro",
    "enderecoPessoal.cep": "cep",
    "enderecoPessoal.cidade": "cidade",
    "enderecoPessoal.endereco": "endereco",
    "enderecoPessoal.uf": "uf",
    "dadosBancarios.banco.codigo": "codBancoPagamento",
    "dadosBancarios.meioPagamento.tipo": (data: string) => {
      const parsedData =
        data?.toUpperCase() === "CARTAO MAGNETICO"
          ? "CARTAO_MAGNETICO"
          : data?.toUpperCase() === "CONTA CORRENTE"
          ? "CONTA_CORRENTE"
          : null;
      return { data: parsedData, field: "tipoPagamento" };
    },
    "especie.codigo": (data) => ({
      data: Number(data),
      field: "especieNB",
    }),
    contratosEmprestimo: (data: any[]) => {
      const parsedData = data.map((contract: any) =>
        parseContratoEmprestimoInss(contract)
      );
      return {
        data: parsedData,
        field: "contratosEmprestimosInss",
      };
    },
    nomeRepresentanteLegal: "nomeRepresentanteLegal",
    "dadosBancarios.agencia.codigo": "agenciaPagamento",
    "dadosBancarios.meioPagamento.numero": (data) => ({
      data: data === "0" ? "" : data,
      field: "contaPagamento",
    }),
    contratosCartao: (data: any[]) => {
      let parsedData = null;
      if (data?.length)
        parsedData = data.map((contract: any) =>
          parseContratoRMCorRCC(contract)
        );
      return {
        data: parsedData,
        field: "contratosRMCInss",
      };
    },
    contratosRcc: (data: any[]) => {
      let parsedData = null;
      if (data?.length)
        parsedData = data.map((contract: any) =>
          parseContratoRMCorRCC(contract)
        );
      return {
        data: parsedData,
        field: "contratosRCCInss",
      };
    },
    Rg: (data) => {
      const parseData = data?.length > 4 ? data : null;
      return { data: parseData, field: "documento" };
    },
    "margem.margemDisponivelEmprestimo": (data) => {
      const parsedData = typeof data === "number" ? data : null;
      return { data: parsedData, field: "valorMargemLivreNovo" };
    },
  };
  dispatch({
    type: "changeField",
    payload: {
      fieldName: "possuiCartaoRmc",
      data: !!consulta.contratosCartao?.length,
    },
  });

  dispatch({
    type: "changeField",
    payload: {
      fieldName: "possuiCartaoBeneficio",
      data: !!consulta.contratosRcc?.length,
    },
  });

  mapObject({ keyForward, data: consulta, dispatch });
}
