import {
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from "@chakra-ui/react";
import { ReactNode } from "react";

export function CustomInput({
  icon,
  ...rest
}: { icon: ReactNode } & InputProps) {
  return (
    <InputGroup>
      <InputLeftElement h="55px" pl="16px" pr="12px">
        {icon}
      </InputLeftElement>
      <Input
        borderRadius="10"
        borderColor="#141B3B"
        w="100%"
        h="55px"
        _placeholder={{ color: "primary.500", fontWeight: "500" }}
        _focus={{ border: "1px solid #", boxShadow: "none" }}
        {...rest}
      />
    </InputGroup>
  );
}
