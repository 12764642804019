import { CheckIcon, CloseIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Grid,
  IconButton,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import ChatWindow from "components/chat-window";
import { CustomModal } from "components/custom-modal";
import { openModalConfirm } from "components/modal-confirm-new";
import { DocumentIcon } from "components/vectors/document-icon";
import { useAttendanceContext } from "contexts/AttendanceContext";
import { useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { createEventListener, makeEvent } from "services/events";
import { getExportedVariable } from "services/get-external-function";
import { Attendance } from "../atendimento-form/types";
import { copyExtratoDataToAtendimento, digitationFunction } from "./functions";
import { consultaFGTS, consultaOffline } from "./functions/consulta";
import { in100 } from "./functions/in100";
import { LeftSideAttendance } from "./left-side-attendance";
import { useApplicationContext } from "contexts/ApplicationContext";
import { isColomboUser } from "utils/is-colombo-user";
import { numerosdeBenefico } from "../atendimento-form/fields-data";
import { controllerAttendanceRequests } from "./actions-reducer";
import {
  QualificationProps,
  desligarChamada3cPlus,
  getQualifications,
  makeManualCall,
  openModalReturnToCampaign,
} from "services/api3cplus";
import { api3cPlus } from "api/wss-c3plus";

import { TurnOffPhoneIcon } from "components/vectors/turn-off-phone-icon";
import { IoMdMic, IoMdMicOff } from "react-icons/io";
import { PhoneIcon } from "components/vectors/phone-icon";
import { use3cPlusContext } from "contexts/Context3cPlus";

export function ModalAtendimento({
  updateAttendances,
}: {
  updateAttendances: () => Promise<void>;
}) {
  const [ligandoManualCall, setLigandoManualCall] = useState(false);
  const [loadingTabulacao, setLoadingTabulacao] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tabulacoes, setTabulacoes] = useState<QualificationProps[]>([]);
  const [carregandoTabulacoes, setCarregandoTabulacoes] = useState(false);
  const { user } = useApplicationContext();
  const { controls3cPlus, dispatch3cPlus } = use3cPlusContext();
  const {
    isOpen: isOpenLastStatementModal,
    onOpen: onOpenLastStatementModal,
    onClose: onCloseLastStatementModal,
  } = useDisclosure();
  const { dispatch, formControl, setConsultandoFGTS, setReConsultandoFGTS } =
    useAttendanceContext();
  const [modalLoading, setModalLoading] = useState(false);
  const [isBlockedClose, setIsBlockedClose] = useState(false);
  const toast = useToast(toastDefaultStyle);
  const formValues = formControl.values as Attendance;
  const convenio = formControl.values.convenio;

  const consultaOfflineMessage = [
    {
      condition: ["SIAPE"].includes(convenio!) && !formControl.values.cpf,
      message: `Preecha o campo "CPF" para fazer a consulta`,
      invalidateFields: () =>
        dispatch({
          type: "setInvalidField",
          payload: { fieldName: "cpf", action: "append" },
        }),
    },
    {
      condition:
        ["GOV", "PREF", "INSS"].includes(convenio!) && !formControl.values.nb,
      message: `Preecha o campo "${
        numerosdeBenefico[convenio ?? "default"] ?? "Número do Benefício"
      }" para fazer a consulta`,
      invalidateFields: () =>
        dispatch({
          type: "setInvalidField",
          payload: { fieldName: "nb", action: "append" },
        }),
    },
    {
      condition: !["SIAPE", "GOV", "PREF", "INSS"].includes(convenio!),
      message: `Consulta disponível apenas para convênio: "INSS" ou "SIAPE"`,
      invalidateFields: () =>
        dispatch({
          type: "setInvalidField",
          payload: { fieldName: "convenio", action: "append" },
        }),
    },
    {
      condition: formControl.pendingChanges,
      message: `Salve as alterações antes de fazer a consulta`,
      invalidateFields: () => {},
    },
  ].find(({ condition }) => condition);

  const onCloseModal = () => {
    setModalLoading(false);
    setIsOpen(false);
    dispatch({ type: "closeAttendance" });
    makeEvent("attendance-modal-state", false);
    setConsultandoFGTS(false);
    setTabulacoes([]);
    setIsBlockedClose(false);
    if (!controls3cPlus.inCall)
      dispatch3cPlus({ type: "setStatus", payload: null });
    if (!controls3cPlus.inCall && controls3cPlus.isManualMode) {
      openModalReturnToCampaign({ dispatch3cPlus });
    }
  };

  const onOpen = async ({ id, init }: { id: number; init?: boolean }) => {
    setIsOpen(true);

    await getModalData({ id, init });
    makeEvent("attendance-modal-state", true);
  };

  useEffect(() => {
    if (controls3cPlus.inCall) setIsBlockedClose(controls3cPlus.inCall);
  }, [controls3cPlus.inCall]);

  const onClose = () => {
    if (formControl.pendingChanges) {
      openModalConfirm({
        title: "Salvar alterações",
        confirmButtonStyle: {
          variant: undefined,
          children: "Salvar",
          leftIcon: <FaSave />,
          loadingText: "Salvando",
        },
        rejectButtonStyle: {
          children: "Descartar",
          leftIcon: <CloseIcon w="12px" h="12px" />,
        },
        message: "Deseja salvar as alterações pendentes?",
        onConfirm: async () => {
          await updateAtendimento();
          onCloseModal();
        },
        onReject: () => {
          onCloseModal();
        },
      });
    } else onCloseModal();
  };

  const getModalData = async ({ id, init }: { id: number; init?: boolean }) => {
    setModalLoading(true);
    try {
      const destination = init
        ? `/atendimentos/${id}/start`
        : `/atendimentos/${id}/open`;
      const { data }: { data: Attendance } = await api.get(destination);
      dispatch({ type: "initAttendance", payload: data });
      if (data.atendimentoFactaFGTS?.statusSaldo === "PENDING" && data.cpf) {
        consultaFGTS({
          dispatch,
          formValues: data,
          loading: setConsultandoFGTS,
          verificar: () =>
            api.get(`/facta/fgts/consultar-solicitacao-saldo/${data.id}`),
          isEndLoop: (response) => response.data?.statusSaldo !== "PENDING",
        });
      }
      if (
        data.atendimentoFactaFGTS?.statusRecalculo === "PENDING" &&
        data.cpf
      ) {
        consultaFGTS({
          dispatch,
          formValues: data,
          loading: setReConsultandoFGTS,
          verificar: () =>
            api.get(`/facta/fgts/consultar-solicitacao-recalculo/${data.id}`),
          errorMessage: "Erro ao recalcular",
          isEndLoop: (response) => response.data?.statusRecalculo !== "PENDING",
        });
      }
    } catch (error) {
      toast({
        title: init
          ? "Erro ao iniciar atendimento!"
          : "Erro ao abrir atendimento!",
        status: "error",
        ...toastDefaultStyle,
      });
      onClose();
    } finally {
      setModalLoading(false);
    }
  };

  createEventListener("open-modal-attendance", onOpen);

  async function gerarExtrato(attendance: Attendance) {
    if (!attendance.nb || attendance.nb.trim() === "") {
      toast({
        title: "Informe o benefício",
        status: "error",
        ...toastDefaultStyle,
      });
      return;
    }
    setModalLoading(true);
    let response;
    try {
      response = await api.get(
        `/extratos/create/${attendance.nb}?atendimentoId=${attendance.id}`
      );
    } catch (error: any) {
      let hasMessage = error?.response?.data?.message;
      const errors: any = {
        sem_saldo: "Sem saldo!",
        matricula_invalida: "Benefício inválido!",
        fora_horario_consulta: "Fora do horário de consulta!",
        fora_do_horario_permitido: "Fora do horário permitido!",
        informe_matricula: "Informe o benefício!",
        sem_credito_api: "Serviço indisponível. Contate o administrador!",
        service_unavailable: "Serviço indisponível!",
      };
      if (errors[hasMessage]) {
        toast({
          title: errors[hasMessage],
          status: "error",
          ...toastDefaultStyle,
        });
      } else {
        toast({
          title: "Não foi possível gerar o extrato. Contate o administrador!",
          status: "error",
          ...toastDefaultStyle,
        });
      }
      setModalLoading(false);
      return;
    }

    dispatch({
      type: "changeFieldWithInitial",
      payload: { fieldName: "extratoOnline", data: response.data },
    });

    onOpenLastStatementModal();
    setModalLoading(false);
  }

  const updateAtendimento = async () => {
    setModalLoading(true);
    const { id } = formControl.values;
    try {
      const response = await api.put(`/atendimentos/${id}`, formControl.values);
      dispatch({ type: "initAttendance", payload: response.data });
    } catch (error) {
      toast({
        title: "Erro ao salvar alterações no atendimento!",
        status: "error",
        ...toastDefaultStyle,
      });
      setModalLoading(false);
      return;
    } finally {
      setModalLoading(false);
    }
    makeEvent("updateAttendances");
  };

  const getTabulacao = async () => {
    if (user.userData.enabled3cplus && controls3cPlus.inCall) {
      setCarregandoTabulacoes(true);
      try {
        const data = await getQualifications();
        setTabulacoes(data);
      } catch (e) {
        toast({ title: "Erro ao carregar tabulações" });
      } finally {
        setCarregandoTabulacoes(false);
      }
    }
  };

  const onClickTabulacao = async (qualification_id: number) => {
    setModalLoading(true);
    setLoadingTabulacao(true);
    try {
      const { id } = formControl.values;
      await api.put(`/atendimentos/${id}`, {
        ...formControl.values,
        idTabulacao3Cplus: qualification_id,
      });
      if (controls3cPlus.isManualMode) {
        await api3cPlus.post(
          `/3cplus/agent/manual_call/${controls3cPlus.idCall}/qualify`,
          { qualification_id }
        );
      } else {
        await api3cPlus.post(
          `/3cplus/agent/call/${controls3cPlus.idCall}/qualify`,
          { qualification_id }
        );
      }
      if (!controls3cPlus.inCall && controls3cPlus.isManualMode) {
        openModalReturnToCampaign({ dispatch3cPlus });
      }
      onClose();
    } catch (e) {
    } finally {
      setModalLoading(false);
      setLoadingTabulacao(false);
    }
  };

  useEffect(() => {
    if (controls3cPlus.inCall) getTabulacao();
  }, [controls3cPlus.inCall]);

  const atendimentoDialogFooter = !isOpen ? null : (
    <>
      {tabulacoes.length && controls3cPlus.idCall != null ? (
        tabulacoes.map((button) => {
          return (
            <Button
              key={button.id}
              onClick={() => onClickTabulacao(button.id)}
              color="#fff"
              bg={button.color}
              variant=""
              isLoading={loadingTabulacao}
              loadingText="Tabulando"
            >
              {button.name}
            </Button>
          );
        })
      ) : carregandoTabulacoes ? (
        <Spinner m="auto 10px" />
      ) : null}
      {convenio === "INSS" && isColomboUser(user) ? (
        <Button
          onClick={async () => {
            const {
              values: { bancoDigitacao, tipoOperacao, possuiCartaoBeneficio },
              pendingChanges,
            } = formControl;
            if (!bancoDigitacao)
              return toast({
                title: <>Selecione o Banco Digitação! &#128512;</>,
                status: "error",
                ...toastDefaultStyle,
              });
            if (pendingChanges)
              return toast({
                title: "Há alterações não salvas!",
                description: "Salve as alterações antes de digitar a proposta.",
                status: "error",
                ...toastDefaultStyle,
              });
            if (
              tipoOperacao === "CARTAO_BENEFICIO_INSS" &&
              possuiCartaoBeneficio === true
            )
              return toast({
                title: "O Cliente já possui Cartao Benefício!",
                status: "error",
                ...toastDefaultStyle,
              });

            const bDigitacao = bancoDigitacao as
              | "PAN"
              | "FACTA"
              | "BMG"
              | "MASTER";
            const digitacao = digitationFunction[bDigitacao!];
            digitacao(setModalLoading, formControl, dispatch);
          }}
          disabled={!!formControl.values.digitadoApi || modalLoading}
        >
          Digitar Proposta
        </Button>
      ) : null}

      {!isColomboUser(user) && convenio === "INSS" ? (
        <Button
          leftIcon={<DocumentIcon width="20px" height="20px" />}
          onClick={() => gerarExtrato(formControl.values as Attendance)}
          disabled={modalLoading}
        >
          Novo extrato
        </Button>
      ) : null}

      {user.userData?.verIn100 && convenio === "INSS" ? (
        <Button
          onClick={async () => {
            in100(
              dispatch,
              formControl.values as Attendance,
              setModalLoading,
              controllerAttendanceRequests
            );
          }}
          disabled={modalLoading}
        >
          IN100
        </Button>
      ) : null}

      {["GOV", "PREF", "INSS", "SIAPE"].includes(convenio!) ? (
        <Button
          onClick={async () => {
            if (["GOV", "PREF"].includes(convenio!))
              return toast({
                title: `Serviço em desenvolvimento para o convenio ${convenio}`,
                ...toastDefaultStyle,
              });

            if (consultaOfflineMessage) {
              dispatch({
                type: "setInvalidField",
                payload: { action: "clear" },
              });
              consultaOfflineMessage.invalidateFields();
              return toast({
                title: consultaOfflineMessage.message,
                ...toastDefaultStyle,
              });
            }
            consultaOffline(dispatch, formValues, setModalLoading);
          }}
          isDisabled={modalLoading}
          leftIcon={<SearchIcon />}
        >
          Consulta Offline
        </Button>
      ) : null}
      {!isColomboUser(user) && formControl.values.extratoOnline ? (
        <Button
          leftIcon={<CheckIcon />}
          onClick={onOpenLastStatementModal}
          disabled={modalLoading}
        >
          Ver último extrato
        </Button>
      ) : null}
      {formControl.pendingChanges ? (
        <Button
          leftIcon={<CheckIcon />}
          onClick={updateAtendimento}
          isLoading={modalLoading}
          loadingText="Salvando"
        >
          Salvar alterações
        </Button>
      ) : null}
      {formControl.pendingChanges ? (
        <Button
          leftIcon={<CloseIcon w="12px" h="12px" />}
          onClick={() => dispatch({ type: "discardChanges" })}
          disabled={modalLoading}
        >
          Descartar alterações
        </Button>
      ) : null}
      {user.userData?.enabled3cplus ? (
        <Button
          leftIcon={<PhoneIcon width="19px" height="19px" />}
          onClick={async () => {
            console.log(controls3cPlus.lastEventName);
            if (formValues.telefoneContatoNormalizado) {
              setLigandoManualCall(true);
              try {
                await makeManualCall({
                  phone: formValues.telefoneContatoNormalizado,
                  dispatch3cPlus,
                });
              } catch (e: any) {
                const errorMessage = e?.response?.data?.detail;
                toast({ title: errorMessage ?? "Erro ao ligar manualmente" });
              } finally {
                setLigandoManualCall(false);
              }
            }
          }}
          isDisabled={
            modalLoading ||
            controls3cPlus.inCall ||
            !formValues.telefoneContatoNormalizado ||
            !controls3cPlus.campaign.isLogged
          }
          isLoading={ligandoManualCall}
          loadingText="Solicitando Ligação"
        >
          Ligar Manualmente
        </Button>
      ) : null}
      {controls3cPlus.inCall && user.userData?.enabled3cplus ? (
        <>
          <IconButton
            aria-label=""
            onClick={() => {
              const session = getExportedVariable("session-3cplus");
              if (session?.isMuted?.().audio) session?.unmute?.();
              else session?.mute?.();
            }}
            icon={
              controls3cPlus.isMuted ? (
                <IoMdMic size="18px" />
              ) : (
                <IoMdMicOff size="18px" />
              )
            }
          />
          <Button
            variant="danger"
            bg="red"
            rightIcon={<TurnOffPhoneIcon />}
            onClick={() => {
              const { idCall } = controls3cPlus;
              dispatch3cPlus({ type: "hangingUpCall" });
              desligarChamada3cPlus({ idCall: idCall!, dispatch3cPlus });
            }}
            isLoading={controls3cPlus.hangingUpCall}
            loadingText="Desligando Ligação"
          >
            Desligar Ligação
          </Button>
        </>
      ) : null}
      <Button
        leftIcon={<CloseIcon w="12px" h="12px" />}
        variant="outline"
        onClick={onClose}
        isDisabled={isBlockedClose}
      >
        Fechar
      </Button>
    </>
  );

  return (
    <>
      <CustomModal
        isOpen={isOpen}
        size="full"
        modalTitle="Atendimento"
        modalFooter={atendimentoDialogFooter}
        onClose={() => {
          if (!isBlockedClose) onClose();
        }}
        isLoading={modalLoading}
        withOutBlur
        modalCloseButtonProps={{ isDisabled: isBlockedClose }}
      >
        {formControl.values.id ? (
          <Grid templateColumns="66fr 34fr" w="100%" gap="40px">
            <LeftSideAttendance />
            <ChatWindow formControl={formControl} />
          </Grid>
        ) : null}
      </CustomModal>

      <CustomModal
        isOpen={isOpenLastStatementModal}
        modalTitle="Último Extrato"
        onClose={onCloseLastStatementModal}
        size="2xl"
        modalFooter={
          <>
            <Button
              onClick={() => {
                openModalConfirm({
                  message: `Deseja copiar dados do último extrato para o atendimento?`,
                  onConfirm: () => {
                    copyExtratoDataToAtendimento(
                      formControl.values as Attendance,
                      dispatch
                    );
                    toast({
                      title: "Dados copiados para o atendimento",
                      status: "info",
                      ...toastDefaultStyle,
                    });
                  },
                });
                onCloseLastStatementModal();
              }}
              whiteSpace="normal"
            >
              Copiar dados do extrato para o atendimento
            </Button>
            {formControl.values.extratoOnline ? (
              <Button
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_PUBLIC_API_URL}/extratos/exibir/${
                      formControl.values.extratoOnline!.hash
                    }`,
                    "_blank"
                  );
                }}
                whiteSpace="normal"
              >
                Exibir extrato
              </Button>
            ) : null}
          </>
        }
      >
        <></>
      </CustomModal>
    </>
  );
}

export const openModalAttendance = (data: { id: number; init?: boolean }) =>
  makeEvent("open-modal-attendance", data);
