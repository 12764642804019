import { Box, Flex } from "@chakra-ui/react";
import { defaultScroll, defaultTransition } from "chakra/theme";
import { Navbar, NavbarProps, navbarWidth } from "components/navbar";
import SideMenu, { isMenuOpenState, menuWidth } from "components/side-menu";
import { ReactNode } from "react";

export function GetLayout({
  children,
  HeadRender,
}: {
  children?: ReactNode;
  HeadRender?: NavbarProps["HeadRender"];
}) {
  return (
    <>
      <Navbar HeadRender={HeadRender} />
      <Flex w="100%" h={`calc(100vh - ${navbarWidth})`} bg="bg-gray">
        <SideMenu />
        <Box
          id="page-content"
          maxW={
            isMenuOpenState
              ? `calc(100% - ${menuWidth})`
              : { base: "calc(100% - 100px)" }
          }
          w="100%"
          p={{ base: "30px", xl: "30px 30px 30px 30px" }}
          maxH={`calc(100vh - ${navbarWidth})`}
          overflow="auto"
          transition={defaultTransition}
          display={"block"}
          bg={`#F5F7F9`}
          sx={defaultScroll}
        >
          {children}
        </Box>
      </Flex>
    </>
  );
}
