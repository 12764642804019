import { Icon, IconProps } from "@chakra-ui/react";

export function TurnOffPhoneIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="20px"
      height="20px"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M2.20731 0.0127203C2.1105 -0.0066425 1.99432 -0.00664723 1.91687 0.0320784C0.871279 0.438697 0.212942 1.92964 0.0580392 2.95587C-0.426031 6.28627 2.20731 9.17133 4.62766 11.0689C6.77694 12.7534 10.9012 15.5223 13.3409 12.8503C13.6507 12.5211 14.0186 12.037 13.9993 11.553C13.9412 10.7397 13.186 10.1588 12.6051 9.71349C12.1598 9.38432 11.2304 8.47427 10.6495 8.49363C10.1267 8.51299 9.79754 9.05515 9.46837 9.38432L8.88748 9.96521C8.79067 10.062 7.55145 9.24878 7.41591 9.15197C6.91248 8.8228 6.4284 8.45491 6.00242 8.04829C5.57644 7.64167 5.18919 7.19632 4.86002 6.73161C4.7632 6.59607 3.96933 5.41495 4.04678 5.31813C4.04678 5.31813 4.72448 4.58234 4.91811 4.2919C5.32473 3.67229 5.63453 3.18822 5.16982 2.45243C4.99556 2.18135 4.78257 1.96836 4.55021 1.73601C4.14359 1.34875 3.73698 0.94213 3.27227 0.612962C3.02055 0.419334 2.59457 0.0708088 2.20731 0.0127203Z"
        fill="currentColor"
      />
      <path
        d="M1 14L11.5 2"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Icon>
  );
}
