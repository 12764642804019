import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useEffect, useRef, useState } from "react";
// import { Toast } from 'primereact/toast';
import { DeleteIcon } from "@chakra-ui/icons";
import { GetLayout } from "components/get-layout";
import { ConfirmDialog } from "components/modal-confirm";
import { BlockUI } from "primereact/blockui";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressBar } from "primereact/progressbar";
import { ProgressSpinner } from "primereact/progressspinner";
import api from "../api/api";
import { useToast } from "@chakra-ui/react";
import { toastDefaultStyle } from "chakra/theme";

export const SimulacaoLista = () => {
  const [confirmDialogData, setConfirmDialogData] = useState(null);
  const fileUploadRef = useRef(null);
  const [simulacaoDialog, setSimulacaoDialog] = useState({
    nome: "",
    file: "",
    filename: "",
  });
  const [simulacaoDownloadDialog, setsimulacaoDownloadDialog] = useState({});
  const [loading, setLoading] = useState(false);
  const [openSimulacaoDialog, setOpenSimulacaoDialog] = useState(false);
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
  const [simulacoes, setSimulacoes] = useState([]);
  const [errors, setErrors] = useState({ name: false });
  const [taxaNovaOperacao, setTaxaNovaOperacao] = useState("");
  const [coeficiente, setCoeficiente] = useState("");
  const toast = useToast();

  let saveOrUpdateSimulacao = async () => {
    let hasNomeError =
      !simulacaoDialog.nome || simulacaoDialog.nome.trim().length === 0;
    let hasFileError = !simulacaoDialog.file;
    setErrors({ nome: hasNomeError, file: hasFileError });
    if (hasNomeError || hasFileError) return;
    setLoading(true);
    let formData = new FormData();
    formData.append("files", simulacaoDialog.file);
    formData.append("filename", simulacaoDialog.filename);
    formData.append("nome", simulacaoDialog.nome.trim());
    try {
      await api.post("/simulacoes", formData);
    } catch (error) {
      let hasMessage =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message;
      if (
        hasMessage &&
        "column_beneficio_not_found" === error.response.data.message
      ) {
        toast({
          title:
            "Erro ao enviar: Arquivo não possui coluna NB ou BENEFICIO ou MATRICULA!",
          status: "error",
          ...toastDefaultStyle,
        });
      } else if (
        hasMessage &&
        "duplicate_column_beneficio_found" === error.response.data.message
      ) {
        toast({
          title:
            "Erro ao enviar: Arquivo deve possuir apenas uma coluna NB ou BENEFICIO ou MATRICULA!",
          status: "error",
          ...toastDefaultStyle,
        });
      } else {
        toast({
          title: "Erro ao enviar arquivo!",
          status: "error",
          ...toastDefaultStyle,
        });
      }
    }
    await loadSimulacoes();
    setOpenSimulacaoDialog(false);
    setLoading(false);
  };

  const abrirDownloadDialog = (rowData) => {
    setTaxaNovaOperacao("");
    setsimulacaoDownloadDialog({ ...rowData });
    setOpenDownloadDialog(true);
  };

  const download = async (rowData, type) => {
    let taxaNovaOperacaoTratada = taxaNovaOperacao.replace(",", ".");
    let coeficienteTratado = coeficiente.replace(",", ".");

    if (taxaNovaOperacaoTratada === "" || isNaN(taxaNovaOperacaoTratada)) {
      setErrors({ ...errors, taxaNovaOperacao: true });
      return;
    }

    if (coeficienteTratado === "" || isNaN(coeficienteTratado)) {
      setErrors({ ...errors, coeficiente: true });
      return;
    }

    setLoading(true);
    let response;
    try {
      response = await api.get(
        "/simulacoes/download/" +
          rowData.id +
          "?taxaNovaOperacao=" +
          taxaNovaOperacaoTratada +
          "&coeficiente=" +
          coeficienteTratado
      );
      let filename = rowData.nome + ".csv";
      createLinkAndDownload(response, filename);
    } catch (error) {
      toast({
        title: "Erro ao realizar download!",
        status: "error",
        ...toastDefaultStyle,
      });
    }
    setLoading(false);
    setOpenDownloadDialog(false);
  };

  const createLinkAndDownload = (response, filename) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await loadSimulacoes();
      setLoading(false);
    };
    load();
  }, []);

  const play = async (rowData) => {
    setLoading(true);
    await api.get("/simulacoes/" + rowData.id + "/play");
    await loadSimulacoes();
    setLoading(false);
  };

  const pause = async (rowData) => {
    setLoading(true);
    await api.get("/simulacoes/" + rowData.id + "/pause");
    await loadSimulacoes();
    setLoading(false);
  };

  const loadSimulacoes = async () => {
    let response = await api.get("/simulacoes");
    setSimulacoes(response.data);
  };

  const atualizar = async () => {
    setLoading(true);
    await loadSimulacoes();
    setLoading(false);
  };

  const onChangeSimulacaoDialog = (field, value) => {
    setErrors({ ...errors, [field]: false });
    setSimulacaoDialog({ ...simulacaoDialog, [field]: value });
  };

  const changeFiles = (e) => {
    setErrors({ ...errors, file: false });
    setSimulacaoDialog({
      ...simulacaoDialog,
      file: e.target.files[0],
      filename: e.target.files[0].name,
    });
  };

  const openNew = () => {
    setErrors({});
    setSimulacaoDialog({
      nome: "",
      atualizacaoMinima: "",
      file: "",
      filename: "",
    });
    setOpenSimulacaoDialog(true);
  };

  const onChangeTaxaNovaOperacao = (taxa) => {
    setErrors({ ...errors, taxaNovaOperacao: false });
    let novaTaxa = "";
    let casasAlemVirgula = 0;
    let passouVirgula = false;
    for (let i = 0; i < taxa.length; i++) {
      if (
        ["0", "9", "8", "7", "6", "5", "4", "3", "2", "1"].includes(taxa[i])
      ) {
        if (passouVirgula) {
          if (casasAlemVirgula < 2) {
            novaTaxa += taxa[i];
            casasAlemVirgula++;
          }
        } else {
          novaTaxa += taxa[i];
        }
      } else if ([",", "."].includes(taxa[i])) {
        if (!passouVirgula) {
          novaTaxa += taxa[i];
          passouVirgula = true;
        }
      }
    }
    setTaxaNovaOperacao(novaTaxa);
  };

  const onChangeCoeficiente = (coeficiente) => {
    setErrors({ ...errors, taxaNovaOperacao: false });
    let novoCoeficiente = "";
    let casasAlemVirgula = 0;
    let passouVirgula = false;
    for (let i = 0; i < coeficiente.length; i++) {
      if (
        ["0", "9", "8", "7", "6", "5", "4", "3", "2", "1"].includes(
          coeficiente[i]
        )
      ) {
        if (passouVirgula) {
          if (casasAlemVirgula < 6) {
            novoCoeficiente += coeficiente[i];
            casasAlemVirgula++;
          }
        } else {
          novoCoeficiente += coeficiente[i];
        }
      } else if ([",", "."].includes(coeficiente[i])) {
        if (!passouVirgula) {
          novoCoeficiente += coeficiente[i];
          passouVirgula = true;
        }
      }
    }
    setCoeficiente(novoCoeficiente);
  };

  const simulacaoTableTableHeader = (
    <div className="table-header">
      Lista de Simulações
      {/* <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText value={globalFilter1} onChange={(e) => setGlobalFilter1(e.target.value)} placeholder="Global Search" />
            </span> */}
      <span className="block mt-2 md:mt-0 p-input-icon-left">
        <Button
          label="Atualizar"
          icon="pi pi-plus"
          className="p-button-primary mr-2"
          onClick={atualizar}
          style={{ width: "150px" }}
        />
        <Button
          label="Nova"
          icon="pi pi-plus"
          className="p-button-primary mr-2"
          onClick={openNew}
          style={{ width: "150px" }}
        />
      </span>
    </div>
  );

  const bodyTemplate = (data, props) => (
    <>
      <span className="p-column-title">{props.header}</span>
      {data[props.field]}
    </>
  );
  const dateBodyTemplate = (data, props) => (
    <>
      <span className="p-column-title">{props.header}</span>
      {parseDate(data[props.field])}
    </>
  );

  const parseDate = (value) => {
    return (
      value.substring(8, 10) +
      "/" +
      value.substring(5, 7) +
      "/" +
      value.substring(0, 4)
    );
  };

  const statusBodyTemplate = (hig) => {
    let value = Math.floor((hig.totalCompleted / hig.total) * 10000) / 100;
    let statusBadge = value === 100 ? "qualified" : "proposal";
    let status = value === 100 ? "COMPLETO" : "EM ANDAMENTO";
    return (
      <>
        <span className="p-column-title">Status</span>
        {/* <span className={`customer-badge status-${data.status}`}>{data.status}</span> */}
        <span className={"customer-badge status-" + statusBadge}>{status}</span>
      </>
    );
  };

  const activityBody = (hig) => {
    let value = Math.floor((hig.totalCompleted / hig.total) * 10000) / 100;
    return (
      <>
        {/* <span className="p-column-title">Activity</span> */}
        <span>{hig.totalCompleted + "/" + hig.total}</span>
        <ProgressBar value={value} showValue={false} />
      </>
    );
  };

  const actionTemplate = (rowData) => (
    <div className="actions">
      {rowData.paused === true && rowData.totalCompleted !== rowData.total && (
        <Button
          icon="pi pi-play"
          className="p-button-rounded p-button-primary mr-1"
          onClick={() => play(rowData)}
        />
      )}
      {rowData.paused === false && rowData.totalCompleted !== rowData.total && (
        <Button
          icon="pi pi-pause"
          className="p-button-rounded p-button-primary mr-1"
          onClick={() => pause(rowData)}
        />
      )}
      {rowData.totalCompleted === rowData.total && (
        <Button
          icon="pi pi-download"
          className="p-button-rounded p-button-primary mr-1"
          onClick={() => abrirDownloadDialog(rowData)}
        />
      )}
      <Button
        icon="pi pi-trash"
        className="p-button-rounded p-button-danger"
        onClick={() => confirmDeleteSimulacao(rowData)}
      />
    </div>
  );

  const simulacaoDialogFooter = (
    <>
      <Button
        label="Salvar"
        icon="pi pi-check"
        className="p-button-text"
        onClick={() => saveOrUpdateSimulacao()}
      />
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => hideSimulacaoDialog()}
      />
    </>
  );

  const downloadDialogFooter = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => hideDownloadDialog()}
      />
    </>
  );

  const hideSimulacaoDialog = () => {
    setOpenSimulacaoDialog(false);
  };

  const hideDownloadDialog = () => {
    setOpenDownloadDialog(false);
  };

  const acceptDeleteSimulacao = async (rowData) => {
    setLoading(true);
    try {
      await api.delete("/simulacoes/" + rowData.id);
    } catch (error) {
      toast({
        title: "Erro ao excluir simulação!",
        status: "error",
        ...toastDefaultStyle,
      });
    }
    await loadSimulacoes();
    setLoading(false);
  };

  const confirmDeleteSimulacao = (rowData) => {
    setConfirmDialogData({
      message: `Deseja excluir "${rowData.nome}"?`,
      title: "Confirmação",
      accept: () => acceptDeleteSimulacao(rowData),
      close: () => setConfirmDialogData(null),
      acceptButtonStyle: { variant: "danger", leftIcon: <DeleteIcon /> },
    });
  };

  const getLoadingTemplate = () => {
    return (
      <ProgressSpinner
        style={{ width: "150px", height: "150px" }}
        strokeWidth="3"
        animationDuration=".5s"
      />
    );
  };

  return (
    <>
      <ConfirmDialog dialogData={confirmDialogData} />
      <GetLayout>
        <div className="grid table-demo">
          <div className="col-12">
            <div className="card">
              {/* <h5>Default</h5>
                    <p>Pagination, sorting, filtering and checkbox selection.</p> */}

              <BlockUI
                blocked={loading}
                fullScreen
                template={getLoadingTemplate}
              ></BlockUI>
              <DataTable
                value={simulacoes}
                paginator
                className="p-datatable-customers"
                rows={10}
                dataKey="id"
                rowHover
                // globalFilter={globalFilter1}
                emptyMessage="Não há simulações cadastradas"
                header={simulacaoTableTableHeader}
                size="small"
              >
                <Column
                  field="nome"
                  header="Nome"
                  sortable
                  body={bodyTemplate}
                ></Column>
                <Column
                  field="criacao"
                  header="Criação"
                  sortable
                  body={dateBodyTemplate}
                ></Column>
                <Column
                  header="Atividade"
                  sortable
                  body={activityBody}
                ></Column>
                <Column
                  field="status"
                  header="Status"
                  sortable
                  body={statusBodyTemplate}
                ></Column>
                <Column header="Ações" body={actionTemplate}></Column>
              </DataTable>
              <Dialog
                visible={openSimulacaoDialog}
                style={{ width: "450px" }}
                header="Simulação"
                modal
                className="p-fluid"
                footer={simulacaoDialogFooter}
                onHide={hideSimulacaoDialog}
              >
                <div className="field">
                  <label htmlFor="nomeDialog">Nome</label>
                  <InputText
                    id="nomeDialog"
                    value={simulacaoDialog.nome}
                    className={errors.nome && "p-invalid"}
                    onChange={(e) =>
                      onChangeSimulacaoDialog("nome", e.target.value)
                    }
                    autoComplete="new-password"
                  />
                  {errors.nome && (
                    <small className="p-invalid">Informe um nome</small>
                  )}
                </div>
                <input
                  ref={fileUploadRef}
                  hidden
                  type="file"
                  onChange={(e) => changeFiles(e)}
                />
                <div className="grid">
                  <div className="field col-6">
                    <Button
                      label="Selecionar arquivo"
                      icon="pi pi-plus"
                      onClick={() => {
                        fileUploadRef.current.click();
                      }}
                    ></Button>
                    {errors.file && (
                      <small className="p-invalid">Selecione um arquivo</small>
                    )}
                  </div>
                </div>
                <div className="field">
                  <label>{simulacaoDialog.filename}</label>
                </div>
              </Dialog>
              <Dialog
                visible={openDownloadDialog}
                style={{ width: "450px" }}
                header="Download"
                modal
                className="p-fluid"
                footer={downloadDialogFooter}
                onHide={hideDownloadDialog}
              >
                <div className="grid">
                  <div className="field col-6">
                    <label htmlFor="taxaNovaOperacao">
                      Taxa da nova operação
                    </label>
                    <InputText
                      id="taxaNovaOperacao"
                      value={taxaNovaOperacao}
                      className={errors.taxaNovaOperacao && "p-invalid"}
                      onChange={(e) => onChangeTaxaNovaOperacao(e.target.value)}
                      autoComplete="new-password"
                    />
                    {errors.taxaNovaOperacao && (
                      <small className="p-invalid">
                        Taxa da nova operação inválida
                      </small>
                    )}
                  </div>
                  <div className="field col-6">
                    <label htmlFor="coeficiente">Coeficiente</label>
                    <InputText
                      id="coeficiente"
                      value={coeficiente}
                      className={errors.coeficiente && "p-invalid"}
                      onChange={(e) => onChangeCoeficiente(e.target.value)}
                      autoComplete="new-password"
                    />
                    {errors.coeficiente && (
                      <small className="p-invalid">Coeficiente inválido</small>
                    )}
                  </div>
                  <div className="field col-12">
                    <Button
                      label="Download"
                      onClick={() => {
                        download(simulacaoDownloadDialog);
                      }}
                    ></Button>
                  </div>
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </GetLayout>
    </>
  );
};
