export interface MailingField {
  name: string;
  field: string;
  isVisible: (p: string) => boolean;
  type?: "multi-select";
}
export interface MailingFields {
  title: string;
  fields: MailingField[];
}

export const uploadMailingFields: MailingFields[] = [
  {
    title: "Selecione as Colunas de Dados Cadastrais:",
    fields: [
      {
        name: "Benefício",
        field: "colunaBeneficio",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Matrícula",
        field: "colunaMatricula",
        isVisible: (convenio) =>
          convenio === "SIAPE" || convenio === "EXERCITO",
      },
      {
        name: "CPF",
        field: "colunaCPF",
        isVisible: (convenio) => true,
      },
      {
        name: "Telefones",
        field: "colunasTelefones",
        type: "multi-select",
        isVisible: (convenio) => true,
      },
      {
        name: "Nome",
        field: "colunaNome",
        isVisible: (convenio) => true,
      },
      {
        name: "Data Nascimento",
        field: "colunaDataNascimento",
        isVisible: (convenio) => true,
      },
      {
        name: "Sexo",
        field: "colunaSexo",
        isVisible: (convenio) => true,
      },
      {
        name: "Naturalidade",
        field: "colunaNaturalidade",
        isVisible: (convenio) => true,
      },
      {
        name: "UF Naturalidade",
        field: "colunaUFNaturalidade",
        isVisible: (convenio) => true,
      },
      {
        name: "Documento",
        field: "colunaDocumento",
        isVisible: (convenio) => true,
      },
      {
        name: "Órgão Emissor Doc.",
        field: "colunaOrgaoEmissorDoc",
        isVisible: (convenio) => true,
      },
      {
        name: "UF Emissão Doc.",
        field: "colunaUFEmissaoDoc",
        isVisible: (convenio) => true,
      },
      {
        name: "Data Emissão Doc.",
        field: "colunaDataEmissaoDoc",
        isVisible: (convenio) => true,
      },
      {
        name: "Nome da mãe",
        field: "colunaNomeMae",
        isVisible: (convenio) => true,
      },
      {
        name: "Nome do pai",
        field: "colunaNomePai",
        isVisible: (convenio) => true,
      },
      {
        name: "CEP",
        field: "colunaCEP",
        isVisible: (convenio) => true,
      },
      {
        name: "Endereço",
        field: "colunaEndereco",
        isVisible: (convenio) => true,
      },
      {
        name: "Número",
        field: "colunaNumero",
        isVisible: (convenio) => true,
      },
      {
        name: "Complemento",
        field: "colunaComplemento",
        isVisible: (convenio) => true,
      },
      {
        name: "Bairro",
        field: "colunaBairro",
        isVisible: (convenio) => true,
      },
      {
        name: "Município",
        field: "colunaMunicipio",
        isVisible: (convenio) => true,
      },
      {
        name: "UF",
        field: "colunaUF",
        isVisible: (convenio) => true,
      },
      {
        name: "E-mail",
        field: "colunaEmail",
        isVisible: (convenio) => true,
      },
      {
        name: "Valor Benefício (MR)",
        field: "colunaValorBeneficio",
        isVisible: (convenio) => true,
      },
      {
        name: "Cod. Espécie Benefício",
        field: "colunaCodigoEspecie",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "DDB",
        field: "colunaDDB",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "UF Benefício",
        field: "colunaUFBeneficio",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Situação Benefício",
        field: "colunaSituacaoBeneficio",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Meio de Pagamento",
        field: "colunaMeioPagamento",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Cód. Banco Pagamento",
        field: "colunaCodigoBancoPagamento",
        isVisible: (convenio) => true,
      },
      {
        name: "Agência Pagamento",
        field: "colunaAgenciaPagamento",
        isVisible: (convenio) => true,
      },
      {
        name: "DV Agência Pagamento",
        field: "colunaAgenciaPagamentoDV",
        isVisible: (convenio) => true,
      },
      {
        name: "Conta Pagamento",
        field: "colunaContaPagamento",
        isVisible: (convenio) => true,
      },
      {
        name: "DV Conta Pagamento",
        field: "colunaContaPagamentoDV",
        isVisible: (convenio) => true,
      },
      {
        name: "Valor Parcelas",
        field: "colunaValorParcelas",
        isVisible: (convenio) => convenio === "INSS" || convenio === "SIAPE",
      },
      {
        name: "Valor Empréstimos",
        field: "colunaValorEmprestimos",
        isVisible: (convenio) => convenio === "INSS" || convenio === "SIAPE",
      },
      {
        name: "Margem 35",
        field: "colunaMargem35",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Total Contratos",
        field: "colunaTotalContratos",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Possui Cartão RMC",
        field: "colunaPossuiCartaoRMC",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Possui Cartão RCC",
        field: "colunaPossuiCartaoRCC",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Margem Cartão",
        field: "colunaMargemCartao",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Possui Representante Legal",
        field: "colunaPossuiRepresentanteLegal",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "CPF Representante Legal",
        field: "colunaCPFRepresentanteLegal",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Nome Representante Legal",
        field: "colunaNomeRepresentanteLegal",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Não Perturbe",
        field: "colunaNaoPerturbe",
        isVisible: (convenio) => true,
      },
    ],
  },
  {
    title: "Selecione as Colunas de Contratos:",
    fields: [
      {
        name: "Código Contrato",
        field: "colunaCodigoContrato",
        isVisible: (convenio) => true,
      },
      {
        name: "Código Banco Contrato",
        field: "colunaCodigoBancoContrato",
        isVisible: (convenio) => true,
      },
      {
        name: "Valor Emprestimo",
        field: "colunaValorEmprestimo",
        isVisible: (convenio) => true,
      },
      {
        name: "Início Desconto",
        field: "colunaInicioDesconto",
        isVisible: (convenio) => true,
      },
      {
        name: "Final Desconto",
        field: "colunaFinalDesconto",
        isVisible: (convenio) => true,
      },
      {
        name: "Prazo",
        field: "colunaPrazo",
        isVisible: (convenio) => true,
      },
      {
        name: "Valor Parcela",
        field: "colunaValorParcela",
        isVisible: (convenio) => true,
      },
      {
        name: "Tipo Empréstimo",
        field: "colunaTipoEmprestimo",
        isVisible: (convenio) => true,
      },
      {
        name: "Data Averbação",
        field: "colunaDataAverbacao",
        isVisible: (convenio) => true,
      },
      {
        name: "Taxa",
        field: "colunaTaxa",
        isVisible: (convenio) => true,
      },
      {
        name: "Parcelas Restantes",
        field: "colunaParcelasRestantes",
        isVisible: (convenio) => true,
      },
      {
        name: "Parcelas Pagas",
        field: "colunaParcelasPagas",
        isVisible: (convenio) => true,
      },
      {
        name: "Saldo Devedor",
        field: "colunaSaldoDevedor",
        isVisible: (convenio) => true,
      },
    ],
  },
];
