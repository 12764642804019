import { Icon, IconProps } from "@chakra-ui/react";

export function CleanIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="20px"
      height="20px"
      viewBox="0 0 30 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M27.83 0.0352402C29.398 -0.194088 29.93 0.751891 30 1.25355C29.776 2.0562 27.5733 8.08562 26.5 11L23 9.28004C26.416 2.28552 27.6433 0.202458 27.83 0.0352402Z"
        fill="currentColor"
      />
      <path
        d="M27.6427 19C28.7998 15.7582 26.8714 13.6557 25.7625 13.0097C25.0635 12.5399 23.058 11.3359 20.6282 10.2788C18.1984 9.2217 16.5303 11.424 16 12.6573L27.6427 19Z"
        fill="currentColor"
      />
      <path
        d="M24.556 19.2249L27 20.0958C23.273 30.4823 21.0174 33.0262 20.3555 32.9998C8.74667 32.8415 1.18567 24.1332 0.269184 22.7082C-0.464004 21.5683 0.472847 20.861 1.03292 20.6499C9.34239 20.5233 13.5073 16.1639 14.5511 14L16.6895 15.3458L16.6132 15.5042C14.7802 20.0641 7.49923 22.5235 4.08787 23.1832C4.51556 23.6899 6.30271 25.0304 7.14282 25.6374C8.97579 25.384 10.5033 24.5291 11.0379 24.1332C10.488 25.1465 9.48495 26.4026 9.05217 26.904C9.89228 27.5637 11.8933 28.7723 13.1763 28.329C14.4594 27.8857 17.0714 25.8221 18.217 24.8457C18.0948 25.669 16.384 28.4082 15.5439 29.6748C17.1325 30.1182 18.8535 30.2818 19.5154 30.3082C21.4705 27.6482 23.6905 21.811 24.556 19.2249Z"
        fill="currentColor"
      />
    </Icon>
  );
}
