import { EditIcon, RepeatIcon } from "@chakra-ui/icons";
import { Button, IconButton, useToast } from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { AttendanceActionsTypes } from "components/atendimentos-components/modal-atendimento/actions-reducer";
import { useState, Dispatch } from "react";
import { Attendance } from "../types";

export function BtnLinkFormalizacao({
  formValues,
  dispatch,
}: {
  formValues: Attendance;
  dispatch: Dispatch<AttendanceActionsTypes>;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  return formValues.bancoDigitacao === "PAN" ? (
    <Button
      size="sm"
      px="18px"
      onClick={async () => {
        setIsLoading(true);
        try {
          const response = await api.get(
            `/propostas/generate-link/${formValues.id}`
          );
          dispatch({
            type: "changeFieldWithInitial",
            payload: {
              fieldName: "linkFormalizacao",
              data: response.data.linkFormalizacao,
            },
          });
          setIsLoading(false);
        } catch {
          toast({
            title: "Erro ao gerar Link Formalização!",
            ...toastDefaultStyle,
          });
          setIsLoading(false);
        }
      }}
      isLoading={isLoading}
      loadingText="Gerando Link"
      leftIcon={<EditIcon />}
      disabled={!formValues.digitadoApi}
    >
      Gerar Link
    </Button>
  ) : null;
}
